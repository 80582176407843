import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import MatchFieldComposition from "./MatchFieldComposition";
import MatchTimeline from "./MatchTimeline";
import TableMatchGeneralSoccer from "../../tables/TableMatchGeneralSoccer";
import MatchGeneralHeader from "./MatchGeneralHeader";

class MatchGeneral extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      selectedColumns: [],
      selectedGroups: [],
      searchText: "",
    };
  }

  componentDidMount() {
    this.prepareData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.prepareData();
    }
  }

  findPlayerPosition = (position, infoByPlayer) => {
    if (!infoByPlayer) return {};
    const infoPosition = infoByPlayer.find(
      (info) => info && info.position && info.position.number === position
    );
    return infoPosition && infoPosition.player;
  };

  getMatchSheetsPlayersSubstitutes = (infoByPlayer) =>
    infoByPlayer && infoByPlayer.filter((info) => info.position.number >= 16);


  renderStaff = () => {
    const staffs = this.props.matchInfos && this.props.matchInfos.staffs;
    return (
      staffs &&
      staffs.length > 0 &&
      staffs.map((staff) => {
        return (
          <div className="py-1 px-3 rounded-full text-sm">
            {staff.title + " : "}
            {staff.displayName}
          </div>
        );
      })
    );
  };

  prepareData = (firstTime) => {
    var infoByPlayer =
      this.props.infoByPlayer &&
      this.props.infoByPlayer.sort((a, b) =>
        a.position.number === b.position.number
          ? 0
          : a.position.number < b.position.number
          ? -1
          : 1
      );

    const columns = [
      {
        key: "name",
        label: "Name",
        visible: true,
        format: (row) => (
          <Link
            to={{
              pathname: `/competitionSoccer/playerInfoByMatch/${row.matchId}/${row.player.id}`,
            }}
          >
            <span className="hover:bg-blue-200 py-1 px-3 rounded-full text-xs">
              {row.position.number + " - " + row.player.lastName.toUpperCase()}
            </span>
          </Link>
        ),
      },
      {
        key: "position",
        label: "Position",
        visible: true,
        format: (row) => (
          <span className=" py-1 px-3 rounded-full text-xs">
            {row.position.name}
          </span>
        ),
      },
      {
        key: "status",
        label: "Status",
        visible: true,
        format: (row) => (
          <span className=" py-1 px-3 rounded-full text-xs">
            {row.position.status.name}
          </span>
        ),
      },
      {
        key: "playtime",
        label: "Playtime",
        visible: true,
        format: (row) => (
          <span className=" py-1 px-3 rounded-full text-xs">
            {row.playTime.minutes + " Min"}
          </span>
        ),
      },
    ];

    // after columns added Add local Storage
    var selectedColumns = localStorage.getItem("TableSoccer_GeneralStat");
    if (selectedColumns === null || selectedColumns.length === 0) {
      selectedColumns = [];
      columns.forEach((column) => {
        selectedColumns.push(column.key);
        //  column.visible = true
      });
    } else selectedColumns = selectedColumns.split("|");
    columns.forEach((column) => {
      column.visible = selectedColumns.indexOf(column.key) < 0 ? false : true;
    });

    // selected columns
    if (firstTime) {
      selectedColumns = [];
      columns.forEach((column) => {
        if (column.visible) {
          selectedColumns.push(column.key);
        }
      });
      selectedColumns = selectedColumns.slice(0, 6);
      this.setState({ selectedColumns });
    }

    this.setState({
      columns,
      infoByPlayer,
      selectedColumns,
    });
  };

  // change columns
  onChangeSelectedColumns = (res) => {
    this.setState({ selectedColumns: res }, () => {
      // determiner ce qui est selectionne
      let select = [];
      this.state.columns.forEach((a) => {
        if (this.state.selectedColumns.indexOf(a.key) > -1) select.push(a.key);
      });
      // save selected elements to localstorage
      localStorage.setItem("TableSoccer_GeneralStat", select.join("|"));
    });
  };

  render() {
    const { t } = this.props;
    var infoByPlayer = this.props.infoByPlayer;
    var columns = this.state.columns;
    var selectedColumns = this.state.selectedColumns;
    var searchText = this.state.searchText;

    if (columns) {
      columns = columns.filter((o) =>
        selectedColumns.some((item) => item === o.key)
      );
    }

    if (searchText.length > 2) {
      infoByPlayer = infoByPlayer.filter(
        (o) =>
          o.player.lastName.toLowerCase().includes(searchText.toLowerCase())
        // ||
        // (o.type &&
        //   o.type.lastName.toLowerCase().includes(searchText.toLowerCase()))
      );
    }

    return (
      <div className="container font-semibold">
        <MatchGeneralHeader infoByPlayer={infoByPlayer} t={t} match={this.props.match} matchInfos={this.props.matchInfos} />
        <div className="grid grid-cols-2 gap-4">
          <MatchFieldComposition infoByPlayer={infoByPlayer} t={t} match={this.props.match} matchInfos={this.props.matchInfos} />
          <MatchTimeline t={t}  matchInfos={this.props.matchInfos} />
        </div>
        <TableMatchGeneralSoccer infoByPlayer={this.props.infoByPlayer} />
      </div>
    );
  }
}

export default withTranslation()(withRouter(MatchGeneral));
