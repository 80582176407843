import React from "react"
import { Link } from "react-router-dom"
import { withTranslation } from "react-i18next"
import TableSimple from "../components/table/TableSimple"
import moment from "moment"
import FiltersSearch from "../components/filters/FiltersSearch"
import SearchInput from "../components/forms/input/SearchInput"
import ExportToCSV from "../components/export/ExportToCSV"
import Tooltip from "../components/typography/Tooltip/Tooltip"
import FilterDate from "../components/filters/FilterDate"
import FilterSeparator from "../components/filters/FilterSeparator"
import FiltersTitle from "../components/filters/FiltersTitle"
import FiltersBar from "../components/filters/FiltersBar"


class TableYearsByPlayer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      columns: [],
      weeks: [],
      searchText: "",
      months: [],
      selectedMonths: [],
      startDate: null,
      endDate: null,
    }
  }

  componentDidMount() {
    this.prepareData()
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.prepareData()
    }
  }

  prepareData = () => {
    var cycles = this.props.cycles
    // filter weeks for current year
    if (this.props.weeks) {
      var currentYear = moment().format("YYYY")
      var currentWeeks = this.props.weeks.filter((w) => {
        if (this.state.startDate && this.state.startDate > w.endDate)
          return false
        if (this.state.endDate && this.state.endDate < w.startDate) return false
        // check if year matches
        if (
          w.startDate.substr(0, 4) !== currentYear &&
          w.endDate.substr(0, 4) !== currentYear
        )
          return false
        // check if prog exists for week
        for (var i = 0; i < this.props.prog.length; i++) {
          if (
            (this.props.prog[i].tournament.startDate >= w.startDate &&
              this.props.prog[i].tournament.startDate <= w.endDate) ||
            (this.props.prog[i].tournament.endDate >= w.startDate &&
              this.props.prog[i].tournament.endDate <= w.endDate)
          ) {
            w.prog = this.props.prog[i]
          }
        }
        // check if cycle exists for week
        for (var j = 0; j < this.props.cycles.length; j++) {
          if (
            (this.props.cycles[j].startDate >= w.startDate &&
              this.props.cycles[j].startDate <= w.endDate) ||
            (this.props.cycles[j].endDate >= w.startDate &&
              this.props.cycles[j].endDate <= w.endDate) ||
              (this.props.cycles[j].startDate < w.startDate && 
                this.props.cycles[j].endDate > w.endDate)
          ) {
            w.cycle = this.props.cycles[j]
          }
        }
        return true
      })
      this.setState({ weeks: currentWeeks })
    }
    // update columns
    const columns = [
      {
        key: "name",
        visible: true,
        label: this.props.t("Dates and week title"),
        pinned: "left",
        id: "Dates et intitulé de la semaine",
        format: (row) => {
          return (
            <>
              <div className="flex justify-between">
                <Link className="text-indigo-700 font-medium text-xs 2xl:text-sm" to={row.link}>
                  {row.category ? row.category.name + " - " : ""}
                  {row.name ? row.name : ""}
                </Link>
              </div>
              <div className="text-xs 2xl:text-sm">
                {moment(row.startDate).format("dddd D MMMM Y")} au{" "}
                {moment(row.endDate).format("dddd D MMMM Y")}
              </div>
            </>
          )
        },
      },
      {
        key: "prog.classification",
        visible: true,
        label: this.props.t("Tournament"),
        pinned: "left",
        id: "Tournois",
        format: (row) => {
          return (
            <div className="flex justify-between text-xs 2xl:text-sm">
              <Tooltip
                tooltipContent={
                  row.prog && "Classification: " + row.prog.classification
                }
                tooltipContent1={
                  row.prog &&
                  row.prog.tournament.ground &&
                  "Surface: " + row.prog.tournament.ground.name
                }
              >
                {row.prog ? 
                <Link 
                 className="text-indigo-700 font-medium text-xs 2xl:text-sm" 
                 to={row.prog.tournament.tournamentTypeId === "National" ? ("/competitionTennis/nationaldetails/" + row.prog.tournamentId + "/a/b") : ("/competitionTennis/internationaldetails/" + row.prog.tournamentId + "/a/b")}>
                  {row.prog ? row.prog.name : " - "}
                </Link>
                 : " - "
                } 
              </Tooltip>
            </div>
          )
        },
      },
      {
        key: "cycle.name",
        visible: true,
        label: this.props.t("Current cycle"),
        pinned: "left",
        id: "Cycle en cours",
        format: (row) => {
          return (
            <div className="flex justify-between text-xs 2xl:text-sm">
              <Tooltip
                tooltipContent={row.cycle && "Objectifs: "}
                tooltipContent1={row.cycle && row.cycle.physicalTargets}
                tooltipContent2={row.cycle && row.cycle.mentalTargets}
                tooltipContent3={row.cycle && row.cycle.sportingTargets}
              >
                {row.cycle ? (
                  <Link
                    className="text-indigo-700 font-medium text-xs 2xl:text-sm"
                    to={row.cycle.link}
                  >
                    {row.cycle.name}
                  </Link>
                ) : (
                  " - "
                )}
              </Tooltip>
            </div>
          )
        },
      },
    ]
    // save changes
    this.setState({
      columns,
      cycles,
    })
  }

      // date filters changed
      onChangeDates = (startDate, endDate) => {
        if (!startDate && !endDate) return;
        var obj = {};
        if (startDate) obj.startDate = startDate;
        if (endDate) obj.endDate = endDate;
        this.setState(obj, () => {
          this.prepareData()
        });
      }
  

  render() {
    const { t } = this.props
    // var cycles = this.props.cycles
    var columns = this.state.columns
    var weeks = this.state.weeks
    var searchText = this.state.searchText

    if (searchText.length > 2) {
      weeks = weeks.filter((o) => {
        var x =
          (o.category ? o.category.name + " - " : "") +
          (o.name ? o.name + " - " : "") +
          (o.cycle ? o.cycle.name + " - " : "") +
          (o.prog ? o.prog.name + " - " : "") +
          moment(o.startDate).format("dddd D MMMM Y") +
          " au " +
          moment(o.endDate).format("dddd D MMMM Y")
        return x.toLowerCase().includes(searchText.toLowerCase())
      })
    }

    return (
      <>
        <FiltersBar
          components={
            <>
              <FiltersTitle title={weeks ? weeks.length + " " + t("Weeks").toLowerCase() : ""} />
              <FilterSeparator />
              <FiltersSearch
                search={
                  <SearchInput
                    value={this.state.searchText}
                    onChange={(searchText) => this.setState({ searchText })}
                  />
                }
              />
              <FilterSeparator />
              <FilterDate onChange={this.onChangeDates} />
              <FilterSeparator />
              <ExportToCSV
                columns={columns}
                jsonData={weeks}
                fileName={t("Weeks") + ".csv"}
              />
            </>
          }
        />
        <TableSimple columns={columns} data={weeks} />
      </>
    )
  }
}

export default withTranslation()(TableYearsByPlayer)
