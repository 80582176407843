import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import IconRound32x32 from "../components/icons/IconRound32x32";
import moment from "moment";
import FiltersBar from "../components/filters/FiltersBar";
import FiltersTitle from "../components/filters/FiltersTitle";
import { SelectMultiple } from "../components/forms";
import TableSimple from "../components/table/TableSimple";
import FilterSeparator from "../components/filters/FilterSeparator";
import FiltersSearch from "../components/filters/FiltersSearch";
import SearchInput from "../components/forms/input/SearchInput";
import FiltersDropDowns from "../components/filters/FiltersDropDowns";
import FilterColumns from "../components/filters/FilterColumns";
import { Link } from "react-router-dom";
import ExportToCSV from "../components/export/ExportToCSV";
// import SeasonPicker from "../components/forms/input/SeasonPicker";

class TableMatchsResults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      actions: [],
      groups: [],
      selectedGroups: [],
      columns: [],
      searchText: "",
    };
  }

  componentDidMount() {
    this.prepareData();
  }

  getResultColorClass(result) {
    switch (result) {
      case "Win":
        return "text-green-600";
      case "Loss":
        return "text-red-600";
      case "Draw":
        return "text-yellow-600";
      default:
        return "";
    }
  }

  getScoreByResult(result, scoreDom, scoreExt) {
    switch (result) {
      case "Win":
        return `${scoreDom} - ${scoreExt}`;
      case "Loss":
        return `${scoreExt} - ${scoreDom}`;
      case "Draw":
        return `${scoreExt} - ${scoreDom}`;
      default:
        return " - ";
    }
  }

  getResultByColor(row) {
    return (
      <span
        className={`text-xs 2xl:text-sm ${this.getResultColorClass(
          row.result
        )}`}
      >
        {this.getScoreByResult(
          row.result,
          row.scoreFullTimeOpponentTeamDom,
          row.scoreFullTimeOpponentTeamExt
        )}
      </span>
    );
  }

  prepareData = (firstTime) => {
    var matchsInfos = this.props.matchsInfos;
    var groups = [];

    const columns = [
      {
        key: "date",
        label: "Date",
        visible: true,
        format: (row) => (
          <span className="text-xs">
            {moment(row.startDate).format("DD-MM-YYYY")}
          </span>
        ),
      },
      {
        key: "homeTeam",
        label: "Home Team",

        visible: true,
        format: (row) => (
          <>
            {row && row.localization === "Home" ? (
              <>
                <IconRound32x32 photo={row && row.club && row.club.logo} />
                <span>{row && row.club && row.club.name}</span>
              </>
            ) : (
              <>
                <IconRound32x32
                  photo={row && row.opponent && row.opponent.logo}
                />
                <span>{row && row.opponent && row.opponent.name}</span>
              </>
            )}
          </>
        ),
      },
      {
        key: "score",
        label: "Score",
        visible: true,
        filter: {
          name: "SelectColumnFilter",
          type: "includes",
        },
        format: (row) => (
          <>
            <Link
              to={{
                pathname: `/${this.props.sportTypePath}/matchInfos/${row.eventId}`,
                state: {
                  matchInfo: row,
                },
              }}
            >
              {this.getResultByColor(row)}
            </Link>
          </>
        ),
      },
      {
        key: "awayTeam",
        label: "Away Team",
        visible: true,
        format: (row) => (
          <>
            <>
              {row && row.localization === "Away" ? (
                <>
                  <IconRound32x32 photo={row && row.club && row.club.logo} />
                  <span>{row && row.club && row.club.name}</span>
                </>
              ) : (
                <>
                  <IconRound32x32
                    photo={row && row.opponent && row.opponent.logo}
                  />
                  <span>{row && row.opponent && row.opponent.name}</span>
                </>
              )}
            </>
          </>
        ),
      },
      {
        key: "category",
        label: "Category",
        visible: true,
        format: (row) => (
          <>
            {row && row.category ? (
              <span className="text-xs">{row && row.category}</span>
            ) : (
              <span>{row && row.tour && row.tour.id}</span>
            )}
          </>
        ),
      },
    ];

    // after columns added Add local Storage
    var selectedColumns = localStorage.getItem(this.props.savedSelectedColumns);
    if (selectedColumns === null || selectedColumns.length === 0) {
      selectedColumns = [];
      columns.forEach((column) => {
        selectedColumns.push(column.key);
        //  column.visible = true
      });
    } else selectedColumns = selectedColumns.split("|");
    columns.forEach((column) => {
      column.visible = selectedColumns.indexOf(column.key) < 0 ? false : true;
    });

    // selected columns
    if (firstTime) {
      selectedColumns = [];
      columns.forEach((column) => {
        if (column.visible) {
          selectedColumns.push(column.key);
        }
      });
      selectedColumns = selectedColumns.slice(0, 6);
      this.setState({ selectedColumns });
    }

    this.setState({
      columns,
      matchsInfos,
      groups,
      selectedColumns,
      // selectedGroups,
    });
  };

  // change columns
  onChangeSelectedColumns = (res) => {
    this.setState({ selectedColumns: res }, () => {
      // determiner ce qui est selectionne
      let select = [];
      this.state.columns.forEach((a) => {
        if (this.state.selectedColumns.indexOf(a.key) > -1) select.push(a.key);
      });
      // save selected elements to localstorage
      localStorage.setItem(this.props.savedSelectedColumns, select.join("|"));
    });
  };

  render() {
    const { t } = this.props;
    var matchInfos = this.props.matchInfos;
    var columns = this.state.columns;
    var selectedColumns = this.state.selectedColumns;
    var searchText = this.state.searchText;

    if (columns) {
      columns = columns.filter((o) =>
        selectedColumns.some((item) => item === o.key)
      );
    }

    if (searchText.length >= 2) {
      matchInfos = matchInfos.filter(
        (o) =>
          o.name.toLowerCase().includes(searchText.toLowerCase()) ||
          o.category.toLowerCase().includes(searchText.toLowerCase()) ||
          (o.opponent &&
            o.opponent.name.toLowerCase().includes(searchText.toLowerCase())) ||
          moment(o.startDate)
            .format("DD-MM-YYYY")
            .toLowerCase()
            .includes(searchText.toLowerCase())
      );
    }

    return (
      <>
        <FiltersBar
          components={
            <>
              <FiltersTitle
                title={
                  matchInfos
                    ? matchInfos.length + " " + t("Matchs").toLowerCase()
                    : ""
                }
              />
              <FilterSeparator />
               {/* <FilterSeparator /> */}
              {/* <MonthPicker
                className="mx-2 mt-1 2xl:mt-0 h-8 2xl:h-auto w-40 2xl:w-auto rounded-md focus:ring-indigo-500 focus:border-indigo-500 block text-xs 2xl:text-sm border-gray-300"
                value={this.props.seasons}
                onChange={(seasons) => this.props.onChange(seasons)}
              /> */}
              {/* <MonthPicker
                className="mx-2 mt-1 2xl:mt-0 h-8 2xl:h-auto w-40 2xl:w-auto rounded-md focus:ring-indigo-500 focus:border-indigo-500 block text-xs 2xl:text-sm border-gray-300"
                value={this.props.date}
                onChange={(date) => this.props.onChange(date)}
              /> */}
              {/* <SeasonPicker
                className="mx-2 mt-1 2xl:mt-0 h-8 2xl:h-auto w-40 2xl:w-auto rounded-md focus:ring-indigo-500 focus:border-indigo-500 block text-xs 2xl:text-sm border-gray-300"
                value={this.props.date}
                onChange={(date) => this.props.onChange(date)}
              /> */}
              {/* <FilterSeparator /> */}
              <FiltersSearch
                search={
                  <SearchInput
                    value={this.state.searchText}
                    onChange={(searchText) => this.setState({ searchText })}
                  />
                }
              />
              <FilterSeparator />
              <FiltersDropDowns
                selects={[
                  <SelectMultiple
                    options={this.state.groups}
                    keys={this.state.selectedGroups}
                    className="mx-2"
                    fixedLabel={t("Group")}
                  />,
                ]}
              />
              <FilterSeparator />
              <FilterColumns
                columns={
                  <SelectMultiple
                    options={this.state.columns}
                    keys={this.state.selectedColumns}
                    className="mx-2"
                    fixedLabel={t("Active columns")}
                    onChange={this.onChangeSelectedColumns}
                  />
                }
              />
              <FilterSeparator />
              <ExportToCSV
                columns={columns}
                jsonData={matchInfos}
                fileName={t("RugbyMatchs") + ".csv"}
              />
            </>
          }
          buttons={this.props.buttons}
        />
        <TableSimple columns={columns} data={matchInfos} url={true} />
      </>
    );
  }
}

export default withTranslation()(TableMatchsResults);