import React from "react";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import Page from "../../components/page/Page";
import Card from "../../components/card/Card";
import { getPlayer, loadPlayer } from "../../utils/indexedDB/users/handleUser";
import ActionButton from "../../components/buttons/ActionButton";
import moment from "moment";
import Table from "../../components/table/TableSimple";
import Button from "../../components/buttons/Button";
import GeneratedForm from "../../utils/forms/generateForm";
import {
  loadCareer,
  loadCurriculumsPursuedByPlayer,
  loadScholarshipDocumentsByPlayer,
  loadScholarshipReportsByPlayer,
} from "../../utils/indexedDB/scholarship/handleScholarship";
import SVG from "react-inlinesvg";
import SvgEdit from "../../assets/sass/vendors/theme/svg/icons/General/Edit.svg";

class ScholarshipByPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      player: null,
    };
  }

  componentDidMount() {
    var id = this.props.match.params.id;
    getPlayer(id).then((player) => (player ? this.setState({ player }) : null));
    if (navigator.onLine) {
      loadPlayer(id).then((player) =>
        player ? this.setState({ player }) : null
      );
      loadScholarshipDocumentsByPlayer(id).then((documents) =>
        this.setState({ documents })
      );
      loadScholarshipReportsByPlayer(id).then((reports) =>
        this.setState({ reports })
      );
      loadCurriculumsPursuedByPlayer(id).then((curriculumsPursued) =>
        this.setState({ curriculumsPursued })
      );
      loadCareer(id).then((career) => this.setState({ career }));
    }
  }

  render() {
    const { t } = this.props;
    const columnsCurriculumsPursued = [
      {
        key: "school",
        pinned: "left",
        label: t("School"),
        id: "school",
        format: (row) => row.curriculum.school.name,
      },
      {
        key: "name",
        pinned: "left",
        label: t("Name"),
        id: "name",
        format: (row) => row.curriculum.name,
      },
      {
        key: "startDate",
        pinned: "left",
        label: t("Start date"),
        id: "startDate",
        format: (row) => moment(row.startDate).calendar(),
      },
      {
        key: "endDate",
        pinned: "left",
        label: t("End date"),
        id: "endDate",
        format: (row) => moment(row.endDate).calendar(),
      },
      {
        key: "actions",
        pinned: "left",
        label: "",
        format: (row) => (
          <ActionButton
            key={row.id}
            updateForm="updateCurriculumPursued"
            deleteForm="deleteCurriculumPursued"
            formData={row}
            refreshData={() =>
              loadCurriculumsPursuedByPlayer(
                this.state.id
              ).then((curriculumsPursued) =>
                this.setState({ curriculumsPursued })
              )
            }
          />
        ),
      },
    ];

    const columnsScholarshipDocuments = [
      {
        key: "name",
        pinned: "left",
        label: t("Name"),
        id: "name",
        format: (row) => (
          <a
            className="text-primary font-weight-bolder mb-0"
            href={row.filePath}
          >
            {row.name}
          </a>
        ),
      },
      {
        key: "date",
        pinned: "left",
        label: t("Date"),
        id: "date",
        format: (row) => moment(row.date).calendar(),
      },
      {
        key: "actions",
        pinned: "left",
        label: "",
        format: (row) => (
          <ActionButton
            key={row.id}
            updateForm="updateScholarshipDocument"
            deleteForm="deleteScholarshipDocument"
            formData={row}
            refreshData={() =>
              loadScholarshipDocumentsByPlayer(
                this.state.id
              ).then((documents) => this.setState({ documents }))
            }
          />
        ),
      },
    ];

    const columnsScholarshipReports = [
      {
        key: "name",
        pinned: "left",
        label: t("Name"),
        id: "name",
        format: (row) => row.name,
      },
      {
        key: "date",
        pinned: "left",
        label: t("Date"),
        id: "date",
        format: (row) => moment(row.date).calendar(),
      },
      {
        key: "actions",
        pinned: "left",
        label: "",
        format: (row) => (
          <ActionButton
            key={row.id}
            updateForm="updateScholarshipReport"
            deleteForm="deleteScholarshipReport"
            formData={row}
            refreshData={() =>
              loadScholarshipReportsByPlayer(this.state.id).then((reports) =>
                this.setState({ reports })
              )
            }
          />
        ),
      },
    ];

    return (
      <Page
        pageTitle={
          t("Scholarship") +
          (this.state.player
            ? " - " +
              this.state.player.lastName.toUpperCase() +
              " " +
              this.state.player.firstName
            : "")
        }
        backPage="/group/players"
        tab={[
          {
            title: t("Curriculums pursued"),
            visible: true,
            data: this.state.curriculumsPursued,
            component: (data) => {
              return data ? (
                <>
                  <Card
                    className=" "
                    title={t("Curriculums pursued")}
                    headerActions={
                      <GeneratedForm
                        key="createCurriculumPursued"
                        component={(show, text) => (
                          <Button
                            onClick={show}
                            className="btn btn-primary btn-hover-light-primary font-weight-bold btn-shadow"
                            disabled={!navigator.onLine}
                          >
                            <i className="flaticon2-plus"></i> {text}
                          </Button>
                        )}
                        callback={(res) =>
                          loadCurriculumsPursuedByPlayer(
                            this.state.id
                          ).then((curriculumsPursued) =>
                            this.setState({ curriculumsPursued })
                          )
                        }
                        formName="createCurriculumPursued"
                        formData={{ playerId: this.state.id }}
                      />
                    }
                  >
                    <Table columns={columnsCurriculumsPursued} data={data} />
                  </Card>
                </>
              ) : null;
            },
          },
          {
            title: t("Reports"),
            visible: true,
            data: this.state.reports,
            component: (data) => {
              return (
                <>
                  <Card
                    className=" "
                    title={t("Reports")}
                    headerActions={
                      <GeneratedForm
                        key="createScholarshipReport"
                        component={(show, text) => (
                          <Button
                            onClick={show}
                            className="btn btn-primary btn-hover-light-primary font-weight-bold btn-shadow"
                            disabled={!navigator.onLine}
                          >
                            <i className="flaticon2-plus"></i> {text}
                          </Button>
                        )}
                        callback={(res) =>
                          loadScholarshipReportsByPlayer(
                            this.state.id
                          ).then((reports) => this.setState({ reports }))
                        }
                        formName="createScholarshipReport"
                        formData={{ playerId: this.state.id }}
                      />
                    }
                  >
                    <Table
                      columns={columnsScholarshipReports}
                      data={this.state.scholarshipReports}
                    />
                  </Card>
                </>
              );
            },
          },
          {
            title: t("Documents"),
            visible: true,
            data: this.state.documents,
            component: (data) => {
              return (
                <>
                  <Card
                    className=" "
                    title={t("Documents")}
                    headerActions={
                      <GeneratedForm
                        key="createScholarshipDocument"
                        component={(show, text) => (
                          <Button
                            onClick={show}
                            className="btn btn-primary btn-hover-light-primary font-weight-bold btn-shadow"
                            disabled={!navigator.onLine}
                          >
                            <i className="flaticon2-plus"></i> {text}
                          </Button>
                        )}
                        callback={(res) =>
                          loadScholarshipDocumentsByPlayer(
                            this.state.id
                          ).then((documents) => this.setState({ documents }))
                        }
                        formName="createScholarshipDocument"
                        formData={{ playerId: this.state.id }}
                      />
                    }
                  >
                    <Table columns={columnsScholarshipDocuments} data={data} />
                  </Card>
                </>
              );
            },
          },
          {
            title: t("Professional project"),
            visible: true,
            data: this.state.reports,
            component: (data) => {
              return (
                <>
                  <Card
                    className=" card-stretch"
                    title={t("Professional project")}
                    headerActions={
                      <GeneratedForm
                        key="updateCareer"
                        component={(show, text) => (
                          <Button
                            className="btn btn-sm btn-clean btn-icon mr-2"
                            onClick={show}
                            disabled={!navigator.onLine}
                          >
                            <span className="svg-icon svg-icon-md">
                              <SVG src={SvgEdit} title={text} />
                            </span>
                          </Button>
                        )}
                        callback={(res) => this.forceUpdate()}
                        formName="updateCareer"
                        formData={this.state.career}
                      />
                    }
                  >
                    <div className="form-group row my-2">
                      <label className="col-4 col-form-label">
                        {t("Considered job")}
                      </label>
                      <div className="col-8">
                        <span className="form-control-plaintext font-weight-bolder">
                          {this.state.career.consideredJob}
                        </span>
                      </div>
                    </div>
                    <div className="form-group row my-2">
                      <label className="col-4 col-form-label">
                        {t("Comments")}
                      </label>
                      <div className="col-8">
                        <span className="form-control-plaintext font-weight-bolder">
                          {this.state.career.description}
                        </span>
                      </div>
                    </div>
                  </Card>
                </>
              );
            },
          },
        ]}
      />
    );
  }
}

export default withTranslation()(withRouter(ScholarshipByPlayer));
