import React from "react";
import Tooltip from "../typography/Tooltip/Tooltip";
import { withTranslation } from "react-i18next";

class IconButtonAside extends React.Component {
    render() {
        var { selectedModule, module, history, route, t } = this.props
        return (
            
            <button className={selectedModule === module ? "h-10 w-12 dark:bg-indigo-900 dark:text-white rounded-md flex items-center justify-center bg-indigo-100 text-indigo-900" : "h-10 w-12 dark:text-gray-500 rounded-md flex items-center justify-center"}
                onClick={() => {
                    history.push(route);
                }}>
                    <Tooltip tooltipContent={t(module.charAt(0).toUpperCase() + module.slice(1))}>
                        {this.props.children}
                    </Tooltip>
            </button>
        );
    }
}

export default withTranslation()(IconButtonAside);
