import React from "react";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import Page from "../../components/page/Page";
import VideoCard from "../../components/card/VideoCard";
import GeneratedForm from "../../utils/forms/generateForm";
import Button from "../../components/buttons/Button";
import {
  getCollection,
  loadCollection,
  loadVideoTempByCollection,
  getVideoTempByCollection,
} from "../../utils/indexedDB/medias/handleMedia";
import { getStaffs, loadStaffs } from "../../utils/indexedDB/users/handleUser";
import ButtonLight from "../../components/buttons/ButtonLight";
import SVGEdit from "../../components/icons/SVGEdit";
import SVGCreate from "../../components/icons/SVGCreate";

class Videos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
    };
    this.handleVideosTemp = this.handleVideosTemp.bind(this);
    this.handleCollection = this.handleCollection.bind(this);
  }
  componentDidMount() {
    // const script = document.createElement("script");

    // script.src = "//amp.azure.net/libs/amp/2.1.5/azuremediaplayer.min.js";
    // script.async = true;

    // document.body.appendChild(script);

    var id = this.props.match.params.id;
    this.handleCollection(id);
    this.handleVideosTemp(id);
    this.handleStaffs();
  }

  handleStaffs() {
    this.setState({ getStaffsFromAPI: false });
    getStaffs().then(staffs => !this.state.getStaffsFromAPI ? this.setState({ staffs }) : null);
    if (navigator.onLine) {
      loadStaffs().then(staffs => this.setState({ staffs, getStaffsFromAPI: true }));
    }
  }

  handleCollection(id) {
    this.setState({ getCollectionFromAPI: false })
    getCollection(id).then((collection) => !this.state.getCollectionFromAPI ? this.setState({ collection }) : null);
    if (navigator.onLine) {
      loadCollection(id).then((collection) => this.setState({ collection, getCollectionFromAPI: true }));
    }
  }

  handleVideosTemp(id) {
    this.setState({ getVideoTempFromAPI: false })
    getVideoTempByCollection(id).then((videoTemp) => {
      if (videoTemp && videoTemp.length && !this.state.getVideoTempFromAPI) {

        this.setState({
          videoTemp: videoTemp.filter((v) => v.state !== "Completed"),
        });
      }
    });

    if (navigator.onLine) {
      loadVideoTempByCollection(id).then((videoTemp) => {
        if (videoTemp && videoTemp.length) {
          this.setState({
            videoTemp: videoTemp.filter((v) => v.state !== "Completed"),
            getVideoTempFromAPI: true
          });
        }
      });
    }
  }

  render() {
    const { t } = this.props;

    return (
      <Page
        backPage="/collections"
        pageTitle={
          this.state.collection
            ? this.state.collection.name +
            " - " +
            t("Videos list") +
            (this.state.collection.files
              ? " (" + this.state.collection.files.length + ")"
              : "")
            : ""
        }
        tab={[
          {
            title: t("Videos"),
            visible: true,
            data: this.state.collection ? this.state.collection.files : null,
            component: (data) => {
              return (
                <div className="my-4 2xl:my-8 flex flex-wrap mx-8 gap-4 2xl:gap-8 p-3 2xl:p-6 mb-10">
                  {this.state.videoTemp && this.state.videoTemp.length > 0 ? (
                    this.state.videoTemp.map((video, key) => (
                      <VideoCard video={video} author={this.state.staffs ? this.state.staffs.find(s => s.id === video.authorId) : null} isVideoTemp={true} handleVideosTemp={() => this.handleVideosTemp(this.state.id)} key={key} />
                    ))
                  ) : null}
                  {this.state.collection
                    ? this.state.collection.files.map((video, key) => (
                      <VideoCard video={video} author={this.state.staffs ? this.state.staffs.find(s => s.id === video.authorId) : null} handleVideos={() => this.handleCollection(this.state.id)} isVideoTemp={false} key={key} />
                    ))
                    : null}
                </div>
              );
            },
          },
        ]}
        actions={{
          other: [
            <GeneratedForm
              key="createVideo"
              title={t("Add video")}
              component={(show, text) => (
                <Button onClick={show} style={{ marginRight: "5px" }}>
                  <SVGCreate />
                </Button>
              )}
              method="POST"
              formName="createVideo"
              formData={{ collectionId: this.state.id }}
              callback={() => this.handleVideosTemp(this.state.id)}
            />,
            <GeneratedForm
              key="updateCollection"
              title={t("Update")}
              component={(show, text) => (
                <ButtonLight onClick={show} style={{ marginRight: "5px" }}>
                  <SVGEdit />
                </ButtonLight>
              )}
              method="PATCH"
              formName="updateCollection"
              formData={this.state.collection}
              callback={() =>
                this.handleCollection(this.state.id)
              }
            />,
            // <GeneratedForm
            //   key="deleteCollection"
            //   title={t("Delete")}
            //   component={(show, text) => <Button onClick={show}>{text}</Button>}
            //   method="DELETE"
            //   callback={() => this.props.history.goBack()}
            //   formName="deleteCollection"
            //   formData={this.state.collection}
            // />,
          ],
        }}
      />
    );
  }
}

export default withTranslation()(withRouter(Videos));
