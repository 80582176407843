import React from "react";
import { withTranslation } from "react-i18next";

// INPUT
// * key
// * onChange => (value)
// type = "button", "checkbox", "color", "date", "datetime-local", "email", "file", "number", "image", "month", "password", "radio", "range", "reset", "tel", "time", "url", "week"
// checked (bool)
// "formaction" (for image type)
// disabled
// accept (type = file) = corresponds to the Extension
// max
// min
// maxlength
// minlength
// pattern (regex)
// required
// placeholder
// src (type=image)
// value
// width (type = image)

class SearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value ? this.props.value : "",
    };
    this.onInputChange = this.onInputChange.bind(this);
  }

  onInputChange(event) {
    var value = event.target.value;
    this.props.onChange(value);
    this.setState({ value });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value });
    }
  }

  render() {
    var { onChange, className, type, ...newProps } = this.props;
    return (
        <div className="flex-1 px-2 flex justify-center lg:ml-1 my-1 lg:justify-end">
        <div className="max-w-xs w-full">
          <label for="search" className="sr-only">Search</label>
          <div className="relative text-light-blue-100 focus-within:text-gray-400">
            <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
              <svg className="flex-shrink-0 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
              </svg>
            </div>
            <input 
            onChange={this.onInputChange}
            type={type ? type : "text"}
            value={
            this.state.value && this.state.value !== null ? this.state.value : ""
            }
             id="search" name="search" 
              {...newProps}
             className="block w-full bg-light-gray-700 bg-opacity-50 py-1 2xl:py-2 pl-10 pr-3 border border-gray-300 rounded-md leading-5 placeholder-light-blue-100 focus:outline-none focus:bg-white focus:ring-indigo-700 focus:border-indigo-500 focus:placeholder-gray-500 focus:text-gray-900 text-xs 2xl:text-sm" placeholder={this.props.t("Search")} />
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(SearchInput);