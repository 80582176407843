import db from "./db";
import get from "../../requests/get";

async function loadTrainingSequences(eventId, template = false) {
  let feature = "trainingSequencesByEvent"
  let database = db.trainingSequences
  if(template) {
    feature = "trainingSessionTemplateSequencesByTemplate"
    database = db.trainingSequencesTemplate
  }
  return get("training", feature, eventId).then((res) => {
    getTrainingSequences(eventId, template).then(indexedDBres => {
      var promises = [];
      if (indexedDBres && indexedDBres.length) {
        indexedDBres.forEach(r => {
          promises.push(database.delete(r.id));
        })
      }
      Promise.all(promises).then(() => {
        if (res) {
          database.bulkPut(res);
        }
      })
    });
    return res;
  });
}

function getTrainingSequences(eventId, template = false) {
  let database = db.trainingSequences
  if(template) {
    database = db.trainingSequencesTemplate
  }
  if (database.toArray()) {
    return template ? db.trainingSequencesTemplate.where({ trainingSessionTemplateId: eventId }).toArray() : db.trainingSequences.where({ eventId: eventId }).toArray();
  }
}

function loadTrainingCategories() {
  return get("training", "trainingCategories").then((res) => {
    getTrainingCategories().then(indexedDBres => {
      var promises = [];
      if (indexedDBres && indexedDBres.length) {
        indexedDBres.forEach(r => {
          promises.push(db.trainingCategories.delete(r.id));
        })
      }
      Promise.all(promises).then(() => {
        if (res) {
          db.trainingCategories.bulkPut(res);
        }
      })
    });
    return res;
  });
}

function getTrainingCategories() {
  return db.trainingCategories.toArray();
}

async function loadPhysicalSequences(eventId, template=false) {
  let feature = "physicalSequencesByEvent"
  let database = db.physicalSequences
  if(template) {
    feature = "physiqueSessionTemplateSequencesByTemplate"
    database = db.physicalSequencesTemplate
  }
  return get("training", feature, eventId).then((res) => {
    getPhysicalSequences(eventId, template).then(indexedDBres => {
      var promises = [];
      if (indexedDBres && indexedDBres.length) {
        indexedDBres.forEach(r => {
          promises.push(database.delete(r.id));
        })
      }
      Promise.all(promises).then(() => {
        if (res) {
          database.bulkPut(res);
        }
      })
    });
    return res;
  });
}

function getPhysicalSequences(eventId, template=false) {
  let database = db.physicalSequences
  if(template) {
    database = db.physicalSequencesTemplate
  }
  if (database.toArray()) {
    return template ? db.physicalSequencesTemplate.where({ physicalSessionTemplateId: eventId }).toArray() : db.physicalSequences.where({ eventId: eventId }).toArray();
  }
}

function loadPhysicalCategories() {
  return get("training", "physicalCategories").then((res) => {
    getPhysicalCategories().then(indexedDBres => {
      var promises = [];
      if (indexedDBres && indexedDBres.length) {
        indexedDBres.forEach(r => {
          promises.push(db.physicalCategories.delete(r.id));
        })
      }
      Promise.all(promises).then(() => {
        if (res) {
          db.physicalCategories.bulkPut(res);
        }
      })
    });
    return res;
  });
}

function getPhysicalCategories() {
  return db.physicalCategories.toArray();
}

async function loadTrainingDrills(authors) {
  return get("training", "trainingDrills").then((res) => {
    if (res) {
      res = res.map((ex) => {
        let author = authors.find((a) => a.id === ex.authorId)
        ex.author = author ? author : null;
        return ex;
      });
      db.trainingDrills.bulkPut(res);
    }
    res.sort((a, b) =>
      a.trainingTheme.trainingCategory.name === b.trainingTheme.trainingCategory.name ? (
        a.trainingTheme.name === b.trainingTheme.name ? (
          a.name === b.name ? 0 : a.name > b.name ? 1 : -1
        ) : a.trainingTheme.name > b.trainingTheme.name ? 1 : -1
      ) : a.trainingTheme.trainingCategory.name > b.trainingTheme.trainingCategory.name ? 1 : -1
    )
    return res;
  });
}

function getTrainingDrills() {
  return db.trainingDrills.toArray();
}

async function loadTrainingDrill(id, authors, themes) {
  return get("training", "trainingDrills", id).then((res) => {
    if (res) {
      let author = authors.find((a) => a.id === res.authorId)
      let theme = themes.find((a) => a.id === res.traningThemeId)
      res.author = author ? author : null;
      res.trainingTheme = theme ? theme : null;
      db.trainingDrills.put(res);
    }
    return res;
  });
}

function getTrainingDrill(id) {
  return db.trainingDrills.get(id);
}

async function loadTrainingDrillsThemes() {
  return get("training", "trainingThemes").then((res) => {
    if (res) {
      db.trainingThemes.bulkPut(res);
    }
    return res;
  });
}

function getTrainingDrillsThemes() {
  return db.trainingThemes.toArray();
}

function getTrainingDrillsTheme(id) {
  return db.trainingThemes.get(id);
}

async function loadTrainingDrillsByGroup(id, authors) {
  return get("training", "trainingDrillsByGroup", id).then((res) => {
    if (res) {
      res = res.map((ex) => {
        let author = authors.find((a) => a.id === ex.authorId)
        ex.author = author ? author : null;
        return ex;
      });
      db.trainingDrills.bulkPut(res);
      res.sort((a, b) =>
        a.trainingTheme.trainingCategory.name === b.trainingTheme.trainingCategory.name ? (
          a.trainingTheme.name === b.trainingTheme.name ? (
            a.name === b.name ? 0 : a.name > b.name ? 1 : -1
          ) : a.trainingTheme.name > b.trainingTheme.name ? 1 : -1
        ) : a.trainingTheme.trainingCategory.name > b.trainingTheme.trainingCategory.name ? 1 : -1
      )
    }
    return res;

  });
}

function getTrainingDrillsByGroup(groupId) {
  if (db.trainingDrills.toArray()) {
    return db.trainingDrills.where({ groupId }).toArray()
  }
}

async function loadPhysicalDrillsThemes() {
  return get("training", "physicalThemes").then((res) => {
    if (res) {
      db.physicalThemes.bulkPut(res);
    }
    return res;
  });
}

function getPhysicalDrillsThemes() {
  return db.physicalThemes.toArray();
}

async function loadPhysicalDrillsByGroup(id, authors) {
  return get("training", "physicalDrillsByGroup", id).then((res) => {
    if (res) {
      res = res.map((ex) => {
        let author = authors.find((a) => a.id === ex.authorId)
        ex.author = author ? author : null;
        return ex;
      });
      db.physicalDrills.bulkPut(res);
      res.sort((a, b) =>
        a.physicalTheme.physicalCategory.name === b.physicalTheme.physicalCategory.name ? (
          a.physicalTheme.name === b.physicalTheme.name ? (
            a.name === b.name ? 0 : a.name > b.name ? 1 : -1
          ) : a.physicalTheme.name > b.physicalTheme.name ? 1 : -1
        ) : a.physicalTheme.physicalCategory.name > b.physicalTheme.physicalCategory.name ? 1 : -1
      )
    }
    return res;

  });
}

function getPhysicalDrillsByGroup(groupId) {
  if (db.physicalDrills.toArray()) {
    return db.physicalDrills.where({ groupId }).toArray();
  }
}

function getPhysicalDrill(id) {
  return db.physicalDrills.get(id);
}

async function loadTrainingSessionTemplates() {
  return get("training", "trainingSessionTemplates").then((res) => {
    if (res) {
      res = res.map((r) => {
        let minutes = (((r.duration / 60) % 1) * 60).toFixed(0)
        r.durationHourly = Math.trunc(r.duration / 60) + "H" + (minutes < 10 ? "0" + minutes : minutes)
        r.sequences.map((s) => {
          s.trainingThemeName = s.trainingTheme.name
          return s
        })
        return r;
      })
      db.trainingSessionTemplates.bulkPut(res);
    }
    return res;
  });
}

function getTrainingSessionTemplates() {
  return db.trainingSessionTemplates.toArray();
}

async function loadPhysicalSessionTemplates() {
  return get("training", "physicalSessionTemplates").then((res) => {
    if (res) {
      res = res.map((r) => {
        let minutes = (((r.duration / 60) % 1) * 60).toFixed(0)
        r.durationHourly = Math.trunc(r.duration / 60) + "H" + (minutes < 10 ? "0" + minutes : minutes)
        r.sequences.map((s) => {
          s.physicalThemeName = s.physicalTheme.name
          return s
        })
        return r;
      })
      db.physicalSessionTemplates.bulkPut(res);
    }
    return res;
  });
}

function getPhysicalSessionTemplates() {
  return db.physicalSessionTemplates.toArray();
}

async function loadTrainingSessionTemplate(id) {
  return get("training", "trainingSessionTemplates", id).then((res) => {
    if (res) {
      let minutes = (((res.duration / 60) % 1) * 60).toFixed(0)
      res.durationHourly = Math.trunc(res.duration / 60) + "H" + (minutes < 10 ? "0" + minutes : minutes)
      res.sequences.map((s) => {
        s.trainingThemeName = s.trainingTheme.name
        return s
      })
      db.trainingSessionTemplates.put(res);
    }
    return res;
  });
}

function getTrainingSessionTemplate(id) {
  return db.trainingSessionTemplates.get(id);
}

async function loadPhysicalSessionTemplate(id) {
  return get("training", "physicalSessionTemplates", id).then((res) => {
    if (res) {
      let minutes = (((res.duration / 60) % 1) * 60).toFixed(0)
      res.durationHourly = Math.trunc(res.duration / 60) + "H" + (minutes < 10 ? "0" + minutes : minutes)
      res.sequences.map((s) => {
        s.physicalThemeName = s.physicalTheme.name
        return s
      })
      db.physicalSessionTemplates.put(res);
    }
    return res;
  });
}

function getPhysicalSessionTemplate(id) {
  return db.physicalSessionTemplates.get(id);
}

export {
  loadTrainingSequences,
  getTrainingSequences,
  getTrainingCategories,
  loadTrainingCategories,
  loadPhysicalSequences,
  getPhysicalSequences,
  loadPhysicalCategories,
  getPhysicalCategories,
  loadTrainingDrills,
  getTrainingDrills,
  loadTrainingDrill,
  getTrainingDrill,
  loadTrainingDrillsThemes,
  getTrainingDrillsThemes,
  getTrainingDrillsTheme,
  loadTrainingDrillsByGroup,
  getTrainingDrillsByGroup,
  loadPhysicalDrillsThemes,
  getPhysicalDrillsThemes,
  loadPhysicalDrillsByGroup,
  getPhysicalDrillsByGroup,
  getPhysicalDrill,
  loadTrainingSessionTemplates,
  getTrainingSessionTemplates,
  loadPhysicalSessionTemplates,
  getPhysicalSessionTemplates,
  loadTrainingSessionTemplate,
  getTrainingSessionTemplate,
  loadPhysicalSessionTemplate,
  getPhysicalSessionTemplate
};
