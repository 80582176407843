import React from "react";
import ActionButton from "../components/buttons/ActionButton";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { SelectMultiple } from "../components/forms";
import TableSimple from "../components/table/TableSimple";
import moment from "moment";
import FiltersSearch from "../components/filters/FiltersSearch";
import SearchInput from "../components/forms/input/SearchInput";
import FilterColumns from "../components/filters/FilterColumns";
import ExportToCSV from "../components/export/ExportToCSV";
import FilterSeparator from "../components/filters/FilterSeparator";
import FiltersTitle from "../components/filters/FiltersTitle";
import FiltersBar from "../components/filters/FiltersBar";

class TableCyclesByPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actions: [],
      columns: [],
      searchText: "",
    };
  }

  componentDidMount() {
    this.prepareData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.prepareData();
    }
  }

  prepareData = () => {
    var cycles = this.props.cycles;

    const columns = [
      {
        key: "name",
        visible: true,
        label: this.props.t("Name"),
        pinned: "left",
        id: "name",
        format: (row) => {
          return (
            <div className="flex justify-between">
              <Link
                className="text-indigo-700 font-medium text-xs 2xl:text-sm"
                to={"/training/cycleByPlayer/" + row.id}
              >
                {row.name}
              </Link>
              <ActionButton
                key={row.id}
                updateForm="updateTennisCycle"
                deleteForm="deleteTennisCycle"
                formData={row}
                refreshData={this.props.refresh}
              />
            </div>
          );
        },
      },
      {
        key: "startDate",
        visible: true,
        label: this.props.t("Start date"),
        pinned: "left",
        id: "startDate",
        format: (row) => {
          return <><span className=" text-xs 2xl:text-sm">{moment(row.startDate).calendar()}</span></>;
        },
      },
      {
        key: "endDate",
        visible: true,
        label: this.props.t("End date"),
        pinned: "left",
        id: "endDate",
        format: (row) => {
          return <><span className=" text-xs 2xl:text-sm">{moment(row.endDate).calendar()}</span></>;
        },
      },
    ];

    var selectedColumns = localStorage.getItem("TableCyclesByPlayer_selectedColumns")
    if (selectedColumns === null || selectedColumns.length === 0)  selectedColumns = ["name", "startDate", "endDate"]
    else selectedColumns = selectedColumns.split("|")
    columns.forEach((column) => {
      column.visible = selectedColumns.indexOf(column.key) < 0 ? false : true 

    });
    this.setState({
      columns,
      cycles,
      selectedColumns,
    });
  };

    // change columns
    onChangeSelectedColumns = (res) => {
      this.setState({ selectedColumns: res }, () => {
        // determiner ce qui est selectionne
        let select = []
        this.state.columns.forEach((a) => {
          if (this.state.selectedColumns.indexOf(a.key) > -1 ) select.push(a.key)
        })
        // save selected elements to localstorage
        localStorage.setItem("TableCyclesByPlayer_selectedColumns", select.join("|"))
      })
    }

  render() {
    const { t } = this.props;
    var cycles = this.props.cycles;
    var columns = this.state.columns;
    var selectedColumns = this.state.selectedColumns;
    var searchText = this.state.searchText;
    if (columns) {
      columns = columns.filter((o) =>
        selectedColumns.some((item) => item === o.key)
      );
    }

    if (searchText.length > 2) {
      cycles = cycles.filter((o) =>
        o.name.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    return (
      <>
        <FiltersBar
          components={
            <>
              <FiltersTitle title={cycles ? cycles.length + " " + t("Cycles").toLowerCase() : ""} />
              <FilterSeparator />
              <FiltersSearch search={<SearchInput
                value={this.state.searchText}
                onChange={(searchText) => this.setState({ searchText })}
              />} />
              <FilterSeparator />
              <FilterColumns
                columns={
                  <SelectMultiple
                    options={this.state.columns}
                    keys={this.state.selectedColumns}
                    className="mx-2"
                    onChange={this.onChangeSelectedColumns}
                    fixedLabel={t("Active columns")}
                  />
                }
              />
              <FilterSeparator />
              <ExportToCSV columns={columns} jsonData={cycles} fileName={t("Cycles") + ".csv"} />
            </>
          }
          buttons={this.props.buttons}
        />
        <TableSimple columns={columns} data={cycles} />
      </>
    );
  }
}

export default withTranslation()(TableCyclesByPlayer);
