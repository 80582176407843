import Dexie from "dexie";

const db = new Dexie("Core");
db.version(1).stores({ ageGroups: "++id" });
db.version(1).stores({ bodyZones: "++id" });
db.version(1).stores({ colors: "++id" });
db.version(1).stores({ operators: "++id" });
db.version(1).stores({ interpretations: "++id" });
db.version(1).stores({ countries: "++id" });
db.version(1).stores({ orgs: "++id" });
db.version(1).stores({ sectors: "++id" });
db.version(1).stores({ sites: "++id" });
db.version(1).stores({ tennisClubs: "++id" });
db.version(1).stores({ tennisRegions: "++id" });

export default db;
