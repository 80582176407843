import React from "react";
import { withTranslation } from "react-i18next";
import * as Drawer from "../../../components/drawer";
import Button from "../../../components/buttons/Button"
import { DatePicker } from "../../../components/forms";
import Checkbox from "../../../components/forms/input/Checkbox";
import moment from "moment";
import { del, post } from "../../requests";
import { loadAttendeesByEvent, loadOrganizersByEvent } from "../../indexedDB/events/handleEvents"
import cogoToast from "cogo-toast";
import Loading from "../../../components/loaders/Loading";

class BulkCopyDeletion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: null,
            endDate: null,
            events: [],
            eventsSelected: [],
            copyInvitation: true,
            copyStartDate: null,
            loading: false
        }
        this.handleCopy = this.handleCopy.bind(this);
        this.handleDeletion = this.handleDeletion.bind(this);
    }

    getEvents(startDate, endDate) {
        this.setState({ loading: true });
        if (this.props.getEventsFromAPI && startDate && endDate) {
            var start = moment.isMoment(startDate) ? startDate : moment(startDate);
            if (start.isValid()) {
                start = start.subtract(1, 'days').endOf('day').format("YYYY-MM-DDTHH:mm:ss");
            }
            var end = moment.isMoment(endDate) ? endDate : moment(endDate);
            if (end.isValid()) {
                end = end.add(1, 'days').startOf('day').format("YYYY-MM-DDTHH:mm:ss");
            }
            this.props
                .getEventsFromAPI(start, end)
                .then((eventsDB) => {
                    if (eventsDB && eventsDB.length) {
                        // get attendees and organizers per event
                        var promises = [];
                        eventsDB.forEach(e => {
                            var promiseEvent = [];
                            promiseEvent.push(loadAttendeesByEvent(e.id));
                            promiseEvent.push(loadOrganizersByEvent(e.id));
                            promises.push(Promise.all(promiseEvent).then(([att, org]) => {
                                e.organizers = org;
                                e.attendees = att;
                                return e;
                            }))
                        })
                        Promise.all(promises).then(events => {
                            events = events.filter(e => e.specificInfos || e.specificInfos === null);
                            this.setState({ events, eventsSelected: events.map(e => e.id), loading: false });
                        })

                    }
                });
        } else {
            this.setState({ loading: false, err: [this.props.t("Error on get events")] });
        }
    }

    handleCopy(hide) {
        this.setState({ loading: true });
        var events = this.state.events;
        if (events && events.length && this.state.copyStartDate && this.state.startDate) {
            var promises = [];
            var startDate = moment(this.state.startDate);
            var copyStartDate = moment(this.state.copyStartDate);
            var diff = copyStartDate.diff(startDate, 'days');

            events.forEach(ev => {
                if (this.state.eventsSelected.includes(ev.id)) {
                    var newEvent = ev;
                    newEvent.startDate = moment(ev.startDate).add(diff, 'days').format("YYYY-MM-DDTHH:mm:ss");
                    newEvent.endDate = moment(ev.endDate).add(diff, 'days').format("YYYY-MM-DDTHH:mm:ss");
                    delete newEvent.id;
                    if (newEvent.specificInfos && newEvent.specificInfos.id) {
                        delete newEvent.specificInfos.id;
                    }
                    promises.push(post("schedule", "events", newEvent).then(res => {
                        if (res.ok) {
                            return res.json()
                        } else {
                            cogoToast.error(newEvent.name + "-" + this.props.t("Copy Event Error"), { position: "bottom-right" });
                            throw new Error(res.status)
                        }
                    }).then(res => {
                        newEvent.id = res.id;
                        var promisesAttOrg = [];
                        if (ev.organizers && ev.organizers.length) {
                            ev.organizers.forEach(o => {
                                var invit = Object.assign({}, o);
                                invit.eventId = newEvent.id;
                                delete invit.id;
                                delete invit.date;
                                promisesAttOrg.push(post("schedule", "organizers", invit).then(res => {
                                    if (res.ok) {
                                        return true;
                                    } else {
                                        cogoToast.error(newEvent.name + "-" + this.props.t("Copy Organizers Error"), { position: "bottom-right" });
                                        return false;
                                    }
                                }));
                            })
                        }
                        if (ev.attendees && ev.attendees.length) {
                            ev.attendees.forEach(o => {
                                var invit = Object.assign({}, o);
                                invit.eventId = newEvent.id;
                                invit.isExcluded = false;
                                invit.isLate = false;
                                invit.isMissing = false;
                                invit.isMissingWithJustification = false;
                                delete invit.id;
                                delete invit.date;
                                promisesAttOrg.push(post("schedule", "attendees", invit).then(res => {
                                    if (res.ok) {
                                        return true;
                                    } else {
                                        cogoToast.error(newEvent.name + "-" + this.props.t("Copy Attendees Error"), { position: "bottom-right" });
                                        return false;
                                    }
                                }));
                            })
                        }
                        return Promise.all(promisesAttOrg).then(() => {
                            var failed = promisesAttOrg.find(res => res === false);
                            if (!(failed && failed.length)) {
                                cogoToast.success(newEvent.name + "-" + this.props.t("Copy Successed"), { position: "bottom-right" });
                            }
                            return newEvent
                        });
                    }).catch(err => {
                        this.setState({ errors: [err.toString()], loading: false });
                    }));
                }
            })

            Promise.all(promises).then(() => {
                this.props.callback();
                this.setState({
                    startDate: null,
                    endDate: null,
                    events: [],
                    eventsSelected: [],
                    copyInvitation: true,
                    copyStartDate: null,
                    loading: false
                })
                hide();
            });
        } else {
            this.setState({
                startDate: null,
                endDate: null,
                events: [],
                eventsSelected: [],
                copyInvitation: true,
                copyStartDate: null,
                loading: false
            });
            hide();
        }
    }

    handleDeletion(hide) {
        this.setState({ loading: true });
        var events = this.state.events;
        if (events && events.length) {
            var listEventsIds = {
                eventIds: events.map(e => e.id)
            }
            del("schedule", "events", listEventsIds).then(res => {
                if (res.ok) {
                    cogoToast.success(this.props.t("Delete x events successfully", { x: events.length }), { position: "bottom-right" });
                    this.setState({
                        startDate: null,
                        endDate: null,
                        events: [],
                        eventsSelected: [],
                        copyInvitation: true,
                        copyStartDate: null,
                        loading: false
                    })
                    this.props.callback();
                    hide();
                }
            })
        } else {
            this.setState({
                startDate: null,
                endDate: null,
                events: [],
                eventsSelected: [],
                copyInvitation: true,
                copyStartDate: null,
                loading: false
            })
            hide();
        }
    }

    render() {
        const { t } = this.props;
        const columns = [
            {
                id: "checkbox",
                format: (row) => (
                    <Checkbox
                        value={this.state.eventsSelected.includes(row.id)}
                        onChange={() => {
                            var selected = this.state.eventsSelected;
                            if (selected.includes(row.id)) {
                                selected = selected.filter((x) => x !== row.id);
                            } else {
                                selected.push(row.id);
                            }
                            this.setState({ eventsSelected: selected });
                        }}
                    />
                ),
            },
            {
                id: "name"
            },
            {
                id: "type",
                format: (row) => row.type ? row.type.name : row.typeId
            },
            {
                id: "dates",
                format: (row) => <>{row.startDate} - {row.endDate}</>
            },
        ];
        return (
            <Drawer.Layout
                id="event-form"
                component={(show) => this.props.component(show, t(this.props.title))}
            >
                {(hide) =>
                    <>
                        <Drawer.Header hide={hide}>{this.props.method === "COPY" ? t("Bulk copy") : t("Bulk deletion")}</Drawer.Header>
                        <Drawer.Body>
                            <div className="row">
                                <div className="col-md-6 form-group questionContainer">
                                    <label>
                                        {t("Start date")}*
                                        </label>
                                    <DatePicker className="form-control form-control-solid" value={this.state.startDate} onChange={(value) => {
                                        this.setState({ startDate: value });
                                        this.getEvents(value, this.state.endDate);
                                    }} />
                                </div>
                                <div className="col-md-6 form-group questionContainer">
                                    <label>
                                        {t("End date")}*
                                        </label>
                                    <DatePicker className="form-control form-control-solid" value={this.state.endDate} onChange={(value) => {
                                        this.setState({ endDate: value });
                                        this.getEvents(this.state.startDate, value);
                                    }} />
                                </div>
                            </div>
                            <label className="mt-3">
                                {t("Events")} :
                                </label>
                            <div className="table-scrollable-sm">
                                <table className="table table-hover">
                                    {this.state.events && this.state.events.length ? (
                                        <tbody>
                                            {this.state.events.map((row, key) => {
                                                return row && row != null ? (
                                                    <tr key={key}>
                                                        {columns.map((column, keyColumn) => {
                                                            const value = column.format ? row : row[column.id];
                                                            return (
                                                                <td
                                                                    key={keyColumn}
                                                                    style={column.width ? { width: column.width } : {}}
                                                                >
                                                                    {column.format ? column.format(value) : value}
                                                                </td>
                                                            );
                                                        })}
                                                    </tr>
                                                ) : null;
                                            })}
                                        </tbody>
                                    ) : <>{this.state.loading ? <Loading /> : (this.state.startDate && this.state.endDate ? t("No results") : t("Please select a starting date and an ending date"))}</>}
                                </table>
                            </div>
                            {this.props.method === "COPY" ?
                                <>
                                    <div className="form-group questionContainer mt-3">
                                        <label>
                                            {t("Copy start date")}*
                                        </label>
                                        <DatePicker className="form-control form-control-solid" value={this.state.copyStartDate} onChange={(value) => {
                                            this.setState({ copyStartDate: value });
                                        }} />
                                    </div>
                                </>
                                : null
                            }
                        </Drawer.Body>
                        <Drawer.Footer>
                            {this.props.method === "COPY" ?
                                <Button
                                    loading={this.state.loading}
                                    onClick={() => {
                                        this.handleCopy(hide);
                                    }}
                                >
                                    {t("Copy")}
                                </Button>
                                : <Button
                                    loading={this.state.loading}
                                    onClick={() => {
                                        this.handleDeletion(hide);
                                    }}
                                >
                                    {t("Delete all")}
                                </Button>}

                        </Drawer.Footer>
                    </>
                }
            </Drawer.Layout>
        )
    }
}

export default withTranslation()(BulkCopyDeletion);