import React from "react";
import ActionButton from "../components/buttons/ActionButton";
import { withTranslation } from "react-i18next";
import TableSimple from "../components/table/TableSimple";
import moment from "moment";
import { SelectMultiple } from "../components/forms";
import { Link } from "react-router-dom";
import IconRound32x32 from "../components/icons/IconRound32x32";
import FiltersSearch from "../components/filters/FiltersSearch";
import SearchInput from "../components/forms/input/SearchInput";
import FilterColumns from "../components/filters/FilterColumns";
import FiltersDropDowns from "../components/filters/FiltersDropDowns";
import FilterSeparator from "../components/filters/FilterSeparator";
import FiltersTitle from "../components/filters/FiltersTitle";
import FiltersBar from "../components/filters/FiltersBar";

class TableCurriculumsPursued extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actions: [],
      curriculums: [],
      selectedCurriculums: [],
      schools: [],
      selectedSchools: [],
      players: [],
      selectedPlayers: [],
      columns: [],
      searchText: "",
    };
  }

  componentDidMount() {
    this.prepareData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.prepareData();
    }
  }

  prepareData = () => {
    var curriculumsPursued = this.props.curriculumsPursued;
    var curriculums = [];
    var selectedCurriculums = [];
    var schools = [];
    var selectedSchools = [];
    var players = [];
    var selectedPlayers = [];
    if (curriculumsPursued) {
        curriculumsPursued = curriculumsPursued.map((curriculumPursued) => {
        if (curriculums && !curriculums.find((o) => o.key === curriculumPursued.tennisClubId)) {
            curriculums.push({
            key: curriculumPursued.curriculum.id,
            label: curriculumPursued.curriculum.shortName,
          });
          selectedCurriculums.push(curriculumPursued.curriculum.id);
        }
        if (schools && !schools.find((o) => o.key === curriculumPursued.curriculum.school.id)) {
            schools.push({
                key: curriculumPursued.curriculum.school.id,
                label: curriculumPursued.curriculum.school.name,
            });
            selectedSchools.push(curriculumPursued.curriculum.school.id);
        }
        if (players && !players.find((o) => o.key === curriculumPursued.player.id)) {
            players.push({
                key: curriculumPursued.player.id,
                label: curriculumPursued.player.name,
            });
            selectedPlayers.push(curriculumPursued.player.id);
        }
        return curriculumPursued;
      });
    }
    

    const columns = [
        {
            key: "player.name",
            visible: true,
            label: this.props.t("Player"),
            pinned: "left",
            id: "displayName",
            format: (row) => {
              return (
                <span className="flex justify-between">
                  <Link
                    className="text-indigo-700 font-medium pr-2 text-xs 2xl:text-sm"
                    to={"/group/player/" + row.player.id}
                  >
                    <IconRound32x32 photo={row.player.photo} />
                    <span className="ml-5">
                      {row.player.lastName.toUpperCase() + " " + row.player.firstName}
                    </span>
                  </Link>
                </span>
              );
            },
          },
          {
            key: "curriculum.shortName",
            visible: true,
            label: this.props.t("Curriculum"),
            pinned: "left",
            id: "name",
            format: (row) => {
              return (
                <>
                {row.curriculum ? (
                  <Link
                    className="text-indigo-700 font-medium text-xs 2xl:text-sm"
                    to={"/administration/curriculum/" + row.curriculum.id}
                  >
                    {row.curriculum.shortName}
                  </Link>) : "" }
                </>
              );
            },
          },
          {
            key: "curriculum.school.name",
            visible: true,
            label: this.props.t("School"),
            pinned: "left",
            id: "school",
            format: (row) => {
              return (
                <>
                  {row.curriculum.school ? (
                    <Link
                      className="text-indigo-700 font-medium text-xs 2xl:text-sm"
                      to={"/administration/school/" + row.curriculum.schoolId}
                    >
                      {row.curriculum.school.name}
                    </Link>
                  ) : (
                    "-"
                  )}
                </>
              );
            },
          },
      {
        key: "startDate",
        visible: true,
        pinned: "left",
        label: this.props.t("Start"),
        id: "startDate",
        format: (row) => <span className="text-xs 2xl:text-sm">{moment(row.startDate).calendar()}</span>,
      },
      {
        key: "endDate",
        visible: true,
        pinned: "left",
        label: this.props.t("End"),
        id: "endDate",
        format: (row) => <span className="text-xs 2xl:text-sm">{moment(row.endDate).calendar()}</span>,
      },
      {
        key: "actions",
        visible: true,
        pinned: "left",
        label: "",
        format: (row) => (
            <ActionButton
            key={row.id}
            updateForm="updateCurriculumPursued"
            deleteForm="deleteCurriculumPursued"
            formData={row}
            refreshData={() =>
              this.props.callback()
            }
          />
        ),
      },
    ];
    var selectedColumns = [];
    columns.forEach((column) => {
      if (column.visible) {
        selectedColumns.push(column.key);
      }
    });
    this.setState({
      columns,
      curriculumsPursued,
      curriculums,
      selectedCurriculums,
      schools,
      selectedSchools,
      players,
      selectedPlayers,
      selectedColumns,
    });
  };

  render() {

    const { t } = this.props;
    var curriculumsPursued = this.props.curriculumsPursued;
    var columns = this.state.columns;
    var selectedCurriculums = this.state.selectedCurriculums;
    var selectedSchools = this.state.selectedSchools;
    var selectedPlayers = this.state.selectedPlayers;
    var selectedColumns = this.state.selectedColumns;
    var searchText = this.state.searchText;
    if (curriculumsPursued) {
        curriculumsPursued = curriculumsPursued.filter((o) =>
            selectedCurriculums.some((item) => item === o.curriculum.id)
        );
    }
    if (curriculumsPursued) {
        curriculumsPursued = curriculumsPursued.filter((o) =>
            selectedSchools.some((item) => item === o.curriculum.school.id)
        );
    }
    if (curriculumsPursued) {
        curriculumsPursued = curriculumsPursued.filter((o) =>
            selectedPlayers.some((item) => item === o.player.id)
        );
    }
    if (searchText.length > 2) {
        curriculumsPursued = curriculumsPursued.filter((o) =>
            o.player.lastName.toLowerCase().includes(searchText.toLowerCase()) || 
            o.player.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
            o.curriculum.name.toLowerCase().includes(searchText.toLowerCase())
        );
    }
    if (columns) {
      columns = columns.filter((o) =>
        selectedColumns.some((item) => item === o.key)
      );
    }
    return (
      <>
        <FiltersBar
          components={
            <>
              <FiltersTitle title={ curriculumsPursued
                  ? curriculumsPursued.length +
                    " " +
                    t("Curriculums pursued").toLowerCase()
                  : "" }
              />
              <FilterSeparator />
              <FiltersSearch search={<SearchInput
                value={this.state.searchText}
                onChange={(searchText) => this.setState({ searchText })}
              />} />
              <FilterSeparator />
              <FiltersDropDowns selects={[
                <SelectMultiple
                    options={this.state.schools}
                    keys={this.state.selectedSchools}
                    className="mx-2"
                    onChange={(res) => this.setState({ selectedSchools: res })}
                    fixedLabel={t("School")}
                />,
                <SelectMultiple
                    options={this.state.curriculums}
                    keys={this.state.selectedCurriculums}
                    className="mx-2"
                    onChange={(res) => this.setState({ selectedCurriculums: res })}
                    fixedLabel={t("Curriculum")}
                />,
                <SelectMultiple
                    options={this.state.players}
                    keys={this.state.selectedPlayers}
                    className="mx-2"
                    onChange={(res) => this.setState({ selectedPlayers: res })}
                    fixedLabel={t("Player")}
                />
                ]} 
              />
              <FilterSeparator />
              <FilterColumns
                columns={
                  <SelectMultiple
                    options={this.state.columns}
                    keys={this.state.selectedColumns}
                    className="mx-2"
                    onChange={(res) => this.setState({ selectedColumns: res })}
                    fixedLabel={t("Active columns")}
                  />
                }
              />
            </>
          }
          buttons={this.props.buttons}
        />
        <TableSimple columns={columns} data={curriculumsPursued} />
      </>
    );
  }
}

export default withTranslation()(TableCurriculumsPursued);
