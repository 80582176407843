import React from "react";
import Button from "../buttons/Button";

class DrawerHeader extends React.Component {
  render() {
    return (
      <div className="py-5 2xl:py-8 px-4 sm:px-6 bg-indigo-700">
        <div className="flex items-start justify-between">
          <h2
            id="slide-over-heading"
            className="text-base 2xl:text-lg font-medium text-white"
          >
            {this.props.children}
          </h2>
          <div className="ml-3 h-6 2xl:h-7 flex items-center">
            <Button
              className="bg-transparent rounded-md text-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={this.props.hide}
            >
              <svg
                className="h-4 w-4 2xl:h-6 2xl:w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default DrawerHeader;
