import React from "react";
import IconRound32x32 from "../icons/IconRound32x32";

// Stepper
class Step extends React.Component{
    // !step = {!photo, !displayName}
    // index
    render() {
        return <div className={this.props.selectedStep === this.props.step ? "selected" : ""}>
            {this.props.step && this.props.step.photo?<IconRound32x32 photo={ this.props.step.photo}/> : this.props.index}
            {this.props.step ? this.props.step.displayName : ""} 
            </div>
    }
}
class Stepper extends React.Component {
    // !steps = { photo, displayName }
    // selectedStep
    render() {
        return this.props.steps && this.props.steps.length > 0 ? (
        <div className="stepper">
            {this.props.steps.length === 1 ? 
            <Step step={this.props.steps[0]} selectedStep={this.props.steps[0]}/> 
            : this.props.selectedStep ? <>
            {this.props.steps.map((step, key) =>{
                if(step !== this.props.steps[this.props.steps.length - 1]){
                    return <div key={key}>< Step selectedStep={this.props.selectedStep} index={this.props.steps.indexOf(step) + 1} step={step}/> {'->'} </div>
                }
                return <Step key={key} step={step} index={this.props.steps.indexOf(step) + 1} selectedStep={this.props.selectedStep} />
            })}
            </> : null}
        </div>) : null;
    }
}

export default Stepper;