import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import IconRound32x32 from "../components/icons/IconRound32x32";
import { withTranslation } from "react-i18next";
import { Select, SelectMultiple } from "../components/forms";
import TableSimple from "../components/table/TableSimple";
import Flag from "../components/icons/Flag"
import FilterColumns from "../components/filters/FilterColumns";
import FiltersDropDowns from "../components/filters/FiltersDropDowns";
import FiltersSearch from "../components/filters/FiltersSearch";
import SearchInput from "../components/forms/input/SearchInput";
import ExportToCSV from "../components/export/ExportToCSV";
import FilterSeparator from "../components/filters/FilterSeparator";
import FiltersTitle from "../components/filters/FiltersTitle";
import FiltersBar from "../components/filters/FiltersBar";


class TableRankingTennisEuropeU14F extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actions: [],
      ages: [],
      selectedAges: [],
      nationalities: [],
      selectedNationalities: [],
      dates: [],
      columns: [],
      searchText: "",
    };
  }

  componentDidMount() {
    this.prepareData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.prepareData();
    }
  }

  prepareData = () => {
    var rankings = this.props.rankings;
    var ages = [];
    var nationalities = [];
    var selectedAges = [];
    var selectedNationalities = [];

    //  chercher les elements a déselectionner du localstorage
    var unselectedAges = localStorage.getItem("TableRankingEUR_U14F_unselectedAges")
    if (unselectedAges) unselectedAges = unselectedAges.split("|")
       
    // nationalities
    var unselectedNationalities = localStorage.getItem("TableRankingEUR_U14F_unselectedNationalities")
    if (unselectedNationalities) unselectedNationalities = unselectedNationalities.split("|") 

    
    if (rankings) {
      rankings = rankings.map((ranking) => {
        if (ages && !ages.find((o) => o.key === ranking.yob)) {
          ages.push({ key: ranking.yob, label: ranking.yob })
          // only add to selected if not unselected
          if (
            !unselectedAges ||
            unselectedAges.indexOf(ranking.yob.toString()) < 0
          )
          selectedAges.push(ranking.yob);
        }
        if (
          nationalities &&
          !nationalities.find((o) => o.key === ranking.player.nationality)
        ) {
          nationalities.push({
            key: ranking.player.nationality,
            label: ranking.player.country ? ranking.player.country.nationality : ranking.player.nationality,
          })
          // only add to selected if not unselected
          if (
            !unselectedNationalities ||
            unselectedNationalities.indexOf(ranking.player.nationality) < 0
          )
          selectedNationalities.push(ranking.player.nationality);
        }
        return ranking;
      });
    }
    nationalities = nationalities.sort((a, b) => a.label === b.label
        ? 0
        : a.label > b.label
          ? 1
          : -1
    )
    ages = ages.sort((a, b) => a.key === b.key
        ? 0
        : a.key > b.key
          ? 1
          : -1
    )
    var propsDates = this.props.dates;
    var dates = [];
    if (propsDates) {
      propsDates.map((date) =>
        dates.push({
          key: date,
          label: moment(date).format("D MMM YYYY"),
        })
      );
    }
    var selectedDate = this.props.selectedDate;

    const columns = [
      {
        key: "rank",
        visible: true,
        width: 100,
        id: "rank",
        label: this.props.t("Ranking"),
        format: (row) => 
          <div className="flex text-xs 2xl:text-sm">
            <div>{row.rank}</div>
            <div>{row.delta && row.delta !== 0 ? (row.delta > 0 ? <span className="ml-2 text-green-700 text-xs">{"+" + row.delta + ""}</span> : <span className="ml-2 text-red-700 text-xs">{row.delta}</span>) : <span className="ml-2">{"="}</span>}</div>
          </div>,
      },
      {
        key: "name",
        visible: true,
        label: this.props.t("Name"),
        id: "name",

        format: (row) =>
          row.player.nationality != null && row.player.nationality === "FR" ? (
            <>
              <Link
                className="text-indigo-700 font-semibold"
                to={
                  "/competitionTennis/rankingTennisEuropeU14fByPlayer/" +
                  row.player.id
                }
              >
                <IconRound32x32 photo={row.player.photo} />
                <span className="ml-5 text-xs 2xl:text-sm">{row.name}</span>
              </Link>
            </>
          ) : (
              <Link
                className="text-black"
                to={"/competitionTennis/rankingAtpByPlayer/" + row.player.id}
              >
                <IconRound32x32 photo={row.player.photo} />
                <span className="ml-5 text-xs 2xl:text-sm">{row.name}</span>
              </Link>
            ),
      },
      {
        key: "player.nationality",
        visible: true,
        label: this.props.t("Nationality"),
        id: "player.nationality",
        filter: {
          name: "SelectColumnFilter",
          type: "includes",
        },
        format: (row) =>
          row.player ? (
              <div className="flex">
                <Flag className="w-4 2xl:w-6" nationalityId={row.player.nationality} />
                <div className="flex px-3 text-xs 2xl:text-sm">
                {row.player.country ? row.player.country.nationality : row.player.nationality}
                </div>
              </div>
          ) : (
              ""
            ),
      },
      {
        key: "player.dob",
        visible: true,
        label: this.props.t("Date of birth"),
        id: "player.dob",
        filter: {
          name: "SelectColumnFilter",
          type: "includes",
        },
        format: (row) => <span className="text-xs 2xl:text-sm">{row.player.dob ? moment(row.player.dob).format("DD/MM/YYYY") : "-"}</span>,
      },
      {
        key: "age",
        visible: true,
        label: this.props.t("Age"),
        id: "age",
        // format: (row) => (
        //   <span className="font-weight-bold text-muted">{row.age}</span>
        // ),
        // filter: {
        //   name: "NumberRangeColumnFilter",
        // },
        filter: {
          name: "SelectColumnFilter",
          type: "includes",
        },
        format: (row) => <span className="text-xs 2xl:text-sm">{row.age + " ans"}</span>,
      },
      {
        key: "pts",
        visible: true,
        label: this.props.t("Points"),
        id: "pts",
        format: (row) => (
          <span className="font-weight-bold text-muted text-xs 2xl:text-sm">
            {row.pts + " pts"}
          </span>
        ),
      },
    ];

    var selectedColumns = localStorage.getItem("TableRankingEUR_U14F_selectedColumns")
    if (selectedColumns === null || selectedColumns.length === 0)  selectedColumns = ["rank", "name", "player.nationality", "age", "pts" ]
    else selectedColumns = selectedColumns.split("|")
    columns.forEach((column) => {
      column.visible = selectedColumns.indexOf(column.key) < 0 ? false : true 

    });
    this.setState({
      columns,
      rankings,
      ages,
      dates,
      selectedDate,
      nationalities,
      selectedColumns,
      selectedAges,
      selectedNationalities,
    });
  };

      // change ages
      onChangeSelectedAges = (res) => {
        this.setState({ selectedAges: res }, () => {
          // determiner ce qui n'est pas selectionner
          let unselect = []
          this.state.ages.forEach((a) => {
            if (this.state.selectedAges.indexOf(a.key) < 0) unselect.push(a.key)
          })
          // save unselected elements to localstorage
          localStorage.setItem("TableRankingEUR_U14F_unselectedAges", unselect.join("|"))
        })
      }
      
      // change nationalities
      onChangeSelectedNationalities = (res) => {
        this.setState({ selectedNationalities: res }, () => {
          // determiner ce qui n'est pas selectionner
          let unselect = []
          this.state.nationalities.forEach((a) => {
            if (this.state.selectedNationalities.indexOf(a.key) < 0) unselect.push(a.key)
          })
          // save unselected elements to localstorage
          localStorage.setItem("TableRankingEUR_U14F_unselectedNationalities", unselect.join("|"))
        })
      }
    
      // change columns
      onChangeSelectedColumns = (res) => {
          this.setState({ selectedColumns: res }, () => {
            // determiner ce qui est selectionne
            let select = []
            this.state.columns.forEach((a) => {
              if (this.state.selectedColumns.indexOf(a.key) > -1 ) select.push(a.key)
            })
            // save selected elements to localstorage
            localStorage.setItem("TableRankingEUR_U14F_selectedColumns", select.join("|"))
          })
        }

  render() {
    const { t } = this.props;
    var rankings = this.props.rankings;
    var dates = this.state.dates;
    var selectedDate = this.state.selectedDate;
    var columns = this.state.columns;
    var selectedColumns = this.state.selectedColumns;
    var selectedAges = this.state.selectedAges;
    var selectedNationalities = this.state.selectedNationalities;
    var searchText = this.state.searchText;
    if (columns) {
      columns = columns.filter((o) =>
        selectedColumns.some((item) => item === o.key)
      );
    }
    if (rankings) {
      rankings = rankings.filter((o) =>
        selectedAges.some((item) => item === o.yob)
      );
    }
    if (rankings) {
      rankings = rankings.filter((o) =>
        selectedNationalities.some((item) => item === o.player.nationality)
      );
    }
    if (searchText.length > 2) {
      rankings = rankings.filter(
        (o) =>
          o.player.lastName
            .toLowerCase()
            .includes(searchText.toLowerCase()) ||
          o.player.firstName.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    return (
      <>
        <FiltersBar
          components={
            <>
              <FiltersTitle title={rankings ? rankings.length + " " + t("Players").toLowerCase() : ""} />
              <FilterSeparator />
              <Select
                options={dates}
                value={selectedDate}
                onChange={(date) => this.props.onChange(date)}
              />
              <FilterSeparator />
              <FiltersSearch search={<SearchInput
                value={this.state.searchText}
                onChange={(searchText) => this.setState({ searchText })}
                
              />} />
              <FilterSeparator />
              <FiltersDropDowns selects={[
                <SelectMultiple
                  options={this.state.ages}
                  keys={this.state.selectedAges}
                  className="mx-2"
                  onChange={this.onChangeSelectedAges}
                  fixedLabel={t("Age")}
                />,
                <SelectMultiple
                  options={this.state.nationalities}
                  keys={this.state.selectedNationalities}
                  className="mx-2"
                  onChange={this.onChangeSelectedNationalities}
                  fixedLabel={t("Nationality")}
                />
                ]} 
              />
              <FilterSeparator />
              <FilterColumns 
                columns={
                  <SelectMultiple
                    options={this.state.columns}
                    keys={this.state.selectedColumns}
                    className="mx-2"
                    onChange={this.onChangeSelectedColumns}
                    fixedLabel={t("Active columns")}
                  />
                }
              />
              <FilterSeparator />
              <ExportToCSV columns={columns} jsonData={rankings} fileName={t("Ranking") + ".csv"} />
            </>
          }
        />
        <TableSimple columns={columns} data={rankings} />
      </>
    );
  }
}

export default withTranslation()(TableRankingTennisEuropeU14F);
