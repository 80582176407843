import Dexie from "dexie";

const db = new Dexie("Administration");
db.version(1).stores({ records: "++playerId" });
db.version(1).stores({ licenses: "++id, playerId" });
db.version(1).stores({ contacts: "++id, playerId, typeId" });
db.version(1).stores({ contactsTypes: "++id" });
db.version(1).stores({ personalDocuments: "++id, playerId" });
db.version(1).stores({ schools: "++id" });
db.version(1).stores({ curriculums: "++id, schoolId" });
db.version(1).stores({ maritalStatuses: "++id" });

export default db;
