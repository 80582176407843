import React from "react";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import Page from "../components/page/Page";
import Calendar from "../components/calendar/Calendar";
import Tour from "reactour"

class PageCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: null,
      reloadCalendar: false,
      isTourOpen: false,
    };
    this.closeTour = this.closeTour.bind(this);
    this.openTour = this.openTour.bind(this);
    this.modifyTour = this.modifyTour.bind(this);
    this.displayTour = this.displayTour.bind(this);
  }

  closeTour() {
    this.setState({ isTourOpen: false });
  }
  openTour() {
    this.setState({ isTourOpen: true });
  }
  modifyTour(value) {
    if(typeof value === "boolean")
    {
      this.setState({ isTourOpen: value });
    }
  }

  displayTour(steps) {
    return (
      <Tour
        steps={steps}
        isOpen={this.state.isTourOpen}
        onRequestClose={this.closeTour}
      />
    );
  }

  render() {
    const { t } = this.props;

    const steps = [
      {
        selector: ".CalendarStep1",
        content: t("Access the calendar from the player's file (accessible from the Group module) and create his individual schedule. Access the global calendar from the Calendar module. You will be able to create your events but also to display simultaneously the schedule of several players that you follow."),
      },
      {
        selector: ".CalendarStep2",
        content: t("Select the players for who you want to display the schedule and filter the events you want to display"),
      },
      {
        selector: ".CalendarStep3",
        content: t("Select a time slot to create the event type of your choice or click on the event to access its options (access the event details)"),
      },
      {
        selector: ".CalendarStep4",
        content: t("Select the players for whom you want to display the schedule and filter the events you want to display"),
      },
      {
        selector: ".CalendarStep5",
        content: t("Copy the events of one or more training days and paste them wherever you want in your calendar"),
      },
      {
        selector: ".CalendarStep6",
        content: t("Delete the events of one or more training days")
      },
    ]
    
    return (
      <>
      {this.displayTour(steps)}
      <Page
        closeTour={() => this.closeTour()}
        openTour={() => this.openTour()}
        modifyTour={(value) => this.modifyTour(value)}
        haveReactTour={true}
        tab={[
          {
            title: t("Planning"),
            visible: true,
            actions: {
              addForm: "event",
              method: "POST",
              formData: this.state.formData,
              refreshData: () => {
                this.setState({ reloadCalendar: !this.state.reloadCalendar })
              },
            },

            data: null,
            component: (data) => {
              return (
                  <Calendar
                    availableViews="month,week"
                    defaultView="timeGridWeek"
                    callbackFormData={(formData) => this.setState({ formData })}
                    reloadCalendar={this.state.reloadCalendar}
                    filters
                  />
              );
            },
          },
        ]}
      />
    </>
    );
  }
}

export default withTranslation()(withRouter(PageCalendar));
