import React from "react";
import { withTranslation } from "react-i18next";

// INPUT
// * key
// * onChange => (value)
// type = "button", "checkbox", "color", "date", "datetime-local", "email", "file", "number", "image", "month", "password", "radio", "range", "reset", "tel", "time", "url", "week"
// checked (bool)
// "formaction" (for image type)
// disabled
// accept (type = file) = corresponds to the Extension
// max
// min
// maxlength
// minlength
// pattern (regex)
// required
// placeholder
// src (type=image)
// value
// width (type = image)

class FileUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value ? this.props.value : "",
    };
    this.onInputChange = this.onInputChange.bind(this);
  }

  componentDidMount() {
    var { id } = this.props;
    var elDragDrop = document.getElementById("drag-and-drop-" + id);
    if (window.File && window.FileReader && window.FileList && window.Blob) {
      elDragDrop.addEventListener("dragover", (event) => {
        event.stopPropagation();
        event.preventDefault();
        event.dataTransfer.dropEffect = 'copy';
      });
      elDragDrop.addEventListener("drop", (event) => {
        event.stopPropagation();
        event.preventDefault();
        this.onInputChange(event.dataTransfer.files[0])
      });
    }
  }

  onInputChange(value) {
    this.props.onChange(value);
    this.setState({ value });
  }

  render() {
    var { onChange, t, id, ...newProps } = this.props;
    return (
      <div className="mt-2 sm:mt-0 sm:col-span-2">
        <div className="flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md" id={"drag-and-drop-" + id}>
          <div className="space-y-1 text-center">
            <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
              <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            {this.state.value && this.state.value.name ?
              <div className="flex text-sm text-gray-600">
                <div className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-700 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                  {this.state.value.name}
                </div>
              </div>
              : ""}
            <p className="text-xs text-gray-500">
              <label htmlFor={id ? id : "file-upload"} className="relative cursor-pointer bg-white rounded-md font-small hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                <span>{this.state.value && this.state.value.name ? t("Modify") : t("Upload a file")}</span>
                <input id={id ? id : "file-upload"}
                  name={id ? id : "file-upload"}
                  type="file"
                  className="hidden"
                  onChange={(event) => this.onInputChange(event.target.files[0])}
                  {...newProps}></input>
              </label>
            </p>
          </div>
        </div>
      </div >



    );
  }
}

export default withTranslation()(FileUpload);
