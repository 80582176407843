import React from "react";
import ActionButton from "../components/buttons/ActionButton";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { SelectMultiple } from "../components/forms";
import TableSimple from "../components/table/TableSimple";
import FilterColumns from "../components/filters/FilterColumns";
import FiltersDropDowns from "../components/filters/FiltersDropDowns";
import FiltersSearch from "../components/filters/FiltersSearch";
import SearchInput from "../components/forms/input/SearchInput";
import FilterSeparator from "../components/filters/FilterSeparator";
import FiltersTitle from "../components/filters/FiltersTitle";
import FiltersBar from "../components/filters/FiltersBar";

class TableSchools extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actions: [],
      cities: [],
      selectedCities: [],
      columns: [],
      searchText: "",
    };
  }

  componentDidMount() {
    this.prepareData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.prepareData();
    }
  }

  prepareData = () => {
    var schools = this.props.schools;
    var cities = [];
    var selectedCities = [];
    if (schools) {
      schools = schools.map((school) => {
        if (cities && !cities.find((o) => o.key === school.city)) {
          cities.push({
            key: school.city,
            label: school.city,
          });
          selectedCities.push(school.city);
        }
        return school;
      });
    }

    const columns = [
      {
        key: "name",
        visible: true,
        label: this.props.t("Name"),
        pinned: "left",
        id: "name",
        format: (row) => {
          return (
            <>
              <Link
                className="text-indigo-700 font-medium text-xs 2xl:text-sm"
                to={"/administration/school/" + row.id}
              >
                {row.name}
              </Link>
            </>
          );
        },
      },
      {
        key: "address",
        visible: true,
        label: this.props.t("Address"),
        pinned: "left",
        id: "address",
        format: (row) => {
          return <><span className=" text-xs 2xl:text-sm">{row.address}</span></>;
        },
      },
      {
        key: "city",
        visible: true,
        label: this.props.t("City"),
        pinned: "left",
        id: "city",
        format: (row) => {
          return <><span className=" text-xs 2xl:text-sm">{row.city}</span></>;
        },
      },
      {
        key: "actions",
        visible: true,
        pinned: "left",
        label: "",
        format: (row) => (
          <ActionButton
            key={row.id}
            updateForm="updateSchool"
            deleteForm="deleteSchool"
            formData={row}
            refreshData={() => this.props.callback()}
          />
        ),
      },
    ];
    var selectedColumns = [];
    columns.forEach((column) => {
      if (column.visible) {
        selectedColumns.push(column.key);
      }
    });
    this.setState({
      columns,
      schools,
      cities,
      selectedColumns,
      selectedCities,
    });
  };

  render() {
    const { t } = this.props;
    var schools = this.props.schools;
    var columns = this.state.columns;
    var selectedColumns = this.state.selectedColumns;
    var selectedCities = this.state.selectedCities;
    var searchText = this.state.searchText;
    if (columns) {
      columns = columns.filter((o) =>
        selectedColumns.some((item) => item === o.key)
      );
    }
    if (schools) {
      schools = schools.filter((o) =>
        selectedCities.some((item) => item === o.city)
      );
    }
    if (searchText.length > 2) {
      schools = schools.filter((o) =>
        o.name.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    return (
      <>
        <FiltersBar
          components={
            <>
              <FiltersTitle title={schools ? schools.length + " " + t("Schools").toLowerCase() : ""} />
              <FilterSeparator />
              <FiltersSearch search={<SearchInput
                value={this.state.searchText}
                onChange={(searchText) => this.setState({ searchText })}
                
              />} />
              <FilterSeparator />
              <FiltersDropDowns selects={[
                <SelectMultiple
                options={this.state.cities}
                keys={this.state.selectedCities}
                className="mx-2"
                onChange={(res) => this.setState({ selectedCities: res })}
                fixedLabel={t("City")}
              />
                ]} 
              />
              <FilterSeparator />
              <FilterColumns
                columns={
                  <SelectMultiple
                    options={this.state.columns}
                    keys={this.state.selectedColumns}
                    className="mx-2"
                    onChange={(res) => this.setState({ selectedColumns: res })}
                    fixedLabel={t("Active columns")}
                  />
                }
              />
            </>
          }
          buttons={this.props.buttons}
        />
        <TableSimple columns={columns} data={schools} />
      </>
    );
  }
}

export default withTranslation()(TableSchools);
