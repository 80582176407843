import React, { Component } from "react";
import Card from "../components/card/Card";
import GeneratedForm from "../utils/forms/generateForm";
import ButtonLight from "../components/buttons/ButtonLight";
import ExportToCSV from "../components/export/ExportToCSV";
import FilterColumns from "../components/filters/FilterColumns";
import FiltersBar from "../components/filters/FiltersBar";
import FiltersDropDowns from "../components/filters/FiltersDropDowns";
import FilterSeparator from "../components/filters/FilterSeparator";
import FiltersSearch from "../components/filters/FiltersSearch";
import FiltersTitle from "../components/filters/FiltersTitle";
import { SelectMultiple } from "../components/forms";
import SearchInput from "../components/forms/input/SearchInput";
import TableSimple from "../components/table/TableSimple";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";

class TableMatchGeneralRugby extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      selectedColumns: [],
      selectedGroups: [],
      searchText: "",
    };
  }

  componentDidMount() {
    this.prepareData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.prepareData();
    }
  }

  prepareData = (firstTime) => {
      this.props.infoByPlayer &&
      this.props.infoByPlayer.sort((a, b) =>
        a.position.number === b.position.number
          ? 0
          : a.position.number < b.position.number
          ? -1
          : 1
      );

    const columns = [
      {
        key: "name",
        label: "Name",
        visible: true,
        format: (row) => (
          <Link
            to={{
              pathname: `/competitionSoccer/playerInfoByMatch/${row.matchId}/${row.player.id}`,
            }}
          >
            <span className="hover:bg-blue-200 py-1 px-3 rounded-full text-xs">
              {row.position.number + " - " + row.player.lastName.toUpperCase()}
            </span>
          </Link>
        ),
      },
      {
        key: "position",
        label: "Position",
        visible: true,
        format: (row) => (
          <span className=" py-1 px-3 rounded-full text-xs">
            {row.position.name}
          </span>
        ),
      },
      {
        key: "status",
        label: "Status",
        visible: true,
        format: (row) => (
          <span className=" py-1 px-3 rounded-full text-xs">
            {row.position.status.name}
          </span>
        ),
      },
      {
        key: "playtime",
        label: "Playtime",
        visible: true,
        format: (row) => (
          <span className=" py-1 px-3 rounded-full text-xs">
            {row.playTime.minutes ? row.playTime.minutes  + " Min" : " - "}
          </span>
        ),
      },
    ];

    // after columns added Add local Storage
    var selectedColumns = localStorage.getItem("TableSoccer_GeneralStat");
    if (selectedColumns === null || selectedColumns.length === 0) {
      selectedColumns = [];
      columns.forEach((column) => {
        selectedColumns.push(column.key);
        //  column.visible = true
      });
    } else selectedColumns = selectedColumns.split("|");
    columns.forEach((column) => {
      column.visible = selectedColumns.indexOf(column.key) < 0 ? false : true;
    });

    // selected columns
    if (firstTime) {
      selectedColumns = [];
      columns.forEach((column) => {
        if (column.visible) {
          selectedColumns.push(column.key);
        }
      });
      selectedColumns = selectedColumns.slice(0, 6);
      this.setState({ selectedColumns });
    }

    this.setState({
      columns,
      selectedColumns,
    });
  };

  // change columns
  onChangeSelectedColumns = (res) => {
    this.setState({ selectedColumns: res }, () => {
      // determiner ce qui est selectionne
      let select = [];
      this.state.columns.forEach((a) => {
        if (this.state.selectedColumns.indexOf(a.key) > -1) select.push(a.key);
      });
      // save selected elements to localstorage
      localStorage.setItem("TableSoccer_GeneralStat", select.join("|"));
    });
  };

  render() {
    const { t } = this.props;
    var infoByPlayer = this.props.infoByPlayer;
    var columns = this.state.columns;
    var selectedColumns = this.state.selectedColumns;
    var searchText = this.state.searchText;

    if (columns) {
      columns = columns.filter((o) =>
        selectedColumns.some((item) => item === o.key)
      );
    }

    if (searchText.length > 2) {
      infoByPlayer = infoByPlayer.filter(
        (o) =>
          o.player.lastName.toLowerCase().includes(searchText.toLowerCase())
        // ||
        // (o.type &&
        //   o.type.lastName.toLowerCase().includes(searchText.toLowerCase()))
      );
    }
    return (
      <Card
        title={t("Statistiques Joueurs")}
        headerActions={
          <GeneratedForm
            key="updateCommentPlayer"
            component={(show) => (
              <ButtonLight onClick={show} disabled={!navigator.onLine}>
                {t("Edit")}
              </ButtonLight>
            )}
            callback={() => this.props.refresh()}
            formName="updateCommentPlayer"
            formData={this.state.player}
          />
        }
      >
        <FiltersBar
          components={
            <>
              <FiltersTitle
                title={
                  infoByPlayer
                    ? infoByPlayer.length + " " + t("Joueurs").toLowerCase()
                    : ""
                }
              />
              <FilterSeparator />
              <FiltersSearch
                search={
                  <SearchInput
                    value={this.state.searchText}
                    onChange={(searchText) => this.setState({ searchText })}
                  />
                }
              />
              <FilterSeparator />
              <FiltersDropDowns
                selects={[
                  <SelectMultiple
                    options={this.state.groups}
                    keys={this.state.selectedGroups}
                    className="mx-2"
                    fixedLabel={t("Group")}
                  />,
                ]}
              />
              <FilterSeparator />
              <FilterColumns
                columns={
                  <SelectMultiple
                    options={this.state.columns}
                    keys={this.state.selectedColumns}
                    className="mx-2"
                    fixedLabel={t("Active columns")}
                    onChange={this.onChangeSelectedColumns}
                  />
                }
              />
              <FilterSeparator />
              <ExportToCSV
                columns={columns}
                jsonData={infoByPlayer}
                fileName={t("Match General Stat") + ".csv"}
              />
            </>
          }
          buttons={this.props.buttons}
        />
        <TableSimple columns={columns} data={infoByPlayer} />
      </Card>
    );
  }
}

export default withTranslation()(withRouter(TableMatchGeneralRugby));
