import React from "react";

class IconRound32x32 extends React.Component {
  render() {
    if (this.props.tooltip) {
      return (
        <img
          alt="img"
          src={this.props.photo}
          className="inline-block h-6 w-6 rounded-full"
          data-toggle="tooltip"
          data-placement="auto bottom"
          title={this.props.tooltip}
        />
      );
    } else {
      return (
        <img
          alt="img"
          src={this.props.photo}
          className="inline-block h-6 2xl:h-9 w-6 2xl:w-9 rounded-full"
        />
      );
    }
  }
}

export default IconRound32x32;
