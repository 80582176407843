import React from "react";
import ActionButton from "../components/buttons/ActionButton";
import { withTranslation } from "react-i18next";
import { Select, SelectMultiple } from "../components/forms";
import TableSimple from "../components/table/TableSimple";
import moment from "moment";
import ChartAnthropometryByPlayer from "../charts/ChartAnthropometryByPlayer";
import SearchInput from "../components/forms/input/SearchInput";
import FiltersSearch from "../components/filters/FiltersSearch";
import FilterColumns from "../components/filters/FilterColumns";
import ExportToCSV from "../components/export/ExportToCSV";
import FilterSeparator from "../components/filters/FilterSeparator";
import FiltersTitle from "../components/filters/FiltersTitle";
import FiltersBar from "../components/filters/FiltersBar";

class TablePlayerAnthropometricData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actions: [],
      columns: [],
      searchText: "",
    };
  }

  componentDidMount() {
    this.prepareData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.prepareData();
    }
  }

  prepareData = () => {
    var data = this.props.data;
    if (data)
    {
      data = data.sort((a, b) =>
          a.date === b.date ? 0 : a.date > b.date ? -1 : 1
        )
    }
    var measures = this.props.measures;
    const columns = [
      {
        key: "date",
        visible: true,
        pinned: "left",
        label: this.props.t("Date"),
        id: "date",
        format: (row) => <span className=" text-xs 2xl:text-sm">{moment(row.date).calendar()}</span>,
        filter: {
          name: "DateBetweenFilter",
        },
      },
      {
        key: "value",
        visible: true,
        pinned: "left",
        label: this.props.t("Value"),
        id: "value",
        format: (row) =>
          <div className="flex justify-between">
            <span className=" text-xs 2xl:text-sm">
              {row.thresholdColor ?
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-white text-xs 2xl:text-sm" style={{ backgroundColor: row.thresholdColor }}>
                  {row.value +
                    (measures.find((o) => o.id === row.measureId) &&
                      measures.find((o) => o.id === row.measureId).unit
                      ? measures.find((o) => o.id === row.measureId).unit
                      : "")}
                </span>
                : row.value +
                (measures.find((o) => o.id === row.measureId) &&
                  measures.find((o) => o.id === row.measureId).unit
                  ? measures.find((o) => o.id === row.measureId).unit
                  : "")}
            </span>
            <ActionButton
              key={row.id}
              updateForm="updateAnthropo"
              deleteForm="deleteAnthropo"
              formData={row}
              refreshData={
                this.props.refresh
                // () =>
                //     loadAnthropometricData(this.state.id).then((anthropometricData) =>
                //         this.setState({ anthropometricData })
                //     )
              }
            />
          </div>
        ,
      },
      {
        key: "comments",
        visible: true,
        pinned: "left",
        label: this.props.t("Comments"),
        id: "comments",
        format: (row) => <span className=" text-xs 2xl:text-sm">{row.comments}</span>,
      }
    ];
    var selectedColumns = [];
    columns.forEach((column) => {
      if (column.visible) {
        selectedColumns.push(column.key);
      }
    });
    this.setState({
      columns,
      data,
      measures,
      selectedColumns,
    });
  };

  render() {
    const { t } = this.props;
    var measures = this.props.measures;
    var data = this.state.data;
    var columns = this.state.columns;
    var selectedColumns = this.state.selectedColumns;
    var searchText = this.state.searchText;
    var measureId = this.props.measureId;
    var measuresList = [];
    if (!measureId && measures && measures.length > 0) {
      measureId = measures[0].id;
    }
    if (measures && measures.length > 0) {

      measures.map((measure) =>
        measuresList.push({
          key: measure.id,
          label: measure.name,
        })
      );
    }
    if (columns) {
      columns = columns.filter((o) =>
        selectedColumns.some((item) => item === o.key)
      );
    }
    if (measures && searchText.length > 2) {
      measures = measures.filter((o) =>
        o.name.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    return (
      <>
        <FiltersBar
          components={
            <>
              <FiltersTitle title={measures ? measures.length + " " + t("Results").toLowerCase() : ""} />
              <FilterSeparator />
              <Select
                value={measureId}
                options={measuresList}
                onChange={this.props.onMeasureChange}
              />
              <FilterSeparator />
              <FiltersSearch search={<SearchInput
                value={this.state.searchText}
                onChange={(searchText) => this.setState({ searchText })}
                
              />} />
              <FilterSeparator />
              <FilterColumns
                columns={
                  <SelectMultiple
                    options={this.state.columns}
                    keys={this.state.selectedColumns}
                    className="mx-2"
                    onChange={(res) => this.setState({ selectedColumns: res })}
                    fixedLabel={t("Active columns")}
                  />
                }
              />
              <FilterSeparator />
              <ExportToCSV columns={columns} jsonData={data} fileName={t("Anthropometry") + ".csv"} />
            </>
          }
          buttons={this.props.buttons}
        />
        <ChartAnthropometryByPlayer
          title={
            measures.find(
              (o) => o.id === measureId
            ).name
          }
          data={data ? data.filter(
            (o) => o.measureId === measureId
          ) : null}
        />
        {/* <Table columns={this.state.columns} data={players} /> */}
        <TableSimple columns={columns} data={data} />
      </>
    );
  }
}

export default withTranslation()(TablePlayerAnthropometricData);
