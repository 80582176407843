import React from "react";
import { withTranslation } from "react-i18next";
import { 
  SelectMultiple, 
  // DatePicker, 
  Select 
} from "../components/forms";
import FiltersSearch from "../components/filters/FiltersSearch";
import SearchInput from "../components/forms/input/SearchInput";
import FiltersDropDowns from "../components/filters/FiltersDropDowns";
import ExerciceCard from "../components/card/ExerciceCard";
import FilterSeparator from "../components/filters/FilterSeparator";
import FiltersTitle from "../components/filters/FiltersTitle";
import FiltersBar from "../components/filters/FiltersBar";

class ListTennisDrills extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actions: [],
      categories: [],
      selectedCategories: [],
      themes: [],
      selectedThemes: [],
      searchText: "",
      exercises: []
    };
  }

  componentDidMount() {
    this.prepareData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.prepareData();
    }
  }

  prepareData = () => {
    var exercises = this.props.exercises
    var type = this.props.type
    var themes = [];
    var selectedThemes = [];
    var categories = [];
    var selectedCategories = [];
    if (exercises) {
      exercises = exercises.map((exercise) => {
        if (
          categories &&
          !categories.find((o) => o.key === exercise[type + "Theme"][type + "Category"].id)
        ) {
          categories.push({
            key: exercise[type + "Theme"][type + "Category"].id,
            label: exercise[type + "Theme"][type + "Category"].name,
          });
          selectedCategories.push(exercise[type + "Theme"][type + "Category"].id);
        }
        if (
          themes &&
          !themes.find((o) => o.key === exercise[type + "Theme"].id)
        ) {
          themes.push({
            key: exercise[type + "Theme"].id,
            label: exercise[type + "Theme"].name,
          });
          selectedThemes.push(exercise[type + "Theme"].id);
        }
        return exercise;
      });
    }

    this.setState({
      exercises,
      themes,
      selectedThemes,
      categories,
      selectedCategories
    });
  };


  render() {
    const { t, type } = this.props;
    var exercises = this.props.exercises;
    var selectedThemes = this.state.selectedThemes;
    var selectedCategories = this.state.selectedCategories;
    var searchText = this.state.searchText;

    if (exercises) {
      exercises = exercises.filter((o) =>
        selectedThemes.some((item) => item === o[type + "Theme"].id)
      );
    }
    if (exercises) {
      exercises = exercises.filter((o) =>
        selectedCategories.some((item) => item === o[type + "Theme"][type + "Category"].id)
      );
    }
    if (searchText.length > 2 && exercises) {
      exercises = exercises.filter((o) =>
        o.name.toLowerCase().includes(searchText.toLowerCase()) ||
        o.exercise[type + "Theme"].name.toLowerCase().includes(searchText.toLowerCase()) ||
        o.exercise[type + "Theme"].exercise[type + "Category"].name.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    return (
      <>
        <FiltersBar
          components={
            <>
              <FiltersTitle title={exercises
                  ? exercises.length + " " + t("Exercises").toLowerCase()
                  : ""}
              />
              <FilterSeparator />
              <FiltersSearch search={<SearchInput
                value={this.state.searchText}
                onChange={(searchText) => this.setState({ searchText })}

              />} />
              <FilterSeparator />
              <Select
                options={this.props.groups}
                value={this.props.selectedGroup}
                className="mx-2"
                onChange={(res) => this.props.onSelectorChange(res)}
                fixedLabel={t("Period")}
              />
              <FilterSeparator />
              <FiltersDropDowns selects={[
                <SelectMultiple
                  options={this.state.categories}
                  keys={this.state.selectedCategories}
                  className="mx-2"
                  onChange={(res) => this.setState({ selectedCategories: res })}
                  fixedLabel={t("Categories")}
                />,
                <SelectMultiple
                  options={this.state.themes}
                  keys={this.state.selectedThemes}
                  className="mx-2"
                  onChange={(res) => this.setState({ selectedThemes: res })}
                  fixedLabel={t("Themes")}
                />
              ]} />
            </>
          }
          buttons={this.props.buttons}
        />
        <div className="mt-1 2xl:mt-6 w-full grid gap-8 grid-cols-3 2xl:grid-cols-4 lg:max-w-none p-6">
          {exercises ? exercises.map((ex) =>
            <ExerciceCard
              key={ex.id}
              exercice={ex}
              type={this.props.type}
              refresh={this.props.refresh}
            />
          ) : ""}
        </div>
      </>
    );
  }
}

export default withTranslation()(ListTennisDrills);
