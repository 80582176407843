import i18n from "../i18n"
import get from "../utils/requests/get";
import { patch, } from "../utils/requests/index";
import cogoToast from "cogo-toast";

const supportedLanguage = [{
    label: "Français",
    id: "FR",
    i18n: "fr",
    key: "fr"
}, {
    label: "English",
    id: "EN",
    i18n: "en",
    key: "en"
}];

function getSelectedLanguage() {
    return supportedLanguage.find(l => { return l.i18n === i18n.language });
}

function loadSelectedLanguage(userId) {
    get("group", "language", userId).then((lng) => {
        if (lng) {
            var match = supportedLanguage.find(l => { return l.id === lng.language });
            if(match) {
                if(match.key !== i18n.language) {
                    i18n.changeLanguage(match.key)
                }
                localStorage.setItem('Language', match.key)
            } else {
                localStorage.setItem('Language', "fr")
            } 
            
        }
    });
    return supportedLanguage.find(l => { return l.i18n === i18n.language });
}

function changeLanguage(key, userId, t) {
    var APImodule = "group"
    var APIfeature = "language"
    var formData = {
        userId,
        language: supportedLanguage.find(l => { return l.key === key }).id
    }
    var contentType = ""
    patch(APImodule, APIfeature, formData, contentType).then(
        (res) => {
            if (res.ok) {
                cogoToast.success(t("Update Successed"), { position: "bottom-right" });
                return res.json();
            } else {
                this.setState({ loading: false, errors: [res.status + " - " + res.url] });
                cogoToast.error("Error PATCH:" + res.status + " " + APImodule + ", " + APIfeature, { position: "bottom-right" });
                throw new Error(res.status);
            }
        }
    ).catch(err => {
        cogoToast.error("Error PATCH:" + APImodule + ", " + APIfeature, { position: "bottom-right" });
    });
    localStorage.setItem('Language', key)
    i18n.changeLanguage(key);
    return supportedLanguage.find(l => { return l.i18n === key });
}

async function setLanguage() {
    var lng = localStorage.getItem('Language')
    return lng || "fr"
}

export default supportedLanguage;

export {
    getSelectedLanguage,
    loadSelectedLanguage,
    changeLanguage,
    setLanguage
};