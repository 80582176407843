import React from "react";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import Page from "../components/page/Page";
import {
    // loadTrainingDrills,
    // loadTrainingCategories,
    getTrainingDrillsByGroup,
    loadTrainingDrillsByGroup,
    getPhysicalDrillsByGroup,
    loadPhysicalDrillsByGroup,
    getTrainingSessionTemplates,
    loadTrainingSessionTemplates,
    getPhysicalSessionTemplates,
    loadPhysicalSessionTemplates
} from "../utils/indexedDB/training/handleTraining";
// import Loading from "../components/loaders/Loading";
import { loadStaffs, loadGroups, getGroups, getStaffs } from "../utils/indexedDB/users/handleUser";
import SubTab from "../components/tab/SubTab";
import getUserProfile from "../utils/auth/getUserProfile";
// import ExerciceCard from "../components/card/ExerciceCard";
// import { Select } from "../components/forms";
// import Card from "../components/card/Card";
import GeneratedForm from "../utils/forms/generateForm";
import ButtonLight from "../components/buttons/ButtonLight";
import ListTennisDrills from "../lists/ListTennisDrills";
import TableSessionTemplates from "../tables/TableSessionTemplates"


class Training extends React.Component {
    constructor(props) {
        super(props);
        this.state = { loading: true, physicView: "exercicesp", tennisView: "exercicest" };
        this.handleDrills = this.handleDrills.bind(this);
        this.handlePhysicalDrills = this.handlePhysicalDrills.bind(this);
        this.changeSelectedGroup = this.changeSelectedGroup.bind(this);
        this.handlePhysicalSessionTemplates = this.handlePhysicalSessionTemplates.bind(this)
        this.handleTrainingSessionTemplates = this.handleTrainingSessionTemplates.bind(this)
        
    }

    componentDidMount() {
        getUserProfile().then(user => {
            if (user) {
                this.setState({ userId: user.sub, gender: user.gender, clubId: user.club })
            }
        })
        getStaffs().then((authors) => {
            if (authors)
                this.setState({ authors })
        })
        getGroups().then((groups) => {
            if (groups.length > 0) {
                getTrainingDrillsByGroup(groups[0].id).then((exercices) => {
                    if (exercices) {
                        exercices.sort((a, b) =>
                            a.trainingTheme.trainingCategory.name === b.trainingTheme.trainingCategory.name ? (
                                a.trainingTheme.name === b.trainingTheme.name ? (
                                    a.name === b.name ? 0 : a.name > b.name ? 1 : -1
                                ) : a.trainingTheme.name > b.trainingTheme.name ? 1 : -1
                            ) : a.trainingTheme.trainingCategory.name > b.trainingTheme.trainingCategory.name ? 1 : -1
                        );
                        this.setState({ exercices, selectedGroup: groups[0].id, groups })
                    }
                })
                getPhysicalDrillsByGroup(groups[0].id).then((exercicesPhysical) => {
                    if (exercicesPhysical) {
                        exercicesPhysical.sort((a, b) =>
                            a.physicalTheme.physicalCategory.name === b.physicalTheme.physicalCategory.name ? (
                                a.physicalTheme.name === b.physicalTheme.name ? (
                                    a.name === b.name ? 0 : a.name > b.name ? 1 : -1
                                ) : a.physicalTheme.name > b.physicalTheme.name ? 1 : -1
                            ) : a.physicalTheme.physicalCategory.name > b.physicalTheme.physicalCategory.name ? 1 : -1
                        );
                        this.setState({ exercicesPhysical, selectedGroup: groups[0].id, groups })
                    }
                })
            }
        })
        getPhysicalSessionTemplates().then((sessionTemplatesPhysical) => {
            if(sessionTemplatesPhysical)
            this.setState({ sessionTemplatesPhysical })
        })
        getTrainingSessionTemplates().then((sessionTemplatesTraining) => {
            if(sessionTemplatesTraining)
            this.setState({ sessionTemplatesTraining })
        })


        if (navigator.onLine) {
            loadStaffs().then((authors) => {
                loadGroups().then((groups) => {
                    if (groups.length > 0) {
                        groups = groups.map((g) => {
                            g.key = g.id;
                            g.label = g.name;
                            return g;
                        })
                        this.handleDrills(groups[0].id, authors)
                        this.handlePhysicalDrills(groups[0].id, authors)
                    }
                    this.setState({ groups, authors })
                })
            })
            this.handlePhysicalSessionTemplates()
            this.handleTrainingSessionTemplates()
        }
    }

    handleDrills(groupId, authors) {
        loadTrainingDrillsByGroup(groupId, authors).then((exercices) => {
            this.setState({ exercices, selectedGroup: groupId })
        });
    }

    handlePhysicalDrills(groupId, authors) {
        loadPhysicalDrillsByGroup(groupId, authors).then((exercicesPhysical) => {
            this.setState({ exercicesPhysical, selectedGroup: groupId })
        });
    }

    changeSelectedGroup(groupId, authors) {
        getTrainingDrillsByGroup(groupId).then((exercices) => {
            if (exercices) {
                exercices.sort((a, b) =>
                    a.trainingTheme.trainingCategory.name === b.trainingTheme.trainingCategory.name ? (
                        a.trainingTheme.name === b.trainingTheme.name ? (
                            a.name === b.name ? 0 : a.name > b.name ? 1 : -1
                        ) : a.trainingTheme.name > b.trainingTheme.name ? 1 : -1
                    ) : a.trainingTheme.trainingCategory.name > b.trainingTheme.trainingCategory.name ? 1 : -1
                );
                this.setState({ exercices })
            }
        })
        getPhysicalDrillsByGroup(groupId).then((exercicesPhysical) => {
            if (exercicesPhysical) {
                exercicesPhysical.sort((a, b) =>
                    a.physicalTheme.physicalCategory.name === b.physicalTheme.physicalCategory.name ? (
                        a.physicalTheme.name === b.physicalTheme.name ? (
                            a.name === b.name ? 0 : a.name > b.name ? 1 : -1
                        ) : a.physicalTheme.name > b.physicalTheme.name ? 1 : -1
                    ) : a.physicalTheme.physicalCategory.name > b.physicalTheme.physicalCategory.name ? 1 : -1
                );
                this.setState({ exercicesPhysical })
            }
        })
        this.setState({ selectedGroup: groupId })
        if (navigator.onLine) {
            loadPhysicalDrillsByGroup(groupId, authors).then((exercicesPhysical) => {
                this.setState({ exercicesPhysical })
            });
            loadTrainingDrillsByGroup(groupId, authors).then((exercices) => {
                this.setState({ exercices })
            });
        }
    }

    handleTrainingSessionTemplates() {
        loadTrainingSessionTemplates().then((sessionTemplatesTraining) => {
            this.setState({ sessionTemplatesTraining })
        })
    }

    handlePhysicalSessionTemplates() {
        loadPhysicalSessionTemplates().then((sessionTemplatesPhysical) => {
            this.setState({ sessionTemplatesPhysical })
        })
    }
    
    render() {
        const { t } = this.props;
        // var { exercicesPhysical, sessionTemplatesPhysical } = this.state;
        return (
            <Page
                tab={[
                    {
                        title: t("Training"),
                        visible: true,
                        data: this.state.exercices,
                        component: (data) => {
                            return (this.state.selectedGroup ?
                                <>
                                    <SubTab key="training"
                                        tab={[
                                            { key: "exercicest", label: t("Drills") },
                                            { key: "sessiont", label: t("Typical sessions") }
                                        ]}
                                        selectedTab={this.state.trainingView}
                                        onChange={(key) => this.setState({ trainingView: key })}
                                    />
                                    {this.state.tennisView === "exercicest" ?
                                        <ListTennisDrills
                                            exercises={data}
                                            type={"training"}
                                            onSelectorChange={(res) => this.changeSelectedGroup(res, this.state.authors)}
                                            groups={this.state.groups}
                                            selectedGroup={this.state.selectedGroup}
                                            refresh={() => this.handlePhysicalDrills(this.state.selectedGroup, this.state.authors)}
                                            buttons={[<GeneratedForm
                                                key="createTrainingDrill"
                                                component={(show) => (
                                                    <ButtonLight
                                                        onClick={show}
                                                        disabled={!navigator.onLine}
                                                    >
                                                        {t("Add training drill")}
                                                    </ButtonLight>
                                                )}
                                                callback={(res) => this.handleDrills(this.state.selectedGroup, this.state.authors)}
                                                formName="createTrainingDrill"
                                                formData={{
                                                    groupId: this.state.selectedGroup,
                                                    authorId: this.state.userId,
                                                    system: null,
                                                    instruction: null,
                                                    picture: null,
                                                    clubId: this.state.clubId
                                                }}
                                            />]}
                                        />
                                        : ""}
                                    {this.state.tennisView === "sessiont" ?
                                        <TableSessionTemplates
                                            sessions={this.state.sessionTemplatesTraining}
                                            groups={this.state.groups}
                                            type={"training"}
                                            callback={(res) => this.handleTrainingSessionTemplates()}
                                            buttons={[<GeneratedForm
                                                key="createTrainingSessionTemplates"
                                                component={(show) => (
                                                    <ButtonLight
                                                        onClick={show}
                                                        disabled={!navigator.onLine}
                                                    >
                                                        {t("Create training session template")}
                                                    </ButtonLight>
                                                )}
                                                callback={(res) => this.handleTrainingSessionTemplates()}
                                                formName="createTrainingSessionTemplates"
                                                formData={{
                                                    groupId: this.state.selectedGroup,
                                                    authorId: this.state.userId,
                                                    system: null,
                                                    instruction: null,
                                                    picture: null,
                                                    clubId: this.state.clubId
                                                }}
                                            />]}
                                        />
                                        : ""
                                    }
                                </>
                                : ""
                            );
                        },
                    },
                    {
                        title: t("Physique"),
                        visible: true,
                        data: this.state.exercicesPhysical,
                        component: (data) => {
                            return (<>
                                <SubTab
                                    key="physique"
                                    tab={[
                                        { key: "exercicesp", label: t("Drills") },
                                        { key: "sessionp", label: t("Typical sessions") },
                                    ]}
                                    selectedTab={this.state.physicView}
                                    onChange={(key) => this.setState({ physicView: key })}
                                />
                                {this.state.physicView === "exercicesp" ?
                                    <ListTennisDrills
                                        exercises={data}
                                        type={"physical"}
                                        onSelectorChange={(res) => this.changeSelectedGroup(res, this.state.authors)}
                                        refresh={() => this.handlePhysicalDrills(this.state.selectedGroup, this.state.authors)}
                                        groups={this.state.groups}
                                        selectedGroup={this.state.selectedGroup}
                                        buttons={[<GeneratedForm
                                            key="createPhysicalDrill"
                                            component={(show) => (
                                                <ButtonLight
                                                    onClick={show}
                                                    disabled={!navigator.onLine}
                                                >
                                                    {t("Add physical drill")}
                                                </ButtonLight>
                                            )}
                                            callback={(res) => this.handlePhysicalDrills(this.state.selectedGroup, this.state.authors)}
                                            formName="createPhysicalDrill"
                                            formData={{
                                                groupId: this.state.selectedGroup,
                                                authorId: this.state.userId,
                                                system: null,
                                                instruction: null,
                                                picture: null,
                                                clubId: this.state.clubId
                                            }}
                                        />]}
                                    />
                                    : ""}
                                {this.state.physicView === "sessionp" ?
                                    <TableSessionTemplates
                                        sessions={this.state.sessionTemplatesPhysical}
                                        groups={this.state.groups}
                                        type={"physical"}
                                        callback={(res) => this.handlePhysicalSessionTemplates()}
                                        buttons={[<GeneratedForm
                                            key="createPhysicalSessionTemplates"
                                            component={(show) => (
                                                <ButtonLight
                                                    onClick={show}
                                                    disabled={!navigator.onLine}
                                                >
                                                    {t("Create physical session template")}
                                                </ButtonLight>
                                            )}
                                            callback={(res) => this.handlePhysicalSessionTemplates()}
                                            formName="createPhysicalSessionTemplates"
                                            formData={{
                                                groupId: this.state.selectedGroup,
                                                authorId: this.state.userId,
                                                system: null,
                                                instruction: null,
                                                picture: null,
                                                clubId: this.state.clubId
                                            }}
                                        />]}
                                    />
                                    : ""
                                }
                            </>
                            );
                        },
                    },
                ]}
            />
        );
    }
}

export default withTranslation()(withRouter(Training));
