import React from "react";
import Button from "./Button";
class ButtonLight extends React.Component {
  render() {
    return (
      <Button
        className="inline-flex items-center px-3 2xl:px-4 py-1 2xl:py-2 mx-2 2xl:mx-4 rounded-md text-sm 2xl:text-md scale-50 2xl:scale-100 font-medium border text-indigo-700 border-gray-300 hover:bg-indigo-700 hover:text-white hover:border-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        {...this.props}
      />
    );
  }
}

export default ButtonLight;
