import React from "react";
import ActionButton from "../components/buttons/ActionButton";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { SelectMultiple } from "../components/forms";
import TableSimple from "../components/table/TableSimple";
import FiltersSearch from "../components/filters/FiltersSearch";
import SearchInput from "../components/forms/input/SearchInput";
import FiltersDropDowns from "../components/filters/FiltersDropDowns";
import FilterColumns from "../components/filters/FilterColumns";
import generateColor from "../utils/generateColor";
import FilterSeparator from "../components/filters/FilterSeparator";
import FiltersTitle from "../components/filters/FiltersTitle";
import FiltersBar from "../components/filters/FiltersBar";

class TableSchoolCurriculums extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actions: [],
      schools: [],
      selectedSchools: [],
      types: [],
      selectedTypes: [],
      columns: [],
      searchText: "",
    };
  }

  componentDidMount() {
    this.prepareData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.prepareData();
    }
  }

  prepareData = () => {
    var curriculums = this.props.curriculums;
    var types = [];
    var selectedTypes = [];

    if (curriculums) {
      curriculums = curriculums.map((curriculum) => {
        if (types && !types.find((o) => o.key === curriculum.type.id)) {
          types.push({
            key: curriculum.type.id,
            label: curriculum.type.name,
          });
          selectedTypes.push(curriculum.type.id);
        }
        return curriculum;
      });
    }

    const columns = [
      {
        key: "name",
        visible: true,
        label: this.props.t("Name"),
        pinned: "left",
        id: "name",
        format: (row) => {
          return (
            <>
            <div className="flex justify-between">
            <Link
                className="text-indigo-700 font-medium text-xs 2xl:text-sm"
                to={"/administration/curriculum/" + row.id}
              >
                {row.shortName}
              </Link>
            <ActionButton
            key={row.id}
            updateForm="updateCurriculum"
            deleteForm="deleteCurriculum"
            formData={row}
            refreshData={this.props.refresh}
          />
            </div>
              
            </>
          );
        },
      },
      {
        key: "type",
        visible: true,
        label: this.props.t("Type"),
        pinned: "left",
        id: "type",
        format: (row) => {
          return <>
            <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs 2xl:text-sm font-medium bg-yellow-100 text-yellow-800" style={{color: "#FFFFFF", backgroundColor: generateColor(row.type.name)}}>
            {row.type ? row.type.name : "-"}</span></>;
        },
      },
      {
        key: "description",
        visible: true,
        label: this.props.t("Description"),
        pinned: "left",
        id: "description",
        format: (row) => {
          return <><span className=" text-xs 2xl:text-sm">{row.description}</span></>;
        },
      }
    ];
    var selectedColumns = [];
    columns.forEach((column) => {
      if (column.visible) {
        selectedColumns.push(column.key);
      }
    });
    this.setState({
      columns,
      curriculums,
      types,
      selectedColumns,
      selectedTypes,
    });
  };

  render() {
    const { t } = this.props;
    var curriculums = this.props.curriculums;
    var columns = this.state.columns;
    var selectedColumns = this.state.selectedColumns;
    var selectedTypes = this.state.selectedTypes;
    var searchText = this.state.searchText;
    if (columns) {
      columns = columns.filter((o) =>
        selectedColumns.some((item) => item === o.key)
      );
    }
    if (curriculums) {
      curriculums = curriculums.filter((o) =>
        selectedTypes.some((item) => item === o.type.id)
      );
    }
    if (searchText.length > 2) {
      curriculums = curriculums.filter((o) =>
        o.name.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    return (
      <>
        <FiltersBar
          components={
            <>
              <FiltersTitle title={curriculums
                  ? curriculums.length + " " + t("Curriculums").toLowerCase()
                  : ""}
              />
              <FilterSeparator />
              <FiltersSearch search={
                  <SearchInput
                    value={this.state.searchText}
                    onChange={(searchText) => this.setState({ searchText })}
                  />
                }
              />
              <FilterSeparator />
              <FiltersDropDowns selects={[
                <SelectMultiple
                  options={this.state.types}
                  keys={this.state.selectedTypes}
                  className="mx-2"
                  onChange={(res) => this.setState({ selectedTypes: res })}
                  fixedLabel={t("Type")}
                />
                ]} 
              />
              <FilterSeparator />
              <FilterColumns
                columns={
                  <SelectMultiple
                    options={this.state.columns}
                    keys={this.state.selectedColumns}
                    className="mx-2"
                    onChange={this.onChangeSelectedColumns}
                    fixedLabel={t("Active columns")}
                  />
                }
              />
            </>
          }
        />
        <TableSimple columns={columns} data={curriculums} />
      </>
    );
  }
}

export default withTranslation()(TableSchoolCurriculums);
