import React from "react";
import ReactFusioncharts from "react-fusioncharts";
import Card from "../../card/Card";

const SpiderChart = ({ dataSource, title }) => {
  return (
    <div
      className="flex items-center justify-center pt-1"
      style={{ boxShadow: "0 0 10px 0 rgba(82,63,105,.1)" }}
    >
      <Card className="" title={title}>
        <ReactFusioncharts
          type="radar"
          width="600"
          height="600"
          dataFormat="JSON"
          dataSource={dataSource}
        />
      </Card>
    </div>
  );
};

export default SpiderChart;
