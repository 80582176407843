import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import {
  getCompetitionsByPlayer,
  loadCompetitionsByPlayer,
} from "../../utils/indexedDB/Rugby-Competition/handleCompetitionRugby";
import TableTournamentsByPlayer from "../../tables/TableTournamentsByPlayer";

class TournamentsByplayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      competitions: [],
      getCompetitionsByPlayerFromAPi: false,
    };
  }

  componentDidMount() {
    var playerId = this.props.playerId;
    this.prepareData();
    this.handleCompetitionsByPlayer(playerId);
  }

  handleCompetitionsByPlayer = (playerId) => {
    this.setState({ getCompetitionsByPlayerFromAPi: false });
    getCompetitionsByPlayer(playerId).then((competitions) =>
      competitions &&
        competitions.sort((a, b) =>
          a.position.number === b.position.number
            ? 0
            : a.position.number < b.position.number
              ? -1
              : 1
        ) &&
        !this.state.getCompetitionsByPlayerFromAPi
        ? this.setState({ competitions })
        : null
    );
    if (navigator.onLine) {
      loadCompetitionsByPlayer(playerId).then((competitions) => {
        if (competitions) {
          this.setState({
            competitions,
            getCompetitionsByPlayerFromAPi: true,
          });
        }
      });
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.prepareData();
    }
  }

  prepareData = () => {
    var competitions = this.props.competitions;

    this.setState({
      competitions,
    });
  };

  render() {
    return <TableTournamentsByPlayer competitions={this.state.competitions} />;
  }
}

export default withTranslation()(withRouter(TournamentsByplayer));