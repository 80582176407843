import React from "react";
import ActionButton from "../components/buttons/ActionButton";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { SelectMultiple } from "../components/forms";
import TableSimple from "../components/table/TableSimple";
import ExportToCSV from "../components/export/ExportToCSV";
import FilterColumns from "../components/filters/FilterColumns";
import FiltersDropDowns from "../components/filters/FiltersDropDowns";
import FiltersSearch from "../components/filters/FiltersSearch";
import SearchInput from "../components/forms/input/SearchInput";
import generateColor from "../utils/generateColor";
import FilterSeparator from "../components/filters/FilterSeparator";
import FiltersTitle from "../components/filters/FiltersTitle";
import FiltersBar from "../components/filters/FiltersBar";


class TableCollections extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actions: [],
      categories: [],
      selectedCategories: [],
      columns: [],
      searchText: "",
    };
  }

  componentDidMount() {
    this.prepareData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.prepareData();
    }
  }

  prepareData = () => {
    var collections = this.props.collections;
    var categories = [];
    var selectedCategories = [];
    if (collections) {
      collections = collections.map((collection) => {
        if (
          categories &&
          !categories.find((o) => o.key === collection.category)
        ) {
          categories.push({
            key: collection.category,
            label: collection.category,
          });
          selectedCategories.push(collection.category);
        }
        return collection;
      });
    }

    const columns = [
      {
        key: "name",
        visible: true,
        label: this.props.t("Collection"),
        id: "name",
        width: 350,
        format: (row) => (
          <>
          <div className="flex justify-between">
          <Link
              className="text-indigo-700 font-semibold text-xs 2xl:text-sm"
              to={"/videos/" + row.id}
            >
              {row.name}
            </Link>
            <ActionButton
              key={row.id}
              updateForm="updateCollection"
              deleteForm="deleteCollection"
              formData={row}
              refreshData={() => this.props.updateCollections(this.props.pId)}
            />
          </div>
          </>
        ),
      },
      {
        key: "description",
        visible: true,
        label: this.props.t("Description"),
        id: "description",
        width: 280,
        format: (row) => (
          <span className="font-weight-bold text-muted text-xs 2xl:text-sm">{row.description}</span>
        ),
      },
      {
        key: "category",
        visible: true,
        label: this.props.t("Category"),
        id: "category",
        width: 280,
        format: (row) => (
          <span  className="inline-flex items-center px-3 py-0.5 rounded-full  text-xs 2xl:text-sm font-medium bg-yellow-100 text-yellow-800" style={{color: "#FFFFFF", backgroundColor: generateColor(row.category ? row.category : " ")}}>
            {row.category}
          </span>
        ),
        filter: {
          name: "SelectColumnFilter",
          type: "includes",
        },
      },
      {
        key: "lastModificationDateDisplay",
        visible: true,
        label: this.props.t("lastModificationDate"),
        id: "lastModificationDateDisplay",
        width: 250,
        format: (row) => (
          <span className="font-weight-bold text-muted text-xs 2xl:text-sm">
            {row.lastModificationDateDisplay}
          </span>
        ),
      },
      {
        key: "videos",
        visible: true,
        label: this.props.t("Videos"),
        width: 250,
        format: (row) => (
          <span className="font-weight-bold text-muted text-xs 2xl:text-sm">
            {row.files.length + " " + this.props.t("videos")}
          </span>
        ),
      }
    ];
    var selectedColumns = [];
    columns.forEach((column) => {
      if (column.visible) {
        selectedColumns.push(column.key);
      }
    });
    this.setState({
      columns,
      collections,
      categories,
      selectedColumns,
      selectedCategories,
    });
  };

  render() {
    const { t } = this.props;
    var collections = this.props.collections;
    var columns = this.state.columns;
    var selectedColumns = this.state.selectedColumns;
    var selectedCategories = this.state.selectedCategories;
    var searchText = this.state.searchText;
    if (columns) {
      columns = columns.filter((o) =>
        selectedColumns.some((item) => item === o.key)
      );
    }
    if (collections) {
      collections = collections.filter((o) =>
        selectedCategories.some((item) => item === o.category)
      );
    }
    if (searchText.length > 2) {
      collections = collections.filter((o) =>
        o.name.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    return (
      <>
        <FiltersBar
          components={
            <>
              <FiltersTitle title={collections
                  ? collections.length + " " + t("Collections").toLowerCase()
                  : ""}
              />
              <FilterSeparator />
              <FiltersSearch search={<SearchInput
                value={this.state.searchText}
                onChange={(searchText) => this.setState({ searchText })}
              />} />
              <FilterSeparator />
              <FiltersDropDowns selects={[
                <SelectMultiple
                  options={this.state.categories}
                  keys={this.state.selectedCategories}
                  className="mx-2"
                  onChange={(res) => this.setState({ selectedCategories: res })}
                  fixedLabel={t("Category")}
                />
                ]} 
              />
              <FilterSeparator />
              <FilterColumns
                columns={
                  <SelectMultiple
                    options={this.state.columns}
                    keys={this.state.selectedColumns}
                    className="mx-2"
                    onChange={(res) => this.setState({ selectedColumns: res })}
                    fixedLabel={t("Active columns")}
                  />
                }
              />
              <FilterSeparator />
              <ExportToCSV columns={columns} jsonData={collections} fileName={t("Collections") + ".csv"} />
            </>
          }
          buttons={this.props.buttons}
        />
        <TableSimple columns={columns} data={collections} />
      </>
    );
  }
}

export default withTranslation()(TableCollections);
