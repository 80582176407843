import React from "react";
import { withTranslation } from "react-i18next";
import * as Drawer from "../../components/drawer";
import Button from "../../components/buttons/Button";
import getAccessToken from "../auth/getAccessToken";
import { loadStaff, loadPlayersByGroup, loadPlayersByStaff } from "../indexedDB/users/handleUser";
import TableStaffRightsOnPlayers from "../../tables/TableStaffRightsOnPlayers";
import { del, post } from "../requests";
import cogoToast from "cogo-toast";

var jwt = require('jsonwebtoken');

class FormsRightsStaff extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rights: [
                "Manager",
                "Administratif",
                "Assistant",
                "Commercial",
                "Finance",
                "President",
                "Scolarite",
                "Tuteur"
            ],
            players: null,
            playersSelected: null,
            playersOriginalSelected: null
        }
    }

    componentDidMount() {
        getAccessToken().then(accessToken => {
            this.setState({ accessToken: jwt.decode(accessToken) });
        })
    }

    loadData() {
        this.setState({ loading: true });
        var id = this.props.id;
        loadStaff(id).then(staff => {
            if (staff.groups && staff.groups.length) {
                var promises = [];
                staff.groups.forEach(g => {
                    if (g.id) {
                        promises.push(loadPlayersByGroup(g.id).then(players => {
                            players = players.map(p => {
                                p.group = g;
                                return p;
                            })
                            return players;
                        }));
                    }
                })
                Promise.all(promises).then(playersList => {
                    var players = [];
                    playersList.forEach(pL => {
                        pL.forEach(p => {
                            if (!players.includes(p)) {
                                players.push(p);
                            }
                        })
                    });
                    this.setState({ players, loading: false });
                })
            }
        });
        loadPlayersByStaff(id).then(players => {
            this.setState({ playersOriginalSelected: players.map(p => p.id), playersSelected: players.map(p => p.id), loading: false });
        })
    }

    initializeComponent() {
        this.setState({
            players: null,
            playersSelected: null,
            playersOriginalSelected: null
        })
    }

    handleSubmit(hide) {
        this.setState({ loading: true })
        var staffId = this.props.id;
        var promises = [];
        var playersOriginalSelected = this.state.playersOriginalSelected;
        var playersSelected = this.state.playersSelected;
        playersOriginalSelected.forEach(pO => {
            if (!playersSelected.includes(pO)) {
                promises.push(del("group", "staffsPlayersAuthorizations", staffId + "/" + pO));
            }
        });
        playersSelected.forEach(pS => {
            if (!playersOriginalSelected.includes(pS)) {
                promises.push(post("group", "staffsPlayersAuthorizations", staffId + "/" + pS))
            }
        })

        Promise.all(promises).then((res) => {
            if (res) {
                var errors = [];
                res.forEach(r => {
                    if (!r.ok) {
                        errors.push(r.status + " - " + r.url);
                    }
                })
                if (errors && errors.length) {
                    this.setState({ errors, loading: false });
                } else {
                    cogoToast.success(this.props.t("Rights update succeeded"), { position: "bottom-right" });
                    this.setState({ loading: false });
                    hide();
                    if(this.props.callback){
                        this.props.callback();
                    }
                }
            }
        })

    }

    render() {
        const { t } = this.props;
        return (
            <Drawer.Layout
                className="w-screen"
                component={(show) => this.props.component(() => {
                    show();
                    this.loadData();
                },
                    !navigator.onLine || (navigator.onLine && this.state.accessToken && this.state.rights && !this.state.rights.includes(this.state.accessToken.role)),
                    !navigator.onLine ? t("Navigator offline") : ((navigator.onLine && this.state.accessToken && this.state.rights && !this.state.rights.includes(this.state.accessToken.role)) ? t("Not enough rights") : null))}
                hide={() => {
                    if (this.props.hide) {
                        this.props.hide();
                    }
                    this.initializeComponent();
                }}
            >
                {(hide) => <>
                    <Drawer.Header hide={hide}>
                        {t("Handle rights staff on player")}
                    </Drawer.Header>
                    <Drawer.Body>
                        <>
                            {this.state.errors ? <div className="rounded-md bg-red-50 p-4">
                                <div className="flex">
                                    <div className="flex-shrink-0">
                                        <svg className="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
                                        </svg>
                                    </div>
                                    <div className="ml-3">
                                        <h3 className="text-sm font-medium text-red-800">
                                            {t("Error")}
                                        </h3>
                                        <div className="mt-2 text-sm text-red-700">
                                            <ul className="list-disc pl-5 space-y-1">
                                                {this.state.errors.map((error, key) => {
                                                    return <li key={key}>{error}</li>;
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div> : (
                                    ""
                                )}
                            <TableStaffRightsOnPlayers
                                players={this.state.players}
                                playersSelected={this.state.playersSelected}
                                onChange={(value, id) => {
                                    var playersSelected = this.state.playersSelected;
                                    if (value) {
                                        if (!playersSelected.includes(id)) {
                                            playersSelected.push(id);
                                            this.setState({ playersSelected });
                                        }
                                    } else {
                                        if (playersSelected.includes(id)) {
                                            playersSelected = playersSelected.filter(p => p !== id);
                                            this.setState({ playersSelected });
                                        }
                                    }
                                }}
                            />
                        </>
                    </Drawer.Body>
                    <Drawer.Footer>
                        <>
                            <Button
                                loading={this.state.loading}
                                onClick={() => {
                                    this.handleSubmit(hide);
                                }}
                            >
                                {t("Submit")}
                            </Button>
                        </>
                    </Drawer.Footer>
                </>
                }
            </Drawer.Layout>
        )
    }
}

export default withTranslation()(FormsRightsStaff);