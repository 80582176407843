import React from "react";
import ActionButton from "../components/buttons/ActionButton";
import { withTranslation } from "react-i18next";
import { Select, SelectMultiple } from "../components/forms";
import TableSimple from "../components/table/TableSimple";
import FilterColumns from "../components/filters/FilterColumns";
import FiltersDropDowns from "../components/filters/FiltersDropDowns";
import FiltersSearch from "../components/filters/FiltersSearch";
import SearchInput from "../components/forms/input/SearchInput";
import IconRound32x32 from "../components/icons/IconRound32x32";
import { Link } from "react-router-dom";
import generateColor from "../utils/generateColor";
import FilterSeparator from "../components/filters/FilterSeparator";
import FiltersTitle from "../components/filters/FiltersTitle";
import FiltersBar from "../components/filters/FiltersBar";
// import DropdownSelector from "../components/forms/dropdown/DropdownSelector";


class TableFederalSupport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actions: [],
      statuses: [],
      selectedStatuses: [],
      columns: [],
      searchText: "",
    };
  }

  componentDidMount() {
    this.prepareData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.prepareData();
    }
  }

  prepareData = () => {
    var statuses = [];
    var programs = [];
    var tCenters = [];
    var selectedStatuses = [];
    var selectedPrograms = [];
    var selectedTCenters = [];
    var players = this.props.players

    //  chercher les elements a déselectionner du localstorage
    var unselectedStatuses = localStorage.getItem("TableFederalSupport_unselectedStatuses")
    if (unselectedStatuses) unselectedStatuses = unselectedStatuses.split("|")
    var unselectedPrograms = localStorage.getItem("TableFederalSupport_unselectedPrograms")
    if (unselectedPrograms) unselectedPrograms = unselectedPrograms.split("|")
    var unselectedTCenters = localStorage.getItem("TableFederalSupport_unselectedTCenters")
    if (unselectedTCenters) unselectedTCenters = unselectedTCenters.split("|")

    if (players) {
      players.map((player) => {
        if (statuses && !statuses.find((o) => o.key === player.federalSupportStatusId)) {
          statuses.push({
            key: player.federalSupportStatusId,
            label: player.federalSupportStatus.name,
          });
          // only add to selected if not unselected
          if (
            !unselectedStatuses ||
            unselectedStatuses.indexOf(player.federalSupportStatusId.toString()) < 0
          )
            selectedStatuses.push(player.federalSupportStatusId);
        }
        if (programs && !programs.find((o) => o.key === player.federalSupportProgrammeId)) {
          programs.push({
            key: player.federalSupportProgrammeId,
            label: player.federalSupportProgramme.name,
          });
          // only add to selected if not unselected
          if (
            !unselectedPrograms ||
            unselectedPrograms.indexOf(player.federalSupportProgrammeId.toString()) < 0
          )
            selectedPrograms.push(player.federalSupportProgrammeId);
        }
        if (tCenters && !tCenters.find((o) => o.key === player.trainingCenterId)) {
          tCenters.push({
            key: player.trainingCenterId,
            label: player.trainingCenter.name ? player.trainingCenter.name : "N/A",
          });
          // only add to selected if not unselected
          if (
            !unselectedTCenters ||
            unselectedTCenters.indexOf(player.trainingCenterId.toString()) < 0
          )
            selectedTCenters.push(player.trainingCenterId);
        }
        return player;
      });
    }

    const columns = [
      {
        key: "displayName",
        visible: true,
        pinned: "left",
        label: this.props.t("Player"),
        id: "displayName",
        width: 300,
        format: (row) => (
          <span className="flex justify-between">
            <span className="text-primary font-weight-bolder mb-0">
              <Link
                className="text-indigo-700 font-semibold text-xs 2xl:text-sm"
                to={"/group/player/" + row.playerId}
              >
                <IconRound32x32 photo={row.photo} />
                <span className="ml-5">
                  {row.displayName}
                </span>
              </Link>
            </span>
            {navigator.onLine && this.props.role === "Manager" ?
            <ActionButton
              key={row.id}
              updateForm="updateFederalSupport"
              deleteForm="deleteFederalSupport"
              formData={row}
              refreshData={
                this.props.refresh
              }
            /> : ""}
          </span>

        ),
      },
      {
        key: "federalSupportStatusId",
        visible: true,
        label: this.props.t("Status"),
        id: "federalSupportStatusId",
        format: (row) => (
          <span className="font-weight-bold text-muted text-xs 2xl:text-sm">{row.federalSupportStatus ? row.federalSupportStatus.name : "-"}</span>
        ),
      },
      {
        key: "federalSupportProgrammeId",
        visible: true,
        label: this.props.t("Program"),
        id: "federalSupportProgrammeId",
        format: (row) => (
          <span  className="inline-flex items-center px-3 py-0.5 rounded-full text-xs 2xl:text-sm font-medium bg-yellow-100 text-yellow-800" style={{color: "#FFFFFF", backgroundColor: generateColor(row.federalSupportProgrammeId ? row.federalSupportProgrammeId : "")}}>
            {row.federalSupportProgramme ? row.federalSupportProgramme.name : "-"}
          </span>
        ),
      },
      {
        key: "trainingCenterId",
        visible: true,
        label: this.props.t("Training Center"),
        id: "trainingCenterId",
        format: (row) => (
          <span className="font-weight-bold text-muted text-xs 2xl:text-sm">{row.trainingCenter ? row.trainingCenter.name : "-"}</span>
        ),
      },
      {
        key: "comments",
        visible: true,
        label: this.props.t("Comments"),
        id: "comments",
        format: (row) => (
          <span className="font-weight-bold text-muted text-xs 2xl:text-sm">{row.comments}</span>
        ),
      },
    ];

    var selectedColumns = [];
    columns.forEach((column) => {
      if (column.visible) {
        selectedColumns.push(column.key);
      }
    });
    this.setState({
      columns,
      statuses,
      programs,
      tCenters,
      selectedColumns,
      selectedStatuses,
      selectedPrograms,
      selectedTCenters
    });
  };

  render() {
    const { t } = this.props;
    var players = this.props.players;
    var { columns, statuses, programs, tCenters,
      selectedColumns, selectedStatuses, selectedPrograms, selectedTCenters
    } = this.state;
    var searchText = this.state.searchText;
    if (columns) {
      var displayColumns = columns.filter((o) =>
        selectedColumns.some((item) => item === o.key)
      );
    }
    if (players) {
      players = players.filter((o) =>
        selectedStatuses.some((item) => item === o.federalSupportStatusId)
      );
    }
    if (players) {
      players = players.filter((o) =>
        selectedPrograms.some((item) => item === o.federalSupportProgrammeId)
      );
    }
    if (players) {
      players = players.filter((o) =>
        selectedTCenters.some((item) => item === o.trainingCenterId)
      );
    }
    if (searchText.length > 2) {
      players = players.filter((o) =>
        o.displayName.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    return (
      <>
        <FiltersBar
          components={
            <>
              <FiltersTitle title={players
                  ? players.length + " " + (players.length > 1 ? t("Supports").toLowerCase() : t("Support").toLowerCase())
                  : ""}
              />
              {this.props.playerCardVue ? "" :
                <>
                  <FilterSeparator />
                  <Select
                    options={this.props.seasons}
                    value={this.props.selectedSeason.id}
                    className="mx-2"
                    onChange={(res) => this.props.changeSeason(res)}
                    fixedLabel={t("Period")}
                  />
                </>
              }
              <FilterSeparator />
              <FiltersSearch search={<SearchInput
                value={this.state.searchText}
                onChange={(searchText) => this.setState({ searchText })}

              />} />
              <FilterSeparator />
              <FiltersDropDowns selects={[
                <SelectMultiple
                  options={statuses}
                  keys={selectedStatuses}
                  className="mx-2"
                  onChange={(res) => this.setState({ selectedStatuses: res }, () => {
                    // determiner ce qui n'est pas selectionner
                    let unselect = []
                    this.state.statuses.forEach((a) => {
                      if (this.state.selectedStatuses.indexOf(a.key) < 0) unselect.push(a.key)
                    })
                    // save unselected elements to localstorage
                    localStorage.setItem("TableFederalSupport_unselectedStatuses", unselect.join("|"))
                  })}
                  fixedLabel={t("Status")}
                />,
                <SelectMultiple
                  options={programs}
                  keys={selectedPrograms}
                  className="mx-2"
                  onChange={(res) => this.setState({ selectedPrograms: res }, () => {
                    // determiner ce qui n'est pas selectionner
                    let unselect = []
                    this.state.programs.forEach((a) => {
                      if (this.state.selectedPrograms.indexOf(a.key) < 0) unselect.push(a.key)
                    })
                    // save unselected elements to localstorage
                    localStorage.setItem("TableFederalSupport_unselectedPrograms", unselect.join("|"))
                  })}
                  fixedLabel={t("Programs")}
                />,
                <SelectMultiple
                  options={tCenters}
                  keys={selectedTCenters}
                  className="mx-2"
                  onChange={(res) => this.setState({ selectedTCenters: res }, () => {
                    // determiner ce qui n'est pas selectionner
                    let unselect = []
                    this.state.tCenters.forEach((a) => {
                      if (this.state.selectedTCenters.indexOf(a.key) < 0) unselect.push(a.key)
                    })
                    // save unselected elements to localstorage
                    localStorage.setItem("TableFederalSupport_unselectedTCenters", unselect.join("|"))
                  })}
                  fixedLabel={t("Training Centers")}
                />
              ]}
              />
              <FilterSeparator />
              <FilterColumns
                columns={
                  <SelectMultiple
                    options={columns}
                    keys={selectedColumns}
                    className="mx-2"
                    onChange={(res) => this.setState({ selectedColumns: res })}
                    fixedLabel={t("Active columns")}
                  />
                }
              />
              <FilterSeparator />
            </>
          }
          buttons={this.props.buttons}
        />
        <TableSimple columns={displayColumns} data={players} />
      </>
    );
  }
}

export default withTranslation()(TableFederalSupport);
