import React from "react";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import { getPlayer } from "../../utils/indexedDB/users/handleUser";
import GeneratedForm from "../../utils/forms/generateForm";
import {
  getTargets,
  loadTargets,
  loadPeriodicReportsByPlayer,
  getPeriodicReportsByPlayer,
} from "../../utils/indexedDB/management/handleManagement";
import Card from "../../components/card/Card";
import SubTab from "../../components/tab/SubTab";
import {
  loadAnthropometricData,
  loadAnthropometricDataCurrent,
  loadAnthropometricMeasuresActive,
  loadAnthropometricTargets,
  loadPhysicalTestsData,
  loadPhysicalTestsDataCurrent,
  loadPhysicalTestsMeasuresActive,
} from "../../utils/indexedDB/assessment/handleAssessment";
import TablePlayerTargets from "../../tables/TablePlayerTargets";
import ButtonLight from "../../components/buttons/ButtonLight";
import TablePlayerAnthropometricData from "../../tables/TablePlayerAnthropometricData";
import TablePlayerAnthropometricDataCurrent from "../../tables/TablePlayerAnthropometricDataCurrent";
import TablePlayerAnthropometricTargets from "../../tables/TablePlayerAnthropometricTargets";
import TablePlayerPhysicalTestsDataCurrent from "../../tables/TablePlayerPhysicalTestsDataCurrent";
import TablePlayerPhysicalTestsData from "../../tables/TablePlayerPhysicalTestsData";
import DropdownSelector from "../../components/forms/dropdown/DropdownSelector";
import TablePeriodicReport from "../../tables/TablePeriodicReport";

class PlayerManagementTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.playerId,
      managementTab: "Targets",
      targetsView: "Current targets",
      anthropometricView: "Latest results",
      physicalTestsView: "Latest results",
    };
    this.updateTargets = this.updateTargets.bind(this);
  }
  componentDidMount() {
    // get id from url
    var id = this.props.playerId;
    // function get from indexedDB
    getTargets(id).then((targets) =>
      targets ? this.setState({ targets }) : null
    );
    getPlayer(id).then((player) => (player ? this.setState({ player }) : null));
    getPeriodicReportsByPlayer(id).then((playerPR) => {
      this.setState({ playerPR });
    });

    if (navigator.onLine) {
      //function get from database
      loadTargets(id).then((targets) =>
        targets ? this.setState({ targets }) : null
      );
      loadPhysicalTestsMeasuresActive().then((physicalTestsMeasures) => {
        this.setState({ physicalTestsMeasures });
        var physicalTestsMeasuresList = [];
        if (physicalTestsMeasures && physicalTestsMeasures.length > 0) {
          this.setState({
            physicalTestsMeasureId: physicalTestsMeasures[0].id,
          });
          physicalTestsMeasures.map((physicalTestsMeasure) =>
            physicalTestsMeasuresList.push({
              key: physicalTestsMeasure.id,
              label: physicalTestsMeasure.name,
            })
          );
          this.setState({ physicalTestsMeasuresList });
        }
      });
      loadPhysicalTestsDataCurrent(id).then((physicalTestsDataCurrent) =>
        this.setState({ physicalTestsDataCurrent })
      );
      loadPhysicalTestsData(id).then((physicalTestsData) =>
        this.setState({ physicalTestsData })
      );
      loadAnthropometricTargets(id).then((anthropometricTargets) =>
        this.setState({ anthropometricTargets })
      );
      loadAnthropometricMeasuresActive().then((anthropometricMeasures) => {
        this.setState({ anthropometricMeasures });
        var anthropometricMeasuresList = [];
        if (anthropometricMeasures && anthropometricMeasures.length > 0) {
          this.setState({
            anthropometricMeasureId: anthropometricMeasures[0].id,
          });
          anthropometricMeasures.map((anthropometricMeasure) =>
            anthropometricMeasuresList.push({
              key: anthropometricMeasure.id,
              label: anthropometricMeasure.name,
            })
          );
          this.setState({ anthropometricMeasuresList });
        }
      });
      loadAnthropometricDataCurrent(id).then((anthropometricDataCurrent) =>
        this.setState({ anthropometricDataCurrent })
      );
      loadAnthropometricData(id).then((anthropometricData) =>
        this.setState({ anthropometricData })
      );

      getPlayer(id).then((player) => {
        loadPeriodicReportsByPlayer(id, player).then((playerPR) => {
          this.setState({ playerPR });
        });
      });
    }
  }

  updateTargets(id) {
    loadTargets(id).then((targets) =>
      targets ? this.setState({ targets }) : null
    );
  }

  render() {
    const { t } = this.props;

    return !this.state.targets ? (
      <div>Loading</div>
    ) : (
      <>
        <SubTab
          tab={[
            { key: "Targets", label: t("Targets") },
            { key: "Anthropometry", label: t("Anthropometry") },
            { key: "PhysicalTests", label: t("Physical tests") },
            { key: "Bilan", label: t("Bilans Periodiques") },
          ]}
          selectedTab={this.state.managementTab}
          onChange={(key) =>
            this.setState({
              managementTab: key,
            })
          }
        />
        {this.state.managementTab === "Targets" ? (
          <TablePlayerTargets
            targets={this.state.targets}
            buttons={[
              <GeneratedForm
                component={(show, text) => (
                  <ButtonLight onClick={show} disabled={!navigator.onLine}>
                    <i className="flaticon2-plus"></i> {text}
                  </ButtonLight>
                )}
                callback={(res) =>
                  loadTargets(this.props.playerId).then((targets) =>
                    targets ? this.setState({ targets }) : null
                  )
                }
                formName="createObjectif"
                method="POST"
                formData={{
                  playerId: this.state.id,
                  playerIdname: this.props.player
                    ? this.props.player.displayName
                    : null,
                  eventId: null,
                }}
              />,
            ]}
            updateTargets={this.updateTargets}
            pId={this.props.playerId}
            player={this.props.player}
          />
        ) : (
          ""
        )}
        {this.state.managementTab === "Anthropometry" ? (
          <>
            <Card
              title={t("Anthropometry")}
              headerActions={
                <DropdownSelector
                  value={t(this.state.anthropometricView)}
                  className="btn btn-light-primary font-weight-bolder dropdown-toggle mr-5"
                  options={[
                    {
                      key: "Latest results",
                      label: t("Latest results"),
                    },
                    {
                      key: "All results",
                      label: t("All results"),
                    },
                    {
                      key: "Targets",
                      label: t("Targets"),
                    },
                  ]}
                  onChange={(key) => this.setState({ anthropometricView: key })}
                />
              }
            ></Card>
            {this.state.anthropometricView === "Latest results" && (
              <>
                <TablePlayerAnthropometricDataCurrent
                  data={this.state.anthropometricDataCurrent}
                  measures={this.state.anthropometricMeasures}
                  buttons={[
                    <GeneratedForm
                      component={(show, text) => (
                        <ButtonLight
                          onClick={show}
                          disabled={!navigator.onLine}
                        >
                          {text}
                        </ButtonLight>
                      )}
                      callback={(res) => {
                        loadAnthropometricDataCurrent(
                          this.props.match.params.id
                        ).then((anthropometricDataCurrent) =>
                          this.setState({ anthropometricDataCurrent })
                        );
                        loadAnthropometricData(this.props.match.params.id).then(
                          (anthropometricData) =>
                            this.setState({ anthropometricData })
                        );
                      }}
                      formName="createAnthropo"
                      method="POST"
                      formData={{
                        playerId: this.state.id,
                        playerIdname: this.props.player
                          ? this.props.player.displayName
                          : null,
                        eventId: null,
                      }}
                    />,
                  ]}
                />
              </>
            )}
            {this.state.anthropometricView === "Targets" ? (
              <TablePlayerAnthropometricTargets
                targets={this.state.anthropometricTargets}
                measures={this.state.anthropometricMeasures}
                refresh={() =>
                  loadAnthropometricTargets(this.state.id).then(
                    (anthropometricTargets) =>
                      this.setState({ anthropometricTargets })
                  )
                }
                buttons={[
                  <GeneratedForm
                    component={(show, text) => (
                      <ButtonLight onClick={show} disabled={!navigator.onLine}>
                        {text}
                      </ButtonLight>
                    )}
                    callback={() =>
                      loadAnthropometricTargets(this.state.id).then(
                        (anthropometricTargets) =>
                          this.setState({ anthropometricTargets })
                      )
                    }
                    formName="createAnthropometricTarget"
                    method="POST"
                    formData={{
                      playerId: this.state.id,
                      playerIdname: this.props.player
                        ? this.props.player.displayName
                        : null,
                    }}
                  />,
                ]}
              />
            ) : (
              ""
            )}
            {this.state.anthropometricView === "All results" &&
            this.state.anthropometricMeasureId ? (
              <>
                <TablePlayerAnthropometricData
                  data={this.state.anthropometricData.filter(
                    (o) => o.measureId === this.state.anthropometricMeasureId
                  )}
                  measures={this.state.anthropometricMeasures}
                  measureId={this.state.anthropometricMeasureId}
                  onMeasureChange={(key) =>
                    this.setState({
                      anthropometricMeasureId: key,
                    })
                  }
                  refresh={() =>
                    loadAnthropometricData(this.state.id).then(
                      (anthropometricData) =>
                        this.setState({ anthropometricData })
                    )
                  }
                  buttons={[
                    <GeneratedForm
                      component={(show, text) => (
                        <ButtonLight
                          onClick={show}
                          disabled={!navigator.onLine}
                        >
                          {text}
                        </ButtonLight>
                      )}
                      callback={(res) => {
                        loadAnthropometricDataCurrent(
                          this.props.match.params.id
                        ).then((anthropometricDataCurrent) =>
                          this.setState({ anthropometricDataCurrent })
                        );
                        loadAnthropometricData(this.props.match.params.id).then(
                          (anthropometricData) =>
                            this.setState({ anthropometricData })
                        );
                      }}
                      formName="createAnthropo"
                      method="POST"
                      formData={{
                        playerId: this.state.id,
                        playerIdname: this.props.player
                          ? this.props.player.displayName
                          : null,
                        eventId: null,
                      }}
                    />,
                  ]}
                />
              </>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}
        {this.state.managementTab === "PhysicalTests" ? (
          <Card
            title={t("Physical tests")}
            headerActions={
              <DropdownSelector
                fixedLabel={t("Physical tests")}
                className="btn btn-light-primary font-weight-bolder dropdown-toggle mr-0"
                value={t(this.state.physicalTestsView)}
                options={[
                  {
                    key: "Latest results",
                    label: t("Latest results"),
                  },
                  {
                    key: "All results",
                    label: t("All results"),
                  },
                ]}
                onChange={(key) => this.setState({ physicalTestsView: key })}
              />
            }
          >
            {this.state.physicalTestsView === "Latest results" ? (
              <>
                <TablePlayerPhysicalTestsDataCurrent
                  data={this.state.physicalTestsDataCurrent}
                  measures={this.state.physicalTestsMeasures}
                  refresh={() =>
                    loadPhysicalTestsData(this.state.id).then(
                      (physicalTestsData) =>
                        this.setState({ physicalTestsData })
                    )
                  }
                  buttons={[
                    <GeneratedForm
                      component={(show, text) => (
                        <ButtonLight
                          onClick={show}
                          disabled={!navigator.onLine}
                        >
                          {text}
                        </ButtonLight>
                      )}
                      callback={(res) => {
                        loadPhysicalTestsDataCurrent(
                          this.props.match.params.id
                        ).then((physicalTestsDataCurrent) =>
                          this.setState({ physicalTestsDataCurrent })
                        );
                        loadPhysicalTestsData(this.props.match.params.id).then(
                          (physicalTestsData) =>
                            this.setState({ physicalTestsData })
                        );
                      }}
                      formName="createPhysique"
                      method="POST"
                      formData={{
                        playerId: this.state.id,
                        playerIdname: this.props.player
                          ? this.props.player.displayName
                          : null,
                        eventId: null,
                      }}
                    />,
                  ]}
                />
              </>
            ) : (
              ""
            )}
            {this.state.physicalTestsView === "All results" ? (
              <>
                <TablePlayerPhysicalTestsData
                  data={this.state.physicalTestsData.filter(
                    (o) => o.measureId === this.state.physicalTestsMeasureId
                  )}
                  measures={this.state.physicalTestsMeasures}
                  measureId={this.state.physicalTestsMeasureId}
                  onMeasureChange={(key) =>
                    this.setState({
                      physicalTestsMeasureId: key,
                    })
                  }
                  refresh={() =>
                    loadPhysicalTestsData(this.state.id).then(
                      (physicalTestsData) =>
                        this.setState({ physicalTestsData })
                    )
                  }
                  buttons={[
                    <GeneratedForm
                      component={(show, text) => (
                        <ButtonLight
                          onClick={show}
                          disabled={!navigator.onLine}
                        >
                          {text}
                        </ButtonLight>
                      )}
                      callback={(res) => {
                        loadPhysicalTestsDataCurrent(
                          this.props.match.params.id
                        ).then((physicalTestsDataCurrent) =>
                          this.setState({ physicalTestsDataCurrent })
                        );
                        loadPhysicalTestsData(this.props.match.params.id).then(
                          (physicalTestsData) =>
                            this.setState({ physicalTestsData })
                        );
                      }}
                      formName="createPhysique"
                      method="POST"
                      formData={{
                        playerId: this.state.id,
                        playerIdname: this.props.player
                          ? this.props.player.displayName
                          : null,
                        eventId: null,
                      }}
                    />,
                  ]}
                />
              </>
            ) : (
              ""
            )}
          </Card>
        ) : (
          ""
        )}
        {this.state.managementTab === "Bilan" ? (
          <TablePeriodicReport
            players={this.state.playerPR}
            refresh={() => {
              loadPeriodicReportsByPlayer(
                this.state.id,
                this.state.player
              ).then((playerPR) => {
                this.setState({ playerPR });
              });
            }}
            buttons={[
              <GeneratedForm
                component={(show, text) => (
                  <ButtonLight onClick={show} disabled={!navigator.onLine}>
                    <i className="flaticon2-plus"></i> {text}
                  </ButtonLight>
                )}
                callback={() => {
                  loadPeriodicReportsByPlayer(
                    this.state.id,
                    this.state.player
                  ).then((playerPR) => {
                    this.setState({ playerPR });
                  });
                }}
                formName="createPeriodicReport"
                method="POST"
                formData={{
                  playerId: this.state.id,
                }}
              />,
            ]}
          />
        ) : (
          ""
        )}
      </>
    );
  }
}

export default withTranslation()(withRouter(PlayerManagementTab));
