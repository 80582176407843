import React, { /*lazy,*/ Suspense } from 'react';
import { Switch, Route, BrowserRouter } from "react-router-dom";
import * as Core from "./page/core/index";
import DemoComponents from "./page/demo/DemoComponents";
import Layout from "./components/layout/Layout";
import routes  from "./utils/logicRoutes";

class Router extends React.Component {
    render() {
        return (
            <BrowserRouter>
                <Suspense fallback={<Layout component={Core.Loading} path="/" />}>
                    <Switch>
                        {routes.map((r, index) => {
                            return <Layout key={index} path={r.path} component={r.component} exact={r.exact} />
                        })}

                        {/* Page demo */}
                        {process.env.NODE_ENV === "development" || process.env.NODE_ENV === "staging" ? <Layout path="/demo" component={DemoComponents} /> : ""}
                        <Route>
                            <Core.NotFound />
                        </Route>
                    </Switch>
                </Suspense>
            </BrowserRouter>
        )
    }
}

export default Router;
