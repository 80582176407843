import React from "react"
import { withRouter } from "react-router"
import { withTranslation } from "react-i18next"
import Page from "../../components/page/Page"
import Card from "../../components/card/Card"
import {
  loadEventsByPlayer,
  getEventsByPlayer,
  getCycle,
  loadCycle,
  getCyclesByPlayer,
  loadCyclesByPlayer,
  loadEventsDayTimeByTypeIdAndPlayerId,
  getEventsDayTimeByTypeIdAndPlayerId,
  getEventsDayTimeByPlayerId,
  loadEventsDayTimeByPlayerId
} from "../../utils/indexedDB/events/handleEvents"
import Calendar from "../../components/calendar/Calendar"
import moment from "moment"
import ChartCycleByPlayerDailySequencesByTrainingThemes from "../../charts/ChartCycleByPlayerDailySequencesByTrainingThemes"
import ChartCycleByPlayerSessionTimesByTypes from "../../charts/ChartCycleByPlayerSessionTimesByTypes"
import ChartCycleByPlayerDailySequencesByPhysicalThemes from "../../charts/ChartCycleByPlayerDailySequencesByPhysicalThemes"
import ChartCyclesByPlayerDailySequencesByCategories from "../../charts/ChartCycleByPlayerDailySequencesByCategories"
import ChartCycleByPlayerMatchs from "../../charts/ChartCycleByPlayerMatchs"
import {
  getMatchesByDates,
  loadMatchesByDates,
} from "../../utils/indexedDB/Tennis-Competition/handleCompetitionTennis"
import { getPlayer, loadPlayer } from "../../utils/indexedDB/users/handleUser"
import DisplayData3cols from "../../components/displayData/DisplayData3cols"
import SubTab from "../../components/tab/SubTab"
import ListTennisMatches from "../../lists/ListTennisMatches"
import GeneratedForm from "../../utils/forms/generateForm"
import Button from "../../components/buttons/Button"
import ButtonLight from "../../components/buttons/ButtonLight"
import ChartRpeByPlayer from "../../charts/ChartRpeByPlayer"
import Tooltip from "../../components/typography/Tooltip/Tooltip"


class CycleByPlayer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      id: this.props.match.params.id,
      cycle: null,
      cycles: [],
      currentIndex: -1,
      events: null,
      trainingView: "global",
      physicalView: "global",
      matchView: "global",
      rpeByEvent: [],
      totalSRPE: 0
    }
  }

  componentDidMount() {
    var id = this.props.match.params.id
    let end = null
    let start = null
    getCycle(id).then((cycle) => {
      if (cycle) {
        end = moment(cycle.endDate).add(1, 'day').format();
        start = moment(cycle.startDate).add(-1, 'day').format();
        this.setState({ cycle, start, end })
        getPlayer(cycle.playerId).then((player) => this.setState({ player }))
        getEventsDayTimeByPlayerId(cycle.playerId, start, end).then((summary) => {
          if (summary)
            this.setState({ summary })
        })
        getEventsDayTimeByTypeIdAndPlayerId("Physical", cycle.playerId, start, end).then((physical) => {
          if (physical)
            this.setState({ physical })
        })
        getEventsDayTimeByTypeIdAndPlayerId("PhysicalDetails", cycle.playerId, start, end).then((physicalDetails) => {
          if (physicalDetails)
            this.setState({ physicalDetails })
        })
        getEventsDayTimeByTypeIdAndPlayerId("Training", cycle.playerId, start, end).then((training) => {
          if (training)
            this.setState({ training })
        })
        getEventsDayTimeByTypeIdAndPlayerId("TrainingDetails", cycle.playerId, start, end).then((trainingDetails) => {
          if (trainingDetails)
            this.setState({ trainingDetails })
        })
        getEventsDayTimeByTypeIdAndPlayerId("Mental", cycle.playerId, start, end).then((mental) => {
          if (mental)
            this.setState({ mental })
        })
        getEventsDayTimeByTypeIdAndPlayerId("RPE", cycle.playerId, start, end).then((rpe) => {
          if (rpe)
            rpe.forEach((r) => {
              r.results.forEach((data) => {
                if (data.typeId === "Entrainement") {
                  data.kind = "Tennis"
                } else if (data.typeId === "Musculation" || data.typeId === "Prevention" || data.typeId === "Energetique") {
                  data.kind = "Physique"
                } else if (data.typeId === "Match tennis") {
                  data.kind = "Match"
                }
                else data.kind = data.typeId
              })
            })
          let categories = []
          let totalSRPE = 0
          rpe.forEach((day) => {
            day.results.forEach((data) => {
              totalSRPE += data.srpe
              if (data.kind && (categories.length === 0 || !categories.find((o) => o === data.kind))) {
                categories.push(data.kind)
              }
            })
          })
          this.setState({ rpe, sRPECategories: categories, totalSRPE })
        })
        getEventsByPlayer(cycle.playerId, cycle.startDate, end).then(
          (events) => {
            if (events) {
              this.setState({ events })
            }
          }
        )

        // importer tableau :  getCyclesByPlayer();
        // trouver l'index du cycle
        // index + 1 .id ou link (pour le prochain) ou -1 pour le precedent
        getCyclesByPlayer(
          cycle.playerId,
          moment().add(-4, "y").startOf("day").toDate().toISOString(),
          moment().add(1, "y").startOf("day").toDate().toISOString()
        ).then((cycles) => {
          cycles = cycles.sort((a, b) =>
            moment(a.startDate) === moment(b.startDate)
              ? 0
              : moment(a.startDate) > moment(b.startDate)
                ? 1
                : -1
          )
          // find index
          let currentIndex = -1
          for (var i = 0; i < cycles.length; i++)
            if (this.state.id === cycles[i].id) {
              currentIndex = i
              break
            }
          //  index precedent => i - 1
          //  index suivant => i + 1

          this.setState({ cycles, currentIndex })
        })

        getMatchesByDates(
          cycle.playerId,
          cycle.startDate,
          end
        ).then((matches) => this.setState({ matches }))
      }
    })
    if (navigator.onLine) {
      loadCycle(id).then((cycle) => {
        if (cycle && cycle.playerId) {
          end = moment(cycle.endDate).add(1, 'day').format();
          this.setState({ cycle, end })
          loadCyclesByPlayer(
            cycle.playerId,
            moment().add(-4, "y").startOf("day").toDate().toISOString(),
            moment().add(1, "y").startOf("day").toDate().toISOString()
          ).then((cycles) => {
            cycles = cycles.sort((a, b) =>
              moment(a.startDate) === moment(b.startDate)
                ? 0
                : moment(a.startDate) > moment(b.startDate)
                  ? 1
                  : -1
            )
            let currentIndex = -1
            for (var i = 0; i < cycles.length; i++)
              if (this.state.id === cycles[i].id) {
                currentIndex = i
                break
              }
            this.setState({ cycles, currentIndex })
          })
          loadPlayer(cycle.playerId).then((player) => this.setState({ player }))
          loadEventsDayTimeByPlayerId(cycle.playerId, cycle.startDate, cycle.endDate).then((summary) => {
            this.setState({ summary })
          })
          loadEventsDayTimeByTypeIdAndPlayerId("Physical", cycle.playerId, cycle.startDate, cycle.endDate).then((physical) => {
            this.setState({ physical })
          })
          loadEventsDayTimeByTypeIdAndPlayerId("PhysicalDetails", cycle.playerId, cycle.startDate, cycle.endDate).then((physicalDetails) => {
            this.setState({ physicalDetails })
          })
          loadEventsDayTimeByTypeIdAndPlayerId("Training", cycle.playerId, cycle.startDate, cycle.endDate).then((training) => {
            this.setState({ training })
          })
          loadEventsDayTimeByTypeIdAndPlayerId("TrainingDetails", cycle.playerId, cycle.startDate, cycle.endDate).then((trainingDetails) => {
            this.setState({ trainingDetails })
          })
          loadEventsDayTimeByTypeIdAndPlayerId("Mental", cycle.playerId, cycle.startDate, cycle.endDate).then((mental) => {
            this.setState({ mental })
          })
          loadEventsDayTimeByTypeIdAndPlayerId("RPE", cycle.playerId, cycle.startDate, cycle.endDate).then((rpe) => {
            rpe.forEach((r) => {
              r.results.forEach((data) => {
                if (data.typeId === "Entrainement") {
                  data.kind = "Tennis"
                } else if (data.typeId === "Musculation" || data.typeId === "Prevention" || data.typeId === "Energetique") {
                  data.kind = "Physique"
                } else if (data.typeId === "Match tennis") {
                  data.kind = "Match"
                }
                else data.kind = data.typeId
              })
            })
            let categories = []
            let totalSRPE = 0
            rpe.forEach((day) => {
              day.results.forEach((data) => {
                totalSRPE += data.srpe
                if (data.kind && (categories.length === 0 || !categories.find((o) => o === data.kind))) {
                  categories.push(data.kind)
                }
              })
            })
            this.setState({ rpe, sRPECategories: categories, totalSRPE })
          })

          loadEventsByPlayer(cycle.playerId, cycle.startDate, end).then(
            (events) => {
              this.setState({ events })
              if (events) {
                events.forEach((event) => {
                  if (event.typeId === "Entrainement") {
                    event.typeId = "Tennis"
                  } else if (
                    event.typeId === "Musculation" ||
                    event.typeId === "Prevention" ||
                    event.typeId === "Energetique"
                  ) {
                    event.typeId = "Physique"
                  } else if (event.typeId === "Match tennis") {
                    event.typeId = "Match"
                  }
                })
              }
            })
          loadMatchesByDates(cycle.playerId, cycle.startDate, end).then(
            (matches) =>
              this.setState({ matches })
          )
        }
      })
    }
  }

  render() {
    const { t } = this.props
    let { cycle, events, summary, training, trainingDetails, physical, physicalDetails, mental, matches, end, rpe } = this.state
    let eventTypes = ["Tennis", "Physique", "Récupération", "Match", "Mental", "Analyse vidéo"]
    var duration = 0
    var nbMatches = []
    var trainingCategories = []
    var trainingThemes = []
    var physicalCategories = []
    var physicalThemes = []
    var mentalCategories = []
    if (cycle) {
      duration = moment(end).diff(moment(cycle.startDate), "days")
    }
    if (events) {
      nbMatches = events.filter((o) => o.typeId === "Match")
      if (nbMatches.length > 0)
        matches = matches.map(tournament => {
          let t = tournament.matches.filter(m => {
            return nbMatches.some(n => n.id === m.id)
          })
          tournament.matches = t
          return tournament
        })
    }
    if (mental) {
      mental.forEach((day) => {
        day.results.forEach((sequence) => {
          if (
            sequence.typeId &&
            (mentalCategories.length === 0 ||
              !mentalCategories.find((o) => o === sequence.typeId))
          ) {
            mentalCategories.push(sequence.typeId)
          }
        })
      })
    }
    if (trainingDetails) {
      trainingDetails.forEach((day) => {
        day.results.forEach((sequence) => {
          if (
            sequence.theme &&
            (trainingThemes.length === 0 ||
              !trainingThemes.find((o) => o.id === sequence.theme.id))
          ) {
            trainingThemes.push(sequence.theme)
          }
        })
      })
    }
    if (training) {
      training.forEach((day) => {
        day.results.forEach((sequence) => {
          if (sequence.typeId === "PPI Aérobie" || sequence.typeId === "PPI Explosivité" || sequence.typeId === "PPI Vitesse / Coordination") {
            if ((trainingCategories.length === 0 ||
              !trainingCategories.find((o) => o === "PPI"))) {
              trainingCategories.push("PPI")
            }
          }
          else if (
            sequence.typeId &&
            (trainingCategories.length === 0 ||
              !trainingCategories.find((o) => o === sequence.typeId))
          ) {
            trainingCategories.push(sequence.typeId)
          }
        })
      })
    }
    if (physicalDetails && physical) {
      physicalDetails.forEach((day) => {
        day.results.forEach((sequence) => {
          if (
            sequence.theme &&
            (physicalThemes.length === 0 ||
              !physicalThemes.find((o) => o.id === sequence.theme.id))
          ) {
            physicalThemes.push(sequence.theme)
          }
        })
      })
      physical.forEach((day) => {
        day.results.forEach((sequence) => {
          if (
            sequence.typeId &&
            (physicalCategories.length === 0 ||
              !physicalCategories.find((o) => o === sequence.typeId))
          ) {
            physicalCategories.push(sequence.typeId)
          }
        })
      })
    }
    var trainingSubTabs = []
    trainingSubTabs.push({ key: "global", label: t("Global") })
    trainingCategories.map((category) =>
      trainingSubTabs.push({ key: category, label: category })
    )
    var physicalSubTabs = []
    physicalSubTabs.push({ key: "global", label: t("Global") })
    physicalSubTabs.push({ key: "detail", label: t("Detail") })
    var matchSubTabs = []
    matchSubTabs.push({ key: "global", label: t("Global") })
    matchSubTabs.push({ key: "detail", label: t("Detail") })

    return this.state.cycle && events ? (
      <Page
        actions={{
          other: [
            <Tooltip tooltipContent={t("Last cycle")}>
              <ButtonLight
                className="inline-flex items-center px-4 py-2 mr-4 rounded-md text-md font-medium border text-indigo-700 border-gray-300 hover:bg-indigo-700 hover:text-white hover:border-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={() =>
                  this.props.history.push(
                    this.state.cycles[this.state.currentIndex - 1].id
                  )
                }
                disabled={!this.state.cycles || !this.state.currentIndex || this.state.currentIndex < 1}
              >
                <svg className="w-5 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" aria-labelledby="title" aria-describedby="desc" role="img" xlink="http://www.w3.org/1999/xlink">
                  <path data-name="layer1"
                    fill="currentColor" d="M24 32.001L38 46l4-4.485-9.515-9.514L42 22.487 38 18 24 32.001z"></path>
                </svg>
              </ButtonLight></Tooltip>,
            <Tooltip tooltipContent={t("Next cycle")}>
              <ButtonLight
                className="inline-flex items-center px-4 py-2 mr-48 rounded-md text-md font-medium border text-indigo-700 border-gray-300 hover:bg-indigo-700 hover:text-white hover:border-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={() => {
                  this.props.history.push(
                    this.state.cycles[this.state.currentIndex + 1].id
                  )
                }}
                disabled={
                  !this.state.cycles || !this.state.currentIndex || this.state.currentIndex < 0 ||
                  this.state.currentIndex + 1 >= this.state.cycles.length
                }
              >
                <svg className="w-5 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" aria-labelledby="title" aria-describedby="desc" role="img" xlink="http://www.w3.org/1999/xlink">
                  <path data-name="layer1"
                    fill="currentColor" d="M40 31.998L26 18l-4 4.486 9.515 9.512L22 41.513 26 46l14-14.002z"></path>
                </svg>
              </ButtonLight></Tooltip>,
            <GeneratedForm
              key="updateTennisCycle"
              component={(show, text) => (
                <ButtonLight onClick={show} disabled={!navigator.onLine}>
                  {text}
                </ButtonLight>
              )}
              callback={() => this.forceUpdate()}
              formName="updateTennisCycle"
              formData={this.state.cycle}
            />,
            <GeneratedForm
              key="deleteTennisCycle"
              component={(show, text) => (
                <Button onClick={show} disabled={!navigator.onLine}>
                  {text}
                </Button>
              )}
              callback={() =>
                this.props.history.goBack()
              }
              formName="deleteTennisCycle"
              formData={this.state.cycle}
            />,
          ],
        }}
        pageTitle={this.state.cycle.name}
        backPage="/training/cyclesByPlayer"
        tab={[
          {
            title: t("Summary"),
            visible: true,
            data: this.state.cycle,
            component: (data) => {
              return data && summary ? (
                <div>
                  <div className="flex">
                    <div className="w-full px-10 pt-6 py-2">
                      <Card>
                        <DisplayData3cols
                          data={[
                            { key: t("Title"), value: data.name },
                            { key: t("Description"), value: data.description },
                            {
                              key: t("Category"),
                              value: data.category ? data.category.name : "-",
                            },

                            {
                              key: t("Start date"),
                              value: moment(data.startDate).calendar(),
                            },
                            {
                              key: t("End date"),
                              value: moment(data.endDate).calendar(),
                            },
                            {
                              key: t("Duration"),
                              value: duration + " " + t("days"),
                            },
                            {
                              key: t("Sporting targets"),
                              value: data.sportingTargets,
                            },
                            {
                              key: t("Physical targets"),
                              value: data.physicalTargets,
                            },
                            {
                              key: t("Mental targets"),
                              value: data.mentalTargets,
                            },
                          ]}
                        />
                      </Card>
                    </div>
                  </div>

                  <div className="mx-6 px-6 pt-6">
                    {this.state.cycle !== null &&
                      summary !== null ? (
                      <ChartCycleByPlayerSessionTimesByTypes
                        data={summary}
                        types={eventTypes}
                        startDate={this.state.cycle.startDate}
                        endDate={this.state.cycle.endDate}
                        height={600}
                        events={events}
                        totalSRPE={this.state.totalSRPE}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ) : null
            },
          },
          {
            title: t("Planning"),
            visible: true,
            data: null,
            component: (data) => {
              return this.state.cycle ? (
                <Calendar
                  availableViews="week"
                  defaultView="timeGridWeek"
                  defaultDate={this.state.cycle.startDate}
                  filters
                  choosePlayer={false}
                  defaultFiltersOpened={false}
                  playersId={[this.state.cycle.playerId]}
                  formData={{
                    attendees: [this.state.cycle.playerId],
                    playerId: this.state.cycle.playerId,
                    groupId: this.state.player
                      ? this.state.player.group1Id
                      : null,
                  }}
                  callbackFormData={() => { }}
                />
              ) : (
                ""
              )
            },
          },
          {
            title: t("Tennis training"),
            visible: true,
            data: training,
            component: (data) => {
              return data && trainingDetails ? (
                <>
                  <SubTab key="tennistraining"
                    tab={trainingSubTabs}
                    onChange={(key) => this.setState({ trainingView: key })}
                    selectedTab={this.state.trainingView}
                  />
                  {this.state.trainingView === "global" ? (
                    <div className="row">
                      <div className="col-md-12">
                        {this.state.cycle != null &&
                          data != null &&
                          trainingCategories ? (
                          <Card>
                            <ChartCyclesByPlayerDailySequencesByCategories
                              sequences={data}
                              categories={trainingCategories}
                              startDate={this.state.cycle.startDate}
                              endDate={this.state.cycle.endDate}
                            />
                          </Card>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  ) : this.state.cycle != null &&
                    trainingDetails != null &&
                    trainingCategories != null &&
                    trainingThemes != null ? (
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <Card>
                          <ChartCycleByPlayerDailySequencesByTrainingThemes
                            sequences={trainingDetails}
                            category={trainingCategories.find(
                              (o) => o === this.state.trainingView
                            )}
                            themes={trainingThemes.filter(
                              (o) =>
                                o.trainingCategory.name === this.state.trainingView ||
                                (this.state.trainingView === "PPI" && (
                                  o.trainingCategoryId === "TennisPPIVitesseCoordination" ||
                                  o.trainingCategoryId === "TennisPPIExplosivite" ||
                                  o.trainingCategoryId === "TennisPPIAerobie"
                                ))
                            )}
                            startDate={this.state.cycle.startDate}
                            endDate={this.state.cycle.endDate}
                          />
                        </Card>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )
            },
          },
          {
            title: t("Physical training"),
            visible: true,
            data: physical,
            component: (data) => {
              return data && physicalDetails ? (
                <>
                  <SubTab
                    key="physicaltraining"
                    tab={physicalSubTabs}
                    onChange={(key) => this.setState({ physicalView: key })}
                    selectedTab={this.state.physicalView}
                  />
                  {this.state.physicalView === "global" ? (
                    <div className="row">
                      <div className="col-md-12">
                        {this.state.cycle != null &&
                          data != null &&
                          trainingCategories ? (
                          <Card>
                            <ChartCyclesByPlayerDailySequencesByCategories
                              sequences={data}
                              categories={physicalCategories}
                              startDate={this.state.cycle.startDate}
                              endDate={this.state.cycle.endDate}
                            />
                          </Card>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  ) :
                    this.state.cycle != null &&
                    physicalDetails != null &&
                      physicalCategories != null &&
                      physicalThemes != null
                      ? <div className="row mt-3">
                        <div className="col-md-12">
                          <Card>
                            <ChartCycleByPlayerDailySequencesByPhysicalThemes
                              sequences={physicalDetails}
                              themes={physicalThemes}
                              startDate={this.state.cycle.startDate}
                              endDate={this.state.cycle.endDate}
                            />
                          </Card>
                        </div>
                      </div>
                      : ""}
                </>
              ) : (
                ""
              )
            },
          },
          {
            title: t("Mental training"),
            visible: true,
            data: mental,
            component: (data) => {
              return data ? (
                <>
                  <div className="row">
                    <div className="col-md-12">
                      {this.state.cycle != null &&
                        data != null &&
                        mentalCategories ? (
                        <Card>
                          <ChartCyclesByPlayerDailySequencesByCategories
                            sequences={data}
                            categories={mentalCategories}
                            startDate={this.state.cycle.startDate}
                            endDate={this.state.cycle.endDate}
                          />
                        </Card>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </>
              ) : (
                ""
              )
            },
          },
          {
            title: t("Matches"),
            visible: true,
            data: matches,
            component: (data) => {
              return data && this.state.player ? (
                <>
                  {nbMatches.length > 0 ?
                    <>
                      <SubTab
                        key="matches"
                        tab={matchSubTabs}
                        onChange={(key) => this.setState({ matchView: key })}
                        selectedTab={this.state.matchView}
                      />
                      {this.state.matchView === "global" ? (
                        <ChartCycleByPlayerMatchs
                          matches={nbMatches}
                          categories={data}
                          startDate={this.state.cycle.startDate}
                          endDate={this.state.cycle.endDate}
                        />
                      ) : (
                        <ListTennisMatches
                          tournaments={data}
                          player={this.state.player}
                        />
                      )
                      }
                    </>
                    :
                    <ChartCycleByPlayerMatchs
                      matches={nbMatches}
                      categories={data}
                      startDate={this.state.cycle.startDate}
                      endDate={this.state.cycle.endDate}
                    />
                  }
                </>
              ) : (
                ""
              )
            },
          },
          {
            title: t("RPE"),
            visible: true,
            data: rpe ? rpe : null,
            component: (data) => {
              return data ? (
                <>
                  <div className="row">
                    <div className="col-md-12">
                      {this.state.cycle != null &&
                        data != null && this.state.sRPECategories ? (
                        <Card>
                          <ChartRpeByPlayer
                            startDate={this.state.cycle.startDate}
                            endDate={this.state.cycle.endDate}
                            rpeByEvent={data}
                            categories={this.state.sRPECategories}
                          />
                        </Card>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </>
              ) : (
                ""
              )
            },
          },
        ]}
      />
    ) : null
  }
}

export default withTranslation()(withRouter(CycleByPlayer))
