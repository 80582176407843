import * as Home from "../page/home/index";
import * as Group from "../page/group/index";
import * as Training from "../page/training/index";
import * as CompetitionTennis from "../page/competitionTennis/index";
import * as CompetitionRugby from "../page/competitionRugby/index";
import * as CompetitionSoccer from "../page/competitionSoccer/index";
import PageCompetitionSoccer from "../page/competitionSoccer/CompetitionSoccerPage";
import * as Administration from "../page/administration/index";
import Calendar from "../page/Calendar";
import Contact from "../page/Group";
import Management from "../page/Management";
import * as ManagementDetails from "../page/management/index";
import PageCompetitionTennis from "../page/Competition";
import Account from "../page/Account";
import AdministrationLibrary from "../page/AdministrationLibrary";
import TrainingMain from "../page/Training";
import CompetitionRugbyPage from "../page/competitionRugby/CompetitionRugbyPage";

/** for not Core things :
 * const ComponentName = withRouter(
    lazy(() => import('url')),
);
 */

const routes = [
  // CORE
  {
    path: "/calendar",
    module: "calendar",
    feature: "Calendar",
    component: Calendar,
    exact: true,
  },
  {
    path: "/training/cycleByPlayer/:id",
    module: "calendar",
    feature: "Cycle by player",
    component: Training.CycleByPlayer,
    exact: false,
  },
  {
    path: "/training/weekByPlayer/:id",
    module: "calendar",
    feature: "Week by player",
    component: Training.WeekByPlayer,
    exact: false,
  },
  {
    path: "/training/tennisSession/:eventId/:playerId",
    module: "calendar",
    feature: "Tennis session",
    component: Training.TennisSession,
    exact: false,
  },
  {
    path: "/training/physicalSession/:eventId/:playerId",
    module: "calendar",
    feature: "Physical session",
    component: Training.PhysicalSession,
    exact: false,
  },
  {
    path: "/training/mentalSession/:eventId/:playerId",
    module: "calendar",
    feature: "Mental session",
    component: Training.MentalSession,
    exact: false,
  },
  {
    path: "/group",
    module: "group",
    feature: "Group",
    component: Contact,
    exact: true,
  },
  {
    path: "/management",
    module: "management",
    feature: "Management",
    component: Management,
    exact: true,
  },
  {
    path: "/competitionTennis",
    module: "competitionTennis",
    feature: "Competition Tennis",
    component: PageCompetitionTennis,
    exact: true,
  },
  {
    path: "/competitionRugby",
    module: "competitionRugby",
    feature: "Competition Rugby",
    component: CompetitionRugbyPage,
    exact: true,
  },
  {
    path: "/competitionSoccer",
    module: "competitionSoccer",
    feature: "Competition Soccer",
    component: PageCompetitionSoccer,
    exact: true,
  },
  {
    path: "/administrationLibrary",
    module: "administrationLibrary",
    feature: "Administration",
    component: AdministrationLibrary,
    exact: true,
  },
  {
    path: "/me",
    module: "me",
    component: Account,
    exact: true,
  },
  // HOME
  {
    path: "/",
    module: "home",
    feature: "Dashboard",
    component: Home.Dashboard,
    exact: true,
  },
  {
    path: "/announces",
    module: "home",
    feature: "Announces",
    component: Home.Announces,
    exact: false,
  },
  {
    path: "/myAccount/:id",
    module: "home",
    feature: "My account",
    component: Home.Account,
    exact: false,
  },
  {
    path: "/videos/:id",
    module: "home",
    feature: "Video collection",
    component: Home.Videos,
    exact: false,
  },
  {
    path: "/photos/:id",
    module: "home",
    feature: "Photo gallery",
    component: Home.Photos,
    exact: false,
  },

  // GROUP
  {
    path: "/group/player/:id",
    module: "group",
    feature: "Player",
    component: Group.Player,
    exact: false,
  },
  {
    path: "/group/staff/:id",
    module: "group",
    feature: "Staff",
    component: Group.Staff,
    exact: false,
  },
  // TRAINING
  {
    path: "/training",
    module: "training",
    feature: "Training",
    // feature: "cyclesByPlayer",
    component: TrainingMain,
    exact: true,
  },
  {
    path: "/training/drill/:id/:type",
    module: "training",
    feature: "Drill",
    component: Training.Drill,
    exact: false,
  },
  {
    path: "/training/sessionTemplate/:id/:type",
    module: "training",
    feature: "SessionTemplate",
    component: Training.SessionTemplate,
    exact: false,
  },
  // COMPETITION
  // COMPETITION TENNIS
  {
    path: "/competitionTennis/match/:id",
    module: "competitionTennis",
    feature: "Match",
    component: CompetitionTennis.Match,
    exact: false,
  },
  {
    path: "/competitionTennis/nationaldetails/:id/:month/:year",
    module: "competitionTennis",
    feature: "National tournament",
    component: CompetitionTennis.NationalDetails,
    exact: false,
  },
  {
    path: "/competitionTennis/internationaldetails/:id/:month/:year",
    module: "competitionTennis",
    feature: "International tournament",
    component: CompetitionTennis.InternationalDetail,
    exact: false,
  },
  {
    path: "/competitionTennis/rankingATP",
    module: "competitionTennis",
    feature: "Ranking ATP",
    component: CompetitionTennis.RankingATP,
    exact: false,
  },
  {
    path: "/competitionTennis/rankingATPByPlayer/:id",
    module: "competitionTennis",
    feature: "Ranking ATP By Player",
    component: CompetitionTennis.RankingATPByPlayer,
    exact: false,
  },
  {
    path: "/competitionTennis/rankingATPDbl",
    module: "competitionTennis",
    feature: "Ranking ATP Double",
    component: CompetitionTennis.RankingATPDbl,
    exact: false,
  },
  {
    path: "/competitionTennis/rankingATPDblByPlayer/:id",
    module: "competitionTennis",
    feature: "Ranking ATP Double By Player",
    component: CompetitionTennis.RankingATPDblByPlayer,
    exact: false,
  },
  {
    path: "/competitionTennis/rankingWTA",
    module: "competitionTennis",
    feature: "Ranking WTA",
    component: CompetitionTennis.RankingWTA,
    exact: false,
  },
  {
    path: "/competitionTennis/rankingWTAByPlayer/:id",
    module: "competitionTennis",
    feature: "Ranking WTA By Player",
    component: CompetitionTennis.RankingWTAByPlayer,
    exact: false,
  },
  {
    path: "/competitionTennis/rankingWTADbl",
    module: "competitionTennis",
    feature: "Ranking WTA Double",
    component: CompetitionTennis.RankingWTADbl,
    exact: false,
  },
  {
    path: "/competitionTennis/rankingWTADblByPlayer/:id",
    module: "competitionTennis",
    feature: "Ranking WTA Double By Player",
    component: CompetitionTennis.RankingWTADblByPlayer,
    exact: false,
  },
  {
    path: "/competitionTennis/rankingItfF",
    module: "competitionTennis",
    feature: "Ranking ITF F",
    component: CompetitionTennis.RankingItfF,
    exact: false,
  },
  {
    path: "/competitionTennis/rankingITFFByPlayer/:id",
    module: "competitionTennis",
    feature: "Ranking ITF F By Player",
    component: CompetitionTennis.RankingITFFByPlayer,
    exact: false,
  },
  {
    path: "/competitionTennis/rankingItfH",
    module: "competitionTennis",
    feature: "Ranking ITF H",
    component: CompetitionTennis.RankingItfH,
    exact: false,
  },
  {
    path: "/competitionTennis/rankingITFHByPlayer/:id",
    module: "competitionTennis",
    feature: "Ranking ITF H By Player",
    component: CompetitionTennis.RankingITFHByPlayer,
    exact: false,
  },
  {
    path: "/competitionTennis/rankingItfJF",
    module: "competitionTennis",
    feature: "Ranking ITF Junior F",
    component: CompetitionTennis.RankingItfJF,
    exact: false,
  },
  {
    path: "/competitionTennis/rankingITFJFByPlayer/:id",
    module: "competitionTennis",
    feature: "Ranking ITF Junior F By Player",
    component: CompetitionTennis.RankingITFJFByPlayer,
    exact: false,
  },
  {
    path: "/competitionTennis/rankingItfJH",
    module: "competitionTennis",
    feature: "Ranking ITF Junior H",
    component: CompetitionTennis.RankingItfJH,
    exact: false,
  },
  {
    path: "/competitionTennis/rankingITFJHByPlayer/:id",
    module: "competitionTennis",
    feature: "Ranking ITF Junior H By Player",
    component: CompetitionTennis.RankingITFJHByPlayer,
    exact: false,
  },
  {
    path: "/competitionTennis/rankingTennisEuropeU14F",
    module: "competitionTennis",
    feature: "Ranking Tennis Europe U14 F",
    component: CompetitionTennis.RankingTennisEuropeU14F,
    exact: false,
  },
  {
    path: "/competitionTennis/rankingTennisEuropeU14FByPlayer/:id",
    module: "competitionTennis",
    feature: "Ranking Tennis Europe U14 F By Player",
    component: CompetitionTennis.RankingTennisEuropeU14FByPlayer,
    exact: false,
  },
  {
    path: "/competitionTennis/rankingTennisEuropeU14H",
    module: "competitionTennis",
    feature: "Ranking Tennis Europe U14 H",
    component: CompetitionTennis.RankingTennisEuropeU14H,
    exact: false,
  },
  {
    path: "/competitionTennis/rankingTennisEuropeU14HByPlayer/:id",
    module: "competitionTennis",
    feature: "Ranking Tennis Europe U14 H By Player",
    component: CompetitionTennis.RankingTennisEuropeU14HByPlayer,
    exact: false,
  },
  {
    path: "/competitionTennis/nationalRankingF",
    module: "competitionTennis",
    feature: "Ranking National F",
    component: CompetitionTennis.RankingNationalF,
    exact: false,
  },
  {
    path: "/competitionTennis/nationalRankingH",
    module: "competitionTennis",
    component: CompetitionTennis.RankingNationalH,
    exact: false,
  },
  // COMPETITION RUGBY
  {
    path: "/competitionRugby/matchInfos/:id",
    module: "competitionRugby",
    component: CompetitionRugby.MatchInfos,
    exact: false,
  },
  {
    path: "/competitionRugby/playerInfoByMatch/:matchId/:playerId",
    module: "competitionRugby",
    component: CompetitionRugby.PlayerInfoByMatch,
    exact: false,
  },
  // COMPETITION SOCCER
  {
    path: "/competitionSoccer/matchInfos/:id",
    module: "competitionSoccer",
    component: CompetitionSoccer.MatchInfos,
    exact: false,
  },
  {
    path: "/competitionSoccer/playerInfoByMatch/:matchId/:playerId",
    module: "competitionSoccer",
    component: CompetitionSoccer.PlayerInfoByMatch,
    exact: false,
  },
  // STEWARDSHIP

  // ADMINISTRATION
  {
    path: "/administration/school/:id",
    module: "administration",
    feature: "School",
    component: Administration.School,
    exact: false,
  },

  {
    path: "/administration/curriculum/:id",
    module: "administration",
    feature: "Curriculum",
    component: Administration.Curriculum,
    exact: false,
  },
  {
    path: "/administration/scholarshipByPlayer/:id",
    module: "administration",
    feature: "Scholarship by Player",
    component: Administration.ScholarshipByPlayer,
    exact: false,
  },
  //MANAGEMENT
  {
    path: "/management/periodicReportDetails/:id",
    module: "Periodic report",
    component: ManagementDetails.periodicReport,
    exact: false,
  },
];

export default routes;
