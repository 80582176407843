import React from "react";
import ActionButton from "../components/buttons/ActionButton";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { SelectMultiple } from "../components/forms";
import TableSimple from "../components/table/TableSimple";
import moment from "moment";
import SearchInput from "../components/forms/input/SearchInput";
import FiltersSearch from "../components/filters/FiltersSearch";
import FiltersDropDowns from "../components/filters/FiltersDropDowns";
import FilterColumns from "../components/filters/FilterColumns";
import ExportToCSV from "../components/export/ExportToCSV";
import generateColor from "../utils/generateColor";
import FilterSeparator from "../components/filters/FilterSeparator";
import FiltersTitle from "../components/filters/FiltersTitle";
import FiltersBar from "../components/filters/FiltersBar";


class TablePlayerGalleries extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actions: [],
      categories: [],
      selectedCategories: [],
      columns: [],
      searchText: "",
    };
  }

  componentDidMount() {
    this.prepareData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.prepareData();
    }
  }

  prepareData = () => {
    var galleries = this.props.galleries;
    var categories = [];
    var selectedCategories = [];
    if (galleries) {
      galleries = galleries.map((gallery) => {
        if (
          categories &&
          !categories.find((o) => o.key === gallery.category.id)
        ) {
          categories.push({
            key: gallery.category.id,
            label: gallery.category.name,
          });
          selectedCategories.push(gallery.category.id);
        }
        return gallery;
      });
    }

    const columns = [
      {
        key: "name",
        visible: true,
        label: this.props.t("Galeries"),
        id: "name",
        width: 350,
        format: (row) => (
          <>
          <div className="flex justify-between">
          <Link
              className="text-indigo-700 font-semibold mb-0 text-xs 2xl:text-sm"
              to={"/photos/" + row.id}
            >
              {row.name}
            </Link>
            <ActionButton
              key={row.id}
              updateForm="updateGallery"
              deleteForm="deleteGallery"
              formData={row}
              refreshData={() => this.props.updateGalleries(this.props.pId)}
            />
          </div>
            
          </>
        ),
      },
      {
        key: "description",
        visible: true,
        label: this.props.t("Description"),
        id: "description",
        width: 280,
        format: (row) => (
          <span className="font-weight-bold text-muted text-xs 2xl:text-sm">{row.description}</span>
        ),
      },
      {
        key: "category.name",
        visible: true,
        label: this.props.t("Category"),
        id: "category.name",
        width: 280,
        format: (row) => (
          <span  className="inline-flex items-center px-3 py-0.5 rounded-full  text-xs 2xl:text-sm font-medium bg-yellow-100 text-yellow-800" style={{color: "#FFFFFF", backgroundColor: generateColor(row.category.name)}}>
            {this.props.t(row.category.name)}
          </span>
        ),
        filter: {
          name: "SelectColumnFilter",
          type: "includes",
        },
      },
      {
        key: "lastModificationDateDisplay",
        visible: true,
        label: this.props.t("lastModificationDate"),
        id: "lastModificationDateDisplay",
        width: 250,
        format: (row) => (
          <span className="font-weight-bold text-muted text-xs 2xl:text-sm">
            {moment(row.lastModificationDate).calendar()}
          </span>
        ),
      },
      {
        key: "photos",
        visible: true,
        label: this.props.t("Photos"),
        width: 250,
        format: (row) => (
          <span className="font-weight-bold text-muted text-xs 2xl:text-sm">
            {row.files.length + " " + this.props.t("photos")}
          </span>
        ),
      },
    ];
    var selectedColumns = [];
    columns.forEach((column) => {
      if (column.visible) {
        selectedColumns.push(column.key);
      }
    });
    this.setState({
      columns,
      galleries,
      categories,
      selectedColumns,
      selectedCategories,
    });
  };

  render() {
    const { t } = this.props;
    var galleries = this.props.galleries;
    var columns = this.state.columns;
    var selectedColumns = this.state.selectedColumns;
    var selectedCategories = this.state.selectedCategories;
    var searchText = this.state.searchText;
    if (columns) {
      columns = columns.filter((o) =>
        selectedColumns.some((item) => item === o.key)
      );
    }
    if (galleries) {
      galleries = galleries.filter((o) =>
        selectedCategories.some((item) => item === o.category.id)
      );
    }
    if (searchText.length > 2) {
      galleries = galleries.filter((o) =>
        o.name.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    return (
      <>
        <FiltersBar
          components={
            <>
              <FiltersTitle title={galleries
                  ? galleries.length + " " + t("Galeries").toLowerCase()
                  : ""}
              />
              <FilterSeparator />
              <FiltersSearch search={<SearchInput
                value={this.state.searchText}
                onChange={(searchText) => this.setState({ searchText })}
              />} />
              <FilterSeparator />
              <FiltersDropDowns selects={[
                <SelectMultiple
                  options={this.state.categories}
                  keys={this.state.selectedCategories}
                  className="mx-2"
                  onChange={(res) => this.setState({ selectedCategories: res })}
                  fixedLabel={t("Category")}
                />
                ]} 
              />
              <FilterSeparator />
              <FilterColumns
                columns={
                  <SelectMultiple
                    options={this.state.columns}
                    keys={this.state.selectedColumns}
                    className="mx-2"
                    onChange={(res) => this.setState({ selectedColumns: res })}
                    fixedLabel={t("Active columns")}
                  />
                }
              />
              <FilterSeparator />
              <ExportToCSV columns={columns} jsonData={galleries} fileName={t("Galleries") + ".csv"} />
            </>
          }
          buttons={this.props.buttons}
        />
        <TableSimple columns={columns} data={galleries} />
      </>
    );
  }
}

export default withTranslation()(TablePlayerGalleries);
