import React from "react";
import ButtonLight from "../../components/buttons/ButtonLight";
import Card from "../../components/card/Card";
import GeneratedForm from "../../utils/forms/generateForm";

function MatchTimeline({ t, matchInfos }) {
  return (
    <div className="mx-6 my-3">
      <Card
        title="Faits du match"
        headerActions={
          <GeneratedForm
            key="updateCommentPlayer"
            component={(show) => (
              <ButtonLight onClick={show} disabled={!navigator.onLine}>
                {t("Edit")}
              </ButtonLight>
            )}
            callback={(res) => this.props.refresh()}
            formName="updateCommentPlayer"
          />
        }
      >
        <div className="container  mx-auto w-full h-full">
          <div className="relative wrap overflow-hidden p-10 h-full">
            {matchInfos &&
              matchInfos.timeline &&
              matchInfos.timeline.length > 0 && (
                <div
                  className="border-2-2 absolute border-opacity-20 border-gray-700 h-full border"
                  style={{ left: "50%" }}
                ></div>
              )}
            {matchInfos &&
              matchInfos.timeline &&
              matchInfos.timeline.map((info) => {
                return (
                  <div
                    key={info.id}
                    className={
                      info.isTeam
                        ? "mb-8 flex justify-between flex-row-reverse items-center w-full left-timeline"
                        : "mb-8 flex justify-between items-center w-full right-timeline "
                    }
                  >
                    <div className="order-1 w-5/12"></div>
                    <div className="z-20 flex items-center order-1 bg-gray-800 shadow-xl w-10 h-10 rounded-full">
                      <h1
                        className={
                          info.isTeam
                            ? "mx-auto font-semibold text-lg text-white"
                            : "mx-auto text-white font-semibold text-lg"
                        }
                      >
                        {info.minutes}
                      </h1>
                    </div>
                    <div
                      className={
                        info.isTeam
                          ? "order-1 rounded-lg shadow-xl w-5/12 px-6 py-4 bg-yellow-400"
                          : "order-1 rounded-lg shadow-xl w-5/12 px-6 py-4 bg-red-700"
                      }
                    >
                      <h3
                        className={
                          info.isTeam
                            ? "mb-3 font-bold text-gray-800 text-l"
                            : "mb-3 font-bold text-white text-l"
                        }
                      >
                        {info.type} {info && info.title}
                      </h3>
                      <h3
                        className={
                          info.isTeam
                            ? "mb-3 font-bold text-gray-800 text-l"
                            : "mb-3 font-bold text-white text-l"
                        }
                      >
                        {info.description}
                      </h3>
                      <p
                        className={
                          info.isTeam
                            ? "text-sm leading-snug tracking-wide text-gray-900 text-opacity-100"
                            : "text-sm font-medium leading-snug tracking-wide text-white text-opacity-100"
                        }
                      >
                        {info && info.player && info.player.displayName}
                      </p>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </Card>
    </div>
  );
}

export default MatchTimeline;
