import React from "react"
import { Link } from "react-router-dom"
import Card from "./Card"
import Flag from "../icons/Flag"
import { withTranslation } from "react-i18next"

class CardTennisMatch extends React.Component {

  render() {
    const { t } = this.props
    var time = null
    if(this.props.match.duration) {
      let heure = this.props.match.duration / 60;
      time = Math.trunc(heure) + "H" + ((heure%1)*60).toFixed(0)
    }
    else {
      let heure = this.props.match.durationSet1 ? this.props.match.durationSet1 : 0;
      heure += this.props.match.durationSet2 ? this.props.match.durationSet2 : 0;
      heure += this.props.match.durationSet3 ? this.props.match.durationSet3 : 0;
      heure += this.props.match.durationSet4 ? this.props.match.durationSet4 : 0;
      heure += this.props.match.durationSet5 ? this.props.match.durationSet5 : 0;
      if(heure > 0)
        time = Math.trunc(heure) + "H" + ((heure%1)*60).toFixed(0)
    }

    return !this.props.player ? (
      ""
    ) : (
        <div className="col-xl-4 col-lg-6 mt-1 2xl:mt-2 w-full px-3 py-1 2xl:py-3">
          <Card><Link
                        to={"/competitionTennis/match/" + this.props.match.id}
                        className="tourStep9"
                      >
            <div
              className="rounded-md  border border-gray-200"
              style={{ boxShadow: "0 0 10px 0 rgba(82,63,105,.2)" }}
            >
              <div className="score-card-wrapper__heading bg-indigo-100 p-1 px-3 font-medium text-xs 2xl:text-sm pt-1.5 2xl:pt-2 flex h-7 2xl:h-9">
                <div className="wrapper-left w-1/2 text-left">
                  <div className="">
                    <span className="">{this.props.match.tour.name}</span>
                    <span className=""> – {this.props.match.mode}</span>
                    <span></span>
                  </div>
                </div>
                <div className="wrapper-right w-1/2 text-right">
                  <div className="score-card-wrapper__match-duration">
                    <span>
                        {
                          time ?
                            <div className="font-small text-gray-500 text-xs 2xl:text-sm">
                              {time}
                            </div>
                          : ""
                        }
                    </span>
                  </div>
                </div>
              </div>
              <div className="w-full">
                {this.props.match.result &&
                <table className="w-full">
                  <tbody>
                    <tr className="h-10 2xl:h-12">
                      <td className="w-3/4 pl-3">
                        <div
                          className={
                            this.props.match.result != null &&
                              (this.props.match.result === "V" ||
                                this.props.match.result === "Vab")
                              ? "text-indigo-700 text-sm 2xl:text-base font-semibold flex truncate"
                              : "text-gray-400 text-sm 2xl:text-base flex truncate"
                          }
                        >
                          <Flag
                            className="w-4 2xl:w-6 mr-3 pt-1"
                            nationalityId={this.props.player.nationalityId}
                          />
                          <div>
                            {this.props.player.firstName +
                              " " +
                              this.props.player.lastName}
                          </div>
                          {this.props.match.partner != null
                            ? (" / " +
                              this.props.match.partner.firstName +
                              " " +
                              this.props.match.partner.lastName)
                            : ""}
                          <span className="player-seed">&nbsp;</span>
                        </div>
                      </td>
                      <td>
                        {this.props.match.result != null &&
                          (this.props.match.result === "V" ||
                            this.props.match.result === "Vab") ? (
                            <i className="ki ki-bold-check mr-3 text-success"></i>
                          ) : (
                            ""
                          )}
                        {this.props.match.result != null &&
                          (this.props.match.result === "D" ||
                            this.props.match.result === "Dab") ? (
                            <i className="ki ki-bold-close mr-3 text-danger"></i>
                          ) : (
                            ""
                          )}
                      </td>
                      {this.props.match.resultSet1 != null ? (
                        <td
                          className={
                            this.props.match.resultSet1Adv != null &&
                              this.props.match.resultSet1 >
                              this.props.match.resultSet1Adv
                              ? "font-semibold text-sm 2xl:text-base text-gray-900"
                              : "text-gray-400 text-sm 2xl:text-base"
                          }
                        >
                          <span>{this.props.match.resultSet1}</span>
                        </td>
                      ) : (
                          ""
                        )}
                      {this.props.match.resultSet2 != null ? (
                        <td
                          className={
                            this.props.match.resultSet2Adv != null &&
                              this.props.match.resultSet2 >
                              this.props.match.resultSet2Adv
                              ? "font-semibold text-gray-900 text-sm 2xl:text-base"
                              : "text-gray-400 text-sm 2xl:text-base"
                          }
                        >
                          <span>{this.props.match.resultSet2}</span>
                        </td>
                      ) : (
                          ""
                        )}
                      {this.props.match.resultSet2 != null ? (
                        <td
                          className={
                            this.props.match.resultSet3Adv != null &&
                              this.props.match.resultSet3 >
                              this.props.match.resultSet3Adv
                              ? "font-semibold text-gray-900 text-sm 2xl:text-base"
                              : "text-gray-400 text-sm 2xl:text-base"
                          }
                        >
                          <span>{this.props.match.resultSet3}</span>
                        </td>
                      ) : (
                          ""
                        )}
                      {this.props.match.resultSet2 != null ? (
                        <td
                          className={
                            this.props.match.resultSet4Adv != null &&
                              this.props.match.resultSet4 >
                              this.props.match.resultSet4Adv
                              ? "font-semibold text-gray-900 text-sm 2xl:text-base"
                              : "text-gray-400 text-sm 2xl:text-base"
                          }
                        >
                          <span>{this.props.match.resultSet4}</span>
                        </td>
                      ) : (
                          ""
                        )}
                      {this.props.match.resultSet2 != null ? (
                        <td
                          className={
                            this.props.match.resultSet5Adv != null &&
                              this.props.match.resultSet5 >
                              this.props.match.resultSet5Adv
                              ? "font-semibold text-gray-900 text-sm 2xl:text-base"
                              : "text-gray-400 text-sm 2xl:text-base"
                          }
                        >
                          <span>{this.props.match.resultSet5}</span>
                        </td>
                      ) : (
                          ""
                        )}
                    </tr>
                    <tr className="h-10 2xl:h-12 border-t">
                      <td className="w-3/4 pl-3">
                        <div
                          className={
                            this.props.match.result != null &&
                              (this.props.match.result === "D" ||
                                this.props.match.result === "Dab")
                              ? "text-gray-800 font-semibold flex text-sm 2xl:text-base truncate"
                              : "text-gray-400 flex text-sm 2xl:text-base truncate"
                          }
                        >
                          {this.props.match.opponent ? (
                            <Flag
                              className="w-4 2xl:w-6 mr-3 pt-1"
                              nationalityId={this.props.match.opponent.nationality}
                            />

                          ) : (
                              ""
                            )}
                          {this.props.match.opponent != null
                            ? this.props.match.opponent.firstName +
                            " " +
                            this.props.match.opponent.lastName
                            : ""}
                          {this.props.match.opponent2 != null
                            ? " / " +
                            this.props.match.opponent2.firstName +
                            " " +
                            this.props.match.opponent2.lastName
                            : ""}
                          <span className="player-seed">&nbsp;</span>
                        </div>
                      </td>
                      <td className="status"></td>
                      {this.props.match.resultSet1Adv != null ? (
                        <td
                          className={
                            this.props.match.resultSet1 != null &&
                              this.props.match.resultSet1Adv >
                              this.props.match.resultSet1
                              ? "font-semibold text-gray-900 text-sm 2xl:text-base"
                              : "text-gray-400 text-sm 2xl:text-base"
                          }
                        >
                          <span>{this.props.match.resultSet1Adv}</span>
                        </td>
                      ) : (
                          ""
                        )}
                      {this.props.match.resultSet2Adv != null ? (
                        <td
                          className={
                            this.props.match.resultSet2 != null &&
                              this.props.match.resultSet2Adv >
                              this.props.match.resultSet2
                              ? "font-semibold text-gray-900 text-sm 2xl:text-base"
                              : "text-gray-400 text-sm 2xl:text-base"
                          }
                        >
                          <span>{this.props.match.resultSet2Adv}</span>
                        </td>
                      ) : (
                          ""
                        )}
                      {this.props.match.resultSet3Adv != null ? (
                        <td
                          className={
                            this.props.match.resultSet3 != null &&
                              this.props.match.resultSet3Adv >
                              this.props.match.resultSet3
                              ? "font-semibold text-gray-900 text-sm 2xl:text-base"
                              : "text-gray-400 text-sm 2xl:text-base"
                          }
                        >
                          <span>{this.props.match.resultSet3Adv}</span>
                        </td>
                      ) : (
                          ""
                        )}
                      {this.props.match.resultSet4Adv != null ? (
                        <td
                          className={
                            this.props.match.resultSet4 != null &&
                              this.props.match.resultSet4Adv >
                              this.props.match.resultSet4
                              ? "font-semibold text-gray-900 text-sm 2xl:text-base"
                              : "text-gray-400 text-sm 2xl:text-base"
                          }
                        >
                          <span>{this.props.match.resultSet4Adv}</span>
                        </td>
                      ) : (
                          ""
                        )}
                      {this.props.match.resultSet5Adv != null ? (
                        <td
                          className={
                            this.props.match.resultSet5 != null &&
                              this.props.match.resultSet5Adv >
                              this.props.match.resultSet5
                              ? "font-semibold text-gray-900 text-sm 2xl:text-base"
                              : "text-gray-400 text-sm 2xl:text-base"
                          }
                        >
                          <span>{this.props.match.resultSet5Adv}</span>
                        </td>
                      ) : (
                          ""
                        )}
                    </tr>
                  </tbody>
                </table>}
              </div>
            </div>
                <div className="text-sm font-small text-gray-500">
                  {time ? time : t("(no time data)")}
                </div>
            {/* </Link> */}</Link>
          </Card>
        </div>
      )
  }
}

export default withTranslation()(CardTennisMatch);
