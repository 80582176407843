import React from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { SelectMultiple } from "../components/forms";
import TableSimple from "../components/table/TableSimple";
import IconRound32x32 from "../components/icons/IconRound32x32";
import FiltersDropDowns from "../components/filters/FiltersDropDowns";
import FilterColumns from "../components/filters/FilterColumns";
import FiltersSearch from "../components/filters/FiltersSearch";
import SearchInput from "../components/forms/input/SearchInput";
import ExportToCSV from "../components/export/ExportToCSV";
import Label from "../components/typography/Label";
import moment from "moment";
import FilterSeparator from "../components/filters/FilterSeparator";
import FiltersTitle from "../components/filters/FiltersTitle";
import FiltersBar from "../components/filters/FiltersBar";

class TableRPE extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actions: [],
      groups: [],
      selectedGroups: [],
      positions: [],
      selectedPositions: [],
      columns: [],
      searchText: "",
    };
  }

  componentDidMount() {
    this.prepareData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.prepareData();
    }
  }

  prepareData = () => {
    var players = this.props.players;
    var groups = [];
    var selectedGroups = [];
    var positions = [];
    var selectedPositions = [];
    if (players) {
      players = players.map((player) => {
        if (groups && !groups.find((o) => o.key === player.group1.id)) {
          groups.push({
            key: player.group1.id,
            label: player.group1.name,
          });
          selectedGroups.push(player.group1.id);
        }
        if (positions && !positions.find((o) => o.key === player.position.id)) {
            positions.push({
              key: player.position.id,
              label: player.position.name,
            });
            selectedPositions.push(player.position.id);
          }
        return player;
      });
    }

    const columns = [
      {
        key: "name",
        visible: true,
        pinned: "left",
        label: this.props.t("Player"),
        id: "displayName",
        width: 300,
        format: (row) => (
          <Link
            className="text-indigo-700 font-semibold text-xs 2xl:text-sm"
            to={"/group/player/" + row.id}
          >
            <IconRound32x32 photo={row.photo} />
            <span className="ml-5">
              {row.lastName.toUpperCase() + " " + row.firstName}
            </span>
          </Link>
        ),
      },
      {
        key: "previousPreviousPreviousPreviousPreviousPreviousPreviousDayValue",
        visible: true,
        id: "previousPreviousPreviousPreviousPreviousPreviousPreviousDayValue",
        label: moment().add(-7, "days").format('ddd DD/MM'),
        format: (row) =>
        <span className=" text-xs 2xl:text-sm">
          {
          row &&
          row.previousPreviousPreviousPreviousPreviousPreviousPreviousDayValue
            ? (row.previousPreviousPreviousPreviousPreviousPreviousPreviousDayValue < 400 ? <Label type="success" text={row.previousPreviousPreviousPreviousPreviousPreviousPreviousDayValue + " UA"} /> : (row.previousPreviousPreviousPreviousPreviousPreviousPreviousDayValue < 700 ? <Label type="warning" text={row.previousPreviousPreviousPreviousPreviousPreviousPreviousDayValue + " UA"} /> : <Label type="danger" text={row.previousPreviousPreviousPreviousPreviousPreviousPreviousDayValue + " UA"} />))
            : ""
          }
        </span>,
      },
      {
        key: "previousPreviousPreviousPreviousPreviousPreviousDayValue",
        visible: true,
        id: "previousPreviousPreviousPreviousPreviousPreviousDayValue",
        label: moment().add(-6, "days").format('ddd DD/MM'),
        format: (row) =>
        <span className=" text-xs 2xl:text-sm">
          {
          row &&
          row.previousPreviousPreviousPreviousPreviousPreviousDayValue
            ? (row.previousPreviousPreviousPreviousPreviousPreviousDayValue < 400 ? <Label type="success" text={row.previousPreviousPreviousPreviousPreviousPreviousDayValue + " UA"} /> : (row.previousPreviousPreviousPreviousPreviousPreviousDayValue < 700 ? <Label type="warning" text={row.previousPreviousPreviousPreviousPreviousPreviousDayValue + " UA"} /> : <Label type="danger" text={row.previousPreviousPreviousPreviousPreviousPreviousDayValue + " UA"} />))
            : ""
          }
        </span>,
      },
      {
        key: "previousPreviousPreviousPreviousPreviousDayValue",
        visible: true,
        id: "previousPreviousPreviousPreviousPreviousDayValue",
        label: moment().add(-5, "days").format('ddd DD/MM'),
        format: (row) =>
        <span className=" text-xs 2xl:text-sm">
          {
          row &&
          row.previousPreviousPreviousPreviousPreviousDayValue
            ? (row.previousPreviousPreviousPreviousPreviousDayValue < 400 ? <Label type="success" text={row.previousPreviousPreviousPreviousPreviousDayValue + " UA"} /> : (row.previousPreviousPreviousPreviousPreviousDayValue < 700 ? <Label type="warning" text={row.previousPreviousPreviousPreviousPreviousDayValue + " UA"} /> : <Label type="danger" text={row.previousPreviousPreviousPreviousPreviousDayValue + " UA"} />))
            : ""}</span>,
      },
      {
        key: "previousPreviousPreviousPreviousDayValue",
        visible: true,
        id: "previousPreviousPreviousPreviousDayValue",
        label: moment().add(-4, "days").format('ddd DD/MM'),
        format: (row) =>
        <span className=" text-xs 2xl:text-sm">{
          row &&
          row.previousPreviousPreviousPreviousDayValue
            ? (row.previousPreviousPreviousPreviousDayValue < 400 ? <Label type="success" text={row.previousPreviousPreviousPreviousDayValue + " UA"} /> : (row.previousPreviousPreviousPreviousDayValue < 700 ? <Label type="warning" text={row.previousPreviousPreviousPreviousDayValue + " UA"} /> : <Label type="danger" text={row.previousPreviousPreviousPreviousDayValue + " UA"} />))
            : ""}</span>,
      },
      {
        key: "previousPreviousPreviousDayValue",
        visible: true,
        id: "previousPreviousPreviousDayValue",
        label: moment().add(-3, "days").format('ddd DD/MM'),
        format: (row) =>
        <span className=" text-xs 2xl:text-sm">{
          row &&
          row.previousPreviousPreviousDayValue
            ? (row.previousPreviousPreviousDayValue < 400 ? <Label type="success" text={row.previousPreviousPreviousDayValue + " UA"} /> : (row.previousPreviousPreviousDayValue < 700 ? <Label type="warning" text={row.previousPreviousPreviousDayValue + " UA"} /> : <Label type="danger" text={row.previousPreviousPreviousDayValue + " UA"} />))
            : ""}</span>,
      },
      {
        key: "previousPreviousDayValue",
        visible: true,
        id: "previousPreviousDayValue",
        label: moment().add(-2, "days").format('ddd DD/MM'),
        format: (row) =>
        <span className=" text-xs 2xl:text-sm">
          {
          row &&
          row.previousPreviousDayValue
            ? (row.previousPreviousDayValue < 400 ? <Label type="success" text={row.previousPreviousDayValue + " UA"} /> : (row.previousPreviousDayValue < 700 ? <Label type="warning" text={row.previousPreviousDayValue + " UA"} /> : <Label type="danger" text={row.previousPreviousDayValue + " UA"} />))
            : ""}</span>,
      },
      {
        key: "previousDayValue",
        visible: true,
        id: "previousDayValue",
        label: moment().add(-1, "days").format('ddd DD/MM'),
        format: (row) =>
        <span className=" text-xs 2xl:text-sm">{
          row &&
          row.previousDayValue
            ? (row.previousDayValue < 400 ? <Label type="success" text={row.previousDayValue + " UA"} /> : (row.previousDayValue < 700 ? <Label type="warning" text={row.previousDayValue + " UA"} /> : <Label type="danger" text={row.previousDayValue + " UA"} />))
            : ""}</span>,
      },
      {
        key: "currentDayValue",
        visible: true,
        id: "currentDayValue",
        label: moment().format('ddd DD/MM'),
        format: (row) =>
        <span className=" text-xs 2xl:text-sm">{
          row &&
          row.currentDayValue
            ? (row.currentDayValue < 500 ? <Label type="success" text={row.currentDayValue + " UA"} /> : (row.currentDayValue < 700 ? <Label type="warning" text={row.currentDayValue + " UA"} /> : <Label type="danger" text={row.currentDayValue + " UA"} />))
            : ""}</span>,
      }
    ];
    var selectedColumns = [];
    columns.forEach((column) => {
      if (column.visible) {
        selectedColumns.push(column.key);
      }
    });
    this.setState({
      columns,
      players,
      groups,
      positions,
      selectedColumns,
      selectedGroups,
      selectedPositions
    });
  };

  render() {
    const { t } = this.props;
    var players = this.props.players;
    var columns = this.state.columns;
    var selectedColumns = this.state.selectedColumns;
    var selectedGroups = this.state.selectedGroups;
    var selectedPositions = this.state.selectedPositions;
    var searchText = this.state.searchText;
    if (columns) {
      columns = columns.filter((o) =>
        selectedColumns.some((item) => item === o.key)
      );
    }
    if (players) {
      players = players.filter((o) =>
        selectedGroups.some((item) => item === o.group1.id)
      );
    }
    if (players) {
        players = players.filter((o) =>
          selectedPositions.some((item) => item === o.position.id)
        );
      }
    if (searchText.length > 2) {
      players = players.filter(
        (o) =>
          o.lastName.toLowerCase().includes(searchText.toLowerCase()) ||
          o.firstName.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    return (
      <>
        <FiltersBar
          components={
            <>
              <FiltersTitle title={players ? players.length + " " + t("Players").toLowerCase() : ""} />
              <FilterSeparator />
              <FiltersSearch search={<SearchInput
                value={this.state.searchText}
                onChange={(searchText) => this.setState({ searchText })}
              />} />
              <FilterSeparator />
              <FiltersDropDowns selects={[
                <SelectMultiple
                  options={this.state.groups}
                  keys={this.state.selectedGroups}
                  className="mx-2"
                  onChange={(res) => this.setState({ selectedGroups: res })}
                  fixedLabel={t("Group")}
                />,
                <SelectMultiple
                  options={this.state.positions}
                  keys={this.state.selectedPositions}
                  className="mx-2"
                  onChange={(res) => this.setState({ selectedPositions: res })}
                  fixedLabel={t("Group")}
                />
                ]} 
              />
              <FilterSeparator />
              <FilterColumns
                columns={
                  <SelectMultiple
                    options={this.state.columns}
                    keys={this.state.selectedColumns}
                    className="mx-2"
                    onChange={(res) => this.setState({ selectedColumns: res })}
                    fixedLabel={t("Active columns")}
                  />
                }
              />
              <FilterSeparator />
              <ExportToCSV columns={columns} jsonData={players} fileName={t("Anthropometry") + ".csv"} />
            </>
          }
          buttons={this.props.buttons}
        />
        <TableSimple columns={columns} data={players} />
      </>
    );
  }
}

export default withTranslation()(TableRPE);
