import React from "react";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import Page from "../../components/page/Page";
import GeneratedForm from "../../utils/forms/generateForm";
import Button from "../../components/buttons/Button";
import {
  getGallery,
  loadGallery,
} from "../../utils/indexedDB/medias/handleMedia";
import ModalImage from "react-modal-image";
import ActionButton from "../../components/buttons/ActionButton";
import { getStaffs, loadStaffs } from "../../utils/indexedDB/users/handleUser";
import ButtonLight from "../../components/buttons/ButtonLight";
import SVGEdit from "../../components/icons/SVGEdit";
import SVGCreate from "../../components/icons/SVGCreate";


class Photos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
    };
  }
  componentDidMount() {
    var id = this.props.match.params.id;
    getStaffs().then(staffs => {
    getGallery(id).then((gallery) => {
    if (gallery.files)
    {
      var files = [];
      gallery.files.map(file => {
          if (staffs.find(o => o.id === file.authorId)) { file.author = staffs.find(o => o.id === file.authorId)}
          files.push(file);
          return file;
      })
      gallery.files = files;
    }
    this.setState({ gallery })
  })
   
    })
    if (navigator.onLine) {
      loadStaffs().then(staffs => {
        loadGallery(id).then((gallery) =>  {
          if (gallery.files)
          {
            var files = [];
            gallery.files.map(file => {
                if (staffs.find(o => o.id === file.authorId)) { file.author = staffs.find(o => o.id === file.authorId)}
                files.push(file);
                return file;
            })
            gallery.files = files;
          }
          this.setState({ gallery })
      
        });

      });
      
    }
  }

  render() {
    const { t } = this.props;
    return (
      <Page
        backPage="/galleries"
        pageTitle={
          this.state.gallery
            ? this.state.gallery.name +
            " - " +
            t("Photos list") +
            (this.state.gallery.files
              ? " (" + this.state.gallery.files.length + ")"
              : "")
            : ""
        }
        tab={[
          {
            title: t("Photos"),
            visible: true,
            data: this.state.gallery ? this.state.gallery.files : null,
            component: (data) => {
              return (
                <>
                <div className="mt-2 2xl:mt-6 w-full mx-auto grid gap-6 2xl:gap-8 grid-cols-4 2xl:max-w-none p-3 2xl:p-6 mb-20">
                {data
                    ? data.map((photo) => (
                    <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                      <div className="flex-shrink-0">
                      <ModalImage
                          small={photo.filePath}
                          large={photo.filePath}
                          alt={photo.name}
                          className=" h-40 2xl:h-64 w-full object-cover"
                        />
                        {/* <img className="h-48 w-full object-cover" src="https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80" alt="" /> */}
                      </div>
                      <div className="flex-1 bg-white p-3 2xl:p-6 px-5 2xl:px-6 flex flex-col justify-between">
                        <div className="flex-1">
                          {/* <p className="text-xs 2xl:text-sm font-medium text-indigo-600">
                            <span className="hover:underline">
                              {moment(photo.date).calendar()}
                            </span>
                          </p> */}
                          <span className="block mt-1 2xl:mt-2">
                            <div className="flex justify-between">
                            <p className="text-sm 2xl:text-base font-semibold text-gray-900 truncate">
                              {photo.name}
                            </p>
                            <ActionButton
                              key={photo.id}
                              updateForm="updatePhoto"
                              deleteForm="deletePhoto"
                              formData={photo}
                              refreshData={() =>
                                loadStaffs().then(staffs => {
                                  loadGallery(this.state.id).then((gallery) =>  {
                                    if (gallery.files)
                                    {
                                      var files = [];
                                      gallery.files.map(file => {
                                          if (staffs.find(o => o.id === file.authorId)) { file.author = staffs.find(o => o.id === file.authorId)}
                                          files.push(file);
                                          return file;
                                      })
                                      gallery.files = files;
                                    }
                                    this.setState({ gallery })
                                
                                  });
                          
                                })
                              }
                            />
                            </div>
                            <p className="mt-1 2xl:mt-3 text-xs 2xl:text-sm text-gray-500">
                              {photo.description}
                            </p>
                          </span>
                        </div>
                        <div className="mt-4 2xl:mt-6 flex items-center">
                          <div className="flex-shrink-0">
                            <span>
                              <span className="sr-only">{photo.author ? photo.author.lastName.toUpperCase() : ""}</span>
                              <img className="h-7 w-7 2xl:h-10 2xl:w-10 rounded-full" src={photo.author ? photo.author.photo : ""} alt="" />
                            </span>
                          </div>
                          <div className="ml-3">
                            <p className="text-xs 2xl:text-sm font-medium text-gray-900">
                              <span>
                                {photo.author ? photo.author.lastName.toUpperCase() + " " + photo.author.firstName : ""}
                              </span>
                            </p>
                            <div className="flex space-x-1 text-xs 2xl:text-sm text-gray-500">
                            {photo.author ? photo.author.title : ""}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
))
: ""}


                  </div>
                </>
              );
            },
          },
        ]}
        actions={{
          other: [
            <GeneratedForm
              key="createPhoto"
              title={t("Add photo")}
              component={(show, text) => (
                <Button onClick={show} style={{ marginRight: "5px" }}>
                  <SVGCreate />
                </Button>
              )}
              method="POST"
              formName="createPhoto"
              formData={{ galleryId: this.state.id }}
              callback={() =>
                loadGallery(this.state.id).then((gallery) =>
                  this.setState({ gallery })
                )
              }
            />,
            <GeneratedForm
              key="updateGallery"
              title={t("Update")}
              component={(show, text) => (
                <ButtonLight onClick={show} style={{ marginRight: "5px" }}>
                  <SVGEdit />
                </ButtonLight>
              )}
              method="PATCH"
              formName="updateGallery"
              formData={this.state.gallery}
              callback={() =>
                loadGallery(this.state.id).then((gallery) =>
                  this.setState({ gallery })
                )
              }
            />
          ],
        }}
      />
    );
  }
}

export default withTranslation()(withRouter(Photos));
