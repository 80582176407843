import React from "react";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import Page from "../../components/page/Page";
import {
  getCompetitionById,
  loadInternationalCompetitionById,
} from "../../utils/indexedDB/Tennis-Competition/handleCompetitionTennis";
import moment from "moment";
import ActionButton from "../../components/buttons/ActionButton";
import {
  getPlayers,
  getPlayersAll,
  getStaffs,
} from "../../utils/indexedDB/users/handleUser";
import Card from "../../components/card/Card";
import Loading from "../../components/loaders/Loading";
import CardTennisMatch from "../../components/card/CardTennisMatch";
import Flag from "../../components/icons/Flag";
import DisplayData3cols from "../../components/displayData/DisplayData3cols";
import TableTournamentsINTParticipants from "../../tables/TableTournamentsINTParticipants";
import { Bracket, Seed, SeedItem, SeedTeam } from 'react-brackets';
import ButtonLight from "../../components/buttons/ButtonLight";
import GeneratedForm from "../../utils/forms/generateForm";
import SVGEdit from "../../components/icons/SVGEdit";
import SVGDelete from "../../components/icons/SVGDelete";
import ButtonLightRed from "../../components/buttons/ButtonLightRed";

const CustomSeed = (seed, breakpoint, roundIndex) => {
  // breakpoint passed to Bracket component
  // to check if mobile view is triggered or not

  // mobileBreakpoint is required to be passed down to a seed
  return (
    <Seed mobileBreakpoint={breakpoint} style={{ fontSize: 12, fontFamily: "inherit", width: "300px" }} className="w-96">
      <SeedItem className="shadow-2xl">
        <div>
          <SeedTeam style={seed.teams[0].win ? { fontWeight: '600', backgroundColor: '#253382'} : {fontStyle: 'italic', backgroundColor: '#f6f6f6', color: '#3c3c3c', opacity: '0.9'}}>
            <div className="flex justify-between w-full">
              <div className="flex">
                <Flag className="w-6" nationalityId={seed.teams[0].nationality} />
                <div className="flex px-3">
                  {seed.teams[0].name}
                </div>
              </div>
              <div className="flex">
                {
                  seed.teams[0].scoreSet1 != null ?  " " + seed.teams[0].scoreSet1 : ""
                }
                {
                  seed.teams[0].scoreSet2 != null ?  " " + seed.teams[0].scoreSet2 : ""
                }
                {
                  seed.teams[0].scoreSet3 != null ?  " " + seed.teams[0].scoreSet3 : ""
                }
                {
                  seed.teams[0].scoreSet4 != null ?  " " + seed.teams[0].scoreSet4 : ""
                }
                {
                  seed.teams[0].scoreSet5 != null ?  " " + seed.teams[0].scoreSet5 : ""
                }
              </div>
            </div>
          </SeedTeam>
          <SeedTeam style={seed.teams[1].win ? { fontWeight: '600', backgroundColor: '#253382'} : {fontStyle: 'italic', backgroundColor: '#f6f6f6', color: '#3c3c3c', opacity: '0.9'}}>
          <div className="flex justify-between w-full">
          <div className="flex">
              <Flag className="w-6" nationalityId={seed.teams[1].nationality} />
              <div className="flex px-3">
                {seed.teams[1].name}
              </div>
            </div>
            <div className="flex">
            {
                  seed.teams[1].scoreSet1 != null ?  " " + seed.teams[1].scoreSet1 : ""
                }
                {
                  seed.teams[1].scoreSet2 != null ?  " " + seed.teams[1].scoreSet2 : ""
                }
                {
                  seed.teams[1].scoreSet3 != null ?  " " + seed.teams[1].scoreSet3 : ""
                }
                {
                  seed.teams[1].scoreSet4 != null ?  " " + seed.teams[1].scoreSet4 : ""
                }
                {
                  seed.teams[1].scoreSet5 != null ?  " " + seed.teams[1].scoreSet5 : ""
                }
              </div>
            </div>
            </SeedTeam>
        </div>
      </SeedItem>
    </Seed>
  );
};
class InternationalDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      refresh: false,
      disableDelete: true,
    };
  }

  

  componentDidMount() {
    var id = this.props.match.params.id;

    getCompetitionById(id).then((competition) => {
      this.setState({ competition, disableDelete: competition ? competition.disableDelete : true });
      getPlayers().then((players) =>
        players && players.length ? this.setState({ players }) : null
      );
      getPlayersAll().then((playersAll) =>
        playersAll && playersAll.length ? this.setState({ playersAll }) : null
      );
      getStaffs().then((staffs) =>
        staffs && staffs.length
          ? this.setState({ staffs, loading: false })
          : null
      );
    });
    if (navigator.onLine) {
      loadInternationalCompetitionById(this.props.match.params.id).then(
        (competition) => {
          this.setState({
            competition,
            disableDelete: competition ? competition.disableDelete : true,
          });
          getPlayers().then((players) =>
            players && players.length
              ? this.setState({ players, loading: false })
              : null
          );
        }
      );
    }
  }

  update() {
    this.setState({ loading: true });
    loadInternationalCompetitionById(this.props.match.params.id).then(
      (competition) => {
        this.setState({
          competition,
          disableDelete: competition ? competition.disableDelete : true,
        });
        getPlayers().then((players) =>
          players && players.length
            ? this.setState({ players, loading: false })
            : null
        );
      }
    );
  }

  getPlayers(engagements) {
    var users = [];
    if (engagements)
      engagements.forEach((p) => {
        if (
          this.state.players &&
          this.state.players.find((x) => x.id === p.playerId)
        ) {
          var user = this.state.players.find((x) => x.id === p.playerId);
          user.eId = p.id;
          user.tId = p.tournamentId;
          users.push(user);
        }
      });
    return users;
  }

  getSupervisors(supervisors) {
    var users = [];
    if (supervisors)
      supervisors.forEach((s) => {
        if (
          this.state.staffs &&
          this.state.staffs.find((x) => x.id === s.staffId)
        ) {
          var user = this.state.staffs.find((x) => x.id === s.staffId);
          user.eId = s.id;
          users.push(user);
        }
      });
    return users;
  }

  render() {
    const { t } = this.props;
    var matches = this.state.competition ? this.state.competition.matches : null;
    var tours = [];
    var table = [];
    if (matches)
    {
      matches.forEach(match => {
        if (!tours.find(o => o.id === match.tourId))
        {
          tours.push(match.tour);
        }
      });
      if (tours)
      {
        tours.forEach(tour => {
          if (matches.find(o => o.tourId === tour.id))
          {
            var tourMatches = matches.filter(o => o.tourId === tour.id);
            var seeds = [];
            tourMatches.forEach(tourMatch => {
              seeds.push({
                id: tourMatch.index,
                date: new Date().toDateString(),
                teams: [
                  { 
                    name: tourMatch.opponent1.lastName + " " + tourMatch.opponent1.firstName, 
                    nationality: tourMatch.opponent1.nationality, 
                    scoreSet1: tourMatch.resultSet1,
                    scoreSet2: tourMatch.resultSet2,
                    scoreSet3: tourMatch.resultSet3,
                    scoreSet4: tourMatch.resultSet4,
                    scoreSet5: tourMatch.resultSet5,
                    win: tourMatch.result === "V"
                  }, 
                  {
                    name: tourMatch.opponent2.lastName + " " + tourMatch.opponent2.firstName,
                    nationality: tourMatch.opponent2.nationality, 
                    scoreSet1: tourMatch.resultSet1Adv,
                    scoreSet2: tourMatch.resultSet2Adv,
                    scoreSet3: tourMatch.resultSet3Adv,
                    scoreSet4: tourMatch.resultSet4Adv,
                    scoreSet5: tourMatch.resultSet5Adv,
                    win: tourMatch.result === "D"
                  }
                ],
              })
            });
            table.push({
              title: t(tour.name),
              seeds: seeds
            })
          }
        })
      }
    }
    
    const rounds = table;

    return (
      <Page
        pageTitle={this.state.competition ? this.state.competition.name : t("International Details")}
        backPage="/competitionTennis/international"
        actions={{
          other: [
            <GeneratedForm
              key="updateTennisTournamentInternational"
              component={(show, text) => (
                <ButtonLight
                  onClick={show}
                  disabled={!navigator.onLine}
                >
                  <SVGEdit />
                </ButtonLight>
              )}
              callback={(res) =>
                this.update()
              }
              formName="updateTennisTournamentInternational"
              formData={this.state.competition}
            />,
            <GeneratedForm
              key="deleteTennisTournamentInternational"
              component={(show, text) => (
                <ButtonLightRed
                  onClick={show}
                  disabled={!navigator.onLine}
                >
                  <SVGDelete />
                </ButtonLightRed>
              )}
              callback={(res) =>
                this.props.history.push("/competitionTennis")
              }
              formName="deleteTennisTournamentInternational"
              formData={this.state.competition}
            />
          ],
        }}
        tab={[
          {
            title: t("Main information"),
            visible: true,
            data: this.state.competition,
            component: (data) => {
              return this.state.loading ? (
                <div style={{ height: "80vh", width: "100%" }}>
                  <Loading />
                </div>
              ) : (
                  <div>
                    <div className="flex space-x-5">
                      <div className="w-full py-3 px-10">
                        <Card>
                          <DisplayData3cols
                            data={[
                              {
                                key: t("Title"),
                                value: data.name
                              },
                              {
                                key: t("Circuit"),
                                value: data.circuit ? data.circuit.name : "N/A"
                              },
                              {
                                key: t("Classification"),
                                value: data.classification ? data.classification : ""
                              },
                              { key: t("Location"), value: (data.city + " (" + data.country + ")") },
                              { key: t("SDate"), value: moment(data.startDate).calendar() },
                              {
                                key: t("EDate"),
                                value: moment(data.endDate).calendar(),
                              },
                            ]}
                          />
                        </Card>
                      </div>
                    </div>
                    <div className="flex space-x-10 my-5 2xl:my-20 mx-10">
                      <div
                        className="w-1/2 py-4 px-5 mx-5"
                        style={{ boxShadow: "0 0 10px 0 rgba(82,63,105,.1)" }}
                      >
                        <Card title={t("Engagements")} className=" card-stretch" 
                          headerActions={[
                            <GeneratedForm
                              key="addTennisEngagement"
                              component={(show, text) => (
                                <ButtonLight
                                  onClick={show}
                                  disabled={!navigator.onLine}
                                >
                                  {t("Add")}
                                </ButtonLight>
                              )}
                              callback={(res) =>
                                this.update()
                              }
                              formName="addTennisEngagement"
                              formData={{
                                tournamentId: this.props.match.params.id,
                              }}
                            />
                          ]}>
                          <ul className="divide-y divide-gray-200">
                            {data.engagements
                              ? this.getPlayers(data.engagements).map((player, Skey) =>
                                player ? (
                                  <li className="py-4 flex" key={Skey}>
                                    <div className="flex justify-between w-full">
                                      <div className="flex">
                                        <img
                                          className="h-10 w-10 rounded-full"
                                          src={player.photo}
                                          alt=""
                                        />
                                        <div className="ml-3">
                                          <p className="text-md font-semibold text-gray-900">
                                            {player.lastName
                                              ? player.lastName.toUpperCase() +
                                              " " +
                                              player.firstName
                                              : null}
                                          </p>
                                          <p className="text-sm text-gray-500">{player.group1.name}</p>

                                        </div>
                                      </div>
                                      <ActionButton
                                        key={player.id}
                                        deleteForm="deleteTennisEngagement"
                                        formData={{
                                          id: player.eId,
                                          tournamentId: player.tId,
                                          playerId: player.id,
                                          name: player.lastName.toUpperCase() + " " + player.firstName,
                                        }}
                                        refreshData={() => this.update()}
                                      />
                                    </div>
                                    
                                    
                                  </li>
                                ) : (
                                    ""
                                  )
                              )
                              : ""}
                          </ul>
                        </Card>
                      </div>
                      <div
                        className="w-1/2 py-4 px-5 mx-5"
                        style={{ boxShadow: "0 0 10px 0 rgba(82,63,105,.1)" }}
                      >
                        <Card title={t("Supervisors")} className=" card-stretch" headerActions={[
                            <GeneratedForm
                              key="addTennisSupervision"
                              component={(show, text) => (
                                <ButtonLight
                                  onClick={show}
                                  disabled={!navigator.onLine}
                                >
                                  {t("Add")}
                                </ButtonLight>
                              )}
                              callback={(res) =>
                                this.update()
                              }
                              formName="addTennisSupervision"
                              formData={{
                                tournamentId: this.props.match.params.id,
                              }}
                            />
                          ]}>
                          <ul className="divide-y divide-gray-200">
                            {data.supervisors
                              ? this.getSupervisors(data.supervisors).map((staff, Skey) =>
                                staff ? (
                                  <li className="py-4 flex" key={Skey}>
                                    <div className="flex justify-between w-full">
                                      <div className="flex">
                                    <img
                                      className="h-10 w-10 rounded-full"
                                      src={staff.photo}
                                      alt=""
                                    />
                                    <div className="ml-3">
                                      <p className="text-md font-semibold text-gray-900">
                                        {staff.lastName
                                          ? staff.lastName.toUpperCase() +
                                          " " +
                                          staff.firstName
                                          : null}
                                      </p>
                                      <p className="text-sm text-gray-500">{staff.title}</p>
                                    </div>
                                    </div>
                                    <ActionButton
                                      key={staff.id}
                                      deleteForm="deleteTennisSupervision"
                                      formData={{
                                        id: staff.eId,
                                        tournamentId: this.props.match.params.id,
                                        playerId: staff.id,
                                        name: staff.lastName.toUpperCase() + " " + staff.firstName,
                                      }}
                                      refreshData={() => this.update()}
                                    /></div>
                                  </li>
                                ) : (
                                    ""
                                  )
                              )
                              : ""}
                          </ul>
                        </Card>
                      </div>
                    </div>
                  </div>
                );
            },
          },
          {
            title: t("Participants"),
            visible: true,
            data: this.state.competition
              ? this.state.competition.participants
              : null,
            component: (data) => {
              return this.state.loading ? (
                <div style={{ height: "80vh", width: "100%" }}>
                  <Loading />
                </div>
              ) : (
                  <>
                    <TableTournamentsINTParticipants
                        participants={data}
                      />
                  </>
                );
            },
          },

          {
            title: t("Results"),
            visible: true,
            data: this.state.competition
              ? this.state.competition.matches
              : null,
            component: (data) => {
              return this.state.loading ? (
                <div style={{ height: "80vh", width: "100%" }}>
                  <Loading />
                </div>
              ) : (
                  <>
                  {rounds != null ?<div style={{height: "75vh"}} className="m-10">
                  <Bracket rounds={rounds} renderSeedComponent={CustomSeed} /></div> : ""}
                    {data
                      ? data.map((match, key) => (
                        <CardTennisMatch
                          match={match}
                          player={this.state.players.find(
                            (x) => x.id === match.playerId
                          )}
                          key={key}
                        />
                      ))
                      : ""}
                  </>
                );
            },
          },
        ]}
      />
    );
  }
}

export default withTranslation()(withRouter(InternationalDetail));
