import React from "react";
import { withTranslation } from "react-i18next";
import { post, patch, del } from "../../requests/index";
import * as Drawer from "../../../components/drawer";
import * as FormsComponent from "../../../components/forms";
import { loadAnthropometricMeasures, loadAnthropometricThreshold } from "../../indexedDB/assessment/handleAssessment";
import { loadGenders } from "../../indexedDB/users/handleUser";
import Table from "../../../components/table/EditableTable";
import SVG from "react-inlinesvg";
import SvgTrash from "../../../assets/sass/vendors/theme/svg/icons/General/Trash.svg";
import Button from "../../../components/buttons/Button";
import cogoToast from "cogo-toast";
import MultipleSliderReadOnly from "../../../components/forms/input/MultipleSliderReadOnly";

// props:
// method

class AnthropometricThreshold extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            method: this.props.method,
            measureId: this.props.measureId ? this.props.measureId : "",
            sexe: this.props.sexe ? this.props.sexe : "",
            ageMin: this.props.ageMin ? parseFloat(this.props.ageMin.toString().replace(/, /, '.')) : 0,
            ageMax: this.props.ageMax ? parseFloat(this.props.ageMax.toString().replace(/, /, '.')) : 100,
            thresholds: this.props.thresholds ? this.props.thresholds : [],
            measures: [],
            genders: [],
            allThresholds: [],
            loading: true,
            searchingThreshold: false,
            loadingSubmit: false
        }
        this.addThresholds = this.addThresholds.bind(this);
        this.deleteThreshold = this.deleteThreshold.bind(this);
    }

    componentDidMount() {
        // load anthropometric measure
        loadAnthropometricMeasures().then(measures => this.setState({ measures }));
        loadGenders().then(genders => this.setState({ genders }));
        loadAnthropometricThreshold().then(allThresholds => this.setState({ allThresholds, loading: false }, () => {
            this.loadThreshold(this.props.measureId, this.props.sexe, this.props.ageMin ? parseFloat(this.props.ageMin.toString().replace(/, /, '.')) : 0, this.props.ageMax ? parseFloat(this.props.ageMax.toString().replace(/, /, '.')) : 100)
        }));
    }

    componentDidUpdate(prevProps) {
        if (prevProps.thresholds !== this.props.thresholds) {
            this.setState({ thresholds: this.props.thresholds });
        }
    }

    onChange(field, value) {
        var { measureId, sexe, ageMin, ageMax } = this.state;
        switch (field) {
            case "measureId":
                measureId = value;
                break;
            case "sexe":
                sexe = value;
                break;
            case "ageMin":
                ageMin = value;
                break;
            case "ageMax":
                ageMax = value;
                break;
            default:
                break;
        }
        this.loadThreshold(measureId, sexe, ageMin, ageMax);
        this.setState({ measureId, sexe, ageMin, ageMax });
    }

    loadThreshold(measureId, sexe, ageMin, ageMax) {
        if (this.state.allThresholds && this.state.allThresholds.length) {
            this.setState({ searchingThreshold: true });
            var thresholds = this.state.allThresholds.find(t => t.anthropometricMeasureId === measureId && t.sexe === sexe && t.ageMin.toString() === ageMin.toString() && t.ageMax.toString() === ageMax.toString()) ?
                this.state.allThresholds.find(t => t.anthropometricMeasureId === measureId && t.sexe === sexe && t.ageMin.toString() === ageMin.toString() && t.ageMax.toString() === ageMax.toString()).thresholds : []
            this.setState({ thresholds, searchingThreshold: false }, () => {
                if (thresholds.length === 0 && measureId && sexe && (ageMin || ageMin === 0) && ageMax) {
                    this.addThresholds()
                }
            });
        }
    }

    addThresholds() {
        var thresholds = this.state.thresholds ? this.state.thresholds : [];
        thresholds.sort((a, b) => a.thresholdMin > b.thresholdMin);
        var { measureId, sexe, ageMin, ageMax } = this.state;
        var newThreshold = {
            measureId, sexe,
            ageMin: parseFloat(ageMin.toString().replace(/, /, '.')),
            ageMax: parseFloat(ageMax.toString().replace(/, /, '.')),
            thresholdMin: thresholds.length > 0 ? thresholds[thresholds.length - 1].thresholdMax : 0,
            thresholdMax: thresholds.length > 0 ? thresholds[thresholds.length - 1].thresholdMax + 1 : 100,
            color: ["#FF0000", "#FFA500", "#008000", "#808080"].find(u => !thresholds.find(th => th.color !== u)) ? ["#FF0000", "#FFA500", "#008000", "#808080"].find(u => !thresholds.find(th => th.color !== u)).id : ""
        }
        thresholds.push(newThreshold);
        this.setState({ thresholds });
    }

    deleteThreshold(row) {
        var thresholds = this.state.thresholds ? this.state.thresholds : [];
        if (thresholds && thresholds.find((thr) => thr === row)) {
            thresholds = thresholds.filter((thr) => thr !== row);
        }
        if (!thresholds || !thresholds.length) {
            var { measureId, sexe, ageMin, ageMax } = this.state;
            thresholds = [];
            var newThreshold = {
                measureId, sexe,
                ageMin: parseFloat(ageMin.replace(/, /, '.')),
                ageMax: parseFloat(ageMax.replace(/, /, '.')),
                thresholdMin: 0,
                thresholdMax: 100,
                color: "#FF0000"
            };
            thresholds.push(newThreshold);
        }
        this.setState({ thresholds });
    }

    handleSubmit() {
        this.setState({ loadingSubmit: true });
        var { thresholds, ageMax, ageMin, sexe, measureId } = this.state;


        loadAnthropometricThreshold().then(originalThresholds => {
            var original = originalThresholds.find(t => t.anthropometricMeasureId === measureId && t.sexe === sexe && t.ageMin.toString() === ageMin.toString() && t.ageMax.toString() === ageMax.toString()) ? originalThresholds.find(t => t.anthropometricMeasureId === measureId && t.sexe === sexe && t.ageMin.toString() === ageMin.toString() && t.ageMax.toString() === ageMax.toString()).thresholds : [];
            var promises = [];
            thresholds.forEach(thr => {
                if ((thr.thresholdMin || thr.thresholdMin === 0) && (thr.thresholdMax || thr.thresholdMax === 0) && thr.color && thr.measureId && thr.sexe && (thr.ageMin || thr.ageMin === 0) && thr.ageMax) {
                    if (thr.id) {
                        var o = original.find(t => t.id === thr.id);
                        if (o && thr && (o.color !== thr.color || o.thresholdMin.toString() !== thr.thresholdMin.toString() || o.thresholdMax.toString() !== thr.thresholdMax.toString())) {
                            promises.push(patch("evaluation", "threshold", thr));
                        }
                    } else {
                        promises.push(post("evaluation", "threshold", thr));
                    }
                }
            });

            if (original && original.length) {
                original.forEach(o => {
                    if (!thresholds.find(t => t.id === o.id)) {
                        promises.push(del("evaluation", "threshold", o.id))
                    }
                })
            }

            if (promises && promises.length) {
                Promise.all(promises)
                    .then((res) => {
                        if (res) {
                            var errors = [];
                            res.forEach((r) => {
                                if (!r.ok) {
                                    errors.push(r.status + " - " + r.url);
                                }
                            });
                            if (errors && errors.length) {
                                this.setState({ errors, loadingSubmit: false });
                            } else {
                                cogoToast.success(this.props.t("Submit Successed"), {
                                    position: "bottom-right",
                                });
                                this.setState({ loadingSubmit: false });

                                if (this.props.callback) {
                                    this.props.callback();
                                }
                            }
                        } else {
                            throw new Error("500");
                        }
                    })
                    .catch((err) => {
                        this.setState({ errors: [err.toString()], loadingSubmit: false });
                    });
            } else {
                this.setState({ loadingSubmit: false });
            }

        });
    }

    render() {
        const { t } = this.props;

        const columns = [
            {
                key: "min",
                label: t("Min") + " *",
                id: "min",
                maxWidth: 200,
                format: (row) => (
                    <FormsComponent.Input
                        className="form-control w-full rounded-md border-gray-200"
                        value={row.thresholdMin}
                        onChange={(value) => {
                            var thresholds = Object.assign([], this.state.thresholds);
                            var index = thresholds.findIndex(thr => thr === row);
                            var threshold = thresholds[index];
                            threshold.thresholdMin = value ? parseFloat(value.replace(/, /, '.')) : 0;
                            thresholds[index] = threshold;
                            this.setState({ thresholds });
                        }}
                    />
                ),
            },
            {
                key: "max",
                label: t("Max") + " *",
                id: "max",
                maxWidth: 200,
                format: (row) => (
                    <FormsComponent.Input
                        className="form-control w-full rounded-md border-gray-200"
                        value={row.thresholdMax}
                        onChange={(value) => {
                            var thresholds = Object.assign([], this.state.thresholds);
                            var index = thresholds.findIndex(thr => thr === row);
                            var threshold = thresholds[index];
                            threshold.thresholdMax = value ? parseFloat(value.replace(/, /, '.')) : 0;
                            thresholds[index] = threshold;
                            this.setState({ thresholds });
                        }}
                    />
                ),
            },
            {
                key: "color",
                label: t("Color") + " *",
                width: 250,
                format: (row) =>
                (
                    <FormsComponent.Select
                        value={row.color}
                        options={[
                            {
                                "id": "#FF0000",
                                "name": t("Red")
                            },
                            {
                                "id": "#FFA500",
                                "name": t("Orange")
                            },
                            {
                                "id": "#008000",
                                "name": t("Green")
                            },
                            {
                                "id": "#808080",
                                "name": t("Gray")
                            }
                        ].map((th) => {
                            return { key: th.id, label: th.name };
                        })}
                        onChange={(color) => {
                            var thresholds = Object.assign([], this.state.thresholds);
                            var index = thresholds.findIndex(thr => thr === row);
                            var threshold = thresholds[index];
                            threshold.color = color;
                            thresholds[index] = threshold;
                            this.setState({ thresholds });
                        }}
                    />
                )
            },
            {
                key: "delete",
                maxWidth: 50,
                format: (row) => (
                    <Button
                        className="btn btn-sm btn-clean btn-icon mr-2"
                        onClick={() => this.deleteThreshold(row)}
                    >
                        <span className="svg-icon svg-icon-md">
                            <SVG src={SvgTrash} />
                        </span>
                    </Button>
                ),
            },
        ];

        return (
            <Drawer.Layout
                className="w-screen"
                component={(show) => this.props.component(show, t(this.props.title))}
                hide={() => {
                    if (this.props.hide) {
                        this.props.hide();
                    }
                }}
            >
                {(hide) => <>
                    <Drawer.Header hide={hide}>{t("Thresholds")}</Drawer.Header>
                    <Drawer.Body>
                        {this.state.measures && this.state.measures.length && this.state.genders && this.state.genders.length ?
                            <>
                                <div className="mt-2 2xl:mt-3">
                                    <label className="block text-xs 2xl:text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 mt-3">
                                        {t("Anthropometric measure")} {"*"}
                                    </label>
                                    <div className="mt-1">
                                        <div>
                                            <FormsComponent.Select
                                                key={"measureId"}
                                                id={"measureId"}
                                                value={this.state.measureId}
                                                onChange={(value) => this.onChange("measureId", value)}
                                                options={this.state.measures.map(m => { return { key: m.id, label: t(m.name) } })}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-2 2xl:mt-3">
                                    <label className="block text-xs 2xl:text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 mt-3">
                                        {t("Sexe")} {"*"}
                                    </label>
                                    <div className="mt-1">
                                        <div>
                                            <FormsComponent.Select
                                                key={"sexe"}
                                                id={"sexe"}
                                                value={this.state.sexe}
                                                onChange={(value) => this.onChange("sexe", value)}
                                                options={this.state.genders.map(g => { return { key: g.id, label: t(g.name) } })}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-2 2xl:mt-3">
                                    <label className="block text-xs 2xl:text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 mt-3">
                                        {t("Age")} {"*"}
                                    </label>
                                    <div className="mt-1 grid grid-flow-col gap-4">
                                        <div>
                                            {t("Min")} :
                                        <FormsComponent.NumberPicker
                                                id="ageMin"
                                                type="text"
                                                value={this.state.ageMin}
                                                onChange={(value) => this.onChange("ageMin", value)}
                                            />
                                        </div>
                                        <div>
                                            {t("Max")} :
                                        <FormsComponent.NumberPicker
                                                id="ageMax"
                                                type="text"
                                                value={this.state.ageMax}
                                                onChange={(value) => this.onChange("ageMax", value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {this.state.thresholds && this.state.thresholds.length ?
                                    <>
                                        <div className="mt-10 mb-10">
                                            {t("Aperçu")} : <MultipleSliderReadOnly list={this.state.thresholds.map(t => { return { min: t.thresholdMin, max: t.thresholdMax, color: t.color } })} />
                                        </div>
                                        <div className="mt-10">
                                            <Table
                                                columns={columns}
                                                data={this.state.thresholds}
                                                height={500}
                                                handleAdd={this.addThresholds}
                                            />
                                        </div>
                                    </>
                                    : t("Please fill the field before creating some threshold.")
                                }
                            </>
                            : ""}

                    </Drawer.Body>
                    <Drawer.Footer>
                        <Button
                            loading={this.state.loadingSubmit}
                            onClick={(event) => {
                                this.handleSubmit(event);
                            }}
                        >
                            {t("Save")}
                        </Button>
                    </Drawer.Footer>
                </>}
            </Drawer.Layout>
        )
    }
}

export default withTranslation()(AnthropometricThreshold);