import React from "react"
class Card extends React.Component {
  render() {
    const title = this.props.title //title of the card (not mandatory)
    const subTitle = this.props.subTitle //subTitle of the card (not mandatory)
    // const className = this.props.className; //className = color of the card (not mandatory)
    const headerActions = this.props.headerActions //headerActions = buttons in the card toolbar (not mandatory)
    return (
      <>
        <div
          className="bg-white rounded-lg divide-y divide-gray-200"
          style={this.props.style}
          onClick={this.props.onClick}
        >
          {title || headerActions ? (
            <div className="px-2 2xl:px-4 py-3 2xl:py-5">
              <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
                <div className="ml-6 2xl:ml-8 mt-1 2xl:mt-2">
                  <h3 className="text-sm 2xl:text-base leading-6 font-medium text-gray-900">
                    {title}
                  </h3>
                </div>
                {headerActions ? (
                  <div className="text-sm 2xl:test-base ml-3 2xl:ml-6 mr-2 2xl:mr-8 mt-1 2xl:mt-2 flex-shrink-0">
                    {headerActions}
                  </div>
                ) : null}
              </div>
            </div>
        ) : (
            <></>
        )}
          {subTitle ? (
          <div className="text-muted font-weight-bold font-size-sm mb-6">
            {subTitle}
          </div>
        ) : (
            <></>
          )}
        {/* Body */}
        <div className="px-0.5 2xl:px-1 py-1 2xl:py-3">{this.props.children}</div>
      </div>
      </>
    )
  }
}

export default Card
