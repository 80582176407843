import React from "react";
import moment from "moment";
import { withTranslation } from "react-i18next";
import { Input, SelectMultiple, MonthPicker } from "../components/forms";
import TableSimple from "../components/table/TableSimple";
import Button from "../components/buttons/Button";
import FilterSeparator from "../components/filters/FilterSeparator";
import FiltersTitle from "../components/filters/FiltersTitle";
import FiltersBar from "../components/filters/FiltersBar";
class TableTournamentsINTProg extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actions: [],
      circuits: [],
      selectedCircuits: [],
      columns: [],
      searchText: "",
      year: parseInt(moment().format("YYYY")),
      month: parseInt(moment().format("M")),
    };
  }

  componentDidMount() {
    this.prepareData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.prepareData();
    }
  }

  prepareData = () => {
    var tournaments = this.props.tournaments;
    var circuits = [];
    var selectedCircuits = [];
    if (tournaments) {
      tournaments = tournaments.map((tournament) => {
        if (
          circuits &&
          !circuits.find((o) => o.key === tournament.circuit.id)
        ) {
          circuits.push({
            key: tournament.circuit.id,
            label: tournament.circuit.name,
          });
          selectedCircuits.push(tournament.circuit.id);
        }
        return tournament;
      });
    }

    const columns = [
      {
        key: "date",
        visible: true,
        label: this.props.t("Dates"),
        id: "date",
        width: 200,
        format: (row) => (
          <span className="font-weight-bold text-muted">{row.date}</span>
        ),
      },
      {
        key: "name",
        visible: true,
        label: this.props.t("Tournois"),
        id: "name",
        format: (row) => row.name,
        width: 300,
      },

      {
        key: "circuit.name",
        visible: true,
        label: this.props.t("Circuit"),
        id: "circuit.name",
        format: (row) =>
          row.circuit ? (
            <span
              className="px-3 py-0.5 rounded-full text-sm font-medium bg-red-100 text-red-800"
              style={{
                color: row.circuit.color2,
                backgroundColor: row.circuit.color1,
              }}
            >
              {row.circuit.name +
                (row.classification ? " " + row.classification : "")}
            </span>
          ) : (
              "N/A"
            ),
        filter: {
          name: "SelectColumnFilter",
          type: "includes",
        },
        width: 200,
      },
      {
        key: "city",
        visible: true,
        label: this.props.t("Location"),
        id: "city",
        format: (row) => (
          <span className="font-weight-bold text-muted">{row.city}</span>
        ),
        width: 250,
      },
      {
        key: "conditions.name",
        visible: true,
        label: this.props.t("Conditions"),
        id: "conditions.name",
        width: 200,
        filter: {
          name: "SelectColumnFilter",
          type: "includes",
        },
        format: (row) => (row.conditions ? row.conditions.name : "N/A"),
      },
      {
        key: "ground.name",
        visible: true,
        label: this.props.t("Ground"),
        id: "ground.name",
        width: 200,
        filter: {
          name: "SelectColumnFilter",
          type: "includes",
        },
        format: (row) => (row.ground ? row.ground.name : "N/A"),
      },
      {
        key: "action",
        visible: true,
        label: this.props.t("Add (status)"),
        format: (row) =>
          moment(row.startDate).isBefore(moment()) &&
          !(
            this.props.programmation &&
            this.props.programmation.find((p) => p.tournamentId === row.id)
          ) ? (
            <Button
              onClick={() =>
                this.props
                  .addEngagement(
                    this.props.id,
                    row.tId,
                    this.props.progView === "Real"
                      ? 0
                      : this.props.progView === "Option 1"
                      ? 1
                      : this.props.progView === "Option 2"
                      ? 2
                      : 0
                  )
                  .then((res) => {
                    this.props.updateProg(this.props.id, this.props.progView);
                  })
              }
            >
              <i className="flaticon2-plus"></i>
              {this.props.t("Add to programation")}
            </Button>
          ) : row.add ? (
            <Button
              onClick={() =>
                this.props
                  .addEngagement(
                    this.props.id,
                    row.tId,
                    this.props.progView === "Real"
                      ? 0
                      : this.props.progView === "Option 1"
                      ? 1
                      : this.props.progView === "Option 2"
                      ? 2
                      : 0
                  )
                  .then((res) => {
                    this.props.updateProg(this.props.id);
                  })
              }
            >
              <i className="flaticon2-plus"></i>
              {this.props.t("Add to programation")}
            </Button>
          ) : (
            "X"
          ),
      },
    ];
    var selectedColumns = [];
    columns.forEach((column) => {
      if (column.visible) {
        selectedColumns.push(column.key);
      }
    });
    this.setState({
      columns,
      tournaments,
      circuits,
      selectedColumns,
      selectedCircuits,
    });
  };

  render() {
    const { t } = this.props;
    var tournaments = this.props.tournaments;
    var columns = this.state.columns;
    var selectedColumns = this.state.selectedColumns;
    var selectedCircuits = this.state.selectedCircuits;
    var searchText = this.state.searchText;
    if (columns) {
      columns = columns.filter((o) =>
        selectedColumns.some((item) => item === o.key)
      );
    }
    if (tournaments) {
      tournaments = tournaments.filter((o) =>
        selectedCircuits.some((item) => item === o.circuit.id)
      );
    }
    if (searchText.length > 2) {
      tournaments = tournaments.filter((o) =>
        o.name.toLowerCase().includes(searchText.toLowerCase()) || o.city.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    return (
      <>
        <FiltersBar
          components={
            <>
              <FiltersTitle title={tournaments
                  ? tournaments.length + " " + t("Tournaments").toLowerCase()
                  : ""}
              />
              <FilterSeparator />
              <MonthPicker
                className="mx-2 rounded-md focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300"
                value={moment(this.state.month + "-" + this.state.year, "MM-YYYY")}
                onChange={(date) => {
                  var month = moment(date, "YYYY-MM").format("MM");
                  var year = moment(date, "YYYY-MM").format("YYYY");
                  this.setState({ month, year });
                  this.props.onChange(month, year);
                }}
              />
              <FilterSeparator />
              <SelectMultiple
                options={this.state.circuits}
                keys={this.state.selectedCircuits}
                className="mx-2"
                onChange={(res) => this.setState({ selectedCircuits: res })}
                fixedLabel={t("Circuit")}
              />
              <FilterSeparator />
              <Input
                value={this.state.searchText}
                onChange={(searchText) => this.setState({ searchText })}
                className="mx-2 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"
              />
            </>
          }
        />
        <TableSimple columns={columns} data={tournaments} />
      </>
    );
  }
}

export default withTranslation()(TableTournamentsINTProg);
