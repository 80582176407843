import { getUserManager } from "@axa-fr/react-oidc-context";

async function getAccessToken() {
  return getUserManager()
    .getUser()
    .then((data) => {
      if (data) {
        return data.access_token;
      }
    });
}

export default getAccessToken;
