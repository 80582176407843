import React from "react";
import moment from "moment";
import { withTranslation } from "react-i18next"
import Input from "../input/Input"
import {
    loadPlayers
} from "../../../utils/indexedDB/users/handleUser"
import {
    loadPhysicalTestsByPlayersAndDate,
    loadPhysicalTestsMeasuresActive
} from "../../../utils/indexedDB/assessment/handleAssessment"
import EditableTable from "../../table/EditableTable"
import IconRound32x32 from "../../icons/IconRound32x32";
import * as Drawer from "../../drawer/index";
import ButtonLight from "../../buttons/ButtonLight";
import { SelectMultiple, DatePicker } from "../index";
import FilterColumns from "../../filters/FilterColumns";
import FiltersDropDowns from "../../filters/FiltersDropDowns";
import { post, patch, del } from "../../../utils/requests/index";
import cogoToast from "cogo-toast";
class EditableTablePhysicalTests extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            measures: [],
            players: [],
            columns: [],
            selectedColumns: [],
            searchText: "",
            selectedPlayers: [],
            data: [],
            date: null,
            currentProcess: []
        }
    }

    componentWillMount() {
        Promise.all([loadPhysicalTestsMeasuresActive(), loadPlayers()]).then(([measures, players]) => {
            var date = moment().startOf('day');
            this.setState({ players: players, selectedPlayers: players, date, measures }, () => this.getColumns());
            this.getDataByDateByPlayers(date.format("YYYY-MM-DDTHH:mm:ss"), players.map(p => p.id));
        });
    }

    getDataByDateByPlayers(date, playersIds) {
        loadPhysicalTestsByPlayersAndDate(playersIds, date).then(data => this.setState({
            data: this.state.data && this.state.data.length ? this.state.data.concat(data) : data
        }));
    }

    waitFor(conditionFunction) {
        const poll = resolve => {
            if (conditionFunction()) resolve();
            else setTimeout(_ => poll(resolve), 400);
        }

        return new Promise(poll);
    }

    handleValueChange(value, measure, row) {
        var date = this.state.date.format("YYYY-MM-DDTHH:mm:ss");
        this.waitFor(() => !this.state.currentProcess.find(d => d.playerId === row.id && d.measureId === measure.id && moment(d.date).isSame(moment(date), 'day')))
            .then(() => {
                var currentProcess = this.state.currentProcess;
                currentProcess.push({
                    measureId: measure.id,
                    playerId: row.id,
                    date: date
                });
                this.setState({ currentProcess });
                var data = this.state.data;
                var valueBeforeChange = data.find(d => d.playerId === row.id && d.measureId === measure.id && moment(d.date).isSame(moment(date), 'day'));
                if (valueBeforeChange && valueBeforeChange.id) {
                    if (!value || value === "") {
                        del("evaluation", "physicalTestsData", valueBeforeChange.id).then(() => {
                            data = this.state.data;
                            data = data.filter(d => !(d.playerId === row.id && d.measureId === measure.id && moment(d.date).isSame(moment(date), 'day')));
                            var process = this.state.currentProcess;
                            process = process.filter(d => !(d.playerId === row.id && d.measureId === measure.id && moment(d.date).isSame(moment(date), 'day')));
                            this.setState({ data, currentProcess: process });
                        })
                    } else {
                        valueBeforeChange.value = typeof value === 'string' ? parseFloat(value.replace(',', '.')) : value;
                        patch("evaluation", "physicalTestsData", valueBeforeChange).then(res => {
                            if (res.ok) {
                                return res.json();
                            } else {
                                cogoToast.error(this.props.t("Failed to upload, please retry later"), { position: "bottom-right" });
                            }
                        }).then(valueFromPatch => {
                            data = this.state.data;
                            data = data.filter(d => !(d.playerId === row.id && d.measureId === measure.id && moment(d.date).isSame(moment(date), 'day')));
                            if (value && value.length && [",", "."].includes(value[value.length - 1])) {
                                valueFromPatch.value = valueFromPatch.value.toString() + ".";
                            }
                            data.push(valueFromPatch);
                            var process = this.state.currentProcess;
                            process = process.filter(d => !(d.playerId === row.id && d.measureId === measure.id && moment(d.date).isSame(moment(date), 'day')));
                            this.setState({ data, currentProcess: process });
                        })
                    }
                } else {
                    var valueFormatted = {
                        measureId: measure.id,
                        playerId: row.id,
                        date: date,
                        value: typeof value === 'string' ? parseFloat(value.replace(',', '.')) : value,
                        comments: ""
                    }
                    post("evaluation", "physicalTestsData", valueFormatted).then(res => {
                        if (res.ok) {
                            return res.json();
                        } else {
                            cogoToast.error(this.props.t("Failed to upload, please retry later"), { position: "bottom-right" });
                        }
                    }).then(valueFromPost => {
                        data = this.state.data;
                        if (value && value.length && [",", "."].includes(value[value.length - 1])) {
                            valueFromPost.value = valueFromPost.value.toString() + ".";
                        }
                        data.push(valueFromPost);
                        var process = this.state.currentProcess;
                        process = process.filter(d => !(d.playerId === row.id && d.measureId === measure.id && d.date === date));
                        this.setState({ data, currentProcess: process });
                    })
                }
            })
    }

    getColumns() {
        const { t } = this.props;
        var columns = [
            {
                key: "name",
                visible: true,
                pinned: "left",
                label: t("Player"),
                id: "displayName",
                width: 300,
                format: (row) => (
                    <div
                        className="text-indigo-700 font-semibold"
                    >
                        <IconRound32x32 photo={row.photo} />
                        <span className="ml-5">
                            {row.lastName.toUpperCase() + " " + row.firstName}
                        </span>
                    </div>
                )
            },
        ];
        if (this.state.measures) {
            this.state.measures.map((measure) =>
                columns.push({
                    key: measure.id,
                    visible: true,
                    id: measure.id,
                    label: measure.name,
                    format: (row) => {
                        var value = this.state.data && this.state.data.length && this.state.data.find(d => d.playerId === row.id && d.measureId === measure.id && moment(d.date).isSame(moment(this.state.date), 'day')) ? this.state.data.find(d => d.playerId === row.id && d.measureId === measure.id).value : "";
                        return <Input className="w-40 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md" value={value} onChange={(value) => this.handleValueChange(value, measure, row)} />
                    }
                    ,
                })
            );
        }

        var selectedColumns = this.state.selectedColumns && this.state.selectedColumns.length > 0 ? this.state.selectedColumns : columns.map(c => c.key);
        selectedColumns = selectedColumns.slice(0, 6);
        this.setState({ columns, selectedColumns });
    }

    render() {
        const { t } = this.props;
        var columns = this.state.columns;
        var selectedColumns = this.state.selectedColumns;
        if (columns) {
            columns = columns.filter((o) =>
                selectedColumns.some((item) => item === o.key)
            );
        }

        return (
            <Drawer.Layout
                className="w-screen"
                component={(show, text) => (
                    <ButtonLight
                        onClick={show}
                        disabled={!navigator.onLine}
                    >
                        <i className="flaticon2-plus"></i> {t("Edit")}
                    </ButtonLight>
                )}
            >
                {(hide) => (
                    <>
                        <Drawer.Header hide={() => {
                            if (this.props.callbackRefreshData) {
                                this.props.callbackRefreshData()
                            }
                            hide();
                        }}>{t("Edit physical tests")}</Drawer.Header>
                        <div className="py-2 flex justify-between border-b-2">
                            <div className="flex flex-row">
                                <DatePicker
                                    className="mx-2 rounded-md focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300"
                                    value={this.state.date}
                                    onChange={(date) => {
                                        if (date) {
                                            date = moment(date);
                                            this.setState({ date });
                                            this.getDataByDateByPlayers(date.format("YYYY-MM-DDTHH:mm:ss"), this.state.selectedPlayers.map(p => p.id));
                                        }

                                    }}
                                />
                                <span className="ml-5 mr-5 w-1 border-l"></span>
                                <FiltersDropDowns selects={[
                                    <SelectMultiple
                                        options={this.state.players.map(p => { return { key: p.id, label: p.displayName } })}
                                        keys={this.state.selectedPlayers.map(p => p.id)}
                                        className="mx-2"
                                        onChange={(selectedPlayers) => {
                                            if (selectedPlayers) {
                                                var date = moment(this.state.date);
                                                this.setState({ selectedPlayers: this.state.players.filter(p => selectedPlayers.includes(p.id)) });
                                                this.getDataByDateByPlayers(date.format("YYYY-MM-DDTHH:mm:ss"), selectedPlayers);
                                            }
                                        }}
                                        fixedLabel={t("Players")}
                                    />
                                ]}
                                />
                                <span className="ml-5 mr-5 w-1 border-l"></span>
                                <FilterColumns
                                    columns={
                                        <SelectMultiple
                                            options={this.state.columns}
                                            keys={selectedColumns}
                                            className="mx-2"
                                            onChange={(res) => this.setState({ selectedColumns: res })}
                                            fixedLabel={t("Active columns")}
                                        />
                                    }
                                />
                            </div>
                            <div>
                                {this.state.currentProcess && this.state.currentProcess.length ?
                                    <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-gray-100 text-gray-800">
                                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                        {t("Uploading")}
                                    </span>
                                    :
                                    <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800">
                                        <svg className="-ml-1 mr-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
                                        </svg>
                                        {t("Up to date")}
                                    </span>}
                            </div>
                        </div>
                        <div className="w-full overflow-x-auto">
                            <EditableTable
                                columns={columns}
                                data={this.state.selectedPlayers}
                                className="p-0"
                            />
                        </div>
                    </>
                )}
            </Drawer.Layout>

        )
    }
}

export default withTranslation()(EditableTablePhysicalTests)
