import Dexie from "dexie";
const dbs = [
  "Administration",
  "Users",
  "evaluation",
  "Core",
  "Events",
  "Management",
  "Medias",
  "Monitoring",
  "Tennis-Competition",
  "Training",
  "Scholarship"
];

function deleteAllDB() {
  dbs.forEach((db) => {
    Dexie.delete(db).catch((err) => {
      console.error("Could not delete database : ", err);
    });
  });
}

export default deleteAllDB;
