import Dexie from "dexie";

const db = new Dexie("Medias");
db.version(1).stores({
  collections: "++id",
  collectionsByPlayer: "++id, playerId",
  videos: "++id, collectionId",
  videosTypes: "++id",
  videosByPlayer: "++id, tennisPlayerId",
  galleries: "++id",
  galleriesByPlayer: "++id, playerId",
  galleriesCategories: "++id",
  photos: "++id, galleryId",
});
db.version(2).stores({
  videosTempByCollection: "++id, collectionId",
});
db.version(3).stores({
  videos: "++id, collectionId, eventId"
})
db.version(4).stores({
  folders: "++id"
})

export default db;
