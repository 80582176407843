import React from "react";
class Button extends React.Component {
  render() {
    var { loading, disabled, className, ...newProps } = this.props;
    return (
      <button
        disabled={loading || disabled}
        className={className ? className : (disabled ? "inline-flex items-center px-3 2xl:px-4 py-1 2xl:py-2 mx-2 2xl:mx-4 border border-transparent rounded-md text-sm 2xl:text-md scale-50 2xl:scale-100 font-medium text-white bg-gray-200 opacity-50" : "inline-flex items-center px-3 2xl:px-4 py-1 2xl:py-2 ml-2 2xl:mx-4 border border-transparent rounded-md text-sm 2xl:text-md scale-50 2xl:scale-100 font-medium text-white bg-indigo-600 hover:text-white hover:bg-indigo-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500")}
        {...newProps}
      >
        {loading ? <span>Loading</span> : this.props.children}
      </button>
    );
  }
}

export default Button;
