import React from "react";
import { withTranslation } from "react-i18next";
import moment from "moment";
import ChartStackedColumns from "../components/charts/ChartStackedColumns";
import WidgetKeyFigure from "../components/widgets/WidgetKeyFigure";
import ChartDoughnut2D from "../components/charts/ChartDoughnut2D";
import { Select } from "../components/forms";
import Carousel from "../components/carousels/Carousel";


class ChartCycleByPlayerDailySequencesByPhysicalThemes extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      view: "Hebdo",
      week: null,
    }
  }

  render() {
    const { t } = this.props;
    const sequences = this.props.sequences;
    const startDate = this.props.startDate;
    const endDate = this.props.endDate;
    var themes = this.props.themes;
    let dataSet = [];
    let dates = [];
    var dataDoughnut = [];
    var date1 = moment(startDate).format("dddd DD MMM");
    var date2 = moment(endDate).format("dddd DD MMM");
    let weeks = [];
    let weeksDataSet = [];
    const addWeek = (startOfWeek) => {
      let s = moment(startOfWeek)
      let e = moment(startOfWeek).endOf('week')
      let key = weeks.length;
      weeks.push({
        startDate: s.format(),
        endDate: e.format(),
        key,
        label: s.format("dddd DD MMM") + " - " + e.format("dddd DD MMM")
      })
    }
    var totalTime = 0;
    if (sequences != null && sequences.length > 0 && startDate != null && endDate != null && themes != null) {
      var start = moment(startDate);
      var end = moment(endDate);
      let days = end.diff(start, "days") + 1;
      if (start !== start.startOf('week') && (!this.props.isWeek || this.state.view === "Hebdo")) {
        addWeek(start.startOf('week'));
      }
      for (let i = 0; i < days; i++) {
        const dt1 = moment(moment(startDate).format("YYYY-MM-DD")).add(
          i,
          "days"
        );
        // const dt2 = moment(dt1.format("YYYY-MM-DD")).add(1, "days");
        dates.push({
          label: dt1.format("dddd DD"),
        });
        let startOfWeek = moment(moment(startDate).format("YYYY-MM-DD")).add(
          i,
          "days"
        ).startOf('week');
        if (dt1._d.getDate() === startOfWeek._d.getDate() && (!this.props.isWeek || this.state.view === "Hebdo")) {
          addWeek(startOfWeek);
        }
      }
      if (this.state.week === null && weeks.length > 0 && !this.props.isWeek) {
        this.setState({ week: weeks[0] });
      }

      themes.forEach((theme) => {
        let weeklyValues = [];
        var dailyValues = [];
        var nbTheme = 0;
        let weeklyData = 0;
        let week = 0;
        for (let i = 0; i < days; i++) {
          const dt1 = moment(moment(startDate).format("YYYY-MM-DD")).add(
            i,
            "days"
          );
          if (moment(weeks[week].endDate).isBefore(dt1)) {
            weeklyValues.push({
              value: weeklyData.toFixed(0),
            });
            weeklyData = 0;
            week += 1;
          }
          const dailyData = (sequences.find((o) => o.date === moment(dt1).format("YYYY-MM-DD")+"T00:00:00")).results.filter(
            (o) =>
              o.themeId === theme.id
          );
          if (dailyData.length > 0)
            nbTheme += dailyData.reduce((total, curr) => total + curr.amount, 0)
          var total = dailyData.reduce((total, curr) => total + curr.value, 0);
          weeklyData += total;

          dailyValues.push({
            value: total.toFixed(0),
          });
        }
        weeklyValues.push({
          value: weeklyData.toFixed(0),
        });
        let value = 0
        if (dailyValues)
          dailyValues.forEach(v => {
            value += Number(v.value)
          });
        dataSet.push({
          seriesname: t(theme.name),
          catName: theme.physicalCategory ? theme.physicalCategory.name : "",
          nbTheme,
          data: dailyValues,
        });
        weeksDataSet.push({
          seriesname: t(theme.name),
          data: weeklyValues
        })
        let min = (((value / 60) % 1) * 60).toFixed(0)
        dataDoughnut.push({
          label: t(theme.name),
          value,
          displayValue: t(theme.name) + " - " + Math.trunc(value / 60) + "H" + (min < 10 ? "0" + min : min)
        })
        totalTime += value;
      });
    }

    var widgets = []

    const widgetSequence = (minutes, title, nbSequence, catName) => {
      widgets.push(
        <div className="px-3 py-3 w-full">
          <WidgetKeyFigure
            svg='svg'
            figure={nbSequence}
            title={t(catName) + " - " + t(title)}
            heures={minutes}
            isSequence={true}
          />
        </div>
      )
    }

    dataSet.map(dataSetObject => {
      let totalMinutes = 0
      dataSetObject.data.forEach(dataObject => {
        totalMinutes += parseInt(dataObject.value)
      })
      let hours = moment.duration(totalMinutes * 60 * 1000).asHours()
      return widgetSequence(hours, dataSetObject.seriesname, dataSetObject.nbTheme, dataSetObject.catName);
    })

    return (
      <>
        {!this.props.isWeek ?
          <>
            <Select
              options={[
                {
                  key: "Hebdo",
                  label: t("Hebdo"),
                },
                {
                  key: "Daily",
                  label: t("Daily"),
                },
                {
                  key: "Weeks",
                  label: t("Weeks"),
                }
              ]}
              value={this.state.view}
              onChange={(view) => this.setState({ view })}
            />
            {this.state.view === "Hebdo" ? // chart une barre par semaine
              <>
                <Carousel items={widgets} />
                <ChartStackedColumns dataSet={weeksDataSet} categories={weeks}
                  title={t("Training categories weekly distribution")}
                  subTitle={t("Time spent in each physical themes based on sessions drills")}
                />
                <ChartDoughnut2D title={t("Distribution")}
                  dataSet={dataDoughnut}
                  subcaption={t("From x to y", { date1, date2 })}
                  totalTime={totalTime}
                  width="100%"
                />
              </>
              : <></>
            }
            {this.state.view === "Daily" ?
              <>
                <Carousel items={widgets} />
                <ChartStackedColumns
                  title={t("Training categories daily distribution")}
                  subTitle={t("Time spent in each physical themes based on sessions drills")}
                  dataSet={dataSet}
                  categories={dates}
                />
                <ChartDoughnut2D
                  title={t("Distribution")}
                  dataSet={dataDoughnut}
                  subcaption={t("From x to y", { date1, date2 })}
                  totalTime={totalTime}
                  width="100%"
                />
              </>
              : <></>
            }
            {this.state.view === "Weeks" ?
              <>
                <Select
                  options={weeks}
                  value={this.state.week.label}
                  onChange={(week) => this.setState({ week: weeks.find(w => w.key === week) })}
                />
                <ChartCycleByPlayerDailySequencesByPhysicalThemes
                  sequences={this.props.sequences}
                  startDate={this.state.week.startDate}
                  endDate={this.state.week.endDate}
                  themes={this.props.themes}
                  height="400"
                  isWeek={true}
                  t={t}
                />
              </>
              : <></>
            }
          </>
          : <>
            <Carousel items={widgets} />
            <ChartStackedColumns
              title={t("Training categories daily distribution")}
              subTitle={t("Time spent in each physical themes based on sessions drills")}
              dataSet={dataSet}
              categories={dates}
            />
            <ChartDoughnut2D
              title={t("Distribution")}
              dataSet={dataDoughnut}
              subcaption={t("From x to y", { date1, date2 })}
              totalTime={totalTime}
              width="100%"
            />
          </>
        }
      </>
    );
  }
}

export default withTranslation()(
  ChartCycleByPlayerDailySequencesByPhysicalThemes
);
