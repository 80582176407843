import React from "react";

// Modal
// component => openModal
// children = content of the modal

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  show(event) {
    this.setState({ modal: true });
  }

  hide(event) {
    this.setState({ modal: false });
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ modal: false });
    }
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  render() {
    return (
      <div id={this.props.id}>
        {this.props.component(this.show)}
        {this.state.modal ? (
          <div className="modal">
            <div className="modal-background" />
            <div className="modal-content" ref={this.setWrapperRef}>
              {this.props.title ? (
                <div className="modal-header">
                  <div className="title offcanvas-header d-flex align-items-center justify-content-between pb-7">
                    <h4 className="font-weight-bolder">{this.props.title}</h4>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <i aria-hidden="true" className="ki ki-close"></i>
                    </button>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="modal-body">
                {typeof this.props.children === "function"
                  ? this.props.children(this.hide)
                  : this.props.children}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default Modal;
