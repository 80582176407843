import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/sass/vendors/theme/logos/IconExaduoSport.png";

class AsideBrand extends React.Component {
  render() {
    return (
      <>
        <div className="h-16 flex items-center justify-center tourStep2">
          <Link to="/">
            <img alt="logo" src={logo} className="w-8" />
          </Link>
        </div>
      </>
    );
  }
}

export default AsideBrand;
