import React from "react";
import { withTranslation } from "react-i18next";
import moment from "moment";
import ChartStackedColumns from "../components/charts/ChartStackedColumns";
import Card from "../components/card/Card";
import WidgetKeyFigure from "../components/widgets/WidgetKeyFigure";
import SvgTrainingTennis from "../assets/sass/vendors/theme/svg/icons/General/Thunder-move.svg"
// import SvgTrainingWorkout from "../assets/sass/vendors/theme/svg/icons/Home/Weight1.svg"
// import SvgTrainingConditioning from "../assets/sass/vendors/theme/svg/icons/Clothes/Sneakers.svg"
import SvgTrainingPrevention from "../assets/sass/vendors/theme/svg/icons/General/Shield-check.svg"

class ChartCycleByPlayerMatchs extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            view: "Hebdo",
            week: null,
        }
    }

    render() {
        const { t, matches } = this.props;
        const startDate = this.props.startDate;
        const endDate = this.props.endDate;
        var categories = this.props.categories;
        let weeks = [];
        let weeksDataSet = [];
        const addWeek = (startOfWeek, end = null) => {
            let s = moment(startOfWeek)
            let e = (end != null ? moment(end) : moment(startOfWeek).endOf('week'))
            let key = weeks.length;
            weeks.push({
                startDate: s.format(),
                endDate: e.format(),
                key,
                label: s.format("dddd DD MMM") + " - " + e.format("dddd DD MMM")
            })
        }
        var nbMatches = 0
        var nbTournaments = 0
        var tempsMatchs = 0;
        var victory = 0;
        var defeats = 0;

        if (matches != null && matches.length > 0 && startDate != null && endDate != null && categories != null) {
            var start = moment(startDate);
            var end = moment(endDate);
            let days = end.diff(start, "days") + 1;
            if (start !== start.startOf('week') && (!this.props.isWeek || this.state.view === "Hebdo")) {
                addWeek(start);
            }
            for (let i = 0; i < days; i++) {
                const dt1 = moment(moment(startDate).format("YYYY-MM-DD")).add(
                    i,
                    "days"
                );
                let startOfWeek = moment(moment(startDate).format("YYYY-MM-DD")).add(
                    i,
                    "days"
                ).startOf('week');
                if (dt1._d.getDate() === startOfWeek._d.getDate() && (!this.props.isWeek || this.state.view === "Hebdo")) {
                    if (days - i < 7)
                        addWeek(startOfWeek, endDate);
                    else
                        addWeek(startOfWeek);
                }
            }

            nbTournaments = categories.length
            var week = weeks.length
            categories.map((tournament) => {
                let weeklyValues = [];
                for (var i = 0; i < week; i++) {
                    let weekEvents = []
                    let w = i
                    let temp = tempsMatchs
                    let v = victory
                    let d = defeats
                    weekEvents = matches.filter((m) => moment(m.startDate).isAfter(weeks[w].startDate)
                        && moment(m.endDate).isBefore(weeks[w].endDate)
                        && m.specificInfos && m.specificInfos.tournamentId === tournament.id)
                    if (weekEvents.length > 0) {
                        nbMatches += weekEvents.length
                        weekEvents.map((match) => {
                            let te = parseInt(match.specificInfos.duration ? match.specificInfos.duration :
                                ((match.specificInfos.durationSet1 ? match.specificInfos.durationSet1 : 0) +
                                    (match.specificInfos.durationSet2 ? match.specificInfos.durationSet2 : 0) +
                                    (match.specificInfos.durationSet3 ? match.specificInfos.durationSet3 : 0) +
                                    (match.specificInfos.durationSet4 ? match.specificInfos.durationSet4 : 0) +
                                    (match.specificInfos.durationSet5 ? match.specificInfos.durationSet5 : 0)))
                            if (t === 0) te = moment.duration(match.endTime.diff(match.startTime)).asMinutes();
                            temp += te
                            v += match.specificInfos.result === "V" || match.specificInfos.result === "Vab" ? 1 : 0
                            d += match.specificInfos.result === "D" || match.specificInfos.result === "Dab" ? 1 : 0
                            return true
                        })
                        tempsMatchs = temp
                        victory = v
                        defeats = d
                        weeklyValues.push({
                            value: weekEvents.length,
                        });
                    }
                    else
                        weeklyValues.push({ value: 0 });
                }
                weeksDataSet.push({
                    seriesname: tournament.name,
                    data: weeklyValues
                })
                return true
            })
            let weeklyValues = [];
            for (var i = 0; i < week; i++) {
                let weekEvents = []
                let w = i
                let temp = tempsMatchs
                let v = victory
                let d = defeats
                weekEvents = matches.filter((m) => moment(m.startDate).isAfter(weeks[w].startDate)
                    && moment(m.endDate).isBefore(weeks[w].endDate)
                    && m.specificInfos && m.specificInfos.tournamentId === null)
                if (weekEvents.length > 0) {
                    nbMatches += weekEvents.length
                    weekEvents.map((match) => {
                        let te = parseInt(match.specificInfos.duration ? match.specificInfos.duration :
                            ((match.specificInfos.durationSet1 ? match.specificInfos.durationSet1 : 0) +
                                (match.specificInfos.durationSet2 ? match.specificInfos.durationSet2 : 0) +
                                (match.specificInfos.durationSet3 ? match.specificInfos.durationSet3 : 0) +
                                (match.specificInfos.durationSet4 ? match.specificInfos.durationSet4 : 0) +
                                (match.specificInfos.durationSet5 ? match.specificInfos.durationSet5 : 0)))
                        if (t === 0) te = moment.duration(match.endTime.diff(match.startTime)).asMinutes();
                        temp += te
                        v += match.specificInfos.result === "V" || match.specificInfos.result === "Vab" ? 1 : 0
                        d += match.specificInfos.result === "D" || match.specificInfos.result === "Dab" ? 1 : 0
                        return true
                    })
                    tempsMatchs = temp
                    victory = v
                    defeats = d
                    weeklyValues.push({
                        value: weekEvents.length,
                    });
                }
            }
            if (weeklyValues.length > 0)
                weeksDataSet.push({
                    seriesname: t("Not linked to a tournament"),
                    data: weeklyValues
                })
            tempsMatchs /= 60
        }


        return (
            <>
                <div className="w-full px-10 py-2">
                    <Card>
                        <div className="grid grid-cols-4">
                            <div className="px-3 py-3 w-full">
                                <WidgetKeyFigure
                                    svg={SvgTrainingTennis}
                                    figure={nbTournaments}
                                    title={t("Nombre tournois")}
                                    blank={true}
                                />
                            </div>
                            <div className="px-3 py-3 w-full">
                                <WidgetKeyFigure
                                    svg={SvgTrainingPrevention}
                                    figure={nbMatches}
                                    heures={tempsMatchs}
                                    title={t("Nombre matchs")}
                                />
                            </div>
                            <div className="px-3 py-3 w-full">
                                <WidgetKeyFigure
                                    svg={SvgTrainingPrevention}
                                    figure={victory}
                                    title={t("Nombre victoires")}
                                    blank={true}
                                />
                            </div>
                            <div className="px-3 py-3 w-full">
                                <WidgetKeyFigure
                                    svg={SvgTrainingPrevention}
                                    figure={defeats}
                                    title={t("Nombre défaites")}
                                    blank={true}
                                />
                            </div>
                        </div>
                    </Card>
                </div>
                <ChartStackedColumns dataSet={weeksDataSet} categories={weeks}
                    title={t("Bilan de compétition vue hebdomadaire")}
                    notMin={"match"}
                />
            </>

        );
    }
}

export default withTranslation()(
    ChartCycleByPlayerMatchs
);
