import React from "react";
import { withTranslation } from "react-i18next";
import moment from "moment";
import ChartStackedColumns from "../components/charts/ChartStackedColumns";
import {DatePicker} from "../components/forms";

class ChartCyclesByPlayerWeeklySequencesByTrainingCategories extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
    };
  }
  
  render() {
    const { t } = this.props;
    var data = this.props.data.filter(d => {
      if (this.state.startDate && this.state.startDate > d.week.startDate) return false  
      if (this.state.endDate && this.state.endDate < d.week.endDate) return false  
      return true
    });
    let dataSet = [];
    let weeksList = [];

    if (data) {
      var types = [];
      data = data.filter(o => o && o.week && o.week.startDate ? moment(o.week.startDate).isBefore(moment()) : false);
      data.forEach((Dweek) => {
        if (Dweek && Dweek.week) {
          weeksList.push({
            label: Dweek.week.name,
          });

          if(Dweek.results && Dweek.results.length) {
            Dweek.results.forEach(r =>{
              if(r.typeId && !types.includes(r.typeId)){
                types.push(r.typeId)
              }
            })
          }
        }
      });

      types.forEach((type) => {
        var weeklyValues = [];
        data.forEach((Dweek) => {
          var total = Dweek ? (Dweek.results.find(r => r.typeId === type) ? Dweek.results.find(r => r.typeId === type).value : 0) : 0;
          weeklyValues.push({
            value: total.toFixed(0),
          });
        });
        dataSet.push({
          seriesname: t(type),
          data: weeklyValues,
        });
      });
    }

    dataSet = dataSet.filter(dS => dS.data.find(x => x.value !== "0"));

    return (
      <>
        <div className="px-8 py-2 flex justify-between border-b-2">
        <div className="flex flex-row">
            <div className="text-md p-2 pr-4">{t("From")}</div>
          </div>
          <DatePicker
            value={this.state.startDate}
            onChange={(startDate) => {
              this.setState({ startDate })
            }}
          />
          <span className="ml-5 mr-5 w-1 border-l"></span>
          <div className="flex flex-row">
            <div className="text-md p-2 pr-4">{t("To")}</div>
          </div>
          <DatePicker
            value={this.state.endDate}
            onChange={(endDate) => {
              this.setState({ endDate })
            }}
          />
        </div>
        <ChartStackedColumns
          title={t("Training categories weekly distribution")}
          subTitle={t(
            "Time spent in each training category based on sessions drills"
          )}
          dataSet={dataSet}
          categories={weeksList}
        />
      </>
    );
  }
}

export default withTranslation()(
  ChartCyclesByPlayerWeeklySequencesByTrainingCategories
);
