import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import TableStatistiquesIndividuelles from "../../tables/TableStatistiquesIndividuelles";

class StatistiqueIndividuelGeneric extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { t } = this.props;

    return (
      <>
        {/* <TeamLeadersCarousel
          t={t}
          infoByPlayer={this.props.infoByPlayer}
          sportTypeUrl={this.props.sportTypeUrl}
          selectedTabKey={this.props.selectedTabKey}
        /> */}
        <TableStatistiquesIndividuelles
          t={t}
          infoByPlayer={this.props.infoByPlayer}
          sportTypeUrl={this.props.sportTypeUrl}
          selectedTabKey={this.props.selectedTabKey}
        />
      </>
    );
  }
}

export default withTranslation()(withRouter(StatistiqueIndividuelGeneric));
