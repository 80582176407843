import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { loadEventsByPlayerAndType } from "../../utils/indexedDB/events/handleEvents";
import {
  getMatchsInfosByPlayerId,
  loadMatchsInfos,
} from "../../utils/indexedDB/Rugby-Competition/handleCompetitionRugby";
import TableMatchsByPlayer from "../../tables/TableMatchsByPlayer";

class MatchsByPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      infoByPlayer: [],
      matchs: [],
      getMatchsByPlayerFromAPi: false,
    };
  }

  componentDidMount() {
    var playerId = this.props.playerId;
    this.prepareData();
    this.handleMatchsByPlayer(playerId);
    this.setState(
      {
        infoByPlayer: this.props.infoByPlayer,
        matchInfos: this.props.matchInfos,
      },
      () => {
        this.prepareData(true);
      }
    );
  }

  handleMatchsByPlayer = (playerId) => {
    this.setState({ getMatchsByPlayerFromAPi: false });
    getMatchsInfosByPlayerId(playerId).then((matchsInfos) => {
      matchsInfos = matchsInfos.sort((a, b) =>
        a.startDate === b.startDate ? 0 : a.startDate > b.startDate ? -1 : 1
      );
      if (matchsInfos) this.setState({ matchs: matchsInfos });
    });
    if (navigator.onLine) {
      loadEventsByPlayerAndType(
        playerId,
        "Match",
        "2017-01-27T00:00:00+02:00",
        "2021-10-04T00:00:00+02:00"
      ).then((matchsApi) => {
        loadMatchsInfos(
          matchsApi.map((match) => match.id),
          playerId
        ).then((matchs) => {
          this.setState({ matchs });
        });
      });
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.prepareData();
    }
  }

  prepareData = () => {
    var matchs = this.props.matchs;

    this.setState({
      matchs,
    });
  };

  render() {
    return <TableMatchsByPlayer matchs={this.state.matchs} />;
  }
}

export default withTranslation()(withRouter(MatchsByPlayer));
