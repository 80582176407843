import React from "react";
import { withRouter } from "react-router";
import { withTranslation } from 'react-i18next';

class WorkInProgress extends React.Component {
    render() {
        return (<div>WorkInProgress</div>)
    }
}

export default withTranslation()(withRouter(WorkInProgress));