import React from "react";
import Card from "../../../components/card/Card";
import DisplayData3cols from "../../../components/displayData/DisplayData3cols";
import List from "../../../components/lists/List";
import GeneratedForm from "../../../utils/forms/generateForm";

const PlayerStatistics = ({
  t,
  playerInfo,
  matchInfos,
  positionInfo,
  matchInfosClub,
  matchInfosOpponent,
  infoByPlayer,
}) => {
  var individualStatistics = infoByPlayer && infoByPlayer.individualStatistics;

  var itemPlayer =
    individualStatistics &&
    individualStatistics.map((col) => {
      return {
        key: col.individualStatistic.name,
        value: col.value,
      };
    });

  return (
    <>
      <div className="flex space-x-5 pt-5 items-center">
        <div className="py-1 px-20 w-96">
          <GeneratedForm
            callback={() => {}}
            formName={"updatePhotoPlayer"}
            // formData={this.state.player}
            component={(show, label) => (
              <div className="w-36 h-36 py-2" onClick={() => show()}>
                <div className="relative mx-auto my-auto cursor-pointer">
                  <img
                    className="block opacity-100 h-36 w-36 rounded-full"
                    src={playerInfo.photo}
                    alt=""
                  />
                  <span className="absolute top-0 h-36 w-36 opacity-0 hover:opacity-75 rounded-full hover:bg-gray-700 overflow-hidden text-center">
                    <span className="block pt-16 text-white">{t("Edit")}</span>
                  </span>
                </div>
              </div>
            )}
          />
        </div>

        <div className="m-5 py-3 px-10 w-full">
          <Card>
            <DisplayData3cols
              data={[
                {
                  key: t("Last name"),
                  value: playerInfo.lastName,
                  // value: this.state.player.lastName
                  //   ? this.state.player.lastName.toUpperCase()
                  //   : "",
                },
                {
                  key: t("First name"),
                  value: playerInfo.firstName,
                  // value: this.state.player.firstName,
                },
                {
                  key: t("Club"),
                  value: matchInfos && matchInfos.club && matchInfos.club.name,
                },
              ]}
            />
          </Card>
        </div>
      </div>

      <div className="m-5 py-3 px-10 w-full">
        <Card title={t("Player")} className="card-stretch">
          <List
            data={[
              {
                key: t("Name"),
                value: playerInfo.lastName,
              },
              {
                key: t("firstName"),
                value: playerInfo.firstName,
              },
              {
                key: t("Number"),
                value: positionInfo.number,
              },
              {
                key: t("Poste"),
                value: positionInfo.name,
              },
            ]}
          />
        </Card>
      </div>

      <div className="m-5 py-3 px-10 w-full">
        <Card title={t("Match")} className="card-stretch">
          <List
            data={[
              {
                key: t("Journée"),
                value: matchInfos.name,
              },
              {
                key: t("Club"),
                value: matchInfosClub.name,
              },
              {
                key: t("Opponent"),
                value: matchInfosOpponent.name,
              },
              {
                key: t("Place"),
                value: matchInfos.place,
              },
            ]}
          />
        </Card>
      </div>

      {itemPlayer && itemPlayer.length > 0 && (
        <div className="m-5 py-3 px-10 w-full">
          <Card title={t("Match Statistics")} className="card-stretch">
            <List data={itemPlayer}></List>
          </Card>
        </div>
      )}
    </>
  );
};

export default PlayerStatistics;
