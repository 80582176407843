import React from "react";
import ActionButton from "../components/buttons/ActionButton";
import moment from "moment";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { SelectMultiple, MonthPicker } from "../components/forms";
import TableSimple from "../components/table/TableSimple";
import FilterColumns from "../components/filters/FilterColumns";
import FiltersDropDowns from "../components/filters/FiltersDropDowns";
import FiltersSearch from "../components/filters/FiltersSearch";
import SearchInput from "../components/forms/input/SearchInput";
import ExportToCSV from "../components/export/ExportToCSV";
import generateColor from "../utils/generateColor";
import FilterSeparator from "../components/filters/FilterSeparator";
import FiltersTitle from "../components/filters/FiltersTitle";
import FiltersBar from "../components/filters/FiltersBar";
//import { removeExact } from "@fullcalendar/react";
class TableTournamentsNAT extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actions: [],
      classifications: [],
      selectedClassifications: [],
      regions: [],
      selectedRegions: [],
      columns: [],
      searchText: "",
    };
  }

  componentDidMount() {
    this.prepareData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.prepareData();
    }
  }

  prepareData = () => {
    var tournaments = this.props.tournaments;
    var classifications = [];
    var selectedClassifications = [];
    var regions = [];
    var selectedRegions = [];
    // var conditions = [];
    // var selectedConditions = [];

    //  chercher les années a déselectionner du localstorage
    var unselectedClassifications = localStorage.getItem("TableTournamentsNAT_unselectedClassifications")
    if (unselectedClassifications) unselectedClassifications = unselectedClassifications.split("|")
    
    // grounds
    var unselectedRegions = localStorage.getItem("TableTournamentsNAT_unselectedRegions")
    if (unselectedRegions) unselectedRegions = unselectedRegions.split("|")    

    // // conditions
    // var unselectedConditions = localStorage.getItem("TableTournamentsNAT_unselectedConditions")
    // if (unselectedConditions) unselectedConditions = unselectedConditions.split("|") 

    
    if (tournaments) {
       tournaments = tournaments.map((tournament) => {
        if (
          classifications &&
          !classifications.find((o) => o.key === tournament.classification.id)
        ) {
          classifications.push({
            key: tournament.classification.id,
            label: tournament.classification.name,
          })
          // only add to selected if not unselected
        if (
          !unselectedClassifications ||
          unselectedClassifications.indexOf(tournament.classification.id.toString()) < 0
        )  
          selectedClassifications.push(tournament.classification.id);
        }
        if (
          tournament.club &&
          regions &&
          !regions.find((o) => o.key === tournament.club.region.id)
        ) {
          regions.push({
            key: tournament.club.region.id,
            label: tournament.club.region.name,
          });
          if (tournament.club)
          {
          selectedRegions.push(tournament.club.region.id)
          
          // only add to selected if not unselected
        if (
          !unselectedRegions ||
          unselectedRegions.indexOf(tournament.club.region.id.toString()) < 0
        );
          }  
        }
    //     if (
    //       conditions &&
    //       !conditions.find((o) => o.key === tournament.conditionsId)
    //     ) {
    //       conditions.push({
    //         key: tournament.conditionsId,
    //         label: tournament.conditionsId,
    //       })
    //       selectedConditions.push(tournament.conditionsId)
    //         // only add to selected if not unselected
    //     if (
    //       !unselectedConditions ||
    //       unselectedConditions.indexOf(tournament.conditionsId.toString()) < 0
    //     );
    //     }
         return tournament;
       });
    }

    const columns = [
      {
        key: "startDate",
        visible: true,
        label: this.props.t("Start"),
        format: (row) => <span className="text-xs 2xl:text-sm">{moment(row.startDate).format("D MMM YY")}</span>,
        filter: {
          name: "DateBetweenFilter",
        },
        width: 90,
      },
      {
        key: "endDate",
        visible: true,
        label: this.props.t("End"),
        format: (row) => <span className="text-xs 2xl:text-sm">{moment(row.endDate).format("D MMM YY")}</span>,
        filter: {
          name: "DateBetweenFilter",
        },
        width: 90,
      },
      {
        key: "name",
        visible: true,
        label: this.props.t("Name"),
        id: "name",
        format: (row) => (
          <span className="flex justify-between mb-0">
            <Link
              className="text-indigo-700 font-semibold text-xs 2xl:text-sm mr-6"
              to={"/competitionTennis/nationaldetails/" + row.tId + "/a/b"}
            >
              {row.name}
            </Link>
            <ActionButton
              key={row.id}
              updateForm={
                row.tournamentTypeId === "National"
                  ? "updateTennisTournamentNational"
                  : "updateTennisTournamentInternational"
              }
              addForm="addTennisEngagement"
              deleteForm={
                row.tournamentTypeId === "National"
                  ? "deleteTennisTournamentNational"
                  : "deleteTennisTournamentInternational"
              }
              formData={
                row.tournamentTypeId === "National"
                  ? {
                    id: row.tId,
                    startDate: row.startDate,
                    endDate: row.endDate,
                    name: row.name,
                    category: row.category,
                    classificationId: row.classificationId,
                    grounds: row.grounds,
                    conditionsId: row.conditionsId,
                    typeId: row.typeId,
                    city: row.city,
                    regionId: row.regionId,
                    clubId: row.clubId,
                    contests: row.contests,
                    level: row.level,
                    mode: row.mode,
                    serie: row.serie
                  }
                  : row
              }
              formDataBis={{ tournamentId: row.tId, tournamentIdname: row.name }}
              refreshData={this.props.refresh}
            />
          </span>
        ),
        width: 350,
      },
      {
        key: "classification.name",
        visible: true,
        label: this.props.t("Classification"),
        id: "circuit.name",
        format: (row) =>
          row.circuit ? (
            <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs 2xl:text-sm font-medium bg-yellow-100 text-yellow-800"  style={{color: "#FFFFFF", backgroundColor: generateColor(row.classification ? " " + row.classification.name : "")}}>
              {
                row.classification ? " " + row.classification.name : ""}
            </span>
          ) : (
              "N/A"
            ),
        filter: {
          name: "SelectColumnFilter",
          type: "includes",
        },
      },
      {
        key: "contests",
        visible: false,
        label: this.props.t("Contests"),
        filter: {
          name: "SelectColumnFilter",
          type: "includes",
        },
        width: 200,
        format: (row) => <span className="text-xs 2xl:text-sm">{row.contests}</span>
      },
      {
        key: "localisation",
        visible: true,
        label: this.props.t("Localisation"),
        id: "localisation",
        format: (row) => (
          <span className="font-weight-bold text-muted text-xs 2xl:text-sm">
            {row.localisation}
          </span>
        ),
        filter: {
          name: "SelectColumnFilter",
          type: "includes",
        },
        width: 250,
      },
      {
        key: "category",
        visible: true,
        label: this.props.t("Category"),
        id: "category",
        filter: {
          name: "SelectColumnFilter",
          type: "includes",
        },
        width: 200,
        format: (row) => 
        <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs 2xl:text-sm font-medium bg-yellow-100 text-yellow-800"  style={{color: "#FFFFFF", backgroundColor: generateColor(row.category ? " " + row.category : "")}}>
          {row.category}
        </span>
      },
      {
        key: "level",
        visible: true,
        label: this.props.t("Level"),
        filter: {
          name: "SelectColumnFilter",
          type: "includes",
        },
        width: 200,
        format: (row) => 
        <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs 2xl:text-sm font-medium bg-yellow-100 text-yellow-800"  style={{color: "#FFFFFF", backgroundColor: generateColor(row.level ? " " + row.level : "")}}>
          {row.level}
        </span>
      },
      {
        key: "type.name",
        visible: true,
        label: this.props.t("Type"),
        filter: {
          name: "SelectColumnFilter",
          type: "includes",
        },
        width: 200,
        format: (row) => (row.type ? <span className="text-xs 2xl:text-sm">{row.type.name}</span> : <span className="text-xs 2xl:text-sm">{"N/A"}</span>),
      },
      
      {
        key: "mode",
        visible: false,
        label: this.props.t("Mode"),
        filter: {
          name: "SelectColumnFilter",
          type: "includes",
        },
        width: 200,
        format: (row) => <span className="text-xs 2xl:text-sm">{row.mode}</span>
      },
      {
        key: "grounds",
        visible: false,
        label: this.props.t("Conditions"),
        filter: {
          name: "SelectColumnFilter",
          type: "includes",
        },
        width: 200,
        format: (row) => <span className="text-xs 2xl:text-sm">{row.grounds}</span>
      },
      {
        key: "clubId",
        visible: false,
        pinned: "left",
        label: this.props.t("Club"),
        id: "clubId",
        format: (row) =>
          row.club
            ? <span className="text-xs 2xl:text-sm">{row.club.name}</span>
            : "-",
        width: 250,
        filter: {
          name: "SelectColumnFilter",
          type: "includes",
        },
      },
      {
        key: "regionId",
        visible: true,
        pinned: "left",
        label: this.props.t("Ligue"),
        id: "regionId",
        format: (row) =>
          row.club && row.club.region
            ? <span className="text-xs 2xl:text-sm">{row.club.region.name}</span>
            : "-",
        width: 250,
        filter: {
          name: "SelectColumnFilter",
          type: "includes",
        },
      }
    ];

    var selectedColumns = localStorage.getItem("TableTournamentsNAT_selectedColumns")
    if (selectedColumns === null || selectedColumns.length === 0)  selectedColumns = ["startDate", "endDate", "name", "localisation", "classification.name", "contests", "regionId", "participants" ]
    else selectedColumns = selectedColumns.split("|")
    columns.forEach((column) => {
      column.visible = selectedColumns.indexOf(column.key) < 0 ? false : true 

    });
    this.setState({
      columns,
      tournaments,
      classifications,
      regions,
      // conditions,
      selectedColumns,
      selectedClassifications,
      selectedRegions,
      // selectedConditions
    });
  };

   // change regions
   onChangeSelectedRegions = (res) => {
    this.setState({ selectedRegions: res }, () => {
      // determiner ce qui n'est pas selectionner
      let unselect = []
      this.state.regions.forEach((a) => {
        if (this.state.selectedRegions.indexOf(a.key) < 0) unselect.push(a.key)
      })
      // save unselected elements to localstorage
      localStorage.setItem("TableTournamentsNAT_unselectedRegions", unselect.join("|"))
    })
  }
  
  // change classifications
  onChangeSelectedClassifications = (res) => {
    this.setState({ selectedClassifications: res }, () => {
      // determiner ce qui n'est pas selectionner
      let unselect = []
      this.state.classifications.forEach((a) => {
        if (this.state.selectedClassifications.indexOf(a.key) < 0) unselect.push(a.key)
      })
      // save unselected elements to localstorage
      localStorage.setItem("TableTournamentsNAT_unselectedClassifications", unselect.join("|"))
    })
  }

  // change conditions
  // onChangeSelectedConditions = (res) => {
  //   this.setState({ selectedConditions: res }, () => {
  //     // determiner ce qui n'est pas selectionner
  //     let unselect = []
  //     this.state.conditions.forEach((a) => {
  //       if (this.state.selectedConditions.indexOf(a.key) < 0) unselect.push(a.key)
  //     })
  //     // save unselected elements to localstorage
  //     localStorage.setItem("TableTournamentsNAT_unselectedConditions", unselect.join("|"))
  //   })
  // }

  // change columns
  onChangeSelectedColumns = (res) => {
      this.setState({ selectedColumns: res }, () => {
        // determiner ce qui est selectionne
        let select = []
        this.state.columns.forEach((a) => {
          if (this.state.selectedColumns.indexOf(a.key) > -1 ) select.push(a.key)
        })
        // save selected elements to localstorage
        localStorage.setItem("TableTournamentsNAT_selectedColumns", select.join("|"))
      })
    } 


  render() {
    const { t } = this.props;
    var tournaments = this.props.tournaments;
    var columns = this.state.columns;
    var selectedColumns = this.state.selectedColumns;
    var selectedRegions = this.state.selectedRegions;
    var selectedClassifications = this.state.selectedClassifications;
    //var selectedConditions = this.state.selectedConditions;
    var searchText = this.state.searchText;
    if (columns) {
      columns = columns.filter((o) =>
        selectedColumns.some((item) => item === o.key)
      );
    }
    if (tournaments) {
       tournaments = tournaments.filter((o) =>
         selectedRegions.some((item) => o.club && item === o.club.region.id)
       );
     }
     if (tournaments) {
       tournaments = tournaments.filter((o) =>
         selectedClassifications.some((item) => item === o.classification.id)
       );
     }
    // if (tournaments) {
    //   tournaments = tournaments.filter((o) =>
    //     selectedConditions.some((item) => item === o.conditionsId)
    //   );
    // }
    if (searchText.length > 2) {
      tournaments = tournaments.filter((o) =>
        o.name.toLowerCase().includes(searchText.toLowerCase()) || o.city.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    return (
      <>
        <FiltersBar
          components={
            <>
              <FiltersTitle title={tournaments
                  ? tournaments.length + " " + t("Tournaments").toLowerCase()
                  : ""}
              />
              <FilterSeparator />
              <MonthPicker
                className="mx-2 mt-1 2xl:mt-0 h-8 2xl:h-auto w-40 2xl:w-auto rounded-md focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300"
                value={this.props.date}
                onChange={(date) => this.props.onChange(date)}
              />
              <FilterSeparator />
              <FiltersSearch search={<SearchInput
                value={this.state.searchText}
                onChange={(searchText) => this.setState({ searchText })}
              />} />
              <FilterSeparator />
              <FiltersDropDowns selects={[
                <SelectMultiple
                  options={this.state.classifications}
                  keys={this.state.selectedClassifications}
                  className="mx-2"
                  onChange={this.onChangeSelectedClassifications}
                  fixedLabel={t("Classification")}
                />,
                <SelectMultiple
                  options={this.state.regions}
                  keys={this.state.selectedRegions}
                  className="mx-2"
                  onChange={this.onChangeSelectedRegions}
                  fixedLabel={t("Regions")}
                />,
                // <SelectMultiple
                //   options={this.state.conditions}
                //   keys={this.state.selectedConditions}
                //   className="mx-2"
                //   onChange={this.onChangeSelectedConditions}
                //   fixedLabel={t("Conditions")}
                // />
                ]} 
              />
              <FilterSeparator />
              <FilterColumns
                columns={
                  <SelectMultiple
                    options={this.state.columns}
                    keys={this.state.selectedColumns}
                    className="mx-2"
                    onChange={(res) => this.setState({ selectedColumns: res })}
                    fixedLabel={t("Active columns")}
                  />
                }
              />
              <FilterSeparator />
              <ExportToCSV columns={columns} jsonData={tournaments} fileName={t("Tournaments") + ".csv"} />
            </>
          }
          buttons={this.props.buttons ? (
            <>
            <div className="flex flex-row">
              {this.props.buttons.map((button, key) => <span key={key}>{button}</span>)}
              </div>
            </>
          ) : (
            ""
          )}
        />
        <TableSimple columns={columns} data={tournaments} />
      </>
    );
  }
}

export default withTranslation()(TableTournamentsNAT);
