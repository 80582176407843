import React, { Component } from "react";
import Card from "../components/card/Card";
import ExportToCSV from "../components/export/ExportToCSV";
import FilterColumns from "../components/filters/FilterColumns";
import FiltersBar from "../components/filters/FiltersBar";
import FiltersDropDowns from "../components/filters/FiltersDropDowns";
import FilterSeparator from "../components/filters/FilterSeparator";
import FiltersSearch from "../components/filters/FiltersSearch";
import FiltersTitle from "../components/filters/FiltersTitle";
import { SelectMultiple } from "../components/forms";
import SearchInput from "../components/forms/input/SearchInput";
import TableSimple from "../components/table/TableSimple";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import IconRound32x32 from "../components/icons/IconRound32x32";

class TableTournamentsByPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      selectedColumns: [],
      selectedGroups: [],
      searchText: "",
      sort_field: "name",
      sort_direction: -1,
      infoByPlayer: [],
      competitions: [],
    };
  }

  componentDidMount() {
    this.prepareData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.prepareData();
    }
  }

  prepareData = (firstTime) => {
    var competitions = this.props.competitions;
    var groups = [];

    const columns = [
      {
        key: "logo",
        visible: true,
        label: this.props.t("Logo"),
        width: 60,
        pinned: "left",
        format: (row) => <IconRound32x32 photo={row.logo} />,
      },
      {
        key: "name",
        visible: true,
        label: this.props.t("Name"),
        filter: {
          name: "SelectColumnFilter",
          type: "includes",
        },
        width: 150,
        format: (row) => (
          <span className=" text-xs 2xl:text-sm">{row.name}</span>
        ),
      },
      {
        key: "type.name",
        visible: true,
        label: this.props.t("Type"),
        width: 120,
        pinned: "right",
        format: (row) => (
          <span className=" text-xs 2xl:text-sm">
            {row.type && row.type.name}
          </span>
        ),
      },
    ];

    // after columns added Add local Storage
    var selectedColumns = localStorage.getItem("TableRugby_competitions");
    if (selectedColumns === null || selectedColumns.length === 0) {
      selectedColumns = [];
      columns.forEach((column) => {
        selectedColumns.push(column.key);
        //  column.visible = true
      });
    } else selectedColumns = selectedColumns.split("|");
    columns.forEach((column) => {
      column.visible = selectedColumns.indexOf(column.key) < 0 ? false : true;
    });

    // selected columns
    if (firstTime) {
      selectedColumns = [];
      columns.forEach((column) => {
        if (column.visible) {
          selectedColumns.push(column.key);
        }
      });
      selectedColumns = selectedColumns.slice(0, 6);
      this.setState({ selectedColumns });
    }

    this.setState({
      columns,
      competitions,
      groups,
      selectedColumns,
      //   selectedGroups,
    });
  };

  // change columns
  onChangeSelectedColumns = (res) => {
    this.setState({ selectedColumns: res }, () => {
      // determiner ce qui est selectionne
      let select = [];
      this.state.columns.forEach((a) => {
        if (this.state.selectedColumns.indexOf(a.key) > -1) select.push(a.key);
      });
      // save selected elements to localstorage
      localStorage.setItem("TableRugby_competitions", select.join("|"));
    });
  };

  render() {
    const { t } = this.props;
    var competitions = this.state.competitions;
    var columns = this.state.columns;
    var selectedColumns = this.state.selectedColumns;
    // var selectedGroups = this.state.selectedGroups;
    var searchText = this.state.searchText;
    if (columns) {
      columns = columns.filter((o) =>
        selectedColumns.some((item) => item === o.key)
      );
    }

    if (searchText.length > 2) {
      competitions = competitions.filter(
        (o) =>
          o.name.toLowerCase().includes(searchText.toLowerCase()) ||
          (o.type &&
            o.type.name.toLowerCase().includes(searchText.toLowerCase()))
      );
    }

    return (
      <>
        <Card>
          <FiltersBar
            components={
              <>
                <FiltersTitle
                  title={
                    competitions && competitions.length > 1
                      ? competitions.length +
                        " " +
                        t("Competitions").toLowerCase()
                      : competitions &&
                        competitions.length +
                          " " +
                          t("Competition").toLowerCase()
                  }
                />
                <FilterSeparator />
                <FiltersSearch
                  search={
                    <SearchInput
                      value={this.state.searchText}
                      onChange={(searchText) => this.setState({ searchText })}
                    />
                  }
                />
                <FilterSeparator />
                <FiltersDropDowns
                  selects={[
                    <SelectMultiple
                      options={this.state.groups}
                      keys={this.state.selectedGroups}
                      className="mx-2"
                      fixedLabel={t("Group")}
                    />,
                  ]}
                />
                <FilterSeparator />
                <FilterColumns
                  columns={
                    <SelectMultiple
                      options={this.state.columns}
                      keys={this.state.selectedColumns}
                      className="mx-2"
                      fixedLabel={t("Active columns")}
                      onChange={this.onChangeSelectedColumns}
                    />
                  }
                />
                <FilterSeparator />
                <ExportToCSV
                  columns={columns}
                  jsonData={competitions}
                  fileName={t("CompetitionsRugby") + ".csv"}
                />
              </>
            }
            buttons={this.props.buttons}
          />
          <TableSimple columns={columns} data={competitions} />
        </Card>
      </>
    );
  }
}

export default withTranslation()(withRouter(TableTournamentsByPlayer));
