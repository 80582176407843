import React from "react";

class DrawerFooter extends React.Component {
  render() {
    return (
      <>
        <div className="flex-shrink-0 pl-7 pt-1 2xl:pt-3 flex justify-start">
          {this.props.children}
        </div>
      </>
    );
  }
}

export default DrawerFooter;
