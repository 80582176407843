import React from "react";
import ActionButton from "../components/buttons/ActionButton";
import { withTranslation } from "react-i18next";
import TableSimple from "../components/table/TableSimple";
import moment from "moment";
import { SelectMultiple } from "../components/forms";
import { Link } from "react-router-dom";
import IconRound32x32 from "../components/icons/IconRound32x32";
import FilterColumns from "../components/filters/FilterColumns";
import FilterSeparator from "../components/filters/FilterSeparator";
import FiltersTitle from "../components/filters/FiltersTitle";
import FiltersBar from "../components/filters/FiltersBar";

class TableCurriculumCurriculumsPursued extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actions: [],
      curriculums: [],
      selectedCurriculums: [],
      columns: [],
      searchText: "",
    };
  }

  componentDidMount() {
    this.prepareData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.prepareData();
    }
  }

  prepareData = () => {
    var curriculumsPursued = this.props.curriculumsPursued;

    

    const columns = [
        {
            key: "displayName",
            visible: true,
            label: this.props.t("Player"),
            pinned: "left",
            id: "displayName",
            format: (row) => {
              return (
                <span className="flex justify-between">
                  <Link
                    className="text-indigo-700 font-medium pr-2 text-xs 2xl:text-sm"
                    to={"/group/player/" + row.player.id}
                  >
                    <IconRound32x32 photo={row.player.photo} />
                    <span className="ml-5">
                      {row.player.lastName.toUpperCase() + " " + row.player.firstName}
                    </span>
                  </Link>
                </span>
              );
            },
          },
      {
        key: "startDate",
        visible: true,
        pinned: "left",
        label: this.props.t("Start"),
        id: "startDate",
        format: (row) => <span className="text-xs 2xl:text-sm">{moment(row.startDate).calendar()}</span>,
      },
      {
        key: "endDate",
        visible: true,
        pinned: "left",
        label: this.props.t("End"),
        id: "endDate",
        format: (row) => <span className="text-xs 2xl:text-sm">{moment(row.endDate).calendar()}</span>,
      },
      {
        key: "actions",
        visible: true,
        pinned: "left",
        label: "",
        format: (row) => (
            <ActionButton
            key={row.id}
            updateForm="updateCurriculumPursued"
            deleteForm="deleteCurriculumPursued"
            formData={row}
            refreshData={() =>
              this.props.refresh
            }
          />
        ),
      },
    ];
    var selectedColumns = [];
    columns.forEach((column) => {
      if (column.visible) {
        selectedColumns.push(column.key);
      }
    });
    this.setState({
      columns,
      curriculumsPursued,
      selectedColumns,
    });
  };

  render() {
    const { t } = this.props;
    var curriculumsPursued = this.props.curriculumsPursued;
    var columns = this.state.columns;
    var selectedColumns = this.state.selectedColumns;
    if (columns) {
      columns = columns.filter((o) =>
        selectedColumns.some((item) => item === o.key)
      );
    }
    
    return (
      <>
        <FiltersBar
          components={
            <>
              <FiltersTitle title={curriculumsPursued
                  ? curriculumsPursued.length +
                    " " +
                    t("Curriculums pursued").toLowerCase()
                  : ""}
              />
              <FilterSeparator />
              <FilterColumns
                columns={
                  <SelectMultiple
                    options={this.state.columns}
                    keys={this.state.selectedColumns}
                    className="mx-2"
                    onChange={this.onChangeSelectedColumns}
                    fixedLabel={t("Active columns")}
                  />
                }
              />
            </>
          }
          buttons={this.props.buttons}
        />
        <TableSimple columns={columns} data={curriculumsPursued} />
      </>
    );
  }
}

export default withTranslation()(TableCurriculumCurriculumsPursued);
