import React from "react";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import Page from "../../components/page/Page";
import Card from "../../components/card/Card";
import {
  getEvent,
  loadEvent,
  getOrganizersByEvent,
  loadOrganizersByEvent,
  loadStatusByPlayerByEvent,
  getStatusByPlayerByEvent,
  patchStatusByPlayerByEvent,
} from "../../utils/indexedDB/events/handleEvents";
import moment from "moment";
// import IconRound64x64 from "../../components/icons/IconRound64x64";
import {
  loadPlayer,
  getPlayer,
  loadStaff,
  getStaff,
} from "../../utils/indexedDB/users/handleUser";
import SvgTrainingTennis from "../../assets/sass/vendors/theme/svg/icons/General/Thunder-move.svg";
import {
  getSessionTimesByEvent,
  loadSessionTimesByEvent,
  getRpeDataByEvent,
  loadRpeDataByEvent,
} from "../../utils/indexedDB/monitoring/handleMonitoring";
import {
  getTrainingSequences,
  loadTrainingSequences,
} from "../../utils/indexedDB/training/handleTraining";
import WidgetKeyFigure from "../../components/widgets/WidgetKeyFigure";
// import ListIcon from "../../components/icons/ListIcon";

import EventForm from "../../utils/forms/events/EventForm";
import Button from "../../components/buttons/Button";
import DisplayData3cols from "../../components/displayData/DisplayData3cols";
import TableTrainingSequences from "../../tables/TableTrainingSequences";
import ListIcon from "../../components/icons/ListIcon";
import ButtonLight from "../../components/buttons/ButtonLight";

import VideoCard from "../../components/card/VideoCard";
import { getVideosByEvent, loadVideosByEvent } from "../../utils/indexedDB/medias/handleMedia";

class TennisSession extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      event: null,
      sessionTime: null,
      trainingSequences: null,
      player: null,
      status: "isPresent",
    };
    this.handleEvent = this.handleEvent.bind(this);
    this.handlePlayer = this.handlePlayer.bind(this);
    this.handleStatusPlayerByEvent = this.handleStatusPlayerByEvent.bind(this);
    this.handleOrganizersByEvent = this.handleOrganizersByEvent.bind(this);
    this.handleSequencesByEvent = this.handleSequencesByEvent.bind(this);
    this.handleSessionTimes = this.handleSessionTimes.bind(this);
    this.handleRPEData = this.handleRPEData.bind(this);
    this.patchStatusByPlayerByEvent = patchStatusByPlayerByEvent.bind(this);
  }

  componentDidMount() {
    var eventId = this.props.match.params.eventId;
    var playerId = this.props.match.params.playerId;
    this.handleEvent(eventId);
    this.handlePlayer(playerId);
    this.handleStatusPlayerByEvent(playerId, eventId);
    this.handleOrganizersByEvent(eventId);
    this.handleSequencesByEvent(eventId);
    this.handleSessionTimes(eventId, playerId);
    this.handleRPEData(eventId, playerId);
    this.handleVideos(eventId);
  }

  handleEvent(id) {
    getEvent(id).then((event) => {
      if (event) {
        this.setState({ event });
      }
    });
    if (navigator.onLine) {
      loadEvent(id).then((event) => {
        if (event) {
          this.setState({ event });
        } else {
          this.props.history.push("/notFound");
        }
      });
    }
  }

  handlePlayer(id) {
    getPlayer(id).then((player) => {
      if (player) {
        this.setState({ player });
      }
    });
    if (navigator.onLine) {
      loadPlayer(id).then((player) => {
        if (player) {
          this.setState({ player });
        }
      });
    }
  }

  handleStatusPlayerByEvent(playerId, id) {
    if (navigator.onLine) {
      loadStatusByPlayerByEvent(playerId, id).then((status) =>
        this.setState({ status })
      );
    } else {
      getStatusByPlayerByEvent(playerId, id).then((status) =>
        this.setState({ status })
      );
    }
  }

  handleOrganizersByEvent(id) {
    var promisesOrganizers = [];
    getOrganizersByEvent(id).then((organizers) => {
      organizers.forEach((organizer) => {
        promisesOrganizers.push(
          getStaff(organizer.staffId).then((staff) => {
            organizer.staff = staff;
            organizer.displayName = staff.displayName;
            organizer.photo = staff.photo;
            return organizer;
          })
        );
      });
      Promise.all(promisesOrganizers).then((organizers) => {
        this.setState({
          organizers: organizers
            .sort()
            .filter(
              (value, index, array) => index === 0 || value !== array[index - 1]
            ),
        });
      });
    });
    if (navigator.onLine) {
      var promisesOrganizersAPI = [];
      loadOrganizersByEvent(id).then((organizers) => {
        if (organizers) {
          organizers.forEach((organizer) => {
            promisesOrganizersAPI.push(
              loadStaff(organizer.staffId).then((staff) => {
                organizer.staff = staff;
                organizer.displayName = staff.displayName;
                organizer.photo = staff.photo;
                return organizer;
              })
            );
          });
          Promise.all(promisesOrganizersAPI).then((organizers) => {
            this.setState({
              organizers: organizers
                .sort()
                .filter(
                  (value, index, array) =>
                    index === 0 || value !== array[index - 1]
                )
                .sort((a, b) =>
                  a.staff && b.staff
                    ? a.staff.lastName === b.staff.lastName
                      ? 0
                      : a.staff.lastName > b.staff.lastName
                        ? 1
                        : -1
                    : 0
                ),
            });
          });
        }
      });
    }
  }

  handleSequencesByEvent(id) {
    getTrainingSequences(id).then((trainingSequences) => {
      trainingSequences = trainingSequences.map((t) => {
        t.trainingTypeName =
          t.trainingTheme && t.trainingTheme.trainingCategory
            ? t.trainingTheme.trainingCategory.name
            : "";
        t.trainingThemeName = t.trainingTheme ? t.trainingTheme.name : "";
        return t;
      });
      this.setState({ trainingSequences });
    });
    if (navigator.onLine) {
      loadTrainingSequences(id).then((trainingSequences) => {
        trainingSequences = trainingSequences.map((t) => {
          t.trainingTypeName =
            t.trainingTheme && t.trainingTheme.trainingCategory
              ? t.trainingTheme.trainingCategory.name
              : "";
          t.trainingThemeName = t.trainingTheme ? t.trainingTheme.name : "";
          return t;
        });
        this.setState({ trainingSequences });
      });
    }
  }

  handleRPEData(id, playerId) {
    getRpeDataByEvent(id).then((rpeData) => {
      if (rpeData && rpeData.length) {
        rpeData = rpeData.find((r) => r.playerId === playerId);
        this.setState({ rpeData });
      }
    });
    if (navigator.onLine) {
      loadRpeDataByEvent(id).then((rpeData) => {
        if (rpeData && rpeData.length) {
          rpeData = rpeData.find((r) => r.playerId === playerId);
          this.setState({ rpeData });
        }
      });
    }
  }

  handleSessionTimes(id, playerId) {
    getSessionTimesByEvent(id).then((sessionTimes) => {
      if (sessionTimes && sessionTimes.length) {
        var sessionTime = sessionTimes.find((s) => s.playerId === playerId);
        if (sessionTime) {
          this.setState({ sessionTime });
        }
      }
    });
    if (navigator.onLine) {
      loadSessionTimesByEvent(id).then((sessionTimes) => {
        if (sessionTimes && sessionTimes.length) {
          var sessionTime = sessionTimes.find((s) => s.playerId === playerId);
          if (sessionTime) {
            this.setState({ sessionTime });
          }
        }
      });
    }
  }

  handleVideos(eventId) {
    this.setState({ getVideosFromAPI: false })
    getVideosByEvent(eventId).then(videos => {
      if (videos && videos.length && !this.state.getVideosFromAPI) {
        this.setState({ videos });
      }
    })

    if (navigator.onLine) {
      loadVideosByEvent(eventId).then(videos => {
        this.setState({ videos, getVideosFromAPI: true })
      })
    }
  }

  render() {
    const { t } = this.props;
    var staffs = [];
    this.state.organizers &&
      this.state.organizers.map((organizer) => staffs.push(organizer.staff));
    return (
      <Page
        pageTitle={
          this.state.event ? this.state.event.name : t("Tennis Session")
        }
        actions={{
          other: [
            <EventForm
              key="maininfo-form"
              step="information"
              component={(show, text) => (
                <ButtonLight onClick={show}>{t("Edit")}</ButtonLight>
              )}
              method="PATCH"
              callback={() => this.handleEvent(this.props.match.params.eventId)}
              formData={this.state.event}
            />,
            <EventForm
              key="maininvi-form"
              step="invitation"
              component={(show, text) => (
                <ButtonLight onClick={show}>{t("Edit invitation")}</ButtonLight>
              )}
              method="PATCH"
              callback={() => this.handleOrganizersByEvent(this.props.match.params.eventId)}
              formData={this.state.event}
            />,
            <EventForm
              key="delete-form"
              title={t("Delete")}
              component={(show, text) => (
                <Button onClick={show} disabled={!navigator.onLine}>
                  {text}
                </Button>
              )}
              method="DELETE"
              callback={() => this.props.history.goBack()}
              formData={this.state.event}
            />,
          ],
        }}
        backPage="/training/tennis"
        tab={[
          {
            title: t("Summary"),
            visible: true,
            data: this.state.event,
            component: (data) => {
              return data ? (
                <>
                  <div>
                    <div className="flex">
                      <div className="w-full px-6 pt-4 py-2">
                        <Card>
                          <DisplayData3cols
                            data={[
                              { key: t("Title"), value: data.name },
                              {
                                key: t("Date"),
                                value: moment(data.startDate).calendar(),
                              },
                              {
                                key: t("Category"),
                                value:
                                  data.specificInfos &&
                                    data.specificInfos.category
                                    ? data.specificInfos.category.name
                                    : "N/A",
                              },
                              {
                                key: t("Location"),
                                value: data.location,
                              },

                              {
                                key: t("Ground"),
                                value:
                                  data.specificInfos &&
                                    data.specificInfos.groundId
                                    ? data.specificInfos.groundId
                                    : "",
                              },
                              {
                                key: t("Conditions"),
                                value:
                                  data.specificInfos &&
                                    data.specificInfos.conditionsId
                                    ? data.specificInfos.conditionsId
                                    : "",
                              },
                              {
                                key: t("Staffs"),
                                value: <ListIcon users={staffs} length={3} />,
                              },
                              {
                                key: t("Description"),
                                value: data.description,
                              },
                            ]}
                          />
                        </Card>
                      </div>
                    </div>
                    <div className="w-full px-10 py-2">
                      <Card>
                        <div className="grid grid-cols-3">
                          <div className="pl-0 pr-6 py-3 w-full">
                            <WidgetKeyFigure
                              svg={SvgTrainingTennis}
                              heures={
                                this.state.sessionTime
                                  ? (moment.duration(this.state.sessionTime.value * 60 * 1000).asHours())
                                  : 0
                              }
                              title={t("Duration")}
                            />
                          </div>
                          <div className="px-6 py-3 w-full">
                            <WidgetKeyFigure
                              svg={SvgTrainingTennis}
                              sRpe={
                                data.specificInfos &&
                                  data.specificInfos.rpeRequested
                                  ? data.specificInfos.expectedRpe
                                  : "-"
                              }
                              title={t("Estimated RPE")}
                              blank={true}
                            />
                          </div>
                          <div className="pl-6 pr-0 py-3 w-full">
                            <WidgetKeyFigure
                              svg={SvgTrainingTennis}
                              sRpe={
                                this.state.rpeData
                                  ? this.state.rpeData.rpe
                                  : "-"
                              }
                              title={t("Real RPE")}
                              blank={true}
                            />
                          </div>
                        </div>
                      </Card>
                    </div>
                    <div className="w-full px-6 py-2">
                      {/* <div className="grid grid-cols-2">
                        <div className="shadow-xl border rounded-md ml-4 mr-6">
                          {this.state.player ? (
                            <Card title={t("Participant")}>
                              <IconRound200x200
                                photo={this.state.player.photo}
                              />
                              {this.state.player.lastName.toUpperCase() +
                                " " +
                                this.state.player.firstName}
                              {this.state.player.position
                                ? this.state.player.position.name
                                : ""}
                              <Select
                                disabled={!navigator.onLine}
                                value={this.state.status}
                                options={[
                                  {
                                    key: "isPresent",
                                    label: t("On time"),
                                  },
                                  {
                                    key: "isMissing",
                                    label: t("Missing"),
                                  },
                                  { key: "isLate", label: t("Late") },
                                  {
                                    key: "isMissingWithJustification",
                                    label: t("Missing With Justification"),
                                  },
                                  {
                                    key: "isExcluded",
                                    label: t("Excluded"),
                                  },
                                ]}
                                onChange={(value) =>
                                  this.patchStatusByPlayerByEvent(
                                    this.props.match.params.playerId,
                                    this.props.match.params.eventId,
                                    value
                                  )
                                }
                              />
                            </Card>
                          ) : (
                            ""
                          )}
                        </div>
                      </div> */}
                      <div className="w-full mt-6 mb-20">
                        <div
                          className="border rounded-md ml-4 mr-4"
                          style={{ boxShadow: "0 0 10px 0 rgba(82,63,105,.2)" }}
                        >
                          <Card
                            title={t("Session content")}
                            headerActions={[
                              <EventForm
                                key="sequences-form"
                                step="sequencesTraining"
                                component={(show, text) => (
                                  <Button
                                    onClick={show}
                                    disabled={!navigator.onLine}
                                  >
                                    {t("Edit")}
                                  </Button>
                                )}
                                method="PATCH"
                                callback={() =>
                                  this.handleSequencesByEvent(
                                    this.props.match.params.eventId
                                  )
                                }
                                formData={this.state.event}
                              />,
                            ]}
                          >
                            <TableTrainingSequences
                              sequences={this.state.trainingSequences}
                              height={200}
                            />
                          </Card>
                        </div>
                      </div>{" "}
                    </div>
                  </div>
                </>
              ) : (
                ""
              );
            },
          },
          {
            title: t("Videos"),
            visible: true,
            data: null,
            actions: {
              key: "createVideo",
              title: t("Add video"),
              addForm: "createVideo",
              formData: { eventId: this.props.match.params.eventId, eventTypeId: t("Training"), playerId: this.props.match.params.playerId },
              callback: () => this.handleVideos(this.props.match.params.eventId)
            },
            component: (data) => {
              return (
                <div className="my-8 w-full flex flex-wrap mx-auto gap-8 p-6">
                  {this.state.videos && this.state.videos.length
                    ? this.state.videos.map((video, key) => (
                      <VideoCard video={video} author={this.state.staffs ? this.state.staffs.find(s => s.id === video.authorId) : null} handleVideos={() => this.handleCollection(this.state.id)} isVideoTemp={false} key={key} />
                    ))
                    : null}
                </div>
              );
            },
          },
        ]}
      />
    );
  }
}

export default withTranslation()(withRouter(TennisSession));
