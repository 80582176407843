import handleCondition from "./handleCondition";
let yup = require('yup');

function getYupObjectPerQuestion(form, formData, question, t) {
    var schema = {};
    if (handleCondition(form, formData, question)) {
        switch (question.type) {
            case "string":
                schema[question.label] = yup.string();
                break;
            case "array":
                schema[question.label] = yup.array();
                break;
            case "number":
                schema[question.label] = yup.number();
                break;
            case "bool":
                schema[question.label] = yup.boolean();
                break;
            default:
                schema[question.label] = yup.mixed();
                break;
        }
        if (question.min) {
            schema[question.label] = schema[question.label].min(question.min)
        }
        if (question.max) {
            schema[question.label] = schema[question.label].max(question.max)
        }
        if (question.required) {
            schema[question.label] = schema[question.label].required(x => t("Required field", { questionName: x.path }))
        }
        if (question.subQuestions) {
            question.subQuestions.forEach(sb => {
                var generatedSchema = getYupObjectPerQuestion(form, formData, sb, t);
                Object.keys(generatedSchema).forEach(key => {
                    schema[key] = generatedSchema[key];
                })
            })
        }
    }
    return schema;
}

function getYupObject(form, formData) {
    var schema = {};
    form.forEach(question => {
        var generatedSchema = getYupObjectPerQuestion(form, formData, question, this.props.t);
        Object.keys(generatedSchema).forEach(key => {
            schema[key] = generatedSchema[key];
        })
    });
    return yup.object(schema);
}

export default getYupObject;