import React from "react";
import Input from "./Input";
import moment from "moment";

class TimePicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value ? (moment.isMoment(this.props.value) && this.props.value.isValid() ? this.props.value.format("HH:mm") : (moment(this.props.value).isValid() ?moment(this.props.value).format("HH:mm"): this.props.value)) : ""
        }
        this.onTimeChange = this.onTimeChange.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.value !== prevProps.value) {
            this.setState({ value: this.props.value && moment.isMoment(this.props.value) && this.props.value.isValid() ? this.props.value.format("HH:mm") : (moment(this.props.value).isValid() ? moment(this.props.value).format("HH:mm"): this.props.value) });
        }
    }

    onTimeChange(value) {
        this.props.onChange(value);
        this.setState({ value })
    }
    render() {
        var { onChange, value, ...newProps } = this.props;
        return (
            <Input type="time" onChange={this.onTimeChange} value={this.state.value} {...newProps} />
        )
    }
}

export default TimePicker;