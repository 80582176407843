import React from "react";

class Label extends React.Component {
  render() {
    if (this.props.type === "success") {
      return (
        <div className="inline-flex items-center px-3 py-0.5 rounded-full  text-xs 2xl:text-sm font-medium bg-green-100 text-green-800">
          {this.props.text}
        </div>
      );
    } else if (this.props.type === "warning") {
      return (
        <div className="inline-flex items-center px-3 py-0.5 rounded-full  text-xs 2xl:text-sm font-medium bg-yellow-100 text-yellow-800">
          {this.props.text}
        </div>
      );
    } else if (this.props.type === "danger") {
      return (
        <div className="inline-flex items-center px-3 py-0.5 rounded-full  text-xs 2xl:text-sm font-medium bg-red-100 text-red-800">
          {this.props.text}
        </div>
      );
    } else if (
      this.props.type === "custom" &&
      this.props.fontColor &&
      this.props.backColor
    ) {
      return (
        <div
          className="label label-lg label-light-danger label-inline"
          style={{
            backgroundColor: this.props.backColor,
            color: this.props.fontColor,
          }}
        >
          {this.props.text}
        </div>
      );
    } else {
      return (
        <div className="label label-lg label-light label-inline">
          {this.props.text}
        </div>
      );
    }
  }
}
export default Label;
