import db from "./db";
import get from "../../requests/get";
import post from "../../requests/post";
// import moment from "moment";

async function loadRecord(id) {
  return get("administration", "records", id).then((res) => {
    if (res) {
      db.records.put(res);
    }
    return res;
  });
}

function loadRecordsByPlayers(ids) {
  return post("administration", "records", {playersIds : ids}).then(res => {return res.json()}).then(
    (res) => {
      if (res) {
        db.records.bulkPut(res);
      }
      return res;
    }
  );
}

function getRecord(id) {
  return db.records.get(id);
}

async function loadLicensesByPlayer(playerId) {
  return get("administration", "licensesByPlayer", playerId).then((res) => {
    if (res) {
      db.licenses.bulkPut(res);
    }
    return res;
  });
}

async function getLicensesByPlayer(playerId) {
  return db.licenses.where({ playerId: playerId }).toArray();
}

function loadLicensesByPlayers(ids) {
  return post("administration", "licensesByPlayers", {playersIds : ids}).then(res => {return res.json()}).then(
    (res) => {
      if (res) {
        db.licenses.bulkPut(res);
      }
      return res;
    }
  );
}

async function loadContactsTypes() {
  return get("administration", "contactsTypes").then((res) => {
    if (res) {
      db.contactsTypes.bulkPut(res);
    }
    return res;
  });
}

async function getContactsTypes() {
  return db.contactsTypes.toArray();
}

async function loadContactsByPlayer(playerId) {
  return get("administration", "contactsByPlayer", playerId).then((res) => {
    if (res) {
      db.contacts.bulkPut(res);
    }
    return res;
  });
}

function loadContactsByPlayers(ids) {
  return post("administration", "contactsByPlayers", {playersIds : ids}).then(res => {return res.json()}).then(
    (res) => {
      if (res) {
        db.contacts.bulkPut(res);
      }
      return res;
    }
  );
}

async function getContactsByPlayer(playerId) {
  return db.contacts.where({ playerId: playerId }).toArray();
}

async function getPersonalDocumentsByPlayer(playerId) {
  return db.personalDocuments.where({ playerId: playerId }).toArray();
}

async function loadPersonalDocumentsByPlayer(playerId) {
  return get("administration", "personalFilesByPlayer", playerId).then(
    (res) => {
      if (res) {
        db.personalDocuments.bulkPut(res);
      }
      return res;
    }
  );
}

export {
  loadRecord,
  loadRecordsByPlayers,
  getRecord,
  loadLicensesByPlayer,
  loadLicensesByPlayers,
  getLicensesByPlayer,
  loadContactsByPlayer,
  loadContactsByPlayers,
  getContactsByPlayer,
  loadContactsTypes,
  getContactsTypes,
  loadPersonalDocumentsByPlayer,
  getPersonalDocumentsByPlayer,
};
