import React from "react";
import IconRound32x32 from "../components/icons/IconRound32x32";
import { withTranslation } from "react-i18next";
import { SelectMultiple } from "../components/forms";
import TableSimple from "../components/table/TableSimple";
import FilterColumns from "../components/filters/FilterColumns";
import FiltersDropDowns from "../components/filters/FiltersDropDowns";
import ExportToCSV from "../components/export/ExportToCSV";
import FiltersSearch from "../components/filters/FiltersSearch";
import SearchInput from "../components/forms/input/SearchInput";
import FilterSeparator from "../components/filters/FilterSeparator";
import FiltersTitle from "../components/filters/FiltersTitle";
import FiltersBar from "../components/filters/FiltersBar";

class TableRankingNationalF extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actions: [],
      years: [],
      selectedYears: [],
      series: [],
      selectedSeries: [],
      dates: [],
      columns: [],
      searchText: "",
    };
  }

  componentDidMount() {
    this.prepareData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.prepareData();
    }
  }

  prepareData = () => {
    var rankings = this.props.rankings;
    var years = [];
    var series = [];
    var selectedYears = [];
    var selectedSeries = [];

    //  chercher les années a déselectionner du localstorage
    var unselectedYears = localStorage.getItem("TableRankingNationalF_unselectedYears")
    if (unselectedYears) unselectedYears = unselectedYears.split("|")
    
    // series
    var unselectedSeries = localStorage.getItem("TableRankingNationalF_unselectedSeries")
    if (unselectedSeries) unselectedSeries = unselectedSeries.split("|")   
    
    if (rankings) {
      rankings = rankings.map((ranking) => {
        if (years && !years.find((o) => o.key === ranking.yob)) {
          years.push({ key: ranking.yob, label: ranking.yob })
          // only add to selected if not unselected
        if (
          !unselectedYears ||
          unselectedYears.indexOf(ranking.yob.toString()) < 0
        )
          selectedYears.push(ranking.yob);
        }
        if (series && !series.find((o) => o.key === ranking.s)) {
          series.push({
            key: ranking.s,
            label: ranking.s,
          })
           // only add to selected if not unselected
           if (
            !unselectedSeries ||
            unselectedSeries.indexOf(ranking.s) < 0
          )
          selectedSeries.push(ranking.s);
        }
        return ranking;
      });
    }

    const columns = [
      {
        key: "photo",
        visible: true,
        format: (row) => <IconRound32x32 photo={row.photo} />,
        width: 60,
      },
      {
        key: "displayName",
        visible: true,
        label: this.props.t("Name"),
        id: "displayName",
        width: 350,
        format: (row) => (
          <span className="text-primary font-weight-bolder mb-0 text-xs 2xl:text-sm">
            {row.displayName}
          </span>
        ),
      },
      {
        key: "yob",
        visible: true,
        label: this.props.t("Year of birth"),
        id: "yob",
        format: (row) => (
          <span className="font-weight-bold text-muted text-xs 2xl:text-sm">{row.yob}</span>
        ),
        filter: {
          name: "SelectColumnFilter",
          type: "includes",
        },
        width: 200,
      },
      {
        key: "s",
        visible: true,
        label: this.props.t("Serie"),
        id: "s",
        format: (row) => (
          <span className="font-weight-bold text-muted text-xs 2xl:text-sm">{row.s}</span>
        ),
        width: 250,
        filter: {
          name: "SelectColumnFilter",
          type: "includes",
        },
      },
      {
        key: "name",
        visible: true,
        label: this.props.t("Rank"),
        id: "name",
        format: (row) => (
          <span className="font-weight-bold text-muted text-xs 2xl:text-sm">{row.name}</span>
        ),
        width: 220,
        filter: {
          name: "SelectColumnFilter",
          type: "includes",
        },
      },
    ];

   
    var selectedColumns = localStorage.getItem("TableRankingNationalF_selectedColumns")
    if (selectedColumns === null || selectedColumns.length === 0)  selectedColumns = ["photo", "displayName", "yob", "s", "name" ]
    else selectedColumns = selectedColumns.split("|")
    columns.forEach((column) => {
      column.visible = selectedColumns.indexOf(column.key) < 0 ? false : true 

    });
    this.setState({
      columns,
      rankings,
      years,
      series,
      selectedColumns,
      selectedYears,
      selectedSeries,
    });
  };

    // change ages
    onChangeSelectedYears = (res) => {
      this.setState({ selectedYears: res }, () => {
        // determiner ce qui n'est pas selectionner
        let unselect = []
        this.state.years.forEach((a) => {
          if (this.state.selectedYears.indexOf(a.key) < 0) unselect.push(a.key)
        })
        // save unselected elements to localstorage
        localStorage.setItem("TableRankingNationalF_unselectedYears", unselect.join("|"))
      })
    }
    
    // change nationalities
    onChangeSelectedSeries = (res) => {
      this.setState({ selectedSeries: res }, () => {
        // determiner ce qui n'est pas selectionner
        let unselect = []
        this.state.series.forEach((a) => {
          if (this.state.selectedSeries.indexOf(a.key) < 0) unselect.push(a.key)
        })
        // save unselected elements to localstorage
        localStorage.setItem("TableRankingNationalF_unselectedSeries", unselect.join("|"))
      })
    }
  
    // change columns
    onChangeSelectedColumns = (res) => {
        this.setState({ selectedColumns: res }, () => {
          // determiner ce qui est selectionne
          let select = []
          this.state.columns.forEach((a) => {
            if (this.state.selectedColumns.indexOf(a.key) > -1 ) select.push(a.key)
          })
          // save selected elements to localstorage
          localStorage.setItem("TableRankingNationalF_selectedColumns", select.join("|"))
        })
      }
  

  render() {
    const { t } = this.props;
    var rankings = this.props.rankings;
    var columns = this.state.columns;
    var selectedColumns = this.state.selectedColumns;
    var selectedYears = this.state.selectedYears;
    var selectedSeries = this.state.selectedSeries;
    var searchText = this.state.searchText;
    if (columns) {
      columns = columns.filter((o) =>
        selectedColumns.some((item) => item === o.key)
      );
    }
    if (rankings) {
      rankings = rankings.filter((o) =>
        selectedYears.some((item) => item === o.yob)
      );
    }
    if (rankings) {
      rankings = rankings.filter((o) =>
        selectedSeries.some((item) => item === o.s)
      );
    }
    if (searchText.length > 2) {
      rankings = rankings.filter(
        (o) =>
          o.player.lastName
            .toLowerCase()
            .includes(searchText.toLowerCase()) ||
          o.player.firstName.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    return (
      <>
        <FiltersBar
          components={
            <>
              <FiltersTitle title={rankings ? rankings.length + " " + t("Players").toLowerCase() : ""} />
              <FilterSeparator />
              <FiltersSearch search={<SearchInput
                value={this.state.searchText}
                onChange={(searchText) => this.setState({ searchText })}
                
              />} />
              
              <FilterSeparator />
              <FiltersDropDowns selects={[
                <SelectMultiple
                  options={this.state.years}
                  keys={this.state.selectedYears}
                  className="mx-2"
                  onChange={this.onChangeSelectedYears}
                  fixedLabel={t("Years")}
                />,
                <SelectMultiple
                  options={this.state.series}
                  keys={this.state.selectedSeries}
                  className="mx-2"
                  onChange={this.onChangeSelectedSeries}
                  fixedLabel={t("Series")}
                />
                ]} 
              />
              <FilterSeparator />
              <FilterColumns
                columns={
                  <SelectMultiple
                    options={this.state.columns}
                    keys={this.state.selectedColumns}
                    className="mx-2"
                    onChange={this.onChangeSelectedColumns}
                    fixedLabel={t("Active columns")}
                  />
                }
              />
              <FilterSeparator />
              <ExportToCSV columns={columns} jsonData={rankings} fileName={t("Ranking") + ".csv"} />
            </>
          }
        />
        <TableSimple columns={columns} data={rankings} />
      </>
    );
  }
}

export default withTranslation()(TableRankingNationalF);
