import React from "react"
import { withTranslation } from "react-i18next"
import moment from "moment"
import ChartStackedColumns from "../components/charts/ChartStackedColumns"
import ChartDoughnut2D from "../components/charts/ChartDoughnut2D"
import WidgetKeyFigure from "../components/widgets/WidgetKeyFigure"
import Carousel from "../components/carousels/Carousel"
import { Select } from "../components/forms";

class ChartRpeByPlayer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      view: "Hebdo",
      week: null,
    }
  }

  render() {
    const { t } = this.props
    const data = this.props.rpeByEvent
    const startDate = this.props.startDate
    const endDate = this.props.endDate
    var categories = this.props.categories
    let dataSet = []
    let dates = []
    var dataDoughnut = []
    var date1 = moment(startDate).format("dddd DD MMM");
    var date2 = moment(endDate).format("dddd DD MMM");
    var totalTime = 0
    var totalSeance = 0
    let weeks = [];
    let weeksDataSet = [];
    const addWeek = (startOfWeek) => {
      let s = moment(startOfWeek)
      let e = moment(startOfWeek).endOf('week')
      let key = weeks.length;
      weeks.push({
        startDate: s.format(),
        endDate: e.format(),
        key,
        label: s.format("dddd DD MMM") + " - " + e.format("dddd DD MMM")
      })
    }
    if (data != null && startDate != null && endDate != null && categories != null) {
      var start = moment(startDate)
      var end = moment(endDate)
      let days = end.diff(start, "days") + 1
      if (start !== start.startOf('week') && (!this.props.isWeek || this.state.view === "Hebdo")) {
        addWeek(start.startOf('week'));
      }
      for (let i = 0; i < days; i++) {
        const dt1 = moment(moment(startDate).format("YYYY-MM-DD")).add(
          i,
          "days"
        )
        dates.push({
          label: dt1.format("dddd DD"),
        })
        let startOfWeek = moment(moment(startDate).format("YYYY-MM-DD")).add(
          i,
          "days"
        ).startOf('week');
        if (dt1._d.getDate() === startOfWeek._d.getDate() && (!this.props.isWeek || this.state.view === "Hebdo")) {
          addWeek(startOfWeek);
        }
      }
      if (this.state.week === null && weeks.length > 0 && !this.props.isWeek) {
        this.setState({ week: weeks[0] });
      }

      categories.forEach((category) => {
        let weeklyValues = [];
        var dailyValues = []
        var nbSeance = 0
        let weeklyData = 0;
        let week = 0;
        for (let i = 0; i < days; i++) {
          const dt1 = moment(moment(startDate).format("YYYY-MM-DD")).add(
            i,
            "days"
          )
          if (moment(weeks[week].endDate).isBefore(dt1)) {
            weeklyValues.push({
              value: weeklyData.toFixed(0),
            });
            weeklyData = 0;
            week += 1;
          }
          const dailyData = (data.find((o) => o.date === moment(dt1).format("YYYY-MM-DD")+"T00:00:00")).results.filter(
            (o) =>
              o.kind === category
          );
          if (dailyData.length > 0)
            nbSeance += dailyData.reduce((total, curr) => total + curr.amount, 0)
          var total = dailyData.reduce((total, curr) => total + curr.srpe, 0)
          weeklyData += total;

          dailyValues.push({
            value: total.toFixed(0),
          })
        }
        weeklyValues.push({
          value: weeklyData.toFixed(0),
        });
        let value = 0
        if (dailyValues)
          dailyValues.forEach((v) => {
            value += Number(v.value)
          })
        dataSet.push({
          seriesname: t(category),
          nbSeance,
          data: dailyValues,
        })
        weeksDataSet.push({
          seriesname: t(category),
          data: weeklyValues
        })
        dataDoughnut.push({
          label: t(category),
          value,
        })
        totalTime += value
        totalSeance += nbSeance
      })
    }

    var widgets = []


    const widgetSequence = (sRpe, title, nbSeance) => {
      widgets.push(
        <div className="px-3 py-3">
          <WidgetKeyFigure
            svg="svg"
            figure={nbSeance}
            title={t("sRpe total") + " " + t(title)}
            sRpe={sRpe}
          />
        </div>
      )
    }

    widgetSequence(totalTime, " ", totalSeance);

    dataSet.map(dataSetObject => {
      let totalsRpe = 0
      dataSetObject.data.forEach((dataObject) => {
        totalsRpe += parseInt(dataObject.value)
      })
      return widgetSequence(totalsRpe, dataSetObject.seriesname, dataSetObject.nbSeance)
    })

    return (
      <>
        {!this.props.isWeek ?
          <>
            <Select
              options={[
                {
                  key: "Hebdo",
                  label: t("Hebdo"),
                },
                {
                  key: "Daily",
                  label: t("Daily"),
                },
                {
                  key: "Weeks",
                  label: t("Weeks"),
                }
              ]}
              value={this.state.view}
              onChange={(view) => this.setState({ view })}
            />
            {this.state.view === "Hebdo" ? // chart une barre par semaine
              <>
                <Carousel items={widgets} />
                <ChartStackedColumns dataSet={weeksDataSet} categories={weeks}
                  title={t("sRPE total weekly distribution")}
                  subTitle={t("sRPE total in each category")}
                  notMin={"UA"}
                />
                <ChartDoughnut2D title={t("Distribution")}
                  dataSet={dataDoughnut}
                  subcaption={t("From x to y", { date1, date2 })}
                  totalTime={totalTime}
                  width="100%"
                />
              </>
              : <></>
            }
            {this.state.view === "Daily" ?
              <>
                <Carousel items={widgets} />
                <ChartStackedColumns
                  title={t("sRPE total daily distribution")}
                  subTitle={t("sRPE total in each category")}
                  dataSet={dataSet}
                  categories={dates}
                  height={this.props.height ? this.props.height : null}
                  notMin={"UA"}
                />
                <ChartDoughnut2D
                  title={t("Distribution")}
                  dataSet={dataDoughnut}
                  subcaption={t("From x to y", { date1, date2 })}
                  totalSRPE={totalTime}
                  width="100%"
                />
              </>
              : <></>
            }
            {this.state.view === "Weeks" ?
              <>
                <Select
                  options={weeks}
                  value={this.state.week.label}
                  onChange={(week) => this.setState({ week: weeks.find(w => w.key === week) })}
                />
                <ChartRpeByPlayer
                  startDate={this.state.week.startDate}
                  endDate={this.state.week.endDate}
                  height="400"
                  rpeByEvent={data}
                  categories={this.props.categories}
                  isWeek={true}
                  t={t}
                />
              </>
              : <></>
            }
          </>
          : <>
            <Carousel items={widgets} />
            <ChartStackedColumns
              title={t("sRPE total daily distribution")}
              subTitle={t("sRPE total in each category")}
              dataSet={dataSet}
              categories={dates}
              height={this.props.height ? this.props.height : null}
              notMin={"UA"}
            />
            <ChartDoughnut2D
              title={t("Distribution")}
              dataSet={dataDoughnut}
              subcaption={t("From x to y", { date1, date2 })}
              totalSRPE={totalTime}
              width="100%"
            />
          </>
        }
      </>
    )
  }
}

export default withTranslation()(ChartRpeByPlayer)
