import React from "react";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import bg1 from "../../assets/sass/vendors/theme/assets/img/bg1.jpg";

class NotFound extends React.Component {
  render() {
    return (
      <div className="d-flex flex-column flex-root">
        <div
          className="d-flex flex-row-fluid flex-column bgi-size-cover bgi-position-center bgi-no-repeat p-10 p-sm-30"
          style={{
            backgroundImage: `url(${bg1})`,
          }}
        >
          <h1
            className="font-size-sm-100 font-weight-boldest text-dark-75 mt-15"
            style={{ fontSize: "150px" }}
          >
            404
          </h1>
          <p className="font-size-h3 font-weight-light">
            OOPS ! Page introuvable !
          </p>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(NotFound));
