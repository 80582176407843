import React from "react";
import Card from "../../components/card/Card";
import ButtonLight from "../../components/buttons/ButtonLight";
import GeneratedForm from "../../utils/forms/generateForm";
import moment from "moment";

function MatchGeneralHeaderRugby({t , matchInfos}) {
  return (
    <Card
      headerActions={
        <GeneratedForm
          key="updateCommentPlayer"
          component={(show) => (
            <ButtonLight onClick={show} disabled={!navigator.onLine}>
              {t("Edit")}
            </ButtonLight>
          )}
          callback={() => this.props.refresh()}
          formName="updateCommentPlayer"
        />
      }
    >
      <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
        <div className="flex justify-around">
          <div className="flex flex-row p-2">
            <div>
              {matchInfos &&
              matchInfos.competition &&
              matchInfos.competition.logo ? (
                <img
                  className="h-6 2xl:h-10 w-6 2xl:w-10 rounded-full"
                  src={
                    matchInfos &&
                    matchInfos.competition &&
                    matchInfos.competition.logo
                  }
                  alt="competition"
                />
              ) : (
                <>
                  <div className="flex flex-col items-center">
                    <div className="p-1 text-lg">
                      {matchInfos && matchInfos.name}
                    </div>
                    {matchInfos && matchInfos.place && (
                      <div className="p-1 ">{matchInfos.place}</div>
                    )}
                    <div className="p-1 text-sm">
                      {moment(matchInfos && matchInfos.startDate).format(
                        "DD-MM-YYYY"
                      )}
                    </div>
                    <div className="text-sm">
                      {moment(matchInfos && matchInfos.startDate).format(
                        "H:mm"
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
            <div>
              {matchInfos &&
                matchInfos.competition &&
                matchInfos.competition.name + " " + matchInfos.tour &&
                matchInfos.tour.phase &&
                matchInfos.tour.phase.format}{" "}
              {matchInfos && matchInfos.tour && matchInfos.name}
            </div>
          </div>
        </div>

        <div className="flex justify-evenly">
          {matchInfos && matchInfos.localization === "Home" ? (
            <div className="">
              <img
                className="mx-auto h-20 w-20 rounded-full 2xl:w-24 2xl:h-24"
                src={matchInfos && matchInfos.club && matchInfos.club.logo}
                alt="home team"
              />
              <div>{matchInfos && matchInfos.club && matchInfos.club.name}</div>
            </div>
          ) : (
            <div className="">
              <img
                className="mx-auto h-20 w-20 rounded-full 2xl:w-24 2xl:h-24"
                src={
                  matchInfos && matchInfos.opponent && matchInfos.opponent.logo
                }
                alt="home club"
              />
              <div>
                {matchInfos && matchInfos.opponent && matchInfos.opponent.name}
              </div>
            </div>
          )}

          <div className="flex flex-col justify-evenly">
            <div>
              <span
                className={
                  matchInfos && matchInfos.result === "Win"
                    ? "text-4xl p-1 text-green-600"
                    : matchInfos && matchInfos.result === "Loss"
                    ? "text-4xl p-1 text-red-600"
                    : "text-4xl p-1 text-yellow-400"
                }
              >
                {matchInfos && matchInfos.scoreFullTimeOpponentTeamDom}
              </span>
              <span className="text-4xl p-1">{" - "}</span>
              <span
                className={
                  matchInfos && matchInfos.result === "Win"
                    ? "text-4xl p-1 text-green-600"
                    : matchInfos && matchInfos.result === "Loss"
                    ? "text-4xl p-1 text-red-600"
                    : "text-4xl p-1 text-yellow-400"
                }
              >
                {matchInfos && matchInfos.scoreFullTimeOpponentTeamExt}
              </span>
              <div className="text-sm p-1 text-center">
                Mi-temps:{" "}
                {matchInfos && matchInfos.scoreHalfTimeOpponentTeamDom}
                {" - "}
                {matchInfos && matchInfos.scoreHalfTimeOpponentTeamExt}{" "}
              </div>
            </div>
          </div>
          {matchInfos && matchInfos.localization === "Away" ? (
            <div className="">
              <img
                className="mx-auto h-20 w-20 rounded-full 2xl:w-24 2xl:h-24"
                src={matchInfos && matchInfos.club && matchInfos.club.logo}
                alt="away team"
              />
              <div>{matchInfos && matchInfos.club && matchInfos.club.name}</div>
            </div>
          ) : (
            <div className="">
              <img
                className="mx-auto h-20 w-20 rounded-full 2xl:w-24 2xl:h-24"
                src={
                  matchInfos && matchInfos.opponent && matchInfos.opponent.logo
                }
                alt="away club"
              />
              <div>
                {matchInfos && matchInfos.opponent && matchInfos.opponent.name}
              </div>
            </div>
          )}
        </div>

        {matchInfos && matchInfos.localization === "Away" ? (
          <div className="flex-1 p-8 2xl:p-6 ">
            <div className="flex justify-between p-1">
              <div className="mx-8">
                {matchInfos &&
                  matchInfos.opponentStats &&
                  matchInfos.opponentStats.transformations}
              </div>
              <div className="mx-8">Transformations Réussies</div>
              <div className="mx-8">
                {matchInfos &&
                  matchInfos.teamStats &&
                  matchInfos.teamStats.transformations}
              </div>
            </div>
            <div className="flex justify-between p-1">
              <div className="mx-8">
                {matchInfos &&
                  matchInfos.opponentStats &&
                  matchInfos.opponentStats.penalities}
              </div>
              <div className="mx-8">Pénalités Réussies</div>
              <div className="mx-8">
                {matchInfos &&
                  matchInfos.teamStats &&
                  matchInfos.teamStats.penalities}
              </div>
            </div>
            <div className="flex justify-between p-1">
              <div className="mx-8">
                {matchInfos &&
                  matchInfos.opponentStats &&
                  matchInfos.opponentStats.drops}
              </div>
              <div className="mx-8">Drops Réussies</div>
              <div className="mx-8">
                {matchInfos &&
                  matchInfos.teamStats &&
                  matchInfos.teamStats.drops}
              </div>
            </div>
            <div className="flex justify-between p-1">
              <div className="mx-8">
                {matchInfos &&
                  matchInfos.opponentStats &&
                  matchInfos.opponentStats.cardsY}
              </div>
              <div className="mx-8">Cartons Jaunes</div>
              <div className="mx-8">
                {matchInfos &&
                  matchInfos.teamStats &&
                  matchInfos.teamStats.cardsY}
              </div>
            </div>
            <div className="flex justify-between p-1">
              <div className="mx-8">
                {matchInfos &&
                  matchInfos.opponentStats &&
                  matchInfos.opponentStats.cardsR}
              </div>
              <div className="mx-8">Cartons Rouge</div>
              <div className="mx-8">
                {matchInfos &&
                  matchInfos.teamStats &&
                  matchInfos.teamStats.cardsR}
              </div>
            </div>
          </div>
        ) : (
          <div className="flex-1 p-8 2xl:p-6 ">
            <div className="flex justify-between p-1">
              <div className="mx-8">
                {matchInfos &&
                  matchInfos.teamStats &&
                  matchInfos.teamStats.transformations}
              </div>
              <div className="mx-8">Transformations Réussies</div>
              <div className="mx-8">
                {matchInfos &&
                  matchInfos.opponentStats &&
                  matchInfos.opponentStats.transformations}
              </div>
            </div>
            <div className="flex justify-between p-1">
              <div className="mx-8">
                {matchInfos &&
                  matchInfos.teamStats &&
                  matchInfos.teamStats.penalities}
              </div>
              <div className="mx-8">Pénalités Réussies</div>
              <div className="mx-8">
                {matchInfos &&
                  matchInfos.opponentStats &&
                  matchInfos.opponentStats.penalities}
              </div>
            </div>
            <div className="flex justify-between p-1">
              <div className="mx-8">
                {matchInfos &&
                  matchInfos.teamStats &&
                  matchInfos.teamStats.drops}
              </div>
              <div className="mx-8">Drops Réussies</div>
              <div className="mx-8">
                {matchInfos &&
                  matchInfos.opponentStats &&
                  matchInfos.opponentStats.drops}
              </div>
            </div>
            <div className="flex justify-between p-1">
              <div className="mx-8">
                {matchInfos &&
                  matchInfos.teamStats &&
                  matchInfos.teamStats.cardsY}
              </div>
              <div className="mx-8">Cartons Jaunes</div>
              <div className="mx-8">
                {matchInfos &&
                  matchInfos.opponentStats &&
                  matchInfos.opponentStats.cardsY}
              </div>
            </div>
            <div className="flex justify-between p-1">
              <div className="mx-8">
                {matchInfos &&
                  matchInfos.teamStats &&
                  matchInfos.teamStats.cardsR}
              </div>
              <div className="mx-8">Cartons Rouge</div>
              <div className="mx-8">
                {matchInfos &&
                  matchInfos.opponentStats &&
                  matchInfos.opponentStats.cardsR}
              </div>
            </div>
          </div>
        )}
      </div>
    </Card>
  );
}

export default MatchGeneralHeaderRugby;
