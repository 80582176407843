import React from "react";
import Card from "../../components/card/Card";
import ButtonLight from "../../components/buttons/ButtonLight";
import GeneratedForm from "../../utils/forms/generateForm";
import RugbyField from "./FieldsImage/RugbyField.png";
import { Link } from "react-router-dom";

function MatchComposition({ t, infoByPlayer, matchInfos, match }) {
  const getMatchSheetsPlayersSubstitutes = (infoByPlayer) =>
    infoByPlayer && infoByPlayer.filter((info) => info.position.number >= 16);

  const renderPlayersSubstitutes = (infoByPlayer) =>
    getMatchSheetsPlayersSubstitutes(infoByPlayer).map((info) => {
      return (
        <div>
          <Link
            to={{
              pathname: `/competitionRugby/playerInfoByMatch/${info.matchId}/${info.player.id}`,
            }}
          >
            <span className="hover:bg-blue-200 py-1 px-3 rounded-full text-sm">
              {info && info.position && info.position.number + " - "}
              {info && info.player && info.player.lastName}
            </span>
          </Link>
        </div>
      );
    });

  const renderStaff = () => {
    const staffs = matchInfos && matchInfos.staffs;
    return (
      staffs &&
      staffs.length > 0 &&
      staffs.map((staff) => {
        return (
          <div className="py-1 px-3 rounded-full text-sm">
            {staff.title + " : "}
            {staff.displayName}
          </div>
        );
      })
    );
  };

  const renderPlayerCard = (positionNumber, infoByPlayer) => {
    const player = findPlayerPosition(positionNumber, infoByPlayer);
    return (
      <div className="w-14 h-14 text-black">
        <Link
          to={{
            pathname: `/competitionRugby/playerInfoByMatch/${match.params.id}/${
              player && player.id
            }`,
          }}
        >
          <img className="rounded-full" src={player && player.photo} alt="" />
          {player && player.lastName}
        </Link>
      </div>
    );
  };

  const findPlayerPosition = (position, infoByPlayer) => {
    if (!infoByPlayer) return {};
    const infoPosition = infoByPlayer.find(
      (info) => info && info.position && info.position.number === position
    );
    return infoPosition && infoPosition.player;
  };

  return (
    <div className="col-span-1 mx-6 my-3 flex justify-center ">
      <Card
        className=""
        title="Composition"
        headerActions={
          <GeneratedForm
            key="updateCommentPlayer"
            component={(show, text) => (
              <ButtonLight onClick={show} disabled={!navigator.onLine}>
                {t("Edit")}
              </ButtonLight>
            )}
            formName="updateCommentPlayer"
          />
        }
      >
        <div
          style={{
            backgroundImage: `url(/${RugbyField})`,
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            width: "400px",
            height: "600px",
            padding: "25px",
            margin: "25px",
          }}
        >
          <div className=" flex flex-col justify-around h-full">
            <div className="flex justify-between">
              {renderPlayerCard(1, infoByPlayer)}
              {renderPlayerCard(2, infoByPlayer)}
              {renderPlayerCard(3, infoByPlayer)}
            </div>
            <div className="flex justify-around">
              {renderPlayerCard(4, infoByPlayer)}
              {renderPlayerCard(5, infoByPlayer)}
            </div>
            <div className="flex justify-between">
              {renderPlayerCard(6, infoByPlayer)}
              {renderPlayerCard(8, infoByPlayer)}
              {renderPlayerCard(7, infoByPlayer)}
            </div>
            <div className="flex justify-around">
              <div></div>
              {renderPlayerCard(9, infoByPlayer)}
              <div></div>
              {renderPlayerCard(10, infoByPlayer)}
              <div></div>
            </div>
            <div className="flex justify-around">
              {renderPlayerCard(12, infoByPlayer)}
              {renderPlayerCard(13, infoByPlayer)}
            </div>
            <div className="flex justify-between">
              {renderPlayerCard(11, infoByPlayer)}
              <div></div>
              {renderPlayerCard(14, infoByPlayer)}
            </div>
            <div className="flex justify-between">
              <div></div>
              {renderPlayerCard(15, infoByPlayer)}
              <div></div>
            </div>
          </div>
        </div>
        <div className="max-w-lg w-full rounded-lg shadow-lg p-4 mt-10">
          <h3 className="font-semibold text-lg text-gray-700 tracking-wide">
            Remplaçants:
          </h3>
          {infoByPlayer && renderPlayersSubstitutes(infoByPlayer)}
        </div>
        {matchInfos && matchInfos.staffs && matchInfos.staffs.length > 0 && (
          <div className="max-w-lg w-full rounded-lg shadow-lg p-4 mt-10">
            <h3 className="font-semibold text-lg text-gray-700 tracking-wide">
              Staffs:
            </h3>
            {renderStaff()}
          </div>
        )}
      </Card>
    </div>
  );
}

export default MatchComposition;
