import React from "react";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import {
    getContactsByPlayer,
    getPersonalDocumentsByPlayer,
    getRecord,
    loadContactsByPlayer,
    loadContactsTypes,
    loadPersonalDocumentsByPlayer,
    loadRecord,
} from "../../utils/indexedDB/administration/handleAdministration";
import Card from "../../components/card/Card";
import GeneratedForm from "../../utils/forms/generateForm";
import ActionButton from "../../components/buttons/ActionButton";
import moment from "moment";
import TablePlayerContacts from "../../tables/TablePlayerContacts";
import ButtonLight from "../../components/buttons/ButtonLight";
import SVGCreate from "../../components/icons/SVGCreate";
import SVGEdit from "../../components/icons/SVGEdit";
import DisplayData2cols from "../../components/displayData/DisplayData2cols";


class StaffAdministrationTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.id,
            administrationTab: "Personal file",
        };
        this.updateContacts = this.updateContacts.bind(this);
    }

    componentDidMount() {
        var id = this.props.id;
        getRecord(id).then((record) => (record ? this.setState({ record }) : null));
        getContactsByPlayer(id).then((contacts) =>
            contacts ? this.setState({ contacts }) : null
        );
        getPersonalDocumentsByPlayer(id).then((personalDocuments) =>
            this.setState({ personalDocuments })
        );

        if (navigator.onLine) {
            loadRecord(id).then((record) => this.setState({ record }));
            loadContactsTypes().then((contactsTypes) =>
                this.setState({ contactsTypes })
            );
            loadContactsByPlayer(id).then((contacts) => this.setState({ contacts }));
            loadPersonalDocumentsByPlayer(id).then((personalDocuments) =>
                this.setState({ personalDocuments })
            );
        }
    }

    forceUpdateContact() {
        if (navigator.onLine) {
            loadContactsByPlayer(this.props.id).then((contacts) =>
                this.setState({ contacts })
            );
        }
    }

    updateContacts(id) {
        loadContactsByPlayer(id).then((contacts) =>
            this.setState({ contacts })
        );
    }

    forceUpdateRecord() {
        if (navigator.onLine) {
            loadRecord(this.props.id).then((record) =>
                this.setState({ record })
            );
        }
    }

    render() {
        const { t } = this.props;
        return (
            <>
                {this.state.administrationTab === "Personal file" &&
                    this.state.record ? (
                        <>
                            <div className="grid grid-cols-2 gap-4">
                                <div className="col-span-1 mx-6 my-5">
                                    <Card
                                        className=""
                                        title={t("Personal file")}
                                        headerActions={
                                            <GeneratedForm
                                                key="updateStaffRecord"
                                                component={(show, text) => (
                                                    <ButtonLight
                                                        onClick={show}
                                                        disabled={!navigator.onLine}
                                                    >
                                                        <SVGEdit />
                                                    </ButtonLight>
                                                )}
                                                callback={(res) => this.forceUpdateRecord()}
                                                formName="updateStaffRecord"
                                                formData={this.state.record}
                                            />
                                        }
                                    >
                                        <DisplayData2cols 
                                            data={[
                                                {key:t("Birth city"), value: this.state.record.birthCity},
                                                {key:t("Birth department"), value: this.state.record.birthDepartment},
                                                {key:t("Birth country"), value: this.state.record.birthCountryId},
                                                {key:t("Address"), value: this.state.record.address},
                                                {key:t("Address 2"), value: this.state.record.address2},
                                                {key:t("Postal code"), value: this.state.record.postalCode},
                                                {key:t("City"), value: this.state.record.city},
                                                {key:t("Home country"), value: this.state.record.homeCountryId},
                                                {key:t("Marital status"), value: this.state.record.maritalStatus ? this.state.record.maritalStatus.name : ""},
                                                {key:t("Allergies"), value: this.state.record.allergies},
                                            ]}
                                        />
                                    </Card>
                                </div>
                                <div className="mx-6 my-5">
                                    <Card
                                        className=" "
                                        title={t("Documents")}
                                        headerActions={
                                            <GeneratedForm
                                                key="createScholarshipDocument"
                                                component={(show, text) => (
                                                    <ButtonLight onClick={show} disabled={!navigator.onLine}>
                                                        <SVGCreate />
                                                    </ButtonLight>
                                                )}
                                                callback={(res) =>
                                                    loadPersonalDocumentsByPlayer(
                                                        this.state.id
                                                    ).then((personalDocuments) =>
                                                        this.setState({ personalDocuments })
                                                    )
                                                }
                                                formName="createPersonalDocument"
                                                formData={{ playerId: this.state.id }}
                                            />
                                        }
                                    >
                                        <div className="">
                                            <ul className="divide-y divide-gray-200 h-96 overflow-auto">
                                                {this.state.personalDocuments
                                                    ? this.state.personalDocuments.map(
                                                        (personalDocument, key) => (
                                                            <li key={key}>
                                                                <div className="block hover:bg-gray-50">
                                                                    <div className="flex items-center px-4 py-4 sm:px-6">
                                                                        <div className="min-w-0 flex-1 flex items-center">
                                                                            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                                                                                <div>
                                                                                    <p className="text-xs 2xl:text-sm font-medium text-indigo-600 truncate">
                                                                                        <a href={personalDocument.filePath}>
                                                                                            {personalDocument.name}
                                                                                        </a>
                                                                                    </p>
                                                                                    <p className="mt-1 2xl:mt-2 flex items-center text-xs 2xl:text-sm text-gray-500">
                                                                                        <span className="truncate">
                                                                                            {moment(personalDocument.date).calendar()}
                                                                                        </span>
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <ActionButton
                                                                                key={personalDocument.id}
                                                                                updateForm="updatePersonalDocument"
                                                                                deleteForm="deletePersonalDocument"
                                                                                formData={personalDocument}
                                                                                refreshData={() =>
                                                                                    loadPersonalDocumentsByPlayer(
                                                                                        this.state.id
                                                                                    ).then((personalDocuments) =>
                                                                                        this.setState({ personalDocuments })
                                                                                    )
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        )
                                                    )
                                                    : ""}
                                            </ul>
                                        </div>
                                    </Card>
                                </div>
                            </div>
                            <TablePlayerContacts
                                contacts={this.state.contacts}
                                buttons={[
                                    <GeneratedForm
                                        key="createContact"
                                        component={(show, text) => (
                                            <ButtonLight
                                                onClick={show}
                                                disabled={!navigator.onLine}
                                            >
                                                <i className="flaticon2-plus"></i> {text}
                                            </ButtonLight>
                                        )}
                                        callback={(res) => this.forceUpdateContact()}
                                        formName="createContact"
                                        formData={{ playerId: this.props.id }}
                                    />,
                                ]}
                                updateContacts={this.updateContacts}
                                pId={this.props.id}
                            />
                        </>
                    ) : (
                        ""
                    )}
            </>
        );
    }
}

export default withTranslation()(withRouter(StaffAdministrationTab));
