import React from "react";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import ChartTennisRankingsPoints from "../../charts/ChartTennisRankingsPoints";
import ChartTennisRankingsRank from "../../charts/ChartTennisRankingsRank";
import Card from "../../components/card/Card";
import { Select } from "../../components/forms";
import EventForm from "../../utils/forms/events/EventForm";
import Button from "../../components/buttons/Button";
import WidgetTennisCompetitionKeyFigures from "../../components/widgets/WidgetTennisCompetitionKeyFigures";
import {
  getInternationalProgrammationRemaining,
  getMatches,
  getNationalProgrammationRemaining,
  getProgrammation,
  getResults,
  getResultsNumber,
  loadCircuits,
  loadInternationalProgrammationRemaining,
  loadMatches,
  loadNationalProgrammationRemaining,
  loadProgrammation,
  loadRankingsATPByPlayer,
  loadRankingsITFFByPlayer,
  loadRankingsITFHByPlayer,
  loadRankingsITFJFByPlayer,
  loadRankingsITFJHByPlayer,
  loadRankingsTennisEuropeU14FByPlayer,
  loadRankingsTennisEuropeU14HByPlayer,
  loadRankingsWTAByPlayer,
  // getRankingsATPByPlayer,
  // getRankingsITFFByPlayer,
  // getRankingsITFHByPlayer,
  // getRankingsITFJFByPlayer,
  // getRankingsITFJHByPlayer,
  // getRankingsTennisEuropeU14FByPlayer,
  // getRankingsTennisEuropeU14HByPlayer,
  // getRankingsWTAByPlayer,
  loadResults,
  addEngagement,
  loadRankingsATPDblByPlayer,
  loadRankingsWTADblByPlayer,
} from "../../utils/indexedDB/Tennis-Competition/handleCompetitionTennis";
import moment from "moment";
import Loading from "../../components/loaders/Loading";

import SubTab from "../../components/tab/SubTab";
import TablePlayerTournamentsResults from "../../tables/TablePlayerTournamentsResults";
import TablePlayerProgrammation from "../../tables/TablePlayerProgrammation";
import ListTennisMatches from "../../lists/ListTennisMatches";
import {
  getTennisClubs,
  loadTennisClubs,
} from "../../utils/indexedDB/core/handleCore";

class PlayerCompetitionTennisTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.playerId,
      player: null,
      competitionTab: "Results",
      year: parseInt(moment().format("YYYY")),
      month: parseInt(moment().format("M")),
      loadRanking: true,
    };
    this.addEngagement = addEngagement.bind(this);
    this.updateProg = this.updateProg.bind(this);
    this.updateAddTournament = this.updateAddTournament.bind(this);
    this.handleProgrammation = this.handleProgrammation.bind(this);
    this.handleProgrammationRemaining = this.handleProgrammationRemaining.bind(this)
  }
  componentDidMount() {
    // get id from url
    var id = this.props.playerId;

    //initialise real programmation (i=0) and options (i=1, i=2)
    for (let i = 0; i < 3; i++)
      this.handleProgrammation(id, i, this.state.month, this.state.year)

    // function get from indexedDB
    getResults(id).then((results) =>
      this.setState({ results, numbers: getResultsNumber(results) })
    );
    getMatches(id).then((matches) => this.setState({ matches }));
    if (navigator.onLine) {
      //function get from database
      loadCircuits();

      var internationalRankings = [];
      if (this.props.player && this.props.player.tennisOpponentId) {
        loadRankingsATPByPlayer(this.props.player.tennisOpponentId).then(
          (rankingsATP) => {
            this.setState({ rankingsATP });
            if (rankingsATP && rankingsATP.length > 0) {
              internationalRankings.push({ key: "ATP", label: "ATP" });
              if (!this.state.internationalRankingsView) {
                this.setState({
                  internationalRankingsView: internationalRankings[0].key,
                });
              }
            }
          }
        );
        loadRankingsATPDblByPlayer(this.props.player.tennisOpponentId).then(
          (rankingsATPDbl) => {
            this.setState({ rankingsATPDbl });
            if (rankingsATPDbl && rankingsATPDbl.length > 0) {
              internationalRankings.push({ key: "ATP Double", label: "ATP Double" });
              if (!this.state.internationalRankingsView) {
                this.setState({
                  internationalRankingsView: internationalRankings[0].key,
                });
              }
            }
          }
        );
        loadRankingsWTAByPlayer(this.props.player.tennisOpponentId).then(
          (rankingsWTA) => {
            this.setState({ rankingsWTA });
            if (rankingsWTA && rankingsWTA.length > 0) {
              internationalRankings.push({ key: "WTA", label: "WTA" });
              if (!this.state.internationalRankingsView) {
                this.setState({
                  internationalRankingsView: internationalRankings[0].key,
                });
              }
            }
          }
        );
        loadRankingsWTADblByPlayer(this.props.player.tennisOpponentId).then(
          (rankingsWTADbl) => {
            this.setState({ rankingsWTADbl });
            if (rankingsWTADbl && rankingsWTADbl.length > 0) {
              internationalRankings.push({ key: "WTA Double", label: "WTA Double" });
              if (!this.state.internationalRankingsView) {
                this.setState({
                  internationalRankingsView: internationalRankings[0].key,
                });
              }
            }
          }
        );
        loadRankingsITFHByPlayer(this.props.player.tennisOpponentId).then(
          (rankingsITFH) => {
            this.setState({ rankingsITFH });
            if (rankingsITFH && rankingsITFH.length > 0) {
              internationalRankings.push({ key: "ITF H", label: "ITF H" });
              if (!this.state.internationalRankingsView) {
                this.setState({
                  internationalRankingsView: internationalRankings[0].key,
                });
              }
            }
          }
        );
        loadRankingsITFFByPlayer(this.props.player.tennisOpponentId).then(
          (rankingsITFF) => {
            this.setState({ rankingsITFF });
            if (rankingsITFF && rankingsITFF.length > 0) {
              internationalRankings.push({ key: "ITF F", label: "ITF F" });
              if (!this.state.internationalRankingsView) {
                this.setState({
                  internationalRankingsView: internationalRankings[0].key,
                });
              }
            }
          }
        );
        loadRankingsITFJHByPlayer(this.props.player.tennisOpponentId).then(
          (rankingsITFJH) => {
            this.setState({ rankingsITFJH });
            if (rankingsITFJH && rankingsITFJH.length > 0) {
              internationalRankings.push({
                key: "ITF Junior H",
                label: "ITF Junior H",
              });
              if (!this.state.internationalRankingsView) {
                this.setState({
                  internationalRankingsView: internationalRankings[0].key,
                });
              }
            }
          }
        );
        loadRankingsITFJFByPlayer(this.props.player.tennisOpponentId).then(
          (rankingsITFJF) => {
            this.setState({ rankingsITFJF });
            if (rankingsITFJF && rankingsITFJF.length > 0) {
              internationalRankings.push({
                key: "ITF Junior F",
                label: "ITF Junior F",
              });
              if (!this.state.internationalRankingsView) {
                this.setState({
                  internationalRankingsView: internationalRankings[0].key,
                });
              }
            }
          }
        );
        loadRankingsTennisEuropeU14HByPlayer(id).then(
          (rankingsTennisEuropeU14H) => {
            this.setState({ rankingsTennisEuropeU14H });
            if (
              rankingsTennisEuropeU14H &&
              rankingsTennisEuropeU14H.length > 0
            ) {
              internationalRankings.push({
                key: "Tennis Europe U14 H",
                label: "Tennis Europe U14 H",
              });
              if (!this.state.internationalRankingsView) {
                this.setState({
                  internationalRankingsView: internationalRankings[0].key,
                });
              }
            }
          }
        );
        loadRankingsTennisEuropeU14FByPlayer(id).then(
          (rankingsTennisEuropeU14F) => {
            this.setState({ rankingsTennisEuropeU14F });
            if (
              rankingsTennisEuropeU14F &&
              rankingsTennisEuropeU14F.length > 0
            ) {
              internationalRankings.push({
                key: "Tennis Europe U14 F",
                label: "Tennis Europe U14 F",
              });
              if (!this.state.internationalRankingsView) {
                this.setState({
                  internationalRankingsView: internationalRankings[0].key,
                });
              }
            }
          }
        );
        this.setState({ internationalRankings });
        if (internationalRankings.length > 0) {
          this.setState({
            internationalRankingsView: internationalRankings[0].key,
          });
        }
      }
      this.setState({
        loadRanking: false,
      });
      loadResults(id).then((results) => {
        this.setState({ results, numbers: getResultsNumber(results) })
      }
      );
      loadMatches(id).then((matches) => this.setState({ matches }));
    }
  }

  handleProgrammation(id, option, month, year) {
    let o = option === 0 ? "" : option
    let O = option === 0 ? "" : "Option" + option
    getProgrammation(id, o).then((prog) => {
      if (prog) this.setState({ ["prog" + O]: prog, loading: false });
      this.handleProgrammationRemaining(id, option, month, year, o, "get")
    });

    if (navigator.onLine) {
      loadProgrammation(id, o).then((prog) => {
        this.setState({ ["prog" + O]: prog, loading: false });
        this.handleProgrammationRemaining(id, option, month, year, o, "load")
      });
    }
  }

  handleProgrammationRemaining(id, option, month, year, o, getOrLoad = "") {
    if (getOrLoad === "get" || getOrLoad === "") {
      getInternationalProgrammationRemaining(
        month,
        year,
        id,
        option
      ).then((competitionInt) => this.setState({ ["competitionInt" + o]: competitionInt }));
      getNationalProgrammationRemaining(
        month,
        year,
        id,
        option
      ).then((competitionNat) => {
        getTennisClubs().then(clubs => {
          if (clubs && clubs.length && competitionNat && competitionNat.length > 0) {
            competitionNat = competitionNat.map((t) => {
              t.tournamentTypeId = "National";
              t.localisation = t.city;
              if (clubs && clubs.find(o => o.id === t.clubId)) {
                t.club = clubs.find(o => o.id === t.clubId);
              }
              return t;
            });
            this.setState({ ["competitionNat" + o]: competitionNat })
          }
        });
      });
    }

    if (navigator.onLine && (getOrLoad === "load" || getOrLoad === "")) {
      loadInternationalProgrammationRemaining(
        month,
        year,
        id,
        option
      ).then((competitionInt) => this.setState({ ["competitionInt" + o]: competitionInt }));
      loadNationalProgrammationRemaining(
        month,
        year,
        id,
        option
      ).then((competitionNat) => {
        loadTennisClubs().then(clubs => {
          if (clubs && clubs.length && competitionNat && competitionNat.length > 0) {
            competitionNat = competitionNat.map((t) => {
              t.tournamentTypeId = "National";
              t.localisation = t.city;
              if (clubs && clubs.find(o => o.id === t.clubId)) {
                t.club = clubs.find(o => o.id === t.clubId);
              }
              return t;
            });
            this.setState({ ["competitionNat" + o]: competitionNat })
          }
        });
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.player !== this.props.player) {
      this.setState({ player: this.props.player });
    }
  }

  updateProg(id, progView) {
    var progNumber =
      progView === "Real"
        ? 0
        : progView === "Option 1"
          ? 1
          : progView === "Option 2"
            ? 2
            : 0;
    this.handleProgrammation(id, progNumber, this.state.month, this.state.year)
  }

  updateAddTournament(month, year) {
    this.setState({ loading: true });
    if (month === 0) {
      month = 12;
      year -= 1;
    } else if (month === 13) {
      month = 1;
      year += 1;
    }
    this.setState({ month: month, year: year });
    for (let option = 0; option < 3; option++) {
      let o = (option === 0 ? "" : option)
      this.handleProgrammationRemaining(this.state.id, option, month, year, o)
    }
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <SubTab
          tab={[
            { key: "Results", label: t("Results") },
            { key: "Rankings", label: t("Rankings") },
            { key: "Programmation", label: t("Programmation") },
            { key: "Matches", label: t("Matches") },
          ]}
          selectedTab={this.state.competitionTab}
          onChange={(key) =>
            this.setState({
              competitionTab: key,
            })
          }
        />
        {this.state.prog && this.state.competitionTab === "Programmation" ? (
          <div>
            <Card>
              <TablePlayerProgrammation
                programmation={this.state.prog}
                programmation1={this.state.progOption1}
                programmation2={this.state.progOption2}
                national={this.state.competitionNat}
                national1={this.state.competitionNat1}
                national2={this.state.competitionNat2}
                international={this.state.competitionInt}
                international1={this.state.competitionInt1}
                international2={this.state.competitionInt2}
                updateProg={this.updateProg}
                id={this.state.id}
                addEngagement={this.addEngagement}
                onChange={this.updateAddTournament}
              />
            </Card>
          </div>
        ) : (
          <></>
        )}
        {this.state.results && this.state.competitionTab === "Results" ? (
          <div>
            <div className="flex">
              <div className="w-1/2 py-4 px-10">
                <WidgetTennisCompetitionKeyFigures
                  title={t("Track record") + " - " + t("Simple")}
                  data={this.state.results.filter((o) => o.mode === "Simple")}
                />
              </div>
              <div className="w-1/2 py-4 px-10">
                <WidgetTennisCompetitionKeyFigures
                  title={t("Track record") + " - " + t("Double")}
                  data={this.state.results.filter((o) => o.mode === "Double")}
                />
              </div>
            </div>
            <TablePlayerTournamentsResults results={this.state.results} />
          </div>
        ) : (
          <></>
        )}
        {this.state.matches &&
          this.props.player &&
          this.state.competitionTab === "Matches" ? (
          <div>
            <ListTennisMatches
              tournaments={this.state.matches}
              player={this.props.player}
              buttons={[
                <EventForm
                  component={(show, text) => (
                    <Button onClick={show} disabled={!navigator.onLine}>
                      <i className="flaticon2-plus"></i> {t("Create")}
                    </Button>
                  )}
                  callback={(res) => this.forceUpdate()}
                  method="POST"
                  formData={{
                    attendees: [this.state.id],
                    playerId: this.state.id,
                    typeId: "Match tennis",
                    groupId: this.props.player
                      ? this.props.player.group1Id
                      : null,
                  }}
                />,
              ]}
            />
          </div>
        ) : (
          <></>
        )}
        {this.state.competitionTab === "Rankings" ? (
          <div>
            <Card
              title={t("Rankings")}
              headerActions={
                <Select
                  value={this.state.internationalRankingsView}
                  classPerso="btn btn-light-primary font-weight-bolder dropdown-toggle mr-5"
                  options={this.state.internationalRankings}
                  onChange={(key) =>
                    this.setState({
                      internationalRankingsView: key,
                    })
                  }
                />
              }
            >
              {this.state.loadRanking === false ? (
                this.state.internationalRankingsView ? (
                  <>
                    {this.state.internationalRankingsView === "ATP" &&
                      this.state.rankingsATP ? (
                      <>
                        <ChartTennisRankingsRank
                          title="Rang ATP"
                          data={this.state.rankingsATP}
                        />
                        <ChartTennisRankingsPoints
                          title="Points ATP"
                          data={this.state.rankingsATP}
                        />
                      </>
                    ) : (
                      ""
                    )}
                    {this.state.internationalRankingsView === "ATP Double" &&
                      this.state.rankingsATPDbl ? (
                      <>
                        <ChartTennisRankingsRank
                          title="Rang ATP Double"
                          data={this.state.rankingsATPDbl}
                        />
                        <ChartTennisRankingsPoints
                          title="Points ATP Double"
                          data={this.state.rankingsATPDbl}
                        />
                      </>
                    ) : (
                      ""
                    )}
                    {this.state.internationalRankingsView === "WTA" &&
                      this.state.rankingsWTA ? (
                      <>
                        <ChartTennisRankingsRank
                          title="Rang WTA"
                          data={this.state.rankingsWTA}
                        />
                        <ChartTennisRankingsPoints
                          title="Points WTA"
                          data={this.state.rankingsWTA}
                        />
                      </>
                    ) : (
                      ""
                    )}
                    {this.state.internationalRankingsView === "WTA Double" &&
                      this.state.rankingsWTADbl ? (
                      <>
                        <ChartTennisRankingsRank
                          title="Rang WTA Double"
                          data={this.state.rankingsWTADbl}
                        />
                        <ChartTennisRankingsPoints
                          title="Points WTA Double"
                          data={this.state.rankingsWTADbl}
                        />
                      </>
                    ) : (
                      ""
                    )}
                    {this.state.internationalRankingsView === "ITF H" &&
                      this.state.rankingsITFH ? (
                      <>
                        <ChartTennisRankingsRank
                          title="Rang ITF H"
                          data={this.state.rankingsITFH}
                        />
                        <ChartTennisRankingsPoints
                          title="Points ITF H"
                          data={this.state.rankingsITFH}
                        />
                      </>
                    ) : (
                      ""
                    )}
                    {this.state.internationalRankingsView === "ITF F" &&
                      this.state.rankingsITFF ? (
                      <>
                        <ChartTennisRankingsRank
                          title="Rang ITF F"
                          data={this.state.rankingsITFF}
                        />
                        <ChartTennisRankingsPoints
                          title="Points ITF F"
                          data={this.state.rankingsITFF}
                        />
                      </>
                    ) : (
                      ""
                    )}
                    {this.state.internationalRankingsView === "ITF Junior H" &&
                      this.state.rankingsITFJH ? (
                      <>
                        <ChartTennisRankingsRank
                          title="Rang ITF Junior H"
                          data={this.state.rankingsITFJH}
                        />
                        <ChartTennisRankingsPoints
                          title="Points ITF Junior H"
                          data={this.state.rankingsITFJH}
                        />
                      </>
                    ) : (
                      ""
                    )}
                    {this.state.internationalRankingsView === "ITF Junior F" &&
                      this.state.rankingsITFJF ? (
                      <>
                        <ChartTennisRankingsRank
                          title="Rang ITF Junior F"
                          data={this.state.rankingsITFJF}
                        />
                        <ChartTennisRankingsPoints
                          title="Points ITF Junior F"
                          data={this.state.rankingsITFJF}
                        />
                      </>
                    ) : (
                      ""
                    )}
                    {this.state.internationalRankingsView ===
                      "Tennis Europe U14 H" &&
                      this.state.rankingsTennisEuropeU14H ? (
                      <>
                        <ChartTennisRankingsRank
                          title="Rang Tennis Europe U14 H"
                          data={this.state.rankingsTennisEuropeU14H}
                        />
                        <ChartTennisRankingsPoints
                          title="Points ITF H"
                          data={this.state.rankingsTennisEuropeU14H}
                        />
                      </>
                    ) : (
                      ""
                    )}
                    {this.state.internationalRankingsView ===
                      "Tennis Europe U14 F" &&
                      this.state.rankingsTennisEuropeU14F ? (
                      <>
                        <ChartTennisRankingsRank
                          title="Rang Tennis Europe U14 F"
                          data={this.state.rankingsTennisEuropeU14F}
                        />
                        <ChartTennisRankingsPoints
                          title="Points Tennis Europe U14 F"
                          data={this.state.rankingsTennisEuropeU14F}
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  <div className="rounded-md bg-blue-50 p-4 my-3 mx-6">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <svg className="h-5 w-5 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                        </svg>
                      </div>
                      <div className="ml-3 flex-1 md:flex md:justify-between">
                        <p className="text-sm text-blue-700">
                          {t("Either the player is not registered for a ranking category, either you are currently offline")}
                        </p>
                      </div>
                    </div>
                  </div>

                )
              ) : (
                <Loading />
              )}
            </Card>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }
}

export default withTranslation()(withRouter(PlayerCompetitionTennisTab));
