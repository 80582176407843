import React from "react";
import IconRound32x32 from "../../icons/IconRound32x32";

class CardUser extends React.Component {
  render() {
    return (
      <span
        style={{
          display: "flex",
          cursor: "pointer",
          width: "200px"
        }} {...this.props}
      >
        <span>
          <IconRound32x32 photo={this.props.photo} />
        </span>
        <span
          style={{
            display: "flex",
            flexWrap: "wrap",
            textAlign: "start",
            flex: "1 1",
            marginLeft: "15px",
          }}
        >
          <span
            style={{
              display: "flex",
              flexDirection: "column",
              flex: "1 1",
              marginRight: ".5rem",
            }}
          >
            <span
              className="text-primary"
            >
              {this.props.title}
            </span>
            <span className="kt-widget__desc text-muted">
              {this.props.subtitle}
            </span>
          </span>
        </span>
      </span>

    );
  }
}

export default CardUser;
