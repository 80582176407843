import db from "./db";
import get from "../../requests/get";

async function loadAnnounces() {
    return get("communication", "announces").then((res) => {
        if (res) {
            // res = res.map((ann) => {
            //     return ann;
            // });
            db.annonces.bulkPut(res);
        }
        return res;
    });
}

function getAnnounces(Id) {
    if (db.annonces.toArray()) {
        return db.annonces
            //   .where({ kind: Id })
            .toArray()
        //   .then((targets) => {
        //     return targets.sort((a, b) =>
        //       moment(a.startDate) === moment(b.startDate)
        //         ? 0
        //         : moment(a.startDate) > moment(b.startDate)
        //         ? 1
        //         : -1
        //     );
        //   });
    }
}

export { loadAnnounces, getAnnounces };
