import getAccessToken from "../auth/getAccessToken";
import { getSASTokenAzureBlobStorage, uploadFile } from "../azure-blobstorage/functions";

export default async function post(APImodule, APIfeature, data, blobStorage, uploadDataPercentageFunction) {
  if (typeof data === "string") {
    return getAccessToken().then((accessToken) => fetch(
      window.location.origin + "/api/v1.0/" + APImodule + "/" + APIfeature + "/" + data,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "my-Authorization": `Bearer ${accessToken}`
        }
      }
    ))
  } else {
    if (blobStorage && blobStorage.domain && blobStorage.container) {
      var { domain, container } = blobStorage;
      return getSASTokenAzureBlobStorage(domain).then(azureBlobStorageToken => {
        if (azureBlobStorageToken) {
          var file = data.file;
          if (domain === "storageamsexaduosport" && container === "uploadedvideos") {
            delete data.file;
            data.filePath = "." + (file.type && file.type.replace(/^.*\//g, '') ? file.type.replace(/^.*\//g, '') : file.name.substring(file.name.lastIndexOf('.') + 1));
            return getAccessToken().then((accessToken) =>
              fetch(
                window.location.origin + "/api/v1.0/" + APImodule + "/" + APIfeature,
                {
                  method: "POST",
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "my-Authorization": `Bearer ${accessToken}`,
                  },
                  body: JSON.stringify(data),
                }
              )).then(res => {
                if (res.ok) {
                  return res.json()
                } else {
                  throw new Error("No result");
                }
              }).then(video => {
                if (video.id) {
                  data.id = video.id;
                  data.filePath = video.filePath;
                  data.file = file;
                  return uploadFile(azureBlobStorageToken, data, domain, container, uploadDataPercentageFunction).then(res => {
                    return getAccessToken().then((accessToken) =>
                      fetch(
                        window.location.origin + "/api/v1.0/" + APImodule + "/" + APIfeature + "/reupload/" + video.id,
                        {
                          method: "PATCH",
                          headers: {
                            Accept: "application/json",
                            "my-Authorization": `Bearer ${accessToken}`
                          }
                        }
                      ))
                  })
                } else {
                  throw new Error("No id on result");
                }
              })
          } else {
            if(domain === "exaduosport" && container === "exercices") {
              data.filePath = data.clubId + "/" + data.file.name;
            }
            else if(domain === "exaduosport" && (container === "scolarite" || container === "documents")) {
              data.filePath = data.playerId + "/" + data.file.name;
            }
            else {
              data.filePath = data.galleryId + "/" + data.file.name;
            }
            return uploadFile(azureBlobStorageToken, data, domain, container, uploadDataPercentageFunction).then(res => {
              if (res) {
                data.fileName = data.file.name;
                data.size = data.file.size;
                data.picture = data.clubId + "/" + data.file.name;
                delete data.file;
                return getAccessToken().then((accessToken) =>
                  fetch(
                    window.location.origin + "/api/v1.0/" + APImodule + "/" + APIfeature,
                    {
                      method: "POST",
                      headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "my-Authorization": `Bearer ${accessToken}`,
                      },
                      body: JSON.stringify(data),
                    }
                  ))
              } else {
                throw new Error("Error on upload file azure blob storage");
              }
            }).catch(err => {
              throw err;
            })
          }
        }
        else {
          throw new Error("Error on get azure blob storage token");
        }
      });
    } else {
      return getAccessToken().then((accessToken) =>
        fetch(
          window.location.origin + "/api/v1.0/" + APImodule + "/" + APIfeature,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "my-Authorization": `Bearer ${accessToken}`,
            },
            body: JSON.stringify(data),
          }
        )
      );
    }
  }

}
