import React from "react";
import ActionButton from "../components/buttons/ActionButton";
import { withTranslation } from "react-i18next";
import { SelectMultiple } from "../components/forms";
import TableSimple from "../components/table/TableSimple";
import moment from "moment";
import * as Drawer from "../components/drawer";
import TableTournamentsINTProg from "./TableTournamentsINTProg";
import TableTournamentsNATProg from "./TableTournamentsNATProg";
import ExportToCSV from "../components/export/ExportToCSV";
import FilterColumns from "../components/filters/FilterColumns";
import FiltersDropDowns from "../components/filters/FiltersDropDowns";
import { Link } from "react-router-dom";
import FilterSeparator from "../components/filters/FilterSeparator";
import FiltersTitle from "../components/filters/FiltersTitle";
import ButtonLight from "../components/buttons/ButtonLight";


class TablePlayerProgrammation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            actions: [],
            circuits: [],
            selectedCircuits: [],
            conditions: [],
            selectedConditions: [],
            ground: [],
            selectedGround: [],
            columns: [],
            searchText: "",
            progView: "Real",
        };
    }

    componentDidMount() {
        this.prepareData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.prepareData();
        }
    }

    prepareData = () => {
        var supervision = this.props.supervision;
        var circuits = [];
        var selectedCircuits = [];
        var conditions = [];
        var selectedConditions = [];
        var ground = []
        var selectedGround = [];
        if (supervision) {
            supervision = supervision.map((sup) => {
                if (
                    circuits &&
                    sup.tournament &&
                    sup.tournament.circuit &&
                    !circuits.find((o) => o.key === sup.tournament.circuit.id)
                ) {
                    circuits.push({
                        key: sup.tournament.circuit.id,
                        label: sup.tournament.circuit.name,
                    });
                    selectedCircuits.push(sup.tournament.circuit.id);
                }
                if (
                    conditions &&
                    sup.tournament &&
                    sup.tournament.conditions &&
                    !conditions.find((o) => o.key === sup.tournament.conditions.id)
                ) {
                    conditions.push({
                        key: sup.tournament.conditions.id,
                        label: sup.tournament.conditions.name,
                    });
                    selectedConditions.push(sup.tournament.conditions.id);
                }
                if (
                    ground &&
                    sup.tournament &&
                    sup.tournament.ground &&
                    !ground.find((o) => o.key === sup.tournament.ground.id)
                ) {
                    ground.push({
                        key: sup.tournament.ground.id,
                        label: sup.tournament.ground.name,
                    });
                    selectedGround.push(sup.tournament.ground.id);
                }
                return sup;
            });
        }

        const columns = [
            {
                key: "date",
                label: this.props.t("Dates"),
                format: (row) => (
                    <span
                        className={
                            row.bold
                                ? "font-weight-bolder text-xs 2xl:text-sm"
                                : row.normal
                                    ? "font-weight-bold text-xs 2xl:text-sm"
                                    : "font-weight-bold text-muted text-xs 2xl:text-sm"
                        }
                    >
                        {row.tournament
                            ? moment(row.tournament.startDate).format("D MMM YY") +
                            " - " +
                            moment(row.tournament.endDate).format("D MMM YY")
                            : ""}
                    </span>
                ),
                visible: true,
            },
            {
                key: "name",
                visible: true,
                label: this.props.t("Tournois"),
                id: "name",
                format: (row) => (
                    <span className="text-primary font-weight-bolder mb-0">
                        <span className="text-primary font-weight-bolder mb-0">
                            <Link
                                className="text-indigo-700 font-semibold text-xs 2xl:text-sm"
                                to={row.cname !== "National" ?
                                    "/competitionTennis/internationaldetails/" + row.tournamentId + "/a/b" :
                                    "/competitionTennis/nationaldetails/" + row.tournamentId + "/a/b"
                                }
                            >
                                {row.name}
                            </Link>
                        </span>
                    </span>
                ),
                width: 350,
            },
            {
                key: "cname",
                visible: true,
                label: this.props.t("Circuit"),
                id: "cname",
                format: (row) =>
                    row.tournament && row.tournament.circuit ? (
                        <span
                            className="px-3 py-0.5 rounded-full font-medium bg-red-100 text-red-800 text-xs 2xl:text-sm"
                            style={{
                                color: row.tournament.circuit.color2,
                                backgroundColor: row.tournament.circuit.color1,
                            }}
                        >
                            {row.tournament.circuit.name +
                                (row.tournament.classification
                                    ? " " + row.tournament.classification
                                    : "")}
                        </span>
                    ) : (
                            "-"
                        ),
                width: 200,
                filter: {
                    name: "SelectColumnFilter",
                    type: "includes",
                },
            },
            {
                key: "conditions",
                visible: true,
                label: this.props.t("Conditions"),
                id: "conditions",
                format: (row) =>
                    row.tournament && row.tournament.conditions ? (
                        <span className="text-primary font-weight-bolder mb-0 text-xs 2xl:text-sm" >
                            {row.tournament.conditions.name}
                        </span>
                    ) : (
                            "N/A"
                        ),
                width: 200,
                filter: {
                    name: "SelectColumnFilter",
                    type: "includes",
                },
            },
            {
                key: "ground",
                visible: true,
                label: this.props.t("Ground"),
                id: "ground",
                format: (row) =>
                    row.tournament && row.tournament.ground ? (
                        <span className="text-primary font-weight-bolder mb-0 text-xs 2xl:text-sm" >
                            {row.tournament.ground.name}
                        </span>
                    ) : (
                            "N/A"
                        ),
                width: 200,
                filter: {
                    name: "SelectColumnFilter",
                    type: "includes",
                },
            },
            {
                key: "action",
                visible: true,
                format: (row) => (
                    <ActionButton
                        key={row.id}
                        deleteForm={"deleteTennisSupervision"}
                        formData={row}
                        refreshData={() => this.props.updateSupervision(this.props.id)}
                    />
                ),
            },
        ];
        var selectedColumns = [];
        columns.forEach((column) => {
            if (column.visible) {
                selectedColumns.push(column.key);
            }
        });
        this.setState({
            columns,
            supervision,
            circuits,
            selectedColumns,
            selectedCircuits,
            conditions,
            selectedConditions,
            ground,
            selectedGround,
        });
    };

    render() {
        const { t } = this.props;
        var supervision = this.props.supervision;
        var columns = this.state.columns;
        var selectedColumns = this.state.selectedColumns;
        var selectedCircuits = this.state.selectedCircuits;
        var selectedConditions = this.state.selectedConditions;
        var selectedGround = this.state.selectedGround;
        var searchText = this.state.searchText;
        if (columns) {
            columns = columns.filter((o) =>
                selectedColumns.some((item) => item === o.key)
            );
        }
        if (supervision) {
            supervision = supervision.filter((o) =>
                selectedCircuits.some(
                    (item) =>
                        o.tournament &&
                        o.tournament.circuit &&
                        item === o.tournament.circuit.id
                )
            );
        }
        if (supervision) {
            supervision = supervision.filter((o) =>
                selectedConditions.some(
                    (item) =>
                        o.tournament &&
                        o.tournament.conditions &&
                        item === o.tournament.conditions.id
                )
            );
        }
        if (supervision) {
            supervision = supervision.filter((o) =>
                selectedGround.some(
                    (item) =>
                        o.tournament &&
                        o.tournament.ground &&
                        item === o.tournament.ground.id
                )
            );
        }
        if (searchText.length > 2) {
            supervision = supervision.filter((o) =>
                o.name.toLowerCase().includes(searchText.toLowerCase())
            );
        }
        return (
            <>
                <div className="px-8 py-2 flex justify-between border-b-2">
                    <div className="flex flex-row">
                        <FiltersTitle title={supervision
                                ? supervision.length + " " + t("Tournaments").toLowerCase()
                                : ""}
                        />
                        <FilterSeparator />
                        <FiltersDropDowns selects={[
                            <SelectMultiple
                                options={this.state.circuits}
                                keys={this.state.selectedCircuits}
                                className="mx-2"
                                onChange={(res) => this.setState({ selectedCircuits: res })}
                                fixedLabel={t("Circuit")}
                            />,
                            <SelectMultiple
                                options={this.state.conditions}
                                keys={this.state.selectedConditions}
                                className="mx-2"
                                onChange={(res) => this.setState({ selectedConditions: res })}
                                fixedLabel={t("Conditions")}
                            />,
                            <SelectMultiple
                                options={this.state.ground}
                                keys={this.state.selectedGround}
                                className="mx-2"
                                onChange={(res) => this.setState({ selectedGround: res })}
                                fixedLabel={t("Ground")}
                            />
                        ]}
                        />
                        <FilterSeparator />
                        <FilterColumns
                            columns={
                                <SelectMultiple
                                    options={this.state.columns}
                                    keys={this.state.selectedColumns}
                                    className="mx-2"
                                    onChange={(res) => this.setState({ selectedColumns: res })}
                                    fixedLabel={t("Active columns")}
                                />
                            }
                        />
                        <FilterSeparator />
                        <ExportToCSV columns={columns} jsonData={supervision} fileName={t("Programmation") + ".csv"} />
                    </div>
                    <div className="flex flex-row">
                        <Drawer.Layout
                            className="w-screen"
                            component={(show) => (
                                <ButtonLight
                                    onClick={show}
                                >
                                    <i className="flaticon2-plus"></i>
                                    {t("International tournament")}
                                </ButtonLight>
                            )}
                        >
                            {(hide) => (
                                <>
                                    <Drawer.Header hide={hide}>
                                        {t("Add international tournament")}
                                    </Drawer.Header>
                                    <TableTournamentsINTProg
                                        id={this.props.id}
                                        progView={this.state.progView}
                                        updateProg={this.props.updateSupervision}
                                        addEngagement={this.props.addSupervision}
                                        tournaments={this.props.international}
                                        programmation={supervision}
                                        onChange={this.props.onChange}
                                    />
                                </>
                            )}
                        </Drawer.Layout>
                        <Drawer.Layout
                            className="w-screen"
                            component={(show) => (
                                <ButtonLight
                                    onClick={show}
                                >
                                    <i className="flaticon2-plus"></i>
                                    {t("National tournament")}
                                </ButtonLight>
                            )}
                        >
                            {(hide) => (
                                <>
                                    <Drawer.Header hide={hide}>
                                        {t("Add national tournament")}
                                    </Drawer.Header>
                                    <TableTournamentsNATProg
                                        id={this.props.id}
                                        progView={this.state.progView}
                                        updateProg={this.props.updateSupervision}
                                        addEngagement={this.props.addSupervision}
                                        tournaments={this.props.national}
                                        programmation={supervision}
                                        onChange={this.props.onChange}
                                    />
                                </>
                            )}
                        </Drawer.Layout>
                    </div>
                </div>
                <TableSimple columns={columns} data={supervision} />
            </>
        );
    }
}

export default withTranslation()(TablePlayerProgrammation);
