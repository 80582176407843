import Dexie from "dexie";

const db = new Dexie("Users");
db.version(1).stores({ players: "++id" });
db.version(1).stores({ playersAll: "++id" });
db.version(1).stores({ staffs: "++id" });
db.version(1).stores({ genders: "++id" });
db.version(1).stores({ groups: "++id" });
db.version(1).stores({ positions: "++id" });
db.version(1).stores({ roles: "++id" });
db.version(1).stores({ groupsAll: "++id" });

export default db;
