import React from "react";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import Page from "../../components/page/Page";
import { getPlayer, loadPlayer } from "../../utils/indexedDB/users/handleUser";
import PlayerAdministrationTabRugby from "./Rugby/PlayerAdministrationTabRugby";
import PlayerAdministrationTab from "./PlayerAdministrationTab";
import PlayerManagementTab from "./PlayerManagementTab";
import PlayerMediasTab from "./PlayerMediasTab";
import PlayerScheduleTab from "./PlayerScheduleTab";
import PlayerGeneralTabRugby from "./Rugby/PlayerGeneralTabRugby";
import PlayerGeneralTab from "./PlayerGeneralTab";
import PlayerCompetitionRugbyTab from "./Rugby/PlayerCompetitionRugbyTab";
import PlayerCompetitionSoccerTab from "./Soccer/PlayerCompetitionSoccerTab";
import PlayerCompetitionTennisTab from "./PlayerCompetitionTennisTab";
import Tour from "reactour";
import getUserProfile from "../../utils/auth/getUserProfile";
import PlayerTrainingTabRugby from "./Rugby/PlayerTrainingTabRugby";
import PlayerTrainingTab from "./PlayerTrainingTab";

class Player extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      player: null,
      reloadCalendar: false,
      isTourOpen: false,
      sport: null,
    };
    this.refreshPlayer = this.refreshPlayer.bind(this);
    this.closeTour = this.closeTour.bind(this);
    this.openTour = this.openTour.bind(this);
    this.modifyTour = this.modifyTour.bind(this);
  }

  componentDidMount() {
    var id = this.props.match.params.id;
    getPlayer(id).then((player) => (player ? this.setState({ player }) : null));

    getUserProfile().then((profile) => {
      this.setState({ sport: profile.sport });
    });

    if (navigator.onLine) {
      loadPlayer(id).then((player) => {
        if (player) {
          this.setState({ player });
        }
      });
    }
  }

  refreshPlayer() {
    loadPlayer(this.props.match.params.id).then((player) => {
      if (player) {
        this.setState({ player });
      }
    });
  }

  closeTour() {
    this.setState({ isTourOpen: false });
  }
  openTour() {
    this.setState({ isTourOpen: true });
  }
  modifyTour(value) {
    if (value) {
      this.setState({ isTourOpen: value });
    }
  }

  render() {
    const { t } = this.props;

    const steps = [
      {
        selector: ".playerTourStep1",
        content: t("Switch from one page to another using the tabs"),
      },
      {
        selector: ".playerTourStep2",
        content: t(
          "In the player's file, you will find a summary of the sports and administrative information of your player. You will also be able to enter your player's schedule, build his or her program and fill in the information related to the follow-up of his or her performance (evaluation results, addition of videos/photos, creation of objectives, administrative information, etc.)"
        ),
      },
      {
        selector: ".myTab0",
        content: t("Find the main sports information of your player"),
      },
      {
        selector: ".myTab1",
        content: t(
          "Enter and manage your player's schedule directly from their player file"
        ),
      },
      {
        selector: ".myTab2",
        content: t("Share and manage your player's videos and photos"),
      },
      {
        selector: ".myTab3",
        content: t("Enter and manage your player's goals and assessments"),
      },
      {
        selector: ".myTab4",
        content: t("Consult the training report"),
      },
      {
        selector: ".myTab5",
        content: t(
          "View results, standings and the list of games played by your player. Also build his schedule"
        ),
      },
      {
        selector: ".myTab6",
        content: t(
          "Fill in your player's administrative file, parents' contacts and school information"
        ),
      },
    ];

    return (
      this.state.player && (
        <>
          <Tour
            steps={steps}
            isOpen={this.state.isTourOpen}
            onRequestClose={this.closeTour}
          />
          <Page
            closeTour={() => this.closeTour()}
            openTour={() => this.openTour()}
            modifyTour={(value) => this.modifyTour(value)}
            haveReactTour={true}
            pageTitle={this.state.player.displayName}
            backPage="/group"
            actions={{
              addForm: "updatePlayer",
              deleteForm: "deletePlayer",
              formData: this.state.player,
            }}
            refreshData={() => this.props.history.goBack()}
            tab={[
              {},
              this.state.sport === "Tennis" && {
                title: t("General"),
                visible: true,
                data: null,
                component: () => {
                  return (
                    <PlayerGeneralTab
                      playerId={this.state.id}
                      player={this.state.player}
                      refresh={this.refreshPlayer}
                    />
                  );
                },
              },
              this.state.sport === "RugbyXV" && {
                title: t("General"),
                visible: true,
                data: null,
                component: () => {
                  return (
                    <PlayerGeneralTabRugby
                      playerId={this.state.id}
                      player={this.state.player}
                      refresh={this.refreshPlayer}
                    />
                  );
                },
              },
              {
                title: t("Planning"),
                visible: true,
                data: null,
                actions: {
                  addForm: "event",
                  method: "POST",
                  formData: {
                    attendees: [this.state.id],
                    playerId: this.state.id,
                    groupId: this.state.player
                      ? this.state.player.group1Id
                      : null,
                  },
                  refreshData: () => {
                    this.setState({
                      reloadCalendar: !this.state.reloadCalendar,
                    });
                  },
                },
                component: () => {
                  return (
                    <PlayerScheduleTab
                      playerId={this.state.id}
                      player={this.state.player}
                      reloadCalendar={this.state.reloadCalendar}
                    />
                  );
                },
              },
              {
                title: t("Medias"),
                visible: true,
                data: null,
                component: () => {
                  return (
                    <PlayerMediasTab
                      playerId={this.state.id}
                      player={this.state.player}
                    />
                  );
                },
              },
              {
                title: t("Management"),
                visible: true,
                data: null,
                component: () => {
                  return (
                    <PlayerManagementTab
                      playerId={this.state.id}
                      player={this.state.player}
                    />
                  );
                },
              },
              this.state.sport === "Tennis" && {
                title: t("Training"),
                visible: true,
                data: null,
                component: () => {
                  return (
                    <PlayerTrainingTab
                      playerId={this.state.id}
                      player={this.state.player}
                    />
                  );
                },
              },
              this.state.sport === "RugbyXV" && {
                title: t("Training"),
                visible: true,
                data: null,
                component: () => {
                  return (
                    <PlayerTrainingTabRugby
                      playerId={this.state.id}
                      player={this.state.player}
                    />
                  );
                },
              },
              this.state.sport === "RugbyXV" && {
                title: t("Competition"),
                visible: true,
                data: null,
                component: () => (
                  <PlayerCompetitionRugbyTab
                    playerId={this.state.id}
                    player={this.state.player}
                  />
                ),
              },
              this.state.sport === "Tennis" && {
                title: t("Competition"),
                visible: true,
                data: null,
                component: () => (
                  <PlayerCompetitionTennisTab
                    playerId={this.state.id}
                    player={this.state.player}
                  />
                ),
              },
              this.state.sport === "Foot" && {
                title: t("Competition"),
                visible: true,
                data: null,
                component: () => {
                  return (
                    <PlayerCompetitionSoccerTab
                      playerId={this.state.id}
                      player={this.state.player}
                    />
                  );
                },
              },
              this.state.sport === "RugbyXV" ? {
                title: t("Administration"),
                visible: true,
                data: null,
                component: () => {
                  return <PlayerAdministrationTabRugby
                    playerId={this.state.id}
                    player={this.state.player}
                  />;
                },
              } : {
                title: t("Administration"),
                visible: true,
                data: null,
                component: () => {
                  return <PlayerAdministrationTab
                    playerId={this.state.id}
                    player={this.state.player}
                  />;
                },
              },
            ]}
          />
        </>
      )
    );
  }
}

export default withTranslation()(withRouter(Player));


