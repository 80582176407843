import React from "react";

// TAB
// * onChange => (value)
// tab = {key, label}
// length
class SubTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: "",
    };
    this.changeTab = this.changeTab.bind(this);
  }

  componentDidMount() {
    if (
      this.props.tab &&
      this.props.selectedTab &&
      this.props.tab.find((t) => t.key === this.props.selectedTab)
    ) {
      var key = this.props.tab.find((t) => t.key === this.props.selectedTab)
        .key;
      this.setState({ key });
    } else {
      this.setState({ key: this.props.tab ? this.props.tab[0].key : "" });
    }
  }

  changeTab(key) {
    this.props.onChange(key);
    this.setState({ key })
  }

  render() {
    var buttons = [];
    var selector = [];
    var length = this.props.length ? this.props.length : 8;
    if (this.props.tab && this.props.tab.length) {
      var tabs = this.props.tab;
      for (var i = 0; i < tabs.length; i++) {
        var tab = tabs[i];
        if (buttons.length < length) {
          buttons.push(tab);
        } else {
          tab.key = i;
          selector.push(tab);
        }
      }
    }
    return buttons !== [] ? (
      <>
        <div className="px-4 sm:px-6 2xl:px-8 py-2 2xl:py-4 bg-gray-100">
          <div className="mx-auto">
            <div>
              <div className="sm:hidden">
                <select
                  id="tabs"
                  name="tabs"
                  value={this.state.key}
                  readOnly
                  className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                >
                  {buttons.map((ta, i) => {
                    return (
                      <option key={i} value={ta.key}>
                        {ta.label}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="hidden sm:block">
                <nav className="flex space-x-4" aria-label="Tabs">
                  {buttons.map((ta, keyB) => {
                    return (
                      <button
                        key={keyB}
                        className={
                          ta.key === this.state.key
                            ? "bg-indigo-700 text-white px-2 2xl:px-3 py-2 2xl:py-2 font-medium text-sm 2xl:text-base rounded-md"
                            : "text-gray-600 hover:text-gray-800 px-2 2xl:px-3 py-2 2xl:py-2 font-medium text-sm 2xl:text-base rounded-md"
                        }
                        onClick={() => this.changeTab(ta.key)}
                      >
                        {ta.label}
                      </button>
                    );
                  })}
                </nav>
              </div>
            </div>
          </div>
        </div>
      </>
    ) : null;
  }
}

export default SubTab;
