import Dexie from "dexie";

const db = new Dexie("Rugby-Competition");
db.version(1).stores({
  competitions: "++id, playerId",
  matchsInfos: "++id, eventId, playerId",
  matchsInfosByPlayer: "++matchParticipantId, matchId",
  chargeExterneNormalized: "++id, playerId, matchId",
});
db.version(2).stores({
  chargeExterneNormalized: "++id, playerId, matchId",
  competitions: "++id, playerId",
});
db.version(3).stores({
  matchsInfos: "++id, eventId, playerId",
});

export default db;
