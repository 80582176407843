import React from "react";
class Checkbox extends React.Component {
    constructor(props) {
        super(props);
        this.onCheckboxChange = this.onCheckboxChange.bind(this);
    }
    onCheckboxChange(event) {
        var value = !this.props.value
        this.props.onChange(value);
    }
    render() {
        var { onChange, value, className, id, ...newProps } = this.props;
        return (
            <input type="checkbox" onClick={this.onCheckboxChange}
            checked={value}
            id={id ? id : "checkbox"} name={id ? id : "checkbox"}
            className="focus:ring-indigo-500 checked:bg-blue-600 h-4 w-4 text-indigo-600 border-gray-300"
            {...newProps} />
        )
    }
}

export default Checkbox;