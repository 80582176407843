import React from "react";
import { withRouter } from "react-router";
import { withTranslation } from 'react-i18next';
import Page from "../../components/page/Page";
import DisplayData3cols from "../../components/displayData/DisplayData3cols";
import { getStaff, loadStaff, loadPlayersByStaff, modifyPassword } from "../../utils/indexedDB/users/handleUser";
import moment from "moment";
import Card from "../../components/card/Card";
import ButtonLight from "../../components/buttons/ButtonLight";
import FormsRightsStaff from "../../utils/forms/FormsRightsStaff";
import getUserProfile from "../../utils/auth/getUserProfile";
import StaffAdministrationTab from "./StaffAdministrationTab";
import {
    loadContactsByPlayer,
    loadContactsTypes,
    loadPersonalDocumentsByPlayer,
    loadRecord,
} from "../../utils/indexedDB/administration/handleAdministration";
import { getSelectedLanguage } from "../../locales/supportedLanguage";
import * as FormsComponent from "../../components/forms/index";
import Button from "../../components/buttons/Button";
import StaffSupervisionTab from "./StaffSupervisionTab";
import GenerateForm from "../../utils/forms/generateForm";
import { Link } from "react-router-dom";
import SVGEdit from "../../components/icons/SVGEdit";
import DisplayData1col from "../../components/displayData/DisplayData1col";
import ComingSoon from "../../components/comingSoon/comingSoon";
class Staff extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            staff: null,
            players: null,
            userIdMatch: false,
            sport: null,
        }
    }

    componentDidMount() {
        this.setState({ selectedLanguage: getSelectedLanguage() })
        // get id from url
        var id = this.props.match.params.id;
        //loadSelectedLanguage(id);
        getUserProfile().then((profile) => {
            // var isAdmin = false
            // if (profile.role === "Manager" || profile.role === "Administratif" || profile.role === "Scolarite"
            //     || profile.role === "Commercial" || profile.role === "Finance" || profile.role === "President"
            //     || profile.role === "Tuteur")
            //     isAdmin = true
            this.setState({ userIdMatch: profile.sub === id, isAdmin: profile.isAdmin, sport: profile.sport });
            
        });

        // function get from indexedDB
        getStaff(id).then(staff => staff ? this.setState({ staff }) : null);
        if (navigator.onLine) {
            loadStaff(id).then(staff => this.setState({ staff }));
            loadPlayersByStaff(id).then(players => this.setState({ players }))
            loadRecord(id).then((record) => this.setState({ record }));
            loadContactsTypes().then((contactsTypes) =>
                this.setState({ contactsTypes })
            );
            loadContactsByPlayer(id).then((contacts) => this.setState({ contacts }));
            loadPersonalDocumentsByPlayer(id).then((personalDocuments) =>
                this.setState({ personalDocuments })
            );
        }
    }

    updatePlayers(id) {
        loadPlayersByStaff(id).then(players => this.setState({ players }))
    }

    render() {
        const { t } = this.props;
        var id = this.props.match.params.id;
        var userIdMatch = this.state.userIdMatch;
        var isAdmin = this.state.isAdmin;

        return userIdMatch || isAdmin !== "false" ?
            <Page pageTitle={this.state.staff ? this.state.staff.displayName : t("My Account")}
                backPage="/group"
                actions={{ addForm: isAdmin !== "false" ? "updateStaff" : "updateMyStaffAccount", deleteForm: "deleteStaff", formData: this.state.staff, activeUpdateException: userIdMatch || isAdmin !== "false" }}
                refreshData={() => this.props.history.goBack()}
                tab={[
                    {
                        title: t("General"),
                        visible: true,
                        data: null,
                        component: (data) => {
                            return this.state.staff ?
                                <General
                                    staff={this.state.staff}
                                    players={this.state.players}
                                    t={t}
                                    id={id}
                                    updatePlayers={this.updatePlayers}
                                    selectedLanguage={this.state.selectedLanguage}
                                    userIdMatch={userIdMatch}
                                />
                                : null
                        }
                    },
                    {
                        title: t("Administration"),
                        visible: true,
                        data: null,
                        component: () => {
                            return (
                                <StaffAdministrationTab
                                    id={id}
                                    player={this.state.player}
                                />
                            );
                        },
                    },
                    this.state.sport === "Tennis" &&
                    {
                        title: t("Supervision"),
                        visible: true,
                        data: null,
                        component: () => {
                            return (
                                <StaffSupervisionTab
                                    id={id}
                                    player={this.state.player}
                                />
                            );
                        },
                    },
                    this.state.sport === "RugbyXV" &&
                    {
                        title: t("Group"),
                        visible: true,
                        data: null,
                        component: () => {
                            return (
                               <ComingSoon />
                            );
                        },
                    },
                    {
                        title: t("Security"),
                        visible: true,
                        data: null,
                        component: (data) => {
                            return (
                                <Security
                                    staff={this.state.staff}
                                    t={t}
                                    id={id}
                                    userIdMatch={userIdMatch}
                                />
                            )
                        }
                    },
                ]} />
            : <Page pageTitle={this.state.staff ? this.state.staff.displayName : t("Staff")}
                backPage="/group"
                actions={{ addForm: "updateStaff", deleteForm: "deleteStaff", formData: this.state.staff }}
                refreshData={() => this.props.history.goBack()}
                tab={[
                    {
                        title: t("General"),
                        visible: true,
                        data: null,
                        component: (data) => {
                            return this.state.staff ?
                                <General
                                    staff={this.state.staff}
                                    players={this.state.players}
                                    t={t}
                                    id={id}
                                    updatePlayers={this.updatePlayers}
                                    selectedLanguage={this.state.selectedLanguage}
                                    userIdMatch={userIdMatch}
                                />
                                : null
                        }
                    },
                ]} />
    }
}

export default withTranslation()(withRouter(Staff));


class General extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        const { t } = this.props;
        return (<>
            <div className="flex space-x-5 pt-5 items-center">
                <div className="py-1 px-20 w-96">
                <GenerateForm callback={() => { }}
                    formName={"updatePhotoStaff"}
                    formData={this.props.staff}
                    component={(show) => <div className="w-36 h-36 py-2"
                        onClick={() => show()}
                    >
                        <div className="relative mx-auto my-auto cursor-pointer">
                            <img
                                className="block opacity-100 h-36 w-36 rounded-full"
                                src={this.props.staff.photo}
                                alt=""
                            />
                            <span className="absolute top-0 h-36 w-36 opacity-0 hover:opacity-75 rounded-full hover:bg-gray-700 overflow-hidden text-center">
                                <span className="block pt-16 text-white">{t("Edit")}</span>
                            </span>
                        </div>
                    </div>}
                />
                </div>
                <div className="py-3 px-10 w-full">
                    <Card>
                        <DisplayData3cols
                            data={[
                                {
                                    key: t("Last name"),
                                    value: this.props.staff.lastName
                                        ? this.props.staff.lastName.toUpperCase()
                                        : "",
                                },
                                { key: t("First name"), value: this.props.staff.firstName },
                                {
                                    key: t("Training group"),
                                    value: this.props.staff.groupPrincipal
                                        ? this.props.staff.groupPrincipal.name
                                        : "",
                                },
                                {
                                    key: t("Role"),
                                    value: this.props.staff.role
                                        ? this.props.staff.role.name
                                        : "",
                                },
                                {
                                    key: t("Date of birth"),
                                    value: moment(this.props.staff.dob).calendar(),
                                },
                                { key: t("Email"), value: this.props.staff.email },
                                { key: t("Mobile"), value: this.props.staff.mobile },
                            ]}
                        />
                    </Card>
                </div>
            </div>

            {/* <div className="grid grid-cols-5 gap-4 px-4 mb-6 mt-3">
                <div className="col-span-5 md:col-span-1">
                    
                </div>
                <div className="col-span-5 md:col-span-3">
                    
                </div>
                {this.props.userIdMatch ?
                    <div className="col-span-5 md:col-span-1">
                        <Card
                            className=" "
                            title={t("Language")}
                        >
                            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 py-6">
                                <div className="sm:col-span-1">
                                    <dt className="text-sm font-medium text-gray-500">
                                        {t("Selected language")}
                                    </dt>
                                    <dd className="mt-1 text-md text-gray-900 font-bold">
                                        <Select
                                            options={supportedLanguage}
                                            value={this.props.selectedLanguage.key}
                                            onChange={(key) => changeLanguage(key, this.props.id, t)}
                                        />
                                    </dd>
                                </div>
                            </dl>
                        </Card>
                    </div>
                    : null}
            </div> */}
            <div className="mt-2 py-2 px-5 mx-5 mb-5">
                <Card title={t("Followed players")} className="card-stretch"
                    headerActions={
                        <FormsRightsStaff
                            component={(show, disabled, tooltip) => (
                                <ButtonLight
                                    onClick={show}
                                    disabled={disabled}
                                    title={tooltip}
                                >
                                    <SVGEdit />
                                </ButtonLight>
                            )}
                            id={this.props.id}
                            callback={() => {
                                var id = this.props.id;
                                if (navigator.onLine) {
                                    this.props.updatePlayers(id)
                                }
                            }}
                        />
                    }>
                    <ul className="w-full mx-auto grid gap-x-4 gap-y-3 2xl:gap-y-8 py-3 2xl:py-6 grid-cols-5 md:gap-x-6 2xl:max-w-8xl 2xl:gap-x-12 2xl:grid-cols-6 text-center">
                        {this.props.players
                        ? this.props.players.map((player, key) => (
                            <li key={key}>
                                <Link to={"/group/player/" + player.id} key={player.id}>
                                <button className="hover:border-indigo-800 hover:border rounded-xl px-10 py-4 2xl:py-6">
                                    {" "}
                                    <div className="space-y-4">
                                    <img
                                        className="mx-auto h-20 w-20 rounded-full 2xl:w-24 2xl:h-24"
                                        src={player.photo}
                                        alt=""
                                    />
                                    <div className="space-y-2">
                                        <div className="text-xs font-medium 2xl:text-sm truncate">
                                        <h3>
                                            {player.lastName.toUpperCase() +
                                            " " +
                                            player.firstName}
                                        </h3>
                                        <p className="text-indigo-600 truncate text-xs 2xl:text-sm">
                                            {player.group1 ? player.group1.name : ""}
                                        </p>
                                        </div>
                                    </div>
                                    </div>
                                </button>
                                </Link>
                            </li>
                            ))
                            : ""}
                    </ul>
                </Card>
            </div>
        </>)
    }
}

class Security extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newPassword: {
                old: "",
                new: "",
                confirmation: ""
            },
            errorQ: false
        }
    }

    changePassword(t) {
        modifyPassword(this.state.newPassword, this.props.id, t, this.props.userIdMatch).then((errorQ) => {
            this.setState({ errorQ })
        });
    }

    render() {
        const { t } = this.props;
        var errorQ = this.state.errorQ;
        var userIdMatch = this.props.userIdMatch
        return (this.props.staff ?
            <>
                <div className="grid grid-cols-2 gap-4">
                    <div className="mx-6 my-5">
                        <Card
                            className=""
                            title={t("Change Password")}
                        >
                            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 py-6">
                                {userIdMatch ?
                                    <div className="sm:col-span-1">
                                        <dt className="text-xs 2xl:text-sm font-medium text-gray-500">
                                            {t("Old password")}
                                        </dt>
                                        <dd className="mt-1 text-xs 2xl:text-sm text-gray-900 font-bold">
                                            <FormsComponent.PasswordInput
                                                id={"Old password"}
                                                placeholder={t("Old password")}
                                                rows="1"
                                                value={
                                                    this.state.newPassword && this.state.newPassword.old
                                                        ? this.state.newPassword.old
                                                        : ""
                                                }
                                                onChange={(value) => {
                                                    var newPassword = this.state.newPassword;
                                                    newPassword.old = value;
                                                    this.setState({ newPassword });
                                                }}
                                                className={errorQ ? "max-w-lg block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 text-xs 2xl:text-sm rounded-md" :
                                                    "max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 text-xs 2xl:text-sm border-gray-300 rounded-md"}
                                            />
                                        </dd>
                                    </div>
                                    : null}
                                {userIdMatch ?
                                    <div className="sm:col-span-1">
                                    </div>
                                    : null}
                                <div className="sm:col-span-1">
                                    <dt className="text-xs 2xl:text-sm font-medium text-gray-500">
                                        {t("New password")}
                                    </dt>
                                    <dd className="mt-1 text-xs 2xl:text-sm text-gray-900 font-bold">
                                        <FormsComponent.PasswordInput
                                            id={"New password"}
                                            placeholder={t("New password")}
                                            rows="1"
                                            value={
                                                this.state.newPassword && this.state.newPassword.new
                                                    ? this.state.newPassword.new
                                                    : ""
                                            }
                                            onChange={(value) => {
                                                var newPassword = this.state.newPassword;
                                                newPassword.new = value;
                                                this.setState({ newPassword });
                                            }}
                                            className={errorQ ? "max-w-lg block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 text-xs 2xl:text-sm rounded-md" :
                                                "max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 text-xs 2xl:text-sm border-gray-300 rounded-md"}
                                        />
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <dt className="text-xs 2xl:text-sm font-medium text-gray-500">
                                        {t("Confirmation")}
                                    </dt>
                                    <dd className="mt-1 text-xs 2xl:text-md text-gray-900 font-bold">
                                        <FormsComponent.PasswordInput
                                            id={"Confirmation"}
                                            placeholder={t("Confirmation")}
                                            rows="1"
                                            value={
                                                this.state.newPassword && this.state.newPassword.confirmation
                                                    ? this.state.newPassword.confirmation
                                                    : ""
                                            }
                                            onChange={(value) => {
                                                var newPassword = this.state.newPassword;
                                                newPassword.confirmation = value;
                                                this.setState({ newPassword });
                                            }}
                                            className={errorQ ? "max-w-lg block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 text-xs 2xl:text-sm rounded-md" :
                                                "max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 text-xs 2xl:text-sm border-gray-300 rounded-md"}
                                        />
                                    </dd>
                                </div>
                                <div className="sm:col-span-1">
                                    <Button
                                        onClick={() => { this.changePassword(t) }}
                                    //disabled={disabled}
                                    //title={tooltip}
                                    >
                                        {t("Change")}
                                    </Button>
                                </div>
                            </dl>
                        </Card>
                    </div>

                    <div className="mx-6 my-5">
                        <Card
                            className=" "
                            title={t("Security Rules")}
                        >
                            <DisplayData1col
                                data={[
                                    { key: t("Length"), value: t("Password length must be between 8 and 64 characters") },
                                    {
                                        key: t("Characters R"), value: <><div>
                                            {t("Have at least one lowercase character")}
                                        </div>
                                            <div>
                                                {t("Have at least one uppercase character")}
                                            </div>
                                            <div>
                                                {t("Have at least one number             ")}
                                            </div>
                                            <div>
                                                {t("Have at least one symbol character   ")}
                                            </div></>
                                    }
                                ]}
                            />
                        </Card>
                    </div>
                </div>
            </>
            : null)
    }
}