import React from "react";
import Router from "./router";
import {
  AuthenticationProvider,
  InMemoryWebStorage,
} from "@axa-fr/react-oidc-context";
import oidcConfiguration from "./utils/auth/auth";
import { SplashScreen } from "./page/core/SplashScreen";
// language
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

// moment
import moment from "moment";
import "moment/locale/fr";
import { SplashScreenNavigationIssue } from "./page/core/SplashScreenNavigationIssue";

//theme
// import "./assets/sass/main.scss";

function App() {
  moment.locale("fr"); // to manage when changing language

  return (
    <AuthenticationProvider 
      notAuthenticated={SplashScreenNavigationIssue}
      authenticating={SplashScreen}
      configuration={oidcConfiguration}
      isEnabled={true}
      UserStore={InMemoryWebStorage}
      callbackComponentOverride={SplashScreen}
      authenticationComponentOverride={SplashScreen}
      logoutUserInt={process.env.REACT_APP_URL} 
    >
      <I18nextProvider i18n={i18n}>
        <Router />
      </I18nextProvider>
    </AuthenticationProvider>
  );
}

export default App;
