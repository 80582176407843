import React from "react";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import Page from "../../components/page/Page";
import {
  getMatchInfos,
  getMatchsInfosByPlayer,
  // loadMatchsInfos,
  loadMatchsInfosByPlayer,
} from "../../utils/indexedDB/Soccer-Competition/handleCompetitionSoccer";
import SubTab from "../../components/tab/SubTab";
import MatchGeneral from "./MatchGeneral";
import { loadMatchInfos } from "../../utils/indexedDB/Soccer-Competition/handleCompetitionSoccer";
import StatistiqueIndividuelPhysical from "./StatistiqueIndividuelPhysical";
import StatistiqueIndividuelSoccer from "./StatistiqueIndividuelSoccer";
// import Box from "./PlayerInfoByMatch/Box";
// import { DndProvider } from "react-dnd";
// import { HTML5Backend } from "react-dnd-html5-backend";
import ComingSoon from "../../components/comingSoon/comingSoon";

class MatchInfosSoccer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      getMatchsInfosFromAPi: false,
      getMatchsInfosByPlayerFromAPi: false,
      soccerStatView: "soccer",
    };
  }

  componentDidMount() {
    var id = this.props.match.params.id;
    this.handleMatchsInfos(id);
    this.handleMatchInfosByPlayer(id);
  }

  handleMatchsInfos(matchId) {
    this.setState({ getMatchsInfosFromAPi: false });
    getMatchInfos(matchId).then((match) =>
      match && !this.state.getMatchsInfosFromAPi
        ? this.setState({ matchInfos: match[0] })
        : null
    );
    if (navigator.onLine) {
      loadMatchInfos(matchId).then((match) =>
        match
          ? this.setState({ matchInfos: match, getMatchsInfosFromAPi: true })
          : null
      );
    }
  }

  handleMatchInfosByPlayer = (matchId) => {
    this.setState({ getMatchsInfosByPlayerFromAPi: false });
    getMatchsInfosByPlayer(matchId).then((infoByPlayer) =>
      infoByPlayer &&
        infoByPlayer.sort((a, b) =>
          a.position.number === b.position.number
            ? 0
            : a.position.number < b.position.number
              ? -1
              : 1
        ) &&
        !this.state.getMatchsInfosByPlayerFromAPi
        ? this.setState({ infoByPlayer })
        : null
    );
    if (navigator.onLine) {
      loadMatchsInfosByPlayer(matchId).then((infoByPlayer) =>
        infoByPlayer
          ? this.setState({ infoByPlayer, getMatchsInfosByPlayerFromAPi: true })
          : null
      );
    }
  };

  render() {
    const { t } = this.props;

    // return this.state.player &&
    return (
      <Page
        // pageTitle="Match Infos"
        backPage="/group"
        // actions={{
        //   addForm: "updatePlayer",
        //   deleteForm: "deletePlayer",
        //   formData: this.state.player,
        // }}
        refreshData={() => this.props.history.goBack()}
        tab={[
          {
            title: t("Match"),
            visible: true,
            data: null,
            component: () => {
              return (
                <MatchGeneral
                  matchInfos={this.state.matchInfos}
                  infoByPlayer={this.state.infoByPlayer}
                />
              );
            },
          },
          {
            title: t("Statistique Individuel"),
            visible: true,
            data: null,
            component: () => {
              return (
                <>
                  <SubTab
                    key="soccer"
                    tab={[
                      { key: "soccer", label: t("Soccer") },
                      { key: "physical", label: t("Physical") },
                    ]}
                    onChange={(key) => this.setState({ soccerStatView: key })}
                    selectedTab={this.state.soccerStatView}
                  ></SubTab>
                  {this.state.soccerStatView === "soccer" &&
                    this.state.matchInfos &&
                    this.state.infoByPlayer && (
                      <StatistiqueIndividuelSoccer
                        matchInfos={this.state.matchInfos}
                        infoByPlayer={this.state.infoByPlayer}
                      />
                    )}
                  {this.state.soccerStatView === "physical" &&
                    this.state.matchInfos &&
                    this.state.infoByPlayer && (
                      <StatistiqueIndividuelPhysical
                        matchInfos={this.state.matchInfos}
                        infoByPlayer={this.state.infoByPlayer}
                      />
                    )}
                </>
              );
            },
          },
          {
            title: t("Document"),
            visible: true,
            data: null,
            component: () => {
              return (
                // <DndProvider backend={HTML5Backend}>
                //   <Box />
                // </DndProvider>
                <ComingSoon />
              );
            },
          },
        ]}
      />
    );
  }
}

export default withTranslation()(withRouter(MatchInfosSoccer));
