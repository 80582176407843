import getAccessToken from "../auth/getAccessToken";
import cogoToast from "cogo-toast";

async function get (APImodule, APIfeature, parameters) {
    var url = window.location.origin + "/api/v1.0/" + APImodule + "/" + APIfeature;
    if (parameters) {
        url += "/" + parameters;
    }
    return getAccessToken().then((accessToken) => fetch(encodeURI(url), {
        method: "GET",
        headers: {
            "my-Authorization": `Bearer ${accessToken}`
        }
    })
        .then(res => {
            if (res.ok && res.status !== 204) {
                return res.json();
            } else {
                cogoToast.error("Error :" + res.status + " " + APImodule + ", " + APIfeature, {position: "bottom-right"});
                throw new Error(res.status);
            }
        })
        .catch((error) => {
            cogoToast.error("Error :" + APImodule + ", " + APIfeature, {position: "bottom-right"});
            return false;
        })
    )
}

export default get;