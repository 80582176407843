import React from "react"
import { Link } from "react-router-dom"
import IconRound32x32 from "../components/icons/IconRound32x32"
import { withTranslation } from "react-i18next"
import { SelectMultiple } from "../components/forms"
import TableSimple from "../components/table/TableSimple"
import Flag from "../components/icons/Flag"
import FilterColumns from "../components/filters/FilterColumns"
import FiltersDropDowns from "../components/filters/FiltersDropDowns"
import ExportToCSV from "../components/export/ExportToCSV"
import SearchInput from "../components/forms/input/SearchInput"
import FiltersSearch from "../components/filters/FiltersSearch"
import FiltersTitle from "../components/filters/FiltersTitle"
import FiltersBar from "../components/filters/FiltersBar"

class TableTournamentsINTParticipants extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      actions: [],
      ages: [],
      selectedAges: [],
      nationalities: [],
      selectedNationalities: [],
      dates: [],
      columns: [],
      searchText: "",
    }
  }

  componentDidMount() {
    this.prepareData()
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.prepareData()
    }
  }

  prepareData = () => {
    var participants = this.props.participants
    var nationalities = []
    var selectedNationalities = []
    if (participants) {
        participants = participants.map((participant) => {
        
        if (
          nationalities &&
          !nationalities.find((o) => o.key === participant.player.nationality)
        ) {
          nationalities.push({
            key: participant.player.nationality,
            label: participant.player.country
              ? participant.player.country.nationality
              : participant.player.nationality,
          })
          selectedNationalities.push(participant.player.nationality)
        }
        return participant
      })
    }

    const columns = [
      {
        key: "player.lastName",
        visible: true,
        label: this.props.t("Player"),
        id: "name",

        format: (row) =>
          row.player.nationality != null && row.player.nationality === "FR" ? (
            <>
              <Link
                className="text-indigo-700 font-semibold"
                to={"#"}
              >
                <IconRound32x32 photo={row.player.photo} />
                <span className="ml-5 text-xs 2xl:text-sm">{row.player.lastName + " " + row.player.firstName}</span>
              </Link>
            </>
          ) : (
            <Link
              className="text-black"
              to={"#"}
            >
              <IconRound32x32 photo={row.player.photo} />
              <span className="ml-5 text-xs 2xl:text-sm">{row.player.lastName + " " + row.player.firstName}</span>
            </Link>
          ),
      },
      {
        key: "player.nationality",
        visible: true,
        label: this.props.t("Nationality"),
        id: "player.nationality",
        filter: {
          name: "SelectColumnFilter",
          type: "includes",
        },
        format: (row) =>
          row.player ? (
            <div className="flex">
              <Flag className="w-4 2xl:w-6" nationalityId={row.player.nationality} />
              <div className="flex px-3 text-xs 2xl:text-sm">
                {row.player.country
                  ? row.player.country.nationality
                  : row.player.nationality}
              </div>
            </div>
          ) : (
            ""
          ),
      },
      {
        key: "seed",
        visible: true,
        label: this.props.t("Seed"),
        id: "seed",
        format: (row) => (
          <span className="font-weight-bold text-muted text-xs 2xl:text-sm">
            {row.seed}
          </span>
        ),
      },
      {
        key: "seedInfo",
        visible: true,
        label: this.props.t("Access"),
        id: "seedInfo",
        format: (row) => (
          <span className="font-weight-bold text-muted text-xs 2xl:text-sm">
            {row.seedInfo}
          </span>
        ),
      },
    ]

    var selectedColumns = []
    columns.forEach((column) => {
      if (column.visible) {
        selectedColumns.push(column.key)
      }
    })
    this.setState({
      columns,
      participants,
      nationalities,
      selectedColumns,
      selectedNationalities,
    })
  }

  render() {
    const { t } = this.props
    var participants = this.props.participants
    var columns = this.state.columns
    var selectedColumns = this.state.selectedColumns
    var selectedNationalities = this.state.selectedNationalities
    var searchText = this.state.searchText
    if (columns) {
      columns = columns.filter((o) =>
        selectedColumns.some((item) => item === o.key)
      )
    }
    if (participants) {
        participants = participants.filter((o) =>
        selectedNationalities.some((item) => item === o.player.nationality)
      )
    }
    if (searchText.length > 2) {
        participants = participants.filter(
        (o) =>
          o.player.lastName.toLowerCase().includes(searchText.toLowerCase()) ||
          o.player.firstName.toLowerCase().includes(searchText.toLowerCase())
      )
    }
    return (
      <>
        <FiltersBar
          components={
            <>
              <FiltersTitle title={participants
                  ? participants.length + " " + t("Players").toLowerCase()
                  : ""}
              />
              <span className="ml-5 mr-5 w-1 border-l"></span>
              <FiltersSearch
                search={
                  <SearchInput
                    value={this.state.searchText}
                    onChange={(searchText) => this.setState({ searchText })}
                  />
                }
              />
              <span className="ml-5 mr-5 w-1 border-l"></span>
              <FiltersDropDowns
                selects={[
                  <SelectMultiple
                    options={this.state.nationalities}
                    keys={this.state.selectedNationalities}
                    className="mx-2"
                    onChange={(res) =>
                      this.setState({ selectedNationalities: res })
                    }
                    fixedLabel={t("Nationality")}
                  />,
                ]}
              />
              <span className="ml-5 mr-5 w-1 border-l"></span>
              <FilterColumns
                columns={
                  <SelectMultiple
                    options={this.state.columns}
                    keys={this.state.selectedColumns}
                    className="mx-2"
                    onChange={(res) => this.setState({ selectedColumns: res })}
                    fixedLabel={t("Active columns")}
                  />
                }
              />
              <span className="ml-5 mr-5 w-1 border-l"></span>
              <ExportToCSV
                columns={columns}
                jsonData={participants}
                fileName={t("Participants") + ".csv"}
              />
            </>
          }
        />
        <TableSimple columns={columns} data={participants} />
      </>
    )
  }
}

export default withTranslation()(TableTournamentsINTParticipants)
