import React from "react";
import { withTranslation } from "react-i18next";

/**
 * list : {min: max: color:}
 */
class MultipleSliderReadOnly extends React.Component {
    render() {
        var { list } = this.props;
        if (list && list.length) {
            var listNum = list.map(l => l.min)
            list.forEach(l => {
                if (!listNum.find(ln => ln === l.max)) {
                    listNum.push(l.max);
                }
            });
            listNum = listNum.sort(function (a, b) { return a - b });
            var listColor = [];
            listNum.forEach((n, index) => {
                if (index !== listNum.length - 1) {
                    var item = list.find(i => i.min === n && i.max === listNum[index + 1]);
                    if (item && item.color) {
                        listColor.push(item.color);
                    } else {
                        listColor.push("");
                    }
                }
            });
            return listColor && listColor.length && listNum && listNum.length ? (
                <div className="w-full">
                    <div>
                        <div className="relative pt-1">
                            <div className="overflow-hidden h-2 mb-4 text-xs flex rounded">
                                {listColor.map((c, key) => {
                                    return <div key={key} style={{ width: (100 / listColor.length).toString() + "%", backgroundColor: c }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center"></div>
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-between mt-2 text-xs text-gray-400">
                        {listNum.map((value, key) => {
                            if (key === 0) {
                                return <span className="text-left" key={key}>{value}</span>
                            } else if (key === listNum.length - 1) {
                                return <span className="text-right" key={key}>{value}</span>
                            } else {
                                return <span className="text-right" key={key}>{value}</span>
                            }
                        })}
                    </div>
                </div>
            ) : "";
        } else {
            return <></>;
        }
    }
}

export default withTranslation()(MultipleSliderReadOnly);
