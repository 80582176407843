import React from "react";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import Page from "../../components/page/Page";
import Card from "../../components/card/Card";
import {
    loadGroups,
    getGroups,
} from "../../utils/indexedDB/users/handleUser";
import EventForm from "../../utils/forms/events/EventForm";
import Button from "../../components/buttons/Button";
import DisplayData3cols from "../../components/displayData/DisplayData3cols";
import TableTrainingSequences from "../../tables/TableTrainingSequences";
import TablePhysicalSequences from "../../tables/TablePhysicalSequences";
import ButtonLight from "../../components/buttons/ButtonLight";
import GeneratedForm from "../../utils/forms/generateForm";
import { loadTrainingSessionTemplate, getTrainingSessionTemplate, loadPhysicalSessionTemplate, getPhysicalSessionTemplate } from "../../utils/indexedDB/training/handleTraining"

class TennisSession extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            event: null,
            sessionTime: null,
            trainingSequences: null,
            player: null,
            status: "isPresent",
            updateForm: "updateTrainingSessionTemplates",
            deleteForm: "deleteTrainingSessionTemplates",
            step: "sequencesTrainingTemplate"
        };
        this.handleTrainingSessionTemplate = this.handleTrainingSessionTemplate.bind(this);
        this.handlePhysicalSessionTemplate = this.handlePhysicalSessionTemplate.bind(this);
        this.handleGroups = this.handleGroups.bind(this);
    }

    componentDidMount() {
        var sessionId = this.props.match.params.id;
        var type = this.props.match.params.type;
        this.handleGroups();
        if (type === "training") {
            this.handleTrainingSessionTemplate(sessionId)
            this.setState({ 
                handleSessionTemplate: this.handleTrainingSessionTemplate
            })
        }
        else if (type === "physical") {
            this.handlePhysicalSessionTemplate(sessionId);
            this.setState({ 
                updateForm: "updatePhysicalSessionTemplates", 
                deleteForm: "deletePhysicalSessionTemplates",
                handleSessionTemplate: this.handlePhysicalSessionTemplate,
                step: "sequencesPhysicalTemplate"
            })
        }
    }

    handleTrainingSessionTemplate(id) {
        getTrainingSessionTemplate(id).then((session) => {
            if (session)
                this.setState({ session })
        })

        if (navigator.onLine) {
            loadTrainingSessionTemplate(id).then((session) => {
                this.setState({ session })
            })
        }
    }

    handlePhysicalSessionTemplate(id) {
        getPhysicalSessionTemplate(id).then((session) => {
            if (session)
                this.setState({ session })
        })

        if (navigator.onLine) {
            loadPhysicalSessionTemplate(id).then((session) => {
                this.setState({ session })
            })
        }
    }

    handleGroups() {
        getGroups().then((groups) => {
            if (groups) {
                this.setState({ groups })
            }
        })
        if (navigator.onLine) {
            loadGroups().then((groups) => {
                this.setState({ groups })
            })
        }
    }

    render() {
        const { t } = this.props;
        var type = this.props.match.params.type;
        var sessionId = this.props.match.params.id;
        var { groups } = this.state;
        var staffs = [];
        this.state.organizers &&
            this.state.organizers.map((organizer) => staffs.push(organizer.staff));
        return (
            <Page
                pageTitle={
                    this.state.session ? this.state.session.name : t("Session Template")
                }
                actions={{
                    other: [
                        <GeneratedForm
                            key={this.state.updateForm}
                            component={(show, text) => (
                                <ButtonLight onClick={show}>{t("Edit")}</ButtonLight>
                            )}
                            // method="PATCH"
                            callback={() => this.state.handleSessionTemplate(sessionId)}
                            formData={this.state.session}
                            formName={this.state.updateForm}
                        />,
                        <GeneratedForm
                            key={this.state.deleteForm}
                            component={(show, text) => (
                                <ButtonLight onClick={show}>{t("Delete")}</ButtonLight>
                            )}
                            // method="DELETE"
                            callback={() => this.props.history.goBack()}
                            formData={this.state.session}
                            formName={this.state.deleteForm}
                        />,
                    ],
                }}
                backPage="/training/tennis"
                tab={[
                    {
                        title: t("Summary"),
                        visible: true,
                        data: this.state.session,
                        component: (data) => {
                            return data && groups ? (
                                <>
                                    <div>
                                        <div className="flex">
                                            <div className="w-full px-6 pt-4 py-2">
                                                <Card>
                                                    <DisplayData3cols
                                                        data={[
                                                            { key: t("Title"), value: data.name },
                                                            {
                                                                key: t("Duration"),
                                                                value: data.durationHourly,
                                                            },
                                                            {
                                                                key: t("Group"),
                                                                value: groups.find((o) => o.id === data.groupId).name,
                                                            },
                                                            {
                                                                key: t("Description"),
                                                                value: data.description,
                                                            },
                                                        ]}
                                                    />
                                                </Card>
                                            </div>
                                        </div>
                                        <div className="w-full px-6 py-2">
                                            <div className="w-full mt-6 mb-20">
                                                <div
                                                    className="border rounded-md ml-4 mr-4"
                                                    style={{ boxShadow: "0 0 10px 0 rgba(82,63,105,.2)" }}
                                                >
                                                    <Card
                                                        title={t("Session content")}
                                                        headerActions={[
                                                            <EventForm
                                                                key="sequences-form"
                                                                step={this.state.step}
                                                                component={(show, text) => (
                                                                    <Button
                                                                        onClick={show}
                                                                        disabled={!navigator.onLine}
                                                                    >
                                                                        {t("Edit")}
                                                                    </Button>
                                                                )}
                                                                method="PATCH"
                                                                callback={() => this.state.handleSessionTemplate(sessionId)}
                                                                formData={this.state.session}
                                                            />,
                                                        ]}
                                                    >
                                                        {type === "training" && <TableTrainingSequences
                                                            sequences={this.state.session.sequences}
                                                            height={200}
                                                        />}
                                                        {type === "physical" && <TablePhysicalSequences
                                                            sequences={this.state.session.sequences}
                                                            height={200}
                                                        />}
                                                    </Card>
                                                </div>
                                            </div>{" "}
                                        </div>
                                    </div>
                                </>
                            ) : (
                                    ""
                                );
                        },
                    },
                ]}
            />
        );
    }
}

export default withTranslation()(withRouter(TennisSession));
