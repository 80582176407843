import Dexie from "dexie";

const db = new Dexie("Management");
db.version(1).stores({ 
    playerTargets: "++id, kind",
    playerTargetsCategories: "++id",
    interviewCategories: "++id"
});

db.version(2).stores({
    playerPeriodicReport: "++id, playerId",
    playerPeriodicReportSections: "++id, reportId",
    playerPeriodicReportsSectionsCategories: "++id",
    federalSupport: "++id, playerId, seasonId",
    federalSupportProgrammes: "++id",
    federalSupportStatuses: "++id",
    trainingCenters: "++id"
});

export default db;
