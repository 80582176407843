import React from "react";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import Page from "../../components/page/Page";
import Card from "../../components/card/Card";
// import moment from "moment";
// import IconRound32x32 from "../../components/icons/IconRound32x32";
import { Link } from "react-router-dom";
import DisplayData3cols from "../../components/displayData/DisplayData3cols";
// import ButtonLight from "../../components/buttons/ButtonLight";
// import GeneratedForm from "../../utils/forms/generateForm";
import ModalImage from "react-modal-image";
import { 
    // loadTrainingDrill, 
    getTrainingDrill,
    getPhysicalDrill,
    // getTrainingDrillsTheme, 
    // loadTrainingDrillsThemes 
} from "../../utils/indexedDB/training/handleTraining"
import DisplayData1col from "../../components/displayData/DisplayData1col";
// import { loadStaffs } from "../../utils/indexedDB/users/handleUser";

class PeriodicReportDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            type: this.props.match.params.type,
            sections: [],
            loading: true
        };
    }

    componentDidMount() {
        if (this.state.type === "physical") {
            getPhysicalDrill(this.state.id).then((exercice) => {
                if (exercice)
                    this.setState({ exercice, updateForm: "updatePhysicalDrill", deleteForm: "deletePhysicalDrill", theme: exercice.physicalTheme, category: exercice.physicalTheme.physicalCategory,  loading: false })
            })
        }
        else {
            getTrainingDrill(this.state.id).then((exercice) => {
                if (exercice)
                    this.setState({ exercice, updateForm: "updateTrainingDrill", deleteForm: "deleteTrainingDrill", theme: exercice.trainingTheme, category: exercice.trainingTheme.trainingCategory, loading: false })
            })
        }

        // if (navigator.onLine) {
        //     loadStaffs().then((authors) => {
        //         if (this.state.type === "physical") {
        //             getPhysicalDrill(this.state.id).then((exercice) => {
        //                 if (exercice)
        //                     this.setState({ exercice, updateForm: "updatePhysicalDrill", deleteForm: "deletePhysicalDrill", theme: exercice.physicalTheme, loading: false })
        //             })
        //         }
        //         else {
        //             loadTrainingDrillsThemes().then((themes) => {
        //                 loadTrainingDrill(this.state.id, authors, themes).then((exercice) => {
        //                     getTrainingDrillsTheme(exercice.trainingThemeId).then((theme) => {
        //                         if (theme)
        //                             this.setState({ exercice, updateForm: "updateTrainingDrill", deleteForm: "deleteTrainingDrill", theme, loading: false })
        //                     })
        //                 })
        //             })
        //         }
        //     })
        // }
    }

    render() {
        const { t, } = this.props;
        var { exercice, category, theme } = this.state
        var author = exercice ? exercice.author : null;

        return exercice && theme && !this.state.loading ? (
            <Page
                pageTitle={exercice.name}
                backPage="/training"
                actions={{ addForm: this.state.updateForm, deleteForm: this.state.deleteForm, formData: exercice }}
                refreshData={() => this.props.history.goBack()}
                tab={[{
                    title: t("General"),
                    visible: true,
                    data: exercice,
                    component: () => {
                        return (<>
                            <div className="flex space-x-5">
                                <div className="w-full py-3 px-10">
                                    <Card
                                        className=" "
                                    // title={t("General")}
                                    // headerActions={
                                    //     <GeneratedForm
                                    //         key="createPeriodicReportSection"
                                    //         component={(show, text) => (
                                    //             <ButtonLight onClick={show} disabled={!navigator.onLine}>
                                    //                 <i className="flaticon2-plus"></i> {text}
                                    //             </ButtonLight>
                                    //         )}
                                    //         callback={(res) =>
                                    //             loadPeriodicReportSections(id).then((sections) => {
                                    //                 this.setState({ refresh: true })
                                    //                 return (sections ? this.setState({ sections, refresh: false }) : [])
                                    //             })
                                    //         }
                                    //         formName="createPeriodicReportSection"
                                    //         formData={exercice}
                                    //     />
                                    // }
                                    >
                                        <DisplayData3cols
                                            data={[{
                                                key: t("Name"),
                                                value: exercice.name
                                            },
                                            {
                                                key: t("Category - Theme"),
                                                value: category.name + " - " + theme.name
                                            },
                                            {
                                                key: t("Duration"),
                                                value: exercice.duration + " min",
                                            },
                                            {
                                                key: t("Goals"),
                                                value: exercice.goals
                                            },
                                            {
                                                key: t("Author"),
                                                value: author
                                                    ? <Link
                                                        className="text-indigo-700 font-semibold focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                        to={"/group/staff/" + exercice.authorId}
                                                    >
                                                        <div className="mt-1 flex items-center">
                                                            <div className="flex-shrink-0">
                                                                <span>
                                                                    <span className="sr-only">{exercice.author.lastName.toUpperCase()}</span>
                                                                    <img className="h-6 w-6 2xl:h-10 2xl:w-10 rounded-full" src={exercice.author.photo} alt="" />
                                                                </span>
                                                            </div>
                                                            <div className="ml-3">
                                                                <p className="text-xs 2xl:text-sm font-medium text-gray-900">
                                                                    <span>
                                                                        {exercice.author.lastName.toUpperCase() + " " + exercice.author.firstName}
                                                                    </span>
                                                                </p>
                                                                <div className="flex space-x-1 text-xs 2xl:text-sm text-gray-500">
                                                                    {exercice.author.title}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                    : <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 64 64"
                                                        role="img"
                                                        className="h-6"
                                                        stroke="currentColor"
                                                    >
                                                        <path
                                                            data-name="layer1"
                                                            d="M50.4 51C40.5 49.1 40 46 40 44v-1.2a18.9 18.9 0 0 0 5.7-8.8h.1c3 0 3.8-6.3 3.8-7.3s.1-4.7-3-4.7C53 4 35.5-3.2 22.3 6c-5.4 0-5.9 8-3.9 16-3.1 0-3 3.8-3 4.7s.7 7.3 3.8 7.3c1 3.6 2.3 6.9 4.7 9v1.2c0 2 .5 5-9.5 6.8S2 62 2 62h60a14.6 14.6 0 0 0-11.6-11z"
                                                            fill="none"
                                                            strokeMiterlimit="10"
                                                            strokeWidth="2"
                                                            strokeLinejoin="round"
                                                            strokeLinecap="round"
                                                        />
                                                    </svg>,
                                            }]}
                                        />
                                        {/* <div className="mt-6 w-full mx-auto grid gap-8 lg:grid-cols-3 lg:max-w-none p-6 mb-20"></div> */}
                                        
                                    </Card>
                                </div>
                                
                            </div>
                            <div className="w-full px-6 py-6">
                                <Card
                                    title={t("Details")}
                                >
                                    <div className="grid grid-cols-2 my-3 mx-10">
                                
                                        <div
                                            className="p-4 mr-4"
                                        
                                        >
                                            {exercice.picture ?
                                                <ModalImage
                                                    small={exercice.picture}
                                                    large={exercice.picture}
                                                    alt={exercice.name}
                                                    className="h-72 w-full object-cover"
                                                /> : <div>{t("No picture has been uploaded on this exercise")}</div>}
                                        </div>
                                        <div className="p-4">
                                            <DisplayData1col 
                                                data={[
                                                    {key:t("Instructions"), value: exercice.instructions}
                                                ]}
                                            />
                                        </div>
                                    
                                    </div>
                                </Card>
                            </div>
                        </>
                        );
                    },
                }]}
            />
        ) : "";
    }
}

export default withTranslation()(withRouter(PeriodicReportDetails));