import db from "./db";
import get from "../../requests/get";

async function loadAgeGroups() {
  return get("common", "ageGroups").then((res) => {
    if (res) {
      db.ageGroups.bulkPut(res);
    }
    return res;
  });
}

async function loadBodyZones() {
  return get("common", "bodyZones").then((res) => {
    if (res) {
      db.bodyZones.bulkPut(res);
    }
    return res;
  });
}

async function loadColors() {
  return get("common", "colors").then((res) => {
    if (res) {
      db.colors.bulkPut(res);
    }
    return res;
  });
}

async function loadCountries() {
  return get("common", "countries").then((res) => {
    if (res) {
      db.countries.bulkPut(res);
    }
    return res;
  });
}

async function loadOrgs() {
  return get("common", "orgs").then((res) => {
    if (res) {
      db.orgs.put(res);
    }
    return res;
  });
}

async function loadSectors() {
  return get("common", "sectors").then((res) => {
    if (res) {
      db.sectors.bulkPut(res);
    }
    return res;
  });
}

async function loadSites() {
  return get("common", "sites").then((res) => {
    if (res) {
      db.sites.bulkPut(res);
    }
    return res;
  });
}

async function loadTennisClubs() {
  return get("common", "tennisClubs").then((res) => {
    if (res) {
      db.tennisClubs.bulkPut(res);
    }
    return res;
  });
}

async function loadTennisRegions() {
  return get("common", "tennisRegions").then((res) => {
    if (res) {
      db.tennisRegions.bulkPut(res);
    }
    return res;
  });
}

function getAgeGroups() {
  return db.ageGroups.toArray();
}

function getBodyZones() {
  return db.bodyZones.toArray();
}

function getColors() {
  return db.colors.toArray();
}

function getCountries() {
  return db.countries.toArray();
}

function getOrgs() {
  return db.orgs;
}

function getSectors() {
  return db.sectors.toArray();
}

function getSites() {
  return db.sites.toArray();
}

function getTennisClubs() {
  return db.tennisClubs.toArray();
}

function getTennisRegions() {
  return db.tennisRegions.toArray();
}

export {
  loadAgeGroups,
  loadBodyZones,
  loadColors,
  loadCountries,
  loadOrgs,
  loadSectors,
  loadSites,
  loadTennisClubs,
  loadTennisRegions,
  getAgeGroups,
  getBodyZones,
  getColors,
  getCountries,
  getOrgs,
  getSectors,
  getSites,
  getTennisClubs,
  getTennisRegions,
};
