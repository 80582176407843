import Dexie from "dexie";

const db = new Dexie("evaluation");
db.version(1).stores({
    anthropometricData: "++id, kind",
    anthropometricTargets: "++id, kind",
    anthropometricDataCurrent: "++id, kind",
    anthropometricMeasures: "++id",
    anthropometricMeasuresActive: "++id",
    physicalTestsData: "++id, kind",
    physicalTestsDataCurrent: "++id, kind",
    physicalTestsMeasures: "++id",
    physicalTestsMeasuresActive: "++id",
    physicalTestsCategories: "++id"
});

db.version(2).stores({
    threshold: "++id"
});

export default db;
