

import React from "react";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import Card from "../../../components/card/Card";
import moment from "moment";
import SubTab from "../../../components/tab/SubTab";
import TablePlayerProgrammation from "../../../tables/TablePlayerProgrammation";

class PlayerCompetitionSoccerTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.playerId,
      player: null,
      competitionTab: "Results",
      year: parseInt(moment().format("YYYY")),
      month: parseInt(moment().format("M")),
      loadRanking: true,
    };
  }
  componentDidMount() {
    // get id from url
    // var id = this.props.playerId;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.player !== this.props.player) {
      this.setState({ player: this.props.player });
    }
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <SubTab
          tab={[
            { key: "Results", label: t("Results") },
            { key: "Rankings", label: t("Rankings") },
            { key: "Programmation", label: t("Programmation") },
            { key: "Matches", label: t("Matches") },
          ]}
          selectedTab={this.state.competitionTab}
          onChange={(key) =>
            this.setState({
              competitionTab: key,
            })
          }
        />
        {this.state.prog && this.state.competitionTab === "Programmation" && (
          <div>
            <Card>
              <TablePlayerProgrammation />
            </Card>
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation()(withRouter(PlayerCompetitionSoccerTab));

