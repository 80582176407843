import React from "react";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import Page from "../../components/page/Page";
import Card from "../../components/card/Card";
import Button from "../../components/buttons/Button";
import GeneratedForm from "../../utils/forms/generateForm";
import {
  getSchool,
  loadSchool,
  loadCurriculumsBySchool,
  loadCurriculumsPursuedBySchool,
} from "../../utils/indexedDB/scholarship/handleScholarship";
import ButtonLight from "../../components/buttons/ButtonLight";
import TableSchoolCurriculums from "../../tables/TableSchoolCurriculums";
import TableSchoolCurriculumsPursued from "../../tables/TableSchoolCurriculumsPursued";
import { loadPlayers } from "../../utils/indexedDB/users/handleUser";
import DisplayData3cols from "../../components/displayData/DisplayData3cols";
import SVGEdit from "../../components/icons/SVGEdit";

class School extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      player: null,
    };
  }

  componentDidMount() {
    var id = this.props.match.params.id;
    getSchool(id).then((school) => (school ? this.setState({ school }) : null));
    if (navigator.onLine) {
      loadSchool(id).then((school) => this.setState({ school }));
      loadCurriculumsBySchool(id).then((curriculums) =>
        this.setState({ curriculums })
      );
      loadPlayers().then(players => 
        {
          loadCurriculumsPursuedBySchool(id).then((curriculumsPursued) =>
            {
              var curriculumsPursuedFiltered = [];
              curriculumsPursued.map(curriculumPursued => {
                if (players.find(o => o.id === curriculumPursued.playerId))
                {
                  curriculumPursued.player = players.find(o => o.id === curriculumPursued.playerId);
                  curriculumsPursuedFiltered.push(curriculumPursued);
                }
                return curriculumPursued;
              });
              this.setState({ curriculumsPursued: curriculumsPursuedFiltered })
            }
          );
        })
      
    }
  }

  render() {
    const { t } = this.props;

    return (
      <Page
        pageTitle={
          t("School") +
          (this.state.school ? " - " + this.state.school.name : "")
        }
        backPage="/group/players"
        actions={{
          other: [
            <GeneratedForm
              key="updateSchool"
              component={(show, text) => (
                <ButtonLight
                  onClick={show}
                  disabled={!navigator.onLine}
                >
                  <SVGEdit />
                </ButtonLight>
              )}
              callback={(res) => this.forceUpdate()}
              formName="updateSchool"
              formData={this.state.school}
            />
          ]
        }}
        tab={[
          {
            title: t("School"),
            visible: true,
            data: this.state.school,
            component: (data) => {
              return (
                <>
                  <div className="flex space-x-4">
                    <div className="w-full my-6 mx-10">
                      <Card
                      >
                        <DisplayData3cols
                          data={[
                            {
                              key: t("Name"),
                              value: this.state.school
                                ? this.state.school.name
                                : "-",
                            },
                            {
                              key: t("Address"),
                              value: this.state.school
                                ? this.state.school.address
                                : "-",
                            },
                            {
                              key: t("City"),
                              value: this.state.school
                                ? this.state.school.city
                                : "-",
                            },
                            {
                              key: t("Description"),
                              value: this.state.school
                                ? this.state.school.description
                                : "-",
                            },
                          ]}
                        />
                      </Card>
                    </div>
                  </div>
                </>
              );
            },
          },
          {
            title: t("Curriculums"),
            visible: true,
            data: this.state.curriculums,
            component: (data) => {
              return data ? (
                <>
                  <TableSchoolCurriculums
                    curriculums={data}
                    refresh={() => loadCurriculumsBySchool(this.state.id).then((curriculums) =>
                      this.setState({ curriculums })
                    )}
                    buttons={[
                      <GeneratedForm
                        key="createCurriculum"
                        component={(show, text) => (
                          <ButtonLight
                            onClick={show}
                            disabled={!navigator.onLine}
                            className="inline-flex items-center mx-2 px-4 py-2  rounded-md text-sm font-medium border text-indigo-700 border-indigo-700 hover:bg-indigo-700 hover:text-white hover:border-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          >
                            {text}
                          </ButtonLight>
                        )}
                        callback={(res) =>
                          loadCurriculumsBySchool(
                            this.state.id
                          ).then((curriculums) =>
                            this.setState({ curriculums })
                          )
                        }
                        formName="createCurriculum"
                        formData={{ schoolId: this.state.id }}
                      />,
                    ]}
                  />
                </>
              ) : null;
            },
          },
          {
            title: t("Curriculums pursued"),
            visible: true,
            data: this.state.curriculumsPursued,
            component: (data) => {
              return data ? (
                <>
                  <TableSchoolCurriculumsPursued
                    curriculumsPursued={data}
                    refresh={() => loadCurriculumsPursuedBySchool(
                           this.state.id
                         ).then((curriculumsPursued) =>
                           this.setState({ curriculumsPursued })
                         )}
                    buttons={[
                      <GeneratedForm
                        key="createCurriculumPursued"
                        component={(show, text) => (
                          <Button
                            onClick={show}
                            className="inline-flex items-center mx-2 px-4 py-2  rounded-md text-sm font-medium border text-indigo-700 border-indigo-700 hover:bg-indigo-700 hover:text-white hover:border-transparent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            disabled={!navigator.onLine}
                          >
                            {text}
                          </Button>
                        )}
                        callback={(res) =>
                          loadCurriculumsPursuedBySchool(
                            this.state.id
                          ).then((curriculumsPursued) =>
                            this.setState({ curriculumsPursued })
                          )
                        }
                        formName="createCurriculumPursued"
                        formData={{ schoolId: this.state.id }}
                      />,
                    ]}
                  />
                </>
              ) : null;
            },
          },
        ]}
      />
    );
  }
}

export default withTranslation()(withRouter(School));
