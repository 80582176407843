import Dexie from "dexie";

const db = new Dexie("Soccer-Competition");
db.version(1).stores({
  competitions: "++id",
  matchsInfos: "++id, eventId",
  matchsInfosByPlayer: "++matchParticipantId, matchId",
});
db.version(2).stores({
  chargeExterneNormalized: "++id, playerId, matchId",
});

export default db;
