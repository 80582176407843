import db from "./db";
import get from "../../requests/get";
import post from "../../requests/post";

async function loadAnthropometricDataCurrent(playerId) {
  return get("evaluation", "anthropometricDataCurrentByPlayer", playerId).then(
    (res) => {
      if (res) {
        res = res.map((tar) => {
          tar.kind = playerId;
          return tar;
        });
        db.anthropometricDataCurrent.bulkPut(res);
      }
      return res;
    }
  );
}

function loadAnthropometricDataCurrentByPlayers(ids) {
  return post("evaluation", "anthropometricDataCurrentByPlayers", { playersIds: ids }).then(res => { if (res) { return res.json() } }).then(
    (res) => {
      if (res) {
        res = res.map((tar) => {
          tar.kind = tar.playerId;
          return tar;
        });
        db.anthropometricDataCurrent.bulkPut(res);
      }
      return res;
    }
  );
}

function getAnthropometricDataCurrent(playerId) {
  if (db.anthropometricDataCurrent.toArray()) {
    return db.anthropometricDataCurrent
      .where({ kind: playerId })
      .toArray()
      .then((data) => {
        return data;
      });
  }
}

async function loadAnthropometricData(playerId) {
  return get("evaluation", "anthropometricDataByPlayer", playerId).then(
    (res) => {
      if (res) {
        res = res.map((tar) => {
          tar.kind = playerId;
          return tar;
        });
        db.anthropometricData.bulkPut(res);
      }
      return res;
    }
  );
}

function getAnthropometricData(playerId) {
  if (db.anthropometricData) {
    return db.anthropometricData
      .where({ kind: playerId })
      .toArray()
      .then((data) => {
        return data;
      });
  }
}

async function loadAnthropometricTargets(playerId) {
  return get("evaluation", "anthropometricTargetsByPlayer", playerId).then(
    (res) => {
      if (res) {
        res = res.map((tar) => {
          tar.kind = playerId;
          return tar;
        });
        db.anthropometricTargets.bulkPut(res);
      }
      return res;
    }
  );
}

function getAnthropometricTargets(playerId) {
  if (db.anthropometricTargets) {
    return db.anthropometricTargets
      .where({ kind: playerId })
      .toArray()
      .then((data) => {
        return data;
      });
  }
}

async function loadAnthropometricMeasures() {
  return get("evaluation", "anthropometricMeasures").then((res) => {
    if (res) {
      db.anthropometricMeasures.bulkPut(
        res.sort((a, b) =>
          a.lastName === b.order ? 0 : a.order > b.order ? 1 : -1
        )
      );
    }
    return res;
  });
}

function getAnthropometricMeasures() {
  if (db.anthropometricMeasures.toArray()) {
    return db.anthropometricMeasures.toArray();
  }
}

async function loadAnthropometricThreshold() {
  return get("evaluation", "threshold", "anthropometry").then((res) => {
    return res;
  });
}

async function loadPhysicalTestThreshold() {
  return get("evaluation", "threshold", "physicalTest").then((res) => {
    return res;
  });
}



async function loadAnthropometricMeasuresActive() {
  return get("evaluation", "anthropometricMeasuresActive").then((res) => {
    if (res) {
      db.anthropometricMeasuresActive.bulkPut(
        res.sort((a, b) =>
          a.lastName === b.order ? 0 : a.order > b.order ? 1 : -1
        )
      );
    }
    return res;
  });
}

function getAnthropometricMeasuresActive() {
  if (db.anthropometricMeasuresActive.toArray()) {
    return db.anthropometricMeasuresActive.toArray();
  }
}

async function loadPhysicalTestsDataCurrent(playerId) {
  return get("evaluation", "physicalTestsDataCurrentByPlayer", playerId).then(
    (res) => {
      if (res) {
        res = res.map((tar) => {
          tar.kind = playerId;
          return tar;
        });
        db.physicalTestsDataCurrent.bulkPut(res);
      }
      return res;
    }
  );
}

function loadPhysicalTestsDataCurrentByPlayers(ids) {
  return post("evaluation", "physicalTestsDataCurrentByPlayers", { playersIds: ids }).then(res => { if (res) { return res.json() } }).then(
    (res) => {
      if (res) {
        res = res.map((tar) => {
          tar.kind = tar.playerId;
          return tar;
        });
        db.physicalTestsDataCurrent.bulkPut(res);
      }
      return res;
    }
  );
}

function loadPhysicalTestsByPlayersAndDate(ids, date) {
  return post("evaluation", "PhysicalTestsDataByPlayersDate/" + date, { playersIds: ids });
}

function loadAnthropometricByPlayersAndDate(ids, date) {
  return post("evaluation", "AnthropometricDataByPlayersDate/" + date, { playersIds: ids });
}

function getPhysicalTestsDataCurrent(playerId) {
  if (db.physicalTestsDataCurrent.toArray()) {
    return db.physicalTestsDataCurrent
      .where({ kind: playerId })
      .toArray()
      .then((data) => {
        return data;
      });
  }
}

async function loadPhysicalTestsData(playerId) {
  return get("evaluation", "physicalTestsDataByPlayer", playerId).then(
    (res) => {
      if (res) {
        res = res.map((tar) => {
          tar.kind = playerId;
          return tar;
        });
        db.physicalTestsData.bulkPut(res);
      }
      return res;
    }
  );
}

function getPhysicalTestsData(playerId) {
  if (db.physicalTestsData) {
    return db.physicalTestsData
      .where({ kind: playerId })
      .toArray()
      .then((data) => {
        return data;
      });
  }
}

async function loadPhysicalTestsMeasures() {
  return get("evaluation", "physicalTestsMeasures").then((res) => {
    if (res) {
      res.map(a => {
        a.activeFilterDislpay = a.active ? "active" : "inactive"
        return a
      })
      db.physicalTestsMeasures.bulkPut(
        res.sort((a, b) =>
          a.order === b.order ? 0 : a.order > b.order ? 1 : -1
        )
      );
    }
    return res;
  });
}

function getPhysicalTestsMeasures() {
  if (db.physicalTestsMeasures.toArray()) {
    return db.physicalTestsMeasures.toArray();
  }
}

async function loadPhysicalTestsMeasuresActive() {
  return get("evaluation", "physicalTestsMeasuresActive").then((res) => {
    if (res) {
      db.physicalTestsMeasuresActive.bulkPut(
        res.sort((a, b) =>
          a.lastName === b.order ? 0 : a.order > b.order ? 1 : -1
        )
      );
    }
    return res;
  });
}

function getPhysicalTestsMeasuresActive() {
  if (db.physicalTestsMeasuresActive.toArray()) {
    return db.physicalTestsMeasuresActive.toArray();
  }
}

export {
  loadAnthropometricDataCurrent,
  loadAnthropometricDataCurrentByPlayers,
  loadAnthropometricData,
  loadAnthropometricMeasures,
  loadAnthropometricMeasuresActive,
  getAnthropometricDataCurrent,
  getAnthropometricData,
  getAnthropometricMeasures,
  getAnthropometricMeasuresActive,
  loadPhysicalTestsDataCurrent,
  loadPhysicalTestsDataCurrentByPlayers,
  loadPhysicalTestsData,
  loadPhysicalTestsMeasures,
  loadPhysicalTestsMeasuresActive,
  getPhysicalTestsDataCurrent,
  getPhysicalTestsData,
  getPhysicalTestsMeasures,
  getPhysicalTestsMeasuresActive,
  loadAnthropometricTargets,
  getAnthropometricTargets,
  loadPhysicalTestsByPlayersAndDate,
  loadAnthropometricByPlayersAndDate,
  loadAnthropometricThreshold,
  loadPhysicalTestThreshold
};
