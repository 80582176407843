import React from "react"
import { withTranslation } from "react-i18next"

import { DatePicker } from "../forms"

class FilterDate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
    }
  }

  render() {
    let btnShowHide = (
      <button
        onClick={() => this.setState({ visible: !this.state.visible })}
        className="w-4 2xl:w-5 mr-3"
        style={{ color: "#4B5563" }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
          />
        </svg>
      </button>
    )

    let datePickers = (
      <>
        <div className="py-2">{this.props.t("From")}</div>
        <DatePicker
          value={this.state.startDate}
          className="mx-2 rounded-md focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300"
          onChange={(startDate) => {
            this.props.onChange(startDate, null)
          }}
        />
        <div className="py-2">{this.props.t("To")}</div>
        <DatePicker
          value={this.state.endDate}
          className="mx-2 rounded-md focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300"
          onChange={(endDate) => {
            this.props.onChange(null, endDate)
          }}
        />
      </>
    )

    return (
      <>
        {btnShowHide}
        {this.state.visible ? datePickers : ""}
      </>
    )
  }
}

export default withTranslation()(FilterDate)
