import React from "react"
import { withRouter } from "react-router"
import { withTranslation } from "react-i18next"
import Page from "../components/page/Page"
import {
  getPlayers,
  loadPlayers,
  getStaffs,
  loadStaffs,
} from "../utils/indexedDB/users/handleUser"
import moment from "moment"
import Loading from "../components/loaders/Loading"
import TablePlayers from "../tables/TablePlayers"
import TableStaffs from "../tables/TableStaffs"
import GeneratedForm from "../utils/forms/generateForm";
import Button from "../components/buttons/Button";
import getUserProfile from "../utils/auth/getUserProfile";
import Tour from "reactour"


class PageGroup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      getPlayersFromAPI: false,
      getStaffsFromAPI: false,
      userId: "",
      isTourOpen: false
    }
    this.handlePlayers = this.handlePlayers.bind(this)
    this.handleStaffs = this.handleStaffs.bind(this)
    this.closeTour = this.closeTour.bind(this);
    this.openTour = this.openTour.bind(this);
    this.modifyTour = this.modifyTour.bind(this);
  }

  componentDidMount() {
    getUserProfile().then((profile) => {
      // var isAdmin = false
      // if (profile.role === "Manager" || profile.role === "Administratif" || profile.role === "Scolarite"
      //     || profile.role === "Commercial" || profile.role === "Finance" || profile.role === "President"
      //     || profile.role === "Tuteur")
      //     isAdmin = true
      this.setState({ userId: profile.sub, isAdmin: profile.isAdmin });
    });
    this.handlePlayers()
    this.handleStaffs()
  }

  handlePlayers() {
    this.setState({ getPlayersFromAPI: false });
    getPlayers().then((players) =>
      players && players.length && !this.state.getPlayersFromAPI
        ? this.setState({ players, loading: false })
        : null
    )
    if (navigator.onLine) {
      loadPlayers().then((players) =>
        players ? this.setState({ players, loading: false, getPlayersFromAPI: true }) : null
      )
    }
  }

  handleStaffs() {
    this.setState({ getStaffsFromAPI: false });
    getStaffs().then((staffs) =>
      staffs && staffs.length && !this.state.getStaffsFromAPI ? this.setState({ staffs, loading: false }) : null
    )

    if (navigator.onLine) {
      loadStaffs().then((staffs) =>
        staffs ? this.setState({ staffs, loading: false, getStaffsFromAPI: true }) : null
      )
    }
  }

  closeTour() {
    this.setState({ isTourOpen: false });
  }
  openTour() {
    this.setState({ isTourOpen: true });
  }
  modifyTour(value) {
    if(typeof value === "boolean")
      this.setState({ isTourOpen: value });
  }



  render() {
    const { t } = this.props
    var players = this.state.players
    if (players) {
      players = players.map((player) => {
        player.year = moment(player.dob).year()
        return player
      })
    }


    const steps = [
      {
        selector: ".groupTourStep1",
        content: t("Switch from one page to another using the tabs"),
      },
      {
        selector: ".groupTourStep2",
        content: t("Use the search bar to find a player quickly"),
      },
      {
        selector: ".groupTourStep3",
        content: t("Optimize your search by using the filter"),
      },
      {
        selector: ".groupTourStep4",
        content: t("Select the columns to display in the table"),
      },
      {
        selector: ".groupTourStep5",
        content: t("Export your data"),
      },
      {
        selector: ".groupTourStep6",
        content: t("Click to edit/delete information"),
      }
    ]
    
    return (
    <>
      <Tour
        steps={steps}
        isOpen={this.state.isTourOpen}
        onRequestClose={this.closeTour}
      />
      <Page
        closeTour={() => this.closeTour()}
        openTour={() => this.openTour()}
        modifyTour={(value) => this.modifyTour(value)}
        haveReactTour={true}
        tab={[
          {
            title: t("Players"),
            visible: true,
            data: players
              ? this.state.players.map((p) => {
                p.groupLabel = p.group1 ? p.group1.name : t("Other")
                return p
              })
              : null,
            component: (data) => {
              return this.state.loading ? (
                <div style={{ height: "80vh", width: "100%" }}>
                  <Loading />
                </div>
              ) : (
                  // <Table columns={columnsP} data={data} />
                  <TablePlayers players={data} callback={() => this.handlePlayers()} buttons={[
                    <GeneratedForm
                      component={(show, text, disabled, tooltip) => (
                        <Button
                          onClick={show}
                          disabled={disabled}
                          title={tooltip}
                        >
                          {text}
                        </Button>
                      )}
                      callback={(res) => {
                        this.handlePlayers();
                      }}
                      formName="createPlayer"
                      method="POST"
                    />]} />
                )
            },
          },
          {
            title: t("Staffs"),
            visible: true,
            data: this.state.staffs
              ? this.state.staffs.map((s) => {
                s.groupLabel = s.groupPrincipal
                  ? s.groupPrincipal.name
                  : t("Other")
                s.roleLabel = s.role ? s.role.name : t("Other")
                s.categoryLabel =
                  s.role && s.role.category
                    ? s.role.category.name
                    : t("Other")
                return s
              })
              : null,
            component: (data) => {
              return this.state.loading ? (
                <Loading />
              ) : (
                  <TableStaffs staffs={data} userId={this.state.userId} isAdmin={this.state.isAdmin} callback={() => this.handleStaffs()} buttons={[
                    <GeneratedForm
                      component={(show, text, disabled, tooltip) => (
                        <Button
                          onClick={show}
                          disabled={disabled}
                          title={tooltip}
                        >
                          {text}
                        </Button>
                      )}
                      callback={(res) => { 
                        this.handleStaffs();
                      }}
                      formName="createStaff"
                      method="POST"
                    />]} />
                )
            },
          },
          // {
          //   title: t("Group"),
          //   data: null,
          //   // actions: {
          //   //     addForm: "createStaff",
          //   //     refreshData: this.handleStaffs()
          //   // },
          //   component: (data) => {
          //     return this.state.loading ? (
          //       <Loading />
          //     ) : (
          //       <></>
          //       /* <Table columns={columnsS} data={data} /> */
          //     );
          //   },
          // },
        ]}
      />
    </>
    )
  }
}

export default withTranslation()(withRouter(PageGroup))
