import React from "react";
import ActionButton from "../components/buttons/ActionButton";
import { withTranslation } from "react-i18next";
import { SelectMultiple } from "../components/forms";
import TableSimple from "../components/table/TableSimple";
import FiltersSearch from "../components/filters/FiltersSearch";
import SearchInput from "../components/forms/input/SearchInput";
import ExportToCSV from "../components/export/ExportToCSV";
import FilterColumns from "../components/filters/FilterColumns";
import FilterSeparator from "../components/filters/FilterSeparator";
import FiltersTitle from "../components/filters/FiltersTitle";
import FiltersBar from "../components/filters/FiltersBar";
class TablePlayerAnthropometricDataTargets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actions: [],
      columns: [],
      searchText: "",
    };
  }

  componentDidMount() {
    this.prepareData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.prepareData();
    }
  }

  prepareData = () => {
    var targets = this.props.targets;
    var measures = this.props.measures;
    const columns = [
      {
        key: "name",
        visible: true,
        pinned: "left",
        label: this.props.t("Measure"),
        filter: {
          name: "SelectColumnFilter",
          type: "includes",
        },
        id: "name",
        format: (row) => <span className=" text-xs 2xl:text-sm">{row.name}</span>
      },
      {
        key: "comparerId",
        visible: true,
        pinned: "left",
        label: this.props.t("Operator"),
        id: "comparerId",
        format: (row) =>
          targets != null &&
            targets.length > 0 &&
            targets.find((o) => o.measureId === row.id)
            ? <span className=" text-xs 2xl:text-sm">{targets.find((o) => o.measureId === row.id)
              .comparerId}</span>
            : <span className=" text-xs 2xl:text-sm">-</span>,
      },
      {
        key: "value",
        visible: true,
        pinned: "left",
        label: this.props.t("Target value"),
        id: "value",
        format: (row) =>
          <div className="flex justify-between text-xs 2xl:text-sm">
            <span>
              {targets != null &&
                targets.length > 0 &&
                targets.find((o) => o.measureId === row.id)
                ? targets.find((o) => o.measureId === row.id).value +
                (row.unit ? row.unit : "")
                : "-"}</span>
            <ActionButton
              key={row.id}
              updateForm="updateAnthropometricTarget"
              deleteForm="deleteAnthropometricTarget"
              formData={targets.find(
                (o) => o.measureId === row.id
              )}
              refreshData={
                this.props.refresh

              }
            />
          </div>
        ,
      },
      {
        key: "description",
        visible: true,
        pinned: "left",
        label: this.props.t("Description"),
        id: "description",
        format: (row) =>
          targets != null &&
            targets.length > 0 &&
            targets.find((o) => o.measureId === row.id)
            ? <span className=" text-xs 2xl:text-sm">{targets.find((o) => o.measureId === row.id).comments}</span>
            : <span className=" text-xs 2xl:text-sm">-</span>,
      }
    ];
    var selectedColumns = [];
    columns.forEach((column) => {
      if (column.visible) {
        selectedColumns.push(column.key);
      }
    });
    this.setState({
      columns,
      targets,
      measures,
      selectedColumns,
    });
  };

  render() {
    const { t } = this.props;
    var measures = this.props.measures;
    var columns = this.state.columns;
    var selectedColumns = this.state.selectedColumns;
    var searchText = this.state.searchText;
    if (columns) {
      columns = columns.filter((o) =>
        selectedColumns.some((item) => item === o.key)
      );
    }
    if (measures && searchText.length > 2) {
      measures = measures.filter((o) =>
        o.name.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    return (
      <>
        <FiltersBar
          components={
            <>
              <FiltersTitle title={measures ? measures.length + " " + t("Measures").toLowerCase() : ""} />
              <FilterSeparator />
              <FiltersSearch search={<SearchInput
                value={this.state.searchText}
                onChange={(searchText) => this.setState({ searchText })}
                
              />} />
              <FilterSeparator />
              <FilterColumns
                columns={
                  <SelectMultiple
                    options={this.state.columns}
                    keys={this.state.selectedColumns}
                    className="mx-2"
                    onChange={(res) => this.setState({ selectedColumns: res })}
                    fixedLabel={t("Active columns")}
                  />
                }
              />
              <FilterSeparator />
              <ExportToCSV columns={columns} jsonData={measures} fileName={t("Anthropometry") + ".csv"} />
            </>
          }
          buttons={this.props.buttons}
        />
        <TableSimple columns={columns} data={measures} />
      </>
    );
  }
}

export default withTranslation()(TablePlayerAnthropometricDataTargets);
