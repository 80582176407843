import React from "react";
import { withTranslation } from "react-i18next";
import { post, patch, del, get } from "../../../requests/index";
import Button from "../../../../components/buttons/Button";
import getUserProfile from "../../../auth/getUserProfile";
import Table from "../../../../components/table/EditableTable";
import { getPhysicalDrillsByGroup, loadPhysicalDrillsByGroup, loadPhysicalSequences } from "../../../indexedDB/training/handleTraining";
import SVG from "react-inlinesvg";
import SvgTrash from "../../../../assets/sass/vendors/theme/svg/icons/General/Trash.svg";
import SvgExo from "../../../../assets/sass/vendors/theme/svg/icons/General/Duplicate.svg";
import { Input, Select, TextArea } from "../../../../components/forms";
import * as Drawer from "../../../../components/drawer";
import cogoToast from "cogo-toast";
import ExerciceCard from "../../../../components/card/ExerciceCard";
import { loadStaffs, loadGroups, getGroups, getStaffs } from "../../../indexedDB/users/handleUser";
import ButtonLight from "../../../../components/buttons/ButtonLight";
import TableSessionTemplates from "../../../../tables/TableSessionTemplates";
import { getPhysicalSessionTemplates, loadPhysicalSessionTemplates } from "../../../indexedDB/training/handleTraining";

class StepSequences extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sequences: null,
      profile: "",
      themes: {},
      categories: [],
      loading: true,
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.handleCopySessionTemplate = this.handleCopySessionTemplate.bind(this);
  }

  componentDidMount() {
    getUserProfile().then((profile) => {
      this.setState({ profile: profile.sub });
    });
    getStaffs().then((authors) => {
      if (authors)
        this.setState({ authors })
    })
    getGroups().then((groups) => {
      if (groups.length > 0)
        getPhysicalDrillsByGroup(groups[0].id).then((drills) => {
          if (drills) {
            drills.sort((a, b) =>
              a.physicalTheme.physicalCategory.name === b.physicalTheme.physicalCategory.name ? (
                a.physicalTheme.name === b.physicalTheme.name ? (
                  a.name === b.name ? 0 : a.name > b.name ? 1 : -1
                ) : a.physicalTheme.name > b.physicalTheme.name ? 1 : -1
              ) : a.physicalTheme.physicalCategory.name > b.physicalTheme.physicalCategory.name ? 1 : -1
            );
            this.setState({ drills, selectedGroup: groups[0].id, groups })
          }
        })
    })
    getPhysicalSessionTemplates().then((sessionTemplatesPhysical) => {
      if (sessionTemplatesPhysical)
        this.setState({ sessionTemplatesPhysical })
    })

    if (this.props.formData && this.props.formData.id) {
      var id = this.props.formData.id;
      if (navigator.onLine) {
        loadStaffs().then((authors) => {
          loadGroups().then((groups) => {
            groups = groups.map((g) => {
              g.key = g.id;
              g.label = g.name;
              return g;
            })
            loadPhysicalDrillsByGroup(groups[0].id, authors).then((drills) => {
              this.setState({ drills, selectedGroup: groups[0].id })
            });
            this.setState({ groups, authors })
          })
        })
        loadPhysicalSequences(id, this.props.template).then((sequences) => {
          if (sequences && sequences.length) {
            sequences = sequences.map((t) => {
              t.physicalCategoryId = t.physicalTheme
                ? t.physicalTheme.physicalCategoryId
                : "";
              t.physicalThemeId = t.physicalTheme ? t.physicalTheme.id : "";
              return t;
            });

            this.setState({
              sequences: Object.assign([], sequences),
              loading: false,
            });
          } else {
            sequences = [];
            var newSequence = {
              name: "",
              description: "",
              duration: 0,
              goals: "",
              targets: "",
              instructions: "",
              physicalThemeId: "",
              organizerId: this.state.profile,
            };
            sequences.push(newSequence);
            this.setState({ sequences, loading: false });
          }
        });

        get("training", "physicalCategories").then((categories) => {
          if (categories) {
            categories.forEach((cat) => {
              get("training", "physicalThemesByCategory", cat.id).then(
                (themesAPI) => {
                  var themes = this.state.themes ? this.state.themes : {};
                  themes[cat.id] = themesAPI;
                  this.setState({ themes });
                }
              );
            });
          }
          this.setState({ categories: categories ? categories : [] });
        });
        loadPhysicalSessionTemplates().then((sessionTemplatesPhysical) => {
          this.setState({ sessionTemplatesPhysical })
        })
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({ loading: true });
      var sequences = this.state.sequences;
      if (!sequences || !sequences.length) {
        sequences = [];
        var newSequence = {
          name: "",
          description: "",
          duration: 0,
          goals: "",
          targets: "",
          instructions: "",
          physicalThemeId: "",
          organizerId: this.state.profile,
        };
        sequences.push(newSequence);
      }
      this.setState({ sequences, loading: false });
    }
  }

  changeSelectedGroup(groupId, authors) {
    getPhysicalDrillsByGroup(groupId).then((drills) => {
      if (drills) {
        drills.sort((a, b) =>
          a.physicalTheme.physicalCategory.name === b.physicalTheme.physicalCategory.name ? (
            a.physicalTheme.name === b.physicalTheme.name ? (
              a.name === b.name ? 0 : a.name > b.name ? 1 : -1
            ) : a.physicalTheme.name > b.physicalTheme.name ? 1 : -1
          ) : a.physicalTheme.physicalCategory.name > b.physicalTheme.physicalCategory.name ? 1 : -1
        );
        this.setState({ drills })
      }
    })
    this.setState({ selectedGroup: groupId })
    if (navigator.onLine) {
      loadPhysicalDrillsByGroup(groupId, authors).then((drills) => {
        this.setState({ drills })
      });
    }
  }

  handleSubmit() {
    this.setState({ loading: true });
    var module = "schedule"
    var feature = "physicalSequences"
    if (this.props.template) {
      module = "training"
      feature = "physicalSessionTemplateSequences"
    }
    getUserProfile().then((profile) => {
      var sequences = this.state.sequences;
      var id = this.props.formData.id;
      loadPhysicalSequences(id, this.props.template).then((originalSequences) => {
        var index = originalSequences ? originalSequences.length : 1;
        var promises = [];
        sequences.forEach((seq) => {
          if (seq.physicalThemeId && seq.physicalThemeId !== "") {
            if (seq.id) {
              var original = originalSequences.find((o) => o.id === seq.id);
              if (original !== seq) {
                seq.eventId = this.props.formData.id;
                seq.physicalSessionTemplateId = this.props.formData.id;
                seq.duration = parseInt(seq.duration);
                promises.push(patch(module, feature, seq));
              }
            } else {
              seq.eventId = this.props.formData.id;
              seq.physicalSessionTemplateId = this.props.formData.id;
              seq.duration = parseInt(seq.duration);
              seq.order = index;
              index++;
              promises.push(post(module, feature, seq));
            }
          }
        });

        if (originalSequences) {
          originalSequences.forEach((o) => {
            if (!sequences.find((s) => s.id === o.id)) {
              promises.push(del(module, feature, o));
            }
          });
        }

        if (promises && promises.length) {
          Promise.all(promises)
            .then((res) => {
              if (res) {
                var errors = [];
                res.forEach((r) => {
                  if (!r.ok) {
                    errors.push(r.status + " - " + r.url);
                  }
                });
                if (errors && errors.length) {
                  this.setState({ errors, loading: false });
                } else {
                  cogoToast.success(this.props.t("Submit Successed"), {
                    position: "bottom-right",
                  });
                  this.setState({ loading: false });
                  this.props.nextStep(this.props.formData, this.props.hide);
                }
              } else {
                throw new Error("500");
              }
            })
            .catch((err) => {
              this.setState({ errors: [err.toString()], loading: false });
            });
        } else {
          this.setState({ loading: false });
          this.props.nextStep(this.props.formData, this.props.hide);
        }
      });
    });
  }

  handleDelete(row) {
    var sequences = this.state.sequences;
    if (sequences && sequences.find((seq) => seq === row)) {
      sequences = sequences.filter((seq) => seq !== row);
    }
    if (!sequences || !sequences.length) {
      sequences = [];
      var newSequence = {
        name: "",
        description: "",
        duration: 0,
        goals: "",
        targets: "",
        instructions: "",
        physicalThemeId: "",
        organizerId: this.state.profile,
      };
      sequences.push(newSequence);
    }
    this.setState({ sequences });
  }

  handleAdd() {
    var sequences = this.state.sequences ? this.state.sequences : [];
    var newSequence = {
      name: "",
      description: "",
      duration: 0,
      goals: "",
      targets: "",
      instructions: "",
      physicalThemeId: "",
      organizerId: this.state.profile,
    };
    sequences.push(newSequence);
    this.setState({ sequences });
  }

  handleCopyDrill(ex, t) {
    var sequences = this.state.sequences ? this.state.sequences : [];
    var newSequence = {
      name: ex.name,
      description: ex.description,
      duration: ex.duration,
      goals: ex.goals ? ex.goals : ex.targets,
      targets: ex.goals ? ex.goals : ex.targets,
      instructions: ex.instructions,
      physicalThemeId: ex.physicalThemeId,
      physicalCategoryId: ex.physicalTheme.physicalCategory.id,
      physicalDrillId: ex.id,
      organizerId: this.state.profile,
    };
    sequences.push(newSequence);
    this.setState({ sequences });
    cogoToast.success(t("Inserted"))
  }

  handleCopySessionTemplate(row, t) {
    let sequences = this.state.sequences ? this.state.sequences : [];
    row.sequences.forEach((ex) => {
      let newSequence = {
        name: ex.name,
        description: ex.description,
        duration: ex.duration,
        goals: ex.goals ? ex.goals : ex.targets,
        targets: ex.goals ? ex.goals : ex.targets,
        instructions: ex.instructions,
        physicalThemeId: ex.physicalThemeId,
        physicalCategoryId: ex.physicalTheme.physicalCategory.id,
        physicalDrillId: ex.id,
        organizerId: this.state.profile,
      };
      sequences.push(newSequence);
    })
    this.setState({ sequences });
    cogoToast.success(t("Inserted"))
  }

  render() {
    const { t } = this.props;
    const columns = [
      {
        key: "name",
        label: t("Title"),
        id: "name",
        maxWidth: 200,
        format: (row) => (
          <Input
            className="form-control w-full rounded-md border-gray-200"
            value={row.name}
            onChange={(value) => {
              var sequences = Object.assign([], this.state.sequences);
              var index = sequences.findIndex((seq) => seq === row);
              var sequence = sequences[index];
              sequence.name = value;
              sequences[index] = sequence;
              this.setState({ sequences });
            }}
          />
        ),
      },
      {
        key: "physicalCategoryId",
        label: t("Category") + "*",
        format: (row) => (
          <Select
            value={row.physicalCategoryId}
            options={this.state.categories.map((cat) => {
              return { key: cat.id, label: cat.name };
            })}
            onChange={(cat) => {
              var sequences = Object.assign([], this.state.sequences);
              var index = sequences.findIndex((seq) => seq === row);
              var sequence = sequences[index];
              sequence.physicalCategoryId = cat;
              sequences[index] = sequence;
              this.setState({ sequences });
            }}
          />
        ),
      },
      {
        key: "physicalThemeId",
        label: t("Theme") + "*",
        width: 250,
        format: (row) =>
          this.state.themes &&
            row.physicalCategoryId &&
            this.state.themes[row.physicalCategoryId] &&
            this.state.themes[row.physicalCategoryId].length ? (
            <Select
              value={row.physicalThemeId}
              options={this.state.themes[row.physicalCategoryId].map((th) => {
                return { key: th.id, label: th.name };
              })}
              onChange={(th) => {
                var sequences = Object.assign([], this.state.sequences);
                var index = sequences.findIndex((seq) => seq === row);
                var sequence = sequences[index];
                sequence.physicalThemeId = th;
                sequences[index] = sequence;
                this.setState({ sequences });
              }}
            />
          ) : (
            "-"
          ),
      },
      {
        key: "duration",
        label: t("Duration"),
        maxWidth: 100,
        format: (row) => (
          <Input
            className="form-control w-full rounded-md border-gray-200"
            value={row.duration}
            onChange={(value) => {
              var sequences = Object.assign([], this.state.sequences);
              var index = sequences.findIndex((seq) => seq === row);
              var sequence = sequences[index];
              sequence.duration = value;
              sequences[index] = sequence;
              this.setState({ sequences });
            }}
          />
        ),
      },
      {
        key: "instructions",
        label: t("Intructions"),
        format: (row) => (
          <TextArea
            className="form-control w-full rounded-md border-gray-200 text-xs 2xl:text-sm"
            value={row.instructions}
            onChange={(value) => {
              var sequences = Object.assign([], this.state.sequences);
              var index = sequences.findIndex((seq) => seq === row);
              var sequence = sequences[index];
              sequence.instructions = value;
              sequences[index] = sequence;
              this.setState({ sequences });
            }}
          />
        ),
      },
      {
        key: "delete",
        maxWidth: 50,
        format: (row) => (
          <Button
            className="btn btn-sm btn-clean btn-icon mr-2"
            onClick={() => this.handleDelete(row)}
          >
            <span className="svg-icon svg-icon-md">
              <SVG src={SvgTrash} />
            </span>
          </Button>
        ),
      },
    ];
    return (
      <>
        <Drawer.Header hide={this.props.hide}>{t("Sequences")}</Drawer.Header>
        <Drawer.Body>
          <>
            {this.state.errors ? (
              <div className="alert alert-danger">
                {t("Error")}
                {this.state.errors.map((error, key) => {
                  return <div key={key}>{error}</div>;
                })}
              </div>
            ) : (
              ""
            )}
            <Table
              columns={columns}
              data={this.state.sequences}
              height={500}
              handleAdd={this.handleAdd}
            />
            <div className="w-full flex justify-center mt-8">
              <Drawer.Layout
                key="drill"
                className="w-screen"
                component={(show) => (
                  <ButtonLight
                    onClick={show}
                  >
                    <SVG src={SvgExo} /> {t("Insert drills")}
                  </ButtonLight>
                )}
              >
                {(hide) => (
                  <>
                    <Drawer.Header hide={hide}>
                      {t("Inport drills into Session")}
                    </Drawer.Header>
                    <div className="mt-6 w-full mx-auto gap-8 ">
                      <Select
                        options={this.state.groups}
                        value={this.state.selectedGroup}
                        className="mx-2"
                        onChange={(res) => this.changeSelectedGroup(res, this.state.authors)}
                        fixedLabel={t("Period")}
                      />
                    </div>
                    <div className="mt-6 w-full mx-auto grid gap-8 lg:grid-cols-3 lg:max-w-none p-6 mb-20">
                      {this.state.drills && this.state.drills.length > 0 ? this.state.drills.map((ex) =>
                        <Button
                          className="btn"
                          onClick={() => this.handleCopyDrill(ex, t)}
                        >
                          <ExerciceCard
                            exercice={ex}
                            type={"physical"}
                            button={true}
                          />
                        </Button>
                      ) : t("No existing drills")}
                    </div>
                  </>
                )}
              </Drawer.Layout>
              <Drawer.Layout
                key="session"
                className="w-screen"
                component={(show) => (
                  <ButtonLight
                    onClick={show}
                  >
                    <SVG src={SvgExo} /> {t("Insert session template")}
                  </ButtonLight>
                )}
              >
                {(hide) => (
                  <>
                    <Drawer.Header hide={hide}>
                      {t("Inport drills into Session")}
                    </Drawer.Header>
                    {this.state.sessionTemplatesPhysical && this.state.sessionTemplatesPhysical.length > 0 ?
                      <TableSessionTemplates
                        sessions={this.state.sessionTemplatesPhysical}
                        groups={this.state.groups}
                        type={"physical"}
                        button={true}
                        handleAdd={(row) => this.handleCopySessionTemplate(row, t)}
                        hide={hide}
                      />
                      : t("No existing session templates")}
                  </>
                )}
              </Drawer.Layout>
            </div>
          </>
        </Drawer.Body>
        <Drawer.Footer>
          <>
            <Button
              loading={this.state.loading}
              onClick={(event) => {
                this.handleSubmit(event);
              }}
            >
              {t("Submit")}
            </Button>
            {!this.props.step ? (
              <Button
                onClick={(event) => {
                  this.props.nextStep(this.props.formData, this.props.hide);
                }}
              >
                {t("Next time")}
              </Button>
            ) : null}
          </>
        </Drawer.Footer>
      </>
    );
  }
}

export default withTranslation()(StepSequences);
