import React from "react"
import { Link } from "react-router-dom"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import timeGridPlugin from "@fullcalendar/timegrid"
import interactionPlugin from "@fullcalendar/interaction"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import moment from "moment"
import Button from "../buttons/Button"
import { Popover, OverlayTrigger } from "react-bootstrap";
import {
  getTypes,
  loadTypes,
  loadEventsByPlayer,
  getEventsByPlayer,
  getCyclesByPlayer,
  loadCyclesByPlayer,
  loadWeeksByPlayer,
  getWeeksByPlayer,
  copyEvent,
} from "../../utils/indexedDB/events/handleEvents"

import EventForm from "../../utils/forms/events/EventForm"

import BulkCopyDelition from "../../utils/forms/events/BulkCopyDeletion"
import { patch } from "../../utils/requests"
import cogoToast from "cogo-toast"
import { getPlayers, loadPlayers } from "../../utils/indexedDB/users/handleUser"
import generateColor from "../../utils/generateColor"
import Loading from "../loaders/Loading"
import Accordion from "./options/Accordion"
import { Select } from "../forms"


class Calendar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      events: null,
      alreadyGetFromAPI: false,

      filters: null,
      filtersOpened: this.props.defaultFiltersOpened === false ? false : true,

      players: [],
      playersFiltered: [],
      playersSelected: [],
      playersOpened: true,
      playersSearchBar: false,

      types: [],
      typesFiltered: [],
      typesSelected: [],
      typesOpened: true,
      typesSearchBar: false,

      form: {
        method: null,
        formData: this.props.formData
          ? Object.assign({}, this.props.formData)
          : null,
      },
      bulkForm: {
        method: null,
      },
      allDay: false,
      defaultView: this.props.defaultView ? this.props.defaultView : null,
      defaultDate: this.props.defaultDate
        ? moment(this.props.defaultDate)
        : null,
    }
    this.handleEvents = this.handleEvents.bind(this)
    this.renderEventContent = this.renderEventContent.bind(this)
    this.calendarRef = React.createRef()
    this.copyEvent = copyEvent.bind(this)

  }

  componentDidMount() {
    if (
      localStorage.getItem(this.props.match.path + " - Calendar defaultDate")
    ) {
      this.setState({
        defaultDate: moment(
          localStorage.getItem(
            this.props.match.path + " - Calendar defaultDate"
          )
        ),
      })
    } else {
      localStorage.setItem(
        this.props.match.path + " - Calendar defaultDate",
        moment()
      )
      this.setState({ defaultDate: moment() })
    }
    if (
      localStorage.getItem(this.props.match.path + " - Calendar defaultView")
    ) {
      this.setState({
        defaultView: localStorage.getItem(
          this.props.match.path + " - Calendar defaultView"
        ),
      })
    } else {
      localStorage.setItem(
        this.props.match.path + " - Calendar defaultView",
        "timeGridWeek"
      )
      this.setState({ defaultView: "timeGridWeek" })
    }
    getTypes().then((types) => {
      if (types && types.length) {
        if (!types.find((t) => t.id === "cycle")) {
          types.push({ name: this.props.t("Cycle"), id: "cycle" })
        }
        if (!types.find((t) => t.id === "week")) {
          types.push({ name: this.props.t("Week"), id: "week" })
        }
        types = types.sort((a, b) => a.order - b.order)
        this.setState({ types })
        if (this.props.typesId) {
          this.setState({ typesSelected: this.props.typesId }, () =>
            this.reloadCalendar()
          )
        } else {
          var typesSelected = JSON.parse(
            localStorage.getItem("eventTypesSelected")
          )
          if (!typesSelected) {
            typesSelected = types.map((t) => t.id)
          }
          this.setState({ typesSelected }, () => this.reloadCalendar())
        }
      } else if (navigator.onLine) {
        loadTypes().then((types) => {
          if (types) {
            types.push({ name: this.props.t("Cycle"), id: "cycle" })
            types.push({ name: this.props.t("Week"), id: "week" })
            types = types.sort((a, b) => a.order - b.order)
            this.setState({ types })
            if (this.props.typesId) {
              this.setState({ typesSelected: this.props.typesId }, () =>
                this.reloadCalendar()
              )
            } else {
              var typesSelected = JSON.parse(
                localStorage.getItem("eventTypesSelected")
              )
              if (!typesSelected) {
                typesSelected = types.map((t) => t.id)
              }
              this.setState({ typesSelected: typesSelected }, () =>
                this.reloadCalendar()
              )
            }
          }
        })
      }
    })
    var form = this.state.form
    if (!form.formData) {
      form.formData = {}
    }
    if (this.props.choosePlayer === false) {
      form.formData.attendees = this.props.playersId
      this.setState(
        {
          form,
          playersSelected: this.props.playersId ? this.props.playersId : [],
          loading: false,
        },
        () => this.reloadCalendar()
      )
    } else {
      getPlayers().then((players) => {
        var playersLS = JSON.parse(localStorage.getItem("playersSelected"))
        if (players && players.length) {
          players = players.map((p) => {
            p.color = generateColor(p.displayName)
            return p
          })
          if (playersLS && playersLS.length) {
            form.formData.attendees = playersLS
          } else {
            form.formData.attendees = [players.map((p) => p.id)[0]]
          }
          this.props.callbackFormData(form.formData)
          this.setState(
            {
              form,
              players,
              playersSelected: this.props.playersId
                ? this.props.playersId
                : playersLS
                  ? playersLS
                  : [players.map((p) => p.id)[0]],
              loading: false,
            },
            () => this.reloadCalendar()
          )
        } else {
          loadPlayers().then((players) => {
            players = players.map((p) => {
              p.color = generateColor(p.displayName)
              return p
            })
            if (playersLS && playersLS.length) {
              form.formData.attendees = playersLS
            } else {
              form.formData.attendees = [players.map((p) => p.id)[0]]
            }
            this.props.callbackFormData(form.formData)
            this.setState(
              {
                form,
                players,
                playersSelected: this.props.playersId
                  ? this.props.playersId
                  : playersLS
                    ? playersLS
                    : [players.map((p) => p.id)[0]],
                loading: false,
              },
              () => this.reloadCalendar()
            )
          })
        }
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.reloadCalendar !== this.props.reloadCalendar) {
      this.reloadCalendar()
    }
  }

  reloadCalendar() {
    let calendarApi = this.calendarRef.current.getApi()
    calendarApi.refetchEvents()
  }

  handleEventClick = (event) => {
    if (event.event.extendedProps) {
      if (event.event.extendedProps.nextUrl) {
        var location = {
          pathname: event.event.extendedProps.nextUrl,
          state: {
            data: this.state.events
              ? this.state.events.find((e) => e.id === event.event.id)
              : null,
          },
        }
        this.props.history.push(location)
      }
    }
  }

  renderEventContent(eventInfo, t, show) {
    var eventForm = eventInfo.event.extendedProps
    let evtId = "event-" + eventInfo.event.id
    var reactBootstrapPopover = ( // to delete when we found a solution for z index
      <Popover className="bg-white rounded-md shadow-2xl p-2 z-20 border-2 border-gray-50">
        <Popover.Title className="text-base 2xl:text-base font-medium text-gray-900 border-b pb-2">{eventInfo.event.title.toUpperCase()}</Popover.Title>
        <Popover.Content className="mt-1 text-sm 2xl:text-sm text-gray-500">
          <b>{t("Type")} :</b> {eventInfo.event.extendedProps.type ? eventInfo.event.extendedProps.type.name : eventInfo.event.extendedProps.typeId}<br />
          <b>{t("Start date")} :</b> {eventInfo.event.extendedProps.startDate ? moment(eventInfo.event.extendedProps.startDate).format("HH:mm") : "-"}<br />
          <b>{t("End date")} :</b> {eventInfo.event.extendedProps.endDate ? moment(eventInfo.event.extendedProps.endDate).format("HH:mm") : "-"}<br />
          <b>{t("Description")} :</b> {eventInfo.event.extendedProps.description ? eventInfo.event.extendedProps.description : "-"}<br />
          <b>{t("Player")} :</b> {eventInfo.event.extendedProps.playerName ? eventInfo.event.extendedProps.playerName : "-"}<br />
        </Popover.Content>
        <Popover.Content className="flex justify-between border-t mt-3">
          {eventInfo.event.extendedProps.nextUrl ?
            <Link to={eventInfo.event.extendedProps.nextUrl}>
              <Button className="inline-flex items-center p-2 border border-transparent rounded-full text-gray-400 hover:bg-gray-100">
                <svg className="h-3 2xl:h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z" />
                </svg>
              </Button>
            </Link>
            : null}
          {eventForm.specificInfos || eventForm.specificInfos === null ?
            <>
              <Button
                disabled={!navigator.onLine}
                className="inline-flex items-center p-2 border border-transparent rounded-full text-gray-400 hover:bg-gray-100"
                onClick={() => {
                  this.setState(
                    {
                      form: {
                        method: "PATCH",
                        formData: eventForm,
                        step: "information",
                      },
                    },
                    () => show()
                  )
                }}
              >
                <svg
                  className="h-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                  />
                </svg>
              </Button>
              <Button
                disabled={!navigator.onLine}
                className="inline-flex items-center p-2 border border-transparent rounded-full text-gray-400 hover:bg-gray-100"
                onClick={() => {
                  this.setState(
                    {
                      form: {
                        method: "PATCH",
                        formData: eventForm,
                        step: "invitation",
                      },
                    },
                    () => show()
                  )
                }}
              >
                <svg
                  className="h-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                  />
                </svg>
              </Button>
              <Button
                disabled={!navigator.onLine}
                className="inline-flex items-center p-2 border border-transparent rounded-full text-gray-400 hover:bg-gray-100"
                onClick={() => {
                  this.copyEvent(eventForm.id)
                }}
              >
                <svg
                  className="h-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
                  />
                </svg>
              </Button>
              <Button
                disabled={!navigator.onLine}
                className="inline-flex items-center p-2 border border-transparent rounded-full text-gray-400 hover:bg-gray-100"
                onClick={() => {
                  this.setState(
                    {
                      form: {
                        method: "DELETE",
                        formData: eventForm,
                        step: "deletion",
                      },
                    },
                    () => show()
                  )
                }}
              >
                <svg
                  className="h-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                  />
                </svg>
              </Button>
            </>
            : null}
        </Popover.Content>
      </Popover>
    )
    // var tooltipContent = (
    //   <div className="w-50">
    //     <h2 className="text-base font-medium text-gray-900 border-b pb-2">
    //       {eventInfo.event.title.toUpperCase()}
    //     </h2>
    //     <div className="mt-1 text-sm text-gray-500">
    //       <b>{t("Type")} :</b>{" "}
    //       {eventInfo.event.extendedProps.type
    //         ? eventInfo.event.extendedProps.type.name
    //         : eventInfo.event.extendedProps.typeId}
    //       <br />
    //       <b>{t("Start date")} :</b>{" "}
    //       {eventInfo.event.extendedProps.startDate
    //         ? moment(eventInfo.event.extendedProps.startDate).format("HH:mm")
    //         : "-"}
    //       <br />
    //       <b>{t("End date")} :</b>{" "}
    //       {eventInfo.event.extendedProps.endDate
    //         ? moment(eventInfo.event.extendedProps.endDate).format("HH:mm")
    //         : "-"}
    //       <br />
    //       <b>{t("Description")} :</b>{" "}
    //       {eventInfo.event.extendedProps.description
    //         ? eventInfo.event.extendedProps.description
    //         : "-"}
    //       <br />
    //       <b>{t("Player")} :</b>{" "}
    //       {eventInfo.event.extendedProps.playerName
    //         ? eventInfo.event.extendedProps.playerName
    //         : "-"}
    //       <br />
    //     </div>
    //     <div className="flex justify-between border-t mt-2">
    //       {eventInfo.event.extendedProps.nextUrl ? (
    //         <Link to={eventInfo.event.extendedProps.nextUrl}>
    //           <Button className="inline-flex items-center p-2 border border-transparent rounded-full text-gray-400 hover:bg-gray-100">
    //             <svg
    //               className="h-6"
    //               xmlns="http://www.w3.org/2000/svg"
    //               fill="none"
    //               viewBox="0 0 24 24"
    //               stroke="currentColor"
    //             >
    //               <path
    //                 strokeLinecap="round"
    //                 strokeLinejoin="round"
    //                 strokeWidth="2"
    //                 d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z"
    //               />
    //             </svg>
    //           </Button>
    //         </Link>
    //       ) : null}
    //       {eventForm.specificInfos || eventForm.specificInfos === null ? (
    //         <>
    //           <Button
    //             disabled={!navigator.onLine}
    //             className="inline-flex items-center p-2 border border-transparent rounded-full text-gray-400 hover:bg-gray-100"
    //             onClick={() => {
    //               this.setState(
    //                 {
    //                   form: {
    //                     method: "PATCH",
    //                     formData: eventForm,
    //                     step: "information",
    //                   },
    //                 },
    //                 () => show()
    //               )
    //             }}
    //           >
    //             <svg
    //               className="h-6"
    //               xmlns="http://www.w3.org/2000/svg"
    //               fill="none"
    //               viewBox="0 0 24 24"
    //               stroke="currentColor"
    //             >
    //               <path
    //                 strokeLinecap="round"
    //                 strokeLinejoin="round"
    //                 strokeWidth="2"
    //                 d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
    //               />
    //             </svg>
    //           </Button>
    //           <Button
    //             disabled={!navigator.onLine}
    //             className="inline-flex items-center p-2 border border-transparent rounded-full text-gray-400 hover:bg-gray-100"
    //             onClick={() => {
    //               this.setState(
    //                 {
    //                   form: {
    //                     method: "PATCH",
    //                     formData: eventForm,
    //                     step: "invitation",
    //                   },
    //                 },
    //                 () => show()
    //               )
    //             }}
    //           >
    //             <svg
    //               className="h-6"
    //               xmlns="http://www.w3.org/2000/svg"
    //               fill="none"
    //               viewBox="0 0 24 24"
    //               stroke="currentColor"
    //             >
    //               <path
    //                 strokeLinecap="round"
    //                 strokeLinejoin="round"
    //                 strokeWidth="2"
    //                 d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
    //               />
    //             </svg>
    //           </Button>
    //           <Button
    //             disabled={!navigator.onLine}
    //             className="inline-flex items-center p-2 border border-transparent rounded-full text-gray-400 hover:bg-gray-100"
    //             onClick={() => {
    //               this.copyEvent(eventForm.id)
    //             }}
    //           >
    //             <svg
    //               className="h-6"
    //               xmlns="http://www.w3.org/2000/svg"
    //               fill="none"
    //               viewBox="0 0 24 24"
    //               stroke="currentColor"
    //             >
    //               <path
    //                 strokeLinecap="round"
    //                 strokeLinejoin="round"
    //                 strokeWidth="2"
    //                 d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
    //               />
    //             </svg>
    //           </Button>
    //           <Button
    //             disabled={!navigator.onLine}
    //             className="inline-flex items-center p-2 border border-transparent rounded-full text-gray-400 hover:bg-gray-100"
    //             onClick={() => {
    //               this.setState(
    //                 {
    //                   form: {
    //                     method: "DELETE",
    //                     formData: eventForm,
    //                     step: "deletion",
    //                   },
    //                 },
    //                 () => show()
    //               )
    //             }}
    //           >
    //             <svg
    //               className="h-6"
    //               xmlns="http://www.w3.org/2000/svg"
    //               fill="none"
    //               viewBox="0 0 24 24"
    //               stroke="currentColor"
    //             >
    //               <path
    //                 strokeLinecap="round"
    //                 strokeLinejoin="round"
    //                 strokeWidth="2"
    //                 d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
    //               />
    //             </svg>
    //           </Button>
    //         </>
    //       ) : null}
    //     </div>
    //   </div>
    // )

    var reactBootstrapOverlayTrigger = (<OverlayTrigger placement="top" overlay={reactBootstrapPopover} trigger="click" rootClose rootCloseEvent="mousedown">
      <div id={evtId} style={{ height: "inherit" }}>
        <p onClick={() => eventInfo.event.extendedProps.nextUrl ? this.props.history.push(eventInfo.event.extendedProps.nextUrl) : null}>
          <b>{eventInfo.event.title}</b>{" "}<span className="text-xs">{eventInfo.timeText}</span>
        </p>
      </div>
    </OverlayTrigger>);

    // var newContent = (
    //   <TooltipOnclick tooltipContent={tooltipContent}>
    //     {(onClick) =>
    //       <div id={evtId} className="cursor-pointer" style={{ height: "inherit", width: "-webkit-fill-available" }} onClick={onClick}>
    //         {this.state.defaultView === "dayGridMonth" ? (
    //           <Tooltip tooltipContent={eventInfo.timeText + " - " + eventInfo.event.title}>
    //             <div className="inline-block flex items-center truncate relative z-0" onClick={() =>
    //               eventInfo.event.extendedProps.nextUrl
    //                 ? this.props.history.push(eventInfo.event.extendedProps.nextUrl)
    //                 : null
    //             }>

    //               <div className="fc-daygrid-event-dot" style={{ borderColor: eventInfo.event.backgroundColor }}>
    //               </div><div className="fc-event-time">{eventInfo.timeText}</div>
    //               <div className="fc-event-title truncate">{eventInfo.event.title}</div>
    //             </div>
    //           </Tooltip>
    //         )
    //           :
    //           <p className="z-0"
    //             onClick={() =>
    //               eventInfo.event.extendedProps.nextUrl
    //                 ? this.props.history.push(eventInfo.event.extendedProps.nextUrl)
    //                 : null
    //             }
    //           >
    //             <b>{eventInfo.event.title}</b>{" "}
    //             <span className="text-xs">{eventInfo.timeText}</span>
    //           </p>
    //         }

    //       </div>
    //     }
    //   </TooltipOnclick>
    // )
    if (!(this.state.defaultView === "dayGridMonth" && ["week", "cycle"].includes(eventInfo.event.extendedProps.typeId))) {
      return reactBootstrapOverlayTrigger
    }
  }

  getFormattedEvents(events) {
    var eventsFormatted = []
    events &&
      events.length > 0 &&
      events.forEach((event) => {
        if (event) {
          eventsFormatted.push({
            id: event.id,
            title: event.name,
            start: new Date(moment(event.startDate)),
            end: new Date(moment(event.endDate)),
            className: "border border-transparent p-1",
            backgroundColor: event.color
              ? event.color
              : event.type
                ? event.type.color
                : event.category
                  ? event.category.colorId
                  : null,
            extendedProps: {
              nextUrl: event.link ? event.link : "",
              ...event,
            },
            allDay: event.allDay ? event.allDay : false
          })
        }
      })
    return eventsFormatted
  }

  handleEvents(dates, callback) {
    if (
      this.state.playersSelected &&
      this.state.playersSelected.length &&
      this.state.typesSelected &&
      this.state.typesSelected.length
    ) {
      var promises = []
      var typesSelected = this.state.typesSelected
      if (!typesSelected.includes("cycle") && !typesSelected.includes("week")) {
        this.setState({ allDay: false })
      } else {
        this.setState({ allDay: true })
      }
      var players = this.state.players
      if (navigator.onLine) {
        this.setState({ loading: true })
        this.state.playersSelected.forEach((p) => {
          promises.push(
            loadEventsByPlayer(p, dates.startStr, dates.endStr).then(
              (eventsFromPlayer) => {
                return eventsFromPlayer && eventsFromPlayer.length
                  ? eventsFromPlayer.map((eP) => {
                    if (
                      this.state.playersSelected &&
                      this.state.playersSelected.length === 1
                    ) {
                      eP.className = eP.class
                        ? eP.class
                        : "fc-event-light fc-event-solid-primary "
                    } else {
                      eP.color = players.find(
                        (player) => player.id === p
                      ).color
                      eP.playerName = players.find(
                        (player) => player.id === p
                      ).displayName
                    }
                    return eP
                  })
                  : null
              }
            )
          )
          if (typesSelected.includes("cycle")) {
            promises.push(
              loadCyclesByPlayer(p, dates.startStr, dates.endStr).then(
                (cycles) => {
                  return cycles.map((c) => {
                    c.typeId = "cycle"
                    if (
                      this.state.playersSelected &&
                      this.state.playersSelected.length === 1
                    ) {
                      c.className = c.class
                        ? c.class
                        : "fc-event-light fc-event-solid-primary "
                    } else {
                      c.color = players.find((player) => player.id === p).color
                      c.playerName = players.find(
                        (player) => player.id === p
                      ).displayName
                    }
                    return c
                  })
                }
              )
            )
          }
          if (typesSelected.includes("week")) {
            promises.push(
              loadWeeksByPlayer(p, dates.startStr, dates.endStr).then(
                (cycles) => {
                  return cycles.map((c) => {
                    c.typeId = "week"
                    if (
                      this.state.playersSelected &&
                      this.state.playersSelected.length === 1
                    ) {
                      c.className = c.class
                        ? c.class
                        : "fc-event-light fc-event-solid-primary "
                    } else {
                      c.color = players.find((player) => player.id === p).color
                      c.playerName = players.find(
                        (player) => player.id === p
                      ).displayName
                    }
                    return c
                  })
                }
              )
            )
          }
        })
        Promise.all(promises).then((eventsList) => {
          var events = []
          typesSelected = this.state.typesSelected
          eventsList.forEach((el) => {
            if (el && el.length) {
              el.forEach((e) => {
                if (typesSelected.includes(e.typeId)) {
                  events.push(e)
                }
              })
            }
          })
          this.setState({ loading: false, events })
          callback(this.getFormattedEvents(events))
        })
      } else {
        this.setState({ loading: true })
        this.state.playersSelected.forEach((p) => {
          promises.push(
            getEventsByPlayer(p, dates.startStr, dates.endStr).then(
              (eventsFromPlayer) => {
                return eventsFromPlayer && eventsFromPlayer.length
                  ? eventsFromPlayer.map((eP) => {
                    if (
                      this.state.playersSelected &&
                      this.state.playersSelected.length === 1
                    ) {
                      eP.className = eP.class
                        ? eP.class
                        : "fc-event-light fc-event-solid-primary "
                    } else {
                      eP.color = players.find(
                        (player) => player.id === p
                      ).color
                      eP.playerName = players.find(
                        (player) => player.id === p
                      ).displayName
                    }
                    return eP
                  })
                  : null
              }
            )
          )
          if (typesSelected.includes("cycle")) {
            promises.push(
              getCyclesByPlayer(p, dates.startStr, dates.endStr).then(
                (cycles) => {
                  return cycles.map((c) => {
                    c.typeId = "cycle"
                    if (
                      this.state.playersSelected &&
                      this.state.playersSelected.length === 1
                    ) {
                      c.className = c.class
                        ? c.class
                        : "fc-event-light fc-event-solid-primary"
                    } else {
                      c.color = players.find((player) => player.id === p).color
                      c.playerName = players.find(
                        (player) => player.id === p
                      ).displayName
                    }
                    return c
                  })
                }
              )
            )
          }
          if (typesSelected.includes("week")) {
            promises.push(
              getWeeksByPlayer(p, dates.startStr, dates.endStr).then(
                (cycles) => {
                  return cycles.map((c) => {
                    c.typeId = "week"
                    if (
                      this.state.playersSelected &&
                      this.state.playersSelected.length === 1
                    ) {
                      c.className = c.class
                        ? c.class
                        : "fc-event-light fc-event-solid-primary "
                    } else {
                      c.color = players.find((player) => player.id === p).color
                      c.playerName = players.find(
                        (player) => player.id === p
                      ).displayName
                    }
                    return c
                  })
                }
              )
            )
          }
        })
        Promise.all(promises).then((eventsList) => {
          var events = []
          typesSelected = this.state.typesSelected
          eventsList.forEach((el) => {
            if (el && el.length) {
              el.forEach((e) => {
                if (typesSelected.includes(e.typeId)) {
                  events.push(e)
                }
              })
            }
          })
          this.setState({ loading: false, events })
          callback(this.getFormattedEvents(events))
        })
      }
    } else {
      this.setState({ loading: false })
      callback([])
    }
  }


  render() {
    const { t } = this.props
    
    return this.state.defaultDate && this.state.defaultView ? (
    <>
      <BulkCopyDelition
        getEventsFromAPI={(start, end) => {
          var promises = []
          this.state.playersSelected.forEach((p) => {
            promises.push(loadEventsByPlayer(p, start, end))
          })
          return Promise.all(promises).then((eventsList) => {
            var events = []
            var typesSelected = this.state.typesSelected
            eventsList.forEach((el) => {
              if (el && el.length) {
                el.forEach((e) => {
                  if (typesSelected.includes(e.typeId)) {
                    events.push(e)
                  }
                })
              }
            })
            return events
          })
        }}
        callback={() => {
          let calendarApi = this.calendarRef.current.getApi()
          calendarApi.refetchEvents()
        }}
        component={(showBulk) => (
          <>
            <EventForm
              hide={() => {
                this.setState({
                  form: {
                    method: null,
                    formData: this.props.formData
                      ? Object.assign({}, this.props.formData)
                      : null,
                  },
                })
              }}
              callback={() => {
                this.reloadCalendar()
              }}
              component={(show) => (
                <div>
                  {this.state.loading ? <Loading /> : null}
                  <div className="relative z-10 flex-shrink-0 flex h-10 2xl:h-12 bg-white border-b border-gray-200 py-3">
                    <button
                      type="button"
                      className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-900 CalendarStep4"
                      onClick={() => {
                        this.setState({
                          filtersOpened: !this.state.filtersOpened,
                        })
                      }}
                    >
                      <svg
                        className="h-4 w-4 2xl:h-6 2xl:w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
                        />
                      </svg>
                    </button>
                    <div className="flex-1 flex justify-between">
                      <div className="flex-1 flex">
                        <button
                          type="button"
                          onClick={() => {
                            let calendarApi = this.calendarRef.current.getApi()
                            calendarApi.today()
                            localStorage.setItem(
                              this.props.match.path + " - Calendar defaultDate",
                              moment()
                            )
                            this.setState({ defaultDate: moment() })
                          }}
                          className="inline-flex items-center pr-3 pl-3 border-r border-gray-200 text-sm 2xl:text-base font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          {t("Today")}
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            let calendarApi = this.calendarRef.current.getApi()
                            calendarApi.prev()
                            var day = null
                            if (
                              ["dayGridWeek", "timeGridWeek"].includes(
                                this.state.defaultView
                              )
                            ) {
                              day = this.state.defaultDate
                                .subtract(1, "weeks")
                                .startOf("isoWeek")
                            } else if (
                              ["dayGridDay", "timeGridDay"].includes(
                                this.state.defaultView
                              )
                            ) {
                              day = this.state.defaultDate.subtract(1, "days")
                            } else if (
                              this.state.defaultView === "dayGridMonth"
                            ) {
                              day = this.state.defaultDate
                                .subtract(1, "months")
                                .startOf("month")
                            }
                            localStorage.setItem(
                              this.props.match.path + " - Calendar defaultDate",
                              day
                            )
                            this.setState({ defaultDate: day })
                          }}
                          className="inline-flex items-center pr-2 pl-2 2xl:pr-3 2xl:pl-3 border-r border-gray-200 text-base font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          <svg
                            className="h-4 w-4 2xl:h-6 2xl:w-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M15 19l-7-7 7-7"
                            />
                          </svg>
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            let calendarApi = this.calendarRef.current.getApi()
                            calendarApi.next()
                            var day = null
                            if (
                              ["dayGridWeek", "timeGridWeek"].includes(
                                this.state.defaultView
                              )
                            ) {
                              day = this.state.defaultDate
                                .add(1, "weeks")
                                .startOf("isoWeek")
                            } else if (
                              ["dayGridDay", "timeGridDay"].includes(
                                this.state.defaultView
                              )
                            ) {
                              day = this.state.defaultDate.add(1, "days")
                            } else if (
                              this.state.defaultView === "dayGridMonth"
                            ) {
                              day = this.state.defaultDate
                                .add(1, "months")
                                .startOf("month")
                            }
                            localStorage.setItem(
                              this.props.match.path + " - Calendar defaultDate",
                              day
                            )
                            this.setState({ defaultDate: day })
                          }}
                          className="inline-flex items-center pr-2 pl-2 2xl:pr-3 2xl:pl-3 border-r border-gray-200 text-base font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          <svg
                            className="h-4 w-4 2xl:h-6 2xl:w-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M9 5l7 7-7 7"
                            />
                          </svg>
                        </button>
                      </div>
                      <div className="flex">
                        <div className="items-center inline-flex px-3 2xl:px-5 border-r border-gray-200 text-sm 2xl:text-base font-medium text-gray-700 bg-white">
                          {["dayGridWeek", "timeGridWeek"].includes(
                            this.state.defaultView
                          )
                            ? this.state.defaultDate.format("MMMM YYYY")
                            : ["dayGridDay", "timeGridDay"].includes(
                              this.state.defaultView
                            )
                              ? this.state.defaultDate.format("dddd D MMMM YYYY")
                              : this.state.defaultView === "dayGridMonth"
                                ? this.state.defaultDate.format("MMMM YYYY")
                                : null}
                        </div>
                        <Select
                          className="items-center pr-3 pl-3 2xl:pr-5 2xl:pl-5 flex-1 border-r border-gray-200 text-sm 2xl:text-base font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          classButton="text-sm 2xl:text-base font-medium inline-flex items-center"
                          value={this.state.defaultView}
                          options={[
                            { label: t("Day"), key: "timeGridDay" },
                            { label: t("Week"), key: "timeGridWeek" },
                            { label: t("Month"), key: "dayGridMonth" },
                          ]}
                          onChange={(value) => {
                            let calendarApi = this.calendarRef.current.getApi()
                            localStorage.setItem(
                              this.props.match.path + " - Calendar defaultView",
                              value
                            )
                            this.setState({ defaultView: value })
                            calendarApi.changeView(value)
                          }}
                        />
                        <button
                          type="button"
                          className="inline-flex items-center pr-3 pl-3 border-r border-gray-200 text-base font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 CalendarStep5"
                          onClick={() => {
                            if (navigator.onLine) {
                              this.setState(
                                {
                                  bulkForm: {
                                    method: "COPY",
                                  },
                                },
                                () => showBulk()
                              )
                            }
                          }}
                        >
                          <svg
                            className="h-4 w-4 2xl:h-6 2xl:w-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
                            />
                          </svg>
                        </button>
                        <button
                          type="button"
                          className="inline-flex items-center pr-3 pl-3 border-r border-gray-200 text-base font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 CalendarStep6"
                          onClick={() => {
                            if (navigator.onLine) {
                              this.setState(
                                {
                                  bulkForm: {
                                    method: "DELETE",
                                  },
                                },
                                () => showBulk()
                              )
                            }
                          }}
                        >
                          <svg
                            className="h-4 w-4 2xl:h-6 2xl:w-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                            />
                          </svg>
                        </button>
                        <button
                          type="button"
                          className="inline-flex items-center pr-3 pl-3 border-gray-200 text-base font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          onClick={() => {
                            if (navigator.onLine) {
                              this.reloadCalendar()
                            }
                          }}
                        >
                          <svg
                            className="h-4 w-4 2xl:h-6 2xl:w-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="flex-1 relative z-0 flex">
                    {this.props.filters && this.state.filtersOpened ? (
                      <div className="relative xl:order-first xl:flex xl:flex-col w-50 border-r border-gray-200 CalendarStep2">
                        {this.props.choosePlayer === false ? null : (
                          <Accordion
                            data={this.state.players}
                            dataSelected={this.state.playersSelected}
                            property="displayName"
                            title={t("Players")}
                            callback={(playersSelected) => {
                              var form = this.state.form
                              if (form.formData) {
                                form.formData.attendees = playersSelected
                              } else {
                                form.formData = {}
                                form.formData.attendees = playersSelected
                              }
                              this.props.callbackFormData(form.formData)
                              this.setState({ playersSelected, form }, () =>
                                this.reloadCalendar()
                              )
                              localStorage.setItem(
                                "playersSelected",
                                JSON.stringify(playersSelected)
                              )
                            }}
                          />
                        )}
                        {this.state.types && this.state.types.length ? (
                          <Accordion
                            data={this.state.types}
                            dataSelected={this.state.typesSelected}
                            property="name"
                            title={t("Categories")}
                            callback={(typesSelected) => {
                              this.setState({ typesSelected }, () =>
                                this.reloadCalendar()
                              )
                              localStorage.setItem(
                                "eventTypesSelected",
                                JSON.stringify(typesSelected)
                              )
                            }}
                          />
                        ) : null}
                      </div>
                    ) : null}
                    <div className="flex-1 relative z-0 overflow-y-auto focus:outline-none xl:order-last CalendarStep3">
                      <FullCalendar
                        eventContent={(eventInfo) =>
                          this.renderEventContent(eventInfo, t, show)
                        }
                        plugins={[
                          dayGridPlugin,
                          timeGridPlugin,
                          interactionPlugin,
                        ]}
                        ref={this.calendarRef}
                        dayMaxEventRows={5}
                        initialView={this.state.defaultView}
                        allDaySlot={this.state.allDay}
                        headerToolbar={null}
                        scrollTime={moment().startOf("hour").format("HH:mm:ss")}
                        slotDuration="00:15:00"
                        slotLabelInterval="01:00"
                        firstDay={1}
                        events={this.handleEvents}
                        initialDate={new Date(this.state.defaultDate)}
                        expandRows={true}
                        locale={this.props.i18n.language}
                        eventClick={(eventInfo) => {
                          if (this.state.defaultView === "dayGridMonth" && ["week", "cycle"].includes(eventInfo.event.extendedProps.typeId) && eventInfo.event.extendedProps.nextUrl) {
                            this.props.history.push(eventInfo.event.extendedProps.nextUrl)
                          }
                        }}
                        selectMirror={navigator.onLine}
                        selectable={navigator.onLine}
                        editable={navigator.onLine}
                        nowIndicator
                        businessHours={{
                          daysOfWeek: [1, 2, 3, 4, 5],
                          startTime: "08:00",
                          endTime: "20:00",
                        }}
                        slotEventOverlap={false}
                        select={(el) => {
                          var startDate = moment(el.startStr)
                          var endDate = moment(el.endStr)
                          this.setState({ startDate, endDate })
                          var form = this.state.form
                          form.step = null
                          form.method = "POST"
                          form.formData = form.formData
                            ? form.formData
                            : this.props.formData
                              ? Object.assign({}, this.props.formData)
                              : {}
                          form.formData.endDate =
                            startDate.format("YYYY-MM-DD") ===
                              endDate.format("YYYY-MM-DD")
                              ? endDate
                              : startDate.clone().hours(23).minutes(59)
                          form.formData.startDate = startDate
                          if (
                            this.props.typesId &&
                            this.props.typesId.length === 1
                          ) {
                            form.formData.typeId = this.props.typesId[0]
                          }
                          this.setState({ form }, () => show())
                        }}
                        eventChange={(el) => {
                          var event = this.state.events
                            ? this.state.events.find(
                              (e) => e.id === el.oldEvent.extendedProps.id
                            )
                            : null
                          event.startDate = moment(el.event.startStr)
                          event.endDate =
                            el.event.endStr && moment(el.event.endStr).isValid()
                              ? moment(el.event.endStr)
                              : moment(event.endDate)
                          if (event) {
                            if (
                              event.startDate.format("YYYY-MM-DD") !==
                              event.endDate.format("YYYY-MM-DD")
                            ) {
                              event.endDate = event.startDate.clone()
                              event.endDate.hours(23)
                              event.endDate.minutes(59)
                            }
                            event.startDate = event.startDate.format(
                              "YYYY-MM-DDTHH:mm:ss"
                            )
                            event.endDate = event.endDate.format(
                              "YYYY-MM-DDTHH:mm:ss"
                            )
                            patch("schedule", "events", event).then((res) => {
                              this.reloadCalendar()
                              if (res.ok) {
                                cogoToast.success(
                                  this.props.t("Update Successed"),
                                  { position: "bottom-right" }
                                )
                              } else {
                                cogoToast.error(
                                  this.props.t("Error") + " : " + res.status,
                                  { position: "bottom-right" }
                                )
                              }
                            })
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
              {...this.state.form}
            />
          </>
        )}
        {...this.state.bulkForm}
      />
    </>
    ) : null
  }
}

export default withTranslation()(withRouter(Calendar))
