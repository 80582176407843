import React from "react";
import { withTranslation } from "react-i18next";
import TableSimple from "../components/table/TableSimple";
import moment from "moment";
import FiltersSearch from "../components/filters/FiltersSearch";
import SearchInput from "../components/forms/input/SearchInput";
import FilterColumns from "../components/filters/FilterColumns";
import ExportToCSV from "../components/export/ExportToCSV";
import { SelectMultiple } from "../components/forms";
import FilterSeparator from "../components/filters/FilterSeparator";
import FiltersTitle from "../components/filters/FiltersTitle";
import FiltersBar from "../components/filters/FiltersBar";

class TablePlayerPhysicalTestsDataCurrent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actions: [],
      columns: [],
      searchText: "",
      sort_field: "name",
      sort_direction: 1,
      measures: [],
    };
  }

  componentDidMount() {
    this.prepareData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.prepareData();
    }
  }

  generateFormatHeader(key) {
    return (label) => {
      return (
        <b
          className="cursor-pointer"
          onClick={() => {
            this.setState(
              {
                sort_field: key,
                sort_direction: -1 * this.state.sort_direction,
              },
              () => {
                this.prepareData()
              }
            )
          }}
        >
          {label}
          {key ? (
            this.state.sort_direction === -1 ? (
              <span className="text-lg">&#8661;</span>
            ) : (
              <span className="text-lg">&#8661;</span>
            )
          ) : (
            ""
          )}
        </b>
      )
    }
  }

  prepareData = () => {
    var physicalTestsData = this.props.data && this.props.data.length
      ? this.props.data.filter(d => {
        if (this.state.startDate && this.state.startDate > d.date) return false
        if (this.state.endDate && this.state.endDate < d.date) return false
        return true
      })
      : this.props.data;
    var field = this.state.sort_field
    var direction = this.state.sort_direction
    var measures = JSON.parse(JSON.stringify(this.props.measures))
    if (
      physicalTestsData != null &&
      physicalTestsData.length > 0 &&
      measures != null
    ) {
      for (var i = 0; i < measures.length; i++) {
        for (var j = 0; j < physicalTestsData.length; j++) {
          if (measures[i].id === physicalTestsData[j].measureId) {
            measures[i].value = physicalTestsData[j].value
            measures[i].delta = physicalTestsData[j].delta
            measures[i].var = physicalTestsData[j].var
            measures[i].date = physicalTestsData[j].date
            measures[i].thresholdColor = physicalTestsData[j].thresholdColor
            break
          }
        }
      }
    }
    measures.sort((a, b) => {
      if (!a[field]) return 1
      if (!b[field]) return -1
      return a[field] < b[field] ? direction : -1 * direction
    })
    const columns = [
      {
        key: "name",
        visible: true,
        pinned: "left",
        label: this.props.t("Measure"),
        id: "name",
        format: (row) => <span className=" text-xs 2xl:text-sm">{row.name}</span>,
        formatHeader: this.generateFormatHeader("name"),
      },
      {
        key: "value",
        visible: true,
        pinned: "left",
        label: this.props.t("Last value"),
        id: "value",
        format: (row) => <span className="text-xs 2xl:text-sm">{
          row.value ? (row.thresholdColor ? <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-white" style={{ backgroundColor: row.thresholdColor }}>
            {row.value + row.unit}
          </span> : (row.value + (row.unit ? " " + row.unit : ""))) : "-"}</span>,
        formatHeader: this.generateFormatHeader("value"),
      },
      {
        key: "delta",
        visible: true,
        pinned: "left",
        label: this.props.t("Delta"),
        id: "delta",
        format: (row) => {
          if (!row.delta) return <span className=" text-xs 2xl:text-sm">-</span>
          if (row.delta < 0) return <span className=" text-xs 2xl:text-sm">{row.delta.toFixed(2)}</span>
          return <span className=" text-xs 2xl:text-sm">{"+" + row.delta.toFixed(2)}</span>
        },
        formatHeader: this.generateFormatHeader("delta"),
      },
      {
        key: "var",
        visible: true,
        pinned: "left",
        label: this.props.t("Var"),
        id: "var",
        format: (row) => {
          if (!row.var) return <span className=" text-xs 2xl:text-sm">-</span>
          if (row.var < 0) return <span className=" text-xs 2xl:text-sm">{row.var.toFixed(2) + "%"}</span>
          return <span className=" text-xs 2xl:text-sm">{"+" + row.var.toFixed(2) + "%"}</span>
        },
        formatHeader: this.generateFormatHeader("var"),
      },
      {
        key: "date",
        visible: true,
        pinned: "left",
        label: this.props.t("Date"),
        id: "date",
        format: (row) => {
          if (!row.date) return <span className=" text-xs 2xl:text-sm">-</span>
          return <span className=" text-xs 2xl:text-sm">{moment(row.date).calendar()}</span>
        },
        formatHeader: this.generateFormatHeader("date"),
      },
    ];

    var selectedColumns = localStorage.getItem("TablePlayerPhysical_selectedColumns")
    if (selectedColumns === null || selectedColumns.length === 0) selectedColumns = ["name", "value", "delta", "var", "date"]
    else selectedColumns = selectedColumns.split("|")
    columns.forEach((column) => {
      column.visible = selectedColumns.indexOf(column.key) < 0 ? false : true

    });
    this.setState({
      columns,
      physicalTestsData,
      measures,
      selectedColumns,
    });
  };

  // change columns
  onChangeSelectedColumns = (res) => {
    this.setState({ selectedColumns: res }, () => {
      // determiner ce qui est selectionne
      let select = []
      this.state.columns.forEach((a) => {
        if (this.state.selectedColumns.indexOf(a.key) > -1) select.push(a.key)
      })
      // save selected elements to localstorage
      localStorage.setItem("TablePlayerPhysical_selectedColumns", select.join("|"))
    })
  }

  render() {
    const { t } = this.props;
    var measures = this.state.measures;
    var columns = this.state.columns;
    var selectedColumns = this.state.selectedColumns;
    var searchText = this.state.searchText;
    if (columns) {
      columns = columns.filter((o) =>
        selectedColumns.some((item) => item === o.key)
      );
    }
    if (measures && searchText.length > 2) {
      measures = measures.filter((o) =>
        o.name.toLowerCase().includes(searchText.toLowerCase())
      );
    }

    return (
      <>
        <FiltersBar
          components={
            <>
              <FiltersTitle title={measures ? measures.length + " " + t("Measures").toLowerCase() : ""} />
              <FilterSeparator />
              <FiltersSearch search={<SearchInput
                value={this.state.searchText}
                onChange={(searchText) => this.setState({ searchText })}

              />} />
              <FilterSeparator />
              <FilterColumns
                columns={
                  <SelectMultiple
                    options={this.state.columns}
                    keys={this.state.selectedColumns}
                    className="mx-2"
                    onChange={this.onChangeSelectedColumns}
                    fixedLabel={t("Active columns")}
                  />
                }
              />
              <FilterSeparator />
              <ExportToCSV columns={columns} jsonData={measures} fileName={t("Physical tests") + ".csv"} />
            </>
          }
          buttons={this.props.buttons}
        />
        <TableSimple columns={columns} data={measures} />
      </>
    );
  }
}

export default withTranslation()(TablePlayerPhysicalTestsDataCurrent);
