import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-xhr-backend";
import translationFr from "./locales/fr.json";
import translationEn from "./locales/en.json";

//var lng = LanguageDetector;
//if (localStorage.getItem("language") != null) {
//  lng = JSON.parse(localStorage.getItem("language"));
//}


i18n
    .use(XHR)
    .use(LanguageDetector)
    .init({
        debug: process.env.NODE_ENV === "development",
        lng: localStorage.getItem('Language') || "fr",//setLanguage(),
        fallbackLng: ["en","fr"], // use en if detected lng is not available
        keySeparator: false, // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false // react already safes from xss
        },
        resources: {
            fr: {
                translations: translationFr
            },
            en: {
                translations: translationEn
            }
        },
        // have a common namespace used around the full app
        ns: ["translations"],
        defaultNS: "translations"
    });

export default i18n;
