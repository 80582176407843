import Dexie from "dexie";

const db = new Dexie("Scholarship");
db.version(1).stores({ careers: "++playerId" });
db.version(1).stores({ curriculumsPursued: "++id, playerId, curriculumId" });
db.version(1).stores({ curriculums: "++id, schoolId" });
db.version(1).stores({ curriculumsTypes: "++id" });
db.version(1).stores({ schools: "++id" });
db.version(1).stores({ scholarshipDocuments: "++id, playerId" });
db.version(1).stores({ scholarshipReports: "++id, playerId" });
export default db;
