import React from "react";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import {
  getAnnounces,
  loadAnnounces,
} from "../../utils/indexedDB/communication/handleCommunication";
import {
  loadStaffs,
  loadPlayers,
  getPlayers,
} from "../../utils/indexedDB/users/handleUser";
import getUserProfile from "../../utils/auth/getUserProfile";
import Staff from "../group/Staff";

class Announces extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      id: null,
    };
  }

  componentDidMount() {
    getUserProfile().then((profile) => this.setState({ id: profile.sub }));
    getPlayers().then((players) => {
      if (players && players.length) {
        this.setState({ players });
        getAnnounces().then((messages) => {
          if (messages && messages.length > 0) {
            this.setState({ messages, loading: false });
          }
        });
      }
    });

    if (navigator.onLine) {
      loadStaffs().then(() =>
        loadPlayers().then((players) => {
          if (players) this.setState({ players });
          loadAnnounces().then((messages) => {
            if (messages) {
              this.setState({ messages, loading: false });
            }
          });
        })
      );
    }
  }

  render() {
    return (
        <Staff />
    );
  }
}

export default withTranslation()(withRouter(Announces));
