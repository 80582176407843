import React from "react";
import ActionButton from "../components/buttons/ActionButton";
import { withTranslation } from "react-i18next";
import { SelectMultiple } from "../components/forms";
import TableSimple from "../components/table/TableSimple";
import FilterColumns from "../components/filters/FilterColumns";
import FiltersSearch from "../components/filters/FiltersSearch";
import SearchInput from "../components/forms/input/SearchInput";
import FilterSeparator from "../components/filters/FilterSeparator";
import FiltersTitle from "../components/filters/FiltersTitle";
import FiltersBar from "../components/filters/FiltersBar";
import AnthropometricThreshold from "../utils/forms/other/AnthropometricThreshold";
import Button from "../components/buttons/Button";
import MultipleSliderReadOnly from "../components/forms/input/MultipleSliderReadOnly";

class TableAnthropometricThreshold extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actions: [],
      statuses: [],
      columns: [],
      searchText: "",
    };
  }

  componentDidMount() {
    this.prepareData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.prepareData();
    }
  }

  prepareData = () => {
    var threshold = this.props.threshold;

    const columns = [
      {
        key: "measure",
        visible: true,
        label: this.props.t("Measure"),
        id: "measure",
        width: 300,
        format: (row) => (
          <span className="flex justify-between">
            <span className="text-primary font-weight-bolder mb-0 text-xs 2xl:text-sm">
              {row.anthropometricMeasure ? row.anthropometricMeasure.name : "-"}
            </span>
          </span>
        ),
      },
      {
        key: "age",
        visible: true,
        label: this.props.t("Age"),
        id: "age",
        format: (row) => (
          <span className="font-weight-bold text-muted text-xs 2xl:text-sm">{row.ageMin + " - " + row.ageMax}</span>
        ),
      },
      {
        key: "sexe",
        visible: true,
        label: this.props.t("Sexe"),
        format: (row) =>
          <span className=" text-xs 2xl:text-sm">{
            row.sexe}</span>,
      },
      {
        key: "threshold",
        visible: true,
        label: this.props.t("Thresholds"),
        format: (row) =>
          row.thresholds ? <MultipleSliderReadOnly list={row.thresholds.map(t => { return { min: t.thresholdMin, max: t.thresholdMax, color: t.color } })} /> : "-",
      },
      {
        key: "forms",
        visible: true,
        format: (row) => <ActionButton
          key={row.id}
          actions={[<AnthropometricThreshold
            measureId={row.anthropometricMeasure ? row.anthropometricMeasure.id : ""}
            sexe={row.sexe}
            ageMin={row.ageMin}
            ageMax={row.ageMax}
            thresholds={row.thresholds}
            component={(show) => (
              <Button
                className="group flex items-center px-4 py-2 text-xs 2xl:text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                onClick={show}
                title={this.props.t("Update")}
              >
                <svg className="mr-3 h-3 w-3 2xl:h-5 2xl:w-5 text-gray-400 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                </svg>
                {this.props.t("Update")}
              </Button>
            )}
            callback={() => this.props.refresh()}
          />]}

        />
      }
    ];

    var selectedColumns = localStorage.getItem("TableAnthroThreshold_selectedColumns")
    if (selectedColumns === null || selectedColumns.length === 0) selectedColumns = ["mesure", "age", "sexe", "threshold", "forms"]
    else selectedColumns = selectedColumns.split("|")
    columns.forEach((column) => {
      column.visible = selectedColumns.indexOf(column.key) < 0 ? false : true
    });

    this.setState({
      columns,
      threshold,
      selectedColumns
    });
  };

  // change columns
  onChangeSelectedColumns = (res) => {
    this.setState({ selectedColumns: res }, () => {
      // determiner ce qui est selectionne
      let select = []
      this.state.columns.forEach((a) => {
        if (this.state.selectedColumns.indexOf(a.key) > -1) select.push(a.key)
      })
      // save selected elements to localstorage
      localStorage.setItem("TableAnthroThreshold_selectedColumns", select.join("|"))
    })
  }

  render() {
    const { t } = this.props;
    var threshold = this.props.threshold;
    var columns = this.state.columns;
    var selectedColumns = this.state.selectedColumns;
    var searchText = this.state.searchText;
    if (columns) {
      columns = columns.filter((o) =>
        selectedColumns.some((item) => item === o.key)
      );
    }
    if (searchText.length > 2) {
      threshold = threshold.filter((o) =>
        o.name.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    return (
      <>
        <FiltersBar
          components={
            <>
              <FiltersTitle title={threshold ? threshold.length + " " + t("Thresholds").toLowerCase() : ""} />
              <FilterSeparator />
              <FiltersSearch search={<SearchInput
                value={this.state.searchText}
                onChange={(searchText) => this.setState({ searchText })}
              />} />
              <FilterSeparator />
              <FilterColumns
                columns={
                  <SelectMultiple
                    options={this.state.columns}
                    keys={this.state.selectedColumns}
                    className="mx-2"
                    onChange={this.onChangeSelectedColumns}
                    fixedLabel={t("Active columns")}
                  />
                }
              />
            </>
          }
          buttons={this.props.buttons}
        />
        <TableSimple columns={columns} data={threshold} />
      </>
    );
  }
}

export default withTranslation()(TableAnthropometricThreshold);
