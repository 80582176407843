import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import MatchComposition from "./MatchComposition";
import MatchTimeline from "./MatchTimeline";
import MatchGeneralHeaderRugby from "./MatchGeneralHeaderRugby";
import TableMatchGeneralRugby from "../../tables/TableMatchGeneralRugby";

class MatchGeneral extends Component {

  render() {
    const { t } = this.props;
    var infoByPlayer = this.props.infoByPlayer;

    return (
      <div className="container font-semibold">
        <MatchGeneralHeaderRugby
          t={t}
          infoByPlayer={infoByPlayer}
          match={this.props.match}
          matchInfos={this.props.matchInfos}
        />
        <div className="grid grid-cols-2 gap-4 flex justify-evenly">
          <MatchComposition
            t={t}
            infoByPlayer={infoByPlayer}
            match={this.props.match}
            matchInfos={this.props.matchInfos}
          />
          <MatchTimeline t={t} matchInfos={this.props.matchInfos} />
        </div>
        <TableMatchGeneralRugby t={t} infoByPlayer={this.props.infoByPlayer} />
      </div>
    );
  }
}

export default withTranslation()(withRouter(MatchGeneral));
