import db from "./db";
import get from "../../requests/get";
import post from "../../requests/post";
import moment from "moment";

async function loadTargets(playerId) {
  return get("management", "playerTargetsByPlayer", playerId).then((res) => {
    if (res) {
      res = res.map((tar) => {
        tar.kind = playerId;
        return tar;
      });
      db.playerTargets.bulkPut(res);
      res = res.sort((a, b) =>
        moment(a.endDate).isSame(b.endDate)
          ? 0
          : moment(a.endDate).isAfter(b.endDate)
          ? 1
          : -1
      );
    }
    return res;
  });
}

function getTargets(playerId) {
  if (db.playerTargets.toArray()) {
    return db.playerTargets
      .where({ kind: playerId })
      .toArray()
      .then((targets) => {
        return targets.sort((a, b) =>
          moment(a.endDate).isSame(b.endDate)
            ? 0
            : moment(a.endDate).isAfter(b.endDate)
            ? 1
            : -1
        );
      });
  }
}

function loadTargetsByPlayers(ids) {
  return post("management", "playerTargetsByPlayers", { playersIds: ids })
    .then((res) => {
      return res.json();
    })
    .then((res) => {
      if (res) {
        db.playerTargets.bulkPut(res);
      }
      return res;
    });
}

async function loadTargetCategories() {
  return get("management", "playerTargetsCategories").then((res) => {
    if (res) {
      db.playerTargetsCategories.bulkPut(res);
    }
    return res;
  });
}

async function loadInterviewCategories() {
  return get("schedule", "interviewCategories").then((res) => {
    if (res) {
      db.interviewCategories.bulkPut(res);
    }
    return res;
  });
}

function sortTargetSetByEndDate(targets) {
  return targets.sort((a, b) =>
    moment(a.endDate).isSame(b.endDate)
      ? 0
      : moment(a.endDate).isAfter(b.endDate)
      ? -1
      : 1
  );
}

async function loadPeriodicReportsByPlayer(playerId, player) {
  return get("management", "ReportsByPlayer", playerId).then((res) => {
    if (res) {
      res.map((r) => {
        r.displayName = player.displayName
        r.photo = player.photo
        r.period = moment(r.startDate).format("D MMM YY") + " - " + moment(r.endDate).format("D MMM YY")
        return r
      })
      db.playerPeriodicReport.bulkPut(res)
      // res = res.sort((a, b) =>
      //   moment(a.startDate).isSame(b.startDate)
      //     ? 0
      //     : moment(a.startDate).isAfter(b.startDate)
      //       ? -1
      //       : 1
      // );
    }
  }
  );
}

function getPeriodicReports() {
  return db.playerPeriodicReport.toArray().then((res) => {
    return res.sort((a, b) =>
      a.displayName === b.displayName
        ? moment(a.startDate).isSame(b.startDate)
          ? 0
          : moment(a.startDate).isAfter(b.startDate)
          ? -1
          : 1
        : a.displayName > b.displayName
        ? 1
        : -1
    );
  });
}

function getPeriodicReportsByPlayer(playerId) {
  return db.playerPeriodicReport
    .where({ playerId })
    .toArray()
    .then((res) => {
      return res.sort((a, b) =>
        moment(a.startDate).isSame(b.startDate)
          ? 0
          : moment(a.startDate).isAfter(b.startDate)
          ? -1
          : 1
      );
    });
}

function getPeriodicReportFromId(id) {
  return db.playerPeriodicReport.get(id);
}

async function loadPeriodicReportSections(periodicReportId) {
  return get(
    "management",
    "playerPeriodicReportSectionsByReport",
    periodicReportId
  ).then((res) => {
    if (res) {
      db.playerPeriodicReportSections.bulkPut(res);
      res = res.sort((a, b) =>
        moment(a.date).isSame(b.date)
          ? 0
          : moment(a.date).isAfter(b.date)
          ? 1
          : -1
      );
    }
    return res;
  });
}

function getPeriodicReportSections(periodicReportId) {
  return db.playerPeriodicReportSections
    .where({ reportId: periodicReportId })
    .toArray()
    .then((res) => {
      if (res && res.length > 1)
        res = res.sort((a, b) =>
          moment(a.date).isSame(b.date)
            ? 0
            : moment(a.date).isAfter(b.date)
            ? 1
            : -1
        );
      return res;
    });
}

async function loadPeriodicReportSection(id) {
  return get("management", "playerPeriodicReportSections", id).then((res) => {
    if (res) {
      db.playerPeriodicReportSections.bulkPut(res);
    }
    return res;
  });
}

async function loadFederalSupportByPlayer(playerId, player, season) {
  let id = season && season.id && playerId + "/" + season.id;
  return get("management", "federalSupportByPlayer", id).then((res) => {
    if (res && res.id) {
      res.displayName = player.displayName;
      res.photo = player.photo;
      res.season = season;
      db.federalSupport.put(res);
    }
    return res;
  });
}

function loadFederalSupportByPlayers(ids, players, season) {
  return post("management", "federalSupportByPlayers", {
    playersIds: ids,
    seasonId: season,
  })
    .then((res) => {
      return res.json();
    })
    .then((res) => {
      if (res && res.status !== 400) {
        res.forEach(r => {
          r.displayName = players && players.length && players.find(o => o.id === r.playerId).displayName
          r.photo = players && players.length && players.find(o => o.id === r.playerId).photo
          r.season = season
        })
        db.federalSupport.bulkPut(res);
      }
      return res;
    });
}

function getFederalSupportBySeason(seasonId) {
  return db.federalSupport
    .where({ seasonId })
    .toArray()
    .then((res) => {
      if (res && res.length > 1)
        res = res.sort((a, b) =>
          a.displayName === b.displayName
            ? 0
            : a.displayName > b.displayName
            ? 1
            : -1
        );
      return res;
    });
}

function getFederalSupportByPlayer(playerId) {
  return db.federalSupport
    .where({ playerId })
    .toArray()
    .then((res) => {
      if (res && res.length > 1)
        res = res.sort((a, b) =>
          a.seasonId === b.seasonId ? 0 : a.seasonId > b.seasonId ? 1 : -1
        );
      return res;
    });
}

function getFederalSupportByPlayerBySeason(playerId, seasonId) {
  return db.federalSupport
    .where({ playerId, seasonId })
    .toArray()
    .then((res) => {
      return res;
    });
}

export {
  loadTargets,
  loadTargetsByPlayers,
  loadTargetCategories,
  loadInterviewCategories,
  getTargets,
  sortTargetSetByEndDate,
  loadPeriodicReportsByPlayer,
  getPeriodicReports,
  getPeriodicReportsByPlayer,
  getPeriodicReportFromId,
  loadPeriodicReportSections,
  getPeriodicReportSections,
  loadPeriodicReportSection,
  loadFederalSupportByPlayer,
  loadFederalSupportByPlayers,
  getFederalSupportBySeason,
  getFederalSupportByPlayer,
  getFederalSupportByPlayerBySeason,
};
