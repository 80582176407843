import React from "react";
import GeneratedForm from "../../utils/forms/generateForm";
import Button from "./Button";

class ActionButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actions: [],
      open: false
    };
  }

  componentDidMount() {
    var actions = this.formatActions();
    this.setState({ actions });
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      var actions = this.formatActions();
      this.setState({ actions });
    }
  }

  formatActions() {
    var actions = [];
    if (this.props.refreshData && this.props.formData) {
      if (this.props.updateForm) {
        actions.push(
          <GeneratedForm
            key={this.props.updateForm}
            component={(show, text, disabled, tooltip) => (
              <Button
              className={disabled && !this.props.activeUpdateException ?"group flex items-center px-4 py-2 text-xs 2xl:text-sm text-gray-700 bg-gray-100" :"group flex items-center px-4 py-2 text-xs 2xl:text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"}
              onClick={show}
                disabled={disabled && !this.props.activeUpdateException}
                title={tooltip}
              >
                <svg className={disabled ? "mr-3 h-3 w-3 2xl:h-5 2xl:w-5 text-gray-400" : "mr-3 h-3 w-3 2xl:h-5 2xl:w-5 text-gray-400 group-hover:text-gray-500"} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                </svg>
                {text}
              </Button>
            )}
            callback={(res) =>
              this.props.refreshData ? this.props.refreshData(res) : null
            }
            formName={this.props.updateForm}
            formData={this.props.formData}
          />
        );
      }
      if (this.props.deleteForm) {
        actions.push(
          <GeneratedForm
            key={this.props.deleteForm}
            component={(show, text, disabled, tooltip) => (
              <Button
                onClick={show}
                className={disabled ?"group flex items-center px-4 py-2 text-xs 2xl:text-sm text-gray-700 bg-gray-100" :"group flex items-center px-4 py-2 text-xs 2xl:text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"}
                disabled={disabled || this.props.disableDelete}
                title={tooltip}
              >
                <svg className={disabled ? "mr-3 h-3 w-3 2xl:h-5 2xl:w-5 text-gray-400" : "mr-3 h-3 w-3 2xl:h-5 2xl:w-5 text-gray-400 group-hover:text-gray-500"} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                </svg>
                {text}
              </Button>
            )}
            callback={(res) =>
              this.props.refreshData ? this.props.refreshData(res) : null
            }
            formName={this.props.deleteForm}
            formData={this.props.formData}
          />
        );
      }
      if (this.props.addForm) {
        actions.push(
          <GeneratedForm
            key={this.props.addForm}
            component={(show, text, disabled, tooltip) => (
              <Button
                onClick={show}
                disabled={disabled}
                className={disabled ?"group flex items-center px-4 py-2 text-xs 2xl:text-sm text-gray-700 bg-gray-100" :"group flex items-center px-4 py-2 text-xs 2xl:text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"}
                title={tooltip}
              >
                <svg className={disabled ? "mr-3 h-3 w-3 2xl:h-5 2xl:w-5 text-gray-400" : "mr-3 h-3 w-3 2xl:h-5 2xl:w-5 text-gray-400 group-hover:text-gray-500"} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                </svg>
                {text}
              </Button>
            )}
            callback={(res) =>
              this.props.refreshData ? this.props.refreshData(res) : null
            }
            formName={this.props.addForm}
            formData={this.props.formDataBis}
          />
        );
      }
    }
    if (this.props.actions) {
      this.props.actions.forEach((action) => actions.push(action));
    }
    return actions;
  }

  render() {
    return this.state.actions && this.state.actions.length ? (
      <div className="relative text-left">
        <div className="groupTourStep6">
          <button className="rounded-full items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500" onClick={() => this.setState({ open: !this.state.open })}>
            <svg className="h-4 w-4 2xl:h-5 2xl:w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
            </svg>
          </button>
        </div>
        {this.state.open ?
          // <div className="absolute">
            <div className=" overflow-hidden absolute right-0 text-sm 2xl:text-base  mt-2 w-50 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10">
              <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                {this.state.actions.map((action, key) => {
                  return <div key={key}>{action}</div>;
                })}
              </div>
            </div>
          // </div>
          : null}

      </div>
    ) : null;
  }
}

export default ActionButton;
