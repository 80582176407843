import React from "react";
import { withTranslation } from "react-i18next";
import FusionCharts from "fusioncharts/core";
// import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts";
import Column2D from "fusioncharts/viz/column2d";
import StackedColumn2D from "fusioncharts/viz/stackedcolumn2d";
import ScrollColumn2D from "fusioncharts/viz/scrollcolumn2d";
import Doughnut2D from "fusioncharts/viz/doughnut2d";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
// Resolves charts dependancy
ReactFusioncharts.fcRoot(
  FusionCharts,
  StackedColumn2D,
  Column2D,
  Doughnut2D,
  ScrollColumn2D,
  FusionTheme
);
// charts(FusionCharts);

class ChartStackedColumns extends React.Component {
  render() {
    var dataSet = this.props.dataSet;
    var categories = this.props.categories;
    var title = this.props.title;
    var subTitle = this.props.subTitle;
    const dataSource = {
      chart: {
        caption: title,
        subcaption: subTitle,
        plotgradientcolor: " ",
        theme: "fusion",
        yaxismaxvalue: "30",
        numdivlines: "2",
        showlegend: "1",
        interactivelegend: "1",
        plotHighlightEffect: "fadeout",
        showvalues: "0",
        showsum: "1",
        drawcrossline: "1",
        valuefontcolor: "#6e3677",
        plottooltext: "$seriesName : <b>$dataValue</b>"+ (this.props.notMin ? this.props.notMin : "min"),
      },
      categories: [
        {
          category: categories,
        },
      ],
      dataset: dataSet ? dataSet : [],
    };
    return (
      <ReactFusioncharts
        type="stackedcolumn2d"
        width="100%"
        height={this.props.height ? this.props.height : "600"}
        dataFormat="JSON"
        dataSource={dataSource}
      />
    );
  }
}

export default withTranslation()(ChartStackedColumns);
