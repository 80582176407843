import React from "react"
import { withRouter } from "react-router"
import { withTranslation } from "react-i18next"
import Calendar from "../../components/calendar/Calendar"
import SubTab from "../../components/tab/SubTab"
import {
  loadCyclesByPlayer,
  loadWeeksByPlayer,
} from "../../utils/indexedDB/events/handleEvents"
import moment from "moment"
import TableWeeksByPlayer from "../../tables/TableWeeksByPlayer"
import TableCyclesByPlayer from "../../tables/TableCyclesByPlayer"
import ButtonLight from "../../components/buttons/ButtonLight"
import GeneratedForm from "../../utils/forms/generateForm"
import TableYearsByPlayer from "../../tables/TableYearsByPlayer"
import {loadProgrammation} from "../../utils/indexedDB/Tennis-Competition/handleCompetitionTennis"

class PlayerScheduleTab extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      id: this.props.playerId,
      subTab: "calendar",
    }
  }

  componentDidMount() {
    loadCyclesByPlayer(
      this.state.id,
      moment().add(-4, "y").startOf("day").toDate().toISOString(),
      moment().add(1, "y").startOf("day").toDate().toISOString()
    ).then((cycles) => {
      cycles = cycles.sort((a, b) =>
        moment(a.startDate) === moment(b.startDate)
          ? 0
          : moment(a.startDate) > moment(b.startDate)
          ? -1
          : 1
      )
      this.setState({ cycles })
    })
    loadWeeksByPlayer(
      this.state.id,
      moment().add(-4, "y").startOf("day").toDate().toISOString(),
      moment().add(1, "y").startOf("day").toDate().toISOString()
    ).then((weeks) => {
      weeks = weeks.sort((a, b) =>
        moment(a.startDate) === moment(b.startDate)
          ? 0
          : moment(a.startDate) > moment(b.startDate)
          ? -1
          : 1
      )
      this.setState({ weeks })
    })
    loadProgrammation(this.state.id, 0).then((prog) => {
      this.setState({ prog });
    })
  }

  render() {
    const { t } = this.props
    var subTabs = [
      { key: "calendar", label: t("Calendar") },
      { key: "weeks", label: t("Weeks") },
      { key: "cycles", label: t("Cycles") },
      { key: "annual planning", label: t("Annual planning") },
    ]
    return (
      <>
        <SubTab
          tab={subTabs}
          onChange={(key) => this.setState({ subTab: key })}
        />
        {this.state.subTab === "calendar" ? (
          <Calendar
            availableViews="month,week"
            defaultView="timeGridWeek"
            filters
            choosePlayer={false}
            defaultFiltersOpened={false}
            playersId={[this.state.id]}
            formData={{
              attendees: [this.state.id],
              playerId: this.state.id,
              groupId: this.props.player ? this.props.player.group1Id : null,
            }}
            callbackFormData={() => {}}
            reloadCalendar={this.props.reloadCalendar}
          />
        ) : (
          ""
        )}
        {this.state.subTab === "weeks" ? (
          <TableWeeksByPlayer
            weeks={this.state.weeks}
            refresh={() =>
              loadWeeksByPlayer(
                this.state.id,
                moment().add(-4, "y").startOf("day").toDate().toISOString(),
                moment().add(1, "y").startOf("day").toDate().toISOString()
              ).then((weeks) => {
                weeks = weeks.sort((a, b) =>
                  moment(a.startDate) === moment(b.startDate)
                    ? 0
                    : moment(a.startDate) > moment(b.startDate)
                    ? -1
                    : 1
                )
                this.setState({ weeks })
              })
            }
            buttons={[
              <GeneratedForm
                component={(show, text) => (
                  <ButtonLight onClick={show} disabled={!navigator.onLine}>
                    <i className="flaticon2-plus"></i> {text}
                  </ButtonLight>
                )}
                callback={() => {
                  loadWeeksByPlayer(
                    this.state.id,
                    moment().add(-4, "y").startOf("day").toDate().toISOString(),
                    moment().add(1, "y").startOf("day").toDate().toISOString()
                  ).then((weeks) => {
                    weeks = weeks.sort((a, b) =>
                      moment(a.startDate) === moment(b.startDate)
                        ? 0
                        : moment(a.startDate) > moment(b.startDate)
                        ? -1
                        : 1
                    )
                    this.setState({ weeks })
                  })
                }}
                formData={{
                  playerId: this.state.id,
                }}
                formName="createTennisWeek"
                method="POST"
              />,
            ]}
          />
        ) : (
          ""
        )}
        {this.state.subTab === "cycles" ? (
          <TableCyclesByPlayer
            cycles={this.state.cycles}
            refresh={() =>
              loadCyclesByPlayer(
                this.state.id,
                moment().add(-4, "y").startOf("day").toDate().toISOString(),
                moment().add(1, "y").startOf("day").toDate().toISOString()
              ).then((cycles) => {
                cycles = cycles.sort((a, b) =>
                  moment(a.startDate) === moment(b.startDate)
                    ? 0
                    : moment(a.startDate) > moment(b.startDate)
                    ? -1
                    : 1
                )
                this.setState({ cycles })
              })
            }
            buttons={[
              <GeneratedForm
                component={(show, text) => (
                  <ButtonLight onClick={show} disabled={!navigator.onLine}>
                    <i className="flaticon2-plus"></i> {text}
                  </ButtonLight>
                )}
                callback={() => {
                  loadCyclesByPlayer(
                    this.state.id,
                    moment().add(-4, "y").startOf("day").toDate().toISOString(),
                    moment().add(1, "y").startOf("day").toDate().toISOString()
                  ).then((cycles) => {
                    cycles = cycles.sort((a, b) =>
                      moment(a.startDate) === moment(b.startDate)
                        ? 0
                        : moment(a.startDate) > moment(b.startDate)
                        ? -1
                        : 1
                    )
                    this.setState({ cycles })
                  })
                }}
                formData={{
                  playerId: this.state.id,
                }}
                formName="createTennisCycle"
                method="POST"
              />,
            ]}
          />
        ) : (
          ""
        )}
        {this.state.subTab === "annual planning" && (
          <TableYearsByPlayer
            playerId={this.state.id}
            weeks={this.state.weeks}
            prog={this.state.prog}
            cycles={this.state.cycles}
            refresh={() => this.loadProgrammation(this.state.id)}
            buttons={[
              <GeneratedForm
                component={(show, text) => (
                  <ButtonLight onClick={show} disabled={!navigator.onLine}>
                    <i className="flaticon2-plus"></i> {text}
                  </ButtonLight>
                )}
                callback={() => {
                  loadProgrammation(
                    this.state.id,
                    moment().add(-4, "y").startOf("day").toDate().toISOString(),
                    moment().add(1, "y").startOf("day").toDate().toISOString()
                  ).then((cycles) => {
                    cycles = cycles.sort((a, b) =>
                      moment(a.startDate) === moment(b.startDate)
                        ? 0
                        : moment(a.startDate) > moment(b.startDate)
                        ? -1
                        : 1
                    )
                    this.setState({ cycles })
                  })
                }}
                formData={{
                  playerId: this.state.id,
                }}
                formName="createTennisCycle"
                method="POST"
              />,
            ]}
          />
        )}
      </>
    )
  }
}

export default withTranslation()(withRouter(PlayerScheduleTab))
