import React from "react";
import ActionButton from "../components/buttons/ActionButton";
import { Link } from "react-router-dom";
import IconRound32x32 from "../components/icons/IconRound32x32";
import { withTranslation } from "react-i18next";
import { SelectMultiple } from "../components/forms";
import TableSimple from "../components/table/TableSimple";
import FiltersDropDowns from "../components/filters/FiltersDropDowns";
import FilterColumns from "../components/filters/FilterColumns";
import ExportToCSV from "../components/export/ExportToCSV";
import SearchInput from "../components/forms/input/SearchInput";
import FiltersSearch from "../components/filters/FiltersSearch";
import FilterSeparator from "../components/filters/FilterSeparator";
import FiltersTitle from "../components/filters/FiltersTitle";
import FiltersBar from "../components/filters/FiltersBar";

class TableRecords extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actions: [],
      groups: [],
      selectedGroups: [],
      genders: [],
      selectedGenders: [],
      columns: [],
      searchText: "",
    };
  }

  componentDidMount() {
    this.prepareData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.prepareData();
    }
  }

  prepareData = () => {
    var players = this.props.players;
    var groups = [];
    var genders = [
      { key: "H", label: this.props.t("Man") },
      { key: "F", label: this.props.t("Woman") },
    ];
    var selectedGenders = ["H", "F"];
    var selectedGroups = [];
    if (players) {
      players = players.map((player) => {
        if (groups && !groups.find((o) => o.key === player.group1.id)) {
          groups.push({ key: player.group1.id, label: player.group1.name });
          selectedGroups.push(player.group1.id);
        }
        return player;
      });
    }

    var columns = [
      {
        key: "lastName",
        visible: true,
        label: this.props.t("Player"),
        pinned: "left",
        id: "displayName",
        format: (row) => {
          return (
            <span className="flex justify-between">
              <Link
                className="text-indigo-700 font-medium pr-2 text-xs 2xl:text-sm"
                to={"/group/player/" + row.id}
              >
                <IconRound32x32 photo={row.photo} />
                <span className="ml-5">
                  {row.lastName.toUpperCase() + " " + row.firstName}
                </span>
              </Link>
              <ActionButton
                key={row.id}
                updateForm="updateRecord"
                deleteForm="deleteRecord"
                formData={row.record}
                refreshData={() => this.props.callback}

              />
            </span>
          );
        },
      },
      {
        key: "record.address",
        visible: true,
        label: this.props.t("Address"),
        width: 100,
        id: "address",
        format: (row) => (
          <span className="font-weight-bold text-muted text-xs 2xl:text-sm">{row.record ? row.record.address : ""}</span>
        ),
      },
      {
        key: "record.postalCode",
        visible: true,
        label: this.props.t("Postal code"),
        width: 60,
        format: (row) =>  <span className=" text-xs 2xl:text-sm">{row.record ? row.record.postalCode : ""}</span>,
      },
      {
        key: "record.city",
        visible: true,
        label: this.props.t("City"),
        width: 60,
        format: (row) =>  <span className=" text-xs 2xl:text-sm">{row.record ? row.record.city : ""}</span>,
      }
    ];
    var selectedColumns = [];
    columns.forEach((column) => {
      if (column.visible) {
        selectedColumns.push(column.key);
      }
    });
    this.setState({
      columns,
      players,
      groups,
      genders,
      selectedColumns,
      selectedGroups,
      selectedGenders,
    });
  };

  render() {
    const { t } = this.props;
    var players = this.props.players;
    var columns = this.state.columns;
    var selectedColumns = this.state.selectedColumns;
    var selectedGroups = this.state.selectedGroups;
    var selectedGenders = this.state.selectedGenders;
    var searchText = this.state.searchText;
    if (columns) {
      columns = columns.filter((o) =>
        selectedColumns.some((item) => item === o.key)
      );
    }
    if (players) {
      players = players.filter((o) =>
        selectedGroups.some((item) => item === o.group1.id)
      );
    }
    if (players) {
      players = players.filter((o) =>
        selectedGenders.some((item) => item === o.genderId)
      );
    }
    if (searchText.length > 2) {
      players = players.filter(
        (o) =>
          o.lastName.toLowerCase().includes(searchText.toLowerCase()) ||
          o.firstName.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    return (
      <>
        <FiltersBar
          components={
            <>
              <FiltersTitle title={players ? players.length + " " + t("Players").toLowerCase() : ""} />
              <FilterSeparator />
              <FiltersSearch search={<SearchInput
                value={this.state.searchText}
                onChange={(searchText) => this.setState({ searchText })}
                
              />} />
              <FilterSeparator />
              <FiltersDropDowns selects={[
                <SelectMultiple
                  options={this.state.groups}
                  keys={this.state.selectedGroups}
                  className="mx-2"
                  onChange={(res) => this.setState({ selectedGroups: res })}
                  fixedLabel={t("Group")}
                />,
                <SelectMultiple
                  options={this.state.genders}
                  keys={this.state.selectedGenders}
                  className="mx-2"
                  onChange={(res) => this.setState({ selectedGenders: res })}
                  fixedLabel={t("Gender")}
                />
                ]} 
              />
              <FilterSeparator />
              <FilterColumns 
                columns={
                  <SelectMultiple
                    options={this.state.columns}
                    keys={this.state.selectedColumns}
                    className="mx-2"
                    onChange={(res) => this.setState({ selectedColumns: res })}
                    fixedLabel={t("Active columns")}
                  />
                }
              />
              <FilterSeparator />
              <ExportToCSV columns={columns} jsonData={players} fileName={t("Personal records") + ".csv"} />
            </>
          }
        />
        <TableSimple columns={columns} data={players} />
      </>
    );
  }
}

export default withTranslation()(TableRecords);
