import React from "react";
import { withRouter } from "react-router";
import { Route } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { withOidcSecure } from "@axa-fr/react-oidc-context";
import Aside from "./Aside";
import getAccessToken from "../../utils/auth/getAccessToken";

function Layout({ component: Component, path, ...props }) {


  return (
    <Route
      {...props}
      path={path}
      component={withOidcSecure(() => {
        getAccessToken().then(
          (accessToken) => {
            window.zESettings = {
              webWidget: {
                authenticate: {
                  chat: {
                    jwtFn: function (callback) {
                      fetch(
                        "https://localhost:44313/api/v1.0/zendeskChatToken",
                        {
                          method: "GET",
                          headers: {
                            Authorization: `Bearer ${accessToken}`,
                          },
                        }
                      )
                        .then((res) => {
                          if (res.ok) {
                            return res.json();
                          }
                        })
                        .then(function (res) {
                          res.text().then(function (jwt) {
                            callback(jwt);
                          });
                        });
                    },
                  },
                },
              },
            };
          }
        );
        return (
          <div className="dark:bg-gray-900 dark:text-white text-gray-600 h-screen flex overflow-hidden">
            <Aside path={path} />
            <div
              className="flex-grow overflow-auto h-full flex flex-col"
              id="es2-page-container"
            >
              {
                <Component />
              }
            </div>
          </div>
        );
      })}
    />
  );
}

export default withTranslation()(withRouter(Layout));
