function handleCondition(questions, formData, question) {
    if (question.showCondition) {
        if (formData) {
            var tabCondition = [];
            for (var index = 0; index < question.showCondition.conditions.length; index++) {
                var condition = question.showCondition.conditions[index];
                tabCondition.push(isConditionTrue(questions, formData, condition));
            }
            if (question.showCondition.type && question.showCondition.type === "and") {
                return !tabCondition.includes(false);
            } else {
                return tabCondition.includes(true);
            }
        } else {
            return false;
        }
    } else {
        return true;
    }
}

function isConditionTrue(questions, formData, condition) {
    if (typeof (condition.value) !== "object") {
        return isValueVerified(questions.find(x => x.label === condition.questionId).type, formData[condition.questionId], condition.value)
    } else {
        var tabConditionValue = [];
        for (var indexValue = 0; indexValue < condition.value.length; indexValue++) {
            var value = condition.value[indexValue];
            tabConditionValue.push(isValueVerified(questions.find(x => x.label === condition.questionId).type, formData[condition.questionId], value))
        }
        if (condition.type && condition.type === "and") {
            return !tabConditionValue.includes(false);
        } else {
            return tabConditionValue.includes(true);
        }
    }

}

function isValueVerified(type, question, value) {
    if (question) {
        if (type !== "array" && typeof (question) !== "object") {
            return question === value;
        } else {
            return question.includes(value);
        }
    } else {
        return false;
    }

}

export default handleCondition;
