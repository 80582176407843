import React from "react";
import ButtonLight from "../../../components/buttons/ButtonLight";
import Card from "../../../components/card/Card";
import ExportToCSV from "../../../components/export/ExportToCSV";
import FilterColumns from "../../../components/filters/FilterColumns";
import FiltersBar from "../../../components/filters/FiltersBar";
import FiltersDropDowns from "../../../components/filters/FiltersDropDowns";
import FilterSeparator from "../../../components/filters/FilterSeparator";
import FiltersSearch from "../../../components/filters/FiltersSearch";
import FiltersTitle from "../../../components/filters/FiltersTitle";
import { SelectMultiple } from "../../../components/forms";
import SearchInput from "../../../components/forms/input/SearchInput";
import SpiderChart from "../../../components/charts/SpiderChart";
import TableSimple from "../../../components/table/TableSimple";
import GeneratedForm from "../../../utils/forms/generateForm";

const PhysicalStatistics = ({
  t,
  refresh,
  player,
  buttons,
  columns,
  averageDataNormalizedByMatchByPlayerList,
}) => {
  
  //////// Spider Chart Data ////

  var averageTeamValueNormalized =
    averageDataNormalizedByMatchByPlayerList &&
    averageDataNormalizedByMatchByPlayerList.map((value) => {
      return { value: Math.round(value.averageMatchNormalized).toString() };
    });

  var averagePlayerValueNormalized =
    averageDataNormalizedByMatchByPlayerList &&
    averageDataNormalizedByMatchByPlayerList.map((value) => {
      return { value: Math.round(value.valueNormalized).toString() };
    });

  var averageName =
    averageDataNormalizedByMatchByPlayerList &&
    averageDataNormalizedByMatchByPlayerList.map(
      (averageDataNormalizedByMatchByPlayer) => {
        return { label: averageDataNormalizedByMatchByPlayer.name };
      }
    );

  var averagePlayerValuesByCategoryList = 
  averageDataNormalizedByMatchByPlayerList &&
    averageDataNormalizedByMatchByPlayerList.map((object) => {
      return {
        playerValue: Math.round(object.value),
        teamValue: Math.round(object.averageMatch),
        categoryName: object.name,
        unite: object.unite,
      };
    });

  var dataSource = {
    chart: {
      caption: "Statistique du joueur",
      theme: "fusion",
      numbersuffix: "%",
      plotfillalpha: "40",
    },
    categories: [
      {
        category: averageName,
      },
    ],
    dataset: [
      {
        seriesname: "Avg. Team",
        plottooltext: "<b>$label</b> maximum temp is <b>$datavalue</b>",
        data: averageTeamValueNormalized,
      },
      {
        seriesname: "Avg. Player",
        plottooltext: "<b>$label</b> minimum temp is <b>$datavalue</b>",
        data: averagePlayerValueNormalized,
      },
    ],
  };

  return (
    <div>
      {/* SPIDER CHART  */}
      <>
        {averageName && averageName.length > 0 && (
          <SpiderChart dataSource={dataSource} />
        )}
      </>

      <Card
        title={t("Physical Statistics")}
        headerActions={
          <GeneratedForm
            key="updateCommentPlayer"
            component={(show, text) => (
              <ButtonLight onClick={show} disabled={!navigator.onLine}>
                {t("Edit")}
              </ButtonLight>
            )}
            callback={(res) => refresh()}
            formName="updateCommentPlayer"
            formData={player}
          />
        }
      >
        <FiltersBar
          components={
            <>
              {/* <FiltersTitle
                title={
                  individualStatistics
                    ? individualStatistics.length +
                      " " +
                      t("Players").toLowerCase()
                    : ""
                }
              /> */}
              <FiltersTitle title={"13 " + t("Categories").toLowerCase()} />
              <FilterSeparator />
              <FiltersSearch
                search={
                  <SearchInput
                  // value={searchText}
                  // onChange={(searchText) => this.setState({ searchText })}
                  />
                }
              />
              <FilterSeparator />
              <FiltersDropDowns
                selects={[
                  <SelectMultiple
                    // options={this.state.groups}
                    // keys={this.state.selectedGroups}
                    className="mx-2"
                    fixedLabel={t("Group")}
                  />,
                ]}
              />
              <FilterSeparator />
              <FilterColumns
                columns={
                  <SelectMultiple
                    // options={this.state.columns}
                    // keys={this.state.selectedColumns}
                    className="mx-2"
                    fixedLabel={t("Active columns")}
                    // onChange={this.onChangeSelectedColumns}
                  />
                }
              />
              <FilterSeparator />
              <ExportToCSV
                // columns={columns}
                // jsonData={infoByPlayer}
                fileName={t("Match General Stat") + ".csv"}
              />
            </>
          }
          buttons={buttons}
        />
        <TableSimple
          columns={columns}
          data={averagePlayerValuesByCategoryList}
        />
      </Card>
    </div>
  );
};

export default PhysicalStatistics;
