import React from "react";
import ActionButton from "../components/buttons/ActionButton";
import moment from "moment";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { SelectMultiple, MonthPicker } from "../components/forms";
import TableSimple from "../components/table/TableSimple";
import ListIcon from "../components/icons/ListIcon";
import FilterColumns from "../components/filters/FilterColumns";
import FiltersDropDowns from "../components/filters/FiltersDropDowns";
import FiltersSearch from "../components/filters/FiltersSearch";
import SearchInput from "../components/forms/input/SearchInput";
import ExportToCSV from "../components/export/ExportToCSV";
import FilterSeparator from "../components/filters/FilterSeparator";
import FiltersTitle from "../components/filters/FiltersTitle";
import FiltersBar from "../components/filters/FiltersBar";
class TableTournamentsINT extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actions: [],
      circuits: [],
      selectedCircuits: [],
      columns: [],
      searchText: "",
    };
  }

  componentDidMount() {
    this.prepareData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.prepareData();
    }
  }

  prepareData = () => {
    var tournaments = this.props.tournaments;
    var circuits = [];
    var selectedCircuits = [];
    var grounds = [];
    var selectedGrounds = [];
    var conditions = [];
    var selectedConditions = [];


    //  chercher les années a déselectionner du localstorage
    var unselectedCircuits = localStorage.getItem("TableTournamentsINT_unselectedCircuits")
    if (unselectedCircuits) unselectedCircuits = unselectedCircuits.split("|")
    
    // grounds
    var unselectedGrounds = localStorage.getItem("TableTournamentsINT_unselectedGrounds")
    if (unselectedGrounds) unselectedGrounds = unselectedGrounds.split("|")    

    // conditions
    var unselectedConditions = localStorage.getItem("TableTournamentsINT_unselectedConditions")
    if (unselectedConditions) unselectedConditions = unselectedConditions.split("|")    

    
    if (tournaments) {
      tournaments = tournaments.map((tournament) => {
        if (
          circuits &&
          !circuits.find((o) => o.key === tournament.circuit.id)
        ) {
          circuits.push({
            key: tournament.circuit.id,
            label: tournament.circuit.name,
          })
           // only add to selected if not unselected
        if (
          !unselectedCircuits ||
          unselectedCircuits.indexOf(tournament.circuit.id.toString()) < 0
        )  
          selectedCircuits.push(tournament.circuit.id);
        }
        if (
          grounds &&
          !grounds.find((o) => o.key === tournament.groundId)
        ) {
          grounds.push({
            key: tournament.groundId,
            label: tournament.groundId,
          })
           // only add to selected if not unselected
        if (
          !unselectedGrounds ||
          unselectedGrounds.indexOf(tournament.groundId.toString()) < 0
        )  
          selectedGrounds.push(tournament.groundId);
        }
        if (
          conditions &&
          !conditions.find((o) => o.key === tournament.conditionsId)
        ) {
          conditions.push({
            key: tournament.conditionsId,
            label: tournament.conditionsId,
          })
           // only add to selected if not unselected
        if (
          !unselectedConditions ||
          unselectedConditions.indexOf(tournament.conditionsId.toString()) < 0
        )  
          selectedConditions.push(tournament.conditionsId);
        }
        return tournament;
      });
    }

    const columns = [
      {
        key: "startDate",
        visible: true,
        label: this.props.t("Start"),
        format: (row) => <span className="text-xs 2xl:text-sm">{moment(row.startDate).format("D MMM YY")}</span>,
        filter: {
          name: "DateBetweenFilter",
        },
        width: 90,
      },
      {
        key: "endDate",
        visible: true,
        label: this.props.t("End"),
        format: (row) => <span className="text-xs 2xl:text-sm">{moment(row.endDate).format("D MMM YY")}</span>,
        filter: {
          name: "DateBetweenFilter",
        },
        width: 90,
      },
      {
        key: "name",
        visible: true,
        label: this.props.t("Name"),
        id: "name",
        format: (row) => (
          <span className="flex justify-between mb-0">
            <Link
              className="text-indigo-700 font-semibold text-xs 2xl:text-sm mr-6"
              to={"/competitionTennis/internationaldetails/" + row.tId + "/a/b"}
            >
              {row.name}
            </Link>
            <ActionButton
              key={row.id}
              updateForm={
                row.tournamentTypeId === "National"
                  ? "updateTennisTournamentNational"
                  : "updateTennisTournamentInternational"
              }
              addForm="addTennisEngagement"
              deleteForm={
                row.tournamentTypeId === "National"
                  ? "deleteTennisTournamentNational"
                  : "deleteTennisTournamentInternational"
              }
              formData={
                row.tournamentTypeId === "National"
                  ? {
                    id: row.tId,
                    startDate: row.startDate,
                    endDate: row.endDate,
                    name: row.name,
                    category: row.category,
                    classificationId: row.classificationId,
                    groundId: row.groundId,
                    conditionsId: row.conditionsId,
                    typeId: row.typeId,
                    city: row.city,
                    regionId: row.regionId,
                    clubId: row.clubId,
                  }
                  : row
              }
              formDataBis={{ tournamentId: row.tId, tournamentIdname: row.name }}
              refreshData={this.props.refresh}
            />
          </span>
        ),
        width: 350,
      },
      {
        key: "localisation",
        visible: true,
        label: this.props.t("Localisation"),
        id: "localisation",
        format: (row) => (
          <span className="font-weight-bold text-muted text-xs 2xl:text-sm">
            {row.localisation}
          </span>
        ),
        filter: {
          name: "SelectColumnFilter",
          type: "includes",
        },
        width: 250,
      },
      {
        key: "circuit.name",
        visible: true,
        label: this.props.t("Circuit"),
        id: "circuit.name",
        format: (row) =>
          row.circuit ? (
            <span
              className="px-3 py-0.5 rounded-full text-xs 2xl:text-sm font-medium bg-red-100 text-red-800"
              style={{
                color: row.circuit.color2,
                backgroundColor: row.circuit.color1,
              }}
            >
              {row.circuit.name +
                (row.classification ? " " + row.classification : "")}
            </span>
          ) : (
              "N/A"
            ),
        filter: {
          name: "SelectColumnFilter",
          type: "includes",
        },
      },
      {
        key: "conditions.name",
        visible: true,
        label: this.props.t("Conditions"),
        id: "conditions.name",
        width: 200,
        filter: {
          name: "SelectColumnFilter",
          type: "includes",
        },
        format: (row) => <span className="text-xs 2xl:text-sm">{(row.conditions ? row.conditions.name : "N/A")}</span>,
      },
      {
        key: "ground.name",
        visible: true,
        label: this.props.t("Ground"),
        id: "ground.name",
        width: 200,
        filter: {
          name: "SelectColumnFilter",
          type: "includes",
        },
        format: (row) => <span className="text-xs 2xl:text-sm">{(row.ground ? row.ground.name : "N/A")}</span>,
      },
      {
        key: "participants",
        visible: false,
        label: this.props.t("Participants"),
        format: (row) => (
          <ListIcon
            users={this.props.getUsersPhotoFormat(row.engagements)}
            length={3}
          />
          //<></>
        ),
      }
    ];


    var selectedColumns = localStorage.getItem("TableTournamentsINT_selectedColumns")
    if (selectedColumns === null || selectedColumns.length === 0)  selectedColumns = ["startDate", "endDate", "name", "tournamentTypeId", "localisation", "category", "classificationId", "type.name", "circuit.name", "conditions.name", "regionId", "ground.name", "participants" ]
    else selectedColumns = selectedColumns.split("|")
    columns.forEach((column) => {
      column.visible = selectedColumns.indexOf(column.key) < 0 ? false : true 


    });
    this.setState({
      columns,
      tournaments,
      circuits,
      grounds,
      conditions,
      selectedColumns,
      selectedCircuits,
      selectedGrounds,
      selectedConditions
    });
  };

   // change circuits
   onChangeSelectedCircuits = (res) => {
    this.setState({ selectedCircuits: res }, () => {
      // determiner ce qui n'est pas selectionner
      let unselect = []
      this.state.circuits.forEach((a) => {
        if (this.state.selectedCircuits.indexOf(a.key) < 0) unselect.push(a.key)
      })
      // save unselected elements to localstorage
      localStorage.setItem("TableTournamentsINT_unselectedCircuits", unselect.join("|"))
    })
  }
  
  // change grounds
  onChangeSelectedGrounds = (res) => {
    this.setState({ selectedGrounds: res }, () => {
      // determiner ce qui n'est pas selectionner
      let unselect = []
      this.state.grounds.forEach((a) => {
        if (this.state.selectedGrounds.indexOf(a.key) < 0) unselect.push(a.key)
      })
      // save unselected elements to localstorage
      localStorage.setItem("TableTournamentsINT_unselectedGrounds", unselect.join("|"))
    })
  }

  // change conditions
  onChangeSelectedConditions = (res) => {
    this.setState({ selectedConditions: res }, () => {
      // determiner ce qui n'est pas selectionner
      let unselect = []
      this.state.conditions.forEach((a) => {
        if (this.state.selectedConditions.indexOf(a.key) < 0) unselect.push(a.key)
      })
      // save unselected elements to localstorage
      localStorage.setItem("TableTournamentsINT_unselectedConditions", unselect.join("|"))
    })
  }

  // change columns
  onChangeSelectedColumns = (res) => {
      this.setState({ selectedColumns: res }, () => {
        // determiner ce qui est selectionne
        let select = []
        this.state.columns.forEach((a) => {
          if (this.state.selectedColumns.indexOf(a.key) > -1 ) select.push(a.key)
        })
        // save selected elements to localstorage
        localStorage.setItem("TableTournamentsINT_selectedColumns", select.join("|"))
      })
    } 

  render() {
    const { t } = this.props;
    var tournaments = this.props.tournaments;
    var columns = this.state.columns;
    var selectedColumns = this.state.selectedColumns;
    var selectedCircuits = this.state.selectedCircuits;
    var selectedGrounds = this.state.selectedGrounds;
    var selectedConditions = this.state.selectedConditions;
    var searchText = this.state.searchText;
    if (columns) {
      columns = columns.filter((o) =>
        selectedColumns.some((item) => item === o.key)
      );
    }
    if (tournaments) {
      tournaments = tournaments.filter((o) =>
        selectedCircuits.some((item) => item === o.circuit.id)
      );
    }
    if (tournaments) {
      tournaments = tournaments.filter((o) =>
        selectedGrounds.some((item) => item === o.groundId)
      );
    }
    if (tournaments) {
      tournaments = tournaments.filter((o) =>
        selectedConditions.some((item) => item === o.conditionsId)
      );
    }
    if (searchText.length > 2) {
      tournaments = tournaments.filter((o) =>
        o.name.toLowerCase().includes(searchText.toLowerCase()) || o.city.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    return (
      <>
        <FiltersBar
          components={
            <>
              <FiltersTitle title={tournaments
                  ? tournaments.length + " " + t("Tournaments").toLowerCase()
                  : ""}
              />
              <FilterSeparator />
              <MonthPicker
                className="mx-2 mt-1 2xl:mt-0 h-8 2xl:h-auto w-40 2xl:w-auto rounded-md focus:ring-indigo-500 focus:border-indigo-500 block text-xs 2xl:text-sm border-gray-300"
                value={this.props.date}
                onChange={(date) => this.props.onChange(date)}
              />
              <FilterSeparator />
              <FiltersSearch search={<SearchInput
                value={this.state.searchText}
                onChange={(searchText) => this.setState({ searchText })}
              />} />
              <FilterSeparator />
              <FiltersDropDowns selects={[
                <SelectMultiple
                  options={this.state.circuits}
                  keys={this.state.selectedCircuits}
                  className="mx-2"
                  onChange={this.onChangeSelectedCircuits}
                  fixedLabel={t("Circuit")}
                />,
                <SelectMultiple
                  options={this.state.grounds}
                  keys={this.state.selectedGrounds}
                  className="mx-2"
                  onChange={this.onChangeSelectedGrounds}
                  fixedLabel={t("Grounds")}
                />,
                <SelectMultiple
                  options={this.state.conditions}
                  keys={this.state.selectedConditions}
                  className="mx-2"
                  onChange={this.onChangeSelectedConditions}
                  fixedLabel={t("Conditions")}
                />
                ]} 
              />
              <FilterSeparator />
              <FilterColumns
                columns={
                  <SelectMultiple
                    options={this.state.columns}
                    keys={this.state.selectedColumns}
                    className="mx-2"
                    onChange={this.onChangeSelectedColumns}
                    fixedLabel={t("Active columns")}
                  />
                }
              />
              <FilterSeparator />
              <ExportToCSV columns={columns} jsonData={tournaments} fileName={t("Tournaments") + ".csv"} />
            </>
          }
          buttons={this.props.buttons}
        />
        <TableSimple columns={columns} data={tournaments} />
      </>
    );
  }
}

export default withTranslation()(TableTournamentsINT);
