import React from "react";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import Page from "../../../components/page/Page";
import {
  loadRankingNational,
  getRankingNational,
} from "../../../utils/indexedDB/Tennis-Competition/handleCompetitionTennis";
import { loadPlayersAll } from "../../../utils/indexedDB/users/handleUser";
import Loading from "../../../components/loaders/Loading";
import TableRankingNationalF from "../../../tables/TableRankingNationalF";

class Result extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dates: null,
      loading: true,
    };
  }

  componentDidMount() {
    getRankingNational("NationalF").then((rNF) => {
      if (rNF) {
        this.setState({ rNF, loading: false });
      }
    });

    if (navigator.onLine) {
      loadPlayersAll().then((players) => {
        loadRankingNational("NationalF", players).then((rNF) => {
          if (rNF) {
            this.setState({ rNF, loading: false });
          }
        });
      });
    }
  }

  render() {
    const { t } = this.props;

    return (
      <Page
        pageTitle={t("National ranking female")}
        backPage
        tab={[
          {
            title: t("National ranking female"),
            visible: true,
            data: this.state.rNF,
            component: (data) => {
              return this.state.loading ? (
                <div style={{ height: "80vh", width: "100%" }}>
                  <Loading />
                </div>
              ) : !this.state.rNF ? (
                <div>Aucune donnée à afficher</div>
              ) : (
                <TableRankingNationalF rankings={data} />
              );
            },
          },
        ]}
      />
    );
  }
}

export default withTranslation()(withRouter(Result));
