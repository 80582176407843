import React, { Component } from "react";

class CardColor extends Component {
  render() {
    return (
      <div className="col-span-1 flex rounded-md m-3 hover:border-indigo-800">
        <div
          className="flex-shrink-0 flex items-center justify-center w-16 bg-blue-800 text-white text-sm font-medium rounded-l-md hover:border-indigo-800"
          style={{ backgroundColor: this.props.color }}
        >
          {this.props.leftPart}
        </div>
        <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate hover:border-indigo-800">
          <div className="flex-1 px-4 py-2 text-sm truncate">
            {typeof this.props.rightPart === "function" ? this.props.rightPart() : this.props.rightPart}
          </div>
        </div>
      </div>
    );
  }
}

export default CardColor;
