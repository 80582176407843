import Dexie from "dexie";

const db = new Dexie("Training");

db.version(1).stores({
    trainingSequences: "++id, eventId",
    trainingCategories: "++id",
    physicalSequences: "++id, eventId",
    physicalCategories: "++id",
    workoutCategories: "++id",
    preventionCategories: "++id",
    restCategories: "++id",
    videoCategories: "++id"
});

db.version(2).stores({
    trainingDrills: "++id, groupId, authorId, trainingThemeId",
    physicalDrills: "++id, groupId, authorId, physicalThemeId",
    trainingThemes: "++id",
    physicalThemes: "++id"
});

db.version(3).stores({
    trainingCategoriesAndThemes: "++id",
    physicalCategoriesAndThemes: "++id"
});

db.version(4).stores({
    trainingSessionTemplates: "++id, clubId, authorId",
    physicalSessionTemplates: "++id, clubId, authorId"
});

db.version(5).stores({
    trainingSequencesTemplate: "++id, trainingSessionTemplateId",
    physicalSequencesTemplate: "++id, physicalSessionTemplateId",
})

export default db;
