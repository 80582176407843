import React, { Component } from "react"

// Render a SVG Flag by using nationalityId and className props //

class SVGDelete extends Component {
  render() {
    return (
        <svg className="w-3 h-3 my-1 2xl:w-4 2xl:h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" aria-labelledby="title" aria-describedby="desc" role="img" xlink="http://www.w3.org/1999/xlink">
            <path data-name="layer1" d="M58 8H44V5.9A5.9 5.9 0 0 0 38.1 0H25.9A5.9 5.9 0 0 0 20 5.9V8H6a2 2 0 0 0 0 4h3.7l2.8 45.2a7 7 0 0 0 6.8 6.8h25.4a7 7 0 0 0 6.9-6.8L54.3 12H58a2 2 0 0 0 0-4zM24 5.9A1.9 1.9 0 0 1 25.9 4h12.2A1.9 1.9 0 0 1 40 5.9V8H24zm.1 50.1H24a2 2 0 0 1-2-1.9l-2-36a2 2 0 0 1 4-.2l2 36a2 2 0 0 1-1.9 2.1zm9.9-2a2 2 0 0 1-4 0V18a2 2 0 0 1 4 0zm8 .1a2 2 0 0 1-2 1.9h-.1a2 2 0 0 1-1.9-2.1l2-36a2 2 0 0 1 4 .2z" fill="currentColor"></path>
        </svg>
    )
  }
}

export default SVGDelete

