import React from 'react'

function ComingSoon() {
  return (
    <div className="w-full">
      <div className="flex justify-center bg-white h-screen">
        <div className="flex items-center text-center lg:text-left px-8 md:px-12 lg:w-1/2">
          <div>
            <h1 className="py-5 text-5xl font-semibold text-gray-800 md:text-6xl">
              ⏰ En<span className="ml-2 text-blue-600">Construction⏰</span>
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ComingSoon;

