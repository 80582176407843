import { get } from "../requests/index";
import cogoToast from "cogo-toast";

const { BlobServiceClient } = require("@azure/storage-blob");

function getSASTokenAzureBlobStorage(domain) {
    return get("common", "SASTokenAzureBlobStorage", domain)
}

async function uploadFile(azureBlobStorageToken, formData, domain, container, uploadDataPercentageFunction, metadata) {
    const blobSasUrl = "https://" + domain + ".blob.core.windows.net?" + azureBlobStorageToken;
    const blobServiceClient = new BlobServiceClient(blobSasUrl);
    const containerClient = blobServiceClient.getContainerClient(container);

    try {
        if (formData && formData.file) {
            const blockBlobClient = containerClient.getBlockBlobClient(formData.filePath);
            let options = {}
            if(metadata) {
                options.metadata = metadata;
            }
            if(uploadDataPercentageFunction) {
                options.onProgress = (info) => {
                        uploadDataPercentageFunction(info && info.loadedBytes && formData.file.size ? (info.loadedBytes * 100 / formData.file.size) : false)
                }
            }
            return blockBlobClient.uploadData(formData.file, options);
        } else {
            throw new Error("File not found")
        }
    }
    catch (error) {
        cogoToast.error("Error upload blob storage:" + error.message, { position: "bottom-right" });
    }
}

export {
    getSASTokenAzureBlobStorage,
    uploadFile
}