import React from "react";
import { withTranslation } from "react-i18next";

class FiltersBar extends React.Component {
  render() {
    return (
        <div className="px-6 2xl:px-8 py-1 2xl:py-2 flex justify-between border-b-2">
            <div className="flex flex-row">
                {this.props.components}
            </div>
            {this.props.buttons ? (
            <>
            <div className="flex flex-row">
                {this.props.buttons.map((button, key) => <span key={key}>{button}</span>)}
            </div>
            </>
            ) : (
            ""
            )}
        </div>
    );
  }
}

export default withTranslation()(FiltersBar);
