const internationalRankingData = [
  {
    tournament: "ATP Simple",
    extension: "ATP",
    logo: "https://exaduosport.blob.core.windows.net/competitions/ATP.png",
    gender: "H",
  },
  
  {
    tournament: "WTA Simple",
    extension: "WTA",
    logo: "https://exaduosport.blob.core.windows.net/competitions/WTA.png",
    gender: "F",
  },
  {
    tournament: "ATP Double",
    extension: "ATPDbl",
    logo: "https://exaduosport.blob.core.windows.net/competitions/ATP.png",
    gender: "H",
  },
  {
    tournament: "WTA Double",
    extension: "WTADbl",
    logo: "https://exaduosport.blob.core.windows.net/competitions/WTA.png",
    gender: "F",
  },
  {
    tournament: "ITF H",
    extension: "ItfH",
    logo: "https://exaduosport.blob.core.windows.net/competitions/ITF.png",
    gender: "H",
  },
  {
    tournament: "ITF F",
    extension: "ItfF",
    logo: "https://exaduosport.blob.core.windows.net/competitions/ITF.png",
    gender: "F",
  },
  {
    tournament: "ITF Junior H",
    extension: "ItfJH",
    logo: "https://exaduosport.blob.core.windows.net/competitions/ITFJr.png",
    gender: "H",
  },
  {
    tournament: "ITF Junior F",
    extension: "ItfJF",
    logo: "https://exaduosport.blob.core.windows.net/competitions/ITFJr.png",
    gender: "F",
  },
  {
    tournament: "Tennis Europe U14 H",
    extension: "TennisEuropeU14H",
    logo:
      "https://exaduosport.blob.core.windows.net/competitions/TennisEurope.jpg",
    gender: "H",
  },
  {
    tournament: "Tennis Europe U14 F",
    extension: "TennisEuropeU14F",
    logo:
      "https://exaduosport.blob.core.windows.net/competitions/TennisEurope.jpg",
    gender: "F",
  },
];

export default internationalRankingData;
