import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ExportToCSV from "../components/export/ExportToCSV";
import FilterColumns from "../components/filters/FilterColumns";
import FiltersBar from "../components/filters/FiltersBar";
import FiltersDropDowns from "../components/filters/FiltersDropDowns";
import FiltersSearch from "../components/filters/FiltersSearch";
import FiltersTitle from "../components/filters/FiltersTitle";
import { SelectMultiple } from "../components/forms";
import SearchInput from "../components/forms/input/SearchInput";
import IconRound32x32 from "../components/icons/IconRound32x32";
import TableSimple from "../components/table/TableSimple";

class TableTournamentsResults extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actions: [],
      circuits: [],
      selectedCircuits: [],
      modes: [],
      selectedModes: [],
      columns: [],
      searchText: "",
    };
  }

  componentDidMount() {
    this.prepareData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.prepareData();
    }
  }

  prepareData = () => {
    var results = this.props.results;
    var modes = [];
    var selectedModes = [];
    var circuits = [];
    var selectedCircuits = [];
    if (results) {
      results = results.map((result) => {
        if (
          circuits &&
          !circuits.find((o) => o.key === result.tournament.circuit.id)
        ) {
          circuits.push({
            key: result.tournament.circuit.id,
            label: result.tournament.circuit.name,
          });
          selectedCircuits.push(result.tournament.circuit.id);
        }
        if (modes && !modes.find((o) => o.key === result.mode)) {
          modes.push({
            key: result.mode,
            label: result.mode,
          });
          selectedModes.push(result.mode);
        }
        return result;
      });
    }

    const columns = [
      {
        key: "date",
        visible: true,
        label: this.props.t("Dates"),
        id: "date",
        format: (row) => (
          <span className="font-weight-bold text-muted text-xs 2xl:text-sm">{row.date}</span>
        ),
        width: 200,
      },
      {
        key: "player.name",
        visible: true,
        pinned: "left",
        label: this.props.t("Player"),
        id: "displayName",
        width: 300,
        format: (row) => (
          <Link
            className="text-indigo-700 font-semibold text-xs 2xl:text-sm"
            to={"/group/player/" + row.player.id}
          >
            <IconRound32x32 photo={row.player.photo} />
            <span className="ml-5">
              {row.player.lastName.toUpperCase() + " " + row.player.firstName}
            </span>
          </Link>
        ),
      },
      {
        key: "name",
        visible: true,
        label: this.props.t("Tournois"),
        id: "name",
        format: (row) => (
          <Link
            className="text-indigo-700 font-semibold text-xs 2xl:text-sm"
            to={
              row.tournament.tournamentTypeId === "National" ? ("/competitionTennis/nationaldetails/" + row.tournament.id + "/a/b") : ("/competitionTennis/internationaldetails/" + row.tournament.id + "/a/b")
            }
          >
            {row.name}
          </Link>
        ),
        width: 300,
      },
      {
        key: "cname",
        visible: true,
        label: this.props.t("Circuit"),
        id: "cname",
        filter: {
          name: "SelectColumnFilter",
          type: "includes",
        },
        width: 200,
        format: (row) =>
          row.tournament && row.tournament.circuit ? (
            <span
              className="px-3 py-0.5 rounded-full text-xs 2xl:text-sm font-medium bg-red-100 text-red-800"
              style={{
                color: row.tournament.circuit.color2,
                backgroundColor: row.tournament.circuit.color1,
              }}
            >
              {row.tournament.circuit.name +
                (row.tournament.classification
                  ? " " + row.tournament.classification
                  : "")}
            </span>
          ) : (
            "-"
          ),
      },
      {
        key: "mode",
        visible: true,
        label: this.props.t("Epreuve"),
        id: "mode",
        filter: {
          name: "SelectColumnFilter",
          type: "includes",
        },
        width: 200,
        format: (row) => <span className="text-xs 2xl:text-sm">{row.mode}</span>,
      },
      {
        key: "tournament.ground.name",
        visible: true,
        label: this.props.t("Surface"),
        filter: {
          name: "SelectColumnFilter",
          type: "includes",
        },
        format: (row) => (
          <span className="font-weight-bold text-muted text-xs 2xl:text-sm">
            
            {row.tournament
              ? row.tournament.ground
                ? row.tournament.ground.name
                : "N/A"
              : "N/A"}
          </span>
        ),
        width: 200,
      },
      {
        key: "tournament.conditions.name",
        visible: true,
        label: this.props.t("Conditions"),
        filter: {
          name: "SelectColumnFilter",
          type: "includes",
        },
        format: (row) => (
          <span className="font-weight-bold text-muted text-xs 2xl:text-sm">
            {row.tournament
              ? row.tournament.conditions
                ? row.tournament.conditions.name
                : "N/A"
              : "N/A"}
          </span>
        ),
        width: 200,
      },
      {
        key: "tmax",
        visible: true,
        label: this.props.t("Result"),
        id: "tmax",
        filter: {
          name: "SelectColumnFilter",
          type: "includes",
        },
        format: (row) => <span className="text-xs 2xl:text-sm">{(row.isWon ? "Vainqueur" : row.tmax)}</span>,
        width: 200,
      },
    ];
    var selectedColumns = [];
    columns.forEach((column) => {
      if (column.visible) {
        selectedColumns.push(column.key);
      }
    });
    this.setState({
      columns,
      results,
      circuits,
      modes,
      selectedColumns,
      selectedCircuits,
      selectedModes,
    });
  };

  render() {
    const { t } = this.props;
    var results = this.props.results;
    var columns = this.state.columns;
    var selectedColumns = this.state.selectedColumns;
    var selectedCircuits = this.state.selectedCircuits;
    var selectedModes = this.state.selectedModes;
    var searchText = this.state.searchText;
    if (columns) {
      columns = columns.filter((o) =>
        selectedColumns.some((item) => item === o.key)
      );
    }
    if (results) {
      results = results.filter((o) =>
        selectedCircuits.some((item) => item === o.tournament.circuit.id)
      );
    }
    if (results) {
      results = results.filter((o) =>
        selectedModes.some((item) => item === o.mode)
      );
    }
    if (searchText.length > 2) {
      results = results.filter((o) =>
        o.name.toLowerCase().includes(searchText.toLowerCase())
      );
    }

    return (
      <>
        <FiltersBar
          components={
            <>
              <FiltersTitle title={results ? results.length + " " + t("Results").toLowerCase() : ""} />
              <span className="ml-5 mr-5 w-1 border-l"></span>
              <FiltersSearch search={<SearchInput
                value={this.state.searchText}
                onChange={(searchText) => this.setState({ searchText })}
                
              />} />
              <span className="ml-5 mr-5 w-1 border-l"></span>
              <FiltersDropDowns selects={[
                <SelectMultiple
                  options={this.state.circuits}
                  keys={this.state.selectedCircuits}
                  className="mx-2"
                  onChange={(res) => this.setState({ selectedCircuits: res })}
                  fixedLabel={t("Circuit")}
                />,
                <SelectMultiple
                  options={this.state.modes}
                  keys={this.state.selectedModes}
                  className="mx-2"
                  onChange={(res) => this.setState({ selectedModes: res })}
                  fixedLabel={t("Mode")}
                />
                ]} 
              />
              <span className="ml-5 mr-5 w-1 border-l"></span>
              <FilterColumns
                columns={
                  <SelectMultiple
                    options={this.state.columns}
                    keys={this.state.selectedColumns}
                    className="mx-2"
                    onChange={(res) => this.setState({ selectedColumns: res })}
                    fixedLabel={t("Active columns")}
                  />
                }
              />
              <span className="ml-5 mr-5 w-1 border-l"></span>
              <ExportToCSV columns={columns} jsonData={results} fileName={t("Results") + ".csv"} />
            </>
          }
        />
        <TableSimple columns={columns} data={results} />
      </>
    );
  }
}

export default withTranslation()(TableTournamentsResults);
