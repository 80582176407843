import React from "react";
import ActionButton from "../components/buttons/ActionButton";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { SelectMultiple } from "../components/forms";
import TableSimple from "../components/table/TableSimple";
import FiltersDropDowns from "../components/filters/FiltersDropDowns";
import FilterColumns from "../components/filters/FilterColumns";
import SearchInput from "../components/forms/input/SearchInput";
import FiltersSearch from "../components/filters/FiltersSearch";
import generateColor from "../utils/generateColor";
import FilterSeparator from "../components/filters/FilterSeparator";
import FiltersTitle from "../components/filters/FiltersTitle";
import FiltersBar from "../components/filters/FiltersBar";

class TableCurriculums extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actions: [],
      schools: [],
      selectedSchools: [],
      types: [],
      selectedTypes: [],
      columns: [],
      searchText: "",
    };
  }

  componentDidMount() {
    this.prepareData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.prepareData();
    }
  }

  prepareData = () => {
    var curriculums = this.props.curriculums;
    var types = [];
    var selectedTypes = [];
    var schools = [];
    var selectedSchools = [];

    if (curriculums) {
      curriculums = curriculums.map((curriculum) => {
        if (types && !types.find((o) => o.key === curriculum.type.id)) {
          types.push({
            key: curriculum.type.id,
            label: curriculum.type.name,
          });
          selectedTypes.push(curriculum.type.id);
        }
        if (schools && !schools.find((o) => o.key === curriculum.school.id)) {
          schools.push({
            key: curriculum.school.id,
            label: curriculum.school.name,
          });
          selectedSchools.push(curriculum.school.id);
        }
        return curriculum;
      });
    }

    const columns = [
      {
        key: "name",
        visible: true,
        label: this.props.t("Name"),
        pinned: "left",
        id: "name",
        format: (row) => {
          return (
            <>
              <Link
                className="text-indigo-700 font-medium text-xs 2xl:text-sm"
                to={"/administration/curriculum/" + row.id}
              >
                {row.shortName}
              </Link>
            </>
          );
        },
      },
      {
        key: "school",
        visible: true,
        label: this.props.t("School"),
        pinned: "left",
        id: "school",
        format: (row) => {
          return (
            <>
              {row.school ? (
                <Link
                  className="text-indigo-700 font-medium text-xs 2xl:text-sm"
                  to={"/administration/school/" + row.schoolId}
                >
                  {row.school.name}
                </Link>
              ) : (
                "-"
              )}
            </>
          );
        },
      },
      {
        key: "type",
        visible: true,
        label: this.props.t("Type"),
        pinned: "left",
        id: "type",
        format: (row) => {
          return (<>
          <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs 2xl:text-sm font-medium bg-yellow-100 text-yellow-800" style={{color: "#FFFFFF", backgroundColor: generateColor(row.type.name)}}>
            {row.type ? row.type.name : "-"}
            </span>
          </>);
        },
      },
      {
        key: "description",
        visible: true,
        label: this.props.t("Description"),
        pinned: "left",
        id: "description",
        format: (row) => {
          return <><span className="text-xs 2xl:text-sm">{row.description}</span></>;
        },
      },
      {
        key: "actions",
        visible: true,
        pinned: "left",
        label: "",
        format: (row) => (
          <ActionButton
            key={row.id}
            updateForm="updateCurriculum"
            deleteForm="deleteCurriculum"
            formData={row}
            refreshData={() => this.props.callback()}
          />
        ),
      },
    ];
    var selectedColumns = [];
    columns.forEach((column) => {
      if (column.visible) {
        selectedColumns.push(column.key);
      }
    });
    this.setState({
      columns,
      curriculums,
      schools,
      types,
      selectedColumns,
      selectedSchools,
      selectedTypes,
    });
  };
  render() {
    const { t } = this.props;
    var curriculums = this.props.curriculums;
    var columns = this.state.columns;
    var selectedColumns = this.state.selectedColumns;
    var selectedTypes = this.state.selectedTypes;
    var selectedSchools = this.state.selectedSchools;
    var searchText = this.state.searchText;
    if (columns) {
      columns = columns.filter((o) =>
        selectedColumns.some((item) => item === o.key)
      );
    }
    if (curriculums) {
      curriculums = curriculums.filter((o) =>
        selectedTypes.some((item) => item === o.type.id)
      );
    }
    if (curriculums) {
      curriculums = curriculums.filter((o) =>
        selectedSchools.some((item) => item === o.school.id)
      );
    }
    if (searchText.length > 2) {
      curriculums = curriculums.filter((o) =>
        o.shortName.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    return (
      <>
        <FiltersBar
          components={
            <>
              <FiltersTitle title={curriculums
                  ? curriculums.length + " " + t("Curriculums").toLowerCase()
                  : ""}
              />
              <FilterSeparator />
              <FiltersSearch search={<SearchInput
                value={this.state.searchText}
                onChange={(searchText) => this.setState({ searchText })}
                
              />} />
              <FilterSeparator />
              <FiltersDropDowns selects={[
                <SelectMultiple
                  options={this.state.types}
                  keys={this.state.selectedTypes}
                  className="mx-2"
                  onChange={(res) => this.setState({ selectedTypes: res })}
                  fixedLabel={t("Type")}
                />,
                <SelectMultiple
                  options={this.state.schools}
                  keys={this.state.selectedSchools}
                  className="mx-2"
                  onChange={(res) => this.setState({ selectedSchools: res })}
                  fixedLabel={t("School")}
                />
                ]} 
              />
              <FilterSeparator />
              <FilterColumns
                columns={
                  <SelectMultiple
                    options={this.state.columns}
                    keys={this.state.selectedColumns}
                    className="mx-2"
                    onChange={(res) => this.setState({ selectedColumns: res })}
                    fixedLabel={t("Active columns")}
                  />
                }
              />
            </>
          }
          buttons={this.props.buttons}
        />
        <TableSimple columns={columns} data={curriculums} />
      </>
    );
  }
}

export default withTranslation()(TableCurriculums);
