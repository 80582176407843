import React from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { SelectMultiple } from "../components/forms";
import TableSimple from "../components/table/TableSimple";
import IconRound32x32 from "../components/icons/IconRound32x32";
import FiltersDropDowns from "../components/filters/FiltersDropDowns";
import FilterColumns from "../components/filters/FilterColumns";
import FiltersSearch from "../components/filters/FiltersSearch";
import SearchInput from "../components/forms/input/SearchInput";
import ExportToCSV from "../components/export/ExportToCSV";
import Tooltip from "../components/typography/Tooltip/Tooltip";
import FilterSeparator from "../components/filters/FilterSeparator";
import FiltersTitle from "../components/filters/FiltersTitle";
import FiltersBar from "../components/filters/FiltersBar";

class TableMonitoringTests extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actions: [],
      groups: [],
      selectedGroups: [],
      columns: [],
      searchText: "",
      sort_field: "name",
      sort_direction: -1,
      players: [],
    };
  }

  componentDidMount() {
    this.setState({
      players : this.props.players,
    }, () => {
      this.prepareData() 
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      // do this to make props.players modifiable in prepareData
      this.setState({ players: this.props.players }, () => {
        this.prepareData()
      })
    }
  }

  generateFormatHeader(key) {
    return (label) => {
      return (
        <b
        className="cursor-pointer"
          onClick={() => {
            this.setState(
              {
                sort_field: key,
                sort_direction: -1 * this.state.sort_direction,
              },
              () => {
                this.prepareData()
              }
            )
          }}
        >
          {label}
          {key ? (
            this.state.sort_direction === -1 ? (
              <span className="text-lg">&#8661;</span>
            ) : (
              <span className="text-lg">&#8661;</span>
            )
          ) : (
            ""
          )}
        </b>
      )
    }
  }

  prepareData = () => {
    var players = this.state.players;
    var groups = [];
    var selectedGroups = [];
    if (players) {
      players = players.map((player) => {
        if (groups && !groups.find((o) => o.key === player.group1.id)) {
          groups.push({
            key: player.group1.id,
            label: player.group1.name,
          });
          selectedGroups.push(player.group1.id);
        }
        return player;
      });
    }

    const columns = [
      {
        key: "name",
        visible: true,
        pinned: "left",
        label: this.props.t("Player"),
        id: "displayName",
        width: 300,
        format: (row) => (
          <Link
            className="text-indigo-700 font-semibold"
            to={"/group/player/" + row.id}
          >
            <IconRound32x32 photo={row.photo} />
            <span className="ml-5">
              {row.lastName.toUpperCase() + " " + row.firstName}
            </span>
          </Link>
        ),
        formatHeader: this.generateFormatHeader("name"),
      },
    ];
    if (this.props.measures) {
      this.props.measures.forEach((measure) => {
        if (players) {
          for (var i = 0; i < players.length; i++) {
            if (
              !players[i].monitoringTestsData ||
              !players[i].monitoringTestsData.length
            )
              break
            for (var j = 0; j < players[i].monitoringTestsData.length; j++) {
              if (players[i].monitoringTestsData[j].measureId === measure.id) {
                players[i][measure.id] = players[i].monitoringTestsData[j].value
                break
              }
            }
          }
        }
        columns.push({
          key: measure.id,
          visible: true,
          id: measure.id,
          label: measure.name,
          format: (row) =>
            row &&
            row.monitoringTestsData &&
            row.monitoringTestsData.length > 0 &&
            row.monitoringTestsData.find((o) => o.measureId === measure.id)
              ? <Tooltip tooltipContent={<ul className="divide-y divide-gray-200 w-96">
              <li className="relative py-5 px-4 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
                <div className="flex justify-between space-x-3">
                  <div className="min-w-0 flex-1">
                    <div>
                      <span className="absolute inset-0" aria-hidden="true"></span>
                      <p className="text-sm font-medium text-gray-900 truncate">{this.props.t("Last result")}</p>
                    </div>
                  </div>
                  <div className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500">{row.monitoringTestsData.find((o) => o.measureId === measure.id).value != null ? row.monitoringTestsData.find((o) => o.measureId === measure.id).value + measure.unit : "-"}</div>
                </div>
              </li>
              <li className="relative py-5 px-4 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
                <div className="flex justify-between space-x-3">
                  <div className="min-w-0 flex-1">
                    <div>
                      <span className="absolute inset-0" aria-hidden="true"></span>
                      <p className="text-sm font-medium text-gray-900 truncate">{this.props.t("Previous result")}</p>
                    </div>
                  </div>
                  <div className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500">{row.monitoringTestsData.find((o) => o.measureId === measure.id).previousValue != null ? row.monitoringTestsData.find((o) => o.measureId === measure.id).previousValue + measure.unit : "-"}</div>
                </div>
              </li>
              <li className="relative py-5 px-4 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
                <div className="flex justify-between space-x-3">
                  <div className="min-w-0 flex-1">
                    <div>
                      <span className="absolute inset-0" aria-hidden="true"></span>
                      <p className="text-sm font-medium text-gray-900 truncate">{this.props.t("Delta")}</p>
                    </div>
                  </div>
                  <div className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500">{row.monitoringTestsData.find((o) => o.measureId === measure.id).delta != null ? row.monitoringTestsData.find((o) => o.measureId === measure.id).delta + measure.unit : "-"}</div>
                </div>
              </li>
              <li className="relative py-5 px-4 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
                <div className="flex justify-between space-x-3">
                  <div className="min-w-0 flex-1">
                    <div>
                      <span className="absolute inset-0" aria-hidden="true"></span>
                      <p className="text-sm font-medium text-gray-900 truncate">{this.props.t("Var") + " (%)"}</p>
                    </div>
                  </div>
                  <div className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500">{row.monitoringTestsData.find((o) => o.measureId === measure.id).var ? row.monitoringTestsData.find(o => o.measureId === measure.id).var.toFixed(2) + "%" : "-"}</div>
                </div>
              </li>
            </ul>}>  {row.monitoringTestsData.find((o) => o.measureId === measure.id)
                  .value + measure.unit}</Tooltip>
              : "-",
          
            // row &&
            // row.monitoringTestsData &&
            // row.monitoringTestsData.length > 0 &&
            // row.monitoringTestsData.find((o) => o.measureId === measure.id)
            //   ? row.monitoringTestsData.find((o) => o.measureId === measure.id)
            //       .value + measure.unit
            //   : "-",
          formatHeader: this.generateFormatHeader(measure.id),
        })
      })
    }
    // after all the columns are added, and the data is elevated appropriately
    // we can sort data
    if (players) {
      var field = this.state.sort_field
      var direction = this.state.sort_direction
      players.sort((a, b) => {
        if (!a[field]) return 1
        if (!b[field]) return -1
        return a[field] < b[field] ? direction : -1 * direction
      })
    }
    
    var selectedColumns = [];
    columns.forEach((column) => {
      if (column.visible) {
        selectedColumns.push(column.key);
      }
    });
    this.setState({
      columns,
      players,
      groups,
      selectedColumns,
      selectedGroups,
    });
  };

  render() {
    const { t } = this.props;
    var players = this.state.players;
    var columns = this.state.columns;
    var selectedColumns = this.state.selectedColumns;
    var selectedGroups = this.state.selectedGroups;
    var searchText = this.state.searchText;
    if (columns) {
      columns = columns.filter((o) =>
        selectedColumns.some((item) => item === o.key)
      );
    }
    if (players) {
      players = players.filter((o) =>
        selectedGroups.some((item) => item === o.group1.id)
      );
    }
    if (searchText.length > 2) {
      players = players.filter(
        (o) =>
          o.lastName.toLowerCase().includes(searchText.toLowerCase()) ||
          o.firstName.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    return (
      <>
        <FiltersBar
          components={
            <>
              <FiltersTitle title={players ? players.length + " " + t("Players").toLowerCase() : ""} />
              <FilterSeparator />
              <FiltersSearch search={<SearchInput
                value={this.state.searchText}
                onChange={(searchText) => this.setState({ searchText })}
              />} />
              <FilterSeparator />
              <FiltersDropDowns selects={[
                <SelectMultiple
                  options={this.state.groups}
                  keys={this.state.selectedGroups}
                  className="mx-2"
                  onChange={(res) => this.setState({ selectedGroups: res })}
                  fixedLabel={t("Group")}
                />
                ]} 
              />
              <FilterSeparator />
              <FilterColumns
                columns={
                  <SelectMultiple
                    options={this.state.columns}
                    keys={this.state.selectedColumns}
                    className="mx-2"
                    onChange={(res) => this.setState({ selectedColumns: res })}
                    fixedLabel={t("Active columns")}
                  />
                }
              />
              <FilterSeparator />
              <ExportToCSV columns={columns} jsonData={players} fileName={t("Monitoring tests") + ".csv"} />
            </>
          }
          buttons={this.props.buttons}
        />
        <TableSimple columns={columns} data={players} />
      </>
    );
  }
}

export default withTranslation()(TableMonitoringTests);
