import React from "react";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import ChartCycleByPlayerWeeklySessionTimesByTypes from "../../charts/ChartCycleByPlayerWeeklySessionTimesByTypes";
import ChartCycleByPlayerWeeklySequencesByTrainingCategories from "../../charts/ChartCycleByPlayerWeeklySequencesByTrainingCategories";
import ChartCycleByPlayerWeeklySequencesByPhysicalCategories from "../../charts/ChartCycleByPlayerWeeklySequencesByPhysicalCategories";
import ChartCycleByPlayerWeeklySessionTimesByMentalCategories from "../../charts/ChartCycleByPlayerWeeklySessionTimesByMentalCategories";
import {
  loadSeasons,
  loadWeeksResultValue,
  loadWeeksMentalResultValue,
  loadWeeksPhysicalResultValue,
  loadWeeksTrainingResultValue,
  getSeasons,
  getWeeksResultValue,
  getWeeksMentalResultValue,
  getWeeksTrainingResultValue,
  getWeeksPhysicalResultValue
} from "../../utils/indexedDB/events/handleEvents";
import SubTab from "../../components/tab/SubTab";

class PlayerTrainingTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.playerId,
      trainingTab: "Global",
    };
  }
  componentDidMount() {
    var id = this.props.playerId;
    if (navigator.onLine) {
      loadSeasons().then((seasons) => {
        this.setState({ seasons });
        if (seasons && seasons.length > 0) {
          var season = seasons[seasons.length - 1];
          this.setState({ season });
          loadWeeksResultValue(id, season.startDate, season.endDate).then(res => {
            res = res.map(result => {
              if (result.results) {
                result.results = result.results.map(r => {
                  if (r.typeId) {
                    switch (r.typeId) {
                      case "Entrainement":
                        r.typeId = "Tennis";
                        break;
                      case "Musculation":
                      case "Prevention":
                      case "Energetique":
                        r.typeId = "Physique";
                        break;
                      case "Match tennis":
                        r.typeId = "Match";
                        break;
                      default:
                        break;
                    }
                  }
                  return r;
                })
              }
              return result;
            })
            this.setState({ weeksResults: res })
          });
          loadWeeksMentalResultValue(id, season.startDate, season.endDate).then(res => {
            this.setState({ weeksResultsMental: res });
          });
          loadWeeksTrainingResultValue(id, season.startDate, season.endDate).then(res => {
            this.setState({ weeksResultsTraining: res });
          });
          loadWeeksPhysicalResultValue(id, season.startDate, season.endDate).then(res => {
            this.setState({ weeksResultsPhysical: res });
          });
        }
      });
    } else {
      getSeasons().then((seasons) => {
        this.setState({ seasons });
        if (seasons && seasons.length > 0) {
          var season = seasons[seasons.length - 1];
          this.setState({ season });
          getWeeksResultValue(id, season.startDate, season.endDate).then(res => {
            res = res.map(result => {
              if (result && result.results) {
                result.results = result.results.map(r => {
                  if (r.typeId) {
                    switch (r.typeId) {
                      case "Entrainement":
                        r.typeId = "Tennis";
                        break;
                      case "Musculation":
                      case "Prevention":
                      case "Energetique":
                        r.typeId = "Physique";
                        break;
                      case "Match tennis":
                        r.typeId = "Match";
                        break;
                      default:
                        break;
                    }
                  }
                  return r;
                })
              }
              return result;
            })
            this.setState({ weeksResults: res })
          });
          getWeeksMentalResultValue(id, season.startDate, season.endDate).then(res => {
            this.setState({ weeksResultsMental: res });
          });
          getWeeksTrainingResultValue(id, season.startDate, season.endDate).then(res => {
            this.setState({ weeksResultsTraining: res });
          });
          getWeeksPhysicalResultValue(id, season.startDate, season.endDate).then(res => {
            this.setState({ weeksResultsPhysical: res });
          });
        }
      });
    }
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <SubTab
          tab={[
            { key: "Global", label: t("Global") },
            { key: "Tennis", label: t("Tennis") },
            { key: "Physical", label: t("Physical") },
            { key: "Mental", label: t("Mental") },
          ]}
          selectedTab={this.state.trainingTab}
          onChange={(key) =>
            this.setState({
              trainingTab: key,
            })
          }
        />
        <div className="row">
          <div className="col-xl-9 col-xs-12">
            {this.state.trainingTab === "Global" &&
              this.state.weeksResults ? (
                  <ChartCycleByPlayerWeeklySessionTimesByTypes
                    data={this.state.weeksResults}
                  />
              ) : (
                ""
              )}
            {this.state.trainingTab === "Tennis" &&
              this.state.weeksResultsTraining ? (
                <>
                    <ChartCycleByPlayerWeeklySequencesByTrainingCategories
                      data={this.state.weeksResultsTraining}
                    />
                </>
              ) : (
                ""
              )}
            {this.state.trainingTab === "Physical" &&
              this.state.weeksResultsPhysical ? (
                  <ChartCycleByPlayerWeeklySequencesByPhysicalCategories
                    data={this.state.weeksResultsPhysical}
                  />
              ) : (
                ""
              )}
            {this.state.trainingTab === "Mental" &&
              this.state.weeksResultsMental ? (
                  <ChartCycleByPlayerWeeklySessionTimesByMentalCategories
                    data={this.state.weeksResultsMental}
                  />
              ) : (
                ""
              )}
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(withRouter(PlayerTrainingTab));
