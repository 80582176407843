const includesInField = function (obj, key, search) {
  return obj[key] === null
    ? false
    : obj[key]
        .toString()
        .toUpperCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .includes(
          search
            .toUpperCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
        );
};

const includesInObject = function (obj, search) {
  return Object.keys(obj).some(function (key) {
    if (obj[key] && obj[key] !== null) {
      if (typeof obj[key] === "object") {
        return false;
      } else {
        return includesInField(obj, key, search);
      }
    } else {
      return false;
    }
  });
};

export default function getContentFiltered(data, search) {
  var contentFiltered;
  if (search !== null && search.toString() !== "") {
    if (data && data.length > 0) {
      contentFiltered = data.filter((obj) => {
        return includesInObject(obj, search);
      });
    }
  } else {
    contentFiltered = data;
  }
  return contentFiltered;
}
