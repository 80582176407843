import React from "react";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import Page from "../../components/page/Page";
import {
    getPeriodicReportFromId,
    loadPeriodicReportSections,
    getPeriodicReportSections
} from "../../utils/indexedDB/management/handleManagement"
import Card from "../../components/card/Card";
import moment from "moment";
import IconRound32x32 from "../../components/icons/IconRound32x32";
import { Link } from "react-router-dom";
import DisplayData3cols from "../../components/displayData/DisplayData3cols";
import ButtonLight from "../../components/buttons/ButtonLight";
import GeneratedForm from "../../utils/forms/generateForm";

class PeriodicReportDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            sections: [],
            refresh: false
        };
    }

    componentDidMount() {
        var id = this.props.match.params.id;
        getPeriodicReportFromId(id).then((base) => {
            this.setState({ base });
        });
        getPeriodicReportSections(id).then((sections) => {
            return (sections ? this.setState({ sections }) : [])
        });

        if (navigator.onLine) {
            loadPeriodicReportSections(id).then((sections) => {
                return (sections ? this.setState({ sections }) : [])
            });
        }
    }

    render() {
        const { t } = this.props;
        var id = this.props.match.params.id;
        var base = this.state.base;
        var sections = this.state.sections;

        if (base) {
            var baseData = [{
                key: t("Player"),
                value: base.displayName
                    ? <Link
                        className="text-indigo-700 font-semibold"
                        to={"/group/player/" + base.playerId}
                    >
                        <IconRound32x32 photo={base.photo} />
                        <span className="ml-5">
                            {base.displayName}
                        </span>
                    </Link>
                    : "",
            },
            {
                key: t("Period"),
                value: base.period
            },
            {
                key: t("Date"),
                value: moment(base.date).format("D MMMM YYYY"),
            }]

            sections.forEach(section => {
                baseData.push({
                    key: section.categoryId ? t(section.categoryId) : null,
                    value: section.grade ? section.grade + "/10" : t("Not graded")
                })
            })

            var tab = [{
                title: t("Informations principales"),
                visible: true,
                data: base,
                component: () => {
                    return (<>
                        <div className="flex space-x-5">
                            <div className="w-full py-3 px-10">
                                <Card
                                className=" "
                                title={t("Details")}
                                    headerActions={
                                        <GeneratedForm
                                            key="createPeriodicReportSection"
                                            component={(show, text) => (
                                                <ButtonLight onClick={show} disabled={!navigator.onLine}>
                                                    <i className="flaticon2-plus"></i> {text}
                                                </ButtonLight>
                                            )}
                                            callback={(res) =>
                                                loadPeriodicReportSections(id).then((sections) => {
                                                    this.setState({ refresh: true })
                                                    return (sections ? this.setState({ sections, refresh: false }) : [])
                                                })
                                            }
                                            formName="createPeriodicReportSection"
                                            formData={{reportId: base.id, file: null}}
                                        />
                                    }
                                >
                                    <DisplayData3cols
                                        data={baseData}
                                    />
                                </Card>
                            </div>
                        </div>
                    </>
                    );
                },
            }]

            sections.forEach(section => {
                tab.push({
                    title: t(section.categoryId),
                    visible: true,
                    data: section,
                    component: (data) => {
                        return (<>
                            <div className="grid grid-cols-3 gap-4">
                                <div className="col-span-2">
                                    <Card
                                        className=""
                                        title={t("Information")}
                                        headerActions={
                                            <GeneratedForm
                                                key="updatePeriodicReportSection"
                                                component={(show, text) => (
                                                    <ButtonLight onClick={show} disabled={!navigator.onLine}>
                                                        <i className="flaticon2-plus"></i> {text}
                                                    </ButtonLight>
                                                )}
                                                callback={(res) =>
                                                    loadPeriodicReportSections(id).then((sections) => {
                                                        this.setState({ refresh: true })
                                                        return (sections ? this.setState({ sections, refresh: false }) : [])
                                                    })
                                                }
                                                formName="updatePeriodicReportSection"
                                                formData={data}
                                            />
                                        }
                                    >
                                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 py-6">
                                            <div className="">
                                                <dt className="text-sm font-medium text-gray-500">
                                                    {t("Comments")}
                                                </dt>
                                                <dd className="mt-1 text-md text-gray-900 font-bold">
                                                    {data.comments}
                                                </dd>
                                            </div>
                                            <div className="">
                                                <dt className="text-sm font-medium text-gray-500">
                                                    {t("Date")}
                                                </dt>
                                                <dd className="mt-1 text-md text-gray-900 font-bold">
                                                    {moment(data.date).format("D MMMM YYYY")}
                                                </dd>
                                            </div>
                                        </dl>
                                    </Card>
                                </div>
                                <Card
                                    className=" "
                                    title={t("Grade")}
                                    headerActions={
                                        <GeneratedForm
                                            key="deletePeriodicReportSection"
                                            component={(show, text) => (
                                                <ButtonLight onClick={show} disabled={!navigator.onLine}>
                                                    <i className="flaticon2-plus"></i> {text}
                                                </ButtonLight>
                                            )}
                                            callback={(res) =>
                                                loadPeriodicReportSections(id).then((sections) => {
                                                    this.setState({ refresh: true })
                                                    return (sections ? this.setState({ sections, refresh: false }) : [])
                                                })
                                            }
                                            formName="deletePeriodicReportSection"
                                            formData={data}
                                        />
                                    }
                                >
                                    <div className="">
                                        <ul className="divide-y divide-gray-200 h-96 overflow-auto">
                                            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 py-6">
                                                <div className="">
                                                    <dd className="mt-1 text-md text-gray-900 font-bold">
                                                        {data.grade ? data.grade + "/10" : t("Not graded")}
                                                    </dd>
                                                </div>
                                            </dl>
                                        </ul>
                                    </div>
                                </Card>
                            </div>
                        </>
                        );
                    },
                })
            })
        }

        return base && !this.state.refresh ? (
            <Page
                pageTitle={base.name}
                backPage="/management"
                actions={{ addForm: "updatePeriodicReport", deleteForm: "deletePeriodicReport", formData: base }}
                refreshData={() => this.props.history.goBack()}
                tab={tab}
            />
        ) : null;
    }
}

export default withTranslation()(withRouter(PeriodicReportDetails));
