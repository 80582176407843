import post from "./post";

async function visits(userId, module, page, host) {
  if (host.startsWith("localhost") || host.startsWith("dev-app")) return;

  return post("common", "visits", { userId, module, page }).then((res) => {
    if (res.ok) return res.json();
  });
}

export default visits;
