import React from "react";
import moment from "moment";
import CardTennisMatch from "./CardTennisMatch";
import { Link } from "react-router-dom";
// import Link from "react-router";

class TennisTournoisCard extends React.Component {
  render() {
    // var title = this.props.title;
    var headerActions = this.props.headerActions;
    // var subTitle = this.props.subTitle;
    return (
      <>
        <div
          className="bg-white overflow-hidden rounded-lg  divide-y divide-gray-200 mx-6 my-4"
          onClick={this.props.onClick}
        >
          {this.props.tournoi.name || headerActions ? (
            <div className="px-4 py-3 2xl:py-6 ">
              <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
                <div className="ml-8 mt-2 inline-flex">
                  <h3 className="text-base 2xl:text-lg leading-6 font-medium text-gray-900 hover:text-blue-500">
                  <Link
                      to={
                        this.props.tournoi.circuit.name === "National"
                          ? "/competitionTennis/nationaldetails/" +
                            this.props.tournoi.id +
                            "/a/b"
                          : "/competitionTennis/internationaldetails/" +
                            this.props.tournoi.id +
                            "/a/b"
                      }
                    >
                      {this.props.tournoi.name}
                    </Link>
                  </h3>
                  <div className="text-gray-500 text-sm 2xl:text-base ml-10">
                    {this.props.tournoi.circuit.name +
                      " " +
                      this.props.tournoi.classification +
                      " - " +
                      this.props.tournoi.ground.name +
                      " " +
                      this.props.tournoi.conditions.name}
                  </div>
                </div>
                <div className="mr-8 mt-0 flex-shrink-0 text-sm 2xl:text-base">
                  {moment(this.props.tournoi.startDate).format("D MMM YYYY") +
                    " - " +
                    moment(this.props.tournoi.endDate).format("D MMM YYYY")}
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          {/* Body */}
          <div className="px-1 2xl:py-3 py-2 2xl:px-3 grid grid-cols-3">
            {this.props.tournoi.matches.map((match, key) => (
              <CardTennisMatch
                match={match}
                player={this.props.player}
                key={key}
              />
            ))}
          </div>
        </div>
      </>
    );
  }
}

export default TennisTournoisCard;
