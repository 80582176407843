import React from "react";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import Page from "../components/page/Page";
import {
  loadSchools,
  loadCurriculums,
  loadCurriculumsPursuedByPlayers,
} from "../utils/indexedDB/scholarship/handleScholarship";
import Loading from "../components/loaders/Loading";
import TableSchools from "../tables/TableSchools";
import TableCurriculums from "../tables/TableCurriculums";
import { loadPlayers } from "../utils/indexedDB/users/handleUser";
import TableCurriculumsPursued from "../tables/TableCurriculumsPursued";
import { loadContactsByPlayers, loadLicensesByPlayers, loadRecordsByPlayers } from "../utils/indexedDB/administration/handleAdministration";
import TableLicenses from "../tables/TableLicenses";
import { loadTennisClubs } from "../utils/indexedDB/core/handleCore";
import SubTab from "../components/tab/SubTab";
import TableRecords from "../tables/TableRecords";
import TableContacts from "../tables/TableContacts";
import getUserProfile from "../utils/auth/getUserProfile";
import TableTennisLicenses from "../tables/TableTennisLicenses";
import GeneratedForm from "../utils/forms/generateForm";
import { getFederalSupportBySeason, loadFederalSupportByPlayers } from "../utils/indexedDB/management/handleManagement";
import { getCurrentSeason, getSeasonById, getSeasons, loadSeasons } from "../utils/indexedDB/events/handleEvents";
import ButtonLight from "../components/buttons/ButtonLight";
import TableFederalSupport from "../tables/TableFederalSupport";
class AdministrationLibrary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, scholarshipView: "curriculumsPursued",administrationView: "records" };
    this.handleCurriculumns = this.handleCurriculumns.bind(this);
    this.handleCurriculumnsPursued = this.handleCurriculumnsPursued.bind(this);
    this.handleSchools = this.handleSchools.bind(this);
    this.handleContacts = this.handleContacts.bind(this);
    this.handleRecords = this.handleRecords.bind(this);

  }

  componentDidMount() {
    getUserProfile().then((profile) => {
      this.setState({ sport: profile.sport, role: profile.role });
      this.handleRecords();
      this.handleContacts();
      this.handleCurriculumns();
      this.handleSchools();
      this.handleCurriculumnsPursued();
      this.handleLicenses();
      this.handleFederalSupport();
    });
  }

  handleFederalSupport() {
    getSeasons().then(seasons => {
      if (seasons && seasons.length > 0) {
        var selectedSeason = getCurrentSeason(seasons)
        this.setState({ seasons, selectedSeason })
        getFederalSupportBySeason(selectedSeason.id).then(playersFS => {
          if (playersFS)
            this.setState({ playersFS })
        })
      }
    })

    if (navigator.onLine) {
      loadSeasons().then(seasons => {
        var selectedSeason = null
        if (seasons && seasons.length > 0) {
          selectedSeason = getCurrentSeason(seasons)
          this.setState({ seasons, selectedSeason })
        }
        loadPlayers().then((players) => {
          if (players) {

            if (selectedSeason)
            {
              var playersList = [];
              players.forEach((player) => {
                playersList.push(player.id);
              });
              loadFederalSupportByPlayers(playersList, players, selectedSeason.id).then(playersFS => {
                if (playersFS) {
                  playersFS.forEach(playerFS => {
                    playerFS.player = players.find(o => o.id === playerFS.playerId);
                  })
                  playersFS = playersFS.sort((a, b) =>
                    a.displayName === b.displayName ? 0 : a.displayName > b.displayName ? 1 : -1
                  );
                }
                this.setState({ playersFS });
              });
            }
          }
        })
      });
    }
  }

  handleRecords() {
    if (navigator.onLine) {
      loadPlayers().then((players) => {
        if (players) {
          var playersList = [];
          players.forEach((player) => {
              playersList.push(player.id);
          });
          loadRecordsByPlayers(playersList).then(records => {
            players.forEach(player => {
              if (records) {
                player.record = records.find(o => o.playerId === player.id);
              }
            });
            this.setState({players});
          });
        }
      });
    }
  }

  handleContacts() {
    if (navigator.onLine) {
      loadPlayers().then((players) => {
        if (players) {
          var playersList = [];
          players.forEach((player) => {
              playersList.push(player.id);
          });
          loadContactsByPlayers(playersList).then(contacts => {
            if (contacts)
            {
              contacts.forEach(contact => {
                contact.player = players.find(o => o.id === contact.playerId);
              })
            }
            this.setState({ contacts });
          });
        }
      });
    }
  }

  handleCurriculumnsPursued() {
    if (navigator.onLine) {
      loadPlayers().then((players) => {
        if (players) {
          var playersList = [];
          players.forEach((player) => {
              playersList.push(player.id);
          });
          loadCurriculumsPursuedByPlayers(playersList).then(curriculumsPursued => {
            if (curriculumsPursued)
            {
              curriculumsPursued.forEach(curriculumPursued => {
                curriculumPursued.player = players.find(o => o.id === curriculumPursued.playerId);
              })
            }
            this.setState({ curriculumsPursued });
          });
        }
      });
    }
  }

  handleLicenses() {
    if (navigator.onLine) {
      var sport = getUserProfile().then((profile) => { 
        this.setState({sport: profile.sport});
        profile.sport === "Tennis" ?
      loadTennisClubs().then((clubs) => {
        loadPlayers().then((players) => {
          if (players) {
            var playersList = [];
            players.forEach((player) => {
                playersList.push(player.id);
            });
            loadLicensesByPlayers(playersList).then(licenses => {
              if (licenses)
              {
                licenses.forEach(license => {
                  license.player = players.find(o => o.id === license.playerId);
                  license.club = clubs.find(o => o.id === license.tennisClubId);
                })
              }
              this.setState({ licenses });
            });
          }
        });
        return sport; }) : 
        loadPlayers().then((players) => {
          if (players) {
            var playersList = [];
            players.forEach((player) => {
                playersList.push(player.id);
            });
            loadLicensesByPlayers(playersList).then(licenses => {
              if (licenses)
              {
                licenses.forEach(license => {
                  license.player = players.find(o => o.id === license.playerId);
                })
              }
              this.setState({ licenses });
            });
          
          }
        });
    });
    }
  }


  handleCurriculumns() {
    if (navigator.onLine) {
      loadCurriculums().then((curriculums) => {
        if (curriculums) {
          this.setState({ curriculums, loading: false });
        }
      });
    }
  }

  handleSchools() {
    if (navigator.onLine) {
      loadSchools().then((schools) => {
        if (schools) {
          this.setState({ schools, loading: false });
        }
      });
    }
  }

  render() {
    const { t } = this.props;
    return (
      <Page
        tab={[
          {
            title: t("Administration"),
            data: this.state.players,
            visible: true,
            component: (data) => {
              return this.state.loading ? (
                <div style={{ height: "80vh", width: "100%" }}>
                  <Loading />
                </div>
              ) : (
                <>
                  <SubTab key="administration" tab={[{key: "records", label:t("Personal records")},{key: "contacts", label:t("Contacts")}]} selectedTab={this.state.administrationView} onChange={(key) => this.setState({administrationView: key})} />
                  {this.state.administrationView === "records" ?
                    <TableRecords players={this.state.players}  callback={() => this.props.handleRecords} /> : ""}
                  {this.state.administrationView === "contacts" ?
                    <TableContacts contacts={this.state.contacts} buttons={[
                      <GeneratedForm
                        key="createContact"
                        component={(show, text) => (
                          <ButtonLight
                            onClick={show}
                            disabled={!navigator.onLine}
                          >
                            {text}
                          </ButtonLight>
                        )}
                        callback={(res) =>
                          this.handleContacts()
                        }
                        formName="createContact"
                        method="POST"
                      />,
                    ]}  callback={() => this.props.handleContacts} /> : ""}
                </>
              );
            },
          },
          this.state.sport === "Tennis" && 
          {
            title: t("Licenses"),
            data: this.state.licenses,
            visible: true,
            component: (data) => {
              return this.state.loading ? (
                <div style={{ height: "80vh", width: "100%" }}>
                  <Loading />
                </div>
              ) : (
                this.state.sport && this.state.sport !== "Tennis"
                ?
                <TableLicenses licenses={data} />
                :
                (this.state.sport ?
                  <TableTennisLicenses licenses={data} /> : "")
              );
            },
          },
          {
            title: t("Scholarship"),
            visible: true,
            data: this.state.curriculumsPursued,
            component: (data) => {
              return this.state.loading ? (
                <div style={{ height: "80vh", width: "100%" }}>
                  <Loading />
                </div>
              ) : (
                <>
                  <SubTab key="scholarship" tab={[{key: "curriculumsPursued", label:t("Curriculums pursued")},{key: "schools", label:t("Schools")},{key: "curriculums", label:t("Curriculums")}]} selectedTab={this.state.scholarshipView} onChange={(key) => this.setState({scholarshipView: key})} />
                  {this.state.scholarshipView === "curriculums" ?
                    <TableCurriculums curriculums={this.state.curriculums} 
                    buttons={[
                      <GeneratedForm
                        key="createCurriculum"
                        component={(show, text) => (
                          <ButtonLight
                            onClick={show}
                            disabled={!navigator.onLine}
                          >
                            {text}
                          </ButtonLight>
                        )}
                        callback={(res) =>
                          loadCurriculums().then((curriculums) => {
                            if (curriculums) {
                              this.setState({ curriculums, loading: false });
                            }
                          })
                        }
                        formName="createCurriculum"
                        method="POST"
                      />,
                    ]} callback={() => loadCurriculums().then((curriculums) => {
                      if (curriculums) {
                        this.setState({ curriculums, loading: false });
                      }
                    })} /> : ""}
                  {this.state.scholarshipView === "curriculumsPursued" ?
                    <TableCurriculumsPursued curriculumsPursued={this.state.curriculumsPursued}
                    buttons={[
                      <GeneratedForm
                        key="createCurriculumPursued"
                        component={(show, text) => (
                          <ButtonLight
                            onClick={show}
                            disabled={!navigator.onLine}
                          >
                            <i className="flaticon2-plus"></i> {text}
                          </ButtonLight>
                        )}
                        callback={(res) => this.handleCurriculumnsPursued()}
                        formName="createCurriculumPursued"
                      />
                      ,
                    ]}
                    callback={() => this.handleCurriculumnsPursued()}
                    /> : ""}
                  {this.state.scholarshipView === "schools" ?
                    <TableSchools schools={this.state.schools}
                    buttons={[
                      <GeneratedForm
                        key="createSchool"
                        component={(show, text) => (
                          <ButtonLight
                            onClick={show}
                            disabled={!navigator.onLine}
                          >
                            <i className="flaticon2-plus"></i> {text}
                          </ButtonLight>
                        )}
                        callback={(res) => this.handleSchools()}
                        formName="createSchool"
                      />                      
                    ]}
                    callback={() => this.handleSchools()}
                    /> : ""}
                </>
              );
            },
          },
          {
            title: t("Suivi fédéral"),
            visible: this.state.sport === "Tennis",
            data: this.state.playersFS,
            component: (data) => {
              return (data && this.state.seasons && this.state.selectedSeason ?
                <>
                  <TableFederalSupport
                    players={data}
                    selectedSeason={this.state.selectedSeason}
                    seasons={this.state.seasons}
                    refresh={() => {
                      var playersList = [];
                      this.state.players.forEach((player) => {
                        playersList.push(player.id);
                      });
                      loadFederalSupportByPlayers(playersList, this.state.players, this.state.selectedSeason).then(playersFS => {
                        if (playersFS) {
                          playersFS.forEach(playerFS => {
                            playerFS.player = this.state.players.find(o => o.id === playerFS.playerId);
                          })
                          playersFS = playersFS.sort((a, b) =>
                            a.displayName === b.displayName ? 0 : a.displayName > b.displayName ? 1 : -1
                          );
                        }
                        this.setState({ playersFS });
                      });
                    }}
                    role={this.state.role}
                    changeSeason={(selectedSeasonId) => {
                      getSeasonById(selectedSeasonId).then((sSeason) => {
                        getFederalSupportBySeason(selectedSeasonId).then(playersFS => {
                          if (playersFS)
                            this.setState({ playersFS, selectedSeason: sSeason })
                        })
                        if (navigator.onLine) {
                          var playersList = [];
                            this.state.players.forEach((player) => {
                              playersList.push(player.id);
                            });
                            loadFederalSupportByPlayers(playersList, this.state.players, selectedSeasonId).then(playersFS => {
                              if (playersFS) {
                                playersFS.forEach(playerFS => {
                                  playerFS.player = this.state.players.find(o => o.id === playerFS.playerId);
                                })
                                playersFS = playersFS.sort((a, b) =>
                                  a.displayName === b.displayName ? 0 : a.displayName > b.displayName ? 1 : -1
                                );
                              }
                              this.setState({ playersFS });
                            });
                        }
                      });
                    }}
                    buttons={navigator.onLine && this.state.role === "Manager" ? [
                      <GeneratedForm
                        component={(show, text) => (
                          <ButtonLight
                            onClick={show}
                            disabled={!navigator.onLine && this.state.role !== "Manager"}
                          >
                            <i className="flaticon2-plus"></i> {text}
                          </ButtonLight>
                        )}
                        callback={() => {
                          var playersList = [];
                          this.state.players.forEach((player) => {
                            playersList.push(player.id);
                          });
                          loadFederalSupportByPlayers(playersList, this.state.players, this.state.selectedSeason).then(playersFS => {
                            if (playersFS) {
                              playersFS.forEach(playerFS => {
                                playerFS.player = this.state.players.find(o => o.id === playerFS.playerId);
                              })
                              playersFS = playersFS.sort((a, b) =>
                                a.displayName === b.displayName ? 0 : a.displayName > b.displayName ? 1 : -1
                              );
                            }
                            this.setState({ playersFS });
                          });
                        }}
                        formName="createFederalSupport"
                        method="POST"
                        formData={{
                          seasonId: this.state.selectedSeason.id,
                          comments: null,
                          federalSupportProgrammeId: null,
                          federalSupportStatusId: null,
                          trainingCenterId: null,
                        }}
                      />] : null
                    }
                  />
                </>
                : (
                  ""
                )
              );
            },
          }
        ]}
      />
    );
  }
}

export default withTranslation()(withRouter(AdministrationLibrary));
