import React from "react";

class List extends React.Component {
  render() {
    const data = this.props.data; // Value of the item (mandatory)
    return (
      <dl className="sm:divide-y sm:divide-gray-200 first:border-0 px-3">
        {data.map((item, key) => (
          <div className="py-3 2xl:py-6 sm:grid sm:grid-cols-3 sm:gap-4" key={key}>
            <dt className="text-xs 2xl:text-sm font-medium text-gray-800">{item.key}</dt>
            <dd className="sm:mt-0 sm:col-span-2 text-right mr-3">
              <span className="text-green-800 text-xs 2xl:text-sm font-medium rounded-full px-1 py-1 mt-2">
                {item.value}
              </span>
            </dd>
          </div>
        ))}
      </dl>
    );
  }
}

export default List;
