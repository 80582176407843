import React from "react";
import { withTranslation } from "react-i18next";

class FiltersSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  render() {
    return (
        this.state.visible ?
            <div className="flex">
                
                {this.props.search ? this.props.search : ""}
                <button onClick={() => this.setState({visible: !this.state.visible})} className="w-4 2xl:w-5 mr-3" style={{color: "#4B5563"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" aria-labelledby="title"
                        aria-describedby="desc" role="img" xlink="http://www.w3.org/1999/xlink">
                        <path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10" strokeWidth="2" d="M41.999 20.002l-22 22m22 0L20 20" strokeLinejoin="round" strokeLinecap="round"></path>
                    </svg>
                </button>
            </div>
            : 
            <div className="flex groupTourStep2">
                <button onClick={() => this.setState({visible: !this.state.visible})}  className="w-4 2xl:w-5 text-gray-600" style={{color: "#4B5563"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" aria-labelledby="title" aria-describedby="desc" role="img" xlink="http://www.w3.org/1999/xlink">
                        <path data-name="layer1" d="M61.122 56.88L41.54 37.284l-.037.037a22.01 22.01 0 1 0-4.173 4.175l-.031.031L56.878 61.12a3 3 0 0 0 4.244-4.242zM24 40a16 16 0 1 1 16-16 16.002 16.002 0 0 1-16 16z" fill="#202020"></path>
                    </svg>
                </button>
            </div>
    );
  }
}

export default withTranslation()(FiltersSearch);
