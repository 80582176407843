import React from "react";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import Page from "../components/page/Page";
import { getPlayers, loadPlayers, loadStaffs } from "../utils/indexedDB/users/handleUser";
import {
  loadNationalCompetition,
  getNationalCompetition,
  loadInternationalCompetition,
  getInternationalCompetition,
  loadResultsByPlayers,
  // loadMatchesByPlayers,
} from "../utils/indexedDB/Tennis-Competition/handleCompetitionTennis";
import {
  loadTennisRegions,
  getTennisRegions,
  getTennisClubs,
  loadTennisClubs,
} from "../utils/indexedDB/core/handleCore";
import moment from "moment";
import { Link } from "react-router-dom";
import Loading from "../components/loaders/Loading";
import TableTournamentsINT from "../tables/TableTournamentsINT";
import SubTab from "../components/tab/SubTab";
import TableTournamentsNAT from "../tables/TableTournamentsNAT";
import TableTournamentsResults from "../tables/TableTournamentsResults";

const internationalRankingData = require("../utils/internationRankindData")
  .default;

class PageCompetition extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      yearInt: parseInt(moment().format("YYYY")),
      monthInt: parseInt(moment().format("M")),
      yearNat: parseInt(moment().format("YYYY")),
      monthNat: parseInt(moment().format("M")),
      tournamentView: "international"
    };
    this.handleInternationalTournaments = this.handleInternationalTournaments.bind(this);
    this.handleNationalTournaments = this.handleNationalTournaments.bind(this);
    this.getUsersPhotoFormat = this.getUsersPhotoFormat.bind(this);
  }

  componentDidMount() {
    loadStaffs(); // Load for later
    this.handlePlayers();
    this.handleLigue();
    this.handleNationalTournaments(this.state.monthNat, this.state.yearNat);
    this.handleInternationalTournaments(this.state.monthInt, this.state.yearInt);
  }

  handlePlayers() {
    getPlayers().then((players) =>
      players && players.length
        ? this.setState({ players, loading: false })
        : null
    );
    if (navigator.onLine) {
      loadPlayers().then((players) => {
        if (players) {
          this.setState({ players, loading: false });
          var playersIds = [];
          players.map(player => playersIds.push(player.id));
          loadResultsByPlayers(playersIds).then(results =>{ 
            results.map(result => result.player = players.find(o => o.id === result.playerId));
            this.setState({results})
        
        });
        }
      });
    }
  }

  handleLigue() {
    getTennisRegions().then((ligue) => {
      this.setState({ ligue });
    });

    if (navigator.onLine) {
      loadTennisRegions().then((ligue) => {
        this.setState({ ligue });
      });
    }
  }

  handleInternationalTournaments(month, year) {
      getInternationalCompetition(month, year).then((tournaments) => {
        if (tournaments && tournaments.length > 0) {
          tournaments = tournaments.map((t) => {
            t.tournamentTypeId = "International";
            return t;
          });
        }
        if (tournaments && tournaments.length) {
          this.setState({ internationalTournaments : tournaments, loading: false });
        }
        return tournaments;
      })
    if (navigator.onLine) {
        loadInternationalCompetition(month, year).then((tournaments) => {
          if (tournaments && tournaments.length > 0) {
            tournaments = tournaments.map((t) => {
              t.tournamentTypeId = "International";
              return t;
            });
          }
          if (tournaments && tournaments.length) {
            this.setState({ internationalTournaments : tournaments, loading: false });
          }
          return tournaments;
        })
    }
  }

  handleNationalTournaments(month, year) {
    getTennisClubs().then(clubs =>   
    getNationalCompetition(month, year).then((tournaments) => {
        if (tournaments && tournaments.length > 0) {
          tournaments = tournaments.map((t) => {
            t.tournamentTypeId = "National";
            t.localisation = t.city;
            if (clubs && clubs.find(o => o.id === t.clubId))
            {
              t.club = clubs.find(o => o.id === t.clubId);
            }
            return t;
          });
        }
        if (tournaments && tournaments.length) {
          this.setState({ nationalTournaments: tournaments, loading: false });
        }
        return tournaments;
      }));
    if (navigator.onLine) {
      loadTennisClubs().then(clubs => 
        loadNationalCompetition(month, year).then((tournaments) => {
          if (tournaments && tournaments.length > 0) {
            tournaments = tournaments.map((t) => {
              t.tournamentTypeId = "National";
              t.localisation = t.city;
              if (clubs && clubs.find(o => o.id === t.clubId))
              {
                t.club = clubs.find(o => o.id === t.clubId);
              }
              return t;
            });
          }
          if (tournaments && tournaments.length) {
            this.setState({ nationalTournaments: tournaments, loading: false });
          }
          return tournaments;
        }));
    }
  }

  updateTournaments(month, year) {
    this.setState({ loading: true });
    if (month === 0) {
      month = 12;
      year -= 1;
    } else if (month === 13) {
      month = 1;
      year += 1;
    }

    this.setState({ monthInt: month, yearInt: year });
    this.setState({ monthNat: month, yearNat: year });
    this.handleNationalTournaments(month, year);
    this.handleInternationalTournaments(month, year);
  }

  handleTournaments(month, year) {
    this.handleNationalTournaments(month, year);
    this.handleInternationalTournaments(month, year);
  }

  getUsersPhotoFormat(engagements) {
    var users = [];
    engagements.forEach((p) => {
      if (
        this.state.players &&
        this.state.players.find((x) => x.id === p.playerId)
      ) {
        users.push(this.state.players.find((x) => x.id === p.playerId));
      }
    });
    return users;
  }

  render() {
    const { t } = this.props;

    return (
      <Page
        tab={[
          {
            title: t("Tournaments"),
            visible: true,
            data: this.state.tournaments,
            actions: {
              addForm: [
                "createTennisTournamentNational",
                "createTennisTournamentInternational",
              ],
              method: "POST",
              formData: {
                classification: "",
              },
              refreshData: () => this.handleTournaments(this.state.monthInt, this.state.yearInt),
            },
            component: (data) => {
              return this.state.loading ? (
                <div style={{ height: "80vh", width: "100%" }}>
                  <Loading />
                </div>
              ) : (
                <>
                  {this.state.loading ? (
                    <div style={{ height: "80vh", width: "100%" }}>
                      <Loading />
                    </div>
                  ) : (
                    <>
                    <SubTab tab={[{key:"national", label: "National"}, {key: "international", label:"International"}]} selectedTab={this.state.tournamentView} onChange={(key) => this.setState({tournamentView: key})} />
                    {this.state.tournamentView === "international" ?
                    <TableTournamentsINT
                      tournaments={this.state.internationalTournaments}
                      date={moment(
                        this.state.monthInt + "-" + this.state.yearInt,
                        "MM-YYYY"
                      )}
                      onChange={(date) => {
                        var monthInt = moment(date, "YYYY-MM").format("MM");
                        var yearInt = moment(date, "YYYY-MM").format("YYYY");
                        this.updateTournaments(monthInt, yearInt);
                      }}
                      month={this.state.monthInt}
                      year={this.state.yearInt}
                      getUsersPhotoFormat={this.getUsersPhotoFormat}
                      refresh={() => this.handleInternationalTournaments(this.state.monthInt, this.state.yearInt)}
                    /> : ""}
                    {this.state.tournamentView === "national" ?
                    <TableTournamentsNAT
                      tournaments={this.state.nationalTournaments}
                      date={moment(
                        this.state.monthNat + "-" + this.state.yearNat,
                        "MM-YYYY"
                      )}
                      onChange={(date) => {
                        var monthNat = moment(date, "YYYY-MM").format("MM");
                        var yearNat = moment(date, "YYYY-MM").format("YYYY");
                        this.updateTournaments(monthNat, yearNat);
                      }}
                      month={this.state.monthNat}
                      year={this.state.yearNat}
                      getUsersPhotoFormat={this.getUsersPhotoFormat}
                      refresh={() => this.handleNationalTournaments(this.state.monthNat, this.state.yearNat)}
                    /> : ""}
                    </>
                  )}
                </>
              );
            },
          },
          {
            title: t("Results"),
            visible: true,
            data: this.state.results,
            component: (data) => {
              return data ? (
                <>
                  <TableTournamentsResults results={data} />
                </>
              ):null}
          },
          {
            title: t("Rankings"),
            visible: true,
            data: internationalRankingData,
            component: (data) => {
              return data ? (
                <>
                  <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-2 px-4 py-4 2xl:px-6 2xl:py-6">
                    {data.map((ranking, key) => (
                      <li className="col-span-1 bg-white rounded-lg border px-3 py-1 2xl:py-2 hover:bg-gray-50" key={key}>
                        <Link
                          to={"/competitionTennis/ranking" + ranking.extension}
                        >
                          <div className="w-full flex items-center justify-between p-3 2xl:p-6 space-x-2 2xl:space-x-6">
                            <div className="flex-1 truncate">
                              <div className="flex items-center space-x-3">
                                <h3 className="text-gray-900 text-base 2xl:text-lg font-medium truncate">
                                  Classement
                                </h3>
                                <span
                                  className={
                                    ranking.gender === "H"
                                      ? "flex-shrink-0 inline-block px-2 py-0.5 text-blue-800 text-base 2xl:text-lg font-medium bg-blue-100 rounded-full"
                                      : "flex-shrink-0 inline-block px-2 py-0.5 text-pink-800 text-base 2xl:text-lg font-medium bg-pink-100 rounded-full"
                                  }
                                >
                                  {ranking.tournament}
                                </span>
                              </div>
                            </div>
                            <img
                              className="w-12 2xl:w-20 h-12 2xl:h-20 flex-shrink-0"
                              src={ranking.logo}
                              alt=""
                            />
                          </div>
                        </Link>
                      </li>
                    ))}
                    <li className="col-span-1 bg-white rounded-lg border px-3 py-2 hover:bg-gray-50">
                      <Link to={"/competitionTennis/nationalRankingH"}>
                        <div className="w-full flex items-center justify-between p-3 2xl:p-6 space-x-2 2xl:space-x-6">
                          <div className="flex-1 truncate">
                            <div className="flex items-center space-x-3">
                              <h3 className="text-gray-900 text-base 2xl:text-lg font-medium truncate">
                                Classement
                              </h3>
                              <span className="flex-shrink-0 inline-block px-2 py-0.5 text-blue-800 text-base 2xl:text-lg font-medium bg-blue-100 rounded-full">
                                {t("National ranking male")}
                              </span>
                            </div>
                          </div>
                          <img
                            className="h-12 2xl:h-20 flex-shrink-0"
                            src="https://exaduosport.blob.core.windows.net/clubs/FFT.png"
                            alt=""
                          />
                        </div>
                      </Link>
                    </li>
                    <li className="col-span-1 bg-white rounded-lg border px-3 py-2 hover:bg-gray-50">
                      <Link to={"/competitionTennis/nationalRankingF"}>
                        <div className="w-full flex items-center justify-between p-3 2xl:p-6 space-x-2 2xl:space-x-6">
                          <div className="flex-1 truncate">
                            <div className="flex items-center space-x-3">
                              <h3 className="text-gray-900 text-base 2xl:text-lg font-medium truncate">
                                Classement
                              </h3>
                              <span className="flex-shrink-0 inline-block px-2 py-0.5 text-pink-800 text-base 2xl:text-lg font-medium bg-pink-100 rounded-full">
                                {t("National ranking female")}
                              </span>
                            </div>
                          </div>
                          <img
                            className="h-12 2xl:h-20 flex-shrink-0"
                            src="https://exaduosport.blob.core.windows.net/clubs/FFT.png"
                            alt=""
                          />
                        </div>
                      </Link>
                    </li>
                  </ul>
                </>
              ) : null;
            },
          },
        ]}
      />
    );
  }
}

export default withTranslation()(withRouter(PageCompetition));
