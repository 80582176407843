import React from "react"
import Table from "../../components/table/TableSimple";
import { withRouter } from "react-router";
import { withTranslation } from 'react-i18next';
import Page from "../../components/page/Page";
import Tab from "../../components/tab/Tab";
import * as Form from "../../components/forms/index";
import Drawer from "../../components/drawer/Drawer";
import Modal from "../../components/modal/Modal";
import Button from "../../components/buttons/Button";
import GeneratedForm from "../../utils/forms/generateForm";
import Stepper from "../../components/stepper/Stepper";
import Card from "../../components/card/Card";
import { getPlayers } from "../../utils/indexedDB/users/handleUser";
import DropdownTableSelectorUsers from "../../components/table/DropdownTableSelectorUsers";
import Label from "../../components/typography/Label";
import ActionButton from "../../components/buttons/ActionButton";
import { Link } from "react-router-dom";
import IconRound32x32 from "../../components/icons/IconRound32x32";
import moment from "moment";
import EventForm from "../../utils/forms/events/EventForm";


// import data for example
var dataTable = require('./dataForTable.json');
var dataSelect = [{
    key: "EU",
    label: "Europe"
}, {
    key: "NA",
    label: "North America"
}, {
    key: "SKR",
    label: "South Korea"
}];
const eventFormData = {
    categoryId: "Vitesse",
    cycleId: null,
    description: "",
    endDate: "2010-10-10T07:30:00",
    groupId: null,
    id: "5fd50ee6-70af-48a5-b87e-16457bfc19da",
    location: "",
    name: "Entrainement de test",
    seasonId: null,
    specificInfos: null,
    startDate: "2010-10-10T06:30:00",
    typeId: "Entrainement",
    weekId: null
}

class Demo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selector: null,
            input: null,
            textArea: null,
            tabExample: null,
            datePicker: null,
            checkbox: null,
            selectorMultiple: null,
            slider: null,
            fileUpload: null,
            selectedUsers: []
        }
    }

    componentDidMount() {
        // function get from indexedDB
        getPlayers().then(players => this.setState({ players }))

        if (navigator.onLine) {
            //function get from database
        }
    }

    render() {
        const { t } = this.props;

        const tabs = [
            {
                key: "tab1",
                label: "tab1",
                content: "item tab 1"
            },
            {
                key: "tab2",
                label: "tab2",
                content: "item tab 2"
            },
            {
                key: "tab3",
                label: "tab3",
                content: "item tab 3"
            }
        ];

        const columns = [
            {
                key: "photo",
                width: 60,
                pinned: "left",
                format: (row) => <IconRound32x32 photo={row.photo} />,
            },
            {
                key: "displayName",
                label: t("Player"),
                pinned: "left",
                id: "displayName",
                format: (row) => {
                    return <>
                        <Link
                            style={{ textDecoration: "underline" }}
                            to={"/group/player/" + row.id}
                        >
                            {row.displayName}
                        </Link>
                    </>
                },
            },
            {
                key: "nationalityId",
                label: t("Nationality"),
                filter: {
                    name: "SelectColumnFilter",
                    type: "includes"
                }
            },
            {
                key: "dob",
                label: t("Date of birth"),
                format: (row) => <>{moment(row.dob).calendar()}</>
            },
            {
                key: "mobile",
                id:"mobile",
                label: t("Mobile")
            },
            {
                key: "email",
                id:"email",
                label: t("Email")
            },
            {
                key: "status",
                label: t("Availability"),
                format: (row) =>
                    row.status && row.status !== [] && row.status.length > 0 ? (
                        <Label type="danger" text={t("Unavailable")} />
                    ) : (
                            <Label type="success" text={t("Available")} />
                        ),
                filter: {
                    name: "SelectColumnFilter",
                    type: "includes"
                }
            },
            {
                key: "action",
                pinned: "right",
                width: 100,
                format: (row) => (
                    <ActionButton
                        key={row.id}
                        updateForm="updatePlayer"
                        deleteForm="deletePlayer"
                        formData={row}
                        refreshData={() =>
                            console.log("refresh data")
                        }
                    />
                ),
            },
        ];

        return (<Page pageTitle="Demo" tab={[
            {
                title: "Tables",
                data: dataTable,
                component: (data) => {
                    return <div className="card">
                        <Table data={data} columns={columns} />
                    </div>
                }
            },
            {
                title: "Form",
                data: null,
                component: (data) => {
                    return (<div>
                        <div>
                            Selector :
                            <Form.Select key="selector" options={dataSelect} onChange={(value) => this.setState({ selector: value })} />
                            {" "}value : {this.state.selector}
                        </div>
                        <div>
                            Input :
                            <Form.Input onChange={(value) => this.setState({ input: value })} key="input" />
                            {" "}value : {this.state.input}
                        </div>
                        <div>
                            FileUpload :
                            <Form.FileUpload id="fileUpload" onChange={(value) => value && value.name ? this.setState({ fileUpload: value.name }) : null} key="fileUpload" />
                            {" "}value : {this.state.fileUpload}
                        </div>
                        <div>
                            Slider :
                            <Form.Slider id="slider" min={0} max={10} step={0.5} onChange={(value) => this.setState({ slider: value })} key="slider" />
                            {" "}value : {this.state.slider}
                        </div>
                        <div>
                            TextArea :
                            <Form.TextArea row="5" onChange={(value) => this.setState({ textArea: value })} key="textArea" />
                            {" "}value : {this.state.textArea}
                        </div>
                        <div>
                            DatePicker :
                            <Form.DatePicker onChange={(value) => this.setState({ datePicker: value })} key="datePicker" />
                            {" "}value : {this.state.datePicker}
                        </div>
                        <div>
                            DateTimePicker :
                            <Form.DateTimePicker onChange={(value) => this.setState({ datePicker: value })} key="datePicker" />
                            {" "}value : {this.state.datePicker}

                            MonthPicker :
                            <Form.MonthPicker onChange={(value) => this.setState({ monthPicker: value })} key="monthPicker" />
                            {" "}value : {this.state.monthPicker}
                        </div>
                        <div>
                            Checkbox :
                            <Form.Checkbox onChange={(value) => this.setState({ checkbox: value })} value={this.state.checkbox} key="checkbox" />
                            {" "}value : {this.state.checkbox ? "true" : "false"}
                        </div>
                        <div>
                            Switch :
                            <Form.Switch onChange={(value) => this.setState({ switch: value })} value={this.state.switch} key="switch" />
                            {" "}value : {this.state.switch ? "true" : "false"}
                        </div>
                        <div>
                            Selector multiple :
                            <Form.SelectMultiple key="selectormult" options={dataSelect} onChange={(value) => this.setState({ selectorMultiple: value })} />
                            {" "}value : {this.state.selectorMultiple ? this.state.selectorMultiple.map(s => s + ",") : ""}
                        </div>
                        <div>Forms example create :
                        <GeneratedForm
                                component={(show) => <Button onClick={show}>Forms example in Drawer</Button>}
                                callback={() => console.log("data submitted")}
                                formName="createStaff"
                            />
                        </div>
                        <div>Forms example :
                        <GeneratedForm
                                formData={{ id: "toto" }}
                                component={(show) => <Button onClick={show}>Forms example in Drawer</Button>}
                                callback={() => console.log("data submitted")}
                                formName="deletePlayer"
                            />
                        </div>
                        <div>Event Form POST without Object:
                            <EventForm method='POST'
                                // formData={{typeId:"Entrainement", name:"Test", id:"123" }} step={"invitation"}
                                callback={() => console.log("Event Form POST without Object")}
                                component={(show) => <Button onClick={show}>Forms event example in Modal</Button>}
                            />
                        </div>
                        <div>Event Form POST with Type:
                            <EventForm method='POST'
                                formData={{ typeId: "Entrainement" }}
                                callback={() => console.log("Event Form POST with Type")}
                                component={(show) => <Button onClick={show}>Forms event example in Modal</Button>}
                            />
                        </div>
                        <div>Event Form PATCH without Step:
                            <EventForm method='PATCH'
                                formData={eventFormData}
                                callback={() => console.log("Event Form PATCH without Step")}
                                component={(show) => <Button onClick={show}>Forms event example in Modal</Button>}
                            />
                        </div>
                        <div>Event Form PATCH with Step:
                            <EventForm method='PATCH'
                                formData={eventFormData} step="invitation"
                                callback={() => console.log("Event Form PATCH with Step")}
                                component={(show) => <Button onClick={show}>Forms event example in Modal</Button>}
                            />
                        </div>
                        <div>Event Form DELETE:
                            <EventForm method='DELETE'
                            callback={() => console.log("Event Form DELETE")}
                                formData={{ typeId: "Entrainement", name: "Test", id: "5fd50ee6-70af-48a5-b87e-16457bfc19da" }} step={"invitation"}
                                component={(show) => <Button onClick={show}>Forms event example in Modal</Button>}
                            />
                        </div>
                        <div> DropdownTableSelectorUsers : {this.state.selectedUsers.length}
                            <DropdownTableSelectorUsers label="Players" data={this.state.players} onChange={(list) => this.setState({ selectedUsers: list })} />
                        </div>
                    </div>);
                }
            },
            {
                title: "Navigation",
                data: null,
                component: (data) => {
                    return <div>
                        <div>Tabs :
                        <Tab tab={tabs} onChange={(index) => this.setState({ tabExample: index })} />
                            {this.state.tabExample ? tabs.find(x => x.key === this.state.tabExample).content : ""}
                        </div>
                        <div>Stepper :
                        <Stepper steps={tabs} selectedStep={tabs[1]} />
                        </div>
                        <div>Drawer :
                        <Drawer component={(show) => <Button onClick={show}>Drawer</Button>}>
                                This is a sample
                        </Drawer>
                        </div>
                        <div>Modal :
                        <Modal component={(show) => <Button onClick={show}>Modal</Button>}>
                                This is a sample
                        </Modal>
                        </div>
                        <div> Card :
                        <Card />
                        </div>
                    </div>
                }
            }
        ]} />)
    }
}

export default withTranslation()(withRouter(Demo));