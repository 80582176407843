import React from "react";
import { withTranslation } from "react-i18next";
import { CSVLink } from "react-csv";

class ExportToCSV extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }



  render() {
    const columns = this.props.columns;
    const jsonData = this.props.jsonData;

    if (columns && jsonData){
        var headers = [];
        columns.forEach(column => {
            headers.push({label: column.label, key: column.key});
        });

        var data = [];
        data = jsonData;
    }
    return (
        columns && jsonData ?
        <CSVLink data={data} headers={headers} separator={";"} className="w-4 2xl:w-4 pt-2.5 groupTourStep5" filename={this.props.fileName ? this.props.fileName : "Export.csv"}>
            <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" aria-labelledby="title" aria-describedby="desc" role="img" xlink="http://www.w3.org/1999/xlink">
                <path fill="#202020" d="M31.933 49.458l17.256-18.406a3 3 0 1 0-4.377-4.1L35 37.414V5a3 3 0 0 0-6 0v32.066l-9.75-11.05a3 3 0 1 0-4.5 3.969z"  data-name="layer2"></path>
                <path fill="#202020" d="M55 42a3 3 0 0 0-3 3v11H12V45a3 3 0 0 0-6 0v14a3 3 0 0 0 3 3h46a3 3 0 0 0 3-3V45a3 3 0 0 0-3-3z"  data-name="layer1"></path>
            </svg>
        </CSVLink> : ""
    );
  }
}

export default withTranslation()(ExportToCSV);
