import React from "react";
import ActionButton from "../components/buttons/ActionButton";
import { withTranslation } from "react-i18next";
import { SelectMultiple } from "../components/forms";
import TableSimple from "../components/table/TableSimple";
import FilterColumns from "../components/filters/FilterColumns";
import FiltersDropDowns from "../components/filters/FiltersDropDowns";
import FiltersSearch from "../components/filters/FiltersSearch";
import SearchInput from "../components/forms/input/SearchInput";
import IconRound32x32 from "../components/icons/IconRound32x32";
import { Link } from "react-router-dom";
import moment from "moment";
import FilterSeparator from "../components/filters/FilterSeparator";
import FiltersTitle from "../components/filters/FiltersTitle";
import FiltersBar from "../components/filters/FiltersBar";


class TablePeriodicReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actions: [],
      statuses: [],
      selectedStatuses: [],
      columns: [],
      searchText: "",
    };
  }

  componentDidMount() {
    this.prepareData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.prepareData();
    }
  }

  prepareData = () => {
    var statuses = [];
    var selectedStatuses = [];
    var players = this.props.players
    if (players) {
      players.map((player) => {
        if (statuses && !statuses.find((o) => o.key === player.playerId)) {
          statuses.push({
            key: player.playerId,
            label: player.displayName,
          });
          selectedStatuses.push(player.playerId);
        }
        return player;
      });
    }

    const columns = [
      {
        key: "name",
        visible: true,
        label: this.props.t("Name"),
        id: "name",
        width: 300,
        format: (row) => (
          <span className="flex justify-between">
            <span className="text-primary font-weight-bolder mb-0 text-xs 2xl:text-sm">
              <Link
                className="text-indigo-700 font-semibold"
                to={"/management/periodicReportDetails/" + row.id}
              >
                {row.name}
              </Link>
            </span>
            <ActionButton
              key={row.id}
              updateForm="updatePeriodicReport"
              deleteForm="deletePeriodicReport"
              formData={row}
              refreshData={
                this.props.refresh
              }
            />
          </span>

        ),
      },
      {
        key: "displayName",
        visible: true,
        pinned: "left",
        label: this.props.t("Player"),
        id: "displayName",
        width: 300,
        format: (row) => (
          <Link
            className="text-indigo-700 font-semibold text-xs 2xl:text-sm"
            to={"/group/player/" + row.playerId}
          >
            <IconRound32x32 photo={row.photo} />
            <span className="ml-5">
              {row.displayName}
            </span>
          </Link>
        ),
      },
      {
        key: "period",
        visible: true,
        label: this.props.t("Period"),
        id: "period",
        format: (row) => (
          <span className="font-weight-bold text-muted text-xs 2xl:text-sm">{row.period}</span>
        ),
      },
      {
        key: "date",
        visible: true,
        label: this.props.t("Date"),
        format: (row) => (
          <span className="font-weight-bold text-muted text-xs 2xl:text-sm">{moment(row.date).format("D MMM YYYY")}</span>
        ),
      },
    ];
    var selectedColumns = [];
    columns.forEach((column) => {
      if (column.visible) {
        selectedColumns.push(column.key);
      }
    });
    this.setState({
      columns,
      statuses,
      selectedColumns,
      selectedStatuses,
    });
  };

  render() {
    const { t } = this.props;
    var players = this.props.players;
    var columns = this.state.columns;
    var selectedColumns = this.state.selectedColumns;
    var selectedStatuses = this.state.selectedStatuses;
    var searchText = this.state.searchText;
    if (columns) {
      columns = columns.filter((o) =>
        selectedColumns.some((item) => item === o.key)
      );
    }
    if (players) {
      players = players.filter((o) =>
        selectedStatuses.some((item) => item === o.playerId)
      );
    }
    if (searchText.length > 2) {
      players = players.filter((o) =>
        o.name.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    return (
      <>
        <FiltersBar
          components={
            <>
              <FiltersTitle title={players
                ? players.length + " " + (players.length > 1 ? t("ReportsB").toLowerCase() : t("ReportB").toLowerCase())
                : ""}
              />
              <FilterSeparator />
              <FiltersSearch search={<SearchInput
                value={this.state.searchText}
                onChange={(searchText) => this.setState({ searchText })}

              />} />
              <FilterSeparator />
              <FiltersDropDowns selects={[
                <SelectMultiple
                  options={this.state.statuses}
                  keys={this.state.selectedStatuses}
                  className="mx-2"
                  onChange={(res) => this.setState({ selectedStatuses: res })}
                  fixedLabel={t("Status")}
                />
              ]}
              />
              <FilterSeparator />
              <FilterColumns
                columns={
                  <SelectMultiple
                    options={this.state.columns}
                    keys={this.state.selectedColumns}
                    className="mx-2"
                    onChange={(res) => this.setState({ selectedColumns: res })}
                    fixedLabel={t("Active columns")}
                  />
                }
              />
            </>
          }
          buttons={this.props.buttons}
        />
        <TableSimple columns={columns} data={players} />
      </>
    );
  }
}

export default withTranslation()(TablePeriodicReport);
