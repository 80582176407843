import React from "react";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import Page from "../../components/page/Page";
import { getEvent, loadEvent } from "../../utils/indexedDB/events/handleEvents";
import {
  loadMatch,
  getMatch,
  loadMatchStatistics,
  getMatchStatistics,
} from "../../utils/indexedDB/Tennis-Competition/handleCompetitionTennis";
import { loadPlayer, getPlayer } from "../../utils/indexedDB/users/handleUser";
import HeroTennisMatch from "../../components/heroes/HeroTennisMatch";
import Card from "../../components/card/Card";
import Button from "../../components/buttons/Button";
import GeneratedForm from "../../utils/forms/generateForm";
import EventForm from "../../utils/forms/events/EventForm";
import ButtonLight from "../../components/buttons/ButtonLight";

import VideoCard from "../../components/card/VideoCard";
import { getVideosByEvent, loadVideosByEvent } from "../../utils/indexedDB/medias/handleMedia";

class Match extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      match: null,
      player: null,
      event: null,
    };
  }
  componentDidMount() {
    var id = this.props.match.params.id;
    this.handleEvent(id);
    this.handleMatch(id);
    this.handleMatchStatistics(id);
    this.handleVideos(id);
  }

  handleEvent(id) {
    getEvent(id).then((event) => {
      if (event) {
        this.setState({ event });
      }
    });
    if (navigator.onLine) {
      loadEvent(id).then((event) => {
        if (event) {
          this.setState({ event });
        } else {
          this.props.history.push("/notFound");
        }
      });
    }
  }

  handleMatch(id) {
    getMatch(id).then((match) => {
      this.setState({ match: match });
      if (match)
        if (match.playerId) {
          getPlayer(match.playerId).then((player) => {
            if (player) {
              this.setState({ player, loading: false });
            }
          });
        }
    });
    if (navigator.onLine) {
      loadMatch(id).then((match) => {
        this.setState({ match });
        if (match)
          if (match.playerId)
            loadPlayer(match.playerId).then((player) => {
              if (player) {
                this.setState({ player, loading: false });
              }
            });
      });
    }
  }

  handleMatchStatistics(id) {
    getMatchStatistics(id).then((stats) => {
      if (stats.length > 0) {
        this.setState({ stats: stats[0] });
      }
    });

    if (navigator.onLine) {
      loadMatchStatistics(id).then((stats) => {
        if (stats.id) {
          this.setState({ stats });
        }
      });
    }
  }

  handleVideos(eventId) {
    this.setState({ getVideosFromAPI: false })
    getVideosByEvent(eventId).then(videos => {
      if (videos && videos.length && !this.state.getVideosFromAPI) {
        this.setState({ videos });
      }
    })

    if (navigator.onLine) {
      loadVideosByEvent(eventId).then(videos => {
        this.setState({ videos, getVideosFromAPI: true })
      })
    }
  }


  render() {
    const { t } = this.props;

    return (
      <Page
        pageTitle={t("Match")}
        backPage="/competitionTennis/Matches"
        actions={{
          other: [
            <EventForm
              key="maininfo-form"
              step="information"
              component={(show, text) => (
                <ButtonLight onClick={show}>{t("Edit")}</ButtonLight>
              )}
              method="PATCH"
              callback={() => {
                this.handleEvent(this.props.match.params.id);
                this.handleMatch(this.props.match.params.id);
              }}
              formData={this.state.event}
            />,
            <EventForm
              key="delete-form"
              title={t("Delete")}
              component={(show, text) => (
                <Button onClick={show} disabled={!navigator.onLine}>
                  {text}
                </Button>
              )}
              method="DELETE"
              callback={() => this.props.history.goBack()}
              formData={this.state.event}
            />,
          ],
        }}
        tab={[
          {
            title: t("Main information"),
            visible: true,
            data: this.state.event,
            component: (data) => {
              return (
                <>
                  {this.state.match && this.state.player ? (
                    <>
                      <Card>
                        <HeroTennisMatch
                          category={this.state.event ? this.state.event.categoryId : ""}
                          match={this.state.match}
                          player={this.state.player}
                        />
                      </Card>
                      <div className="flex"></div>

                      {this.state.stats ? (
                        <div className="flex flex-row justify-center w-full px-10 py-4">
                          <div className="border rounded-md shadow-xl pb-8 xl:w-2/3 sm:w-3/4 w-full">
                            <Card
                              title={t("Statistics")}
                              headerActions={
                                <GeneratedForm
                                  key="updateMatchTennisStatistics"
                                  component={(show, text) => (
                                    <Button
                                      onClick={show}
                                      disabled={!navigator.onLine}
                                    >
                                      {t("Edit")}
                                    </Button>
                                  )}
                                  callback={(res) =>
                                    loadMatchStatistics(
                                      this.state.id
                                    ).then((stats) => this.setState({ stats }))
                                  }
                                  formName="updateMatchTennisStatistics"
                                  formData={this.state.stats}
                                />
                              }
                              className="w-full"
                            >
                              <div className="table-responsive">
                                <table
                                  className="w-full"
                                  id="kt_advance_table_widget_1"
                                >
                                  <thead className="">
                                    <tr className="h-12">
                                      <th className="w-1/4 text-center">
                                        {this.state.player.lastName}
                                      </th>
                                      <th className="w-1/2"></th>
                                      <th className="w-1/4 text-center">
                                        {this.state.match.opponent
                                          ? this.state.match.opponent.lastName
                                          : ""}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr className="h-12">
                                      <td className="text-center">
                                        {this.state.stats.prct1stService ? this.state.stats.prct1stService + "%" : "-"}
                                      </td>
                                      <td className="text-center">
                                        <span className="text-indigo-800 font-semibold">
                                          %1ER SERVICE
                                        </span>
                                      </td>
                                      <td className="text-center">
                                        {this.state.stats.prct1stServiceAdv ? this.state.stats.prct1stServiceAdv + "%" : "-"}
                                      </td>
                                    </tr>
                                    <tr className="h-12">
                                      <td className="text-center">
                                        {this.state.stats.aces ? this.state.stats.aces : "-"}
                                      </td>
                                      <td className="text-center">
                                        <span className="text-indigo-800 font-semibold">
                                          ACES
                                        </span>
                                      </td>
                                      <td className="text-center">
                                        {this.state.stats.acesAdv ? this.state.stats.acesAdv : "-"}
                                      </td>
                                    </tr>
                                    <tr className="h-12">
                                      <td className="text-center">
                                        {this.state.stats.doubleFaults ? this.state.stats.doubleFaults : "-"}
                                      </td>
                                      <td className="text-center">
                                        <span className="text-indigo-800 font-semibold">
                                          DOUBLES FAUTES
                                        </span>
                                      </td>
                                      <td className="text-center">
                                        {this.state.stats.doubleFaultsAdv ? this.state.stats.doubleFaultsAdv : "-"}
                                      </td>
                                    </tr>
                                    <tr className="h-12">
                                      <td className="text-center">
                                        {this.state.stats.ptsWonOnService != null && this.state.stats.ptsLostOnService != null ? this.state.stats.ptsWonOnService + " / " + Number.parseFloat(this.state.stats.ptsWonOnService + this.state.stats.ptsLostOnService).toFixed(0) : "-"}
                                        {this.state.stats.prctPtsWonOnService != null ? " (" + this.state.stats.prctPtsWonOnService + "%)" : ""}
                                      </td>
                                      <td className="text-center">
                                        <span className="text-indigo-800 font-semibold">
                                          PTS GAGNES AU SERVICE
                                        </span>
                                      </td>
                                      <td className="text-center">
                                        {this.state.stats.ptsWonOnServiceAdv != null && this.state.stats.ptsLostOnServiceAdv != null ? this.state.stats.ptsWonOnServiceAdv + " / " + Number.parseFloat(this.state.stats.ptsWonOnServiceAdv + this.state.stats.ptsLostOnServiceAdv).toFixed(0) : "-"}
                                        {this.state.stats.prctPtsWonOnServiceAdv != null ? " (" + this.state.stats.prctPtsWonOnServiceAdv + "%)" : ""}
                                      </td>
                                    </tr>
                                    <tr className="h-12">
                                      <td className="text-center">
                                        {this.state.stats.ptsWonOn1stService != null && this.state.stats.prct1stService != null && this.state.stats.ptsWonOnService != null && this.state.stats.ptsLostOnService != null ?
                                          this.state.stats.ptsWonOn1stService + " / " + Number.parseFloat((this.state.stats.prct1stService *
                                            (this.state.stats.ptsWonOnService +
                                              this.state.stats
                                                .ptsLostOnService) / 100).toFixed(0)) : "-"
                                        }
                                        {this.state.stats.prctPtsWonOn1stService != null ? " (" + this.state.stats.prctPtsWonOn1stService + "%)" : ""}
                                      </td>
                                      <td className="text-center">
                                        <span className="text-indigo-800 font-semibold">
                                          PTS GAGNES SUR 1ER SERVICE
                                        </span>
                                      </td>
                                      <td className="text-center">
                                        {this.state.stats.ptsWonOn1stServiceAdv != null && this.state.stats.prct1stServiceAdv != null && this.state.stats.ptsWonOnServiceAdv != null && this.state.stats.ptsLostOnServiceAdv != null ?
                                          this.state.stats.ptsWonOn1stServiceAdv + " / " + Number.parseFloat((this.state.stats.prct1stServiceAdv *
                                            (this.state.stats.ptsWonOnServiceAdv +
                                              this.state.stats
                                                .ptsLostOnServiceAdv) / 100).toFixed(0)) : "-"
                                        }
                                        {this.state.stats.prctPtsWonOn1stServiceAdv != null ? " (" + this.state.stats.prctPtsWonOn1stServiceAdv + "%)" : ""}
                                      </td>
                                    </tr>
                                    <tr className="h-12">
                                      <td className="text-center">
                                        {this.state.stats.ptsWonOn2ndService && this.state.stats.ptsWonOnService && this.state.stats.ptsLostOnService ?
                                          this.state.stats.ptsWonOn2ndService + " / " + Number.parseFloat(((100 - this.state.stats.prct1stService) *
                                            (this.state.stats.ptsWonOnService +
                                              this.state.stats.ptsLostOnService)) / 100).toFixed(0) : "-"}
                                        {this.state.stats.prctPtsWonOn2ndService != null ? " (" + this.state.stats.prctPtsWonOn2ndService + "%)" : ""}
                                      </td>
                                      <td className="text-center">
                                        <span className="text-indigo-800 font-semibold">
                                          PTS GAGNES SUR 2EME SERVICE
                                        </span>
                                      </td>
                                      <td className="text-center">
                                        {this.state.stats.ptsWonOn2ndServiceAdv != null && this.state.stats.ptsWonOnServiceAdv != null && this.state.stats.ptsLostOnServiceAdv != null ?
                                          this.state.stats.ptsWonOn2ndServiceAdv + " / " + Number.parseFloat(((100 - this.state.stats.prct1stServiceAdv) *
                                            (this.state.stats.ptsWonOnServiceAdv +
                                              this.state.stats.ptsLostOnServiceAdv)) / 100).toFixed(0) : "-"}
                                        {this.state.stats.prctPtsWonOn2ndServiceAdv != null ? " (" + this.state.stats.prctPtsWonOn2ndServiceAdv + "%)" : ""}
                                      </td>
                                    </tr>
                                    <tr className="h-12">
                                      <td className="text-center">
                                        {this.state.stats.breakPtsWon != null && this.state.stats.breakPtsGained != null ? this.state.stats.breakPtsWon + " / " + this.state.stats.breakPtsGained : "-"}
                                        {this.state.stats.prctBreakPtsWon != null ? " (" + this.state.stats.prctBreakPtsWon + "%)" : ""}
                                      </td>
                                      <td className="text-center">
                                        <span className="text-indigo-800 font-semibold">
                                          BALLES DE BREAK CONVERTIES
                                        </span>
                                      </td>
                                      <td className="text-center">
                                        {this.state.stats.breakPtsWonAdv != null && this.state.stats.breakPtsGainedAdv != null ? this.state.stats.breakPtsWonAdv + " / " + this.state.stats.breakPtsGainedAdv : "-"}
                                        {this.state.stats.prctBreakPtsWonAdv != null ? " (" + this.state.stats.prctBreakPtsWonAdv + "%)" : ""}
                                      </td>
                                    </tr>
                                    <tr className="h-12">
                                      <td className="text-center">
                                        {this.state.stats.breakPtsSaved != null && this.state.stats.breakPtsConceded != null ? this.state.stats.breakPtsSaved + " / " + this.state.stats.breakPtsConceded : "-"}
                                        {this.state.stats.prctBreakPtsSaved != null ? " (" + this.state.stats.prctBreakPtsSaved + "%)" : ""}
                                      </td>
                                      <td className="text-center">
                                        <span className="text-indigo-800 font-semibold">
                                          BALLES DE BREAK SAUVEES
                                        </span>
                                      </td>
                                      <td className="text-center">
                                        {this.state.stats.breakPtsSavedAdv != null && this.state.stats.breakPtsConcededAdv != null ? this.state.stats.breakPtsSavedAdv + " / " + this.state.stats.breakPtsConcededAdv : "-"}
                                        {this.state.stats.prctBreakPtsSavedAdv != null ? " (" + this.state.stats.prctBreakPtsSavedAdv + "%)" : ""}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </Card>
                          </div>
                        </div>
                      ) : (
                          ""
                        )}
                    </>
                  ) : null}
                </>
              );
            },
          },
          {
            title: t("Videos"),
            visible: true,
            data: null,
            actions: this.state.event && this.state.player ? {
              key: "createVideo",
              title: t("Add video"),
              addForm: "createVideo",
              formData: { eventId: this.state.event.id, eventTypeId: t("Match"), playerId: this.state.player.id},
              callback: () => this.handleVideos(this.state.event.id)
            } : null,
            component: (data) => {
              return (
                <div className="my-8 w-full flex flex-wrap mx-auto gap-8 p-6">
                  {this.state.videos && this.state.videos.length
                    ? this.state.videos.map((video, key) => (
                      <VideoCard video={video} author={this.state.staffs ? this.state.staffs.find(s => s.id === video.authorId) : null} handleVideos={() => this.handleCollection(this.state.id)} isVideoTemp={false} key={key} />
                    ))
                    : null}
                </div>
              );
            },
          },
        ]}
      />
    );
  }
}

export default withTranslation()(withRouter(Match));
