import db from "./db";
import { post } from "../../requests/index";
import get from "../../requests/get";
// import moment from "moment";
// import cogoToast from "cogo-toast";

async function loadCompetitions() {
  return get("competition", "competitions").then((competitions) => {
    if (competitions) {
      db.competitions.bulkPut(competitions);
    }
    return competitions;
  });
}

function getCompetitions() {
  return db.competitions.toArray();
}

async function loadMatchInfos(eventId) {
  return get("competition", "matchsInfos", eventId).then((matchInfos) => {
    if (matchInfos) {
      matchInfos.id = eventId;
      db.matchsInfos.put(matchInfos);
    }
    return matchInfos;
  });
}

async function loadMatchsInfos(eventsIds, playerId) {
  return post("competition", "matchsInfos", { eventsIds })
    .then((matchsInfos) => {
      return matchsInfos.json();
    })
    .then((matchsInfos) => {
      if (matchsInfos) {
        matchsInfos.map((matchInfo) => {
          matchInfo.id = matchInfo.eventId;
          if (playerId) {
            matchInfo.playerId = playerId;
          }
          return matchInfo;
        });
        db.matchsInfos.bulkPut(matchsInfos);
      }
      return (
        matchsInfos &&
        matchsInfos.sort((a, b) =>
          a.startDate === b.startDate ? 0 : a.startDate > b.startDate ? -1 : 1
        )
      );
    });
}

function getMatchsInfos() {
  return db.matchsInfos.toArray();
}

function getMatchInfos(eventId) {
  return db.matchsInfos.where({ eventId }).toArray();
}

async function loadMatchsSheets(matchId) {
  return get("competition", "matchsSheets", matchId).then((matchSheet) => {
    if (matchSheet) {
      db.matchsSheets.put(matchSheet);
    }
    return matchSheet;
  });
}

function getMatchsSheets(matchId) {
  return db.matchsSheets.where({ matchId }).toArray();
}

function getMatchsInfosByPlayer(matchId) {
  return db.matchsInfosByPlayer.where({ matchId }).toArray();
}

function getMatchsInfosByPlayerId(playerId) {
  return db.matchsInfos.where({ playerId }).toArray();
}

async function loadMatchsInfosByPlayer(matchId) {
  return get("competition", "matchsInfosByPlayer", matchId).then(
    (matchInfoByPlayer) => {
      matchInfoByPlayer.map((info) => {
        info.matchId = matchId;
        return info;
      });
      if (matchInfoByPlayer) {
        db.matchsInfosByPlayer.bulkPut(matchInfoByPlayer);
      }
      return (
        matchInfoByPlayer &&
        matchInfoByPlayer.sort((a, b) =>
          a.position.number === b.position.number
            ? 0
            : a.position.number < b.position.number
            ? -1
            : 1
        )
      );
    }
  );
}

function loadExternalData(matchId, playerId) {
  return get(
    "competition",
    "chargeExterneNormalized",
    matchId + "/" + playerId
  ).then((chargeExterneNormalized) => {
    getExternalData(matchId, playerId).then((data) => {
      var promises = [];
      if (data && data.length) {
        data.forEach((a) => {
          promises.push(db.chargeExterneNormalized.delete(a.id));
        });
      }
      Promise.all(promises).then(() => {
        if (chargeExterneNormalized) {
          chargeExterneNormalized.map((r) => {
            r.playerId = playerId;
            r.matchId = matchId;
            return r;
          });
          db.chargeExterneNormalized.bulkPut(chargeExterneNormalized);
        }
      });
    });
    return chargeExterneNormalized;
  });
}

function getExternalData(matchId, playerId) {
  return db.chargeExterneNormalized.where({ matchId, playerId }).toArray();
}

async function loadCompetitionsByPlayer(playerId) {
  return get("competition", "CompetitionsByPlayer", playerId).then((res) => {
    getCompetitionsByPlayer(playerId).then((comp) => {
      var promises = [];
      if (comp && comp.length) {
        comp.forEach((a) => {
          promises.push(db.competitions.delete(a.id));
        });
      }
      Promise.all(promises).then(() => {
        if (res) {
          res.map((r) => {
            r.playerId = playerId;
            return r;
          });
          db.competitions.bulkPut(res);
        }
      });
    });
    return res;
  });
}

function getCompetitionsByPlayer(playerId) {
  return db.competitions.where({ playerId }).toArray();
}

// async function loadAttendeesByEvent(eventId) {
//   return get("schedule", "attendeesByEvent", eventId).then((res) => {
//     getAttendeesByEvent(eventId).then((att) => {
//       var promises = [];
//       if (att && att.length) {
//         att.forEach((a) => {
//           promises.push(db.attendees.delete(a.id));
//         });
//       }
//       Promise.all(promises).then(() => {
//         if (res) {
//           db.attendees.bulkPut(res);
//         }
//       });
//     });
//     return res;
//   });
// }

////// TEST ////////////////////////////

// async function loadAttendeesByEvent(eventId) {
//   return get("schedule", "attendeesByEvent", eventId).then((res) => {
//     getAttendeesByEvent(eventId).then((att) => {
//       var promises = [];
//       if (att && att.length) {
//         att.forEach((a) => {
//           promises.push(db.attendees.delete(a.id));
//         });
//       }
//       Promise.all(promises).then(() => {
//         if (res) {
//           db.attendees.bulkPut(res);
//         }
//       });
//     });
//     return res;
//   });
// }

// async function getAttendeesByEvent(eventId) {
//   return db.attendees.where({ eventId: eventId }).toArray();
// }

////////////////////////////////////////

export {
  loadCompetitions,
  getCompetitions,
  loadMatchInfos,
  getMatchsInfos,
  getMatchInfos,
  loadMatchsInfos,
  getMatchsSheets,
  loadMatchsSheets,
  getMatchsInfosByPlayer,
  loadMatchsInfosByPlayer,
  loadExternalData,
  getExternalData,
  loadCompetitionsByPlayer,
  getCompetitionsByPlayer,
  getMatchsInfosByPlayerId,
};
