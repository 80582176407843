import React from "react";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import {
  getContactsByPlayer,
  getLicensesByPlayer,
  getPersonalDocumentsByPlayer,
  getRecord,
  loadContactsByPlayer,
  loadContactsTypes,
  loadLicensesByPlayer,
  loadPersonalDocumentsByPlayer,
  loadRecord,
} from "../../utils/indexedDB/administration/handleAdministration";
import {
  getCareer,
  getCurriculumsPursuedByPlayer,
  getScholarshipDocumentsByPlayer,
  loadCareer,
  loadCurriculumsPursuedByPlayer,
  loadScholarshipDocumentsByPlayer,
} from "../../utils/indexedDB/scholarship/handleScholarship";
import {
  getTennisClubs,
  loadTennisClubs,
} from "../../utils/indexedDB/core/handleCore";
import Card from "../../components/card/Card";
import GeneratedForm from "../../utils/forms/generateForm";
import ActionButton from "../../components/buttons/ActionButton";
import moment from "moment";
import SubTab from "../../components/tab/SubTab";
import TablePlayerContacts from "../../tables/TablePlayerContacts";
import ButtonLight from "../../components/buttons/ButtonLight";
import TablePlayerCurriculumsPursued from "../../tables/TablePlayerCurriculumsPursued";
import TablePlayerLicenses from "../../tables/TablePlayerLicenses";
import { getFederalSupportByPlayer, loadFederalSupportByPlayer } from "../../utils/indexedDB/management/handleManagement";
import { getSeasons, loadSeasons } from "../../utils/indexedDB/events/handleEvents";
import { loadPlayer } from "../../utils/indexedDB/users/handleUser";
import getUserProfile from "../../utils/auth/getUserProfile";
import TablePlayerFederalSupport from "../../tables/TablePlayerFederalSupport";
import SVGCreate from "../../components/icons/SVGCreate";
import SVGEdit from "../../components/icons/SVGEdit";
import DisplayData2cols from "../../components/displayData/DisplayData2cols";
import DisplayData1col from "../../components/displayData/DisplayData1col";


class PlayerAdministrationTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.playerId,
      player: null,
      administrationTab: "Personal file",
    };
    this.updateContacts = this.updateContacts.bind(this);
    this.updateCurriculums = this.updateCurriculums.bind(this);
  }

  componentDidMount() {
    var id = this.props.playerId;
    getUserProfile().then((profile) => {
      this.setState({ sport: profile.sport, role: profile.role });
    });
    getRecord(id).then((record) => (record ? this.setState({ record }) : null));
    getContactsByPlayer(id).then((contacts) =>
      contacts ? this.setState({ contacts }) : null
    );
    getCurriculumsPursuedByPlayer(id).then((curriculumsPursued) =>
      curriculumsPursued ? this.setState({ curriculumsPursued }) : null
    );
    getScholarshipDocumentsByPlayer(id).then((scholarshipDocuments) =>
      this.setState({ scholarshipDocuments })
    );
    getPersonalDocumentsByPlayer(id).then((personalDocuments) =>
      this.setState({ personalDocuments })
    );
    getTennisClubs().then((clubs) => {
      if(clubs) {
        this.setState({ clubs });
        getLicensesByPlayer(id).then((licenses) => {
          licenses = licenses.map((license) => {
            license.club =
              (clubs && clubs.find(o => o.id === license.tennisClubId)
                ? clubs.find(o => o.id === license.tennisClubId)
                : null);
                return license;
          });
          if(licenses) this.setState({ licenses });
        });
      }
    });

    getSeasons().then(seasons => {
      this.setState({ seasons })
    })
    getFederalSupportByPlayer(id).then(playerFS => {
      this.setState({ playerFS })
    })

    getCareer(id).then((career) => (career ? this.setState({ career }) : null));

    if (navigator.onLine) {
      loadPlayer(id).then(player => {
        this.setState({player});
        loadSeasons().then(seasons => {
          if (seasons && seasons.length > 0) {
            this.setState({ seasons })

            var promises = [];
            this.state.seasons.forEach((season) => {
              promises.push(
                loadFederalSupportByPlayer(id, player, season).then(res => {
                  return res
                })
              );
            });
            Promise.all(promises).then((playerCompleted) => {
              if (playerCompleted && playerCompleted.length) {
                var playerFS = [];
                playerCompleted.forEach(p => {
                  if (p && p.id)
                    playerFS = playerFS.concat(p)
                })
                playerFS = playerFS.sort((a, b) =>
                  a.seasonId === b.seasonId ? 0 : a.seasonId > b.seasonId ? 1 : -1
                );
                this.setState({ playerFS })
              }
            });
          }
        });
      });
      loadRecord(id).then((record) => this.setState({ record }));
      loadContactsTypes().then((contactsTypes) =>
        this.setState({ contactsTypes })
      );
      loadContactsByPlayer(id).then((contacts) => this.setState({ contacts }));
      loadCurriculumsPursuedByPlayer(id).then((curriculumsPursued) =>
        this.setState({ curriculumsPursued })
      );
      loadScholarshipDocumentsByPlayer(id).then((scholarshipDocuments) =>
        this.setState({ scholarshipDocuments })
      );
      loadPersonalDocumentsByPlayer(this.state.id).then((personalDocuments) =>
        this.setState({ personalDocuments })
      );
      loadTennisClubs().then((clubs) => {
        this.setState({ clubs });
        loadLicensesByPlayer(id).then((licenses) => {
          licenses = licenses.map((license) => {
            license.club =
              (clubs && clubs.find(o => o.id === license.tennisClubId)
                ? clubs.find(o => o.id === license.tennisClubId)
                : null);
                return license;
          });
          this.setState({ licenses });
        });
      });

      loadCareer(id).then((career) => {
        this.setState({ career })});

        
    }
  }

  forceUpdateContact() {
    if (navigator.onLine) {
      loadContactsByPlayer(this.props.playerId).then((contacts) =>
        this.setState({ contacts })
      );
    }
  }

  updateContacts(playerId) {
    loadContactsByPlayer(playerId).then((contacts) =>
      this.setState({ contacts })
    );
  }

  updateCurriculums(id) {
    loadCurriculumsPursuedByPlayer(id).then((curriculumsPursued) =>
      this.setState({ curriculumsPursued })
    );
  }

  forceUpdateRecord() {
    if (navigator.onLine) {
      loadRecord(this.props.playerId).then((record) =>
        this.setState({ record })
      );
    }
  }

  forceUpdateCareer() {
    if (navigator.onLine) {
      loadCareer(this.props.playerId).then((career) =>
        this.setState({ career })
      );
    }
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <SubTab
          tab={[
            { key: "Personal file", label: t("Personal file") },
            { key: "Licenses", label: t("Licenses") },
            { key: "Scholarship", label: t("Scholarship") },
            { key: "Federal", label: t("Suivi fédéral") },
          ]}
          selectedTab={this.state.administrationTab}
          onChange={(key) =>
            this.setState({
              administrationTab: key,
            })
          }
        />

        {this.state.administrationTab === "Personal file" &&
        this.state.record ? (
          <>
            <div className="grid grid-cols-2 gap-4">
              <div className="col-span-1 mx-6 my-3">
                <Card
                  className=""
                  title={t("Personal file")}
                  headerActions={
                    <GeneratedForm
                      key="updateRecord"
                      component={(show, text) => (
                        <ButtonLight
                          onClick={show}
                          disabled={!navigator.onLine}
                        >
                          <SVGEdit />
                        </ButtonLight>
                      )}
                      callback={(res) => this.forceUpdateRecord()}
                      formName="updateRecord"
                      formData={this.state.record}
                    />
                  }
                >
                  <DisplayData2cols
                    data={[
                      {key: t("Birth city"), value: this.state.record.birthCity },
                      {key:t("Birth department"), value:this.state.record.birthDepartment },
                      {key:t("Birth country"), value:this.state.record.birthCountryId },
                      {key:t("Address"), value:this.state.record.address },
                      {key:t("Address 2"), value:this.state.record.address2 },
                      {key:t("Postal code"), value:this.state.record.postalCode },
                      {key:t("City"), value:this.state.record.city },
                      {key:t("Home country"), value:this.state.record.homeCountryId },
                      {key:t("Marital status"), value:this.state.record.maritalStatus ? this.state.record.maritalStatus.name  : "" },
                      {key:t("Allergies"), value:this.state.record.allergies ? this.state.record.allergies  : "" },
                    ]}
                  />
                </Card>
              </div>
              <div className=" mx-6 my-3">
                <Card
                  className=" "
                  title={t("Documents")}
                  headerActions={
                    <GeneratedForm
                      key="createScholarshipDocument"
                      component={(show, text) => (
                        <ButtonLight onClick={show} disabled={!navigator.onLine}>
                          <SVGCreate />
                        </ButtonLight>
                      )}
                      callback={(res) =>
                        loadPersonalDocumentsByPlayer(
                          this.state.id
                        ).then((personalDocuments) =>
                          this.setState({ personalDocuments })
                        )
                      }
                      formName="createPersonalDocument"
                      formData={{ playerId: this.state.id }}
                    />
                  }
                >
                  <div className="">
                    <ul className="divide-y divide-gray-200 h-96 overflow-auto">
                      {this.state.personalDocuments
                        ? this.state.personalDocuments.map(
                            (personalDocument, key) => (
                              <li key={key}>
                                <div className="block hover:bg-gray-50">
                                  <div className="flex items-center px-4 py-4 sm:px-6">
                                    <div className="min-w-0 flex-1 flex items-center">
                                      <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                                        <div>
                                          <p className="text-xs 2xl:text-sm font-medium text-indigo-600 truncate">
                                            <a href={personalDocument.filePath}>
                                              {personalDocument.name}
                                            </a>
                                          </p>
                                          <p className="mt-1 2xl:mt-2 flex items-center text-xs 2xl:text-sm text-gray-500">
                                            <span className="truncate">
                                              {moment(personalDocument.date).calendar()}
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <ActionButton
                                        key={personalDocument.id}
                                        // updateForm="updatePersonalDocument"
                                        deleteForm="deletePersonalDocument"
                                        formData={personalDocument}
                                        refreshData={() =>
                                          loadPersonalDocumentsByPlayer(
                                            this.state.id
                                          ).then((personalDocuments) =>
                                            this.setState({ personalDocuments })
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </li>
                            )
                          )
                        : ""}
                    </ul>
                  </div>
                </Card>
              </div>
            </div>
            <TablePlayerContacts
              contacts={this.state.contacts}
              buttons={[
                <GeneratedForm
                  key="createContact"
                  component={(show, text) => (
                    <ButtonLight
                      onClick={show}
                      disabled={!navigator.onLine}
                    >
                      <i className="flaticon2-plus"></i> {text}
                    </ButtonLight>
                  )}
                  callback={(res) => this.forceUpdateContact()}
                  formName="createContact"
                  formData={{ playerId: this.state.id }}
                />,
              ]}
              updateContacts={this.updateContacts}
              pId={this.props.playerId}
            />
          </>
        ) : (
          ""
        )}
        {this.state.administrationTab === "Scholarship" ? (
          <>
            <div className="grid grid-cols-2">
              <div className=" mx-6 my-3">
                <Card
                  className=""
                  title={t("Professional project")}
                  headerActions={
                    <GeneratedForm
                      key="updateCareer"
                      component={(show, text) => (
                        <ButtonLight
                          onClick={show}
                          disabled={!navigator.onLine}
                        >
                          <SVGEdit />
                        </ButtonLight>
                      )}
                      callback={(res) => this.forceUpdateCareer()}
                      formName="updateCareer"
                      formData={this.state.career}
                    />
                  }
                >
                  <DisplayData1col
                    data={[
                      {key:t("Considered job"), value:this.state.career ? this.state.career.consideredJob : ""},
                      {key:t("Comments"), value:this.state.career ? this.state.career.description : ""}
                    ]}
                  />
                </Card>
              </div>
              <div className="mx-6 my-3">
                <Card
                  className=" "
                  title={t("Documents")}
                  headerActions={
                    <GeneratedForm
                      key="createScholarshipDocument"
                      component={(show, text) => (
                        <ButtonLight onClick={show} disabled={!navigator.onLine}>
                          <SVGCreate />
                        </ButtonLight>
                      )}
                      callback={(res) =>
                        loadScholarshipDocumentsByPlayer(
                          this.state.id
                        ).then((scholarshipDocuments) =>
                          this.setState({ scholarshipDocuments })
                        )
                      }
                      formName="createScholarshipDocument"
                      formData={{ playerId: this.state.id }}
                    />
                  }
                >
                  <div className="">
                    <ul className="divide-y divide-gray-200 h-96 overflow-auto">
                      {this.state.scholarshipDocuments
                        ? this.state.scholarshipDocuments.map(
                            (scholarshipDocument, key) => (
                              <li key={key}>
                                <div className="block hover:bg-gray-50">
                                  <div className="flex items-center px-4 py-4 sm:px-6">
                                    <div className="min-w-0 flex-1 flex items-center">
                                      <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                                        <div>
                                          <p className="text-xs 2xl:text-sm font-medium text-indigo-600 truncate">
                                            <a href={scholarshipDocument.filePath}>
                                              {scholarshipDocument.name}
                                            </a>
                                          </p>
                                          <p className="mt-1 2xl:mt-2 flex items-center text-xs 2xl:text-sm text-gray-500">
                                            <span className="truncate">
                                              {moment(
                                                scholarshipDocument.date
                                              ).calendar()}
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <ActionButton
                                        key={scholarshipDocument.id}
                                        // updateForm="updateScholarshipDocument"
                                        deleteForm="deleteScholarshipDocument"
                                        formData={scholarshipDocument}
                                        refreshData={() =>
                                          loadScholarshipDocumentsByPlayer(
                                            this.state.id
                                          ).then((scholarshipDocuments) =>
                                            this.setState({
                                              scholarshipDocuments,
                                            })
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </li>
                            )
                          )
                        : ""}
                    </ul>
                  </div>
                </Card>
              </div>
            </div>
            <TablePlayerCurriculumsPursued
              curriculumsPursued={this.state.curriculumsPursued}
              buttons={[
                <GeneratedForm
                  key="createCurriculumPursued"
                  component={(show, text) => (
                    <ButtonLight
                      onClick={show}
                      disabled={!navigator.onLine}
                    >
                      <i className="flaticon2-plus"></i> {text}
                    </ButtonLight>
                  )}
                  callback={(res) => loadCurriculumsPursuedByPlayer(this.state.id).then((curriculumsPursued) =>
                    this.setState({ curriculumsPursued })
                  )}
                  formName="createCurriculumPursued"
                  formData={{ playerId: this.state.id }}
                />,
              ]}
              updateCurriculums={this.updateCurriculums}
              pId={this.props.playerId}
            />
          </>
        ) : (
          ""
        )}

        {this.state.administrationTab === "Licenses" ? (
          <TablePlayerLicenses licenses={this.state.licenses} />
        ) : (
          ""
        )}
        {this.state.administrationTab === "Federal" ? (
          <TablePlayerFederalSupport
            role={this.state.role}
            playerCardVue={true}
            players={this.state.playerFS}
            selectedSeason={this.state.selectedSeason}
            seasons={this.state.seasons}
            refresh={() => {
              var promises = [];
              this.state.seasons.forEach((season) => {
                promises.push(
                  loadFederalSupportByPlayer(this.state.id, this.state.player, season).then(res => {
                    return res
                  })
                );
              });
              Promise.all(promises).then((playerCompleted) => {
                if (playerCompleted && playerCompleted.length) {
                  var playerFS = [];
                  playerCompleted.forEach(p => {
                    if (p && p.id)
                      playerFS = playerFS.concat(p)
                  })
                  playerFS = playerFS.sort((a, b) =>
                    a.seasonId === b.seasonId ? 0 : a.seasonId > b.seasonId ? 1 : -1
                  );
                  this.setState({ playerFS })
                }
              });
            }}
            buttons={navigator.onLine && this.state.role === "Manager" ? [
              <GeneratedForm
                component={(show, text) => (
                  <ButtonLight
                    onClick={show}
                    disabled={!navigator.onLine}
                  >
                    <i className="flaticon2-plus"></i> {text}
                  </ButtonLight>
                )}
                callback={() => {
                  var promises = [];
                  this.state.seasons.forEach((season) => {
                    promises.push(
                      loadFederalSupportByPlayer(this.state.id, this.state.player, season).then(res => {
                        return res
                      })
                    );
                  });
                  Promise.all(promises).then((playerCompleted) => {
                    if (playerCompleted && playerCompleted.length) {
                      var playerFS = [];
                      playerCompleted.forEach(p => {
                        if (p && p.id)
                          playerFS = playerFS.concat(p)
                      })
                      playerFS = playerFS.sort((a, b) =>
                        a.seasonId === b.seasonId ? 0 : a.seasonId > b.seasonId ? 1 : -1
                      );
                      this.setState({ playerFS })
                    }
                  });
                }}
                formName="createFederalSupport"
                method="POST"
                formData={{
                  playerId: this.state.id,
                  comments: null,
                  federalSupportProgrammeId: null,
                  federalSupportStatusId: null,
                  trainingCenterId: null,
                }}
              />] : null
            }
          />
        ) : (
          ""
        )}
      </>
    );
  }
}

export default withTranslation()(withRouter(PlayerAdministrationTab));
