import React from "react"
import moment from "moment"
import IconRound32x32 from "../components/icons/IconRound32x32"
import { withTranslation } from "react-i18next"
import { Input, SelectMultiple, Checkbox } from "../components/forms"
import TableSimple from "../components/table/TableSimple"
import Flag from "../components/icons/Flag"
import FilterSeparator from "../components/filters/FilterSeparator"
import FiltersTitle from "../components/filters/FiltersTitle"
import FiltersBar from "../components/filters/FiltersBar"

class TableStaffRightsOnPlayers extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      actions: [],
      groups: [],
      selectedGroups: [],
      years: [],
      selectedYears: [],
      columns: [],
      searchText: "",
    }
  }

  componentDidMount() {
    this.prepareData()
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.prepareData()
    }
  }

  prepareData = () => {
    var players = this.props.players
    var years = []
    var groups = []
    var genders = [
      { key: "H", label: this.props.t("Man") },
      { key: "F", label: this.props.t("Woman") },
    ]
    var selectedGenders = ["H", "F"]
    var selectedYears = []
    var selectedGroups = []
    if (players) {
      players = players.map((player) => {
        player.year = moment(player.dob).year()
        if (years && !years.find((o) => o.key === player.year)) {
          years.push({ key: player.year, label: player.year })
          selectedYears.push(player.year)
        }
        if (
          groups &&
          player.group &&
          !groups.find((o) => o.key === player.group.id)
        ) {
          groups.push({ key: player.group.id, label: player.group.name })
          selectedGroups.push(player.group.id)
        }
        return player
      })
    }
    this.setState({
      players,
      groups,
      years,
      genders,
      selectedGroups,
      selectedYears,
      selectedGenders,
    })
  }

  render() {
    const { t } = this.props
    var players = this.props.players
    var selectedYears = this.state.selectedYears
    var selectedGroups = this.state.selectedGroups
    var searchText = this.state.searchText
    if (players) {
      players = players.filter((o) =>
        selectedYears.some((item) => item === o.year)
      )
    }
    if (players) {
      players = players.filter((o) =>
        selectedGroups.some((item) => item === o.group.id)
      )
    }
    if (searchText.length > 2) {
      players = players.filter(
        (o) =>
          o.lastName.toLowerCase().includes(searchText.toLowerCase()) ||
          o.firstName.toLowerCase().includes(searchText.toLowerCase())
      )
    }
    var playersSelected = this.props.playersSelected
    var columns = [
      {
        key: "selected",
        visible: true,
        id: "selected",
        format: (row) => {
          return (
            <Checkbox
              value={
                playersSelected && playersSelected.length
                  ? playersSelected.includes(row.id)
                  : false
              }
              onChange={(value) => this.props.onChange(value, row.id)}
            />
          )
        },
      },
      {
        key: "displayName",
        visible: true,
        label: this.props.t("Player"),
        pinned: "left",
        id: "displayName",
        format: (row) => {
          return (
            <span className="flex justify-between">
              <span
                className="text-indigo-700 font-medium pr-2 text-xs 2xl:text-sm"
                to={"/group/player/" + row.id}
              >
                <IconRound32x32 photo={row.photo} />
                <span className="ml-5">
                  {row.lastName.toUpperCase() + " " + row.firstName}
                </span>
              </span>
            </span>
          )
        },
      },
      {
        key: "year",
        visible: true,
        label: this.props.t("Year"),
        width: 100,
        id: "year",
        format: (row) => (
          <span className="font-weight-bold text-muted text-xs 2xl:text-sm">
            {moment(row.dob).format("YYYY")}
          </span>
        ),
        filter: {
          name: "NumberBetweenFilter",
        },
      },
      {
        key: "nationalityId",
        visible: true,
        label: this.props.t("Nationality"),
        width: 60,
        filter: {
          name: "SelectColumnFilter",
          type: "includes",
        },
        format: (row) => (
          <Flag className="w-4 2xl:w-6" nationalityId={row.nationalityId} />
        ),
      },
      {
        key: "groupLabel",
        visible: true,
        label: this.props.t("Group"),
        filter: {
          name: "SelectColumnFilter",
          type: "includes",
        },
        width: 150,
        format: (row) => (
          <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs 2xl:text-sm font-medium bg-indigo-100 text-indigo-800">
            {row.group ? row.group.name : "-"}
          </span>
        ),
      },
    ]
    return (
      <>
        <FiltersBar
          components={
            <>
              <FiltersTitle title={players ? players.length + " " + t("Players").toLowerCase() : ""} />
              <FilterSeparator />
              <SelectMultiple
                options={this.state.groups}
                keys={this.state.selectedGroups}
                className="mx-2"
                onChange={(res) => this.setState({ selectedGroups: res })}
                fixedLabel={t("Group")}
              />
              <SelectMultiple
                options={this.state.years}
                keys={this.state.selectedYears}
                className="mx-2"
                onChange={(res) => this.setState({ selectedYears: res })}
                fixedLabel={t("Year")}
              />
              <FilterSeparator />
              <Input
                value={this.state.searchText}
                onChange={(searchText) => this.setState({ searchText })}
                className="mx-2 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"
              />
            </>
          }
        />
        <TableSimple columns={columns} data={players} />
      </>
    )
  }
}

export default withTranslation()(TableStaffRightsOnPlayers)
