import React from "react";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import Card from "../../components/card/Card";
import {
  getInternationalSupervisionRemaining,
  getNationalSupervisionRemaining,
  getSupervision,
  getResults,
  getResultsNumber,
  loadCircuits,
  loadInternationalSupervisionRemaining,
  loadNationalSupervisionRemaining,
  loadSupervision,
  addSupervision
} from "../../utils/indexedDB/Tennis-Competition/handleCompetitionTennis";
import moment from "moment";
import TableStaffSupervision from "../../tables/TableStaffSupervision";

class StaffSupervisionTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.id,
      // player: null,
      year: parseInt(moment().format("YYYY")),
      month: parseInt(moment().format("M")),
    };
    this.addSupervision = addSupervision.bind(this);
    this.updateSupervision = this.updateSupervision.bind(this);
    this.updateAddTournament = this.updateAddTournament.bind(this);
  }
  componentDidMount() {
    // get id from url
    var id = this.props.id;

    // function get from indexedDB
    getSupervision(id, 0).then((supervision) => {
      if (supervision) this.setState({ supervision, loading: false });
      getInternationalSupervisionRemaining(
        this.state.month,
        this.state.year,
        id
      ).then((competitionInt) => this.setState({ competitionInt }));
      getNationalSupervisionRemaining(
        this.state.month,
        this.state.year,
        id
      ).then((competitionNat) => this.setState({ competitionNat }));
    });

    getResults(id).then((results) =>
      this.setState({ results, numbers: getResultsNumber(results) })
    );
    if (navigator.onLine) {
      //function get from database
      loadCircuits();

      loadSupervision(id).then((supervision) => {
        this.setState({ supervision, loading: false });
        loadInternationalSupervisionRemaining(
          this.state.month,
          this.state.year,
          id
        ).then((competitionInt) => this.setState({ competitionInt }));
        loadNationalSupervisionRemaining(
          this.state.month,
          this.state.year,
          id
        ).then((competitionNat) => this.setState({ competitionNat }));
      });

    }
  }

  // componentDidUpdate(prevProps) {
  //   if (prevProps.player !== this.props.player) {
  //     this.setState({ player: this.props.player });
  //   }
  // }

  updateSupervision(value) {
    getSupervision(value).then((supervision) => {
      if (supervision) {
        this.setState({ supervision })
        getInternationalSupervisionRemaining(
          this.state.month,
          this.state.year,
          value
        ).then((competitionInt) => { this.setState({ competitionInt }) });
        getNationalSupervisionRemaining(
          this.state.month,
          this.state.year,
          value
        ).then((competitionNat) => { this.setState({ competitionNat }) });
      }
    });
    if (navigator.onLine) {
      loadSupervision(value).then((supervision) => {
        this.setState({ supervision })
        loadInternationalSupervisionRemaining(
          this.state.month,
          this.state.year,
          value
        ).then((competitionInt) => { this.setState({ competitionInt }) });
        loadNationalSupervisionRemaining(
          this.state.month,
          this.state.year,
          value
        ).then((competitionNat) => { this.setState({ competitionNat }) });
      });
    }
  }

  updateAddTournament(month, year) {
    this.setState({ loading: true });
    if (month === 0) {
      month = 12;
      year -= 1;
    } else if (month === 13) {
      month = 1;
      year += 1;
    }
    this.setState({ month: month, year: year });

    getNationalSupervisionRemaining(month, year, this.state.id).then(
      (competitionNat) => {
        if (competitionNat) this.setState({ competitionNat, loading: false });
      }
    );
    getInternationalSupervisionRemaining(month, year, this.state.id).then(
      (competitionInt) => {
        if (competitionInt) this.setState({ competitionInt, loading: false });
      }
    );

    if (navigator.onLine) {
      loadNationalSupervisionRemaining(
        month,
        year,
        this.state.id
      ).then((competitionNat) =>
        this.setState({ competitionNat, loading: false })
      );
      loadInternationalSupervisionRemaining(
        month,
        year,
        this.state.id
      ).then((competitionInt) =>
        this.setState({ competitionInt, loading: false })
      );
    }
  }

  render() {
    return (
      <div>
        <div>
          <Card>
            <TableStaffSupervision
              supervision={this.state.supervision}
              national={this.state.competitionNat}
              international={this.state.competitionInt}
              updateSupervision={this.updateSupervision}
              id={this.state.id}
              addSupervision={this.addSupervision}
              onChange={this.updateAddTournament}
            />
          </Card>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(StaffSupervisionTab));
