import React from "react";
import { withTranslation } from "react-i18next";
import Loading from "../../../../components/loaders/Loading";
import Button from "../../../../components/buttons/Button";
import generateYupObject from "../../yupObject";
import moment from "moment";
import getUserProfile from "../../../auth/getUserProfile";
import * as Drawer from "../../../../components/drawer";

import { getQuestionRender, loadChoices, initResponse } from "../../questionRender";
import { get, post, patch, del } from "../../../requests";
import Table from "../../../../components/table/EditableTable";
import { Input, Select, TextArea } from "../../../../components/forms";
import Checkbox from "../../../../components/forms/input/CheckBoxLabel";

import cogoToast from "cogo-toast";
import { loadEngagementsByPlayerByTournament, addEngagement } from "../../../indexedDB/Tennis-Competition/handleCompetitionTennis";
import { loadTrainingSequences, loadPhysicalSequences } from "../../../indexedDB/training/handleTraining";
import ButtonLight from "../../../../components/buttons/ButtonLight";
import SVG from "react-inlinesvg";
import ExerciceCard from "../../../../components/card/ExerciceCard";
import SvgExo from "../../../../assets/sass/vendors/theme/svg/icons/General/Duplicate.svg";
import TableSessionTemplates from "../../../../tables/TableSessionTemplates";
import { getTrainingDrillsByGroup, loadTrainingDrillsByGroup, getPhysicalDrillsByGroup, loadTrainingSessionTemplates, loadPhysicalDrillsByGroup, loadPhysicalSessionTemplates } from "../../../indexedDB/training/handleTraining";
import { loadStaffs, loadGroups } from "../../../indexedDB/users/handleUser";
const jsonSchema = require("../eventFormSchema.json");


class StepInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldsDependance: [],
      formData: null,
      loading: true,
      sequences: null,
      profile: "",
      themes: {},
      categories: [],
      loadingSeq: true,
      openFacultativeField: false,
      keepRecurrence: true
    };
    this.generateYupObject = generateYupObject.bind(this);
    this.getQuestionRender = getQuestionRender.bind(this);
    this.loadChoices = loadChoices.bind(this);
    this.addEngagement = addEngagement.bind(this);
    this.handleDeleteP = this.handleDeleteP.bind(this);
    this.handleAddP = this.handleAddP.bind(this);
    this.handleDeleteE = this.handleDeleteE.bind(this);
    this.handleAddE = this.handleAddE.bind(this);
    this.handleCopySessionTemplate = this.handleCopySessionTemplate.bind(this);
  }

  componentDidMount() {
    if (navigator.onLine) {
      getUserProfile().then((profile) => {
        this.setState({ profile: profile.sub });
      });

      if (
        this.props.method === "POST" &&
        !(this.props.formData && this.props.formData.typeId)
      ) {
        this.props.goToStepChooseType();
      } else {
        if (this.props.formData && this.props.formData.typeId) {
          this.loadStepInfo(this.props.formData.typeId);
        }
      }
    }
  }

  loadStepInfo(typeId) {
    this.setState({ loading: true });
    var promises = [];
    var promisesGen = [];
    var promisesSpec = [];
    var fieldsDependance = [];

    //General questions
    var questionsGeneral = jsonSchema["Common"];
    questionsGeneral.forEach((question) => {
      if (question.dependsOnAnotherField) {
        question.dependsOnAnotherField.forEach((d) => {
          if (!fieldsDependance.includes(d)) {
            fieldsDependance.push(d);
          }
        });
      }
      if (question.options) {
        question.listChoices = question.options;
      }
      promisesGen.push(
        (question.widget === "Select" ||
          question.widget === "SelectMultiple") &&
          question.APIfeature &&
          question.APImodule &&
          !question.dependsOnAnotherField
          ? this.loadChoices(question).then((choices) => {
            question.listChoices = choices;
            return question;
          })
          : question
      );
    });

    //Specific questions
    var questionsSpecific = jsonSchema[typeId];
    if (questionsSpecific) {
      questionsSpecific.forEach((question) => {
        if (question.dependsOnAnotherField) {
          question.dependsOnAnotherField.forEach((d) => {
            if (!fieldsDependance.includes(d)) {
              fieldsDependance.push(d);
            }
          });
        }
        if (question.options) {
          question.listChoices = question.options;
        }
        promisesSpec.push(
          (question.widget === "Select" ||
            question.widget === "SelectMultiple") &&
            question.APIfeature &&
            question.APImodule &&
            !question.dependsOnAnotherField
            ? this.loadChoices(question).then((choices) => {
              question.listChoices = choices;
              return question;
            })
            : question
        );
      });
    }

    if (typeId === "Entrainement") {
      get("training", "trainingCategories").then((categories) => {
        if (categories) {
          categories.forEach((cat) => {
            get("training", "trainingThemesByCategory", cat.id).then(
              (themesAPI) => {
                var themes = this.state.themes ? this.state.themes : {};
                themes[cat.id] = themesAPI;
                this.setState({ themes });
              }
            );
          });
        }
        this.setState({ categories: categories ? categories : [] });
      });
      loadStaffs().then((authors) => {
        loadGroups().then((groups) => {
          groups = groups.map((g) => {
            g.key = g.id;
            g.label = g.name;
            return g;
          })
          loadTrainingDrillsByGroup(groups[0].id, authors).then((drills) => {
            this.setState({ drills, selectedGroup: groups[0].id })
          });
          this.setState({ groups, authors })
        })
      })
      loadTrainingSessionTemplates().then((sessionTemplates) => {
        this.setState({ sessionTemplates })
      })
    }
    if (typeId === "Physique") {
      get("training", "physicalCategories").then((categories) => {
        if (categories) {
          categories.forEach((cat) => {
            get("training", "physicalThemesByCategory", cat.id).then(
              (themesAPI) => {
                var themes = this.state.themes ? this.state.themes : {};
                themes[cat.id] = themesAPI;
                this.setState({ themes });
              }
            );
          });
        }
        this.setState({ categories: categories ? categories : [] });
      });
      loadStaffs().then((authors) => {
        loadGroups().then((groups) => {
          groups = groups.map((g) => {
            g.key = g.id;
            g.label = g.name;
            return g;
          })
          loadPhysicalDrillsByGroup(groups[0].id, authors).then((drills) => {
            this.setState({ drills, selectedGroup: groups[0].id })
          });
          this.setState({ groups, authors })
        })
      })
      loadPhysicalSessionTemplates().then((sessionTemplates) => {
        this.setState({ sessionTemplates })
      })
    }

    promises.push(Promise.all(promisesGen));
    promises.push(Promise.all(promisesSpec));

    if (promises !== []) {
      Promise.all(promises).then((questionsTab) => {
        questionsGeneral = questionsTab[0];
        questionsSpecific = questionsTab[1];
        var questions = questionsGeneral.concat(questionsSpecific);
        this.setState({
          questionsGeneral,
          questionsSpecific,
          questions,
          type: typeId,
          loading: false
        });
        this.schema = this.generateYupObject(questions, this.state.formData);

        if (this.props.formData) {
          var formData = Object.assign({}, this.props.originalFormData);
          if (formData.id && this.props.method === "PATCH") {
            get("schedule", "events", formData.id).then(form => {
              formData = Object.assign({}, form);
              if (formData.specificInfos) {
                Object.keys(formData.specificInfos).forEach(k => {
                  if (formData.specificInfos[k]) {
                    formData[k] = formData.specificInfos[k];
                  }
                })
              }
              questions.forEach(q => {
                if (!formData[q.label] && (initResponse(q) || typeof initResponse(q) === "boolean")) {
                  formData[q.label] = initResponse(q);
                }
              });

              if (formData.startDate && formData.endDate) {
                formData.date = formData.startDate;
                formData.startTime = formData.startDate;
                formData.endTime = formData.endDate;
              }

              this.setState({ formData });
            });

            if (typeId === "Physique") {
              loadPhysicalSequences(formData.id).then((sequences) => {
                if (sequences && sequences.length) {
                  sequences = sequences.map((t) => {
                    t.physicalCategoryId = t.physicalTheme
                      ? t.physicalTheme.physicalCategoryId
                      : "";
                    t.physicalThemeId = t.physicalTheme ? t.physicalTheme.id : "";
                    return t;
                  });

                  this.setState({
                    sequences: Object.assign([], sequences),
                    loading: false,
                  });
                } else {
                  sequences = [];
                  var newSequence = {
                    name: "",
                    description: "",
                    duration: 0,
                    goals: "",
                    instructions: "",
                    physicalThemeId: "",
                    organizerId: this.state.profile,
                  };
                  sequences.push(newSequence);
                  this.setState({ sequences, loadingSeq: false });
                }
              });
            } else if (typeId === "Entrainement") {
              loadTrainingSequences(formData.id).then((sequences) => {
                if (sequences && sequences.length) {
                  sequences = sequences.map((t) => {
                    t.trainingCategoryId = t.trainingTheme
                      ? t.trainingTheme.trainingCategoryId
                      : "";
                    t.trainingThemeId = t.trainingTheme ? t.trainingTheme.id : "";
                    return t;
                  });

                  this.setState({
                    sequences: Object.assign([], sequences),
                    loading: false,
                  });
                } else {
                  sequences = [];
                  var newSequence = {
                    name: "",
                    description: "",
                    duration: 0,
                    goals: "",
                    instructions: "",
                    trainingThemeId: "",
                    trainingDrillId: "",
                    organizerId: this.state.profile,
                  };
                  sequences.push(newSequence);
                  this.setState({ sequences, loadingSeq: false });
                }
              });
            }
          } else {
            questions.forEach(q => {
              if (!formData[q.label] && (initResponse(q) || typeof initResponse(q) === "boolean")) {
                formData[q.label] = initResponse(q);
              }
            });

            if (formData.startDate && formData.endDate) {
              formData.date = formData.startDate;
              formData.startTime = formData.startDate;
              formData.endTime = formData.endDate;
            }

            if (formData && formData.attendees && formData.attendees.length) {
              formData.playerId = formData.attendees[0];
            }

            if (this.props.formData && this.props.formData.typeId) {
              formData.typeId = this.props.formData.typeId;
            }

            this.setState({ formData });
            var sequences = [];
            var newSequence = {};
            if (typeId === "Physique") {
              sequences = [];
              newSequence = {
                name: "",
                description: "",
                duration: 0,
                goals: "",
                instructions: "",
                trainingThemeId: "",
                trainingDrillId: "",
                organizerId: this.state.profile,
              };
              sequences.push(newSequence);
              this.setState({ sequences, loadingSeq: false });
            } else if (typeId === "Entrainement") {
              sequences = [];
              newSequence = {
                name: "",
                description: "",
                duration: 0,
                goals: "",
                instructions: "",
                trainingThemeId: "",
                trainingDrillId: "",
                organizerId: this.state.profile,
              };
              sequences.push(newSequence);
              this.setState({ sequences, loading: false });
            }
          }
        }
      });
    }

    window.scrollTo(0, 0); // scroll component to 0.0
  }

  changeSelectedGroup(groupId, authors) {
    if (this.props.formData.typeId === "Physique") {
      getPhysicalDrillsByGroup(groupId).then((drills) => {
        if (drills) {
          drills.sort((a, b) =>
            a.physicalTheme.physicalCategory.name === b.physicalTheme.physicalCategory.name ? (
              a.physicalTheme.name === b.physicalTheme.name ? (
                a.name === b.name ? 0 : a.name > b.name ? 1 : -1
              ) : a.physicalTheme.name > b.physicalTheme.name ? 1 : -1
            ) : a.physicalTheme.physicalCategory.name > b.physicalTheme.physicalCategory.name ? 1 : -1
          );
          this.setState({ drills })
        }
      })
      this.setState({ selectedGroup: groupId })
      if (navigator.onLine) {
        loadPhysicalDrillsByGroup(groupId, authors).then((drills) => {
          this.setState({ drills })
        });
      }
    }
    else {
      getTrainingDrillsByGroup(groupId).then((drills) => {
        if (drills) {
          drills.sort((a, b) =>
            a.trainingTheme.trainingCategory.name === b.trainingTheme.trainingCategory.name ? (
              a.trainingTheme.name === b.trainingTheme.name ? (
                a.name === b.name ? 0 : a.name > b.name ? 1 : -1
              ) : a.trainingTheme.name > b.trainingTheme.name ? 1 : -1
            ) : a.trainingTheme.trainingCategory.name > b.trainingTheme.trainingCategory.name ? 1 : -1
          );
          this.setState({ drills })
        }
      })
      this.setState({ selectedGroup: groupId })
      if (navigator.onLine) {
        loadTrainingDrillsByGroup(groupId, authors).then((drills) => {
          this.setState({ drills })
        });
      }
    }
  }

  // SUBMIT EVENT
  handleSubmit() {
    this.setState({ loading: true, errors: null });
    var formData = this.state.formData;
    this.schema.isValid(formData).then((valid) => {
      if (valid) {
        try {
          getUserProfile().then((profile) => {
            var event = formData;
            var specific = formData && formData.specificInfos ? formData.specificInfos : {};
            if (this.state.questionsGeneral) {
              this.state.questionsGeneral.forEach((q) => {
                if (formData[q.label] || typeof formData[q.label] === "boolean") {
                  event[q.label] = formData[q.label];
                } else {
                  if (q.required) {
                    throw (q.name + " is required").toString();
                  } else {
                    event[q.label] = "";
                  }
                }
              });
            }
            var date = moment.isMoment(event.date) ? event.date : moment(event.date);
            var startTime = moment.isMoment(event.startTime) ? event.startTime : moment(event.startTime);
            if (startTime.isValid()) {
              if (date.isValid()) {
                startTime.year(date.year()).month(date.month()).date(date.date());
              }
              event.startDate = startTime.format("YYYY-MM-DDTHH:mm:ss");
            } else {
              var startValues = event.startTime.split(":");
              var startDate = moment(event.date);
              startDate.hours(Number(startValues[0]));
              startDate.minutes(Number(startValues[1]));
              event.startDate = startDate.format("YYYY-MM-DDTHH:mm:ss")
            }

            var endTime = moment.isMoment(event.endTime) ? event.endTime : moment(event.endTime);
            if (endTime.isValid()) {
              if (date.isValid()) {
                endTime.year(date.year()).month(date.month()).date(date.date());
              }
              event.endDate = endTime.format("YYYY-MM-DDTHH:mm:ss");
            } else {
              var endValues = event.endTime.split(":");
              event.endDate = moment(event.date);
              event.endDate.hours(Number(endValues[0]));
              event.endDate.minutes(Number(endValues[1]));
              event.endDate = event.endDate.format("YYYY-MM-DDTHH:mm:ss");
            }

            if (this.state.questionsSpecific) {
              this.state.questionsSpecific.forEach((q) => {
                if (formData[q.label] || typeof formData[q.label] === "boolean" || formData[q.label] === 0) {
                  switch (q.type) {
                    case "number":
                      specific[q.label] = Number(formData[q.label]);
                      break;
                    default:
                      specific[q.label] = formData[q.label];
                  }
                } else {
                  if (q.required) {
                    throw (q.name + " is required").toString();
                  } else {
                    specific[q.label] = null;
                  }
                }
              });
            }

            event.specificInfos = specific;
            if (event.typeId === "Match tennis") {
              if (event.tournamentId && event.playerId) {
                loadEngagementsByPlayerByTournament(event.playerId, event.tournamentId).then(engagements => {
                  if (!engagements) {
                    return this.addEngagement(event.playerId, event.tournamentId);
                  } else {
                    return engagements;
                  }
                });
              }
            }
            var keepRecurrence = this.state.keepRecurrence && event.recurrenceId;
            if (this.props.method) {
              switch (this.props.method) {
                case "POST":
                  post("schedule", "events", event).then((res) => {
                    if (res.ok) {
                      cogoToast.success(this.props.t("Creation Successed"), { position: "bottom-right" });
                      return res.json()
                    } else {
                      throw new Error(res.status)
                    }
                  }).then(res => {
                    event.id = res.id;
                    if (event.typeId === "Physique") {
                      this.handleSequencesP(event)
                    }
                    else if (event.typeId === "Entrainement") {
                      this.handleSequencesE(event)
                    }
                    else {
                      this.props.nextStep(event);
                    }
                  }).catch(err => {
                    this.setState({ errors: [err.toString()], loading: false });
                  });
                  break;
                case "PATCH":
                  patch("schedule", keepRecurrence ? "eventsRecurrence" : "events", event).then((res) => {
                    if (res.ok) {
                      cogoToast.success(this.props.t("Update Successed"), { position: "bottom-right" });
                      return res.json()
                    } else {
                      throw new Error(res.status)
                    }
                  })
                    .then(res => {
                      if (event.typeId === "Physique") {
                        this.handleSequencesP(event)
                      }
                      else if (event.typeId === "Entrainement") {
                        this.handleSequencesE(event)
                      }
                      else {
                        this.props.nextStep(event);
                      }
                    })
                    .catch(err => {
                      this.setState({ errors: [err.toString()], loading: false });
                    });
                  break;
                default:
                  console.log("Error on method");
                  break;
              }
            }
          });
        } catch (err) {
          this.setState({ errors: [err.toString()], loading: false });
        }
      } else {
        this.schema
          .validate(formData, { abortEarly: false })
          .catch(function (err) {
            window.scrollTo(0, 0); // scroll component to 0.0
            return err;
          })
          .then((err) => {
            this.setState({ errors: err, loading: false });
          });
      }
    });
  }

  handleSequencesP(event) {
    this.setState({ loading: true });
    getUserProfile().then((profile) => {
      var sequences = this.state.sequences;
      loadPhysicalSequences(event.id).then((originalSequences) => {
        var index = originalSequences ? originalSequences.length : 1;
        var promises = [];
        sequences.forEach((seq) => {
          if (seq.physicalThemeId && seq.physicalThemeId !== "") {
            if (seq.id) {
              var original = originalSequences.find((o) => o.id === seq.id);
              if (original !== seq) {
                seq.eventId = event.id;
                seq.duration = parseInt(seq.duration);
                promises.push(patch("schedule", "physicalSequences", seq));
              }
            } else {
              seq.eventId = event.id;
              seq.duration = parseInt(seq.duration);
              seq.order = index;
              index++;
              promises.push(post("schedule", "physicalSequences", seq));
            }
          }
        });

        if (originalSequences) {
          originalSequences.forEach((o) => {
            if (!sequences.find((s) => s.id === o.id)) {
              promises.push(del("schedule", "physicalSequences", o));
            }
          });
        }

        if (promises && promises.length) {
          Promise.all(promises)
            .then((res) => {
              if (res) {
                var errors = [];
                res.forEach((r) => {
                  if (!r.ok) {
                    errors.push(r.status + " - " + r.url);
                  }
                });
                if (errors && errors.length) {
                  this.setState({ errors, loading: false });
                } else {
                  cogoToast.success(this.props.t("Sequences Successed"), {
                    position: "bottom-right",
                  });
                  this.setState({ loading: false });
                  this.props.nextStep(event);
                }
              } else {
                throw new Error("500");
              }
            })
            .catch((err) => {
              this.setState({ errors: [err.toString()], loading: false });
            });
        } else {
          this.setState({ loading: false });
          this.props.nextStep(event);
        }
      });
    });
  }

  handleSequencesE(event) {
    this.setState({ loading: true });
    getUserProfile().then((profile) => {
      var sequences = this.state.sequences;
      loadTrainingSequences(event.id).then((originalSequences) => {
        var index = originalSequences ? originalSequences.length : 1;
        var promises = [];
        sequences.forEach((seq) => {
          if (seq.trainingThemeId && seq.trainingThemeId !== "") {
            if (seq.id) {
              var original = originalSequences.find((o) => o.id === seq.id);
              if (original !== seq) {
                seq.eventId = event.id;
                seq.duration = parseInt(seq.duration);
                promises.push(patch("schedule", "trainingSequences", seq));
              }
            } else {
              seq.eventId = event.id;
              seq.duration = parseInt(seq.duration);
              seq.order = index;
              index++;
              promises.push(post("schedule", "trainingSequences", seq));
            }
          }
        });

        if (originalSequences) {
          originalSequences.forEach((o) => {
            if (!sequences.find((s) => s.id === o.id)) {
              promises.push(del("schedule", "trainingSequences", o));
            }
          });
        }

        if (promises && promises.length) {
          Promise.all(promises)
            .then((res) => {
              if (res) {
                var errors = [];
                res.forEach((r) => {
                  if (!r.ok) {
                    errors.push(r.status + " - " + r.url);
                  }
                });
                if (errors && errors.length) {
                  this.setState({ errors, loading: false });
                } else {
                  cogoToast.success(this.props.t("Sequences Successed"), {
                    position: "bottom-right",
                  });
                  this.setState({ loading: false });
                  this.props.nextStep(event);
                }
              } else {
                throw new Error("500");
              }
            })
            .catch((err) => {
              this.setState({ errors: [err.toString()], loading: false });
            });
        } else {
          this.setState({ loading: false });
          this.props.nextStep(event);
        }
      });
    });
  }

  handleDeleteE(row) {
    var sequences = this.state.sequences;
    if (sequences && sequences.find((seq) => seq === row)) {
      sequences = sequences.filter((seq) => seq !== row);
    }
    if (!sequences || !sequences.length) {
      sequences = [];
      var newSequence = {
        name: "",
        description: "",
        duration: 0,
        goals: "",
        instructions: "",
        trainingThemeId: "",
        trainingDrillId: "",
        organizerId: this.state.profile,
      };
      sequences.push(newSequence);
    }
    this.setState({ sequences });
  }

  handleAddE() {
    var sequences = this.state.sequences ? this.state.sequences : [];
    var newSequence = {
      name: "",
      description: "",
      duration: 0,
      goals: "",
      instructions: "",
      trainingThemeId: "",
      trainingDrillId: "",
      organizerId: this.state.profile,
    };
    sequences.push(newSequence);
    this.setState({ sequences });
  }

  handleCopyDrill(ex, t) {
    var sequences = this.state.sequences ? this.state.sequences : [];
    var newSequence = {
      name: ex.name,
      description: ex.description,
      duration: ex.duration,
      goals: ex.goals ? ex.goals : ex.targets,
      targets: ex.goals ? ex.goals : ex.targets,
      instructions: ex.instructions,
      physicalThemeId: ex.physicalThemeId,
      physicalCategoryId: ex.physicalTheme ? ex.physicalTheme.physicalCategory.id : null,
      trainingThemeId: ex.trainingThemeId,
      trainingCategoryId: ex.trainingTheme ? ex.trainingTheme.trainingCategory.id : null,
      physicalDrillId: ex.id,
      trainingDrillId: null,
      organizerId: this.state.profile,
    };
    sequences.push(newSequence);
    this.setState({ sequences });
    cogoToast.success(t("Inserted"))
  }

  handleCopySessionTemplate(row, t) {
    let sequences = this.state.sequences ? this.state.sequences : [];
    row.sequences.forEach((ex) => {
      let newSequence = {
        name: ex.name,
        description: ex.description,
        duration: ex.duration,
        goals: ex.goals ? ex.goals : ex.targets,
        targets: ex.goals ? ex.goals : ex.targets,
        instructions: ex.instructions,
        physicalThemeId: ex.physicalThemeId,
        physicalCategoryId: ex.physicalTheme ? ex.physicalTheme.physicalCategory.id : null,
        trainingThemeId: ex.trainingThemeId,
        trainingCategoryId: ex.trainingTheme ? ex.trainingTheme.trainingCategory.id : null,
        physicalDrillId: ex.id,
        trainingDrillId: null,
        organizerId: this.state.profile,
      };
      sequences.push(newSequence);
    })
    this.setState({ sequences });
    cogoToast.success(t("Inserted"))
  }

  handleDeleteP(row) {
    var sequences = this.state.sequences;
    if (sequences && sequences.find((seq) => seq === row)) {
      sequences = sequences.filter((seq) => seq !== row);
    }
    if (!sequences || !sequences.length) {
      sequences = [];
      var newSequence = {
        name: "",
        description: "",
        duration: 0,
        goals: "",
        instructions: "",
        physicalThemeId: "",
        organizerId: this.state.profile,
      };
      sequences.push(newSequence);
    }
    this.setState({ sequences });
  }

  handleAddP() {
    var sequences = this.state.sequences ? this.state.sequences : [];
    var newSequence = {
      name: "",
      description: "",
      duration: 0,
      goals: "",
      instructions: "",
      physicalThemeId: "",
      organizerId: this.state.profile,
    };
    sequences.push(newSequence);
    this.setState({ sequences });
  }

  render() {
    const { t } = this.props;
    var type = null;
    if (this.props.formData.typeId === "Entrainement")
      type = "training"
    else if (this.props.formData.typeId === "Physique")
      type = "physical"
    const columnsE = [
      {
        key: "name",
        label: t("Title"),
        id: "name",
        maxWidth: 200,
        format: (row) => (
          <Input
            className="form-control max-w-full rounded-md border-gray-200"
            value={row.name}
            onChange={(value) => {
              var sequences = Object.assign([], this.state.sequences);
              var index = sequences.findIndex((seq) => seq === row);
              var sequence = sequences[index];
              sequence.name = value;
              sequences[index] = sequence;
              this.setState({ sequences });
            }}
          />
        ),
      },
      {
        key: "trainingCategoryId",
        label: t("Category") + "*",
        format: (row) => (
          <Select
            value={row.trainingCategoryId}
            options={this.state.categories.map((cat) => {
              return { key: cat.id, label: cat.name };
            })}
            onChange={(cat) => {
              var sequences = Object.assign([], this.state.sequences);
              var index = sequences.findIndex((seq) => seq === row);
              var sequence = sequences[index];
              sequence.trainingCategoryId = cat;
              sequences[index] = sequence;
              this.setState({ sequences });
            }}
          />
        ),
      },
      {
        key: "trainingThemeId",
        label: t("Theme") + "*",
        width: 250,
        format: (row) =>
          this.state.themes &&
            row.trainingCategoryId &&
            this.state.themes[row.trainingCategoryId] &&
            this.state.themes[row.trainingCategoryId].length ? (
            <Select
              value={row.trainingThemeId}
              options={this.state.themes[row.trainingCategoryId].map((th) => {
                return { key: th.id, label: th.name };
              })}
              onChange={(th) => {
                var sequences = Object.assign([], this.state.sequences);
                var index = sequences.findIndex((seq) => seq === row);
                var sequence = sequences[index];
                sequence.trainingThemeId = th;
                sequences[index] = sequence;
                this.setState({ sequences });
              }}
            />
          ) : (
            "-"
          ),
      },
      {
        key: "duration",
        label: t("Duration"),
        width: 100,
        format: (row) => (
          <>
            <Input
              className="form-control w-full rounded-md border-gray-200"
              value={row.duration}
              onChange={(value) => {
                var sequences = Object.assign([], this.state.sequences);
                var index = sequences.findIndex((seq) => seq === row);
                var sequence = sequences[index];
                sequence.duration = value;
                sequences[index] = sequence;
                this.setState({ sequences });
              }}
            />
          </>
        ),
      },
      {
        key: "instructions",
        label: t("Intructions"),
        format: (row) => (
          <TextArea
            className="form-control w-full rounded-md border-gray-200 text-xs 2xl:text-sm"
            value={row.instructions}
            onChange={(value) => {
              var sequences = Object.assign([], this.state.sequences);
              var index = sequences.findIndex((seq) => seq === row);
              var sequence = sequences[index];
              sequence.instructions = value;
              sequences[index] = sequence;
              this.setState({ sequences });
            }}
          />
        ),
      },
      {
        key: "delete",
        maxWidth: 50,
        format: (row) => (
          <Button
            className="btn btn-sm btn-clean btn-icon mr-2"
            onClick={() => this.handleDeleteE(row)}
          >
            <svg className="h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
            </svg>
          </Button>
        ),
      },
    ];

    const columnsP = [
      {
        key: "name",
        label: t("Title"),
        id: "name",
        maxWidth: 200,
        format: (row) => (
          <Input
            className="form-control w-full rounded-md border-gray-200"
            value={row.name}
            onChange={(value) => {
              var sequences = Object.assign([], this.state.sequences);
              var index = sequences.findIndex((seq) => seq === row);
              var sequence = sequences[index];
              sequence.name = value;
              sequences[index] = sequence;
              this.setState({ sequences });
            }}
          />
        ),
      },
      {
        key: "physicalCategoryId",
        label: t("Category") + "*",
        format: (row) => (
          <Select
            value={row.physicalCategoryId}
            options={this.state.categories.map((cat) => {
              return { key: cat.id, label: cat.name };
            })}
            onChange={(cat) => {
              var sequences = Object.assign([], this.state.sequences);
              var index = sequences.findIndex((seq) => seq === row);
              var sequence = sequences[index];
              sequence.physicalCategoryId = cat;
              sequences[index] = sequence;
              this.setState({ sequences });
            }}
          />
        ),
      },
      {
        key: "physicalThemeId",
        label: t("Theme") + "*",
        width: 250,
        format: (row) =>
          this.state.themes &&
            row.physicalCategoryId &&
            this.state.themes[row.physicalCategoryId] &&
            this.state.themes[row.physicalCategoryId].length ? (
            <Select
              value={row.physicalThemeId}
              options={this.state.themes[row.physicalCategoryId].map((th) => {
                return { key: th.id, label: th.name };
              })}
              onChange={(th) => {
                var sequences = Object.assign([], this.state.sequences);
                var index = sequences.findIndex((seq) => seq === row);
                var sequence = sequences[index];
                sequence.physicalThemeId = th;
                sequences[index] = sequence;
                this.setState({ sequences });
              }}
            />
          ) : (
            "-"
          ),
      },
      {
        key: "duration",
        label: t("Duration"),
        maxWidth: 100,
        format: (row) => (
          <Input
            className="form-control w-full rounded-md border-gray-200"
            value={row.duration}
            onChange={(value) => {
              var sequences = Object.assign([], this.state.sequences);
              var index = sequences.findIndex((seq) => seq === row);
              var sequence = sequences[index];
              sequence.duration = value;
              sequences[index] = sequence;
              this.setState({ sequences });
            }}
          />
        ),
      },
      {
        key: "instructions",
        label: t("Intructions"),
        format: (row) => (
          <TextArea
            className="form-control w-full rounded-md border-gray-200 text-xs 2xl:text-sm"
            value={row.instructions}
            onChange={(value) => {
              var sequences = Object.assign([], this.state.sequences);
              var index = sequences.findIndex((seq) => seq === row);
              var sequence = sequences[index];
              sequence.instructions = value;
              sequences[index] = sequence;
              this.setState({ sequences });
            }}
          />
        ),
      },
      {
        key: "delete",
        maxWidth: 50,
        format: (row) => (
          <Button
            className="btn btn-sm btn-clean btn-icon mr-2"
            onClick={() => this.handleDeleteP(row)}
          >
            <svg className="h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
            </svg>
          </Button>
        ),
      },
    ];
    return (
      <>
        <Drawer.Header hide={this.props.hide}>
          {this.props.method === "POST" &&
            !(this.props.originalFormData && this.props.originalFormData.typeId) ? (
            <Button className="btn btn-primary mr-2"
              onClick={() => {
                this.props.goToStepChooseType();
              }}
            >
              <svg className="h-6 -mb-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
              </svg>
            </Button>
          ) : null}
          {t("Informations")}
        </Drawer.Header>
        <Drawer.Body>
          <>
            {this.state.loading ? <Loading /> :
              this.state.errors && this.state.errors.errors ? (
                <div className="alert alert-danger">
                  {t("Error")}
                  {this.state.errors.inner.map((error, key) => {
                    var q = this.state.questions.find(
                      (x) => x.label === error.path
                    );
                    if (q) {
                      return (
                        <div key={key}>
                          {t(q.title)} : {error.message}
                        </div>
                      );
                    } else {
                      return <div key={key}>{error.message}</div>;
                    }
                  })}
                </div>
              ) : this.state.errors ? <div className="alert alert-danger">
                {t("Error")}
                {this.state.errors.map((error, key) => {
                  return <div key={key}>{error}</div>;
                })}
              </div> : (
                ""
              )}
            <>
              <div className="text-lg leading-6 font-medium text-gray-900">
                {t("Main information")}
              </div>
              <div className="row mb-8">
                <div className="col-12">
                  {this.state.questionsGeneral ? (
                    <>
                      {this.state.questionsGeneral.filter(q => q.required).map((question, key) => {
                        return this.getQuestionRender(question, key);
                      })}
                    </>
                  ) : null}
                </div>
                <div className="col-12">
                  {this.state.questionsSpecific ? (
                    <>
                      {this.state.questionsSpecific.filter(q => q.required).map((question, key) => {
                        return this.getQuestionRender(question, key);
                      })}
                    </>
                  ) : null}
                </div>
              </div>
              <div className="border-b my-3 -mx-12"></div>
              <div className="flex justify-between w-full pr-4">
                <button className="inline-flex justify-center" onClick={() => this.setState({ openFacultativeField: !this.state.openFacultativeField })}>
                  <svg className="h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                  </svg>
                  <span className="text-lg leading-6 font-medium text-gray-900">
                    {t("Secondary information")}
                  </span>
                </button>
              </div>
              {this.state.openFacultativeField ?
                <div className="flex flex-col mb-8">
                  <div className="-my-0 overflow-x-auto sm:-mx-6 lg:-mx-0 h-full">
                    <div className="py-0 align-middle inline-block min-w-full sm:px-6 lg:px-0">
                      {this.state.questionsGeneral ? (
                        <>
                          {this.state.questionsGeneral.filter(q => !q.required).map((question, key) => {
                            return this.getQuestionRender(question, key);
                          })}
                        </>
                      ) : null}
                      {this.state.questionsSpecific ? (
                        <>
                          {this.state.questionsSpecific.filter(q => !q.required).map((question, key) => {
                            return this.getQuestionRender(question, key);
                          })}
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
                : null}

              {["Physique", "Entrainement"].includes(this.state.type) ?
                <>
                  <div className="border-b mt-3 -mx-12"></div>
                  <div className="mx-6 mb-8 mt-3">
                    <div className="mb-8 text-lg font-medium text-gray-900">
                      {t("Sequences")} :
                  </div>
                    <Table
                      columns={this.state.type === "Physique" ? columnsP : columnsE}
                      data={this.state.sequences}
                      height={500}
                      handleAdd={this.state.type === "Physique" ? this.handleAddP : this.handleAddE}
                    />
                    {type ?
                      <div className="w-full flex justify-center mt-8">
                        <Drawer.Layout
                          key="drill"
                          className="w-screen"
                          component={(show) => (
                            <ButtonLight
                              onClick={show}
                            >
                              <SVG src={SvgExo} /> {t("Insert drills")}
                            </ButtonLight>
                          )}
                        >
                          {(hide) => (
                            <>
                              <Drawer.Header hide={hide}>
                                {t("Inport drills into Session")}
                              </Drawer.Header>
                              <div className="mt-6 w-full mx-auto gap-8 ">
                                <Select
                                  options={this.state.groups}
                                  value={this.state.selectedGroup}
                                  className="mx-2"
                                  onChange={(res) => this.changeSelectedGroup(res, this.state.authors)}
                                  fixedLabel={t("Period")}
                                />
                              </div>
                              <div className="mt-6 w-full mx-auto grid gap-8 lg:grid-cols-3 lg:max-w-none p-6 mb-20">
                                {this.state.drills && this.state.drills.length > 0 ? this.state.drills.map((ex) =>
                                  <Button
                                    className="btn"
                                    onClick={() => this.handleCopyDrill(ex, t)}
                                  >
                                    <ExerciceCard
                                      exercice={ex}
                                      type={type}
                                      button={true}
                                    />
                                  </Button>
                                ) : t("No existing drills")}
                              </div>
                            </>
                          )}
                        </Drawer.Layout>
                        <Drawer.Layout
                          key="session"
                          className="w-screen"
                          component={(show) => (
                            <ButtonLight
                              onClick={show}
                            >
                              <SVG src={SvgExo} /> {t("Insert session template")}
                            </ButtonLight>
                          )}
                        >
                          {(hide) => (
                            <>
                              <Drawer.Header hide={hide}>
                                {t("Inport drills into Session")}
                              </Drawer.Header>
                              {this.state.sessionTemplates && this.state.sessionTemplates.length > 0 ?
                                <TableSessionTemplates
                                  sessions={this.state.sessionTemplates}
                                  groups={this.state.groups}
                                  type={type}
                                  button={true}
                                  handleAdd={(row) => this.handleCopySessionTemplate(row, t)}
                                  hide={hide}
                                />
                                : t("No existing session templates")}
                            </>
                          )}
                        </Drawer.Layout>
                      </div>
                      : ""}
                  </div>
                  <div className="border-b mb-3 mt-5 -mx-12"></div>
                </>
                : null}
              {this.state.formData && this.state.formData.recurrenceId ?
                <>
                  <div className="border-b mt-3 -mx-12"></div>
                  <Checkbox value={this.state.keepRecurrence} onChange={(keepRecurrence) => this.setState({ keepRecurrence })} title={t("Keep recurrence")} />
                </>
                : null}
              <Drawer.Footer>
                <Button
                  loading={this.state.loading}
                  onClick={() => {
                    this.handleSubmit();
                  }}
                >
                  {t("Next")}
                </Button>
              </Drawer.Footer>
            </>
          </>
        </Drawer.Body>
      </>
    );
  }
}

export default withTranslation()(StepInformation);
