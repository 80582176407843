import React from "react";
import moment from "moment";
import { withTranslation } from "react-i18next";
import { SelectMultiple } from "../components/forms";
import TableSimple from "../components/table/TableSimple";
import { Link } from "react-router-dom";
import IconRound32x32 from "../components/icons/IconRound32x32";
import SearchInput from "../components/forms/input/SearchInput";
import FiltersSearch from "../components/filters/FiltersSearch";
import FilterColumns from "../components/filters/FilterColumns";
import FilterSeparator from "../components/filters/FilterSeparator";
import FiltersTitle from "../components/filters/FiltersTitle";
import FiltersBar from "../components/filters/FiltersBar";

class TableLicenses extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actions: [],
      columns: [],
      searchText: "",
    };
  }

  componentDidMount() {
    this.prepareData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.prepareData();
    }
  }

  prepareData = () => {
    var licenses = this.props.licenses;
    const columns = [
      {
        key: "number",
        visible: true,
        pinned: "left",
        label: this.props.t("Number"),
        id: "number",
        format: (row) => row.number,
      },
      {
        key: "player.name",
        visible: true,
        label: this.props.t("Player"),
        pinned: "left",
        id: "displayName",
        format: (row) => {
          return (
              row.player ?
            <span className="flex justify-between">
              <Link
                className="text-indigo-700 font-medium pr-2"
                to={"/group/player/" + row.player.id}
              >
                <IconRound32x32 photo={row.player.photo} />
                <span className="ml-5">
                  {row.player.lastName.toUpperCase() + " " + row.player.firstName}
                </span>
              </Link>
            </span> : "" 
          );
        },
      },
      {
        key: "startDate",
        visible: true,
        pinned: "left",
        label: this.props.t("Start"),
        id: "startDate",
        format: (row) => moment(row.startDate).calendar(),
      },
      {
        key: "endDate",
        visible: true,
        pinned: "left",
        label: this.props.t("End"),
        id: "endDate",
        format: (row) => moment(row.endDate).calendar(),
      },
    ];
    var selectedColumns = [];
    columns.forEach((column) => {
      if (column.visible) {
        selectedColumns.push(column.key);
      }
    });
    this.setState({
      columns,
      licenses,
      selectedColumns
    });
  };

  render() {
    const { t } = this.props;
    var licenses = this.props.licenses;
    var columns = this.state.columns;
    var selectedColumns = this.state.selectedColumns;
    var searchText = this.state.searchText;
    if (columns) {
      columns = columns.filter((o) =>
        selectedColumns.some((item) => item === o.key)
      );
    }
    if (searchText.length > 2) {
      licenses = licenses.filter((o) =>
        o.player.lastName.toLowerCase().includes(searchText.toLowerCase()) || o.player.firstName.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    return (
      <>
        <FiltersBar
          components={
            <>
              <FiltersTitle title={licenses
                  ? licenses.length + " " + t("Licenses").toLowerCase()
                  : ""}
              />
              <FilterSeparator />
              <FiltersSearch search={<SearchInput
                value={this.state.searchText}
                onChange={(searchText) => this.setState({ searchText })}
                
              />} />
              <FilterSeparator />
              <FilterColumns
                columns={
                  <SelectMultiple
                    options={this.state.columns}
                    keys={this.state.selectedColumns}
                    className="mx-2"
                    onChange={(res) => this.setState({ selectedColumns: res })}
                    fixedLabel={t("Active columns")}
                  />
                }
              />
            </>
          }
          buttons={this.props.buttons}
        />
        <TableSimple columns={columns} data={licenses} />
      </>
    );
  }
}

export default withTranslation()(TableLicenses);
