import React from "react";
import ActionButton from "../components/buttons/ActionButton";
import IconRound32x32 from "../components/icons/IconRound32x32";
import { withTranslation } from "react-i18next";
import { SelectMultiple } from "../components/forms";
import TableSimple from "../components/table/TableSimple";
import { Link } from "react-router-dom";
import FiltersDropDowns from "../components/filters/FiltersDropDowns";
import FilterColumns from "../components/filters/FilterColumns";
import ExportToCSV from "../components/export/ExportToCSV";
import SearchInput from "../components/forms/input/SearchInput";
import FiltersSearch from "../components/filters/FiltersSearch";
import generateColor from "../utils/generateColor";
import FilterSeparator from "../components/filters/FilterSeparator";
import FiltersTitle from "../components/filters/FiltersTitle";
import FiltersBar from "../components/filters/FiltersBar";


class TableStaffs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actions: [],
      groups: [],
      selectedGroups: [],
      roles: [],
      selectedRoles: [],
      categories: [],
      selectedCategories: [],
      columns: [],
      searchText: "",
    };
  }

  componentDidMount() {
    this.prepareData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.prepareData();
    }
  }

  prepareData = () => {
    var staffs = this.props.staffs;
    var userId = this.props.userId;
    var categories = [];
    var groups = [];
    var roles = [];
    var selectedCategories = ["H", "F"];
    var selectedRoles = [];
    var selectedGroups = [];

    //  chercher les elements a déselectionner du localstorage
    var unselectedCategories = localStorage.getItem("TableStaff_unselectedCategories")
    if (unselectedCategories) unselectedCategories = unselectedCategories.split("|")
    
    // roles
    var unselectedRoles = localStorage.getItem("TableStaff_unselectedRoles")
    if (unselectedRoles) unselectedRoles = unselectedRoles.split("|")      

    // groups
    var unselectedGroups = localStorage.getItem("TableStaff_unselectedGroups")
    if (unselectedGroups) unselectedGroups = unselectedGroups.split("|")      
    
    
    if (staffs) {
      staffs = staffs.map((staff) => {
        if (staff.role && staff.role.category &&
          categories &&
          !categories.find((o) => o.key === staff.role.category.id)
        ) {
          categories.push({
            key: staff.role.category.id,
            label: staff.role.category.name,
          })
           // only add to selected if not unselected
           if (
            !unselectedCategories ||
            unselectedCategories.indexOf(staff.role.category.id.toString()) < 0
          )
          selectedCategories.push(staff.role.category.id);
        }
        if (staff.role && roles && !roles.find((o) => o.key === staff.role.id)) {
          roles.push({ key: staff.role.id, label: staff.role.name })
          // only add to selected if not unselected
          if (
            !unselectedRoles ||
            unselectedRoles.indexOf(staff.role.id.toString()) < 0
          )
          selectedRoles.push(staff.role.id);
        }
        if (staff.groupPrincipal && groups && !groups.find((o) => o.key === staff.groupPrincipal.id)) {
          groups.push({
            key: staff.groupPrincipal.id,
            label: staff.groupPrincipal.name,
          })
          // only add to selected if not unselected
          if (
            !unselectedGroups ||
            unselectedGroups.indexOf(staff.groupPrincipal.id.toString()) < 0
          )
          selectedGroups.push(staff.groupPrincipal.id);
        }
        return staff;
      });
    }

    const columns = [
      {
        key: "displayName",
        visible: true,
        label: this.props.t("Staff"),
        pinned: "left",
        id: "displayName",
        format: (row) => (
          <span className="flex justify-between">
            <Link className="text-indigo-700 font-medium"
              to={"/group/staff/" + row.id}>
              <IconRound32x32 photo={row.photo} />
              <span className="text-indigo-700 text-xs 2xl:text-sm font-medium ml-5">
                {row.lastName.toUpperCase() + " " + row.firstName}
              </span>
            </Link>
            <ActionButton
              key={row.id}
              updateForm={(this.props.isAdmin!=="false" || row.id!==userId) ? "updateStaff" : "updateMyStaffAccount"}
              deleteForm="deleteStaff"
              activeUpdateException={row.id===userId ? true : false}
              formData={row}
              refreshData={this.props.callback}
            />
          </span>
        ),
        width: 225,
      },
      {
        key: "groupLabel",
        visible: true,
        label: this.props.t("Group"),
        filter: {
          name: "SelectColumnFilter",
          type: "includes",
        },
        width: 250,
        format: (row) => (
          <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs 2xl:text-sm font-medium bg-indigo-100 text-indigo-800"  style={{color: "#FFFFFF", backgroundColor: generateColor(row.groupPrincipal.name)}}>
            {row.groupPrincipal ? row.groupPrincipal.name : "-"}
          </span>
        ),
      },
      {
        key: "categoryLabel",
        visible: true,
        label: this.props.t("Category"),
        filter: {
          name: "SelectColumnFilter",
          type: "includes",
        },
        width: 200,
        format: (row) => (
          <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs 2xl:text-sm font-medium bg-yellow-100 text-yellow-800"  style={{color: "#FFFFFF", backgroundColor: generateColor(row.role.category.name)}}>
            {row.role && row.role.category ? row.role.category.name : "-"}
          </span>
        ),
      },
      {
        key: "title",
        visible: true,
        label: this.props.t("Title"),
        id: "title",
        width: 250,
        format: (row) => (
          <span className="font-weight-bold text-muted text-xs 2xl:text-sm ">{row.title}</span>
        ),
      },
      {
        key: "roleLabel",
        visible: true,
        label: this.props.t("Role"),
        filter: {
          name: "SelectColumnFilter",
          type: "includes",
        },
        format: (row) => (
          <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs 2xl:text-sm font-medium bg-pink-100 text-pink-800"  style={{color: "#FFFFFF", backgroundColor: generateColor(row.role.name)}}>
            {row.role ? row.role.name : "-"}
          </span>
        ),
      },
      {
        key: "mobile",
        visible: true,
        id: "mobile",
        label: this.props.t("Mobile"),
        canFilter: false,
        width: 150,
        format: (row) => (
          <span className="font-weight-bold text-muted text-xs 2xl:text-sm ">{row.mobile}</span>
        ),
      },
      {
        key: "email",
        visible: true,
        id: "email",
        label: this.props.t("Email"),
        width: 225,
        format: (row) => (
          <span className="font-weight-bold text-muted text-xs 2xl:text-sm ">{row.email}</span>
        ),
      },
    ];

    var selectedColumns = localStorage.getItem("TableStaff_selectedColumns")
    if (selectedColumns === null || selectedColumns.length === 0)  selectedColumns = ["displayName", "groupLabel", "categoryLabel", "roleLabel", "title", "mobile", "email" ]
    else selectedColumns = selectedColumns.split("|")
    columns.forEach((column) => {
      column.visible = selectedColumns.indexOf(column.key) < 0 ? false : true 
    });
    this.setState({
      columns,
      staffs,
      groups,
      roles,
      categories,
      selectedColumns,
      selectedGroups,
      selectedRoles,
      selectedCategories,
    });
  };

  // change categories
  onChangeSelectedCategories = (res) => {
    this.setState({ selectedCategories: res }, () => {
      // determiner ce qui n'est pas selectionner
      let unselect = []
      this.state.categories.forEach((a) => {
        if (this.state.selectedCategories.indexOf(a.key) < 0) unselect.push(a.key)
      })
      // save unselected elements to localstorage
      localStorage.setItem("TableStaff_unselectedCategories", unselect.join("|"))
    })
  }

  // change roles
  onChangeSelectedRoles = (res) => {
    this.setState({ selectedRoles: res }, () => {
      // determiner ce qui n'est pas selectionner
      let unselect = []
      this.state.roles.forEach((a) => {
        if (this.state.selectedRoles.indexOf(a.key) < 0) unselect.push(a.key)
      })
      // save unselected elements to localstorage
      localStorage.setItem("TableStaff_unselectedRoles", unselect.join("|"))
    })
  }

  // change groups
  onChangeSelectedGroups = (res) => {
      this.setState({ selectedGroups: res }, () => {
        // determiner ce qui est selectionne
        let select = []
        this.state.groups.forEach((a) => {
          if (this.state.selectedGroups.indexOf(a.key) < 0 ) select.push(a.key)
        })
        // save selected elements to localstorage
        localStorage.setItem("TableStaff_unselectedGroups", select.join("|"))
      })
    }

  // change columns
  onChangeSelectedColumns = (res) => {
    this.setState({ selectedColumns: res }, () => {
      // determiner ce qui est selectionne
      let select = []
      this.state.columns.forEach((a) => {
        if (this.state.selectedColumns.indexOf(a.key) > -1 ) select.push(a.key)
      })
      // save selected elements to localstorage
      localStorage.setItem("TableStaff_selectedColumns", select.join("|"))
    })
  }     

  render() {
    const { t } = this.props;
    var staffs = this.props.staffs;
    var columns = this.state.columns;
    var selectedColumns = this.state.selectedColumns;
    var selectedRoles = this.state.selectedRoles;
    var selectedGroups = this.state.selectedGroups;
    var selectedCategories = this.state.selectedCategories;
    var searchText = this.state.searchText;
    if (columns && columns.length) {
      columns = columns.filter((o) =>
        selectedColumns.some((item) => item === o.key)
      );
    }
    if (staffs && staffs.length) {
      staffs = staffs.filter((o) => o.role &&
        selectedRoles.some((item) => item === o.role.id)
      );
      staffs = staffs.filter((o) =>
        selectedGroups.some((item) => item === o.groupPrincipal.id)
      );
      staffs = staffs.filter((o) =>
        o.role && o.role.category ? selectedCategories.some((item) => item === o.role.category.id) : false
      );
      if (searchText.length > 2) {
        staffs = staffs.filter(
          (o) =>
            o.lastName.toLowerCase().includes(searchText.toLowerCase()) ||
            o.firstName.toLowerCase().includes(searchText.toLowerCase())
        );
      }
    }

    return (
      <>
        <FiltersBar
          components={
            <>
              <FiltersTitle title={staffs ? staffs.length + " " + t("Staffs").toLowerCase() : ""} />
              <FilterSeparator />
              <FiltersSearch search={
                <SearchInput
                  value={this.state.searchText}
                  onChange={(searchText) => this.setState({ searchText })}    
                />
                } 
              />
              <FilterSeparator />
              <FiltersDropDowns selects={[
                <SelectMultiple
                  options={this.state.groups}
                  keys={this.state.selectedGroups}
                  className="mx-2"
                  onChange={this.onChangeSelectedGroups}
                  fixedLabel={t("Group")}
                />,
                <SelectMultiple
                  options={this.state.categories}
                  keys={this.state.selectedCategories}
                  className="mx-2"
                  onChange={this.onChangeSelectedCategories}
                  fixedLabel={t("Categories")}
                />,
                <SelectMultiple
                  options={this.state.roles}
                  keys={this.state.selectedRoles}
                  className="mx-2"
                  onChange={this.onChangeSelectedRoles}
                  fixedLabel={t("Roles")}
                />
                ]} 
              />
              <FilterSeparator />
              <FilterColumns
                columns={
                  <SelectMultiple
                    options={this.state.columns}
                    keys={this.state.selectedColumns}
                    className="mx-2"
                    onChange={this.onChangeSelectedColumns}
                    fixedLabel={t("Active columns")}
                  />
                }
              />
              <FilterSeparator />
              <ExportToCSV columns={columns} jsonData={staffs} fileName={t("Staffs") + ".csv"} />
            </>
          }
          buttons={this.props.buttons}
        />
        <TableSimple columns={columns} data={staffs} />
      </>
    );
  }
}

export default withTranslation()(TableStaffs);
