import db from "./db";
import { post } from "../../requests/index";
import get from "../../requests/get";
import moment from "moment";
import cogoToast from "cogo-toast";

async function loadTournamentById(id) {
  return get("competitionTennis", "tennisNationalTournaments", id).then(tournament => {
    if (tournament && tournament.id) {
      return tournament;
    } else {
      return get("competitionTennis", "tennisInternationalTournaments", id)
    }
  })
}

async function loadNationalCompetition(month, year) {
  if (month === "0") {
    return get(
      "competitionTennis",
      "tennisNationalTournamentsByYear",
      year
    ).then((res) => {
      if (res) {
        res = res.map((comp) => {
          comp.tId = comp.id;
          comp.kind = "national" /*+ month + year*/;
          comp.date =
            moment(comp.startDate).format("D MMM") +
            " - " +
            moment(comp.endDate).format("D MMM");
          comp.condition = (comp.ground ? comp.ground.name : "N/A")
            + (comp.conditions ? " (" + comp.conditions.name + ")" : " (N/A)");
          //comp.id = comp.id /*+ month + year*/;
          return comp;
        });
        db.competition.bulkPut(res);
        res = res.sort((a, b) =>
          moment(a.startDate).isSame(b.startDate)
            ? 0
            : moment(a.startDate).isAfter(b.startDate)
              ? 1
              : -1
        );
      }

      return res;
    });
  }
  return get(
    "competitionTennis",
    "tournamentsNationalByMonth",
    year + "/" + month
  ).then((res) => {
    if (res) {
      res = res.map((comp) => {
        comp.tId = comp.id;
        comp.kind = "national" //+ month + year;
        comp.date =
          moment(comp.startDate).format("D MMM") +
          " - " +
          moment(comp.endDate).format("D MMM");
        comp.condition = (comp.ground ? comp.ground.name : "N/A")
          + (comp.conditions ? " (" + comp.conditions.name + ")" : " (N/A)");
        //comp.id = comp.id + month + year;
        return comp;
      });
      res = res.sort((a, b) =>
        moment(a.startDate).isSame(b.startDate)
          ? 0
          : moment(a.startDate).isAfter(b.startDate)
            ? 1
            : -1
      );
      db.competition.bulkPut(res);
    }
    return res;
  });
}

async function loadNationalCompetitionById(id) {
  return get(
    "competitionTennis",
    "tennisNationalTournaments",
    id
  ).then((res) => {
    if (res) {
      res.tId = res.id;
      res.kind = "national";
      res.date =
        moment(res.startDate).format("D MMM") +
        " - " +
        moment(res.endDate).format("D MMM");
      res.condition = (res.ground ? res.ground.name : "N/A")
        + (res.conditions ? " (" + res.conditions.name + ")" : " (N/A)");
      db.competition.put(res);
    }
    return res;
  })
}

async function loadInternationalCompetition(month, year) {
  if (month === "0") {
    return get(
      "competitionTennis",
      "tennisInternationalTournamentsByYear",
      year
    ).then((res) => {
      if (res) {
        res = res.map((comp) => {
          comp.tId = comp.id;
          comp.kind = "international" //+ month + year;
          // comp.date =
          //   moment(comp.startDate).format("D MMM") +
          //   " - " +
          //   moment(comp.endDate).format("D MMM");
          comp.localisation = comp.city + " (" + comp.country + ")";
          comp.badgeColor = comp.circuit.color1;
          comp.condition = (comp.ground ? comp.ground.name : "N/A")
            + (comp.conditions ? " (" + comp.conditions.name + ")" : " (N/A)");
          //comp.id = comp.id + month + year;
          return comp;
        });
        res = res.sort((a, b) =>
          moment(a.startDate).isSame(b.startDate)
            ? 0
            : moment(a.startDate).isAfter(b.startDate)
              ? 1
              : -1
        );
        db.competition.bulkPut(res);
      }
      return res;
    });
  }
  return get(
    "competitionTennis",
    "tournamentsInternationalByMonth",
    year + "/" + month
  ).then((res) => {
    if (res) {
      res = res.map((comp) => {
        comp.tId = comp.id;
        comp.kind = "international"
        comp.date =
          moment(comp.startDate).format("D MMM") +
          " - " +
          moment(comp.endDate).format("D MMM");
        comp.localisation = comp.city + " (" + comp.country + ")";
        comp.badgeColor = comp.circuit.color1;
        comp.condition = (comp.ground ? comp.ground.name : "N/A")
          + (comp.conditions ? " (" + comp.conditions.name + ")" : " (N/A)");
        return comp;
      });
      res = res.sort((a, b) =>
        moment(a.startDate).isSame(b.startDate)
          ? 0
          : moment(a.startDate).isAfter(b.startDate)
            ? 1
            : -1
      );
      db.competition.bulkPut(res);
    }
    return res;
  });
}

async function loadInternationalCompetitionById(id) {
  return get(
    "competitionTennis",
    "tennisInternationalTournaments",
    id
  ).then((res) => {
    if (res) {
      res.tId = res.id;
      res.kind = "international"
      res.date =
        moment(res.startDate).format("D MMM") +
        " - " +
        moment(res.endDate).format("D MMM");
      res.localisation = res.city + " (" + res.country + ")";
      res.badgeColor = res.circuit.color1;
      res.condition = (res.ground ? res.ground.name : "N/A")
        + (res.conditions ? " (" + res.conditions.name + ")" : " (N/A)");
      db.competition.put(res);
    }
    return res;
  })
}

async function loadResults(playerId) {
  return get(
    "competitionTennis",
    "tournamentsResultsByPlayer",
    playerId
  ).then((res) => {
    if (res) {
      res = res.map((comp) => {
        comp.id = playerId + "-" + comp.tournamentId + "-" + comp.mode;
        comp.kind = playerId;
        comp.startDate =
          moment(comp.tournament.startDate).format("YYYY-MM-DD");
        comp.endDate =
          moment(comp.tournament.endDate).format("YYYY-MM-DD");
        comp.date =
          moment(comp.tournament.startDate).format("D MMM YY") +
          " - " +
          moment(comp.tournament.endDate).format("D MMM YY");
        comp.tmax = comp.tourReached.name;
        comp.name = comp.tournament.name;
        comp.cname = comp.tournament.circuit.name;
        comp.location =
          comp.tournament.city + " (" + comp.tournament.country + ")";
        comp.surface =
          comp.tournament.ground.name +
          " (" +
          comp.tournament.conditions.name +
          ")";
        return comp;
      });
      db.results.bulkPut(res);
    }
    if (res) {
      res = res.sort((a, b) =>
        moment(a.tournament.startDate).isSame(b.tournament.startDate)
          ? 0
          : moment(a.tournament.startDate).isAfter(b.tournament.startDate)
            ? -1
            : 1
      );
    }

    return res;
  });
}

async function loadResultsByPlayers(playersIds) {
  return post(
    "competitionTennis",
    "tournamentsResultsByPlayers",
    {
      playersIds: playersIds
    }
  ).then(res => {return res.json()}).then((res) => {
    if (res) {
      res = res.map((comp) => {
        comp.id = comp.playerId + "-" + comp.tournamentId + "-" + comp.mode;
        comp.kind = comp.playerId;
        comp.date =
          moment(comp.tournament.startDate).format("D MMM YY") +
          " - " +
          moment(comp.tournament.endDate).format("D MMM YY");
        comp.tmax = comp.tourReached.name;
        comp.name = comp.tournament.name;
        comp.cname = comp.tournament.circuit.name;
        comp.location =
          comp.tournament.city + " (" + comp.tournament.country + ")";
        comp.surface =
          comp.tournament.ground.name +
          " (" +
          comp.tournament.conditions.name +
          ")";
        return comp;
      });
      db.results.bulkPut(res);
    }
    if (res) {
      res = res.sort((a, b) =>
        moment(a.tournament.startDate).isSame(b.tournament.startDate)
          ? 0
          : moment(a.tournament.startDate).isAfter(b.tournament.startDate)
            ? -1
            : 1
      );
    }

    return res;
  });
}

async function loadMatches(playerId) {
  return get(
    "competitionTennis",
    "tournamentsWithMatchesByPlayer",
    playerId
  ).then((res) => {
    if (res) {
      res = res.map((comp) => {
        comp.kind = playerId;
        return comp;
      });
      db.matches.bulkPut(res);
    }
    if (res) {
      res = res.sort((a, b) =>
        moment(a.startDate) === moment(b.startDate)
          ? 0
          : moment(a.startDate) > moment(b.startDate)
            ? -1
            : 1
      );
    }
    return res;
  });
}

async function loadMatchesByPlayers(playersIds, startDate, endDate) {
  return post(
    "competitionTennis",
    "tournamentsWithMatchesByPlayers",
    {
      playersIds: playersIds,
      startDate: startDate,
      endDate: endDate
    }
  ).then(res => {return res.json()}).then((res) => {
    if (res) {
      res = res.map((comp) => {
        comp.kind = comp.playerId;
        return comp;
      });
      db.matches.bulkPut(res);
    }
    if (res) {
      res = res.sort((a, b) =>
        moment(a.startDate) === moment(b.startDate)
          ? 0
          : moment(a.startDate) > moment(b.startDate)
            ? -1
            : 1
      );
    }
    return res;
  });
}

async function loadMatchesByDates(playerId, start, end) {
  return get(
    "competitionTennis",
    "tournamentsWithMatchesByPlayer",
    playerId + "/" + start + "/" + end
  ).then((res) => {
    if (res) {
      res = res.map((comp) => {
        comp.kind = playerId;
        return comp;
      });
      db.matches.bulkPut(res);
      res = res.sort((a, b) =>
        moment(a.startDate) === moment(b.startDate)
          ? 0
          : moment(a.startDate) > moment(b.startDate)
            ? -1
            : 1
      );
    }
    return res;
  });
}

async function loadMatchesByDatesAndPlayers(playersIds, start, end) {
  return post(
    "competitionTennis",
    "tournamentsWithMatchesByPlayers",
    {
    playersIds: playersIds, startDate: start, endDate : end
    }
  ).then(res => {if (res.ok) { return res.json() } else {return null}}).then((res) => {
    if (res) {
      res = res.map((comp) => {
        comp.kind = "";
        return comp;
      });
      db.matches.bulkPut(res);
      res = res.sort((a, b) =>
        moment(a.startDate) === moment(b.startDate)
          ? 0
          : moment(a.startDate) > moment(b.startDate)
            ? -1
            : 1
      );
    }
    return res;
  });
}

async function loadMatch(matchId) {
  return get("competitionTennis", "tennisMatches", matchId).then((res) => {
    if (res) {
      db.match.put(res);
    }
    return res;
  });
}

async function loadMatchStatistics(matchId) {
  return get("competitionTennis", "matchStatisticsByMatch", matchId).then(
    (res) => {
      if (res && res.id) {
        db.matchStatistics.put(res);
      }
      return res;
    }
  );
}

async function loadSupervision(staffId) {
  return get("competitionTennis", "supervisionsByStaff", staffId).then(
    (res) => {
      if (res) {
        // res = res.filter((o) =>
        //   moment(o.tournament.startDate).isAfter(moment())
        // );
        if (res) {
          res = res.map((prog) => {
            prog.date =
              moment(prog.tournament.startDate).format("D MMM YY") +
              " - " +
              moment(prog.tournament.endDate).format("D MMM YY");
            prog.name = prog.tournament.name;
            prog.cname = prog.tournament.circuit.name;
            prog.location =
              prog.tournament.city + " (" + prog.tournament.country + ")";
            prog.kind = staffId;
            prog.link = prog.tournament.circuit.id;
            prog.color1 = prog.tournament.circuit.color1;
            prog.color2 = prog.tournament.circuit.color2;
            prog.classification = prog.tournament.classification ? prog.tournament.classification : "N/A";
            prog.bold = moment(prog.tournament.startDate).isAfter(moment());
            prog.normal = moment(prog.tournament.endDate).isAfter(moment());
            return prog;
          });
          db.supervision.bulkPut(res);
        }
      }
      if (res) {
        res = res.sort((a, b) =>
          moment(a.tournament.startDate).isSame(b.tournament.startDate)
            ? 0
            : moment(a.tournament.startDate).isAfter(b.tournament.startDate)
              ? -1
              : 1
        );
      }
      return res;
    }
  );
}

async function addSupervision(staffId, tournamentId) {
  return post("competitionTennis", "supervisions", {
    staffId,
    tournamentId,
  }).then((res) => {
    cogoToast.success(this.props.t("Supervision added"), { position: "bottom-right" });
    // this.props.callback(res);
    // hide();
  });
}

async function loadProgrammation(playerId, option) {
  var endpoint = "EngagementsByPlayer"
  if (option === 1) endpoint = "engagementsOption1ByPlayer"
  else if (option === 2) endpoint = "engagementsOption2ByPlayer"
  return get("competitionTennis", endpoint, playerId).then(
    (res) => {
      if (res) {
        res = res.map((prog) => {
          if(prog.id==="f4bd852b-219a-42e8-be57-6ba39d9e6050")
          prog.date =
            moment(prog.tournament.startDate).format("D MMM YY") +
            " - " +
            moment(prog.tournament.endDate).format("D MMM YY");
          prog.name = prog.tournament.name;
          prog.cname = prog.tournament.circuit.name;
          prog.location =
            prog.tournament.city + " (" + prog.tournament.country + ")";
          prog.kind = prog.playerId;
          prog.link = prog.tournament.circuit.id;
          prog.color1 = prog.tournament.circuit.color1;
          prog.color2 = prog.tournament.circuit.color2;
          prog.classification = prog.tournament.classification ? prog.tournament.classification : "N/A";
          prog.bold = moment(prog.tournament.startDate).isAfter(moment());
          prog.normal = moment(prog.tournament.endDate).isAfter(moment());
          return prog;
        });
        if (option === 0) db.programmation.bulkPut(res);
        else if (option === 1) db.programmationOption1.bulkPut(res);
        else if (option === 2) db.programmationOption2.bulkPut(res);
        else db.programmation.bulkPut(res);

      }
      if (res) {
        // res = res.filter((o) =>
        //   moment(o.tournament.startDate).isAfter(moment())
        // );
        if (res) {
          res = res.sort((a, b) =>
            moment(a.tournament.startDate).isSame(b.tournament.startDate)
              ? 0
              : moment(a.tournament.startDate).isAfter(b.tournament.startDate)
                ? -1
                : 1
          );
        }
      }
      return res;
    }
  );
}

async function addEngagement(playerId, tournamentId, option) {
  var endpoint = "engagements"
  var message = "Programmation added"
  if (option === 1) {
    endpoint = "engagementsOption1"
    message = "Programmation added to Option 1"
  }
  else if (option === 2) {
    endpoint = "engagementsOption2"
    message = "Programmation added to Option 2"
  }
  return post("competitionTennis", endpoint, {
    playerId,
    tournamentId,
  }).then((res) => {
    cogoToast.success(this.props.t(message), { position: "bottom-right" });
    // this.props.callback(res);
    // hide();
  }).catch(error => {
    cogoToast.success("Error : addEngagement - " + error, { position: "bottom-right" });
  });
}

async function loadInternationalProgrammationRemaining(month, year, playerId, option) {
  return getHistoricalProgrammation(playerId, option).then((prog) => {
    return loadInternationalCompetition(month, year).then((res) => {
      if(res) res.map((tournament) => {
        if (prog.find((p) => p.tournamentId === tournament.tId)) {
          tournament.add = false;
        } else tournament.add = true;
        return tournament;
      });
      return res;
    });
  });
}

async function loadNationalProgrammationRemaining(month, year, playerId, option) {
  return getHistoricalProgrammation(playerId, option).then((prog) => {
    return loadNationalCompetition(month, year).then((res) => {
      if(res) res.map((tournament) => {
        if (prog.find((p) => p.tournamentId === tournament.tId)) {
          tournament.add = false;
        } else tournament.add = true;
        return tournament;
      });
      return res;
    });
  });
}

async function loadInternationalSupervisionRemaining(month, year, staffId) {
  return getSupervision(staffId).then((sup) => {
    return loadInternationalCompetition(month, year).then((res) => {
      if(res) res.map((tournament) => {
        if (sup.find((p) => p.tournamentId === tournament.tId)) {
          tournament.add = false;
        } else tournament.add = true;
        return tournament;
      });
      return res;
    });
  });
}

async function loadNationalSupervisionRemaining(month, year, staffId) {
  return getSupervision(staffId).then((sup) => {
    return loadNationalCompetition(month, year).then((res) => {
      if(res) res.map((tournament) => {
        if (sup.find((p) => p.tournamentId === tournament.tId)) {
          tournament.add = false;
        } else tournament.add = true;
        return tournament;
      });
      return res;
    });
  });
}

async function loadRanking(date, kind) {
  return get(
    "competitionTennis",
    "rankings" + kind + "ByDate",
    date
  ).then((res) => {
    if (res) {
      if (date)
        res = res.map((rank) => {
          rank.yob = moment(rank.player.dob).format("YYYY")
          rank.name = rank.player.firstName + " " + rank.player.lastName;
          rank.age = rank.player.dob ? (moment().diff(rank.player.dob, "days") / 365).toFixed(1) : 0;
          return rank;
        });
      res.id = kind + date + "";
      db.ranking.put(res);
    }
    return res.sort((a, b) =>
      a.rank === b.rank ? 0 : a.rank > b.rank ? 1 : -1
    );
  });
}

async function loadRankingDates(kind) {
  return get("competitionTennis", "rankings" + kind + "Dates").then(
    (res) => {
      if (res) {
        res.id = kind;
        db.rankingDates.put(res);
      }
      return res;
    }
  );
}

async function loadRankingNational(kind, players) {
  return get("competitionTennis", "rankings" + kind).then((res) => {
    if (res) {
      res = res.sort((a, b) =>
        a.level === b.level ? 0 : a.level > b.level ? -1 : 1
      );
      res = res.map((rank) => {
        let player = players.find((player) => {
          return player.id === rank.playerId;
        });
        if (player) {
          rank.yob = moment(player.dob).format("YYYY")
          rank.displayName = player.displayName;
          rank.age = (moment().diff(player.dob, "days") / 365).toFixed(1);
          rank.photo = player.photo;
        }
        rank.s = rank.serie.name;
        return rank;
      });
      res.id = kind;
      db.ranking.put(res);
    }
    return res;
  });
}

function getNationalCompetition(month, year) {
  if (month === "0")
    return db.competition
      .where({ kind: "national" })
      .toArray()
      .then((national) => {
        return national.filter((n) => {
          return (
            parseInt(moment(n.startDate).format("YYYY")) <= parseInt(year) &&
            parseInt(moment(n.endDate).format("YYYY")) >= parseInt(year)
          );
        }).then((res) => {
          if (res) {
            res = res.sort((a, b) =>
              moment(a.startDate).isSame(b.startDate)
                ? 0
                : moment(a.startDate).isAfter(b.startDate)
                  ? 1
                  : -1
            );
            return res;
          }
        });
      });
  else
    return db.competition
      .where({ kind: "national" })
      .toArray()
      .then((national) => {
        return national.filter((n) => {
          return (
            (parseInt(moment(n.startDate).format("YYYY")) < parseInt(year) &&
              parseInt(moment(n.endDate).format("YYYY")) > parseInt(year)) ||

            (parseInt(moment(n.startDate).format("YYYY")) === parseInt(year) &&
              parseInt(moment(n.startDate).format("MM")) <= parseInt(month) &&
              parseInt(moment(n.endDate).format("MM")) >= parseInt(month) &&
              parseInt(moment(n.endDate).format("YYYY")) === parseInt(year)) ||

            (parseInt(moment(n.startDate).format("YYYY")) < parseInt(year) &&
              parseInt(moment(n.endDate).format("MM")) >= parseInt(month) &&
              parseInt(moment(n.endDate).format("YYYY")) === parseInt(year)) ||

            (parseInt(moment(n.startDate).format("YYYY")) === parseInt(year) &&
              parseInt(moment(n.startDate).format("MM")) <= parseInt(month) &&
              parseInt(moment(n.endDate).format("YYYY")) > parseInt(year))
          );
        })
      }).then((res) => {
        if (res) {
          res = res.sort((a, b) =>
            moment(a.startDate).isSame(b.startDate)
              ? 0
              : moment(a.startDate).isAfter(b.startDate)
                ? 1
                : -1
          );
          return res;
        };
      });
}

function getInternationalCompetition(month, year) {
  if (month === "0")
    return db.competition
      .where({ kind: "international" })
      .toArray()
      .then((national) => {
        return national.filter((n) => {
          return (
            parseInt(moment(n.startDate).format("YYYY")) <= parseInt(year) &&
            parseInt(moment(n.endDate).format("YYYY")) >= parseInt(year)
          );
        });
      }).then((res) => {
        if (res) {
          res = res.sort((a, b) =>
            moment(a.startDate).isSame(b.startDate)
              ? 0
              : moment(a.startDate).isAfter(b.startDate)
                ? 1
                : -1
          );
          return res;
        };
      });

  else
    return db.competition
      .where({ kind: "international" })
      .toArray()
      .then((national) => {
        return national.filter((n) => {
          return (
            (parseInt(moment(n.startDate).format("YYYY")) < parseInt(year) &&
              parseInt(moment(n.endDate).format("YYYY")) > parseInt(year)) ||
            (parseInt(moment(n.startDate).format("YYYY")) === parseInt(year) &&
              parseInt(moment(n.startDate).format("MM")) <= parseInt(month) &&
              parseInt(moment(n.endDate).format("MM")) >= parseInt(month) &&
              parseInt(moment(n.endDate).format("YYYY")) === parseInt(year)) ||
            (parseInt(moment(n.startDate).format("YYYY")) < parseInt(year) &&
              parseInt(moment(n.endDate).format("MM")) >= parseInt(month) &&
              parseInt(moment(n.endDate).format("YYYY")) === parseInt(year)) ||
            (parseInt(moment(n.startDate).format("YYYY")) === parseInt(year) &&
              parseInt(moment(n.startDate).format("MM")) <= parseInt(month) &&
              parseInt(moment(n.endDate).format("YYYY")) > parseInt(year))
          );
        });
      }).then((res) => {
        if (res) {
          res = res.sort((a, b) =>
            moment(a.startDate).isSame(b.startDate)
              ? 0
              : moment(a.startDate).isAfter(b.startDate)
                ? 1
                : -1
          );
          return res;
        };
      });
}

function getCompetitionById(id) {
  // if (month === "0")
  return db.competition.get(id)
}

function getResults(playerId) {
  return db.results
    .where({ kind: playerId })
    .toArray()
    .then((res) => {
      return res.sort((a, b) =>
        moment(a.tournament.startDate).isSame(b.tournament.startDate)
          ? 0
          : moment(a.tournament.startDate).isAfter(b.tournament.startDate)
            ? -1
            : 1
      );
    });
}

function getMatches(playerId) {
  return db.matches
    .where({ kind: playerId })
    .toArray()
    .then((matches) => {
      return matches.sort((a, b) =>
        moment(a.startDate) === moment(b.startDate)
          ? 0
          : moment(a.startDate) > moment(b.startDate)
            ? -1
            : 1
      );
    });
}

function getMatchesByDates(playerId, startDate, endDate) {
  return db.matches
    .where({ kind: playerId })
    .and(
      (x) =>
        (moment(x.startDate).isAfter(startDate) &&
        moment(x.startDate).isBefore(endDate)) ||
        (moment(x.endDate).isAfter(startDate) &&
        moment(x.endDate).isBefore(endDate)) ||
        (moment(x.startDate).isBefore(startDate) &&
        moment(x.endDate).isAfter(endDate))
    )
    .toArray()
    .then((matches) => {
      return matches.sort((a, b) =>
        moment(a.startDate) === moment(b.startDate)
          ? 0
          : moment(a.startDate) > moment(b.startDate)
            ? -1
            : 1
      );
    });
}

function getMatch(id) {
  return db.match.get(id);
}

function getMatchStatistics(matchId) {
  return db.matchStatistics.where({ eventId: matchId }).toArray();
}

async function getProgrammation(playerId, option) {
  return option === 0 ? db.programmation
    .where({ kind: playerId })
    .toArray()
    .then((prog) => {
      // prog = prog.filter((o) =>
      //   moment(o.tournament.startDate).isAfter(moment())
      // );
      return prog.sort((a, b) =>
        moment(a.tournament.startDate).isSame(b.tournament.startDate)
          ? 0
          : moment(a.tournament.startDate).isAfter(b.tournament.startDate)
            ? -1
            : 1
      );
    })
    : option === 1 ? db.programmationOption1
      .where({ kind: playerId })
      .toArray()
      .then((prog) => {
        // prog = prog.filter((o) =>
        //   moment(o.tournament.startDate).isAfter(moment())
        // );
        return prog.sort((a, b) =>
          moment(a.tournament.startDate).isSame(b.tournament.startDate)
            ? 0
            : moment(a.tournament.startDate).isAfter(b.tournament.startDate)
              ? -1
              : 1
        );
      })
      : option === 2 ? db.programmationOption2
        .where({ kind: playerId })
        .toArray()
        .then((prog) => {
          // prog = prog.filter((o) =>
          //   moment(o.tournament.startDate).isAfter(moment())
          // );
          return prog.sort((a, b) =>
            moment(a.tournament.startDate).isSame(b.tournament.startDate)
              ? 0
              : moment(a.tournament.startDate).isAfter(b.tournament.startDate)
                ? -1
                : 1
          );
        })
        : db.programmation
          .where({ kind: playerId })
          .toArray()
          .then((prog) => {
            // prog = prog.filter((o) =>
            //   moment(o.tournament.startDate).isAfter(moment())
            // );
            return prog.sort((a, b) =>
              moment(a.tournament.startDate).isSame(b.tournament.startDate)
                ? 0
                : moment(a.tournament.startDate).isAfter(b.tournament.startDate)
                  ? -1
                  : 1
            );
          });
}

async function getHistoricalProgrammation(playerId, option) {
  return option === 0 ? db.programmation
    .where({ kind: playerId })
    .toArray()
    .then((prog) => {
      return prog.sort((a, b) =>
        moment(a.tournament.startDate).isSame(b.tournament.startDate)
          ? 0
          : moment(a.tournament.startDate).isAfter(b.tournament.startDate)
            ? 1
            : -1
      );
    })
    : option === 1 ? db.programmationOption1
      .where({ kind: playerId })
      .toArray()
      .then((prog) => {
        return prog.sort((a, b) =>
          moment(a.tournament.startDate).isSame(b.tournament.startDate)
            ? 0
            : moment(a.tournament.startDate).isAfter(b.tournament.startDate)
              ? 1
              : -1
        );
      })
      : option === 2 ? db.programmationOption2
        .where({ kind: playerId })
        .toArray()
        .then((prog) => {
          return prog.sort((a, b) =>
            moment(a.tournament.startDate).isSame(b.tournament.startDate)
              ? 0
              : moment(a.tournament.startDate).isAfter(b.tournament.startDate)
                ? 1
                : -1
          );
        })
        : db.programmation
          .where({ kind: playerId })
          .toArray()
          .then((prog) => {
            return prog.sort((a, b) =>
              moment(a.tournament.startDate).isSame(b.tournament.startDate)
                ? 0
                : moment(a.tournament.startDate).isAfter(b.tournament.startDate)
                  ? 1
                  : -1
            );
          });
}

async function getSupervision(staffId) {
  return db.supervision
    .where({ kind: staffId })
    .toArray()
    .then((sup) => {
      sup = sup.sort((a, b) =>
        moment(a.tournament.startDate).isSame(b.tournament.startDate)
          ? 0
          : moment(a.tournament.startDate).isAfter(b.tournament.startDate)
            ? -1
            : 1
      );
      // sup = sup.filter((o) =>
      //   moment(o.tournament.startDate).isAfter(moment())
      // );
      return sup;
    })
}

async function getInternationalProgrammationRemaining(month, year, playerId, option) {
  return getHistoricalProgrammation(playerId, option).then((prog) => {
    return getInternationalCompetition(month, year).then((res) => {
      res.map((tournament) => {
        if (prog.find((p) => p.tournamentId === tournament.tId)) {
          tournament.add = false;
        } else tournament.add = true;
        return tournament;
      });
      return res;
    });
  });
}

async function getNationalProgrammationRemaining(month, year, playerId, option) {
  return getHistoricalProgrammation(playerId, option).then((prog) => {
    return getNationalCompetition(month, year).then((res) => {
      res.map((tournament) => {
        if (prog.find((p) => p.tournamentId === tournament.tId)) {
          tournament.add = false;
        } else tournament.add = true;
        return tournament;
      });
      return res;
    });
  });
}

async function getInternationalSupervisionRemaining(month, year, staffId) {
  return getSupervision(staffId).then((sup) => {
    return getInternationalCompetition(month, year).then((res) => {
      res.map((tournament) => {
        if (sup.find((p) => p.tournamentId === tournament.tId)) {
          tournament.add = false;
        } else tournament.add = true;
        return tournament;
      });
      return res;
    });
  });
}

async function getNationalSupervisionRemaining(month, year, staffId) {
  return getSupervision(staffId).then((sup) => {
    return getNationalCompetition(month, year).then((res) => {
      res.map((tournament) => {
        if (sup.find((p) => p.tournamentId === tournament.tId)) {
          tournament.add = false;
        } else tournament.add = true;
        return tournament;
      });
      return res;
    });
  });
}

async function getRanking(date, kind) {
  var promises = [];
  promises.push(db.ranking.where({ id: kind + date + "" }));
  var res = Promise.all(promises)[0];
  if (res)
    return res.sort((a, b) =>
      a.rank === b.rank ? 0 : a.rank > b.rank ? 1 : -1
    );
}

function getRankingDates(kind) {
  var promises = [];
  promises.push(db.rankingDates.where({ id: kind }));
  return Promise.all(promises);
}

async function getRankingNational(kind) {
  var promises = [];
  promises.push(db.ranking.where({ id: kind + "" }));
  return Promise.all(promises)[0]; //.sort((a, b) => a.level === b.level ? 0 : a.level > b.level ? -1 : 1);
}

function loadCircuits() {
  return get("competitionTennis", "circuits").then((circuits) => {
    if (circuits) {
      db.circuits.bulkPut(circuits);
    }
    return circuits;
  });
}

function loadInternationalCircuits() {
  return get("competitionTennis", "tennisInternationalCircuits").then(
    (circuits) => {
      if (circuits) {
        db.circuits.bulkPut(circuits);
      }
      return circuits;
    }
  );
}

function loadGrounds() {
  return get("competitionTennis", "grounds").then((grounds) => {
    if (grounds) {
      db.grounds.bulkPut(grounds);
    }
    return grounds;
  });
}

function loadTournamentsNationalTypes() {
  return get("competitionTennis", "tennisNationalType").then((types) => {
    if (types) {
      db.tournamentsNationalTypes.bulkPut(types);
    }
    return types;
  });
}

function loadTournamentsNationalClassification() {
  return get("competitionTennis", "tennisNationalClassification").then(
    (classification) => {
      if (classification) {
        db.nationalClassification.bulkPut(classification);
      }
      return classification;
    }
  );
}

function getOpponents() {
  return db.opponents.toArray();
}

function getOpponentById(id) {
  return db.opponents.get(id);
}

function loadOpponentById(id) {
  return get("competitionTennis", "opponents", id);
}

function loadOpponents() {
  return get("competitionTennis", "opponents").then((opponents) => {
    if (opponents) {
      db.opponents.bulkPut(opponents);
    }
    return opponents;
  });
}

function loadTournamentsByPlayer(id) {
  return get("competitionTennis", "tennisTournamentsByPlayer", id).then(
    (tournaments) => {
      if (tournaments) {
        tournaments = tournaments.map((t) => {
          t.playerId = id;
          return t;
        });
        db.competition.bulkPut(tournaments);
      }
      return tournaments;
    }
  );
}

function getTournamentsByPlayer(id) {
  return db.competition.where({ playerId: id }).toArray();
}

function loadTournamentsByPlayersCurrent(ids) {
  return post("competitionTennis", "tournamentsByPlayersCurrent", {playersIds : ids}).then(res => {return res.json()}).then(
    (tournaments) => {
      if (tournaments) {
        db.competition.bulkPut(tournaments);
      }
      return tournaments;
    }
  );
}

function loadTournamentsByPlayerCurrent(id) {
  return get("competitionTennis", "tennisTournamentsByPlayerCurrent", id).then(
    (tournaments) => {
      if (tournaments) {
        db.competition.bulkPut(tournaments);
      }
      return tournaments;
    }
  );
}

function getResultsNumber(res, wins = 0, second = 0, semi = 0) {
  if (res) {
    res.forEach((r) => {
      if (r.isWon) wins += 1;
      else if (r.tmax === "Finale") second += 1;
      else if (r.tmax === "1/2 finale") semi += 1;
    });
  }
  return {
    amount: res.length,
    wins,
    second,
    semi,
  };
}

function getOpponent(id) {
  return db.opponents.get(id);
}

function loadOpponent(id) {
  return get("competitionTennis", "opponents", id).then((opponent) => {
    if (opponent) {
      db.opponents.put(opponent);
    }
    return opponent;
  });
}

function loadRankingsATPByPlayer(id) {
  return get("competitionTennis", "rankingsAtpByPlayer", id).then(
    (res) => {
      if (res) {
        db.rankingsATPByPlayer.bulkPut(res);
      }
      return res;
    }
  );
}

function getRankingsATPByPlayer(id) {
  return db.rankingsATPByPlayer.where({ playerId: id }).toArray();
}

function loadRankingsATPDblByPlayer(id) {
  return get("competitionTennis", "rankingsAtpDblByPlayer", id).then(
    (res) => {
      if (res) {
        db.rankingsATPDblByPlayer.bulkPut(res);
      }
      return res;
    }
  );
}

function getRankingsATPDblByPlayer(id) {
  return db.rankingsATPDblByPlayer.where({ playerId: id }).toArray();
}

function loadRankingsWTAByPlayer(id) {
  return get("competitionTennis", "rankingsWtaByPlayer", id).then(
    (res) => {
      if (res) {
        db.rankingsWTAByPlayer.bulkPut(res);
      }
      return res;
    }
  );
}

function getRankingsWTAByPlayer(id) {
  return db.rankingsWTAByPlayer.where({ playerId: id }).toArray();
}

function loadRankingsWTADblByPlayer(id) {
  return get("competitionTennis", "rankingsWtaDblByPlayer", id).then(
    (res) => {
      if (res) {
        db.rankingsWTADblByPlayer.bulkPut(res);
      }
      return res;
    }
  );
}

function getRankingsWTADblByPlayer(id) {
  return db.rankingsWTADblByPlayer.where({ playerId: id }).toArray();
}

function getRankingsITFHByPlayer(id) {
  return db.rankingsITFHByPlayer.where({ playerId: id }).toArray();
}

function loadRankingsITFHByPlayer(id) {
  return get("competitionTennis", "rankingsItfHByPlayer", id).then(
    (res) => {
      if (res) {
        db.rankingsITFHByPlayer.bulkPut(res);
      }
      return res;
    }
  );
}

function getRankingsITFFByPlayer(id) {
  return db.rankingsITFFByPlayer.where({ playerId: id }).toArray();
}

function loadRankingsITFFByPlayer(id) {
  return get("competitionTennis", "rankingsItfFByPlayer", id).then(
    (res) => {
      if (res) {
        db.rankingsITFFByPlayer.bulkPut(res);
      }
      return res;
    }
  );
}

function getRankingsITFJHByPlayer(id) {
  return db.rankingsITFJHByPlayer.where({ playerId: id }).toArray();
}

function loadRankingsITFJHByPlayer(id) {
  return get("competitionTennis", "rankingsItfJHByPlayer", id).then(
    (res) => {
      if (res) {
        db.rankingsITFFByPlayer.bulkPut(res);
      }
      return res;
    }
  );
}

function getRankingsITFJFByPlayer(id) {
  return db.rankingsITFJFByPlayer.where({ playerId: id }).toArray();
}

function loadRankingsITFJFByPlayer(id) {
  return get("competitionTennis", "rankingsItfJFByPlayer", id).then(
    (res) => {
      if (res) {
        db.rankingsITFFByPlayer.bulkPut(res);
      }
      return res;
    }
  );
}

function getRankingsTennisEuropeU14HByPlayer(id) {
  return db.rankingsTennisEuropeU14HByPlayer.where({ playerId: id }).toArray();
}

function loadRankingsTennisEuropeU14HByPlayer(id) {
  return get(
    "competitionTennis",
    "rankingsTennisEuropeU14HByPlayer",
    id
  ).then((res) => {
    if (res) {
      db.rankingsTennisEuropeU14HByPlayer.bulkPut(res);
    }
    return res;
  });
}

function getRankingsTennisEuropeU14FByPlayer(id) {
  return db.rankingsTennisEuropeU14FByPlayer.where({ playerId: id }).toArray();
}

function loadRankingsTennisEuropeU14FByPlayer(id) {
  return get(
    "competitionTennis",
    "rankingsTennisEuropeU14FByPlayer",
    id
  ).then((res) => {
    if (res) {
      db.rankingsTennisEuropeU14FByPlayer.bulkPut(res);
    }
    return res;
  });
}

function loadEngagementsByPlayerByTournament(playerId, tournamentId) {
  return get("competitionTennis", "engagementsByPlayerAndTournament", playerId + "/" + tournamentId)
}

export {
  loadNationalCompetition,
  getNationalCompetition,
  loadInternationalCompetition,
  getInternationalCompetition,
  loadResults,
  loadResultsByPlayers,
  getResults,
  loadMatches,
  loadMatchesByPlayers,
  loadMatchesByDates,
  loadMatchesByDatesAndPlayers,
  getMatches,
  getMatchesByDates,
  loadMatch,
  getMatch,
  loadCircuits,
  loadInternationalCircuits,
  loadGrounds,
  loadTournamentsNationalTypes,
  loadTournamentsNationalClassification,
  loadProgrammation,
  getProgrammation,
  addEngagement,
  loadRanking,
  getRanking,
  loadRankingDates,
  getRankingDates,
  loadRankingNational,
  getRankingNational,
  getOpponents,
  loadOpponents,
  loadTournamentsByPlayer,
  loadInternationalProgrammationRemaining,
  getInternationalProgrammationRemaining,
  loadNationalProgrammationRemaining,
  getNationalProgrammationRemaining,
  getResultsNumber,
  loadMatchStatistics,
  getMatchStatistics,
  loadTournamentsByPlayerCurrent,
  loadTournamentsByPlayersCurrent,
  getTournamentsByPlayer,
  loadOpponent,
  getOpponent,
  loadRankingsATPByPlayer,
  getRankingsATPByPlayer,
  loadRankingsATPDblByPlayer,
  getRankingsATPDblByPlayer,
  loadRankingsWTAByPlayer,
  getRankingsWTAByPlayer,
  loadRankingsWTADblByPlayer,
  getRankingsWTADblByPlayer,
  loadRankingsITFHByPlayer,
  getRankingsITFHByPlayer,
  loadRankingsITFFByPlayer,
  getRankingsITFFByPlayer,
  loadRankingsITFJHByPlayer,
  getRankingsITFJHByPlayer,
  loadRankingsITFJFByPlayer,
  getRankingsITFJFByPlayer,
  loadRankingsTennisEuropeU14HByPlayer,
  getRankingsTennisEuropeU14HByPlayer,
  loadRankingsTennisEuropeU14FByPlayer,
  getRankingsTennisEuropeU14FByPlayer,
  getCompetitionById,
  loadNationalCompetitionById,
  loadSupervision,
  addSupervision,
  getOpponentById,
  loadOpponentById,
  loadTournamentById,
  loadEngagementsByPlayerByTournament,
  loadInternationalCompetitionById,
  getSupervision,
  loadInternationalSupervisionRemaining,
  loadNationalSupervisionRemaining,
  getInternationalSupervisionRemaining,
  getNationalSupervisionRemaining,
  getHistoricalProgrammation
};
