import React from "react";
import { withTranslation } from "react-i18next";
import FusionCharts from "fusioncharts/core";
// import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts";
import Column2D from "fusioncharts/viz/column2d";
import StackedColumn2D from "fusioncharts/viz/stackedcolumn2d";
import ScrollColumn2D from "fusioncharts/viz/scrollcolumn2d";
import Doughnut2D from "fusioncharts/viz/doughnut2d";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
// Resolves charts dependancy
ReactFusioncharts.fcRoot(
    FusionCharts,
    StackedColumn2D,
    Column2D,
    Doughnut2D,
    ScrollColumn2D,
    FusionTheme
);
// charts(FusionCharts);

class ChartStackedColumns extends React.Component {
    render() {
        var dataSet = this.props.dataSet;
        var subcaption = this.props.subcaption;
        var title = this.props.title;
        var totalTime = this.props.totalTime;
        var totalSRPE = this.props.totalSRPE;
        var totalMin = 0
        var totalHour = 0
        if(totalTime) {
            totalTime = totalTime/60
            totalMin = ((totalTime%1)*60).toFixed(0)
            totalHour = Math.trunc(totalTime)
        }
        const dataSource = {
            chart: {
                caption: title,
                plottooltext: "$label : <b>$percentValue</b>",
                defaultcenterlabel: totalTime ? ("<b>" + totalHour + "H" + (totalMin < 10 ? "0" + totalMin : totalMin) + "</b>") : 
                totalSRPE ? "<b>" + totalSRPE + " UA</b>" : null,
                numberScaleValue:'60',
                numberScaleUnit:'hr',
                showlegend: "1",
                interactivelegend: "1",
                plotHighlightEffect: "fadeout",
                showpercentvalues: "1",
                legendposition: "bottom",
                usedataplotcolorforlabels: "1",
                theme: "fusion",
                valuefontcolor: "#6e3677",
                //centerlabel: "<b>$value</b>min",
                subcaption: subcaption,
            },
            data: dataSet ? dataSet : []
        };
        return (
            <ReactFusioncharts
                type="doughnut2d"
                width={this.props.width ? this.props.width : "50%"}
                height={this.props.height ? this.props.height : "50%"}
                dataFormat="JSON"
                dataSource={dataSource}
            />
        );
    }
}

export default withTranslation()(ChartStackedColumns);
