import React from "react";

// Drawer
// component => openDrawer
// children = content of the drawer

class Drawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      drawer: false,
    };
    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  show(event) {
    this.setState({ drawer: true });
  }

  hide(event) {
    this.setState({ drawer: false });
    if (this.props.hide) {
      this.props.hide();
    }
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ drawer: false });
      this.forceUpdate();
    }
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  render() {
    return (
      <>
        {this.props.component(this.show)}

        {this.state.drawer ? (
          <>
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 z-3 transition-opacity z-50"></div>
            <section
              className="fixed inset-y-0 right-0 max-w-full flex z-50"
              aria-labelledby="slide-over-heading"
            >
              <div
                className={
                  this.props.className
                    ? this.props.className
                    : "w-screen pl-10 max-w-2xl"
                }
              >
                <div className="h-full pb-6 bg-white shadow-xl overflow-y-auto">
                  {typeof this.props.children === "function"
                    ? this.props.children(this.hide)
                    : this.props.children}
                </div>
              </div>
            </section>
          </>
        ) : null}
      </>
    );
  }
}

export default Drawer;
