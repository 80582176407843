import React from "react";
import { withTranslation } from "react-i18next";

import Input from "../../forms/input/Input";
import Checkbox from "../../forms/input/CheckBoxLabel";
import Button from "../../buttons/Button";
import getDataFiltered from "../../../utils/filteredData";

class Accordion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            dataFiltered: [],
            dataSelected: [],
            open: this.props.open ? this.props.open : true,
            activeSearchBar: false
        };
    }

    componentDidMount() {
        this.setState({
            data: this.props.data,
            dataSelected: this.props.dataSelected,
            dataFiltered: this.props.data

        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data !== this.props.data) {
            this.setState({ data: this.props.data, dataFiltered: this.props.data });
        }

        if (prevProps.open !== this.props.open) {
            this.setState({ open: this.props.open });
        }

        if (prevProps.dataSelected !== this.props.dataSelected) {
            this.setState({ dataSelected: this.props.dataSelected });
        }
    }

    render() {
        const { t } = this.props;
        return this.props.data ? (
            <>
                <div className="flex justify-between">{this.state.activeSearchBar ?
                    <>
                        <Input
                            className="w-32 2xl:w-40 h-6 2xl:h-7 m-2 rounded-md"
                            placeholder={t("Search")}
                            autoFocus
                            onChange={(value) => {
                                this.setState({ dataFiltered: undefined });
                                var dataFiltered = [];
                                if (value) {
                                    dataFiltered = getDataFiltered(
                                        this.state.data,
                                        value
                                    );
                                } else {
                                    dataFiltered = this.state.data;
                                }

                                this.setState({ dataFiltered });
                            }}
                        />
                        <Button
                            className="btn btn-sm btn-clean btn-icon"
                            onClick={() => this.setState({ activeSearchBar: false, dataFiltered: this.state.data })}
                        >
                            <svg className="h-3 2xl:h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
                            </svg>
                        </Button>
                    </>
                    : <div className="flex justify-between border-t border-b w-full pr-6 py-2">
                        <button className="inline-flex justify-center text-sm font-medium 2xl:mt-1 2xl:text-sm" onClick={() => this.setState({ open: !this.state.open })}>
                            <svg className="h-4 2xl:h-6 mr-2 ml-1 mt-0.5 2xl:-mt-0.5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                            </svg>
                            {this.props.title + " (" + this.state.dataSelected.length +")"}</button>
                        {this.state.open ? <Button
                            className="btn btn-sm btn-clean btn-icon"
                            onClick={() => this.setState({ activeSearchBar: true })}
                        >
                            <svg className="h-4 2xl:h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                            </svg>
                        </Button> : null}
                    </div>
                }

                </div>
                {this.state.open ?
                    <div className={this.props.className? this.props.className : "max-h-96 overflow-y-auto"}>{(this.state.activeSearchBar ? this.state.dataFiltered : this.state.data).map((d, key) => {
                        return <Checkbox key={key} value={this.state.dataSelected ? this.state.dataSelected.includes(d.id) : false} onChange={(value) => {
                            var dataSelected = this.state.dataSelected;
                            if (value) {
                                dataSelected.push(d.id);
                            } else {
                                var newDataSelected = []
                                dataSelected.forEach(k => {
                                    if (k !== d.id) {
                                        newDataSelected.push(k)
                                    }
                                })
                                dataSelected = newDataSelected
                            }
                            this.props.callback(dataSelected)
                        }}
                            color={d.color}
                            title={d[this.props.property]} />
                    })}</div> : null}
            </>
        ) : null;
    }
}

export default withTranslation()(Accordion);
