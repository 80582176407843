import React from "react";
import FusionCharts from "fusioncharts/core";
// import charts from "fusioncharts/fusioncharts.charts";
import moment from "moment";
//import InverseMSLine2D from "fusioncharts/fusioncharts.charts";
//import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
// import PowerCharts from "fusioncharts/fusioncharts.powercharts";
import inversemsline from "fusioncharts/viz/inversemsline";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import ReactFC from "react-fusioncharts";
//ReactFC.fcRoot(FusionCharts, InverseMSLine2D, FusionTheme);
// charts(FusionCharts);
ReactFC.fcRoot(FusionCharts, inversemsline, FusionTheme);
FusionCharts.options["creditLabel"] = false;

class ChartTennisRankingsRank extends React.Component {
  componentDidMount() {}
  render() {
    var data = this.props.data;
    var title = this.props.title;
    var chartData = [];
    var chartCategories = [];
    if (data) {
      data = data.sort((a, b) =>
        moment(a.date).isSame(b.date)
          ? 0
          : moment(a.date).isAfter(b.date)
          ? 1
          : -1
      );
    }
    if (data) {
      data.forEach((item) =>
        chartCategories.push({
          label: moment(item.date).format("DD/MM/YYYY"),
        })
      );
      data.forEach((item) =>
        chartData.push({
          value: item.rank.toString(),
        })
      );
    }

    const dataSource = {
      chart: {
        caption: title,
        theme: "fusion",
        yaxisname: "Rank",
        xaxisname: "Date",
        plottooltext: "$seriesName: <b>$dataValue</b>",
        rotatelabels: "1",
        slantlabels: "0",
      },
      categories: [
        {
          category: chartCategories,
        },
      ],
      dataset: [
        {
          seriesname: "Rank",
          allowDrag: "0",
          data: chartData,
        },
      ],
    };

    const chartConfigs = {
      type: "inversemsline",
      width: "100%",
      height: "400",
      dataFormat: "json",
      dataSource: dataSource
    };

    return (
      <>
        <ReactFC {...chartConfigs} />
      </>
    );
  }
}

export default ChartTennisRankingsRank;
