import Dexie from "dexie";

const db = new Dexie("Events");

db.version(1).stores({
  events: "++id, groupId, startDate, endDate, typeId, link",
  eventsByPlayer: "++id, playerId, startDate, endDate, typeId, link",
  attendees: "++id, eventId, playerId",
  cycles: "++id, groupId",
  cyclesByPlayer: "++id, playerId",
  organizers: "++id, eventId, staffId",
  seasons: "++id",
  tasks: "++id, staffId",
  weeks: "++id, groupId",
  weeksByPlayer: "++id, playerId",
  weeksCategories: "++id",
  types: "++id",
  mentalCategories: "++id",
  weeksResultValue: "++weekId",
  weeksMentalResultValue: "++weekId",
  weeksPhysicalResultValue: "++weekId"
});

db.version(2).stores({
  mediaOperationCategories: "++id",
  partnerOperationCategories: "++id"
});

db.version(3).stores({
  weeksTrainingResultValue: "++weekId"
});

db.version(4).stores({
  eventsDayTimeByTypeIdAndPlayerId: "++id, kind, playerId",
  eventsDayTimeByPlayerId: "++id, playerId"
});


export default db;
