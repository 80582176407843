import React from "react";
import ReactFC from "react-fusioncharts";
import FusionCharts from "fusioncharts/core";
import Column2D from "fusioncharts/viz/column2d";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import moment from "moment";
ReactFC.fcRoot(FusionCharts, Column2D, FusionTheme);

class ChartTennisRankingsPoints extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      galeries: [],
    };
  }

  componentDidMount() {}
  render() {
    var data = this.props.data;
    var title = this.props.title;
    var chartData = [];
    if (data) {
      data = data.sort((a, b) =>
        moment(a.date).isSame(b.date)
          ? 0
          : moment(a.date).isAfter(b.date)
          ? 1
          : -1
      );
    }
    if (data) {
      data.forEach((item) =>
        chartData.push({
          label: moment(item.date).format("DD/MM/YYYY"),
          value: item.pts,
        })
      );
    }
    const chartConfigs = {
      type: "column2d", // The chart type
      width: "100%", // Width of the chart
      height: "400", // Height of the chart
      dataFormat: "json", // Data type
      dataSource: {
        // Chart Configuration
        chart: {
          caption: title,
          xAxisName: "Date",
          yAxisName: "Points",
          numberSuffix: "pts",
          theme: "fusion",
        },
        data: chartData,
      },
    };
    return <ReactFC {...chartConfigs} />;
  }
}

export default ChartTennisRankingsPoints;
