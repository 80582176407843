import db from "./db";
import get from "../../requests/get";
import moment from "moment";
import {
  getSASTokenAzureBlobStorage,
  uploadFile,
} from "../../azure-blobstorage/functions";
import getUserProfile from "../../auth/getUserProfile";
const { BlobServiceClient } = require("@azure/storage-blob");

async function loadCollections() {
  return get("medias", "collections").then((collections) => {
    if (collections) {
      collections.map((c) => {
        c.dateDisplay = moment(c.date).format("D MMM YYYY") + "";
        c.lastModificationDateDisplay =
          moment(c.lastModificationDate).format("D MMM YYYY") + "";
        return c;
      });
      db.collections.bulkPut(collections);
    }
    return collections;
  });
}

function getCollections() {
  return db.collections.toArray();
}

async function loadCollectionsByPlayer(playerId) {
  return get("medias", "collectionsByPlayer", playerId).then((collections) => {
    if (collections) {
      collections.map((c) => {
        c.dateDisplay = moment(c.date).format("D MMM YYYY") + "";
        c.lastModificationDateDisplay =
          moment(c.lastModificationDate).format("D MMM YYYY") + "";
        return c;
      });
      db.collectionsByPlayer.bulkPut(collections);
    }
    return collections;
  });
}

function getCollectionsByPlayer(playerId) {
  return db.collectionsByPlayer.where({ playerId: playerId }).toArray();
}

async function loadGalleries() {
  return get("medias", "galleries").then((galleries) => {
    if (galleries) {
      db.galleries.bulkPut(galleries);
    }
    return galleries;
  });
}

async function loadCollection(id) {
  return get("medias", "collections", id).then((collection) => {
    if (collection) {
      db.collectionsByPlayer.put(collection);
    }
    return collection;
  });
}

function getCollection(id) {
  return db.collectionsByPlayer.get(id);
}

function getGalleries() {
  return db.galleries.toArray();
}

async function loadGallery(id) {
  return get("medias", "galleries", id).then((gallery) => {
    if (gallery) {
      db.galleriesByPlayer.put(gallery);
    }
    return gallery;
  });
}

function getGallery(id) {
  return db.galleriesByPlayer.get(id);
}

function loadVideoTempByCollection(collectionId) {
  return get("medias", "videosTempByCollection", collectionId).then(
    (videosTemp) => {
      getVideoTempByCollection(collectionId).then((vidT) => {
        var promises = [];
        if (vidT && vidT.length) {
          vidT.forEach((v) => {
            promises.push(db.videosTempByCollection.delete(v.id));
          });
        }
        Promise.all(promises).then(() => {
          if (videosTemp && videosTemp.length) {
            db.videosTempByCollection.bulkPut(videosTemp);
          }
        });
      });
      return videosTemp;
    }
  );
}

function getVideoTempByCollection(collectionId) {
  return db.videosTempByCollection
    .where({ collectionId: collectionId })
    .toArray();
}

async function loadGalleriesByPlayer(playerId) {
  return get("medias", "galleriesByPlayer", playerId).then((galleries) => {
    if (galleries) {
      db.galleriesByPlayer.bulkPut(galleries);
    }
    return galleries;
  });
}

function getGalleriesByPlayer(playerId) {
  return db.galleriesByPlayer.where({ playerId: playerId }).toArray();
}

async function loadGalleriesCategories() {
  return get("medias", "galleriesCategories").then((galleriesCategories) => {
    if (galleriesCategories) {
      db.galleriesCategories.bulkPut(galleriesCategories);
    }
    return galleriesCategories;
  });
}

function getGalleriesCategories() {
  return db.galleriesCategories.toArray();
}

async function loadVideosByEvent(eventId) {
  return get("medias", "videosByEvent", eventId).then((videos) => {
    if (videos && videos.length) {
      db.videos.bulkPut(videos);
    }
    return videos;
  });
}

function getVideosByEvent(eventId) {
  return db.videos.where({ eventId: eventId }).toArray();
}

async function loadContainer(containerId) {
  return getSASTokenAzureBlobStorage("exaduosport").then(
    (azureBlobStorageToken) => {
      return getUserProfile().then(async (user) => {
        const blobSasUrl =
          "https://exaduosport.blob.core.windows.net?" + azureBlobStorageToken;
        const blobServiceClient = new BlobServiceClient(blobSasUrl);
        const containerClient =
          blobServiceClient.getContainerClient("documentaire");
        var prefix = user.club + "/" + containerId + "/";
        let iter = containerClient.listBlobsByHierarchy("/", {
          prefix,
          includeMetadata: true,
        });
        let hierarchy = [];
        let files = [];
        for await (const item of iter) {
          if (item.kind === "prefix") {
            [files, hierarchy] = await listblob(
              containerClient,
              item.name,
              hierarchy,
              files
            );
          }
          files.push(item);
          hierarchy.push(item.name);
        }

        return {
          hierarchy: hierarchy,
          files: files
        }
      });

      async function listblob(containerClient, prefix, hierarchy, files) {
        let iter1 = containerClient.listBlobsByHierarchy("/", {
          prefix: prefix,
        });
        for await (const item of iter1) {
          files.push(item);
          hierarchy.push(item.name);
        }
        return [files, hierarchy];
      }
    }
  );
}

function getChild(containerId, hierarchy, root, files) {
  return getUserProfile().then(async (user) => {
    const mainMapFromStart = {};

    function set(parts, mainMap) {
      let map = mainMap;
      for (const item of parts) {
        map[item] = map[item] || {};
        map = map[item];
      }
    }

    hierarchy
      .map((item) => item.split("/"))
      .forEach((parts) => {
        set(parts, mainMapFromStart);
      });

    let childName = mainMapFromStart[user.club] ? mainMapFromStart[user.club][containerId] : [];
    if (root && root.length > 1) {
      root.forEach(r => {
        if (Object.keys(childName) && Object.keys(childName).find(r)) {
          childName = childName[r];
        }
      })
    }
    let children = [];
    if(childName && Object.keys(childName)){
      Object.keys(childName).forEach(c => {
        if(files.find(f => f.name === user.club + "/" + containerId + "/" + root + "/" + c)){
          let blob = files.find(f => f.name === user.club + "/" + containerId + "/" + root + "/" + c);
          blob.visibleName = c;
          children.push(blob);
        }
      })
      
    }
    return children ;
  });
}

function getFolder(folderId) {
  return db.folders.get(folderId);
}

async function postDocument(playerId, root, file, isShared) {
  return getSASTokenAzureBlobStorage("exaduosport").then(
    (azureBlobStorageToken) => {
      return getUserProfile().then(async (user) => {
        const prefix = user.club + "/" + playerId + "/";
        const formData = {
          filePath: prefix + root + file.name,
          file,
        };
        const metadata = {
          authorId: user.sub,
          isShared: isShared.toString(),
        };
        return uploadFile(
          azureBlobStorageToken,
          formData,
          "exaduosport",
          "documentaire",
          (uploadDataPercentage) => {
            this.setState({ uploadDataPercentage });
          },
          metadata
        )
      });
    }
  );
}

async function postFolder(playerId, root) {
  return getSASTokenAzureBlobStorage("exaduosport").then(
    (azureBlobStorageToken) => {
      return getUserProfile().then(async (user) => {
        const prefix = user.club + "/" + playerId + "/";
        const formData = {
          filePath: prefix + root,
        };
        return uploadFile(
          azureBlobStorageToken,
          formData,
          "exaduosport",
          "documentaire",
          () => { }
        )
      });
    }
  );
}

export {
  loadCollections,
  getCollections,
  loadCollectionsByPlayer,
  getCollectionsByPlayer,
  loadCollection,
  getCollection,
  loadGalleries,
  getGalleries,
  loadGallery,
  getGallery,
  loadGalleriesByPlayer,
  getGalleriesByPlayer,
  loadGalleriesCategories,
  getGalleriesCategories,
  loadVideoTempByCollection,
  getVideoTempByCollection,
  loadVideosByEvent,
  getVideosByEvent,
  loadContainer,
  getFolder,
  postDocument,
  postFolder,
  getChild
};
