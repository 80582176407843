import React from "react";
import moment from "moment";
import { withTranslation } from "react-i18next";
import { MonthPicker, SelectMultiple } from "../components/forms";
import TableSimple from "../components/table/TableSimple";
import Button from "../components/buttons/Button";
import FiltersDropDowns from "../components/filters/FiltersDropDowns";
import FilterColumns from "../components/filters/FilterColumns";
import FiltersSearch from "../components/filters/FiltersSearch";
import SearchInput from "../components/forms/input/SearchInput";
import FilterSeparator from "../components/filters/FilterSeparator";
import FiltersTitle from "../components/filters/FiltersTitle";
import FiltersBar from "../components/filters/FiltersBar";

class TableTournamentsNATProg extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actions: [],
      circuits: [],
      selectedCircuits: [],
      columns: [],
      searchText: "",
      year: parseInt(moment().format("YYYY")),
      month: parseInt(moment().format("M")),
    };
  }

  componentDidMount() {
    this.prepareData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.prepareData();
    }
  }

  prepareData = () => {
    var tournaments = this.props.tournaments;
    var circuits = [];
    var selectedCircuits = [];
    var classifications = [];
    var selectedClassifications = [];
    var regions = [];
    var selectedRegions = [];

    //  chercher les années a déselectionner du localstorage
    var unselectedClassifications = localStorage.getItem("TableTournamentsNATProg_unselectedClassifications")
    if (unselectedClassifications) unselectedClassifications = unselectedClassifications.split("|")

    // regions/ligues
    var unselectedRegions = localStorage.getItem("TableTournamentsNATProg_unselectedRegions")
    if (unselectedRegions) unselectedRegions = unselectedRegions.split("|")

    if (tournaments) {
      tournaments = tournaments.map((tournament) => {
        if (
          circuits &&
          !circuits.find((o) => o.key === tournament.circuit.id)
        ) {
          circuits.push({
            key: tournament.circuit.id,
            label: tournament.circuit.name,
          });
          selectedCircuits.push(tournament.circuit.id);
        }
        if (
          classifications &&
          !classifications.find((o) => o.key === tournament.classification.id)
        ) {
          classifications.push({
            key: tournament.classification.id,
            label: tournament.classification.name,
          })
          // only add to selected if not unselected
          if (
            !unselectedClassifications ||
            unselectedClassifications.indexOf(tournament.classification.id.toString()) < 0
          )
            selectedClassifications.push(tournament.classification.id);
        }
        if (
          tournament.club &&
          regions &&
          !regions.find((o) => o.key === tournament.club.region.id)
        ) {
          regions.push({
            key: tournament.club.region.id,
            label: tournament.club.region.name,
          });
          if (tournament.club) {
            selectedRegions.push(tournament.club.region.id)
            // only add to selected if not unselected
            if (
              !unselectedRegions ||
              unselectedRegions.indexOf(tournament.club.region.id.toString()) < 0
            );
          }
        }
        return tournament;
      });
    }

    const columns = [
      {
        key: "date",
        visible: true,
        label: this.props.t("Dates"),
        id: "date",
        width: 200,
        format: (row) => (
          <span className="font-weight-bold text-muted">{row.date}</span>
        ),
      },
      {
        key: "name",
        visible: true,
        label: this.props.t("Tournois"),
        id: "name",
        format: (row) => row.name,
        width: 300,
      },
      {
        key: "classification.name",
        visible: true,
        label: this.props.t("Classification"),
        id: "circuit.name",
        format: (row) =>
          row.circuit ? (
            <span
              className="px-3 py-0.5 rounded-full text-sm font-medium bg-red-100 text-red-800"
              style={{
                color: row.circuit.color2,
                backgroundColor: row.circuit.color1,
              }}
            >
              {
                row.classification ? " " + row.classification.name : ""}
            </span>
          ) : (
              "N/A"
            ),
        filter: {
          name: "SelectColumnFilter",
          type: "includes",
        },
      },
      {
        key: "contests",
        visible: true,
        label: this.props.t("Contests"),
        id: "contests",
        width: 200,
        filter: {
          name: "SelectColumnFilter",
          type: "includes",
        },
        format: (row) => (row.contests ? row.contests : "N/A"),
      },
      {
        key: "city",
        visible: true,
        label: this.props.t("Location"),
        id: "city",
        format: (row) => (
          <span className="font-weight-bold text-muted">{row.city}</span>
        ),
        width: 250,
      },
      {
        key: "regionId",
        visible: true,
        pinned: "left",
        label: this.props.t("Ligue"),
        id: "regionId",
        format: (row) =>
          row.club && row.club.region
            ? row.club.region.name
            : "-",
        width: 250,
        filter: {
          name: "SelectColumnFilter",
          type: "includes",
        },
      },
      {
        key: "grounds",
        visible: true,
        label: this.props.t("Conditions"),
        id: "grounds",
        width: 200,
        filter: {
          name: "SelectColumnFilter",
          type: "includes",
        },
        format: (row) => (row.grounds ? row.grounds : "N/A"),
      },
      {
        key: "action",
        visible: true,
        label: this.props.t("Add (status)"),
        format: (row) =>
          moment(row.startDate).isBefore(moment()) &&
            !(
              this.props.programmation &&
              this.props.programmation.find((p) => p.tournamentId === row.id)
            ) ? (
              <Button
                className="btn btn-sm btn-primary font-weight-bolder"
                onClick={() =>
                  this.props
                    .addEngagement(
                      this.props.id,
                      row.tId,
                      this.props.progView === "Real"
                        ? 0
                        : this.props.progView === "Option 1"
                          ? 1
                          : this.props.progView === "Option 2"
                            ? 2
                            : 0
                    )
                    .then((res) => {
                      this.props.updateProg(this.props.id, this.props.progView);
                    })
                }
              >
                <i className="flaticon2-plus"></i>
                {this.props.t("Add to programation")}
              </Button>
            ) : row.add ? (
              <Button
                className="btn btn-sm btn-primary font-weight-bolder"
                onClick={() =>
                  this.props
                    .addEngagement(
                      this.props.id,
                      row.tId,
                      this.props.progView === "Real"
                        ? 0
                        : this.props.progView === "Option 1"
                          ? 1
                          : this.props.progView === "Option 2"
                            ? 2
                            : 0
                    )
                    .then((res) => {
                      this.props.updateProg(this.props.id);
                    })
                }
              >
                <i className="flaticon2-plus"></i>
                {this.props.t("Add to programation")}
              </Button>
            ) : (
                "X"
              ),
      },
    ];
    var selectedColumns = [];
    columns.forEach((column) => {
      if (column.visible) {
        selectedColumns.push(column.key);
      }
    });
    this.setState({
      columns,
      tournaments,
      circuits,
      classifications,
      regions,
      selectedColumns,
      selectedCircuits,
      selectedClassifications,
      selectedRegions,
    });
  };

  // change regions
  onChangeSelectedRegions = (res) => {
    this.setState({ selectedRegions: res }, () => {
      // determiner ce qui n'est pas selectionner
      let unselect = []
      this.state.regions.forEach((a) => {
        if (this.state.selectedRegions.indexOf(a.key) < 0) unselect.push(a.key)
      })
      // save unselected elements to localstorage
      localStorage.setItem("TableTournamentsNATProg_unselectedRegions", unselect.join("|"))
    })
  }

  // change classifications
  onChangeSelectedClassifications = (res) => {
    this.setState({ selectedClassifications: res }, () => {
      // determiner ce qui n'est pas selectionner
      let unselect = []
      this.state.classifications.forEach((a) => {
        if (this.state.selectedClassifications.indexOf(a.key) < 0) unselect.push(a.key)
      })
      // save unselected elements to localstorage
      localStorage.setItem("TableTournamentsNATProg_unselectedClassifications", unselect.join("|"))
    })
  }

  render() {
    const { t } = this.props;
    var tournaments = this.props.tournaments;
    var columns = this.state.columns;
    var selectedColumns = this.state.selectedColumns;
    var selectedCircuits = this.state.selectedCircuits;
    var selectedRegions = this.state.selectedRegions;
    var selectedClassifications = this.state.selectedClassifications;
    var searchText = this.state.searchText;
    if (columns) {
      columns = columns.filter((o) =>
        selectedColumns.some((item) => item === o.key)
      );
    }
    if (tournaments) {
      tournaments = tournaments.filter((o) =>
        selectedCircuits.some((item) => item === o.circuit.id)
      );
    }
    if (tournaments) {
       tournaments = tournaments.filter((o) =>
         selectedRegions.some((item) => o.club && item === o.club.region.id)
       );
     }
     if (tournaments) {
       tournaments = tournaments.filter((o) =>
         selectedClassifications.some((item) => item === o.classification.id)
       );
     }
    if (searchText.length > 2) {
      tournaments = tournaments.filter((o) =>
        o.name.toLowerCase().includes(searchText.toLowerCase()) || o.city.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    return (
      <>
        <FiltersBar
          components={
            <>
              <FiltersTitle title={tournaments
                  ? tournaments.length + " " + t("Tournaments").toLowerCase()
                  : ""}
              />
              <FilterSeparator />
              <MonthPicker
                className="mx-2 rounded-md focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300"
                value={moment(this.state.month + "-" + this.state.year, "MM-YYYY")}
                onChange={(date) => {
                  var month = moment(date, "YYYY-MM").format("MM");
                  var year = moment(date, "YYYY-MM").format("YYYY");
                  this.setState({ month, year });
                  this.props.onChange(month, year);
                }}
              />
              <FilterSeparator />
              <FiltersSearch search={<SearchInput
                value={this.state.searchText}
                onChange={(searchText) => this.setState({ searchText })}
              />} />
              <FilterSeparator />
              <FiltersDropDowns selects={[
                <SelectMultiple
                  options={this.state.classifications}
                  keys={this.state.selectedClassifications}
                  className="mx-2"
                  onChange={this.onChangeSelectedClassifications}
                  fixedLabel={t("Classification")}
                />,
                <SelectMultiple
                  options={this.state.regions}
                  keys={this.state.selectedRegions}
                  className="mx-2"
                  onChange={this.onChangeSelectedRegions}
                  fixedLabel={t("Regions")}
                />
              ]}
              />
              <FilterSeparator />
              <FilterColumns
                columns={
                  <SelectMultiple
                    options={this.state.columns}
                    keys={this.state.selectedColumns}
                    className="mx-2"
                    onChange={(res) => this.setState({ selectedColumns: res })}
                    fixedLabel={t("Active columns")}
                  />
                }
              />
            </>
          }
        />
        <TableSimple columns={columns} data={tournaments} />
      </>
    );
  }
}

export default withTranslation()(TableTournamentsNATProg);
