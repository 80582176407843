import React from "react";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import Page from "../../components/page/Page";
import SubTab from "../../components/tab/SubTab";
import MatchGeneral from "../competitionRugby/MatchGeneral";
// import StatistiqueIndividuelRugby from "../competitionRugby/StatistiqueIndividuelRugby";
// import StatistiqueIndividuelPhysical from "../competitionRugby/StatistiqueIndividuelPhysical";
import StatistiqueIndividuelGeneric from "./StatistiqueIndividuelGeneric";
// import Document from "./Document";
import ComingSoon from "../../components/comingSoon/comingSoon";

class MatchInfosGeneric extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      getMatchsInfosFromAPi: false,
      getMatchsInfosByPlayerFromAPi: false,
      selectedTabKey: this.props.sportType.key,
    };
  }

  render() {
    const { t } = this.props;
    const sportTypeUrl = this.props.sportTypeUrl;

    return (
      <Page
        backPage="/group"
        refreshData={() => this.props.history.goBack()}
        tab={[
          {
            title: t("Match"),
            visible: true,
            data: null,
            component: () => {
              return (
                <MatchGeneral
                  matchInfos={this.props.matchInfos}
                  infoByPlayer={this.props.infoByPlayer}
                />
              );
            },
          },
          {
            title: t("Statistique Individuel"),
            visible: true,
            data: null,
            component: () => {
              return (
                <>
                  <SubTab
                    key={this.state.selectedTabKey}
                    tab={[
                      {
                        key: this.props.sportType.key,
                        label: this.props.sportType.label,
                      },
                      { key: "physical", label: t("Physical") },
                    ]}
                    onChange={(key) => this.setState({ selectedTabKey: key })}
                    selectedTab={this.state.selectedTabKey}
                  ></SubTab>
                  {this.state.selectedTabKey === "rugby" &&
                    this.props.matchInfos &&
                    this.props.infoByPlayer && (
                      //   <StatistiqueIndividuelRugby
                      //     matchInfos={this.props.matchInfos}
                      //     infoByPlayer={this.props.infoByPlayer}
                      //   />
                      <StatistiqueIndividuelGeneric
                        matchInfos={this.props.matchInfos}
                        infoByPlayer={this.props.infoByPlayer}
                        sportTypeUrl={sportTypeUrl}
                        selectedTabKey={this.state.selectedTabKey}
                      />
                    )}
                  {this.state.selectedTabKey === "physical" &&
                    this.props.matchInfos &&
                    this.props.infoByPlayer && (
                      <StatistiqueIndividuelGeneric
                        matchInfos={this.props.matchInfos}
                        infoByPlayer={this.props.infoByPlayer}
                        sportTypeUrl={sportTypeUrl}
                        selectedTabKey={this.state.selectedTabKey}
                      />
                    )}
                </>
              );
            },
          },
          {
            title: t("Document"),
            visible: true,
            data: null,
            component: () => {
              return <ComingSoon />
            },
          },
        ]}
      />
    );
  }
}

export default withTranslation()(withRouter(MatchInfosGeneric));
