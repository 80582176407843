import React, { Component } from "react"
import { createPopper } from "@popperjs/core"

class Tooltip extends Component {
  constructor(props) {
    super(props)
    this.btnRef = React.createRef()
    this.popoverRef = React.createRef()
    this.openTooltip = this.openTooltip.bind(this)
    this.closeTooltip = this.closeTooltip.bind(this)
    this.state = {
      visible: false,
    }
  }

  openTooltip = () => {
    createPopper(this.btnRef.current, this.popoverRef.current, {
      placement: "top",
    })
    this.setState({ visible: true })
  }
  
  closeTooltip = () => {
    this.setState({ visible: false })
  }

  render() {
    return (
      <>
        <span
          className="cursor-pointer z-0"
          onMouseEnter={this.openTooltip}
          onMouseLeave={this.closeTooltip}
          ref={this.btnRef}
        >
          {this.props.children}
        </span>
        <div
          className={
            this.state.visible ? "absolute bg-gray-50 px-3 py-3 font-medium text-xs 2xl:text-sm rounded-md border border-gray-50 shadow-2xl mr-1 mb-1 z-20" 
            : "hidden"
          }
          style={{boxShadow: 'rgba(82, 63, 105, 0.5) 0px 0px 10px 0px'}}
          ref={this.popoverRef}
        >
          <div>{this.props.tooltipContent}</div>
          {this.props.tooltipContent1 ? (<div>{this.props.tooltipContent1}</div>) : ""}
          {this.props.tooltipContent2 ? (<div>{this.props.tooltipContent2}</div>) : ""}
          {this.props.tooltipContent3 ? (<div>{this.props.tooltipContent3}</div>) : ""}
        </div>
      </>
    )
  }
}

export default Tooltip
