import React from "react"
import ActionButton from "../components/buttons/ActionButton"
import moment from "moment"
import { withTranslation } from "react-i18next"
import TableSimple from "../components/table/TableSimple"
import { sortTargetSetByEndDate } from "../utils/indexedDB/management/handleManagement"
import FilterColumns from "../components/filters/FilterColumns"
import ExportToCSV from "../components/export/ExportToCSV"
import FiltersDropDowns from "../components/filters/FiltersDropDowns"
import FiltersSearch from "../components/filters/FiltersSearch"
import SearchInput from "../components/forms/input/SearchInput"
import { SelectMultiple, Select } from "../components/forms"
import FilterDate from "../components/filters/FilterDate"
import generateColor from "../utils/generateColor"
import FilterSeparator from "../components/filters/FilterSeparator"
import FiltersTitle from "../components/filters/FiltersTitle"
import FiltersBar from "../components/filters/FiltersBar"

class TablePlayerTargets extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      actions: [],
      categories: [],
      selectedCategories: [],
      times: [],
      selectedTimes: [],
      columns: [],
      searchText: "",
      startDate: null,
      endDate: null,
    }
  }

  componentDidMount() {
    this.prepareData()
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.prepareData()
    }
  }

  prepareData = () => {
    var targets = this.props.targets.filter((d) => {
      if (this.state.startDate && this.state.startDate > d.endDate) return false
      if (this.state.endDate && this.state.endDate < d.endDate) return false
      return true
    })
    var categories = []
    var selectedCategories = []
    var times = []
    var selectedTimes = []

    //  chercher les elements a déselectionner du localstorage
    var unselectedCategories = localStorage.getItem(
      "TablePlayerTargets_unselectedCategories"
    )
    if (unselectedCategories)
      unselectedCategories = unselectedCategories.split("|")

    if (targets) {
      times = [
        { key: "current", label: this.props.t("Current targets") },
        { key: "history", label: this.props.t("Archives") },
      ]
      selectedTimes = "current"
      targets = targets.map((target) => {
        if (
          categories &&
          !categories.find((o) => o.key === target.category.id)
        ) {
          categories.push({
            key: target.category.id,
            label: target.category.name,
          })
          // only add to selected if not unselected
          if (
            !unselectedCategories ||
            unselectedCategories.indexOf(target.category.id.toString()) < 0
          )
            selectedCategories.push(target.category.id)
        }
        return target
      })
    }

    const columns = [
      {
        key: "name",
        visible: true,
        label: this.props.t("Name"),
        id: "name",
        width: 300,
        format: (row) => (
          <div className="flex justify-between">
            <span className="text-primary font-weight-bolder mb-0 text-xs 2xl:text-sm">
              {row.name}
            </span>
            <ActionButton
              key={row.id}
              updateForm="updateObjectif"
              deleteForm="deleteObjectif"
              formData={{
                id: row.id,
                playerId: this.props.pId,
                playerIdname: this.props.player
                  ? this.props.player.displayName
                  : null,
                name: row.name,
                categoryId: row.categoryId,
                description: row.description,
                startDate: row.startDate,
                startDatename: moment(row.startDate).format("D MMM YYYY"),
                endDate: row.endDate,
              }}
              refreshData={() => this.props.updateTargets(this.props.pId)}
            />
          </div>
        ),
      },
      {
        key: "category.name",
        visible: true,
        format: (row) => 
          <span  className="inline-flex items-center px-3 py-0.5 rounded-full text-xs 2xl:text-sm font-medium bg-yellow-100 text-yellow-800" style={{color: "#FFFFFF", backgroundColor: generateColor(row.category.name)}}>
            {row.category.name}
          </span>,
        id: "category.name",
        label: this.props.t("Category"),
        width: 200,
        filter: {
          name: "SelectColumnFilter",
          type: "includes",
        },
      },
      {
        key: "endDate",
        visible: true,
        format: (row) => <span className=" text-xs 2xl:text-sm">{moment(row.endDate).calendar()}</span>,
        id: "endDate",
        label: this.props.t("EDate"),
        width: 200,
        filter: {
          name: "DateBetweenFilter",
        },
      },
    ]
    var selectedColumns = localStorage.getItem(
      "TablePlayerTargets_selectedColumns"
    )
    if (selectedColumns === null || selectedColumns.length === 0)
      selectedColumns = ["name", "category.name", "endDate"]
    else selectedColumns = selectedColumns.split("|")
    columns.forEach((column) => {
      column.visible = selectedColumns.indexOf(column.key) < 0 ? false : true
    })
    this.setState({
      columns,
      targets,
      categories,
      times,
      selectedColumns,
      selectedCategories,
      selectedTimes,
    })
  }
  // change categories
  onChangeSelectedCategories = (res) => {
    this.setState({ selectedCategories: res }, () => {
      // determiner ce qui n'est pas selectionner
      let unselect = []
      this.state.categories.forEach((a) => {
        if (this.state.selectedCategories.indexOf(a.key) < 0)
          unselect.push(a.key)
      })
      // save unselected elements to localstorage
      localStorage.setItem(
        "TablePlayerTargets_unselectedCategories",
        unselect.join("|")
      )
    })
  }

  // change columns
  onChangeSelectedColumns = (res) => {
    this.setState({ selectedColumns: res }, () => {
      // determiner ce qui est selectionne
      let select = []
      this.state.columns.forEach((a) => {
        if (this.state.selectedColumns.indexOf(a.key) > -1) select.push(a.key)
      })
      // save selected elements to localstorage
      localStorage.setItem(
        "TablePlayerTargets_selectedColumns",
        select.join("|")
      )
    })
  }

  // date filters changed
  onChangeDates = (startDate, endDate) => {
    if (!startDate && !endDate) return;
    var obj = {};
    if (startDate) obj.startDate = startDate;
    if (endDate) obj.endDate = endDate;
    this.setState(obj, () => {
      this.prepareData()
    });
  }

  render() {
    const { t } = this.props
    var targets = this.state.targets
    var columns = this.state.columns
    var selectedColumns = this.state.selectedColumns
    var selectedCategories = this.state.selectedCategories
    var selectedTimes = this.state.selectedTimes
    var searchText = this.state.searchText
    if (columns) {
      columns = columns.filter((o) =>
        selectedColumns.some((item) => item === o.key)
      )
    }

    if (selectedTimes === "current") {
      targets = targets.filter((o) => moment(o.endDate).isAfter(moment()))
    } else if (selectedTimes === "history") {
      targets = targets.filter((o) => moment(o.endDate).isBefore(moment()))
    }
    if (targets) {
      if (selectedTimes === "current") {
        targets = targets.sort((a, b) =>
          a.category.name === b.category.name
            ? 0
            : a.category.name < b.category.name
            ? -1
            : 1
        )
      } else if (selectedTimes === "history") {
        targets = sortTargetSetByEndDate(targets)
      }
    }
    if (targets) {
      targets = targets.filter((o) =>
        selectedCategories.some((item) => item === o.category.id)
      )
    }
    if (searchText.length > 2) {
      targets = targets.filter((o) =>
        o.name.toLowerCase().includes(searchText.toLowerCase())
      )
    }
    return (
      <>
        <FiltersBar
          components={
            <>
              <FiltersTitle title={targets ? targets.length + " " + t("Targets").toLowerCase() : ""} />
              <FilterSeparator />
              <Select
                options={this.state.times}
                value={this.state.selectedTimes}
                cclassName="mx-2 rounded-md focus:ring-indigo-500 focus:border-indigo-500 block  text-xs 2xl:text-sm border-gray-300"
                onChange={(res) => this.setState({ selectedTimes: res })}
                fixedLabel={t("Period")}
              />
              <FilterSeparator />
              <FiltersSearch
                search={
                  <SearchInput
                    value={this.state.searchText}
                    onChange={(searchText) => this.setState({ searchText })}
                  />
                }
              />
              <FilterSeparator />
              <FiltersDropDowns
                selects={[
                  <SelectMultiple
                    options={this.state.categories}
                    keys={this.state.selectedCategories}
                    className="mx-2"
                    onChange={this.onChangeSelectedCategories}
                    fixedLabel={t("Category")}
                  />,
                ]}
              />
              <FilterSeparator />
              <FilterColumns
                columns={
                  <SelectMultiple
                    options={this.state.columns}
                    keys={this.state.selectedColumns}
                    className="mx-2"
                    onChange={this.onChangeSelectedColumns}
                    fixedLabel={t("Active columns")}
                  />
                }
              />
              <FilterSeparator />
              <ExportToCSV
                columns={columns}
                jsonData={targets}
                fileName={t("Targets") + ".csv"}
              />
              <FilterSeparator />
              <FilterDate onChange={this.onChangeDates} />
              <FilterSeparator />
            </>
          }
          buttons={this.props.buttons}
        />
        <TableSimple columns={columns} data={targets} />
      </>
    )
  }
}

export default withTranslation()(TablePlayerTargets)
