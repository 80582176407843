import React from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import GeneratedForm from "../../utils/forms/generateForm";
import Button from "../../components/buttons/Button";
import Tab from "../tab/Tab";
import SubTab from "../tab/SubTab";
import logicRoutes from "../../utils/logicRoutes";
import getUserProfile from "../../utils/auth/getUserProfile";
import visits from "../../utils/requests/visits";

class Page extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      advancedSearch: false,
      isTourOpen: true
    };
    this.changeTab = this.changeTab.bind(this);
  }


  componentDidUpdate(prevProps) {
    if (!prevProps.tab) {
      if (prevProps.tab !== this.props.tab) {
        this.setState({ selectedTab: this.props.tab[0] });
      }
    } else {
      if (prevProps.tab !== this.props.tab) {
        var tab = this.state.selectedTab &&
          this.props.tab.find(
            (t) => t.title === this.state.selectedTab.title
          ) !== null
          ? this.props.tab.find(
            (t) => t.title === this.state.selectedTab.title
          )
          : this.props.tab[0]
        this.setState({
          selectedTab: tab
        });
        if (tab.tab && tab.tab.length) {
          if (localStorage.getItem(this.props.match.path + "-" + tab.title + "-subtab")) {
            this.setState({ selectedSubTab: localStorage.getItem(this.props.match.path + "-" + tab.title + "-subtab") })
          } else {
            localStorage.setItem(this.props.match.path + "-" + this.state.selectedTab.title + "-subtab", tab.tab[0]);
            this.setState({ selectedSubTab: tab.tab[0] });
          }
        }
      }
    }
  }

  componentDidMount() {
    if (!this.props.tab || !this.props.tab[0]) return this.triggerPageTracker();
    if (
      localStorage.getItem(this.props.match.path + "-tab") &&
      this.props.tab.find(
        (t) =>
          t.title === localStorage.getItem(this.props.match.path + "-tab")
      )
    ) {
      var tab = this.props.tab.find(
        (t) =>
          t.title ===
          localStorage.getItem(this.props.match.path + "-tab")
      )
      this.setState({
        selectedTab: tab
      },() => {
        this.triggerPageTracker()
      });
      if (tab.tab && tab.tab.length) {
        if (localStorage.getItem(this.props.match.path + "-" + tab.title + "-subtab")) {
          this.setState({ selectedSubTab: localStorage.getItem(this.props.match.path + "-" + tab.title + "-subtab") })
        } else {
          localStorage.setItem(this.props.match.path + "-" + this.state.selectedTab.title + "-subtab", tab.tab[0]);
          this.setState({ selectedSubTab: tab.tab[0] });
        }
      }
    } else {
      this.setState({ selectedTab: this.props.tab[0] },() => {
        this.triggerPageTracker()
      });
      if (this.props.tab[0].tab && this.props.tab[0].tab.length) {
        this.setState({ selectedSubTab: this.props.tab[0].tab[0] })
      }
    }
  }

  changeTab = (tab) => {
    if (this.props.match) {
      localStorage.setItem(this.props.match.path + "-tab", tab.title);
      if (tab.tab && tab.tab.length) {
        if (localStorage.getItem(this.props.match.path + "-" + tab.title + "-subtab")) {
          this.setState({ selectedSubTab: localStorage.getItem(this.props.match.path + "-" + tab.title + "-subtab") })
        } else {
          localStorage.setItem(this.props.match.path + "-" + this.state.selectedTab.title + "-subtab", tab.tab[0]);
          this.setState({ selectedSubTab: tab.tab[0] });
        }
      }
    }
    this.setState({ selectedTab: tab },() => {
      this.triggerPageTracker()
    });
  };

  changeSubTab = (stab) => {
    if (this.props.match && this.state.selectedTab) {
      localStorage.setItem(this.props.match.path + "-" + this.state.selectedTab.title + "-subtab", stab.title);
    }
    this.setState({ selectedSubTab: stab })
  }

  triggerPageTracker = () => {
    if (navigator.onLine) {
      var host = window.location.host;
      var modu =  logicRoutes.find((o) => o.path === this.props.match.path)
      ? logicRoutes.find((o) => o.path === this.props.match.path).module
      : "Core";
      var page = this.state.selectedTab ? (logicRoutes.find((o) => o.path === this.props.match.path).feature ? logicRoutes.find((o) => o.path === this.props.match.path).feature + " - " + this.state.selectedTab.title : this.state.selectedTab.title)
      : (logicRoutes.find((o) => o.path === this.props.match.path)
      ? logicRoutes.find((o) => o.path === this.props.match.path).feature
      : "Dashboard");
      getUserProfile().then((user) => {
        var userId = user.sub;
        visits(userId, modu, page, host).then((res) => {
            if(this.props.haveReactTour)
            this.props.modifyTour(res)
          });
      });
    }
  }

  render() {
    return (
      <>
        <div className="bg-white shadow">
          {this.props.pageTitle ? (
            <div className="mx-auto py-2 2xl:py-4 pb-0 2xl:pb-1 px-2 sm:px-6 lg:ml-4 lg:px-4 lg:flex lg:items-center lg:justify-between">
              <div className="flex-1 min-w-0">
                <h2 className="text-xl 2xl:text-2xl font-bold leading-7 text-indigo-800 sm:truncate">
                  {this.props.backPage &&
                    <button
                      className="btn btn-primary btn-icon btn-sm btn-circle mr-5"
                      onClick={(o) => this.props.history.goBack()}
                    >
                      <svg className="text-indigo-800 h-4 2xl:h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
                      </svg>
                    </button>
                  }
                  {this.props.pageTitle}
                  {(this.props.pageTitle && this.props.haveReactTour) &&
                    <button className="ml-1" onClick={() =>  {this.props.openTour()}}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </button>
                  }
                </h2>
              </div>

              <div className="d-flex align-items-center">
                {this.props.actions ? (
                  <div>
                    {this.props.actions.addForm ? (
                      <GeneratedForm
                        component={(show, text, disabled, tooltip) => (
                          <Button
                            onClick={show}
                            disabled={disabled && !this.props.actions.activeUpdateException}
                            title={tooltip}
                          >
                            {text}
                          </Button>
                        )}
                        callback={(res) =>
                          this.props.refreshData
                            ? this.props.refreshData
                              ? this.props.refreshData(res)
                              : null
                            : null
                        }
                        formName={this.props.actions.addForm}
                        method={
                          this.props.actions.method
                            ? this.props.actions.method
                            : null
                        }
                        formData={
                          this.props.actions.formData
                            ? this.props.actions.formData
                            : null
                        }
                      />
                    ) : null}
                    {this.props.actions.other
                      ? this.props.actions.other.map((a, key) => (
                        <span key={key}>{a}</span>
                      ))
                      : null}
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}

          <div>
            {this.state.selectedTab ? (
              <>
                <div className="h-12 2xl:h-16 lg:flex w-full border-b border-gray-200 dark:border-gray-800 px-10">
                  {this.props.tab && this.props.tab.filter(o => o.visible === null || o.visible === true) && this.props.tab.filter(o => o.visible === null || o.visible === true).length ? (
                    <Tab
                      selectedTab={this.state.selectedTab}
                      tab={this.props.tab.filter(o => o.visible === null || o.visible === true).map((t, index) => {
                        return { key: index, label: t.title };
                      })}
                      onChange={(index) =>
                        this.changeTab(this.props.tab.filter(o => o.visible === null || o.visible === true)[index])
                      }
                    />
                    ) : null}

                    {(!this.props.pageTitle && this.props.haveReactTour) &&
                      <button className="ml-1 " onClick={() => {this.props.openTour()}}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-5"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </button>
                    }


                  {this.state.selectedTab &&
                    this.state.selectedTab["actions"] ? (
                      <div
                        key={this.state.selectedTab.title}
                        className="ml-auto flex items-center"
                      >
                        {this.state.selectedTab["actions"].addForm ? (
                          typeof this.state.selectedTab["actions"].addForm ===
                            "object" ? (
                              this.state.selectedTab["actions"].addForm.map(
                                (aF, key) => {
                                  return (
                                    <GeneratedForm
                                      key={key}
                                      component={(show, text, disabled, tooltip) => (
                                        <Button
                                          onClick={show}
                                          disabled={disabled}
                                          title={tooltip}
                                        >
                                          {text}
                                        </Button>
                                      )}
                                      callback={(res) =>
                                        this.state.selectedTab["actions"]
                                          .refreshData
                                          ? this.state.selectedTab[
                                            "actions"
                                          ].refreshData(res)
                                          : null
                                      }
                                      formName={aF}
                                      method={
                                        this.state.selectedTab["actions"].method
                                          ? this.state.selectedTab["actions"].method
                                          : null
                                      }
                                      formData={
                                        this.state.selectedTab["actions"].formData && aF !== "createTennisTournamentNational"
                                          ? this.state.selectedTab["actions"].formData
                                          : null
                                      }
                                    />
                                  );
                                }
                              )
                            ) : (
                              <GeneratedForm
                                component={(show, text, disabled, tooltip) => (
                                  <Button
                                    onClick={show}
                                    disabled={disabled}
                                    title={tooltip}
                                  >
                                    {text}
                                  </Button>
                                )}
                                callback={(res) =>
                                  this.state.selectedTab["actions"].refreshData
                                    ? this.state.selectedTab["actions"].refreshData(
                                      res
                                    )
                                    : null
                                }
                                formName={this.state.selectedTab["actions"].addForm}
                                method={
                                  this.state.selectedTab["actions"].method
                                    ? this.state.selectedTab["actions"].method
                                    : null
                                }
                                formData={
                                  this.state.selectedTab["actions"].formData
                                    ? this.state.selectedTab["actions"].formData
                                    : null
                                }
                              />
                            )
                        ) : null}
                        <></>
                      </div>
                    ) : (
                      ""
                    )}
                </div>
                {this.state.selectedTab.tab && this.state.selectedTab.tab.length ?
                  <div className="h10 2xl:h-16 lg:flex w-full px-10 bg-gray-100">
                    <SubTab
                      selectedTab={this.state.selectedSubTab}
                      tab={this.state.selectedTab.tab.map((t, index) => {
                        return { key: index, label: t.title };
                      })}
                      onChange={(index) =>
                        this.changeSubTab(this.state.selectedTab.tab[index])
                      }
                    />
                    {this.state.selectedSubTab &&
                      this.state.selectedSubTab["actions"] ? (
                        <div
                          key={this.state.selectedSubTab.title}
                          className="ml-auto flex items-center"
                        >
                          {this.state.selectedSubTab["actions"].addForm ? (
                            typeof this.state.selectedSubTab["actions"].addForm ===
                              "object" ? (
                                this.state.selectedSubTab["actions"].addForm.map(
                                  (aF, key) => {
                                    return (
                                      <GeneratedForm
                                        key={key}
                                        component={(show, text, disabled, tooltip) => (
                                          <Button
                                            onClick={show}
                                            disabled={disabled}
                                            title={tooltip}
                                          >
                                            {text}
                                          </Button>
                                        )}
                                        callback={(res) =>
                                          this.state.selectedSubTab["actions"]
                                            .refreshData
                                            ? this.state.selectedSubTab[
                                              "actions"
                                            ].refreshData(res)
                                            : null
                                        }
                                        formName={aF}
                                        method={
                                          this.state.selectedSubTab["actions"].method
                                            ? this.state.selectedSubTab["actions"].method
                                            : null
                                        }
                                        formData={
                                          this.state.selectedSubTab["actions"].formData
                                            ? this.state.selectedSubTab["actions"]
                                              .formData
                                            : null
                                        }
                                      />
                                    );
                                  }
                                )
                              ) : (
                                <GeneratedForm
                                  component={(show, text, disabled, tooltip) => (
                                    <Button
                                      onClick={show}
                                      disabled={disabled}
                                      title={tooltip}
                                    >
                                      {text}
                                    </Button>
                                  )}
                                  callback={(res) =>
                                    this.state.selectedSubTab["actions"].refreshData
                                      ? this.state.selectedSubTab["actions"].refreshData(
                                        res
                                      )
                                      : null
                                  }
                                  formName={this.state.selectedSubTab["actions"].addForm}
                                  method={
                                    this.state.selectedSubTab["actions"].method
                                      ? this.state.selectedSubTab["actions"].method
                                      : null
                                  }
                                  formData={
                                    this.state.selectedSubTab["actions"].formData
                                      ? this.state.selectedSubTab["actions"].formData
                                      : null
                                  }
                                />
                              )
                          ) : null}
                          <></>
                        </div>
                      ) : (
                        ""
                      )}
                  </div>
                  : null
                }

                <div className="overflow-auto">
                  <div>
                    {this.state.selectedTab["component"] ? this.state.selectedTab["component"](
                      this.state.selectedTab["data"]
                    ) : null}
                  </div>

                  {this.state.selectedSubTab && this.state.selectedSubTab["component"] ?
                    <div>{this.state.selectedSubTab["component"](this.state.selectedSubTab["data"])}</div>
                    : null}
                </div>
              </>
            ) : (
                ""
              )}
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(withRouter(Page));
