import React from "react";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import Page from "../components/page/Page";
import moment from "moment";
import { loadSeasons, getSeasons, getCurrentSeason } from "../utils/indexedDB/events/handleEvents"
import { getPlayers, loadPlayers } from "../utils/indexedDB/users/handleUser";
import {
  getAnthropometricDataCurrent,
  getAnthropometricMeasures,
  getAnthropometricMeasuresActive,
  getPhysicalTestsDataCurrent,
  getPhysicalTestsMeasures,
  getPhysicalTestsMeasuresActive,
  loadAnthropometricDataCurrent,
  loadAnthropometricDataCurrentByPlayers,
  loadAnthropometricMeasures,
  loadAnthropometricMeasuresActive,
  loadPhysicalTestsDataCurrentByPlayers,
  loadPhysicalTestsMeasures,
  loadPhysicalTestsMeasuresActive,
  loadAnthropometricThreshold,
  loadPhysicalTestThreshold
} from "../utils/indexedDB/assessment/handleAssessment";
import ButtonLight from "../components/buttons/ButtonLight";
import TableAnthropometricMeasures from "../tables/TableAnthropometricMeasures";
import TableAnthropometry from "../tables/TableAnthropometry";
import GeneratedForm from "../utils/forms/generateForm";
import SubTab from "../components/tab/SubTab";
import TablePhysicalTests from "../tables/TablePhysicalTests";
import TablePhysicalTestsMeasures from "../tables/TablePhysicalTestsMeasures";
import TableAnthropometricThreshold from "../tables/TableAnthropometricThreshold";
import TablePhysicalTestThreshold from "../tables/TablePhysicalTestThreshold";
import {
  getTargets,
  loadTargets,
  loadTargetsByPlayers,
  loadPeriodicReportsByPlayer,
  getPeriodicReports
} from "../utils/indexedDB/management/handleManagement";
import TableTargets from "../tables/TableTargets";
import { getGpsDataByPlayer, getMonitoringTestsDataCurrent, getMonitoringTestsMeasures, getMonitoringTestsMeasuresActive, getRpeDataByPlayer, loadGpsDataByPlayersAndDates, loadMonitoringTestsDataCurrent, loadMonitoringTestsDataCurrentByPlayers, loadMonitoringTestsMeasures, loadMonitoringTestsMeasuresActive, loadRpeDataByPlayersAndDates } from "../utils/indexedDB/monitoring/handleMonitoring";
import TableMonitoringTestsMeasures from "../tables/TableMonitoringTestsMeasures";
import TableMonitoringTests from "../tables/TableMonitoringTests";
import TableRPE from "../tables/TableRPE";
import getUserProfile from "../utils/auth/getUserProfile";
import { getPlayTimesByPlayer, loadPlayTimesByPlayers } from "../utils/indexedDB/competition/handleCompetition";
import EditableTableAnthropo from "../components/forms/specificComponent/editableTableAnthropo";
import EditableTablePhysicalTests from "../components/forms/specificComponent/editableTablePhysicalTests";
import TablePeriodicReport from "../tables/TablePeriodicReport";
import AnthropometricThreshold from "../utils/forms/other/AnthropometricThreshold";
import PhysicalTestsThreshold from "../utils/forms/other/PhysicalTestsThreshold";

class Management extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      players: null,
      anthropometricMeasures: null,
      anthropometricMeasuresActive: null,
      anthropometryView: "results",
      physicalTestsMeasures: null,
      physicalTestsMeasuresActive: null,
      physicalTestsView: "results",
      monitoringTestsMeasures: null,
      monitoringTestsMeasuresActive: null,
      monitoringTestsView: "results",
      rpeDataView: "table",
      seasons: [],
      selectedSeason: null,
      physicalTestThreshold: null,
      anthropometricThreshold: null
    };
  }

  componentDidMount() {
    getUserProfile().then((profile) => {
      this.setState({ sport: profile.sport, role: profile.role });
      if (profile.sport === "Tennis") {
        this.handleTargets();
        this.handleAnthropometry();
        this.handlePhysicalTests();
        //this.handleRPE();
        this.handlePeriodicReportsByPlayers();
      }
      else {
        this.handleAnthropometry();
        this.handlePhysicalTests();
        this.handleRPE();
        this.handleGPS();
        this.handleMonitoringTests();
        this.handlePlayTimes();
        this.handleTargets();
        this.handlePeriodicReportsByPlayers();
      }
    });
  }

  handleTargets() {
    var promises = [];
    getPlayers().then((players) => {
      if (players && players.length) {
        players.forEach((player) => {
          promises.push(
            getTargets(player.id).then((data) => {
              if (data) {
                data.map((target) => (target.player = player));
              }
              return data;
            })
          );
        });
        Promise.all(promises).then((targetss) => {
          var targets = [];
          if (targetss) {
            targetss.map(
              (targetssItem) => (targets = targets.concat(targetssItem))
            );
          }
          this.setState({ targets, players });
        });
      }
    });

    if (navigator.onLine) {
      loadPlayers().then((players) => {
        var playersList = [];
        players.forEach((player) => {
          playersList.push(player.id);
        });
        loadTargetsByPlayers(playersList).then(targets => {
          if (targets) {
            targets.forEach(target => {
              target.player = players.find(o => o.id === target.playerId);
            })
          }
          this.setState({ targets, players });
        });
      });
    }
  }

  handlePhysicalTests() {
    this.setState({ getPhysicalTestsFromAPI: false });
    getPhysicalTestsMeasures().then((physicalTestsMeasures) =>
      physicalTestsMeasures && physicalTestsMeasures.length
        ? this.setState({ physicalTestsMeasures })
        : null
    );
    getPhysicalTestsMeasuresActive().then((physicalTestsMeasuresActive) =>
      physicalTestsMeasuresActive && physicalTestsMeasuresActive.length
        ? this.setState({ physicalTestsMeasuresActive })
        : null
    );
    var promises = [];
    getPlayers().then((playersP) => {
      if (playersP && playersP.length && !this.state.getPhysicalTestsFromAPI) {
        playersP.forEach((player) => {
          promises.push(
            getPhysicalTestsDataCurrent(player.id).then((data) => {
              player.physicalTestsData = data;
              return player;
            })
          );
        });
        Promise.all(promises).then((playersCompleted) =>
          playersCompleted && playersCompleted.length
            ? this.setState({ playersP: playersCompleted })
            : null
        );
      }
    });

    if (navigator.onLine) {
      loadPhysicalTestThreshold().then(physicalTestThreshold => this.setState({ physicalTestThreshold }))
      loadPhysicalTestsMeasures().then((physicalTestsMeasures) =>
        physicalTestsMeasures ? this.setState({ physicalTestsMeasures }) : null
      );
      loadPhysicalTestsMeasuresActive().then((physicalTestsMeasuresActive) =>
        physicalTestsMeasuresActive
          ? this.setState({ physicalTestsMeasuresActive })
          : null
      );
      loadPlayers().then((players) => {
        if (players) {
          var playersList = [];
          players.forEach((player) => {
            playersList.push(player.id);
          });
          loadPhysicalTestsDataCurrentByPlayers(playersList).then(res => {
            if (res) {
              players.forEach(player => {
                player.physicalTestsData = res.filter(o => o.playerId === player.id);
              })
            }
          });
          this.setState({ playersP: players, getPhysicalTestsFromAPI: true });
        }
      });
    }
  }

  handleAnthropometry() {
    this.setState({ getAnthropometryFromAPI: false })
    getAnthropometricMeasures().then((anthropometricMeasures) =>
      anthropometricMeasures && anthropometricMeasures.length
        ? this.setState({ anthropometricMeasures })
        : null
    );
    getAnthropometricMeasuresActive().then((anthropometricMeasuresActive) =>
      anthropometricMeasuresActive && anthropometricMeasuresActive.length
        ? this.setState({ anthropometricMeasuresActive })
        : null
    );

    var promises = [];
    getPlayers().then((players) => {
      if (players && players.length && !this.state.getAnthropometryFromAPI) {
        players.forEach((player) => {
          promises.push(
            getAnthropometricDataCurrent(player.id).then((data) => {
              player.anthropometricData = data;
              return player;
            })
          );
        });
        Promise.all(promises).then((playersCompleted) => {
          this.setState({ playersA: playersCompleted });
        });
      }
    });

    if (navigator.onLine) {
      loadAnthropometricThreshold().then((anthropometricThreshold => this.setState({ anthropometricThreshold })))
      loadAnthropometricMeasures().then((anthropometricMeasures) =>
        anthropometricMeasures
          ? this.setState({ anthropometricMeasures })
          : null
      );
      loadAnthropometricMeasuresActive().then((anthropometricMeasuresActive) =>
        anthropometricMeasuresActive
          ? this.setState({ anthropometricMeasuresActive })
          : null
      );
      loadPlayers().then((players) => {
        var playersList = [];
        players.forEach((player) => {
          playersList.push(player.id);
        });
        loadAnthropometricDataCurrentByPlayers(playersList).then(res => {
          if (res) {
            players.forEach(player => {
              player.anthropometricData = res.filter(o => o.playerId === player.id);
            })
          }
        });
        this.setState({ playersA: players, getAnthropometryFromAPI: true });
      });
    }
  }

  handleMonitoringTests() {
    getMonitoringTestsMeasures().then((monitoringTestsMeasures) =>
      monitoringTestsMeasures && monitoringTestsMeasures.length
        ? this.setState({ monitoringTestsMeasures })
        : null
    );
    getMonitoringTestsMeasuresActive().then((monitoringTestsMeasuresActive) =>
      monitoringTestsMeasuresActive && monitoringTestsMeasuresActive.length
        ? this.setState({ monitoringTestsMeasuresActive })
        : null
    );
    var promises = [];
    getPlayers().then((playersP) => {
      if (playersP && playersP.length) {
        playersP.forEach((player) => {
          promises.push(
            getMonitoringTestsDataCurrent(player.id).then((data) => {
              player.monitoringTestsData = data;
              return player;
            })
          );
        });
        Promise.all(promises).then((playersCompleted) =>
          playersCompleted && playersCompleted.length
            ? this.setState({ playersM: playersCompleted })
            : null
        );
      }
    });

    if (navigator.onLine) {
      loadMonitoringTestsMeasures().then((monitoringTestsMeasures) =>
        monitoringTestsMeasures ? this.setState({ monitoringTestsMeasures }) : null
      );
      loadMonitoringTestsMeasuresActive().then((monitoringTestsMeasuresActive) =>
        monitoringTestsMeasuresActive
          ? this.setState({ monitoringTestsMeasuresActive })
          : null
      );
      loadPlayers().then((players) => {
        if (players) {
          var playersList = [];
          players.forEach((player) => {
            playersList.push(player.id);
          });
          loadMonitoringTestsDataCurrentByPlayers(playersList).then(res => {
            if (res) {
              players.forEach(player => {
                player.monitoringTestsData = res.filter(o => o.playerId === player.id);
              })
            }
          });
          this.setState({ playersM: players });
        }
      });
    }
  }

  handleRPE() {
    var promises = [];
    getPlayers().then((playersR) => {
      playersR.forEach((player) => {
        promises.push(
          getRpeDataByPlayer(player.id).then((data) => {
            player.rpeData = data;
            var dt0 = moment().add(1, "days").toDate().toISOString();
            var dt1 = moment().toDate().toISOString();
            var dt2 = moment().add(-1, "days").toDate().toISOString();
            var dt3 = moment().add(-2, "days").toDate().toISOString();
            var dt4 = moment().add(-3, "days").toDate().toISOString();
            var dt5 = moment().add(-4, "days").toDate().toISOString();
            var dt6 = moment().add(-5, "days").toDate().toISOString();
            var dt7 = moment().add(-6, "days").toDate().toISOString();
            var dt8 = moment().add(-7, "days").toDate().toISOString();
            var currentDayValues = player.rpeData.filter(o => moment(o.date).isAfter(dt1) && moment(o.date).isBefore(dt0));
            var currentDayValue = 0;
            currentDayValues.forEach(value => currentDayValue += value.srpe);
            player.currentDayValue = currentDayValue;
            var previousDayValues = player.rpeData.filter(o => moment(o.date).isAfter(dt2) && moment(o.date).isBefore(dt1));
            var previousDayValue = 0;
            previousDayValues.forEach(value => previousDayValue += value.srpe);
            player.previousDayValue = previousDayValue;
            var previousPreviousDayValues = player.rpeData.filter(o => moment(o.date).isAfter(dt3) && moment(o.date).isBefore(dt2));
            var previousPreviousDayValue = 0;
            previousPreviousDayValues.forEach(value => previousPreviousDayValue += value.srpe);
            player.previousPreviousDayValue = previousPreviousDayValue;
            var previousPreviousPreviousDayValues = player.rpeData.filter(o => moment(o.date).isAfter(dt4) && moment(o.date).isBefore(dt3));
            var previousPreviousPreviousDayValue = 0;
            previousPreviousPreviousDayValues.forEach(value => previousPreviousPreviousDayValue += value.srpe);
            player.previousPreviousPreviousDayValue = previousPreviousPreviousDayValue;
            var previousPreviousPreviousPreviousDayValues = player.rpeData.filter(o => moment(o.date).isAfter(dt5) && moment(o.date).isBefore(dt4));
            var previousPreviousPreviousPreviousDayValue = 0;
            previousPreviousPreviousPreviousDayValues.forEach(value => previousPreviousPreviousPreviousDayValue += value.srpe);
            player.previousPreviousPreviousPreviousDayValue = previousPreviousPreviousPreviousDayValue;
            var previousPreviousPreviousPreviousPreviousDayValues = player.rpeData.filter(o => moment(o.date).isAfter(dt6) && moment(o.date).isBefore(dt5));
            var previousPreviousPreviousPreviousPreviousDayValue = 0;
            previousPreviousPreviousPreviousPreviousDayValues.forEach(value => previousPreviousPreviousPreviousPreviousDayValue += value.srpe);
            player.previousPreviousPreviousPreviousPreviousDayValue = previousPreviousPreviousPreviousPreviousDayValue;
            var previousPreviousPreviousPreviousPreviousPreviousDayValues = player.rpeData.filter(o => moment(o.date).isAfter(dt7) && moment(o.date).isBefore(dt6));
            var previousPreviousPreviousPreviousPreviousPreviousDayValue = 0;
            previousPreviousPreviousPreviousPreviousPreviousDayValues.forEach(value => previousPreviousPreviousPreviousPreviousPreviousDayValue += value.srpe);
            player.previousPreviousPreviousPreviousPreviousPreviousDayValue = previousPreviousPreviousPreviousPreviousPreviousDayValue;
            var previousPreviousPreviousPreviousPreviousPreviousPreviousDayValues = player.rpeData.filter(o => moment(o.date).isAfter(dt8) && moment(o.date).isBefore(dt7));
            var previousPreviousPreviousPreviousPreviousPreviousPreviousDayValue = 0;
            previousPreviousPreviousPreviousPreviousPreviousPreviousDayValues.forEach(value => previousPreviousPreviousPreviousPreviousPreviousPreviousDayValue += value.srpe);
            player.previousPreviousPreviousPreviousPreviousPreviousPreviousDayValue = previousPreviousPreviousPreviousPreviousPreviousPreviousDayValue;
            return player;
          })
        );
      });
      Promise.all(promises).then((playersCompleted) => {
        if (playersCompleted && playersCompleted.length) {
          this.setState({ playersR: playersCompleted })
        }
      }
      );
    });
    if (navigator.onLine) {
      loadPlayers().then((players) => {
        if (players) {
          var playersList = [];
          players.forEach((player) => {
            playersList.push(player.id);
          });
          var startDate = moment()
            .add(-2, "weeks")
            .startOf("day")
            .toDate()
            .toISOString();
          var endDate = moment().toDate().toISOString();
          loadRpeDataByPlayersAndDates(playersList, startDate, endDate).then(res => {
            if (res) {
              players.forEach(player => {
                player.rpeData = res.filter(o => o.playerId === player.id);
                var dt0 = moment().add(1, "days").toDate().toISOString();
                var dt1 = moment().toDate().toISOString();
                var dt2 = moment().add(-1, "days").toDate().toISOString();
                var dt3 = moment().add(-2, "days").toDate().toISOString();
                var dt4 = moment().add(-3, "days").toDate().toISOString();
                var dt5 = moment().add(-4, "days").toDate().toISOString();
                var dt6 = moment().add(-5, "days").toDate().toISOString();
                var dt7 = moment().add(-6, "days").toDate().toISOString();
                var dt8 = moment().add(-7, "days").toDate().toISOString();
                var currentDayValues = player.rpeData.filter(o => moment(o.date).isAfter(dt1) && moment(o.date).isBefore(dt0));
                var currentDayValue = 0;
                currentDayValues.forEach(value => currentDayValue += value.srpe);
                player.currentDayValue = currentDayValue;
                var previousDayValues = player.rpeData.filter(o => moment(o.date).isAfter(dt2) && moment(o.date).isBefore(dt1));
                var previousDayValue = 0;
                previousDayValues.forEach(value => previousDayValue += value.srpe);
                player.previousDayValue = previousDayValue;
                var previousPreviousDayValues = player.rpeData.filter(o => moment(o.date).isAfter(dt3) && moment(o.date).isBefore(dt2));
                var previousPreviousDayValue = 0;
                previousPreviousDayValues.forEach(value => previousPreviousDayValue += value.srpe);
                player.previousPreviousDayValue = previousPreviousDayValue;
                var previousPreviousPreviousDayValues = player.rpeData.filter(o => moment(o.date).isAfter(dt4) && moment(o.date).isBefore(dt3));
                var previousPreviousPreviousDayValue = 0;
                previousPreviousPreviousDayValues.forEach(value => previousPreviousPreviousDayValue += value.srpe);
                player.previousPreviousPreviousDayValue = previousPreviousPreviousDayValue;
                var previousPreviousPreviousPreviousDayValues = player.rpeData.filter(o => moment(o.date).isAfter(dt5) && moment(o.date).isBefore(dt4));
                var previousPreviousPreviousPreviousDayValue = 0;
                previousPreviousPreviousPreviousDayValues.forEach(value => previousPreviousPreviousPreviousDayValue += value.srpe);
                player.previousPreviousPreviousPreviousDayValue = previousPreviousPreviousPreviousDayValue;
                var previousPreviousPreviousPreviousPreviousDayValues = player.rpeData.filter(o => moment(o.date).isAfter(dt6) && moment(o.date).isBefore(dt5));
                var previousPreviousPreviousPreviousPreviousDayValue = 0;
                previousPreviousPreviousPreviousPreviousDayValues.forEach(value => previousPreviousPreviousPreviousPreviousDayValue += value.srpe);
                player.previousPreviousPreviousPreviousPreviousDayValue = previousPreviousPreviousPreviousPreviousDayValue;
                var previousPreviousPreviousPreviousPreviousPreviousDayValues = player.rpeData.filter(o => moment(o.date).isAfter(dt7) && moment(o.date).isBefore(dt6));
                var previousPreviousPreviousPreviousPreviousPreviousDayValue = 0;
                previousPreviousPreviousPreviousPreviousPreviousDayValues.forEach(value => previousPreviousPreviousPreviousPreviousPreviousDayValue += value.srpe);
                player.previousPreviousPreviousPreviousPreviousPreviousDayValue = previousPreviousPreviousPreviousPreviousPreviousDayValue;
                var previousPreviousPreviousPreviousPreviousPreviousPreviousDayValues = player.rpeData.filter(o => moment(o.date).isAfter(dt8) && moment(o.date).isBefore(dt7));
                var previousPreviousPreviousPreviousPreviousPreviousPreviousDayValue = 0;
                previousPreviousPreviousPreviousPreviousPreviousPreviousDayValues.forEach(value => previousPreviousPreviousPreviousPreviousPreviousPreviousDayValue += value.srpe);
                player.previousPreviousPreviousPreviousPreviousPreviousPreviousDayValue = previousPreviousPreviousPreviousPreviousPreviousPreviousDayValue;
              })
            }
          });
          this.setState({ playersR: players });
        }
      });
    }
  }

  handleGPS() {
    var promises = [];
    getPlayers().then((playersG) => {
      playersG.forEach((player) => {
        promises.push(
          getGpsDataByPlayer(player.id).then((data) => {
            player.gpsData = data;
            return player;
          })
        );
      });
      Promise.all(promises).then((playersCompleted) =>
        playersCompleted && playersCompleted.length
          ? this.setState({ playersG: playersCompleted })
          : null
      );
    });

    if (navigator.onLine) {
      loadPlayers().then((players) => {
        if (players) {
          var playersList = [];
          players.forEach((player) => {
            playersList.push(player.id);
          });
          var startDate = moment()
            .add(-2, "weeks")
            .startOf("day")
            .toDate()
            .toISOString();
          var endDate = moment().toDate().toISOString();
          loadGpsDataByPlayersAndDates(playersList, startDate, endDate).then(res => {
            if (res) {
              players.forEach(player => {
                player.gpsData = res.filter(o => o.playerId === player.id);
              })
            }
          });
          this.setState({ playersG: players });
        }
      });
    }
  }

  handlePlayTimes() {
    var promises = [];
    getPlayers().then((playersPT) => {
      if (playersPT && playersPT.length) {
        playersPT.forEach((player) => {
          promises.push(
            getSeasons().then(seasons => {

              getPlayTimesByPlayer(player.id).then((data) => {
                data.forEach(playTime => {
                  if (seasons.find(o => moment(playTime.date).isAfter(moment(o.startDate) && moment(playTime.date).isBefore(moment(o.endDate))))) {
                    playTime.season = seasons.find(o => moment(playTime.date).isAfter(moment(o.startDate) && moment(playTime.date).isBefore(moment(o.endDate))));
                  }
                })
                player.playTimes = data;

                return player;
              })
            }
            )
          );
        });
        Promise.all(promises).then((playersCompleted) =>
          playersCompleted && playersCompleted.length
            ? this.setState({ playersPT: playersCompleted })
            : null
        );
      }
    });

    if (navigator.onLine) {
      loadPlayers().then((players) => {
        if (players) {
          var playersList = [];
          players.forEach((player) => {
            playersList.push(player.id);
          });
          loadSeasons(seasons => {
            var now = moment();
            var currentSeason = seasons.find(o => moment(o.startDate).isBefore(now) && moment(o.endDate).isAfter(now));
            loadPlayTimesByPlayers(playersList, moment(currentSeason.startDate).toISOString(), moment(currentSeason.endDate).toISOString()).then(res => {
              if (res) {
                res.forEach(playTime => {
                  if (seasons.find(o => moment(playTime.date).isAfter(moment(o.startDate) && moment(playTime.date).isBefore(moment(o.endDate))))) {
                    playTime.season = seasons.find(o => moment(playTime.date).isAfter(moment(o.startDate) && moment(playTime.date).isBefore(moment(o.endDate))));
                  }
                })
                players.forEach(player => {
                  player.playTimes = res.filter(o => o.playerId === player.id);
                })
              }
            });
          })
          this.setState({ playersM: players });
        }
      })
    }
  }

  handlePeriodicReportsByPlayers() {
    getPeriodicReports().then(playersPR => {
      if (playersPR)
        this.setState({ playersPR })
    })

    if (navigator.onLine) {
      var promises = [];
      loadSeasons().then(seasons => {
        var selectedSeason = null
        if (seasons && seasons.length > 0) {
          selectedSeason = getCurrentSeason(seasons)
          this.setState({ seasons, selectedSeason })
        }
        loadPlayers().then((players) => {
          if (players) {
            players.forEach((player) => {
              promises.push(
                loadPeriodicReportsByPlayer(player.id, player).then(res => {
                  return res
                })
              )
            });
            Promise.all(promises).then((playersCompleted) => {
              if (playersCompleted && playersCompleted.length) {
                var playersPR = [];
                playersCompleted.forEach(p => {
                  if (p)
                    playersPR = playersPR.concat(p)
                })
                playersPR = playersPR.sort((a, b) =>
                  a.displayName === b.displayName ? (moment(a.startDate).isSame(b.startDate)
                    ? 0
                    : moment(a.startDate).isAfter(b.startDate)
                      ? -1
                      : 1) : a.displayName > b.displayName ? 1 : -1
                );
                this.setState({ playersPR })
              }
            });
          }
        })
      });
    }
  }



  render() {
    const { t } = this.props;

    return (
      <Page
        refreshData={() => {
          var promises = [];
          this.state.playersA.forEach((player) => {
            promises.push(
              loadAnthropometricDataCurrent(player.id).then((data) => {
                player.anthropometricData = data;
                return player;
              })
            );
          });
          Promise.all(promises).then((playersCompleted) =>
            playersCompleted
              ? this.setState({ playersA: playersCompleted })
              : null
          );
        }}
        tab={[
          {
            title: t("Targets"),
            visible: true,
            data: this.state.targets,
            component: (data) => {
              return (
                <>
                  <TableTargets targets={data} buttons={[
                    <GeneratedForm
                      component={(show, text) => (
                        <ButtonLight
                          onClick={show}
                          disabled={!navigator.onLine}
                        >
                          <i className="flaticon2-plus"></i> {text}
                        </ButtonLight>
                      )}
                      callback={(res) => {
                        var promisesL = [];
                        loadPlayers().then((players) => {
                          if (players) {
                            players.forEach((player) => {
                              promisesL.push(
                                loadTargets(player.id).then((data) => {
                                  if (data) {
                                    data.map((target) => (target.player = player));
                                  }
                                  return data;
                                })
                              );
                            });

                            Promise.all(promisesL).then((targetss) => {
                              var targets = [];
                              if (targetss) {
                                targetss.map(
                                  (targetssItem) => (targets = targets.concat(targetssItem))
                                );
                              }
                              this.setState({ targets });
                            });
                          }
                        })
                      }
                      }
                      formName="createObjectif"
                      method="POST"
                    />,
                  ]} refresh={() => {
                    var promisesL = [];
                    loadPlayers().then((players) => {
                      if (players) {
                        players.forEach((player) => {
                          promisesL.push(
                            loadTargets(player.id).then((data) => {
                              if (data) {
                                data.map((target) => (target.player = player));
                              }
                              return data;
                            })
                          );
                        });

                        Promise.all(promisesL).then((targetss) => {
                          var targets = [];
                          if (targetss) {
                            targetss.map(
                              (targetssItem) => (targets = targets.concat(targetssItem))
                            );
                          }
                          this.setState({ targets });
                        });
                      }
                    })
                  }} />
                </>
              );
            },
          },
          {
            title: t("Anthropometry"),
            visible: true,
            data: this.state.playersA,
            component: (data) => {
              return (
                <>
                  <SubTab
                    key="anthropometry"
                    tab={[
                      { key: "results", label: t("Results") },
                      { key: "measures", label: t("Measures") },
                      { key: "threshold", label: t("Threshold") }
                    ]}
                    onChange={(key) =>
                      this.setState({ anthropometryView: key })
                    }
                    selectedTab={this.state.anthropometryView}
                  ></SubTab>
                  {this.state.anthropometryView === "results" ? (
                    <TableAnthropometry
                      players={data}
                      measures={this.state.anthropometricMeasuresActive}
                      buttons={[
                        <EditableTableAnthropo callbackRefreshData={() => this.handleAnthropometry()} />
                      ]}
                    />
                  ) : (
                    ""
                  )}
                  {this.state.anthropometryView === "measures" ? (
                    <TableAnthropometricMeasures
                      measures={this.state.anthropometricMeasures}
                      refresh={() => loadAnthropometricMeasures()
                        .then((measures) =>
                          measures ? this.setState({ measures }) : null
                        )
                        .then((res) =>
                          loadAnthropometricMeasuresActive().then((measuresActive) =>
                            measuresActive ? this.setState({ measuresActive }) : null
                          )
                        )}
                      buttons={[
                        <GeneratedForm
                          component={(show, text) => (
                            <ButtonLight
                              onClick={show}
                              disabled={!navigator.onLine}
                            >
                              <i className="flaticon2-plus"></i> {text}
                            </ButtonLight>
                          )}
                          callback={() => {
                            loadAnthropometricMeasures()
                              .then((anthropometricMeasures) =>
                                anthropometricMeasures
                                  ? this.setState({ anthropometricMeasures })
                                  : null
                              )
                              .then((res) =>
                                loadAnthropometricMeasuresActive().then(
                                  (anthropometricMeasuresActive) =>
                                    anthropometricMeasuresActive
                                      ? this.setState({
                                        anthropometricMeasuresActive,
                                      })
                                      : null
                                )
                              );
                          }}
                          formName="createAnthropometricMeasure"
                          method="POST"
                        />,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                  {this.state.anthropometryView === "threshold" ? (
                    <TableAnthropometricThreshold
                      threshold={this.state.anthropometricThreshold}
                      refresh={() => loadAnthropometricThreshold()
                        .then((anthropometricThreshold) => this.setState({ anthropometricThreshold }))
                      }
                      buttons={[
                        <AnthropometricThreshold
                          component={(show) => (
                            <ButtonLight
                              onClick={show}
                              disabled={!navigator.onLine}
                            >
                              <i className="flaticon2-plus"></i> {t("Create")}
                            </ButtonLight>
                          )}
                          callback={() => loadAnthropometricThreshold()
                            .then((anthropometricThreshold) => this.setState({ anthropometricThreshold }))
                          }
                        />,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </>
              );
            },
          },
          {
            title: t("Physical tests"),
            data: this.state.players,
            visible: true,
            component: (data) => {
              return (
                <>
                  <SubTab
                    key="physicaltests"
                    tab={[
                      { key: "results", label: t("Results") },
                      { key: "measures", label: t("Measures") },
                      { key: "threshold", label: t("Threshold") }
                    ]}
                    onChange={(key) =>
                      this.setState({ physicalTestsView: key })
                    }
                    selectedTab={this.state.physicalTestsView}
                  ></SubTab>
                  {this.state.physicalTestsView === "results" ? (
                    <TablePhysicalTests
                      players={this.state.playersP}
                      measures={this.state.physicalTestsMeasuresActive}
                      buttons={[
                        <EditableTablePhysicalTests callbackRefreshData={() => this.handlePhysicalTests()} />
                      ]}
                    />
                  ) : (
                    ""
                  )}
                  {this.state.physicalTestsView === "measures" ? (
                    <TablePhysicalTestsMeasures
                      measures={this.state.physicalTestsMeasures}
                      refresh={() => loadAnthropometricMeasures()
                        .then((measures) =>
                          measures ? this.setState({ measures }) : null
                        )
                        .then((res) =>
                          loadAnthropometricMeasuresActive().then((measuresActive) =>
                            measuresActive ? this.setState({ measuresActive }) : null
                          )
                        )}
                      buttons={[
                        <GeneratedForm
                          component={(show, text) => (
                            <ButtonLight
                              onClick={show}
                              disabled={!navigator.onLine}
                            >
                              <i className="flaticon2-plus"></i> {text}
                            </ButtonLight>
                          )}
                          callback={() => {
                            loadPhysicalTestsMeasures()
                              .then((physicalTestsMeasures) =>
                                physicalTestsMeasures
                                  ? this.setState({ physicalTestsMeasures })
                                  : null
                              )
                              .then((res) =>
                                loadPhysicalTestsMeasuresActive().then(
                                  (physicalTestsMeasuresActive) =>
                                    physicalTestsMeasuresActive
                                      ? this.setState({
                                        physicalTestsMeasuresActive,
                                      })
                                      : null
                                )
                              );
                          }}
                          formName="createPhysicalTestsMeasure"
                          method="POST"
                        />,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                  {this.state.physicalTestsView === "threshold" ? (
                    <TablePhysicalTestThreshold
                      threshold={this.state.physicalTestThreshold}
                      refresh={() => loadPhysicalTestThreshold()
                        .then((physicalTestThreshold) => this.setState({ physicalTestThreshold }))
                      }
                      buttons={[
                        <PhysicalTestsThreshold
                          component={(show) => (
                            <ButtonLight
                              onClick={show}
                              disabled={!navigator.onLine}
                            >
                              <i className="flaticon2-plus"></i> {t("Create")}
                            </ButtonLight>
                          )}
                          callback={() => loadPhysicalTestThreshold()
                            .then((physicalTestThreshold) => this.setState({ physicalTestThreshold }))
                          }
                        />
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </>
              );
            },
          },

          {
            title: t("Screening"),
            visible: this.state.sport && this.state.sport !== "Tennis",
            data: this.state.playersM,
            component: (data) => {
              return (
                <>
                  <SubTab
                    key="screening"
                    tab={[
                      { key: "results", label: t("Results") },
                      { key: "measures", label: t("Measures") },
                    ]}
                    onChange={(key) =>
                      this.setState({ monitoringTestsView: key })
                    }
                    selectedTab={this.state.monitoringTestsView}
                  ></SubTab>
                  {this.state.monitoringTestsView === "results" ? (
                    <TableMonitoringTests
                      players={data}
                      measures={this.state.monitoringTestsMeasuresActive}
                      buttons={[
                        <GeneratedForm
                          component={(show, text) => (
                            <ButtonLight
                              onClick={show}
                              disabled={!navigator.onLine}
                            >
                              <i className="flaticon2-plus"></i> {text}
                            </ButtonLight>
                          )}
                          callback={() => {
                            var promises = [];
                            this.state.players.forEach((player) => {
                              promises.push(
                                loadMonitoringTestsDataCurrent(player.id).then(
                                  (data) => {
                                    player.monitoringTestsData = data;
                                    return player;
                                  }
                                )
                              );
                            });
                            Promise.all(promises).then((playersCompleted) =>
                              playersCompleted
                                ? this.setState({ playersM: playersCompleted })
                                : null
                            );
                          }}
                          formName="createMonitoringTestsData"
                          method="POST"
                          formData={{
                            playerId: this.state.id,
                            playerIdname: this.state.player
                              ? this.state.player.displayName
                              : null,
                            eventId: null,
                          }}
                        />,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                  {this.state.monitoringTestsView === "measures" ? (
                    <TableMonitoringTestsMeasures
                      measures={this.state.monitoringTestsMeasures}
                      refresh={() => loadMonitoringTestsMeasures()
                        .then((measures) =>
                          measures ? this.setState({ measures }) : null
                        )
                        .then((res) =>
                          loadMonitoringTestsMeasuresActive().then((measuresActive) =>
                            measuresActive ? this.setState({ measuresActive }) : null
                          )
                        )}
                      buttons={[
                        <GeneratedForm
                          component={(show, text) => (
                            <ButtonLight
                              onClick={show}
                              disabled={!navigator.onLine}
                            >
                              <i className="flaticon2-plus"></i> {text}
                            </ButtonLight>
                          )}
                          callback={() => {
                            loadMonitoringTestsMeasures()
                              .then((monitoringTestsMeasures) =>
                                monitoringTestsMeasures
                                  ? this.setState({ monitoringTestsMeasures })
                                  : null
                              )
                              .then((res) =>
                                loadMonitoringTestsMeasuresActive().then(
                                  (monitoringTestsMeasuresActive) =>
                                    monitoringTestsMeasuresActive
                                      ? this.setState({
                                        monitoringTestsMeasuresActive,
                                      })
                                      : null
                                )
                              );
                          }}
                          formName="createMonitoringTestsMeasure"
                          method="POST"
                        />,
                      ]}
                    />
                  ) : (
                    ""
                  )}
                </>
              );
            },
          },
          {
            title: t("Bilans périodiques"),
            visible: false,
            data: this.state.playersPR,
            component: (data) => {
              return (data ?
                <>
                  <TablePeriodicReport
                    players={data}
                    refresh={() => {
                      var promises = [];
                      this.state.players.forEach((player) => {
                        promises.push(
                          loadPeriodicReportsByPlayer(player.id, player).then(res => {
                            return res
                          })
                        );
                      });
                      Promise.all(promises).then((playersCompleted) => {
                        if (playersCompleted && playersCompleted.length) {
                          var playersPR = [];
                          playersCompleted.forEach(p => {
                            if (p)
                              playersPR = playersPR.concat(p)
                          })
                          playersPR = playersPR.sort((a, b) =>
                            a.displayName === b.displayName ? 0 : a.displayName > b.displayName ? 1 : -1
                          );
                          this.setState({ playersPR })
                        }
                      });
                    }}
                    buttons={[
                      <GeneratedForm
                        component={(show, text) => (
                          <ButtonLight
                            onClick={show}
                            disabled={!navigator.onLine}
                          >
                            <i className="flaticon2-plus"></i> {text}
                          </ButtonLight>
                        )}
                        callback={() => {
                          var promises = [];
                          this.state.players.forEach((player) => {
                            promises.push(
                              loadPeriodicReportsByPlayer(player.id, player).then(res => {
                                return res
                              })
                            );
                          });
                          Promise.all(promises).then((playersCompleted) => {
                            if (playersCompleted && playersCompleted.length) {
                              var playersPR = [];
                              playersCompleted.forEach(p => {
                                if (p)
                                  playersPR = playersPR.concat(p)
                              })
                              playersPR = playersPR.sort((a, b) =>
                                a.displayName === b.displayName ? 0 : a.displayName > b.displayName ? 1 : -1
                              );
                              this.setState({ playersPR })
                            }
                          });
                        }}
                        formName="createPeriodicReport"
                        method="POST"
                      />,
                    ]}
                  />
                </>
                : (
                  ""
                )
              );
            },
          },
          {
            title: t("RPE"),
            visible: false,
            data: this.state.playersR,
            component: (data) => {
              return (
                <>
                  <SubTab
                    key="rpe"
                    tab={[
                      { key: "table", label: t("Table") },
                      { key: "chart", label: t("Chart") },
                    ]}
                    onChange={(key) =>
                      this.setState({ rpeDataView: key })
                    }
                    selectedTab={this.state.rpeDataView}
                  ></SubTab>
                  {this.state.rpeDataView === "table" ? (
                    <TableRPE
                      players={data}
                    />
                  ) : (
                    ""
                  )}
                </>
              );
            },
          }
        ]}
      />
    );
  }
}

export default withTranslation()(withRouter(Management));
