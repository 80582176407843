import React from "react";
import { withTranslation } from "react-i18next";

class FiltersSeparator extends React.Component {
  render() {
    return (
        <span className="mx-3 2xl:mx-5 w-1 border-l"></span>
    );
  }
}

export default withTranslation()(FiltersSeparator);
