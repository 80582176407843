import Dexie from "dexie";

const db = new Dexie("Monitoring");
db.version(1).stores({ 
    rpeData: "++id, playerId, eventId", 
    gpsData: "++id, playerId, eventId", 
    gpsMeasures: "++id", 
    gpsMeasuresActive: "++id",
    sessionTimes: "++id, playerId, eventId",
});

db.version(2).stores({ 
  monitoringTestsData: "++id, kind", 
  monitoringTestsDataCurrent: "++id, kind", 
  monitoringTestsMeasures: "++id",
  monitoringTestsMeasuresActive: "++id" 
});

export default db;