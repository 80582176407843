import React from "react";
import { withTranslation } from "react-i18next";
import Loading from "../../components/loaders/Loading";
import Uploading from "../../components/loaders/Uploading";
import Button from "../../components/buttons/Button";
import * as Drawer from "../../components/drawer";
import { patch, post, del } from "../requests/index";
import generateYupObject from "./yupObject";
import EventForm from "./events/EventForm";
import { getQuestionRender, loadChoices } from "./questionRender";
import cogoToast from "cogo-toast";
import getAccessToken from "../auth/getAccessToken";
import * as Alert from "../../components/alert/index";

const form = require("./form.json");
const jsonSchema = require("./jsonSchema.json");
var jwt = require('jsonwebtoken');

/** Form props
 * string: formName
 * React.Component: component
 *
 * optional (for update):
 * object: formData
 * string: customClass (on button)
 * string: mode (with link)
 * string: additionalClass (on button)
 * string: customButtonContent (icon, you can put text or everything you want in)
 * string: additionalStyle (on button)
 */

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      conditionInTheForm: [],
      buttonLabel: "",
      loading: true,
      accessToken: false,
      uploadDataPercentage: false
    };
    this.initForm = this.initForm.bind(this);

    if (this.props.formName !== "event") {
      this.handleSubmit = this.handleSubmit.bind(this);
      this.generateYupObject = generateYupObject.bind(this);
      this.getQuestionRender = getQuestionRender.bind(this);
      this.loadChoices = loadChoices.bind(this);
    }
  }

  callbackSubForm = (creationFormData) => {
    if (creationFormData != null) {
      this.props.callbackAddContent();
      if (creationFormData.name && creationFormData.name !== null) {
        alert(creationFormData.name + " " + this.props.t("created !"));
      } else {
        alert(this.props.t("creation succeeded !"));
      }
    }
  };

  componentDidMount() {
    if (this.props.formName) {
      // get formSetup from form.json
      var formSetup = form[this.props.formName];
      if (formSetup && formSetup !== "") {
        getAccessToken().then(accessToken => {
          this.setState({ buttonLabel: formSetup.title, formSetup, accessToken: jwt.decode(accessToken) });
        })

      }
    }
  }

  initForm() {
    this.setState({ uploadDataPercentage: false, loading: true });
    if (this.props.formName) {
      // get formSetup from form.json
      var formSetup = form[this.props.formName];
      if (formSetup && formSetup !== "") {
        // if method is delete no need to initiate components and yup object
        if (formSetup.method !== "DELETE") {
          var questions = jsonSchema[formSetup.jsonSchema];
          // init questions
          var promises = [];
          questions.forEach((question) => {
            if (question.options) {
              question.listChoices = question.options;
            }
            promises.push(
              (question.widget === "Select" ||
                question.widget === "SelectMultiple") &&
                question.APIfeature &&
                question.APImodule
                ? this.loadChoices(question).then((choices) => {
                  question.listChoices = choices;
                  return question;
                })
                : question
            );
          });

          Promise.all(promises).then((questions) => {
            // init responses
            var responses = this.props.formData
              ? this.props.formData
              : this.state.formData
                ? this.state.formData
                : {};
            this.setState({
              jsonSchema: questions,
              formData: responses ? responses : {},
              loading: false,
            });
            this.schema = this.generateYupObject(
              questions,
              this.props.formData
            );
          });

        } else {
          this.setState({ loading: false });
        }
        this.setState({ formSetup });
      }
    }
  }

  handleSubmit(hide) {
    this.setState({ loading: true, errors: undefined, uploadDataPercentage: false });
    var { APIfeature, APImodule, method, blobStorage, contentType } = this.state.formSetup;
    var jsonSchema = this.state.jsonSchema;

    if (method === "DELETE") {
      del(APImodule, APIfeature, this.props.formData).then((res) => {
        if (res.ok) {
          cogoToast.success(this.props.t("Deletion Successed"), { position: "bottom-right" });
          if (this.state.formSetup.indexedDB) {
            var { db, table } = this.state.formSetup.indexedDB;
            if (db && table) {
              import("../indexedDB/" + db + "/db").then((db) => {
                db.default[table].get(this.props.formData.id).then(res => {
                  if (res) {
                    db.default[table].delete(res.id);
                  }
                });
              });
            }
          }
          this.setState({ loading: false });
          this.props.callback(res);
          hide();
          this.initForm();
        } else {
          this.setState({
            errors: [res.status + " - " + res.url],
            loading: false,
          });
        }
      });
    } else {
      var formData = this.state.formData;

      Object.keys(formData).forEach((d) =>
        formData[d] === null ? delete formData[d] : ""
      );

      jsonSchema.forEach(q => {
        if (formData[q.label] || typeof formData[q.label] === "boolean" || formData[q.label] === 0) {
          switch (q.type) {
            case "number":
              formData[q.label] = Number(formData[q.label]);
              break;
            default:
              break;
          }
        }

      })

      this.schema.isValid(formData).then((valid) => {
        if (valid) {
          if (this.props.postFunction) {
            var postFunction = this.props.postFunction.bind(this);
            postFunction(formData);
          }
          else if (APIfeature && APImodule && method) {
            switch (method) {
              case "POST":
                post(APImodule, APIfeature, formData, blobStorage, (uploadDataPercentage) => { this.setState({ uploadDataPercentage }) }).then(
                  (res) => {
                    if (res.ok) {
                      cogoToast.success(this.props.t("Creation Successed"), { position: "bottom-right" });
                      this.setState({ formData: undefined, loading: false, uploadDataPercentage: false });
                      hide();
                      this.initForm();
                      return res.json();
                    } else {
                      throw new Error(res.status);
                    }
                  }
                ).catch(function (err) {
                  if (err instanceof SyntaxError) {
                    return true;
                  }
                  else {
                    throw err;
                  }
                }).then(resPost => {
                  this.props.callback(resPost);
                }).catch(err => {
                  this.setState({ loading: false, errors: ["Error POST:" + APImodule + ", " + APIfeature + " - " + (err && err.message ? err.message : err)] })
                  cogoToast.error("Error POST:" + APImodule + ", " + APIfeature + " - " + (err && err.message ? err.message : err), { position: "bottom-right" });
                });
                break;
              case "PATCH":
                patch(APImodule, APIfeature, formData, contentType, blobStorage, (uploadDataPercentage) => { this.setState({ uploadDataPercentage }) }).then(
                  (res) => {
                    if (res.ok) {
                      cogoToast.success(this.props.t("Update Successed"), { position: "bottom-right" });
                      this.setState({ formData: undefined, loading: false, uploadDataPercentage: false });
                      hide();
                      this.initForm();
                      return res.json();
                    } else {
                      throw new Error(res.status);
                    }
                  }
                ).then(res => {
                  if (res) {
                    this.props.callback(res);
                  }
                }).catch(err => {
                  this.setState({ loading: false, errors: ["Error POST:" + APImodule + ", " + APIfeature + " - " + (err && err.message ? err.message : err)] })
                  cogoToast.error("Error PATCH " + APImodule + ", " + APIfeature + " - " + (err && err.message ? err.message : err), { position: "bottom-right" });
                });
                break;
              default:
                console.log("Error on method");
            }
          }
        } else {
          this.schema
            .validate(formData, { abortEarly: false })
            .catch(function (err) {
              window.scrollTo(0, 0); // scroll component to 0.0
              return err;
            })
            .then((err) => {
              this.setState({ errors: err, loading: false });
            });
        }
      });
    }
  }

  render() {
    const { t } = this.props;
    if (this.props.formName === "event" && this.props.method) {
      var title = "";
      switch (this.props.method) {
        case "POST":
          title = "Create event";
          break;
        case "PATCH":
          title = "Update event";
          break;
        case "DELETE":
          title = "Delete event";
          break;
        default:
          console.log("Error method");
          break;
      }
      return (
        <EventForm
          component={this.props.component}
          title={title}
          {...this.props}
        />
      );
    } else {
      return this.state.formSetup && this.state.accessToken ? (
        <Drawer.Layout
          component={(show) => {
            let prepareComponent = (event) => {
              show(event);
              this.initForm();
            };
            return this.props.component(
              prepareComponent,
              this.state.buttonLabel ? t(this.state.buttonLabel) : "",
              !navigator.onLine || (navigator.onLine && this.state.accessToken && this.state.formSetup.rights && !this.state.formSetup.rights.includes(this.state.accessToken.role)),
              !navigator.onLine ? t("Navigator offline") : ((navigator.onLine && this.state.accessToken && this.state.formSetup.rights && !this.state.formSetup.rights.includes(this.state.accessToken.role)) ? t("Not enough rights") : null)
            );
          }}
          key={this.props.key}
        >
          {this.state.loading ? (this.state.uploadDataPercentage ?
            <Uploading percentage={this.state.uploadDataPercentage} />
            : <Loading />
          ) : this.state.formSetup ? (
            (hide) => (
              <>
                <Drawer.Header hide={hide}>
                  {t(this.state.formSetup.title, {
                    name: this.props.formData
                      ? this.props.formData.name
                        ? this.props.formData.name
                        : this.props.formData.displayName
                          ? this.props.formData.displayName
                          : ""
                      : "",
                  })}
                </Drawer.Header>
                <Drawer.Body>
                  <>
                    {this.state.errors && this.state.errors.errors ? (
                      <Alert.Danger errors={this.state.errors.inner.map((error) => {
                        var q = this.state.jsonSchema ? this.state.jsonSchema.find(
                          (x) => x.label === error.path
                        ) : null;
                        if (q) {
                          return t(q.title) + " : " + error.message;
                        } else {
                          return error.message;
                        }
                      })} />
                    ) : this.state.errors && this.state.errors.length ? (
                      <Alert.Danger errors={this.state.errors} />
                    ) : (
                      ""
                    )}
                    {this.state.formSetup ? (
                      this.state.formSetup.method === "DELETE" ? (
                        <div>
                          {t(this.state.formSetup.description, {
                            name: this.props.formData
                              ? this.props.formData.name
                                ? this.props.formData.name
                                : this.props.formData.displayName
                                  ? this.props.formData.displayName
                                  : ""
                              : "",
                          })}
                        </div>
                      ) : this.state.jsonSchema ? (
                        this.state.jsonSchema.map((question, key) => {
                          return this.getQuestionRender(question, key);
                        })
                      ) : null
                    ) : null}
                  </>
                  <div style={{ paddingBottom: 40 }}></div>
                </Drawer.Body>
                {this.state.formSetup ? (
                  <Drawer.Footer>
                    <Button
                      loading={this.state.loading}
                      onClick={() => this.handleSubmit(hide)}
                    >
                      {t(this.state.formSetup.button)}
                    </Button>
                  </Drawer.Footer>
                ) : null}
              </>
            )
          ) : null}
        </Drawer.Layout>
      ) : null;
    }
  }
}

export default withTranslation()(Form);
