import React, { Component } from "react"

// Render a SVG Flag by using nationalityId and className props //

class SVGCreate extends Component {
  render() {
    return (
        <svg className="w-3 h-3 my-1 2xl:w-4 2xl:h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" aria-labelledby="title" aria-describedby="desc" role="img" xlink="http://www.w3.org/1999/xlink">
            <path data-name="layer1" fill="currentColor" d="M53 29H35V11h-6v18H11v6h18v18h6V35h18v-6z"></path>
        </svg>
    )
  }
}

export default SVGCreate

/*

    

*/
