import React, { Component } from "react"

// Render a SVG Flag by using nationalityId and className props //

class Flag extends Component {
  render() {
    if (!this.props.nationalityId) return ""
    return (
      <img
        className={this.props.className}
        src={`${
          process.env.PUBLIC_URL
        }/appimages/svgflags/${this.props.nationalityId.toLowerCase()}.svg`}
        alt={this.props.nationalityId}
      />
    )
  }
}

export default Flag

/*

    

*/
