import React from "react";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import Page from "../../../components/page/Page";
import {
  loadRanking,
  loadRankingDates,
  getRanking,
  getRankingDates,
} from "../../../utils/indexedDB/Tennis-Competition/handleCompetitionTennis";
import Loading from "../../../components/loaders/Loading";
import TableRankingITFH from "../../../tables/TableRankingITFH";
import { getCountries, loadCountries } from "../../../utils/indexedDB/core/handleCore";

class Result extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dates: null,
      loading: true,
    };
  }

  componentDidMount() {
    getRankingDates("ItfH").then((dates) => {
      if (dates && dates.length > 0) {
        this.setState({ selectedDate: dates[0] });
        getCountries().then(countries => getRanking(dates[0], "ItfH").then((rItfH) => {
          if (rItfH) {
            rItfH.map(ranking => 
              {
                if (countries.find(o => o.id === ranking.player.nationality))
                {
                  ranking.player.country = countries.find(o => o.id === ranking.player.nationality);
                }
                return ranking;
              }
            )
            this.setState({ rItfH, loading: false });
          }
        }));
        this.setState({ dates: dates });
      }
    });

    if (navigator.onLine) {
      loadRankingDates("ItfH").then((dates) => {
        if (dates !== null && dates && dates.length > 0) {
          loadCountries().then(countries => loadRanking(dates[0], "ItfH").then((rItfH) => {
            if (rItfH) {
              rItfH.map(ranking => 
                {
                  if (countries.find(o => o.id === ranking.player.nationality))
                  {
                    ranking.player.country = countries.find(o => o.id === ranking.player.nationality);
                  }
                  return ranking;
                }
              )
              this.setState({ rItfH, loading: false });
            }
          }));

          this.setState({ selectedDate: dates[0], dates });
        }
      });
    }
  }

  async update(more) {
    this.setState({ loading: true });
    var currentDate = this.state.selectedDate;

    var index = this.state.dates.indexOf(currentDate);
    var selectedDate = currentDate;
    if (more) {
      if (index > 0) {
        selectedDate = this.state.dates[index - 1];
      }
    } else {
      if (index < this.state.dates.length - 1)
        selectedDate = this.state.dates[index + 1];
    }
    getCountries().then(countries => getRanking(selectedDate, "ItfH").then((rItfH) => {
      if (rItfH) {
        rItfH.map(ranking => 
          {
            if (countries.find(o => o.id === ranking.player.nationality))
            {
              ranking.player.country = countries.find(o => o.id === ranking.player.nationality);
            }
            return ranking;
          }
        )
        this.setState({ rItfH, loading: false });
      }
    }));
    if (navigator.onLine) {
      loadCountries().then(countries => loadRanking(selectedDate, "ItfH").then((rItfH) => {
        if (rItfH) {
          rItfH.map(ranking => 
            {
              if (countries.find(o => o.id === ranking.player.nationality))
              {
                ranking.player.country = countries.find(o => o.id === ranking.player.nationality);
              }
              return ranking;
            }
          )
          this.setState({ rItfH, loading: false });
        }
      }));
    }
    this.setState({ selectedDate: selectedDate });
  }

  onChangeDate = (selectedDate) => {
    if (selectedDate) {
      getCountries().then(countries => getRanking(selectedDate, "ItfH").then((rItfH) => {
        if (rItfH) {
          rItfH.map(ranking => 
            {
              if (countries.find(o => o.id === ranking.player.nationality))
              {
                ranking.player.country = countries.find(o => o.id === ranking.player.nationality);
              }
              return ranking;
            }
          )
          this.setState({ rItfH, loading: false });
        }
      }));
      if (navigator.onLine) {
        loadCountries().then(countries => loadRanking(selectedDate, "ItfH").then((rItfH) => {
          if (rItfH) {
            rItfH.map(ranking => 
              {
                if (countries.find(o => o.id === ranking.player.nationality))
                {
                  ranking.player.country = countries.find(o => o.id === ranking.player.nationality);
                }
                return ranking;
              }
            )
            this.setState({ rItfH, loading: false });
          }
        }));
      }
      this.setState({ selectedDate: selectedDate });
    }
  };

  render() {
    const { t } = this.props;

    return (
      <Page
        pageTitle={t("Ranking") + " Itf H"}
        backPage="/competitionTennis/ranking"
        tab={[
          {
            title: t("All players"),
            visible: true,
            data: this.state.rItfH,
            component: (data) => {
              return this.state.loading ? (
                <div style={{ height: "80vh", width: "100%" }}>
                  <Loading />
                </div>
              ) : !this.state.dates && !this.state.selectedDate ? (
                <div>Aucune donnée à afficher</div>
              ) : (
                <TableRankingITFH
                  rankings={data}
                  dates={this.state.dates}
                  selectedDate={this.state.selectedDate}
                  onChange={(date) => this.onChangeDate(date)}
                />
              );
            },
          },
          {
            title: t("French players"),
            visible: true,
            data: this.state.rItfH
              ? this.state.rItfH.filter((o) => o.player.nationality === "FR")
              : this.state.rItfH,
            component: (data) => {
              return this.state.loading ? (
                <div style={{ height: "80vh" }}>
                  <Loading />
                </div>
              ) : !this.state.dates && !this.state.selectedDate ? (
                <div>Aucune donnée à afficher</div>
              ) : (
                <TableRankingITFH
                  rankings={data}
                  dates={this.state.dates}
                  selectedDate={this.state.selectedDate}
                  onChange={(date) => this.onChangeDate(date)}
                />
              );
            },
          },
        ]}
      />
    );
  }
}

export default withTranslation()(withRouter(Result));
