import React from "react";
import ActionButton from "../components/buttons/ActionButton";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import TableSimple from "../components/table/TableSimple";
import moment from "moment";
import FiltersSearch from "../components/filters/FiltersSearch";
import SearchInput from "../components/forms/input/SearchInput";
import FiltersDropDowns from "../components/filters/FiltersDropDowns";
import FilterColumns from "../components/filters/FilterColumns";
import ExportToCSV from "../components/export/ExportToCSV";
import { SelectMultiple } from "../components/forms";
import FilterDate from "../components/filters/FilterDate";
import FilterSeparator from "../components/filters/FilterSeparator";
import FiltersTitle from "../components/filters/FiltersTitle";
import FiltersBar from "../components/filters/FiltersBar";

class TablePlayerCurriculumsPursued extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actions: [],
      curriculums: [],
      selectedCurriculums: [],
      columns: [],
      searchText: "",
      startDate: null,
      endDate: null,
    };
  }

  componentDidMount() {
    this.prepareData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.prepareData();
    }
  }

  prepareData = () => {
    var curriculumsPursued = this.props.curriculumsPursued.filter(d => {
      if (this.state.startDate && this.state.startDate > d.startDate) return false  
      if (this.state.endDate && this.state.endDate < d.endDate) return false  
      return true
    });
    var curriculums = [];
    var selectedCurriculums = [];

    if (curriculumsPursued) {
      curriculumsPursued = curriculumsPursued.map((curriculumPursued) => {
        if (
          curriculums &&
          !curriculums.find((o) => o.key === curriculumPursued.curriculum.id)
        ) {
          curriculums.push({
            key: curriculumPursued.curriculum.id,
            label: curriculumPursued.curriculum.name,
          });
          selectedCurriculums.push(curriculumPursued.curriculum.id);
        }
        return curriculumPursued;
      });
    }

    const columns = [
      {
        key: "name",
        visible: true,
        pinned: "left",
        label: this.props.t("Name"),
        id: "name",
        format: (row) => (
          <Link
            className="text-indigo-700 font-medium text-xs 2xl:text-sm"
            to={"/administration/curriculum/" + row.curriculumId}
          >
            {row.curriculum.name}
          </Link>
        ),
      },
      {
        key: "startDate",
        visible: true,
        pinned: "left",
        label: this.props.t("Start"),
        id: "startDate",
        format: (row) => <span className=" text-xs 2xl:text-sm">{moment(row.startDate).calendar()}</span>,
      },
      {
        key: "endDate",
        visible: true,
        pinned: "left",
        label: this.props.t("End"),
        id: "endDate",
        format: (row) => <span className=" text-xs 2xl:text-sm">{moment(row.endDate).calendar()}</span>,
      },
      {
        key: "actions",
        visible: true,
        pinned: "left",
        label: "",
        format: (row) => (
          <ActionButton
            key={row.id}
            updateForm="updateCurriculumPursued"
            deleteForm="deleteCurriculumPursued"
            formData={row}
            refreshData={() => this.props.updateCurriculums(this.props.pId)}
          />
        ),
      },
    ];
    var selectedColumns = [];
    columns.forEach((column) => {
      if (column.visible) {
        selectedColumns.push(column.key);
      }
    });
    this.setState({
      columns,
      curriculumsPursued,
      curriculums,
      selectedColumns,
      selectedCurriculums,
    });
  };

        // date filters changed
        onChangeDates = (startDate, endDate) => {
          if (!startDate && !endDate) return;
          var obj = {};
          if (startDate) obj.startDate = startDate;
          if (endDate) obj.endDate = endDate;
          this.setState(obj, () => {
            this.prepareData()
          });
        }

  render() {
    const { t } = this.props;
    var curriculumsPursued = this.props.curriculumsPursued;
    var columns = this.state.columns;
    var selectedColumns = this.state.selectedColumns;
    var selectedCurriculums = this.state.selectedCurriculums;
    var searchText = this.state.searchText;
    if (columns) {
      columns = columns.filter((o) =>
        selectedColumns.some((item) => item === o.key)
      );
    }
    if (curriculumsPursued) {
      curriculumsPursued = curriculumsPursued.filter((o) =>
        selectedCurriculums.some((item) => item === o.curriculum.id)
      );
    }
    if (searchText.length > 2) {
      curriculumsPursued = curriculumsPursued.filter((o) =>
        o.curriculum.name.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    return (
      <>
        <FiltersBar
          components={
            <>
                <FiltersTitle title={curriculumsPursued
                    ? curriculumsPursued.length +
                      " " +
                      t("Curriculums pursued").toLowerCase()
                    : ""}
                />
                <FilterSeparator />
                <FiltersSearch
                  search={
                    <SearchInput
                      value={this.state.searchText}
                      onChange={(searchText) => this.setState({ searchText })}
                    />
                  }
                />
                <FilterSeparator />
                <FiltersDropDowns
                  selects={[
                    <SelectMultiple
                      options={this.state.curriculums}
                      keys={this.state.selectedCurriculums}
                      className="mx-2"
                      onChange={(res) =>
                        this.setState({ selectedCurriculums: res })
                      }
                      fixedLabel={t("Type")}
                    />,
                  ]}
                />
                <FilterSeparator />
                <FilterColumns
                  columns={
                    <SelectMultiple
                      options={this.state.columns}
                      keys={this.state.selectedColumns}
                      className="mx-2"
                      onChange={(res) => this.setState({ selectedColumns: res })}
                      fixedLabel={t("Active columns")}
                    />
                  }
                />
                <FilterSeparator />
                <ExportToCSV
                  columns={columns}
                  jsonData={curriculumsPursued}
                  fileName={t("Curriculums pursued") + ".csv"}
                />
              <FilterSeparator />
              <FilterDate onChange={this.onChangeDates} />
            </>
          }
          buttons={this.props.buttons}
        />
        <TableSimple columns={columns} data={curriculumsPursued} />
      </>
    );
  }
}

export default withTranslation()(TablePlayerCurriculumsPursued);
