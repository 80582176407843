import React from "react"
import { withRouter } from "react-router"
import { withTranslation } from "react-i18next"
import SVG from "react-inlinesvg"

class WidgetKeyFigure extends React.Component {
  render() {
    const { figure, svg, title, heures, t, isSequence, blank, sRpe } = this.props
    let minutes = 0
    if (heures) 
      minutes = ((heures%1)*60).toFixed(0)
    return (
      <>
        <dl className="w-full">
          <div className="bg-indigo-700 overflow-hidden shadow rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <div className="flex items-center">
                <div className="flex-shrink-0 bg-white rounded-md p-3">
                  <SVG src={svg} />
                </div>
                <div className="ml-5 w-0 flex-1">
                  <dt className="text-sm font-medium text-gray-200 truncate">
                    {title}
                  </dt>
                  <dd className="flex items-baseline">
                    {(figure || figure === 0) && (
                    <div className="text-2xl font-semibold text-white">
                      {figure} {!blank ? (!isSequence ? (figure > 1 ? t("Séances") : t("Séance"))
                      : (figure > 1 ? t("Séquences") : t("Séquence"))) : ""}
                    </div>
                    )}
                    {heures && figure && (
                      <>
                        <div className="text-2xl font-semibold text-white">
                         &nbsp;-&nbsp;
                        </div>
                        <div className="text-2xl font-semibold text-white">
                          {Math.trunc(heures) + "H" + (minutes < 10 ? "0" + minutes : minutes)  }
                        </div>
                      </>
                    )}
                    {heures && !figure && (
                      <>
                        <div className="text-2xl font-semibold text-white">
                          {Math.trunc(heures) + "H" + (minutes < 10 ? "0" + minutes : minutes)  }
                        </div>
                      </>
                    )}
                    {(sRpe || sRpe === 0) && (
                      <>
                        {!blank && (<div className="text-2xl font-semibold text-white">
                         &nbsp;-&nbsp;
                        </div>)}
                        <div className="text-2xl font-semibold text-white">
                          {sRpe + " UA"}
                        </div>
                      </>
                    )}
                  </dd>
                </div>
              </div>
            </div>
          </div>
        </dl>
      </>
    )
  }
}

export default withTranslation()(withRouter(WidgetKeyFigure))
