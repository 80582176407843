import React from "react";
import moment from "moment";
import { withTranslation } from "react-i18next";
import { SelectMultiple } from "../components/forms";
import TableSimple from "../components/table/TableSimple";
import FiltersSearch from "../components/filters/FiltersSearch";
import SearchInput from "../components/forms/input/SearchInput";
import FilterColumns from "../components/filters/FilterColumns";
import ExportToCSV from "../components/export/ExportToCSV";
import FiltersDropDowns from "../components/filters/FiltersDropDowns";
import FilterSeparator from "../components/filters/FilterSeparator";
import FiltersTitle from "../components/filters/FiltersTitle";
import FiltersBar from "../components/filters/FiltersBar";

class TablePlayerLicenses extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actions: [],
      clubs: [],
      selectedClubs: [],
      columns: [],
      searchText: "",
    };
  }

  componentDidMount() {
    this.prepareData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.prepareData();
    }
  }

  prepareData = () => {
    var licenses = this.props.licenses;
    var clubs = [];
    var selectedClubs = [];
    if (licenses) {
      licenses = licenses.map((license) => {
        if (clubs && !clubs.find((o) => o.key === license.tennisClubId)) {
          clubs.push({
            key: license.tennisClubId,
            label: license.club.name,
          });
          selectedClubs.push(license.tennisClubId);
        }
        return license;
      });
    }

    const columns = [
      {
        key: "number",
        visible: true,
        pinned: "left",
        label: this.props.t("Number"),
        id: "number",
        format: (row) => <span className=" text-xs 2xl:text-sm">{row.number}</span>,
      },
      {
        key: "clubId",
        visible: true,
        pinned: "left",
        label: this.props.t("Club"),
        id: "clubId",
        format: (row) => <span className=" text-xs 2xl:text-sm">{row.club.name}</span>,
      },
      {
        key: "regionId",
        visible: true,
        pinned: "left",
        label: this.props.t("Ligue"),
        id: "regionId",
        format: (row) => <span className=" text-xs 2xl:text-sm">{row.club.region.name}</span>,
      },
      {
        key: "startDate",
        visible: true,
        pinned: "left",
        label: this.props.t("Start"),
        id: "startDate",
        format: (row) => <span className=" text-xs 2xl:text-sm">{moment(row.startDate).calendar()}</span>,
      },
      {
        key: "endDate",
        visible: true,
        pinned: "left",
        label: this.props.t("End"),
        id: "endDate",
        format: (row) => <span className=" text-xs 2xl:text-sm">{moment(row.endDate).calendar()}</span>,
      },
    ];
    var selectedColumns = [];
    columns.forEach((column) => {
      if (column.visible) {
        selectedColumns.push(column.key);
      }
    });
    this.setState({
      columns,
      licenses,
      clubs,
      selectedColumns,
      selectedClubs,
    });
  };

  render() {
    const { t } = this.props;
    var licenses = this.props.licenses;
    var columns = this.state.columns;
    var selectedColumns = this.state.selectedColumns;
    var selectedClubs = this.state.selectedClubs;
    var searchText = this.state.searchText;
    if (columns) {
      columns = columns.filter((o) =>
        selectedColumns.some((item) => item === o.key)
      );
    }
    if (licenses) {
      licenses = licenses.filter((o) =>
        selectedClubs.some((item) => item === o.tennisClubId)
      );
    }
    if (searchText.length > 2) {
      licenses = licenses.filter((o) =>
        o.number.toLowerCase().includes(searchText.toLowerCase()) ||
        o.club.name.toLowerCase().includes(searchText.toLowerCase()) ||
        o.club.region.name.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    return (
      <>
        <FiltersBar
          components={
            <>
              <FiltersTitle title={licenses
                  ? licenses.length + " " + t("Licenses").toLowerCase()
                  : ""}
              />
              <FilterSeparator />
              <FiltersSearch search={<SearchInput
                value={this.state.searchText}
                onChange={(searchText) => this.setState({ searchText })}
              />} />
              <FilterSeparator />
              <FiltersDropDowns selects={[
                <SelectMultiple
                  options={this.state.clubs}
                  keys={this.state.selectedClubs}
                  className="mx-2"
                  onChange={(res) => this.setState({ selectedClubs: res })}
                  fixedLabel={t("Club")}
                />
                ]} 
              />
              <FilterSeparator />
              <FilterColumns
                columns={
                  <SelectMultiple
                    options={this.state.columns}
                    keys={this.state.selectedColumns}
                    className="mx-2"
                    onChange={(res) => this.setState({ selectedColumns: res })}
                    fixedLabel={t("Active columns")}
                  />
                }
              />
              <FilterSeparator />
              <ExportToCSV columns={columns} jsonData={licenses} fileName={t("Licenses") + ".csv"} />
            </>
          }
          buttons={this.props.buttons}
        />
        <TableSimple columns={columns} data={licenses} />
      </>
    );
  }
}

export default withTranslation()(TablePlayerLicenses);
