import React from "react";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import Page from "../../../components/page/Page";
import {
  getOpponent,
  loadOpponent,
  getRankingsTennisEuropeU14FByPlayer,
  loadRankingsTennisEuropeU14FByPlayer,
} from "../../../utils/indexedDB/Tennis-Competition/handleCompetitionTennis";
import Card from "../../../components/card/Card";
import Loading from "../../../components/loaders/Loading";
import ChartTennisRankingsRank from "../../../charts/ChartTennisRankingsRank";
import ChartTennisRankingsPoints from "../../../charts/ChartTennisRankingsPoints";

class RankingTennisEuropeU14FByPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      player: null,
      rankings: null,
      loading: true,
      id: this.props.match.params.id,
    };
  }

  componentDidMount() {
    var id = this.props.match.params.id;

    getOpponent(id).then((player) => this.setState({ player }));
    getRankingsTennisEuropeU14FByPlayer(id).then((rankings) =>
      this.setState({ rankings, loading: false })
    );
    this.setState({ id });
    if (navigator.onLine) {
      loadOpponent(id).then((player) => this.setState({ player }));
      loadRankingsTennisEuropeU14FByPlayer(id).then((rankings) =>
        this.setState({ rankings, loading: false })
      );
    }
  }

  render() {
    const { t } = this.props;

    return (
      <Page
        pageTitle={
          t("Ranking") +
          " Tennis Europe U14 Femmes" +
          (this.state.player
            ? " - " +
              this.state.player.lastName +
              " " +
              this.state.player.firstName
            : "")
        }
        backPage="/competitionTennis/ranking"
        tab={[
          {
            title: t("Ranking"),
            visible: true,
            data: this.state.rankings,
            component: (data) => {
              return this.state.loading ? (
                <div style={{ height: "80vh" }}>
                  <Loading />
                </div>
              ) : this.state.player && this.state.rankings ? (
                <div>
                  <Card className="mb-5">
                    <ChartTennisRankingsRank
                      title={t("Tennis Europe U14 Girls Rank evolution")}
                      data={this.state.rankings}
                    />
                  </Card>
                  <Card>
                    <ChartTennisRankingsPoints
                      title={t("Tennis Europe U14 Girls evolution")}
                      data={this.state.rankings}
                    />
                  </Card>
                </div>
              ) : (
                <div>Aucune donnée à afficher</div>
              );
            },
          },
        ]}
      />
    );
  }
}

export default withTranslation()(withRouter(RankingTennisEuropeU14FByPlayer));
