import React from "react";
import { withTranslation } from "react-i18next";

import { post } from "../../../requests/index";
import Button from "../../../../components/buttons/Button";
import * as FormsComponent from "../../../../components/forms/index";
import * as Drawer from "../../../../components/drawer";
import moment from "moment";

class StepRecurrence extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            frequency: "never",
            endDate: null
        }
    }

    componentDidMount() {
        var event = this.props.formData;
        if (event.recurrenceId) {
            this.props.nextStep(this.props.formData);
        } else {
            this.setState({ loading: false, endDate: this.props.formData.endDate ? moment(this.props.formData.endDate) : moment() });
        }
    }

    handleSubmit() {
        this.setState({ loading: true });
        if (this.state.frequency === "never") {
            this.props.nextStep(this.props.formData);
        } else {
            var date = moment.isMoment(this.state.endDate) ? this.state.endDate : moment(this.state.endDate);
            if (date.isValid()) {
                date = date.format("YYYY-MM-DDTHH:mm:ss");

            }
            var result = {
                eventId: this.props.formData.id,
                frequency: this.state.frequency,
                startDate: this.props.formData.startDate,
                endDate: date
            }

            post("schedule", "eventsRecurrence", result).then(() => {
                this.props.nextStep(this.props.formData);
            })
        }

    }

    render() {
        const { t } = this.props;
        return (
            <>
                <Drawer.Header hide={this.props.hide}>
                    {t("Recurrence")}
                </Drawer.Header>
                <Drawer.Body>
                    <>
                        {this.state.errors ? <div className="alert alert-danger">
                            {t("Error")}
                            {this.state.errors.map((error, key) => {
                                return <div key={key}>{error}</div>;
                            })}
                        </div> : (
                            ""
                        )}
                        <div className="row mt-2 mb-2">
                            <div className="col-md-12 col-lg-6 col-xl-4">
                                {t("Frequency")} :
                                <FormsComponent.Select
                                    options={[{ key: "never", label: t("Never") },
                                    { key: "day", label: t("Every days") },
                                    { key: "month", label: t("Every months") },
                                    { key: "week", label: t("Every weeks") },
                                    { key: "year", label: t("Every years") }]}
                                    value={this.state.frequency}
                                    onChange={(frequency) => this.setState({ frequency })}
                                />
                            </div>
                            {this.state.frequency !== "never" ?
                                <>
                                    <div className="col-md-12 col-lg-6 col-xl-4">
                                        {t("Until")} :
                                <FormsComponent.DatePicker
                                            value={this.state.endDate}
                                            onChange={(endDate) => {
                                                if (endDate) {
                                                    endDate = moment(endDate);
                                                    this.setState({ endDate });
                                                }

                                            }} />
                                    </div>
                                </>
                                : ""}

                        </div>
                    </>
                </Drawer.Body>
                <Drawer.Footer>
                    <>
                        <Button
                            loading={this.state.loading}
                            onClick={(event) => {
                                this.handleSubmit(event);
                            }}
                        >
                            {t("Next")}
                        </Button>
                    </>
                </Drawer.Footer>
            </>
        )
    }
}

export default withTranslation()(StepRecurrence);