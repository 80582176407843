import React from "react";
import AsideBrand from "./AsideBrand";
import AsideModules from "./AsideModules";
import logicRoutes from "../../utils/logicRoutes";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { AuthenticationContext } from "@axa-fr/react-oidc-context";
import deleteAllDexieDB from "../../utils/indexedDB/DeleteAllDB";
// import IconButtonAside from "../buttons/IconButtonAside";
import AsideAccount from "./AsideAccount";
class Aside extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedModule: null,
      currentModule: logicRoutes.find((o) => o.path === this.props.path)
        ? logicRoutes.find((o) => o.path === this.props.path).module
        : "Core",
      currentFeature: logicRoutes.find((o) => o.path === this.props.path)
        ? logicRoutes.find((o) => o.path === this.props.path).feature
        : "Dashboard",
    };
  }

  handleModuleChange = (newlySelectedModule) => {
    this.setState({ selectedModule: newlySelectedModule });
  };

  render() {
    const currentModule = this.state.currentModule;
    return (
      <>
        <div className="bg-white dark:bg-gray-900 dark:border-gray-800 w-16 2xl:w-20 flex-shrink-0 border-r border-gray-200 flex-col hidden justify-between sm:flex h-screen">
          <AsideBrand />
          <AsideModules
            selectedModule={
              this.state.selectedModule
                ? this.state.selectedModule
                : currentModule
            }
            callback={this.handleModuleChange}
          />
          <div className=" mx-auto text-gray-400 space-y-2 2xl:space-y-4">
            {/* <IconButtonAside
              selectedModule={currentModule}
              module="announces"
              history={this.props.history}
              route="/announces"
            >
              <svg
                className="h-5"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4"
                />
              </svg>
            </IconButtonAside> */}
            <AsideAccount
              selectedModule={
                this.state.selectedModule
                  ? this.state.selectedModule
                  : currentModule
              }
              callback={this.handleModuleChange} 
            />
            {/* <button
              className="h-10 w-12 dark:text-gray-500 rounded-md flex items-center justify-center"
              onClick={() => {
                this.props.onHelpClicked();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </button> */}
            <AuthenticationContext.Consumer>
              {(props) => {
                return props.oidcUser ? (
                  <button
                    className="h-10 w-12 dark:text-gray-500 rounded-md flex items-center justify-center"
                    onClick={() => {
                      localStorage.clear();
                      deleteAllDexieDB();
                      window.location =
                        "https://login.exaduosport.fr/account/logout";
                    }}
                  >
                    <svg
                      className="h-5"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                          <rect x="0" y="0" width="24" height="24"/>
                          <path d="M7.62302337,5.30262097 C8.08508802,5.000107 8.70490146,5.12944838 9.00741543,5.59151303 C9.3099294,6.05357769 9.18058801,6.67339112 8.71852336,6.97590509 C7.03468892,8.07831239 6,9.95030239 6,12 C6,15.3137085 8.6862915,18 12,18 C15.3137085,18 18,15.3137085 18,12 C18,9.99549229 17.0108275,8.15969002 15.3875704,7.04698597 C14.9320347,6.73472706 14.8158858,6.11230651 15.1281448,5.65677076 C15.4404037,5.20123501 16.0628242,5.08508618 16.51836,5.39734508 C18.6800181,6.87911023 20,9.32886071 20,12 C20,16.418278 16.418278,20 12,20 C7.581722,20 4,16.418278 4,12 C4,9.26852332 5.38056879,6.77075716 7.62302337,5.30262097 Z" fill="currentColor" fillRule="nonzero"/>
                          <rect fill="currentColor" opacity="0.9" x="11" y="3" width="2" height="10" rx="1"/>
                      </g>
                    </svg>
                  </button>
                ) : (
                    ""
                  );
              }}
            </AuthenticationContext.Consumer>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(withRouter(Aside));
