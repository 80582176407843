import React from "react";
import {
  getOpponents,
  loadOpponents,
  loadOpponentById
} from "../../../utils/indexedDB/Tennis-Competition/handleCompetitionTennis";
import { withTranslation } from "react-i18next";
import CardUser from "./cardUser";
import Input from "../input/Input";
import getDataFiltered from "../../../utils/filteredData";
import GenerateForm from "../../../utils/forms/generateForm";

class ChooseOpponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opponents: [],
      opponentsFiltered: [],
      open: false,
      value: this.props.value,
      getFromAPI: false
    };

    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
    this.onOpponentSelected = this.onOpponentSelected.bind(this);
  }

  componentDidMount() {
    getOpponents().then((opponents) => {
      if (opponents && opponents.length && !this.state.getFromAPI) {
        this.setState({ opponents, opponentsFiltered: opponents });
        if (this.props.value) {
          var opponent = opponents.find(opp => opp.id === this.props.value);
          this.setState({ opponent });
        }
      } else {
        loadOpponents().then((opponentsAPI) => {
          if (opponentsAPI) {
            this.setState({ opponents: opponentsAPI, opponentsFiltered: opponentsAPI, getFromAPI: true });
            if (this.props.value) {
              var opponent = opponentsAPI.find(opp => opp.id === this.props.value);
              this.setState({ opponent });
            }
          }
        });
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      var opponent = this.state.opponents.find(o => o.id === this.props.value);
      if (!opponent) {
        loadOpponentById(this.props.value).then(opp => {
          this.setState({ opponent: opp });
        })
      } else {
        this.setState({ opponent });
      }

    }
  }

  show(event) {
    event.preventDefault();
    this.setState({ open: true }, () => {
      document.addEventListener("click", this.hide);
      if (document.getElementById("search")) {
        document.getElementById("search").focus();
      }
    });
  }

  hide(event) {
    this.setState({ open: false }, () => {
      document.removeEventListener("click", this.hide);
    });
  }

  onOpponentSelected(opponent) {
    this.props.onChange(opponent);
  }

  render() {
    var { t } = this.props;
    return (<div>
      <div className="dropdown" style={{ display: "block" }}>
        {this.state.open ? (
          <>
            <Input
              id="search"
              className="form-control"
              style={{ maxWidth: "250px" }}
              autoFocus
              onChange={(value) => {
                this.setState({ opponentsFiltered: undefined });
                var opponentsFiltered = getDataFiltered(
                  this.state.opponents,
                  value
                );
                this.setState({ opponentsFiltered });
              }}
            />
            <div className="dropdown-menu open" style={{ minWidth: "250px" }}>
              {this.state.opponentsFiltered
                ? this.state.opponentsFiltered
                  .slice(0, 5)
                  .map((o, key) => (
                    <CardUser
                      key={key} className="dropdown-item"
                      onClick={() => this.onOpponentSelected(o.id)}
                      photo={o.photo}
                      title={o.lastName + " " + o.firstName}
                    />
                  ))
                : t("No results")}
            </div>
          </>
        ) : this.state.opponent ? (
          <div onClick={this.show} className="btn btn-outline-secondary dropdown-toggle dropdown" style={{ maxWidth: "250px" }} >
            <span style={{ display: "inline-block" }}><CardUser title={this.state.opponent.lastName + " " + this.state.opponent.firstName}
              photo={this.state.opponent.photo} />
            </span>
          </div>
        ) : (
              <div className="dropdown">
                <button className="btn btn-outline-secondary dropdown-toggle dropdown" style={{ maxWidth: "250px" }} onClick={this.show}>{t("Choose your opponent")}</button>
              </div>
            )}

      </div>
      <GenerateForm
        callback={(opponentCreated) => {
          if (navigator.onLine && opponentCreated.id) {
            this.onOpponentSelected(opponentCreated.id);
            var opponents = this.state.opponents;
            opponents.push(opponentCreated);
            this.setState({ opponentsFiltered: opponents, opponents });
          }
        }}
        additionalClass={"createButton"}
        formName={"createOpponent"}
        component={(show, label) => <div
          className="es2-link"
          onClick={() => show()}
        >
          {label}
        </div>}
      />
    </div >
    );
  }
}

export default withTranslation()(ChooseOpponent);
