import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import IconButtonAside from "../buttons/IconButtonAside";
import getUserProfile from "../../utils/auth/getUserProfile";
import IconRound32x32 from "../icons/IconRound32x32";
import { getStaff } from "../../utils/indexedDB/users/handleUser";


class AsideAccount extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userPicture: null,
            userId: "",
        };
    }

    componentDidMount() {
        getUserProfile().then(user => {
            if (user) {
                this.setState({ userId: user.sub, gender: user.gender })
                getStaff(user.sub).then(u => {
                    if (u)
                        this.setState({ userPicture: u.photo/*user.photo*/ })
                })
            }
        })
    }

    render() {
        const selectedModule = this.props.selectedModule;
        return (
            // <span className="flex justify-between">
            // <Link className="text-indigo-700 font-medium"
            //   to={"/myAccount/" + this.state.userId}>
            <IconButtonAside
                selectedModule={selectedModule}
                module="account"
                history={this.props.history}
                route={"/myAccount/" + this.state.userId}
            >
                {this.state.userPicture ?
                    <IconRound32x32 photo={this.state.userPicture} />
                    : (this.state.gender === "H" ?
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 64 64"
                            role="img"
                            className="h-6"
                            stroke="currentColor"
                        >
                            <path
                                data-name="layer1"
                                d="M50.4 51C40.5 49.1 40 46 40 44v-1.2a18.9 18.9 0 0 0 5.7-8.8h.1c3 0 3.8-6.3 3.8-7.3s.1-4.7-3-4.7C53 4 35.5-3.2 22.3 6c-5.4 0-5.9 8-3.9 16-3.1 0-3 3.8-3 4.7s.7 7.3 3.8 7.3c1 3.6 2.3 6.9 4.7 9v1.2c0 2 .5 5-9.5 6.8S2 62 2 62h60a14.6 14.6 0 0 0-11.6-11z"
                                fill="none" 
                                strokeMiterlimit="10" 
                                strokeWidth="2" 
                                strokeLinejoin="round"
                                strokeLinecap="round"
                            />
                        </svg>
                        : <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 64 64"
                            role="img"
                            className="h-6"
                            stroke="currentColor"
                        >
                            <path
                                data-name="layer1"
                                d="M49 17c0 17.2 2.9 18.9 7 23a70.8 70.8 0 0 1-16 2v2c0 2 0 6 10 8s10 10 10 10H4s0-8 10.2-10 9.8-6 9.8-8v-2a71 71 0 0 1-16-2c4.1-4.1 8-5.8 8-23 0 0 0-14 12-14 0 0 21-6.5 21 14z"
                                fill="none"
                                strokeMiterlimit="10"
                                strokeWidth="2"
                                strokeLinejoin="round"
                                strokeLinecap="round"
                            />
                        </svg>
                    )}
            </IconButtonAside>
            //     </Link>
            //   </span>
        );
    }
}

export default withTranslation()(withRouter(AsideAccount));