import React from "react";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import moment from "moment";
import GeneratedForm from "../../../utils/forms/generateForm";
import GenerateForm from "../../../utils/forms/generateForm";
import DisplayData3cols from "../../../components/displayData/DisplayData3cols";
import Flag from "../../../components/icons/Flag";
import generateColor from "../../../utils/generateColor";
import ButtonLight from "../../../components/buttons/ButtonLight";
import Card from "../../../components/card/Card";

class PlayerGeneralTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = { id: this.props.playerId, player: this.props.player };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.player !== this.props.player) {
      this.setState({ player: this.props.player });
    }
  }

  render() {
    const { t } = this.props;
    const printableComment =
      this.state.player && this.state.player.administrativeComments
        ? this.state.player.administrativeComments.replace(
            /(?:\r\n|\r|\n)/g,
            "<br />"
          )
        : "";
    const dangerousComment = { __html: printableComment };
    return this.state.player ? (
      <>
        <div className="flex space-x-5 pt-5 items-center h-full">
          <div className="py-1 px-20 w-96">
            <GenerateForm
              callback={() => {}}
              formName={"updatePhotoPlayer"}
              formData={this.state.player}
              component={(show, label) => (
                <div className="w-36 h-36 py-2" onClick={() => show()}>
                  <div className="relative mx-auto my-auto cursor-pointer">
                    <img
                      className="block opacity-100 h-36 w-36 rounded-full"
                      src={this.props.player.photo}
                      alt=""
                    />
                    <span className="absolute top-0 h-36 w-36 opacity-0 hover:opacity-75 rounded-full hover:bg-gray-700 overflow-hidden text-center">
                      <span className="block pt-16 text-white">
                        {t("Edit")}
                      </span>
                    </span>
                  </div>
                </div>
              )}
            />
          </div>
          <div className="py-3 px-10 w-full">
            <Card>
              <DisplayData3cols
                data={[
                  {
                    key: t("Last name"),
                    value: this.state.player.lastName
                      ? this.state.player.lastName.toUpperCase()
                      : "",
                  },
                  { key: t("First name"), value: this.state.player.firstName },
                  {
                    key: t("Training group"),
                    value: this.state.player.group1 ? (
                      <span
                        className="inline-flex items-center px-3 py-0.5 rounded-full text-xs 2xl:text-sm font-medium bg-yellow-100 text-yellow-800"
                        style={{
                          color: "#FFFFFF",
                          backgroundColor: generateColor(
                            this.state.player.group1.name
                          ),
                        }}
                      >
                        {this.state.player.group1.name}
                      </span>
                    ) : (
                      ""
                    ),
                  },
                  {
                    key: t("Gender"),
                    value: this.state.player.gender
                      ? t(this.state.player.gender.name)
                      : "-",
                  },
                  {
                    key: t("Date of birth"),
                    value: moment(this.state.player.dob).calendar(),
                  },
                  {
                    key: t("Nationality"),
                    value: (
                      <Flag
                        className="w-6"
                        nationalityId={this.state.player.nationalityId}
                      />
                    ),
                  },
                  { key: t("Email"), value: this.state.player.email },
                  { key: t("Mobile"), value: this.state.player.mobile },
                  { key: t("License"), value: this.state.player.mobile },
                ]}
              />
            </Card>
          </div>
        </div>
        <div className="flex space-x-5 mt-3">
          <div
            className="w-1/2 py-4 px-5 mx-5"
            style={{ boxShadow: "0 0 10px 0 rgba(82,63,105,.1)" }}
          >
            <Card title={t("Staff")} className=" card-stretch">
              <ul className="divide-y divide-gray-200 mt-1">
                {this.state.staffs
                  ? this.state.staffs.map((staff, Skey) =>
                      staff ? (
                        <li className="py-2 2xl:py-4 flex" key={Skey}>
                          <img
                            className="h-8 2xl:h-10 w-8 2xl:w-10 rounded-full"
                            src={staff.photo}
                            alt=""
                          />
                          <div className="ml-3">
                            <p className="text-xs 2xl:text-sm font-semibold text-gray-900">
                              {staff.lastName
                                ? staff.lastName.toUpperCase() +
                                  " " +
                                  staff.firstName
                                : null}
                            </p>
                            <p className="text-xs 2xl:text-sm text-gray-500">
                              {staff.title}
                            </p>
                          </div>
                        </li>
                      ) : (
                        ""
                      )
                    )
                  : ""}
              </ul>
            </Card>
          </div>
          <div
            className="w-1/2 py-2 px-5 mx-5"
            style={{ boxShadow: "0 0 10px 0 rgba(82,63,105,.1)" }}
          >
            <Card
              className=""
              title={t("Comments")}
              headerActions={
                <GeneratedForm
                  key="updateCommentPlayer"
                  component={(show, text) => (
                    <ButtonLight onClick={show} disabled={!navigator.onLine}>
                      {t("Edit")}
                    </ButtonLight>
                  )}
                  callback={(res) => this.props.refresh()}
                  formName="updateCommentPlayer"
                  formData={this.state.player}
                />
              }
            >
              <dl className="grid grid-cols-1 gap-x-4 gap-y-0 py-2">
                <div className="">
                  <dd className="mt-1 text-xs 2xl:text-sm text-gray-900">
                    {dangerousComment ? (
                      <p dangerouslySetInnerHTML={dangerousComment}></p>
                    ) : (
                      ""
                    )}
                  </dd>
                </div>
              </dl>
            </Card>
          </div>
        </div>
        <div className="h-20"></div>
      </>
    ) : null;
  }
}

export default withTranslation()(withRouter(PlayerGeneralTab));
