import React, { Component } from "react";
import {
    getCompetitions,
    getMatchsInfos,
    loadCompetitions,
    loadMatchsInfos,
  } from "../../utils/indexedDB/Soccer-Competition/handleCompetitionSoccer";
import moment from "moment";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import {
//   getCurrentSeason,
  getSeasons,
  loadEventsByType,
//   loadSeasons,
} from "../../utils/indexedDB/events/handleEvents";
import CompetitionPage from "../competitionPage/CompetitionPage";

class CompetitionSoccerPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      matchsInfos: [],
    };
  }

  componentDidMount() {
    getMatchsInfos().then((matchsInfos) => {
      if (matchsInfos) this.setState({ matchsInfos });
    });
    getCompetitions().then((competitions) => {
      if (competitions) this.setState({ competitions });
    });
    getSeasons().then((seasons) => {
      if (seasons) this.setState({ seasons });
    });
    if (navigator.onLine) {
      loadCompetitions().then((competitions) => {
        this.setState({ competitions });
      });
    //   loadSeasons().then((seasons) => {
    //     let currentSeason = getCurrentSeason(seasons);
    //     this.setState({ seasons });
        loadEventsByType(
          "Match",
          moment("20170801", "YYYYMMDD").format(),
          moment().format()
        ).then((Matchs) => {
          loadMatchsInfos(Matchs.map((match) => match.id)).then(
            (matchsInfos) => {
              this.setState({ matchsInfos });
            }
          );
        });
    //   });
    }
  }

  render() {
    const savedSelectedColumnsSoccerTournaments = "TableSoccer_competitions";
    const savedSelectedColumnsSoccerMatchs = "TableSoccerResults_selectedColumns";
    const sportTypePath = "competitionSoccer";

    return (
      <CompetitionPage
        competitions={this.state.competitions}
        matchsInfos={this.state.matchsInfos}
        savedSelectedColumnsTournaments={savedSelectedColumnsSoccerTournaments}
        savedSelectedColumnsMatchs={savedSelectedColumnsSoccerMatchs}
        sportTypePath={sportTypePath}
        seasons={this.state.seasons && this.state.seasons}
      />
    );
  }
}

export default withTranslation()(withRouter(CompetitionSoccerPage));
