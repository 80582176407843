import React from "react";
import IconRound32x32 from "../icons/IconRound32x32";
import Label from "../typography/Label";
import { withTranslation } from "react-i18next";
import { Checkbox, Input } from "../forms";
import getDataFiltered from "../../utils/filteredData";

class DropdownTableSelectorUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: this.props.selected ? this.props.selected : [],
      filteredData: this.props.data,
      open: false,
    };
  }

  selectedListChanged(selected) {
    this.props.onChange(selected);
    this.setState({ selected });
  }

  componentDidUpdate(prevProps) {
    if (this.props.selected !== prevProps.selected) {
      var selected = this.state.selected;
      if (this.props.selected && this.props.selected.length) {
        this.props.selected.forEach(s => {
          if (!selected.includes(s)) {
            selected.push(s)
          }
        })
        this.setState({ selected })
      }
    }

    if (prevProps.data !== this.props.data) {
      this.setState({ filteredData: this.props.data });
    }
  }

  render() {
    const { t } = this.props;
    const columns = [
      {
        key: "checkbox",
        format: (row) => (
          <Checkbox
            value={this.state.selected.includes(row.id)}
            onChange={() => {
              var selected = this.state.selected;
              if (selected.includes(row.id)) {
                selected = selected.filter((x) => x !== row.id);
              } else {
                selected.push(row.id);
              }
              this.selectedListChanged(selected);
            }}
          />
        ),
      },
      {
        key: "displayName",
        format: (row) => (
          <>
            <div
              className="text-indigo-700 font-medium"
            >
              <IconRound32x32 photo={row.photo} />
              <span className="ml-5">
                {row.lastName.toUpperCase() + " " + row.firstName}
              </span>
            </div>
          </>
        ),
      },
      {
        key: "availability",
        label: t("Availability"),
        format: (row) =>
          row.status && row.status !== [] && row.status.length > 0 ? (
            <Label type="danger" text={t("Unavailable")} />
          ) : (
              <Label type="success" text={t("Available")} />
            ),
      },
    ];
    return this.props.data ? (
      <>
        <div className="flex justify-between border-t border-b w-full pr-4">
          <button className="inline-flex justify-center" onClick={() => this.setState({ open: !this.state.open })}>
            <svg className="h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
            </svg>
            {this.props.label + " (" + this.state.selected.length + ")"}</button>
        </div>
        {this.state.open ?
          <div className="flex flex-col">
            <div className="-my-0 overflow-x-auto sm:-mx-6 lg:-mx-0 h-full">
              <div className="py-0 align-middle inline-block min-w-full sm:px-6 lg:px-0">
                <div className="overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    {this.state.filteredData ? (
                      <tbody>
                        <tr>
                          <td>
                            <Checkbox value={this.state.selected.length === this.props.data.length} onChange={() => {
                              var selected = this.state.selected;
                              if (selected.length === this.props.data.length) {
                                selected = []
                              } else {
                                selected = this.props.data.map(x => x.id)
                              }
                              this.selectedListChanged(selected);
                            }} />
                          </td>
                          <td>
                            <Input
                              className="form-control"
                              placeholder={t("Search")}
                              autoFocus
                              onChange={(value) => {
                                this.setState({ filteredData: undefined });
                                var filteredData = getDataFiltered(
                                  this.props.data,
                                  value
                                );
                                this.setState({ filteredData });
                              }}
                            />
                          </td>
                        </tr>
                        {this.state.filteredData.map((row, key) => {
                          return row && row != null ? (
                            <tr key={key}>
                              {columns.map((column, keyColumn) => {
                                const value = column.format ? row : row[column.id];
                                return (
                                  <td
                                    key={keyColumn}
                                    style={column.width ? { width: column.width } : {}}
                                  >
                                    {column.format ? column.format(value) : value}
                                  </td>
                                );
                              })}
                            </tr>
                          ) : null;
                        })}
                      </tbody>
                    ) : null}
                  </table>
                </div>
              </div>
            </div>
          </div> : null}
      </>
    ) : null;
  }
}

export default withTranslation()(DropdownTableSelectorUser);
