import React from "react";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import Card from "../card/Card";
import List from "../lists/List";

class WidgetKeyFigure extends React.Component {
  render() {
    // const { t } = this.props;
    const { data } = this.props;
    var tournaments = 0;
    var titles = 0;
    var finals = 0;
    var semiFinals = 0;
    if (data) {
      tournaments = data.length;
      data.forEach((r) => {
        if (r.isWon) titles += 1;
        else if (r.tmax === "Finale") finals += 1;
        else if (r.tmax === "1/2 finale") semiFinals += 1;
      });
    }
    return (
      <Card title={this.props.title}>
      <List data={[{key: this.props.t("Tournois"), value: tournaments}, {key: this.props.t("Titres"), value: titles}, {key: this.props.t("Finales"), value: finals}, {key: this.props.t("Demi-finales"), value: semiFinals}]} />
        
      </Card>
    );
  }
}

export default withTranslation()(withRouter(WidgetKeyFigure));
