import db from "./db";
import get from "../../requests/get";
import patch from "../../requests/patch";
import post from "../../requests/post";
import moment from "moment";
import getUserProfile from "../../auth/getUserProfile";
import cogoToast from "cogo-toast";
// import { loadPlayer } from "../users/handleUser";

async function loadAttendeesByEvent(eventId) {
  return get("schedule", "attendeesByEvent", eventId).then((res) => {
    getAttendeesByEvent(eventId).then(att => {
      var promises = [];
      if (att && att.length) {
        att.forEach(a => {
          promises.push(db.attendees.delete(a.id));
        })
      }
      Promise.all(promises).then(() => {
        if (res) {
          db.attendees.bulkPut(res);
        }
      })
    });
    return res;
  });
}

async function getAttendeesByEvent(eventId) {
  return db.attendees.where({ eventId: eventId }).toArray();
}

async function getStatusByPlayerByEvent(playerId, eventId) {
  return db.attendees
    .where({ eventId: eventId, playerId: playerId })
    .toArray()
    .then((att) => {
      if (att) {
        att = att[0];
        if (att.isExcluded) {
          return "isExcluded";
        }
        if (att.isLate) {
          return "isLate";
        }
        if (att.isMissing) {
          return "isMissing";
        }
        if (att.isMissingWithJustification) {
          return "isMissingWithJustification";
        }
        return "isPresent";
      }
      return null;
    });
}

async function loadStatusByPlayerByEvent(playerId, eventId) {
  return get("schedule", "attendeesByEvent", eventId).then((res) => {
    if (res) {
      db.attendees.bulkPut(res);
      var att = res.find((att) => att.playerId === playerId);
      if (att) {
        if (att.isExcluded) {
          return "isExcluded";
        }
        if (att.isLate) {
          return "isLate";
        }
        if (att.isMissing) {
          return "isMissing";
        }
        if (att.isMissingWithJustification) {
          return "isMissingWithJustification";
        }
        return "isPresent";
      }
      return null;
    }
    return null;
  });
}

async function patchStatusByPlayerByEvent(playerId, eventId, status) {
  return get("schedule", "attendeesByEvent", eventId).then((res) => {
    if (res) {
      db.attendees.bulkPut(res);
      var att = res.find((att) => att.playerId === playerId);
      if (att) {
        switch (status) {
          case "isExcluded":
            att.isExcluded = true;
            att.isLate = false;
            att.isMissing = false;
            att.isMissingWithJustification = false;
            break;
          case "isLate":
            att.isExcluded = false;
            att.isLate = true;
            att.isMissing = false;
            att.isMissingWithJustification = false;
            break;
          case "isMissing":
            att.isExcluded = false;
            att.isLate = false;
            att.isMissing = true;
            att.isMissingWithJustification = false;
            break;
          case "isMissingWithJustification":
            att.isExcluded = false;
            att.isLate = false;
            att.isMissing = false;
            att.isMissingWithJustification = true;
            break;
          default:
            att.isExcluded = false;
            att.isLate = false;
            att.isMissing = false;
            att.isMissingWithJustification = false;
        }
        return patch("schedule", "attendees", att).then(res => {
          if (res.ok) {
            cogoToast.success(this.props.t("Update succeded"), { position: "bottom-right" });
          } else {
            cogoToast.error(this.props.t("Update failed") + " : error " + res.status, { position: "bottom-right" });
          }
        });
      }
    }
  });
}

async function loadCycles() {
  return get("schedule", "cycles").then((res) => {
    db.cycles.toArray().then(att => {
      var promises = [];
      if (att && att.length) {
        att.forEach(a => {
          promises.push(db.cycles.delete(a.id));
        })
      }
      Promise.all(promises).then(() => {
        if (res) {
          db.cycles.bulkPut(res);
        }
      })
    });
    return res;
  });
}

async function loadTypes() {
  return get("schedule", "eventsTypes").then((res) => {
    db.types.toArray().then(att => {
      var promises = [];
      if (att && att.length) {
        att.forEach(a => {
          promises.push(db.types.delete(a.id));
        })
      }
      Promise.all(promises).then(() => {
        if (res) {
          db.types.bulkPut(res);
        }
      })
    });
    return res;
  });
}

function getTypes() {
  return db.types.toArray();
}

async function loadCycle(id) {
  return get("schedule", "cycles", id).then((res) => {
    if (res && res.id) {
      db.cycles.put(res);
    }
    return res;
  });
}

function getCycle(id) {
  return db.cycles.get(id);
}


async function loadCyclesByGroup(groupId, startDate, endDate) {
  return get(
    "schedule",
    "cyclesByGroup",
    groupId + "/" + startDate + "/" + endDate
  ).then((res) => {
    db.cycles.where({ groupId: groupId })
      .and(
        (x) =>
          (moment(startDate).isBefore(x.startDate) &&
            moment(x.startDate).isBefore(endDate)) ||
          (moment(startDate).isBefore(x.endDate) &&
            moment(x.endDate).isBefore(endDate)) ||
          (moment(x.startDate).isBefore(startDate) &&
            moment(endDate).isBefore(x.endDate))
      )
      .toArray().then(att => {
        var promises = [];
        if (att && att.length) {
          att.forEach(a => {
            promises.push(db.cycles.delete(a.id));
          })
        }
        Promise.all(promises).then(() => {
          if (res) {
            res.length > 0 &&
              res.forEach((cycle) => {
                //cycle.url = "/training/cycle/" + cycle.id;
                cycle.link = "/training/cycle/" + cycle.id;
                cycle.class = "fc-event-solid-danger bg-primary text-white p-2";
                cycle.allDay = true;
              });
            db.cycles.bulkPut(res);
          }
        })
      });
    return res;
  });
}

async function getCyclesByGroup(groupId, startDate, endDate) {
  return db.cycles
    .where({ groupId: groupId })
    .and(
      (x) =>
        (moment(startDate).isBefore(x.startDate) &&
          moment(x.startDate).isBefore(endDate)) ||
        (moment(startDate).isBefore(x.endDate) &&
          moment(x.endDate).isBefore(endDate)) ||
        (moment(x.startDate).isBefore(startDate) &&
          moment(endDate).isBefore(x.endDate))
    )
    .toArray();
}

async function loadCyclesByPlayer(playerId, startDate, endDate) {
  return get(
    "schedule",
    "cyclesByPlayer",
    playerId + "/" + startDate + "/" + endDate
  ).then((res) => {
    return db.cyclesByPlayer.where({ playerId: playerId })
      .and(
        (x) =>
          (moment(startDate).isBefore(x.startDate) &&
            moment(x.startDate).isBefore(endDate)) ||
          (moment(startDate).isBefore(x.endDate) &&
            moment(x.endDate).isBefore(endDate)) ||
          (moment(x.startDate).isBefore(startDate) &&
            moment(endDate).isBefore(x.endDate))
      )
      .toArray().then(att => {
        var promises = [];
        if (att && att.length) {
          att.forEach(a => {
            promises.push(db.cyclesByPlayer.delete(a.id));
          })
        }
        return Promise.all(promises).then(() => {
          if (res) {
            res = res.map((cycle) => {
              //cycle.url = "/training/cycleByPlayer/" + cycle.id;
              cycle.link = "/training/cycleByPlayer/" + cycle.id;
              cycle.class = "fc-event-solid-danger bg-primary text-white p-2";
              cycle.allDay = true;
              return cycle;
            });
            db.cyclesByPlayer.bulkPut(res);
            return res;
          }
        })
      });
  });
}

async function getCyclesByPlayer(playerId, startDate, endDate) {
  return db.cyclesByPlayer
    .where({ playerId: playerId })
    .and(
      (x) =>
        (moment(startDate).isBefore(x.startDate) &&
          moment(x.startDate).isBefore(endDate)) ||
        (moment(startDate).isBefore(x.endDate) &&
          moment(x.endDate).isBefore(endDate)) ||
        (moment(x.startDate).isBefore(startDate) &&
          moment(endDate).isBefore(x.endDate))
    )
    .toArray();
}

async function loadWeeks() {
  return get("schedule", "weeks").then((res) => {
    db.weeks.toArray().then(att => {
      var promises = [];
      if (att && att.length) {
        att.forEach(a => {
          promises.push(db.weeks.delete(a.id));
        })
      }
      Promise.all(promises).then(() => {
        if (res) {
          db.weeks.bulkPut(res);
        }
      })
    });
    return res;
  });
}

async function loadWeeksByGroup(groupId, startDate, endDate) {
  return get(
    "schedule",
    "weeksByGroup",
    groupId + "/" + startDate + "/" + endDate
  ).then((res) => {
    return db.weeks.where({ groupId: groupId })
      .and(
        (x) =>
          (moment(startDate).isBefore(x.startDate) &&
            moment(x.startDate).isBefore(endDate)) ||
          (moment(startDate).isBefore(x.endDate) &&
            moment(x.endDate).isBefore(endDate)) ||
          (moment(x.startDate).isBefore(startDate) &&
            moment(endDate).isBefore(x.endDate))
      )
      .toArray().then(att => {
        var promises = [];
        if (att && att.length) {
          att.forEach(a => {
            promises.push(db.weeks.delete(a.id));
          })
        }
        return Promise.all(promises).then(() => {
          if (res) {
            res.length > 0 &&
              res.forEach((week) => {
                //week.url = "/training/week/" + week.id;
                week.link = "/training/week/" + week.id;
                week.class = " bg-primary text-white p-2";
                week.allDay = true;
              });
            db.weeks.bulkPut(res);
            return res;
          }
        })
      });
  });
}

async function getWeeksByGroup(groupId, startDate, endDate) {
  return db.weeks
    .where({ groupId: groupId })
    .and(
      (x) =>
        (moment(startDate).isBefore(x.startDate) &&
          moment(x.startDate).isBefore(endDate)) ||
        (moment(startDate).isBefore(x.endDate) &&
          moment(x.endDate).isBefore(endDate)) ||
        (moment(x.startDate).isBefore(startDate) &&
          moment(endDate).isBefore(x.endDate))
    )
    .toArray();
}

async function loadWeeksByPlayer(playerId, startDate, endDate) {
  return get(
    "schedule",
    "weeksByPlayer",
    playerId + "/" + startDate + "/" + endDate
  ).then((res) => {
    return db.weeksByPlayer.where({ playerId: playerId })
      .and(
        (x) =>
          (moment(startDate).isBefore(x.startDate) &&
            moment(x.startDate).isBefore(endDate)) ||
          (moment(startDate).isBefore(x.endDate) &&
            moment(x.endDate).isBefore(endDate)) ||
          (moment(x.startDate).isBefore(startDate) &&
            moment(endDate).isBefore(x.endDate))
      )
      .toArray().then(att => {
        var promises = [];
        if (att && att.length) {
          att.forEach(a => {
            promises.push(db.weeksByPlayer.delete(a.id));
          })
        }
        return Promise.all(promises).then(() => {
          if (res) {
            res.length > 0 &&
              res.forEach((week) => {
                //week.url = "/training/weekByPlayer/" + week.id;
                week.link = "/training/weekByPlayer/" + week.id;
                week.class = " bg-primary text-white p-2";
                week.allDay = true;
              });
            db.weeksByPlayer.bulkPut(res);
            return res;
          }
        })
      });
  });
}

async function getWeeksByPlayer(playerId, startDate, endDate) {
  return db.weeksByPlayer
    .where({ playerId: playerId })
    .and(
      (x) =>
        (moment(startDate).isBefore(x.startDate) &&
          moment(x.startDate).isBefore(endDate)) ||
        (moment(startDate).isBefore(x.endDate) &&
          moment(x.endDate).isBefore(endDate)) ||
        (moment(x.startDate).isBefore(startDate) &&
          moment(endDate).isBefore(x.endDate))
    )
    .toArray();
}

async function loadWeek(id) {
  return get("schedule", "weeks", id).then((res) => {
    if (res) {
      db.weeks.put(res);
    }
    return res;
  });
}

function getWeek(id) {
  return db.weeks.get(id);
}

async function loadWeekByPlayer(id) {
  return get("schedule", "weeks", id).then((res) => {
    if (res && res.id != null) {
      db.weeksByPlayer.put(res);
    }
    return res;
  });
}

function getWeekByPlayer(id) {
  return db.weeksByPlayer.get(id);
}

async function loadWeeksCategories() {
  return get("schedule", "weeksCategories").then((res) => {
    return db.weeksCategories.toArray().then(att => {
      var promises = [];
      if (att && att.length) {
        att.forEach(a => {
          promises.push(db.weeksCategories.delete(a.id));
        })
      }
      return Promise.all(promises).then(() => {
        if (res) {
          db.weeksCategories.bulkPut(res);
        }
        return res;
      })
    });
  });
}

async function loadEvents(startDate, endDate) {
  return get("schedule", "events", startDate + "/" + endDate).then((res) => {
    return db.events.where((x) =>
      (moment(startDate).isBefore(x.startDate) &&
        moment(x.startDate).isBefore(endDate)) ||
      (moment(startDate).isBefore(x.endDate) &&
        moment(x.endDate).isBefore(endDate)) ||
      (moment(x.startDate).isBefore(startDate) &&
        moment(endDate).isBefore(x.endDate))
    )
      .toArray().then(att => {
        var promises = [];
        if (att && att.length) {
          att.forEach(a => {
            promises.push(db.events.delete(a.id));
          })
        }
        return Promise.all(promises).then(() => {
          if (res) {
            db.events.bulkPut(res);
          }
          return res;
        })
      });
  });
}

async function loadEvent(id) {
  return get("schedule", "events", id).then((res) => {
    if (res) {
      db.events.put(res);
    }
    return res;
  });
}

function getEvent(id) {
  return db.events.get(id);
}

async function loadEventsByGroup(groupId, start, end) {
  return get(
    "schedule",
    "eventsByGroup",
    groupId + "/" + start + "/" + end
  ).then((res) => {
    if (res) {
      db.events.bulkPut(res);
    }
    return res;
  });
}

async function getEventsByGroup(groupId, startDate, endDate) {
  return db.events
    .where({ groupId: groupId })
    .and(
      (x) =>
        moment(startDate).isBefore(x.startDate) &&
        moment(x.startDate).isBefore(endDate)
    )
    .toArray();
}

async function loadEventsByGroupAndType(groupId, typeId, start, end) {
  return getUserProfile().then((profile) => {
    return get(
      "schedule",
      "eventsByGroupAndType",
      groupId + "/" + typeId + "/" + start + "/" + end
    ).then((res) => {
      if (res) {
        res.length > 0 &&
          res.forEach((event) => {
            if (event.typeId === "QuestionnaireForme") {
              //event.url = "/training/wellnessQuestionnaire/" + event.id;
              // event.link = "/training/wellnessQuestionnaire/" + event.id;
              event.class = "bg-danger text-white p-1";
            } else if (event.typeId === "Match tennis") {
              event.link = "/competitionTennis/match/" + event.id;
              event.class = "bg-danger text-white p-1";
            } else if (
              event.typeId === "Entrainement" &&
              profile.sport === "RugbyXV"
            ) {
              //event.url = "/training/rugbySession/" + event.id;
              // event.link = "/training/rugbySession/" + event.id;
              event.class = "bg-warning text-white p-1";
            } else if (
              event.typeId === "Entrainement" &&
              profile.sport === "Foot"
            ) {
              //event.url = "/training/footballSession/" + event.id;
              event.link = "/training/footballSession/" + event.id;
              event.class = "bg-warning text-white p-1";
            } else if (
              event.typeId === "Entrainement" &&
              profile.sport === "Tennis"
            ) {
              //event.url = "/training/tennisSession/" + event.id;
              event.link = "/training/tennisSession/" + event.id;
              event.class = "bg-warning text-white p-1";
            } else if (event.typeId === "Musculation") {
              //event.url = "/training/workoutSession/" + event.id;
              //event.link = "/training/workoutSession/" + event.id;
              event.class = "bg-info text-white p-1";
            } else if (event.typeId === "Energetique") {
              //event.url = "/training/conditioningSession/" + event.id;
              //event.link = "/training/conditioningSession/" + event.id;
              event.class = "bg-info text-white p-1";
            } else if (event.typeId === "Prévention") {
              //event.url = "/training/preventionSession/" + event.id;
              //event.link = "/training/preventionSession/" + event.id;
              event.class = "bg-info text-white p-1";
            } else if (event.typeId === "Physique") {
              event.link = "/training/physicalSession/" + event.id;
              event.class = "bg-info text-white p-1";
            } else if (event.typeId === "Mental") {
              event.link = "/training/mentalSession/" + event.id;
              event.class = "bg-success text-white p-1";
            } else if (event.typeId === "Cours") {
              event.class = "bg-gray-700 text-white p-1";
            } else if (event.typeId === "Médical") {
              event.class = "bg-primary text-white p-1";
            } else if (event.typeId === "Examen") {
              event.class = "bg-primary text-white p-1";
            } else if (event.typeId === "Repas") {
              event.class = "bg-gray-500 text-white p-1";
            } else if (event.typeId === "Repos") {
              event.class = "bg-gray-400 p-1";
            } else if (event.typeId === "Déplacement") {
              event.class = "bg-gray-500 text-white p-1";
            } else {
              event.class = "bg-gray-600  text-white p-1";
            }
          });
        db.events.clear();
        db.events.bulkPut(res);
      }
      return res;
    });
  });
}

async function getEventsByGroupAndType(groupId, typeId, startDate, endDate) {
  return db.events
    .where({ groupId: groupId, typeId: typeId })
    .and(
      (x) =>
        moment(startDate).isBefore(x.startDate) &&
        moment(x.startDate).isBefore(endDate)
    )
    .toArray();
}

async function loadEventsByPlayer(playerId, start, end) {
  return getUserProfile().then((profile) => {
    return get(
      "schedule",
      "eventsByPlayer",
      playerId + "/" + start + "/" + end
    ).then((res) => {
      if (res) {
        res.length > 0 &&
          res.forEach((event) => {
            event.playerId = playerId;
            if (event.typeId === "QuestionnaireForme") {
              //event.url = "/training/wellnessQuestionnaire/" + event.id;
              event.link = "/training/wellnessQuestionnaire/" + event.id;
            } else if (event.typeId === "Match tennis") {
              event.link = "/competitionTennis/match/" + event.id;
            } else if (
              event.typeId === "Entrainement" &&
              profile.sport === "RugbyXV"
            ) {
              //event.url = "/training/rugbySession/" + event.id;
              event.link = "/training/rugbySession/" + event.id;
            } else if (
              event.typeId === "Entrainement" &&
              profile.sport === "Foot"
            ) {
              //event.url = "/training/footballSession/" + event.id;
              event.link = "/training/footballSession/" + event.id;
            } else if (
              event.typeId === "Entrainement" &&
              profile.sport === "Tennis"
            ) {
              //event.url = "/training/tennisSession/" + event.id;
              event.link =
                "/training/tennisSession/" + event.id + "/" + playerId;
            } else if (event.typeId === "Musculation") {
              //event.url = "/training/workoutSession/" + event.id;
              event.link = "/training/workoutSession/" + event.id;
            } else if (event.typeId === "Energetique") {
              //event.url = "/training/conditioningSession/" + event.id;
              event.link = "/training/conditioningSession/" + event.id;
            } else if (event.typeId === "Prévention") {
              //event.url = "/training/preventionSession/" + event.id;
              event.link = "/training/preventionSession/" + event.id;
            } else if (event.typeId === "Physique") {
              event.link =
                "/training/physicalSession/" + event.id + "/" + playerId;
            } else if (event.typeId === "Mental") {
              event.link =
                "/training/mentalSession/" + event.id + "/" + playerId;
            }
          });
        db.eventsByPlayer.clear();
        db.eventsByPlayer.bulkPut(res);
      }
      return res;
    });
  });
}

async function loadEventsByPlayers(playersIds, start, end) {
  return getUserProfile().then((profile) => {
    return post(
      "schedule",
      "eventsByPlayers",
      { startDate: start, endDate: end, playersIds: playersIds }
    ).then(res => { return res.json() }).then((res) => {
      if (res) {
        res.length > 0 &&
          res.forEach((event) => {
            //event.playerId = playerId;
            if (event.typeId === "QuestionnaireForme") {
              //event.url = "/training/wellnessQuestionnaire/" + event.id;
              event.link = "/training/wellnessQuestionnaire/" + event.id;
            } else if (event.typeId === "Match tennis") {
              event.link = "/competitionTennis/match/" + event.id;
            } else if (
              event.typeId === "Entrainement" &&
              profile.sport === "RugbyXV"
            ) {
              //event.url = "/training/rugbySession/" + event.id;
              event.link = "/training/rugbySession/" + event.id;
            } else if (
              event.typeId === "Entrainement" &&
              profile.sport === "Foot"
            ) {
              //event.url = "/training/footballSession/" + event.id;
              event.link = "/training/footballSession/" + event.id;
            } else if (
              event.typeId === "Entrainement" &&
              profile.sport === "Tennis"
            ) {
              //event.url = "/training/tennisSession/" + event.id;
              event.link =
                "/training/tennisSession/" + event.id + "/" + event.playerId;
            }
            // else if (event.typeId === "Musculation") {
            //   //event.url = "/training/workoutSession/" + event.id;
            //   event.link = "/training/workoutSession/" + event.id;
            // } else if (event.typeId === "Energetique") {
            //   //event.url = "/training/conditioningSession/" + event.id;
            //   event.link = "/training/conditioningSession/" + event.id;
            // } else if (event.typeId === "Prévention") {
            //   //event.url = "/training/preventionSession/" + event.id;
            //   event.link = "/training/preventionSession/" + event.id;
            // }
            else if (event.typeId === "Physique") {
              event.link =
                "/training/physicalSession/" + event.id + "/" + event.playerId;
            } else if (event.typeId === "Mental") {
              event.link =
                "/training/mentalSession/" + event.id + "/" + event.playerId;
            }
          });
        db.eventsByPlayer.clear();
        db.eventsByPlayer.bulkPut(res);
      }
      return res;
    });
  });
}

async function getEventsByPlayer(playerId, startDate, endDate) {
  return db.eventsByPlayer.where({ playerId: playerId }).and((event) => moment(startDate).isBefore(event.startDate) && moment(event.startDate).isBefore(endDate)).toArray();
}

async function loadEventsByPlayerAndType(playerId, typeId, startDate, endDate) {
  return getUserProfile().then((profile) => {
    return get(
      "schedule",
      "EventsByPlayerAndType",
      playerId + "/" + typeId + "/" + startDate + "/" + endDate
    ).then((res) => {
      if (res) {
        res.length > 0 &&
          res.forEach((event) => {
            event.playerId = playerId;
            if (event.typeId === "QuestionnaireForme") {
              //event.url = "/training/wellnessQuestionnaire/" + event.id;
              event.link = "/training/wellnessQuestionnaire/" + event.id;
            } else if (event.typeId === "Match tennis") {
              event.link = "/competitionTennis/match/" + event.id;
            } else if (
              event.typeId === "Entrainement" &&
              profile.sport === "RugbyXV"
            ) {
              //event.url = "/training/rugbySession/" + event.id;
              event.link = "/training/rugbySession/" + event.id;
            } else if (
              event.typeId === "Entrainement" &&
              profile.sport === "Foot"
            ) {
              //event.url = "/training/footballSession/" + event.id;
              event.link = "/training/footballSession/" + event.id;
            } else if (
              event.typeId === "Entrainement" &&
              profile.sport === "Tennis"
            ) {
              //event.url = "/training/tennisSession/" + event.id;
              event.link =
                "/training/tennisSession/" + event.id + "/" + playerId;
            }
            //  else if (event.typeId === "Musculation") {
            //   //event.url = "/training/workoutSession/" + event.id;
            //   event.link = "/training/workoutSession/" + event.id;
            // } else if (event.typeId === "Energetique") {
            //   //event.url = "/training/conditioningSession/" + event.id;
            //   event.link = "/training/conditioningSession/" + event.id;
            // } else if (event.typeId === "Prévention") {
            //   //event.url = "/training/preventionSession/" + event.id;
            //   event.link = "/training/preventionSession/" + event.id;
            // }
            else if (event.typeId === "Physique") {
              event.link =
                "/training/physicalSession/" + event.id + "/" + playerId;
            } else if (event.typeId === "Mental") {
              event.link =
                "/training/mentalSession/" + event.id + "/" + playerId;
            }
          });
        return getEventsByPlayerAndType(playerId, typeId, startDate, endDate).then(events => {
          var promises = [];
          if (events && events.length) {
            events.forEach(e => {
              promises.push(db.events.delete(e.id));
            });
          }
          return Promise.all(promises).then(() => {
            db.events.bulkPut(res);
            return res;
          })
        })
      } else {
        return res;
      }
    });
  });
}

async function getEventsByPlayerAndType(playerId, typeId, startDate, endDate) {
  return db.attendees
    .where({ playerId: playerId })
    .toArray()
    .then((attendees) => {
      if (attendees && attendees.length > 0) {
        const eventsId = attendees.forEach((att) => att.eventId);
        return db.events
          .where({ typeId: typeId })
          .and(
            (event) =>
              eventsId &&
              eventsId.includes(event.id) &&
              moment(startDate).isBefore(event.startDate) &&
              moment(event.startDate).isBefore(endDate)
          )
          .toArray();
      }
    });
}

async function loadEventsByStaff(staffId, start, end) {
  return get(
    "schedule",
    "eventsByStaff",
    staffId + "/" + start + "/" + end
  ).then((res) => {
    if (res) {
      db.events.bulkPut(res);
    }
    return res;
  });
}

async function loadEventsByType(typeId, start, end) {
  return get("schedule", "eventsByType", typeId + "/" + start + "/" + end).then(
    (res) => {
      if (res) {
        db.events.bulkPut(res);
      }
      return res;
    }
  );
}

async function loadOrganizersByEvent(eventId) {
  return get("schedule", "organizersByEvent", eventId).then((res) => {
    db.organizers.where({ eventId: eventId }).toArray().then(att => {
      var promises = [];
      if (att && att.length) {
        att.forEach(a => {
          promises.push(db.organizers.delete(a.id));
        })
      }
      Promise.all(promises).then(() => {
        if (res) {
          db.organizers.bulkPut(res);
        }
      })
    });
    return res;
  });
}

async function getOrganizersByEvent(eventId) {
  return db.organizers.where({ eventId: eventId }).toArray();
}

async function loadSeasons() {
  return get("schedule", "seasons").then((res) => {
    var promises = [];
    if (res && res.length) {
      res.forEach(a => {
        promises.push(db.seasons.delete(a.id));
      })
    }
    Promise.all(promises).then(() => {
      if (res) {
        res = res.map((r) => {
          r.key = r.id
          r.label = r.name
          return r
        })
        db.seasons.bulkPut(res);
      }
    })
    if (res) res = res.sort((a, b) =>
      moment(a.endDate).isSame(b.endDate)
        ? 0
        : moment(a.endDate).isAfter(b.endDate)
          ? 1
          : -1
    );
    return res;
  });
}

function getSeasons() {
  return db.seasons.toArray();
}

function getSeasonById(id) {
  return db.seasons.get(id);
}

async function loadTasksByStaff(staffId) {
  return get("schedule", "tasksByStaff", staffId).then((res) => {
    db.tasks.where({ staffId: staffId }).toArray().then(indexedDBres => {
      var promises = [];
      if (indexedDBres && indexedDBres.length) {
        indexedDBres.forEach(r => {
          promises.push(db.tasks.delete(r.id));
        })
      }
      Promise.all(promises).then(() => {
        if (res) {
          db.tasks.bulkPut(res);
        }
      })
    });
    return res;
  });
}

function getCurrentSeason(seasons) {
  var today = moment();
  seasons = seasons.filter(
    (s) => moment(s.startDate) < today && moment(s.endDate) > today
  );
  return seasons[0];
}

function copyEvent(id) {
  var promises = [];
  promises.push(loadEvent(id));
  promises.push(loadAttendeesByEvent(id));
  promises.push(loadOrganizersByEvent(id));

  Promise.all(promises).then(([event, attendees, organizers]) => {
    var newEvent = event;
    delete newEvent.id;
    if (newEvent.specificInfos && newEvent.specificInfos.id) {
      delete newEvent.specificInfos.id;
    }
    post("schedule", "events", newEvent).then(res => {
      if (res.ok) {
        return res.json()
      } else {
        cogoToast.error(newEvent.name + "-" + this.props.t("Copy Event Error"), { position: "bottom-right" });
        throw new Error(res.status)
      }
    }).then(res => {
      newEvent.id = res.id;
      var promisesAttOrg = [];
      if (organizers && organizers.length) {
        organizers.forEach(o => {
          var invit = Object.assign({}, o);
          invit.eventId = newEvent.id;
          delete invit.id;
          delete invit.date;
          promisesAttOrg.push(post("schedule", "organizers", invit).then(res => {
            if (res.ok) {
              return true;
            } else {
              cogoToast.error(newEvent.name + "-" + this.props.t("Copy Organizers Error"), { position: "bottom-right" });
              return false;
            }
          }));
        })
      }
      if (attendees && attendees.length) {
        attendees.forEach(o => {
          var invit = Object.assign({}, o);
          invit.eventId = newEvent.id;
          invit.isExcluded = false;
          invit.isLate = false;
          invit.isMissing = false;
          invit.isMissingWithJustification = false;
          delete invit.id;
          delete invit.date;
          promisesAttOrg.push(post("schedule", "attendees", invit).then(res => {
            if (res.ok) {
              return true;
            } else {
              cogoToast.error(newEvent.name + "-" + this.props.t("Copy Attendees Error"), { position: "bottom-right" });
              return false;
            }
          }));
        })
      }
      return Promise.all(promisesAttOrg).then(() => {
        var failed = promisesAttOrg.find(res => res === false);
        if (!(failed && failed.length)) {
          cogoToast.success(newEvent.name + "-" + this.props.t("Copy Successed"), { position: "bottom-right" });
        }
        this.reloadCalendar();
      });
    }).catch(err => {
      this.setState({ errors: [err.toString()], loading: false });
    });

  })
}

function loadWeeksResultValue(playerId, startDate, endDate) {
  return get("schedule", "weeksResultValue", playerId + "/" + startDate + "/" + endDate).then(res => {
    if (res && res.length) {
      res.forEach(r => {
        var week = r.week;
        if (r.week) {
          db.cyclesByPlayer.put(r.week);
        }
        delete r.week;
        db.weeksTrainingResultValue.put(r);
        r.week = week;
      });
    }
    return res;
  })
}

function getWeeksResultValue(playerId, startDate, endDate) {
  return db.cyclesByPlayer.where({ playerId: playerId })
    .and(
      (x) =>
        (moment(startDate).isBefore(x.startDate) &&
          moment(x.startDate).isBefore(endDate)) ||
        (moment(startDate).isBefore(x.endDate) &&
          moment(x.endDate).isBefore(endDate)) ||
        (moment(x.startDate).isBefore(startDate) &&
          moment(endDate).isBefore(x.endDate))
    ).toArray().then(res => {
      var promises = [];
      res.forEach(r => {
        promises.push(db.weeksResultValue.get(r.id).then(wR => {
          if (wR) {
            wR.week = r;
          }
          return wR
        }))
      });
      return Promise.all(promises)
    })
}

function loadWeeksMentalResultValue(playerId, startDate, endDate) {
  return get("schedule", "weeksResultValue", "Mental/" + playerId + "/" + startDate + "/" + endDate).then(res => {
    if (res && res.length) {
      res.forEach(r => {
        var week = r.week;
        if (r.week) {
          db.cyclesByPlayer.put(r.week);
        }
        delete r.week;
        db.weeksMentalResultValue.put(r);
        r.week = week;
      });
    }
    return res;
  })
}

function getWeeksMentalResultValue(playerId, startDate, endDate) {
  return db.cyclesByPlayer.where({ playerId: playerId })
    .and(
      (x) =>
        (moment(startDate).isBefore(x.startDate) &&
          moment(x.startDate).isBefore(endDate)) ||
        (moment(startDate).isBefore(x.endDate) &&
          moment(x.endDate).isBefore(endDate)) ||
        (moment(x.startDate).isBefore(startDate) &&
          moment(endDate).isBefore(x.endDate))
    ).toArray().then(res => {
      var promises = [];
      res.forEach(r => {
        promises.push(db.weeksMentalResultValue.get(r.id).then(wR => {
          if (wR) {
            wR.week = r;
          }
          return wR
        }))
      });
      return Promise.all(promises)
    })
}

function loadWeeksPhysicalResultValue(playerId, startDate, endDate) {
  return get("schedule", "weeksResultValue", "Physical/" + playerId + "/" + startDate + "/" + endDate).then(res => {
    if (res && res.length) {
      res.forEach(r => {
        var week = r.week;
        if (r.week) {
          db.cyclesByPlayer.put(r.week);
        }
        delete r.week;
        db.weeksPhysicalResultValue.put(r);
        r.week = week;
      });
    }
    return res;
  })
}

function getWeeksPhysicalResultValue(playerId, startDate, endDate) {
  return db.cyclesByPlayer.where({ playerId: playerId })
    .and(
      (x) =>
        (moment(startDate).isBefore(x.startDate) &&
          moment(x.startDate).isBefore(endDate)) ||
        (moment(startDate).isBefore(x.endDate) &&
          moment(x.endDate).isBefore(endDate)) ||
        (moment(x.startDate).isBefore(startDate) &&
          moment(endDate).isBefore(x.endDate))
    ).toArray().then(res => {
      var promises = [];
      res.forEach(r => {
        promises.push(db.weeksPhysicalResultValue.get(r.id).then(wR => {
          if (wR) {
            wR.week = r;
          }
          return wR
        }))
      });
      return Promise.all(promises)
    })
}

function loadWeeksTrainingResultValue(playerId, startDate, endDate) {
  return get("schedule", "weeksResultValue", "Training/" + playerId + "/" + startDate + "/" + endDate).then(res => {
    if (res && res.length) {
      res.forEach(r => {
        var week = r.week;
        if (r.week) {
          db.cyclesByPlayer.put(r.week);
        }
        delete r.week;
        db.weeksTrainingResultValue.put(r);
        r.week = week;
      });

    }
    return res;
  })
}

function getWeeksTrainingResultValue(playerId, startDate, endDate) {
  return db.cyclesByPlayer.where({ playerId: playerId })
    .and(
      (x) =>
        (moment(startDate).isBefore(x.startDate) &&
          moment(x.startDate).isBefore(endDate)) ||
        (moment(startDate).isBefore(x.endDate) &&
          moment(x.endDate).isBefore(endDate)) ||
        (moment(x.startDate).isBefore(startDate) &&
          moment(endDate).isBefore(x.endDate))
    ).toArray().then(res => {
      var promises = [];
      res.forEach(r => {
        promises.push(db.weeksTrainingResultValue.get(r.id).then(wR => {
          if (wR) {
            wR.week = r;
          }
          return wR
        }))
      });
      return Promise.all(promises)
    })

}
function loadRpeByEvent(eventId) {
  return get("monitoring", "rpeDataByEvent", eventId).then(res => {
    return res;
  })
}

function loadRpeByEventForPlayer(eventId, playerId, typeId) {
  return get("monitoring", "rpeDataByEvent", eventId).then(res => {
    if (res && res.length) {
      res.map(r => {
        r.typeId = typeId
        return r
      });
      res = res.filter(r => r.playerId === playerId)
    }
    return res;
  })
}

function loadEventsDayTimeByTypeIdAndPlayerId(typeId, playerId, startDate, endDate) {
  return get("schedule", "daysResultValue", typeId + "/" + playerId + "/" + startDate + "/" + endDate).then(res => {
    res.map(r => {
      r.id = typeId + playerId + r.date
      r.kind = typeId
      r.playerId = playerId
      return r
    });
    db.eventsDayTimeByTypeIdAndPlayerId.bulkPut(res);
    return res;
  })
}

function getEventsDayTimeByTypeIdAndPlayerId(typeId, playerId, startDate, endDate) {
  return db.eventsDayTimeByTypeIdAndPlayerId.where({ playerId, kind: typeId }).and(
    (x) =>
      (moment(startDate).isBefore(x.date) && moment(x.date).isBefore(endDate))
  ).toArray()
}

function loadEventsDayTimeByPlayerId(playerId, startDate, endDate) {
  return get("schedule", "daysResultValue", playerId + "/" + startDate + "/" + endDate).then(res => {
    res.map(r => {
      r.id = playerId + r.date
      r.playerId = playerId
      r.results.map(data => {
        if (data.typeId === "Entrainement") {
          data.kind = "Tennis"
        } else if (data.typeId === "Musculation" || data.typeId === "Prevention" || data.typeId === "Energetique") {
          data.kind = "Physique"
        } else if (data.typeId === "Match tennis") {
          data.kind = "Match"
        }
        else data.kind = data.typeId
        return data
      })
      return r
    });
    db.eventsDayTimeByPlayerId.bulkPut(res);
    return res;
  })
}

function getEventsDayTimeByPlayerId(playerId, startDate, endDate) {
  return db.eventsDayTimeByPlayerId.where({ playerId }).and(
    (x) =>
      (moment(startDate).isBefore(x.date) && moment(x.date).isBefore(endDate))
  ).toArray()
}

export {
  loadAttendeesByEvent,
  loadCycle,
  loadWeeksCategories,
  loadCycles,
  loadCyclesByGroup,
  loadCyclesByPlayer,
  loadEvent,
  loadEvents,
  loadEventsByGroup,
  loadEventsByGroupAndType,
  loadEventsByPlayer,
  loadEventsByPlayers,
  loadEventsByPlayerAndType,
  loadEventsByStaff,
  loadEventsByType,
  loadOrganizersByEvent,
  loadSeasons,
  loadTasksByStaff,
  loadWeeks,
  loadWeek,
  loadWeekByPlayer,
  loadWeeksByGroup,
  loadWeeksByPlayer,
  getAttendeesByEvent,
  getOrganizersByEvent,
  getCurrentSeason,
  getCyclesByGroup,
  getCyclesByPlayer,
  getCycle,
  getWeeksByGroup,
  getWeeksByPlayer,
  getWeek,
  getWeekByPlayer,
  getEventsByGroup,
  getEventsByPlayer,
  getEventsByPlayerAndType,
  getEvent,
  getEventsByGroupAndType,
  getSeasons,
  loadTypes,
  getTypes,
  loadStatusByPlayerByEvent,
  getStatusByPlayerByEvent,
  patchStatusByPlayerByEvent,
  copyEvent,
  loadWeeksResultValue,
  getWeeksResultValue,
  loadWeeksMentalResultValue,
  getWeeksMentalResultValue,
  loadWeeksPhysicalResultValue,
  getWeeksPhysicalResultValue,
  loadWeeksTrainingResultValue,
  getWeeksTrainingResultValue,
  loadRpeByEvent,
  loadRpeByEventForPlayer,
  getSeasonById,
  // getRpeByEvent,
  loadEventsDayTimeByPlayerId,
  getEventsDayTimeByPlayerId,
  getEventsDayTimeByTypeIdAndPlayerId,
  loadEventsDayTimeByTypeIdAndPlayerId,
};
