import db from "./db";
import get from "../../requests/get";
import { patch } from "../../requests/index";
import cogoToast from "cogo-toast";


async function loadUnavailibilitiesCurrentByPlayer(id) {
  return get("group", "unavailabilitiesCurrentByPlayer", id);
}

async function loadStaffs() {
  return get("group", "staffs").then((res) => {
    if (res) {
      res = res.map((staff) => {
        staff.name = staff.lastName + " " + staff.firstName
        return staff
      })
      db.staffs.bulkPut(res);
      res = res.sort((a, b) =>
        a.lastName === b.lastName ? 0 : a.lastName > b.lastName ? 1 : -1
      );
    }
    return res;
  });
}

async function loadStaffsByPlayer(playerId) {
  return get("group", "staffsByPlayer", playerId).then((res) => {
    if (res && res.length) {
      res = res.filter(
        (o) => o.role.id === "Entraineur" || o.role.id === "Preparateur"
      );
      db.staffs.bulkPut(res);
      res.sort((a, b) =>
        a.lastName === b.lastName ? 0 : a.lastName > b.lastName ? 1 : -1
      );

    }

    return res;
  });
}

async function loadPlayersByStaff(staffId) {
  return get("group", "playersByStaff", staffId).then(res => {
    if (res && res.length) {
      res.sort((a, b) =>
        a.lastName === b.lastName ? 0 : a.lastName > b.lastName ? 1 : -1
      );

    }

    return res;
  })
}

async function loadPlayers() {
  return get("group", "players").then((res) => {
    getPlayers().then(play => {
      var promises = [];
      if (play && play.length) {
        play.forEach(p => {
          promises.push(db.players.delete(p.id));
        });
      }
      Promise.all(promises).then(() => {
        if (res) {
          db.players.bulkPut(res);
        }
      })
    })
    return res.sort((a, b) =>
      a.lastName === b.lastName ? 0 : a.lastName > b.lastName ? 1 : -1
    );

  });
}

async function loadPlayersAll() {
  return get("group", "playersAll").then((res) => {
    if (res) {
      db.playersAll.bulkPut(res);
    }
    return res.sort((a, b) =>
      a.lastName === b.lastName ? 0 : a.lastName > b.lastName ? 1 : -1
    );
  });
}

async function loadPlayersWithAvailability() {
  return get("group", "players").then((res) => {
    if (res) {
      var promises = [];
      res = res.forEach((user) => {
        promises.push(
          loadUnavailibilitiesCurrentByPlayer(user.id).then((status) => {
            if (status) {
              user.status = status;
            }
            return user;
          })
        );
      });
      return Promise.all(promises).then((users) => {
        db.players.bulkPut(users);
        return users.sort((a, b) =>
          a.lastName === b.lastName ? 0 : a.lastName > b.lastName ? 1 : -1
        );
      });
    }
    return [];
  });
}

async function loadPlayersByGroup(groupId) {
  return get("group", "playersByGroup", groupId)
}

async function loadPlayer(id) {
  return get("group", "players", id).then((res) => {
    if (res && res.id) {
      db.players.put(res);
    } else {
      res = null
    }
    return res;
  });
}

function getPlayer(id) {
  return db.players.get(id);
}

async function loadStaff(id) {
  return get("group", "staffs", id).then((res) => {
    if (res && res.id) {
      db.staffs.put(res);
    } else {
      res = null
    }
    return res;
  });
}

function getStaff(id) {
  return db.staffs.get(id)
}

async function loadGenders() {
  return get("group", "genders").then((res) => {
    if (res) {
      db.genders.bulkPut(res);
    }
    return res;
  });
}

async function loadGroups() {
  return get("group", "groups").then((res) => {
    if (res) {
      db.groups.bulkPut(res);
    }
    return res;
  });
}

async function loadPositions() {
  return get("group", "positions").then((res) => {
    if (res) {
      db.positions.bulkPut(res);
    }
    return res;
  });
}

async function loadRoles() {
  return get("group", "roles").then((res) => {
    if (res) {
      db.roles.bulkPut(res);
    }
    return res;
  });
}

function getPlayers() {
  return db.players.toArray().then((users) => {
    return users
      ? users.sort((a, b) =>
        a.lastName === b.lastName ? 0 : a.lastName > b.lastName ? 1 : -1
      )
      : null;
  });
}

async function getPlayersAll() {
  return db.playersAll.toArray().then((users) => {
    return users
      ? users.sort((a, b) =>
        a.lastName === b.lastName ? 0 : a.lastName > b.lastName ? 1 : -1
      )
      : null;
  });
}

function getStaffs() {
  return db.staffs.toArray().then((users) => {
    return users
      ? users.sort((a, b) =>
        a.lastName === b.lastName ? 0 : a.lastName > b.lastName ? 1 : -1
      )
      : null;
  });
}
function getGenders() {
  return db.genders.toArray();
}
function getGroups() {
  return db.groups.toArray();
}
function getPositions() {
  return db.positions.toArray();
}
function getRoles() {
  return db.roles.toArray();
}

async function modifyPassword(newPassword, userId, t, userIdMatch) {
  var APImodule = "group"
  var APIfeature = "userPassword"
  var errorq = false
  var password = newPassword.new;

  if (password === newPassword.confirmation) {
    if (password.length >= 8) {
      if (password.length <= 64) {
        var rulesRespected = 0
        var lowercase = false, uppercase = false, numbers = false, symbols = false;
        // var validation = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,64}$/ // /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,64}$/;
        var validationL = /^(?=.*[a-z]).{8,64}$/
        var validationU = /^(?=.*[A-Z]).{8,64}$/
        var validationN = /^(?=.*[0-9]).{8,64}$/
        var validationS = /^(?=.*[^a-zA-Z0-9])(?!.*\s).{8,64}$/
        if (password.match(validationL)) {
          lowercase = true
          rulesRespected += 1
        }
        if (password.match(validationU)) {
          uppercase = true
          rulesRespected += 1
        }
        if (password.match(validationN)) {
          numbers = true
          rulesRespected += 1
        }
        if (password.match(validationS)) {
          symbols = true
          rulesRespected += 1
        }
        if (rulesRespected >= 3) {
          cogoToast.success(t("The new password respect the security rules - ") + rulesRespected + t(" out of 4 rules respected"))
          var formData = {
            userId,
            password: newPassword.new,
            old:  newPassword.old,
            checkOld: userIdMatch
          }
          var contentType = ""
  
          patch(APImodule, APIfeature, formData, contentType).then(
            (res) => {
              if (res.ok) {
                cogoToast.success(t("Update Successed"), { position: "bottom-right" });
                return errorq;
              } else {
                cogoToast.error("Error PATCH:" + res.status + " " + APImodule + ", " + APIfeature, { position: "bottom-right" });
                throw new Error(res.status);
              }
            }
          ).catch(err => {
            cogoToast.error("Error PATCH:" + APImodule + ", " + APIfeature, { position: "bottom-right" });
          });
        }
        else {
          cogoToast.error(<div>
            <div>
              {t("Your password only follows x rules of security and does not meet the minimum required (3 out of 4)", {rulesRespected})}
            </div>
            <div>
              {t("Have at least one lowercase character") + " : " + lowercase}
            </div>
            <div>
              {t("Have at least one uppercase character") + " : " + uppercase}
            </div>
            <div>
              {t("Have at least one number             ") + " : " + numbers}
            </div>
            <div>
              {t("Have at least one symbol character   ") + " : " + symbols}
            </div>
          </div>)
          errorq = true
        }
      }
      else {
        cogoToast.error(t("password is more than 64 character"));
        errorq = true
      }
    }
    else {
      cogoToast.error(t("password is less than 8 character"));
      errorq = true
    }
  }
  else {
    cogoToast.error(t("fields do not match together"));
    errorq = true
  }

  return errorq;
}

// async function loadPlayersAuthorizationsByStaff(staffId) {
//   return get("group", "staffsByPlayer", staffId).then((res) => {
//     if (res) {
//       db.staffsPlayersAuthorizations.bulkPut(res);
//     }
//     return res;
//   });
// }

// function getPlayersAuthorizationsByStaff(staffId) {
//   var results = db.staffsPlayersAuthorizations
//     .where({ staffId: staffId })
//     .toArray();
//   if (results) {
//     return results;
//   }
// }

export {
  loadPlayers,
  loadPlayersByStaff,
  loadPlayersWithAvailability,
  loadPlayer,
  loadPlayersAll,
  loadStaffs,
  loadStaffsByPlayer,
  loadGenders,
  loadGroups,
  loadPositions,
  loadRoles,
  getPlayers,
  getPlayersAll,
  getPlayer,
  getStaffs,
  getGenders,
  getGroups,
  getPositions,
  getRoles,
  loadStaff,
  getStaff,
  loadPlayersByGroup,
  modifyPassword
  // getPlayersAuthorizationsByStaff,
  // loadPlayersAuthorizationsByStaff,
};
