import React from "react";
import ActionButton from "../components/buttons/ActionButton";
import { withTranslation } from "react-i18next";
import TableSimple from "../components/table/TableSimple";
import SearchInput from "../components/forms/input/SearchInput";
import FiltersSearch from "../components/filters/FiltersSearch";
import FiltersDropDowns from "../components/filters/FiltersDropDowns";
import FilterColumns from "../components/filters/FilterColumns";
import ExportToCSV from "../components/export/ExportToCSV";
import IconRound32x32 from "../components/icons/IconRound32x32";
import { Link } from "react-router-dom";
import { SelectMultiple } from "../components/forms";
import generateColor from "../utils/generateColor";
import FilterSeparator from "../components/filters/FilterSeparator";
import FiltersTitle from "../components/filters/FiltersTitle";
import FiltersBar from "../components/filters/FiltersBar";

class TableContacts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actions: [],
      types: [],
      selectedTypes: [],
      players: [],
      selectedPlayers: [],
      columns: [],
      searchText: "",
    };
  }

  componentDidMount() {
    this.prepareData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.prepareData();
    }
  }

  prepareData = () => {
    var contacts = this.props.contacts;
    var types = [];
    var selectedTypes = [];
    var players = [];
    var selectedPlayers = [];
    if (contacts) {
      contacts = contacts.map((contact) => {
        if (types && !types.find((o) => o.key === contact.type.id)) {
          types.push({
            key: contact.type.id,
            label: contact.type.name,
          });
          selectedTypes.push(contact.type.id);
        }
        if (players && !players.find((o) => o.key === contact.player.id)) {
            players.push({
              key: contact.player.id,
              label: contact.player.name,
            });
            selectedPlayers.push(contact.player.id);
          }
        return contact;
      });
    }

    const columns = [
    {
        key: "name",
        visible: true,
        label: this.props.t("Player"),
        pinned: "left",
        id: "displayName",
        format: (row) => {
            return (
            <span className="flex justify-between">
                <Link
                className="text-indigo-700 font-medium text-xs 2xl:text-sm pr-2"
                to={"/group/player/" + row.player.id}
                >
                <IconRound32x32 photo={row.player.photo} />
                <span className="ml-5">
                    {row.player.lastName.toUpperCase() + " " + row.player.firstName}
                </span>
                </Link>
            </span>
            );
        },
    },
    {
        key: "typeId",
        visible: true,
        pinned: "left",
        label: this.props.t("Type"),
        id: "typeId",
        format: (row) => 
        <span  className="inline-flex items-center px-3 py-0.5 rounded-full text-xs 2xl:text-sm font-medium bg-yellow-100 text-yellow-800" style={{color: "#FFFFFF", backgroundColor: generateColor(row.type.name)}}>
        {this.props.t(row.type.name)}
        </span>,
      },
      {
        key: "lastName",
        visible: true,
        pinned: "left",
        label: this.props.t("Last name"),
        id: "lastName",
        format: (row) => <div className="flex justify-between text-xs 2xl:text-sm">
            <div>{row.lastName.toUpperCase()}</div>
            <ActionButton
                key={row.id}
                updateForm="updateContact"
                deleteForm="deleteContact"
                formData={row}
                refreshData={() => this.props.callback}
            />
        </div>,
      },
      {
        key: "firstName",
        visible: true,
        pinned: "left",
        label: this.props.t("First name"),
        id: "firstName",
        format: (row) => <span className="text-xs 2xl:text-sm">{row.firstName}</span>,
      },
      {
        key: "mobile",
        visible: true,
        pinned: "left",
        label: this.props.t("Mobile"),
        id: "mobile",
        format: (row) => <span className="text-xs 2xl:text-sm">{row.mobile}</span>,
      },
      {
        key: "email",
        visible: true,
        pinned: "left",
        label: this.props.t("Email"),
        id: "email",
        format: (row) => <span className="text-xs 2xl:text-sm">{row.email}</span>,
      },
    ];
    var selectedColumns = [];
    columns.forEach((column) => {
      if (column.visible) {
        selectedColumns.push(column.key);
      }
    });
    this.setState({
      columns,
      contacts,
      types,
      players,
      selectedColumns,
      selectedTypes,
      selectedPlayers
    });
  };

  render() {
    const { t } = this.props;
    var contacts = this.props.contacts;
    var columns = this.state.columns;
    var selectedColumns = this.state.selectedColumns;
    var selectedTypes = this.state.selectedTypes;
    var selectedPlayers = this.state.selectedPlayers;
    var searchText = this.state.searchText;
    if (columns) {
      columns = columns.filter((o) =>
        selectedColumns.some((item) => item === o.key)
      );
    }
    if (contacts) {
      contacts = contacts.filter((o) =>
        selectedTypes.some((item) => item === o.type.id)
      );
    }
    if (contacts) {
        contacts = contacts.filter((o) =>
            selectedPlayers.some((item) => item === o.player.id)
        );
    }
    if (searchText.length > 2) {
      contacts = contacts.filter((o) =>
        o.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
        o.lastName.toLowerCase().includes(searchText.toLowerCase()) ||
        o.type.name.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    return (
      <>
        <FiltersBar
          components={
            <>
              <FiltersTitle
                title={contacts
                  ? contacts.length + " " + t("Contacts").toLowerCase()
                  : ""}
              />
              <FilterSeparator />
              <FiltersSearch search={<SearchInput
                value={this.state.searchText}
                onChange={(searchText) => this.setState({ searchText })}
              />} />
              <FilterSeparator />
              <FiltersDropDowns selects={[
                <SelectMultiple
                  options={this.state.types}
                  keys={this.state.selectedTypes}
                  className="mx-2"
                  onChange={(res) => this.setState({ selectedTypes: res })}
                  fixedLabel={t("Type")}
                />,
                <SelectMultiple
                  options={this.state.players}
                  keys={this.state.selectedPlayers}
                  className="mx-2"
                  onChange={(res) => this.setState({ selectedPlayers: res })}
                  fixedLabel={t("Type")}
                />
                ]} 
              />
              <FilterSeparator />
              <FilterColumns
                columns={
                  <SelectMultiple
                    options={this.state.columns}
                    keys={this.state.selectedColumns}
                    className="mx-2"
                    onChange={(res) => this.setState({ selectedColumns: res })}
                    fixedLabel={t("Active columns")}
                  />
                }
              />
              <FilterSeparator />
              <ExportToCSV columns={columns} jsonData={contacts} fileName={t("Contacts") + ".csv"} />
            </>
          }
          buttons={this.props.buttons}
        />
        <TableSimple columns={columns} data={contacts} />
      </>
    );
  }
}

export default withTranslation()(TableContacts);
