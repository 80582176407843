import React from "react";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import {
  getMatchInfos,
  getMatchsInfosByPlayer,
  loadMatchInfos,
  loadMatchsInfosByPlayer,
} from "../../utils/indexedDB/Rugby-Competition/handleCompetitionRugby";
import MatchInfosGeneric from "../competitionGeneric/MatchInfosGeneric";

class MatchInfosRugby extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      getMatchsInfosFromAPi: false,
      getMatchsInfosByPlayerFromAPi: false,
      sportStatView: "rugby",
    };
  }

  componentDidMount() {
    var id = this.props.match.params.id;
    this.handleMatchsInfos(id);
    this.handleMatchInfosByPlayer(id);
  }

  handleMatchsInfos(matchId) {
    this.setState({ getMatchsInfosFromAPi: false });
    getMatchInfos(matchId).then((match) =>
      match && !this.state.getMatchsInfosFromAPi
        ? this.setState({ matchInfos: match[0] })
        : null
    );
    if (navigator.onLine) {
      loadMatchInfos(matchId).then((match) =>
        match
          ? this.setState({ matchInfos: match, getMatchsInfosFromAPi: true })
          : null
      );
    }
  }

  handleMatchInfosByPlayer = (matchId) => {
    this.setState({ getMatchsInfosByPlayerFromAPi: false });
    getMatchsInfosByPlayer(matchId).then((infoByPlayer) =>
      infoByPlayer &&
      infoByPlayer.sort((a, b) =>
        a.position.number === b.position.number
          ? 0
          : a.position.number < b.position.number
          ? -1
          : 1
      ) &&
      !this.state.getMatchsInfosByPlayerFromAPi
        ? this.setState({ infoByPlayer })
        : null
    );
    if (navigator.onLine) {
      loadMatchsInfosByPlayer(matchId).then((infoByPlayer) =>
        infoByPlayer
          ? this.setState({ infoByPlayer, getMatchsInfosByPlayerFromAPi: true })
          : null
      );
    }
  };

  render() {
    const { t } = this.props;
    const sportType = {key : "rugby", label : t("Rugby")}
    const sportTypeUrl = "Rugby"

    return (
      <MatchInfosGeneric
        sportStatView={this.state.sportStatView}
        matchInfos={this.state.matchInfos}
        infoByPlayer={this.state.infoByPlayer}
        sportType={sportType}
        sportTypeUrl={sportTypeUrl}
      />
    );
  }
}

export default withTranslation()(withRouter(MatchInfosRugby));
