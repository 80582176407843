import React from "react";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import Page from "../../components/page/Page";
import {
  getEvent,
  loadEvent,
  getOrganizersByEvent,
  loadOrganizersByEvent,
  loadStatusByPlayerByEvent,
  getStatusByPlayerByEvent,
  patchStatusByPlayerByEvent,
} from "../../utils/indexedDB/events/handleEvents";
import moment from "moment";
import {
  loadPlayer,
  getPlayer,
  loadStaff,
  getStaff,
} from "../../utils/indexedDB/users/handleUser";
import EventForm from "../../utils/forms/events/EventForm";
import Button from "../../components/buttons/Button";
import Card from "../../components/card/Card";
import ButtonLight from "../../components/buttons/ButtonLight";
import ListIcon from "../../components/icons/ListIcon";
import DisplayData3cols from "../../components/displayData/DisplayData3cols";

class MentalSession extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      event: null,
      attendees: null,
      sessionTimes: null,
      mentalSequences: null,
    };
    this.patchStatusByPlayerByEvent = patchStatusByPlayerByEvent.bind(this);
  }

  componentDidMount() {
    var eventId = this.props.match.params.eventId;
    var playerId = this.props.match.params.playerId;
    this.handleEvent(eventId);
    this.handlePlayer(playerId);
    this.handleStatusPlayerByEvent(playerId, eventId);
    this.handleOrganizersByEvent(eventId);
  }

  handleEvent(id) {
    getEvent(id).then((event) => {
      if (event) {
        this.setState({ event });
      }
    });
    if (navigator.onLine) {
      loadEvent(id).then((event) => {
        if (event) {
          this.setState({ event });
        } else {
          this.props.history.push("/notFound");
        }
      });
    }
  }

  handlePlayer(id) {
    getPlayer(id).then((player) => {
      if (player) {
        this.setState({ player });
      }
    });
    if (navigator.onLine) {
      loadPlayer(id).then((player) => {
        if (player) {
          this.setState({ player });
        }
      });
    }
  }

  handleStatusPlayerByEvent(playerId, id) {
    if (navigator.onLine) {
      loadStatusByPlayerByEvent(playerId, id).then((status) =>
        this.setState({ status })
      );
    } else {
      getStatusByPlayerByEvent(playerId, id).then((status) =>
        this.setState({ status })
      );
    }
  }

  handleOrganizersByEvent(id) {
    var promisesOrganizers = [];
    getOrganizersByEvent(id).then((organizers) => {
      organizers.forEach((organizer) => {
        promisesOrganizers.push(
          getStaff(organizer.staffId).then((staff) => {
            organizer.staff = staff;
            organizer.displayName = staff.displayName;
            organizer.photo = staff.photo;
            return organizer;
          })
        );
      });
      Promise.all(promisesOrganizers).then((organizers) => {
        this.setState({
          organizers: organizers
            .sort()
            .filter(
              (value, index, array) => index === 0 || value !== array[index - 1]
            ),
        });
      });
    });
    if (navigator.onLine) {
      var promisesOrganizersAPI = [];
      loadOrganizersByEvent(id).then((organizers) => {
        if (organizers) {
          organizers.forEach((organizer) => {
            promisesOrganizersAPI.push(
              loadStaff(organizer.staffId).then((staff) => {
                organizer.staff = staff;
                organizer.displayName = staff.displayName;
                organizer.photo = staff.photo;
                return organizer;
              })
            );
          });
          Promise.all(promisesOrganizersAPI).then((organizers) => {
            this.setState({
              organizers: organizers
                .sort()
                .filter(
                  (value, index, array) =>
                    index === 0 || value !== array[index - 1]
                )
                .sort((a, b) =>
                  a.staff && b.staff
                    ? a.staff.lastName === b.staff.lastName
                      ? 0
                      : a.staff.lastName > b.staff.lastName
                      ? 1
                      : -1
                    : 0
                ),
            });
          });
        }
      });
    }
  }

  render() {
    const { t } = this.props;
    var staffs = [];
    this.state.organizers &&
      this.state.organizers.map((organizer) => staffs.push(organizer.staff));
    return (
      <Page
        pageTitle={
          this.state.event ? this.state.event.name : t("Mental Session")
        }
        actions={{
          other: [
            <EventForm
              key="maininfo-form"
              step="information"
              component={(show, text) => (
                <ButtonLight onClick={show}>{t("Edit")}</ButtonLight>
              )}
              method="PATCH"
              callback={() => this.handleEvent(this.props.match.params.eventId)}
              formData={this.state.event}
            />,
            <EventForm
              key="maininvi-form"
              step="invitation"
              component={(show, text) => (
                <ButtonLight onClick={show}>{t("Edit invitation")}</ButtonLight>
              )}
              method="PATCH"
              callback={() => this.handleOrganizersByEvent(this.props.match.params.eventId)}
              formData={this.state.event}
            />,
            <EventForm
              title={t("Delete")}
              component={(show, text) => (
                <Button onClick={show} disabled={!navigator.onLine}>
                  {text}
                </Button>
              )}
              method="DELETE"
              callback={() => this.props.history.goBack()}
              formData={this.state.event}
            />,
          ],
        }}
        backPage="/training/mental"
        tab={[
          {
            title: t("Summary"),
            visible: true,
            data: this.state.event,
            component: (data) => {
              return (
                <>
                  <div>
                    <div className="flex w-full">
                      <div className="w-full px-6 pt-4 py-2">
                        <Card>
                          <DisplayData3cols
                            data={[
                              { key: t("Title"), value: data ? data.name : "" },
                              {
                                key: t("Date"),
                                value: data
                                  ? moment(data.startDate).calendar()
                                  : "",
                              },
                              {
                                key: t("Category"),
                                value: data ? data.categoryId : "",
                              },
                              {
                                key: t("Location"),
                                value: data ? data.location : "",
                              },
                              {
                                key: t("Staffs"),
                                value: staffs ? (
                                  <ListIcon users={staffs} length={3} />
                                ) : (
                                  ""
                                ),
                              },
                              {
                                key: t("Description"),
                                value: data ? data.description : "",
                              },
                            ]}
                          />
                        </Card>
                      </div>
                    </div>
                  </div>
                </>
              );
            },
          },
        ]}
      />
    );
  }
}

export default withTranslation()(withRouter(MentalSession));
