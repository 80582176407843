import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import Card from "../components/card/Card";
import TableSimple from "../components/table/TableSimple";
import ExportToCSV from "../components/export/ExportToCSV";
import FilterSeparator from "../components/filters/FilterSeparator";
import { SelectMultiple } from "../components/forms";
import FilterColumns from "../components/filters/FilterColumns";
import FiltersDropDowns from "../components/filters/FiltersDropDowns";
import SearchInput from "../components/forms/input/SearchInput";
import FiltersSearch from "../components/filters/FiltersSearch";
import FiltersTitle from "../components/filters/FiltersTitle";
import FiltersBar from "../components/filters/FiltersBar";
import moment from "moment";
import IconRound32x32 from "../components/icons/IconRound32x32";
import { Link } from "react-router-dom";

class TableMatchsByPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      selectedColumns: [],
      selectedGroups: [],
      searchText: "",
      sort_field: "name",
      sort_direction: -1,
      infoByPlayer: [],
      matchs: [],
      getMatchsByPlayerFromAPi: false,
    };
  }

  componentDidMount() {
    this.prepareData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.prepareData();
    }
  }

  prepareData = (firstTime) => {
    var matchs = this.props.matchs;
    var groups = [];

    const columns = [
      {
        key: "date",
        visible: true,
        label: this.props.t("Date"),
        width: 60,
        pinned: "left",
        format: (row) => (
          <span className=" text-xs 2xl:text-sm">
            {moment(row.startDate).format("DD-MM-YYYY")}
          </span>
        ),
      },
      {
        key: "homeTeam",
        label: "Home Team",

        visible: true,
        format: (row) => (
          <>
            {row && row.localization === "Home" ? (
              <>
                <IconRound32x32 photo={row && row.club && row.club.logo} />
                <span>{row && row.club && row.club.name}</span>
              </>
            ) : (
              <>
                <IconRound32x32
                  photo={row && row.opponent && row.opponent.logo}
                />
                <span>{row && row.opponent && row.opponent.name}</span>
              </>
            )}
          </>
        ),
      },
      {
        key: "score",
        label: "Score",
        visible: true,
        filter: {
          name: "SelectColumnFilter",
          type: "includes",
        },
        format: (row) => (
          <>
            <Link
              to={{
                pathname: `/competitionRugby/matchInfos/${row.eventId}`,
                state: {
                  matchInfo: row,
                },
              }}
            >
              {this.getResultByColor(row)}
            </Link>
          </>
        ),
      },
      {
        key: "awayTeam",
        label: "Away Team",
        visible: true,
        format: (row) => (
          <>
            <>
              {row && row.localization === "Away" ? (
                <>
                  <IconRound32x32 photo={row && row.club && row.club.logo} />
                  <span>{row && row.club && row.club.name}</span>
                </>
              ) : (
                <>
                  <IconRound32x32
                    photo={row && row.opponent && row.opponent.logo}
                  />
                  <span>{row && row.opponent && row.opponent.name}</span>
                </>
              )}
            </>
          </>
        ),
      },
      {
        key: "category",
        label: "Category",
        visible: true,
        format: (row) => (
          <>
            {row && row.category ? (
              <span className="text-xs">{row && row.category}</span>
            ) : (
              <span>{row && row.tour && row.tour.id}</span>
            )}
          </>
        ),
      },
    ];

    // after columns added Add local Storage
    var selectedColumns = localStorage.getItem(
      "TableRugbyMatchsByPlayer_competitions"
    );
    if (selectedColumns === null || selectedColumns.length === 0) {
      selectedColumns = [];
      columns.forEach((column) => {
        selectedColumns.push(column.key);
        //  column.visible = true
      });
    } else selectedColumns = selectedColumns.split("|");
    columns.forEach((column) => {
      column.visible = selectedColumns.indexOf(column.key) < 0 ? false : true;
    });

    // selected columns
    if (firstTime) {
      selectedColumns = [];
      columns.forEach((column) => {
        if (column.visible) {
          selectedColumns.push(column.key);
        }
      });
      selectedColumns = selectedColumns.slice(0, 6);
      this.setState({ selectedColumns });
    }

    this.setState({
      columns,
      matchs,
      groups,
      selectedColumns,
      //   selectedGroups,
    });
  };

  // change columns
  onChangeSelectedColumns = (res) => {
    this.setState({ selectedColumns: res }, () => {
      // determiner ce qui est selectionne
      let select = [];
      this.state.columns.forEach((a) => {
        if (this.state.selectedColumns.indexOf(a.key) > -1) select.push(a.key);
      });
      // save selected elements to localstorage
      localStorage.setItem(
        "TableRugbyMatchsByPlayer_competitions",
        select.join("|")
      );
    });
  };

  getResultColorClass(result) {
    switch (result) {
      case "Win":
        return "text-green-600";
      case "Loss":
        return "text-red-600";
      case "Draw":
        return "text-yellow-600";
      default:
        return "";
    }
  }

  getScoreByResult(result, scoreDom, scoreExt) {
    switch (result) {
      case "Win":
        return `${scoreDom} - ${scoreExt}`;
      case "Loss":
        return `${scoreExt} - ${scoreDom}`;
      case "Draw":
        return `${scoreExt} - ${scoreDom}`;
      default:
        return " - ";
    }
  }

  getResultByColor(row) {
    return (
      <span
        className={`text-xs 2xl:text-sm ${this.getResultColorClass(
          row.result
        )}`}
      >
        {this.getScoreByResult(
          row.result,
          row.scoreFullTimeOpponentTeamDom,
          row.scoreFullTimeOpponentTeamExt
        )}
      </span>
    );
  }

  render() {
    const { t } = this.props;
    var matchs = this.state.matchs;
    var columns = this.state.columns;
    var selectedColumns = this.state.selectedColumns;
    // var selectedGroups = this.state.selectedGroups;
    var searchText = this.state.searchText;
    if (columns) {
      columns = columns.filter((o) =>
        selectedColumns.some((item) => item === o.key)
      );
    }

    if (searchText.length > 2) {
      matchs = matchs.filter(
        (o) =>
          o.name.toLowerCase().includes(searchText.toLowerCase()) ||
          (o.type &&
            o.type.name.toLowerCase().includes(searchText.toLowerCase()))
      );
    }

    return (
      <>
        <Card>
          <FiltersBar
            components={
              <>
                <FiltersTitle
                  title={
                    matchs && matchs.length > 1
                      ? matchs.length + " " + t("Matchs").toLowerCase()
                      : matchs && matchs.length + " " + t("Match").toLowerCase()
                  }
                />
                <FilterSeparator />
                <FiltersSearch
                  search={
                    <SearchInput
                      value={this.state.searchText}
                      onChange={(searchText) => this.setState({ searchText })}
                    />
                  }
                />
                <FilterSeparator />
                <FiltersDropDowns
                  selects={[
                    <SelectMultiple
                      options={this.state.groups}
                      keys={this.state.selectedGroups}
                      className="mx-2"
                      fixedLabel={t("Group")}
                    />,
                  ]}
                />
                <FilterSeparator />
                <FilterColumns
                  columns={
                    <SelectMultiple
                      options={this.state.columns}
                      keys={this.state.selectedColumns}
                      className="mx-2"
                      fixedLabel={t("Active columns")}
                      onChange={this.onChangeSelectedColumns}
                    />
                  }
                />
                <FilterSeparator />
                <ExportToCSV
                  columns={columns}
                  jsonData={matchs}
                  fileName={t("CompetitionsRugby") + ".csv"}
                />
              </>
            }
            buttons={this.props.buttons}
          />
          <TableSimple columns={columns} data={matchs} />
        </Card>
      </>
    );
  }
}

export default withTranslation()(withRouter(TableMatchsByPlayer));
