import React from "react";

class DrawerBody extends React.Component {
    render() {
        return (
            <div className="mt-3 2xl:mt-6 relative px-3 2xl:px-6">
                <div className="inset-0 px-4 sm:px-6">
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default DrawerBody;