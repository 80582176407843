import React from "react";
import { withTranslation } from "react-i18next";
import ButtonLight from "../buttons/ButtonLight";

// TAB
// * onChange => (value)
// tab = {key, label}
// length
class TableSimple extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 30,
      columns: this.props.columns,
      data: this.props.data,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({ columns: this.props.columns, data: this.props.data });
    }
  }

  render() {
    var columns = this.props.columns;
    var data = this.state.data;
    return (
      <div className="flex flex-col ">
        <div className="-my-0 overflow-x-auto sm:-mx-6 lg:-mx-0 h-full">
          <div className="py-0 align-middle inline-block min-w-full sm:px-6 lg:px-0">
            <div className="overflow-hidden sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200 mb-4 text-xs 2xl:text-sm">
                <thead className="bg-gray-50">
                  <tr>
                    {columns &&
                      columns.map((column, columnKey) =>
                        column.underlineColor ? (
                          <th
                            key={columnKey}
                            scope="col"
                            className=" text-left text-gray-500 uppercase"
                          >
                            <span
                              className="inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800"
                              style={{
                                color: "#FFFFFF",
                                backgroundColor: column.underlineColor,
                              }}
                            >
                              {column.formatHeader
                                ? column.formatHeader(column.label)
                                : column.label}
                            </span>
                          </th>
                        ) : (
                          <th
                            key={columnKey}
                            scope="col"
                            className="px-6 py-3 2xl:py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            {column.formatHeader
                              ? column.formatHeader(column.label)
                              : column.label}
                          </th>
                        )
                      )}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {data &&
                    data.slice(0, this.state.count).map((item, cKey) =>
                        <tr key={cKey}>
                          {columns &&
                            columns.map((column, vKey) => (
                              <td key={vKey} className="px-6 py-3 2xl:py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                {column.format ? column.format(item) : item[column.id]}
                              </td>
                            ))}
                        </tr>
                    )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {data && data.length > this.state.count ? (
          <div className="my-2 2xl:my-6 mb-6 flex flex-wrap justify-center">
            <ButtonLight
              onClick={() => this.setState({ count: this.state.count + 30 })}
            >
              {this.props.t("Load more")}
            </ButtonLight>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default withTranslation()(TableSimple);
