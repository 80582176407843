import React from "react";
import { withTranslation } from "react-i18next";


class Uploading extends React.Component {
  render() {
    return (
      <div className="h-full flex justify-between flex-col">
        <div className="h-full flex items-center justify-center">
          {this.props.percentage ?
            <div className="h-3 relative w-96 max-w-xl rounded-full">
              <div className="w-full h-full bg-gray-200 absolute"></div>
              <div className="h-full bg-green-500 absolute" style={{ width: this.props.percentage + "%" }}></div>
            </div>
            : <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>}
          <div className="absolute mt-12">{this.props.t("Uploading")}</div>
        </div>
      </div >
    );
  }
}

export default withTranslation()(Uploading);
