import React from "react";

class IconRound64x64 extends React.Component {
    render() {
        if (this.props.tooltip) {
            return <img alt="img" src={this.props.photo} className="icon icon-round icon-64x64" data-toggle="tooltip"
                data-placement="auto bottom"
                title={this.props.tooltip} />
        }
        else {
            return (<img alt="img" src={this.props.photo} className="icon icon-round icon-64x64" />)
        }

    }
}

export default IconRound64x64;