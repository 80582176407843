import React from "react";
import { withTranslation } from "react-i18next";

class FiltersDropDowns extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  render() {
    return (
        this.state.visible ?
            <div className="flex">
                <button onClick={() => this.setState({visible: !this.state.visible})} className="w-4 2xl:w-5 mr-3" style={{color: "#4B5563"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" aria-labelledby="title" aria-describedby="desc" role="img" xlink="http://www.w3.org/1999/xlink">
                        <path data-name="layer1" fill="#202020" d="M2.5 2h59l-25 32v20l-8 8V34L2.5 2z"></path>
                    </svg>
                </button>
                {this.props.selects ? this.props.selects.map(select => select) : ""}
                <button onClick={() => this.setState({visible: !this.state.visible})} className="w-4 2xl:w-5 mr-3" style={{color: "#4B5563"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" aria-labelledby="title"
                        aria-describedby="desc" role="img" xlink="http://www.w3.org/1999/xlink">
                        <path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10" strokeWidth="2" d="M41.999 20.002l-22 22m22 0L20 20" strokeLinejoin="round" strokeLinecap="round"></path>
                    </svg>
                </button>
            </div>
            : 
            <div className="flex groupTourStep3">
                <button onClick={() => this.setState({visible: !this.state.visible})}  className="w-4 2xl:w-5 text-gray-600" style={{color: "#4B5563"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" aria-labelledby="title" aria-describedby="desc" role="img" xlink="http://www.w3.org/1999/xlink">
                        <path data-name="layer1" fill="#202020" d="M2.5 2h59l-25 32v20l-8 8V34L2.5 2z"></path>
                    </svg>
                </button>
            </div>
    );
  }
}

export default withTranslation()(FiltersDropDowns);
