import React from "react";
import ActionButton from "../components/buttons/ActionButton";
import moment from "moment";
import { Link } from "react-router-dom";
import IconRound32x32 from "../components/icons/IconRound32x32";
import { withTranslation } from "react-i18next";
import { SelectMultiple } from "../components/forms";
import TableSimple from "../components/table/TableSimple";
import Flag from "../components/icons/Flag";
import FiltersDropDowns from "../components/filters/FiltersDropDowns";
import FilterColumns from "../components/filters/FilterColumns";
import ExportToCSV from "../components/export/ExportToCSV";
import SearchInput from "../components/forms/input/SearchInput";
import FiltersSearch from "../components/filters/FiltersSearch";
import generateColor from "../utils/generateColor";
import FilterSeparator from "../components/filters/FilterSeparator";
import FiltersTitle from "../components/filters/FiltersTitle";
import FiltersBar from "../components/filters/FiltersBar";

class TablePlayers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actions: [],
      groups: [],
      selectedGroups: [],
      years: [],
      selectedYears: [],
      genders: [],
      selectedGenders: [],
      columns: [],
      searchText: "",
    };
  }

  componentDidMount() {
    this.prepareData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.prepareData();
    }
  }

  prepareData = () => {
    var players = this.props.players;
    var years = [];
    var groups = [];
    var genders = [
      { key: "H", label: this.props.t("Man") },
      { key: "F", label: this.props.t("Woman") },
    ];
    var selectedYears = [];
    var selectedGroups = [];

    // Selected gender 
    var selectedGenders = localStorage.getItem("TableRankingPlayers_selectedGenders")
    if (!selectedGenders) selectedGenders = ["H", "F"] 
      else selectedGenders = selectedGenders.split("|")    

    //  chercher les elements a déselectionner du localstorage
    var unselectedYears = localStorage.getItem("TableRankingPlayers_unselectedYears")
    if (unselectedYears) unselectedYears = unselectedYears.split("|")
    
    // groups
    var unselectedGroups = localStorage.getItem("TableRankingPlayers_unselectedGroups")
    if (unselectedGroups) unselectedGroups = unselectedGroups.split("|")    
    
    if (players) {
      players = players.map((player) => {
        player.year = moment(player.dob).year();
        if (years && !years.find((o) => o.key === player.year)) {
          years.push({ key: player.year, label: player.year })
           // only add to selected if not unselected
           if (
            !unselectedYears ||
            unselectedYears.indexOf(player.year.toString()) < 0
          )
          selectedYears.push(player.year);
        }
        if (groups && !groups.find((o) => o.key === player.group1.id)) {
          groups.push({ key: player.group1.id, label: player.group1.name })
           // only add to selected if not unselected
           if (
            !unselectedGroups ||
            unselectedGroups.indexOf(player.group1.id.toString()) < 0
          )
          selectedGroups.push(player.group1.id);
        }
        return player;
      });
    }

    years = years.sort((a, b) => b.key - a.key);

    var columns = [
      {
        key: "lastName",
        visible: true,
        label: this.props.t("Player"),
        pinned: "left",
        id: "displayName",
        format: (row) => {
          return (
            <span className="flex justify-between">
              <Link
                className="text-indigo-700 font-medium pr-2  text-xs 2xl:text-sm"
                to={"/group/player/" + row.id}
              >
                <IconRound32x32 photo={row.photo} />
                <span className="ml-5">
                  {row.lastName.toUpperCase() + " " + row.firstName}
                </span>
              </Link>
              <ActionButton
                key={row.id}
                updateForm="updatePlayer"
                deleteForm="deletePlayer"
                formData={row}
                refreshData={this.props.callback}
              />
            </span>
          );
        },
      },
      {
        key: "year",
        visible: true,
        label: this.props.t("Year"),
        width: 100,
        id: "year",
        format: (row) => (
          <span className="font-weight-bold text-muted text-xs 2xl:text-sm">{row.year}</span>
        ),
        filter: {
          name: "NumberBetweenFilter",
        },
      },
      {
        key: "nationalityId",
        visible: true,
        label: this.props.t("Nationality"),
        width: 60,
        filter: {
          name: "SelectColumnFilter",
          type: "includes",
        },
        format: (row) =>  <Flag className="w-4 2xl:w-6" nationalityId={row.nationalityId} />,
      },
      {
        key: "groupLabel",
        visible: true,
        label: this.props.t("Group"),
        filter: {
          name: "SelectColumnFilter",
          type: "includes",
        },
        width: 150,
        format: (row) => (
          <span className="inline-flex items-center px-3 py-0.5 rounded-full text-xs 2xl:text-sm font-medium bg-indigo-100 text-indigo-800" style={{color: "#FFFFFF", backgroundColor: generateColor(row.group1.name)}}>
            {row.group1 ? row.group1.name : "-"}
          </span>
        ),
      },
      {
        key: "mobile",
        visible: true,
        label: this.props.t("Mobile"),
        canFilter: false,
        format: (row) => (
          <span className="font-weight-bold text-muted text-xs 2xl:text-sm">{row.mobile}</span>
        ),
      },
      {
        key: "email",
        visible: true,
        label: this.props.t("Email"),
        format: (row) => (
          <span className="font-weight-bold text-muted text-xs 2xl:text-sm">{row.email}</span>
        ),
      }
    ];

    var selectedColumns = localStorage.getItem("TableRankingPlayers_selectedColumns")
    if (selectedColumns === null || selectedColumns.length === 0)  selectedColumns = ["lastName", "year", "nationalityId", "groupLabel", "mobile", "email" ]
    else selectedColumns = selectedColumns.split("|")
    columns.forEach((column) => {
      column.visible = selectedColumns.indexOf(column.key) < 0 ? false : true 

    });
    this.setState({
      columns,
      players,
      groups,
      years,
      genders,
      selectedColumns,
      selectedGroups,
      selectedYears,
      selectedGenders,
    });
  };

  // change genders
  onChangeSelectedGenders = (res) => {
    this.setState({ selectedGenders: res }, () => {
      // determiner ce qui n'est pas selectionner
      let unselect = []
      this.state.genders.forEach((a) => {
        if (this.state.selectedGenders.indexOf(a.key) < 0) unselect.push(a.key)
      })
      // save unselected elements to localstorage
      localStorage.setItem("TableRankingPlayers_selectedGenders", unselect.join("|"))
    })
  }

  // change years
  onChangeSelectedYears = (res) => {
    this.setState({ selectedYears: res }, () => {
      // determiner ce qui n'est pas selectionner
      let unselect = []
      this.state.years.forEach((a) => {
        if (this.state.selectedYears.indexOf(a.key) < 0) unselect.push(a.key)
      })
      // save unselected elements to localstorage
      localStorage.setItem("TableRankingPlayers_unselectedYears", unselect.join("|"))
    })
  }

  // change groups
  onChangeSelectedGroups = (res) => {
      this.setState({ selectedGroups: res }, () => {
        // determiner ce qui est selectionne
        let select = []
        this.state.groups.forEach((a) => {
          if (this.state.selectedGroups.indexOf(a.key) < 0 ) select.push(a.key)
        })
        // save selected elements to localstorage
        localStorage.setItem("TableRankingPlayers_unselectedGroups", select.join("|"))
      })
    }

  // change columns
  onChangeSelectedColumns = (res) => {
    this.setState({ selectedColumns: res }, () => {
      // determiner ce qui est selectionne
      let select = []
      this.state.columns.forEach((a) => {
        if (this.state.selectedColumns.indexOf(a.key) > -1 ) select.push(a.key)
      })
      // save selected elements to localstorage
      localStorage.setItem("TableRankingPlayers_selectedColumns", select.join("|"))
    })
  }    

  render() {
    const { t } = this.props;
    var players = this.props.players;
    var columns = this.state.columns;
    var selectedColumns = this.state.selectedColumns;
    var selectedYears = this.state.selectedYears;
    var selectedGroups = this.state.selectedGroups;
    var selectedGenders = this.state.selectedGenders;
    var searchText = this.state.searchText;
    if (columns) {
      columns = columns.filter((o) =>
        selectedColumns.some((item) => item === o.key)
      );
    }
    if (players) {
      players = players.filter((o) =>
        selectedYears.some((item) => item === o.year)
      );
    }
    if (players) {
      players = players.filter((o) =>
        selectedGroups.some((item) => item === o.group1.id)
      );
    }
    if (players) {
      players = players.filter((o) =>
        selectedGenders.some((item) => item === o.genderId)
      );
    }
    if (searchText.length > 2) {
      players = players.filter(
        (o) =>
          o.lastName.toLowerCase().includes(searchText.toLowerCase()) ||
          o.firstName.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    return (
      <>
        <FiltersBar 
          components={
            <>
              <FiltersTitle title={players ? players.length + " " + t("Players").toLowerCase() : ""} />
              <FilterSeparator />
              <FiltersSearch search={<SearchInput
                value={this.state.searchText}
                onChange={(searchText) => this.setState({ searchText })}
              />} />
              <FilterSeparator />
              <FiltersDropDowns selects={[
                <SelectMultiple
                  options={this.state.groups}
                  keys={this.state.selectedGroups}
                  className="mx-2"
                  onChange={this.onChangeSelectedGroups}
                  fixedLabel={t("Group")}
                />,
                <SelectMultiple
                  options={this.state.years}
                  keys={this.state.selectedYears}
                  className="mx-2"
                  onChange={this.onChangeSelectedYears}
                  fixedLabel={t("Year")}
                />,
                <SelectMultiple
                  options={this.state.genders}
                  keys={this.state.selectedGenders}
                  className="mx-2"
                  onChange={this.onChangeSelectedGenders}
                  fixedLabel={t("Gender")}
                />
                ]} 
              />
              <FilterSeparator />
              <FilterColumns 
                columns={
                  <SelectMultiple
                    options={this.state.columns}
                    keys={this.state.selectedColumns}
                    className="mx-2"
                    onChange={this.onChangeSelectedColumns}
                    fixedLabel={t("Active columns")}
                  />
                }
              />
              <FilterSeparator />
              <ExportToCSV columns={columns} jsonData={players} fileName={t("Players") + ".csv"} />
            </>
          }
          buttons={this.props.buttons}
        />
        <TableSimple columns={columns} data={players} />
      </>
    );
  }
}

export default withTranslation()(TablePlayers);
