function range(start, edge, step) {
    if (arguments.length === 1) {
        edge = start;
        start = 0;
    }

    edge = edge || 0;
    step = step || 1;

    for (var ret = []; (edge - start) * step > 0; start += step) {
        ret.push(start);
    }
    return ret;
}

function calculateAverage(array) {
    let total = 0;
    array.forEach((num) => {
      total = num + total;
    });
    return total / array.length;
  }


export {
    range,
    calculateAverage,
}

