import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

class Carousel extends React.Component {
  render() {
    const items = this.props.items; // Value of the item (mandatory)
    return (
      <div className="px-1 py-5 sm:p-6 h-48 flex w-full justify-center">
        <div>
          <button
            onClick={() => this.Carousel.slidePrev()}
            className="w-8 mt-12"
          >
            <svg
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 477.175 477.175"
              style={{
                enableBackground: "new 0 0 477.175 477.175",
              }}
              space="preserve"
            >
              <g>
                <path
                  d="M145.188,238.575l215.5-215.5c5.3-5.3,5.3-13.8,0-19.1s-13.8-5.3-19.1,0l-225.1,225.1c-5.3,5.3-5.3,13.8,0,19.1l225.1,225
		c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4c5.3-5.3,5.3-13.8,0-19.1L145.188,238.575z"
                />
              </g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
            </svg>
          </button>
        </div>
        <div className="w-11/12">
          <AliceCarousel
            mouseTracking
            items={items}
            mouseTrackingEnabled
            dotsDisabled={true}
            buttonsDisabled={true}
            infinite={false}
            ref={(el) => (this.Carousel = el)}
            responsive={{
              0: { items: 1 },
              640: { items: 2 },
              1024: { items: 2 },
              1280: { items: 3 },
              2400: { items: 4 },
            }}
          />
        </div>
        <div>
          <button
            onClick={() => this.Carousel.slideNext()}
            className="w-8 mt-12"
          >
            <svg
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 477.175 477.175"
              style={{
                enableBackground: "new 0 0 477.175 477.175",
              }}
              space="preserve"
            >
              <g>
                <path
                  d="M360.731,229.075l-225.1-225.1c-5.3-5.3-13.8-5.3-19.1,0s-5.3,13.8,0,19.1l215.5,215.5l-215.5,215.5
		c-5.3,5.3-5.3,13.8,0,19.1c2.6,2.6,6.1,4,9.5,4c3.4,0,6.9-1.3,9.5-4l225.1-225.1C365.931,242.875,365.931,234.275,360.731,229.075z
		"
                />
              </g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
              <g></g>
            </svg>
          </button>
        </div>
      </div>
    );
  }
}

export default Carousel;
