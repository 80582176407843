import React from "react";
import { withTranslation } from "react-i18next";
import Loading from "../../../../components/loaders/Loading";
import { getTypes, loadTypes } from "../../../indexedDB/events/handleEvents";
import * as Drawer from "../../../../components/drawer";

import getDataFiltered from "../../../../utils/filteredData";
import Input from "../../../../components/forms/input/Input";


class ChooseType extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            types: [],
            typesFiltered: [],
            formData: null,
            loading: true
        };
    }

    componentDidMount() {
        if (
            this.props.method === "POST" &&
            !(this.props.formData && this.props.formData.typeId)
        ) {
            getTypes().then((types) => {
                if (types !== []) {
                    types = types.sort((a, b) => a.order - b.order);
                    this.setState({ types, typesFiltered: types, loading: false })
                }
            }
            );

            loadTypes().then((types) => {
                if (types) {
                    types = types.sort((a, b) => a.order - b.order);
                    this.setState({ types, typesFiltered: types, loading: false });
                }
            });
        } else {
            if (this.props.formData && this.props.formData.typeId) {
                this.props.nextStep(this.props.formData);
            }
        }
    }

    render() {
        const { t } = this.props;
        return (
            <>
                <Drawer.Header hide={this.props.hide}>
                    {t("Choose event type")}
                </Drawer.Header>
                <Drawer.Body>
                    <>
                        {this.state.loading ? <Loading /> :
                            this.state.errors && this.state.errors.errors ? (
                                <div className="alert alert-danger">
                                    {t("Error")}
                                    {this.state.errors.inner.map((error, key) => {
                                        var q = this.state.questions.find(
                                            (x) => x.label === error.path
                                        );
                                        if (q) {
                                            return (
                                                <div key={key}>
                                                    {t(q.title)} : {error.message}
                                                </div>
                                            );
                                        } else {
                                            return <div key={key}>{error.message}</div>;
                                        }
                                    })}
                                </div>
                            ) : this.state.errors ? <div className="alert alert-danger">
                                {t("Error")}
                                {this.state.errors.map((error, key) => {
                                    return <div key={key}>{error}</div>;
                                })}
                            </div> : (
                                        ""
                                    )}
                        <>{this.state.typesFiltered ? <>
                            <Input
                                id="search"
                                className="w-full"
                                autoFocus
                                onChange={(value) => {
                                    this.setState({ typesFiltered: undefined });
                                    var typesFiltered = getDataFiltered(this.state.types, value);
                                    typesFiltered = typesFiltered && typesFiltered.length ? typesFiltered.sort((a, b) => a.order - b.order) : undefined;
                                    this.setState({ typesFiltered });
                                }}
                            />
                            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 mt-3">
                                {this.state.typesFiltered.length ? this.state.typesFiltered.map((type, key) => {
                                    if (type.name !== "cycle" && type.name !== "week") {
                                        return (
                                            <div
                                                key={key}
                                                onClick={() => {
                                                    var formData = Object.assign({}, this.props.originalFormData);
                                                    formData.typeId = type.id;
                                                    this.props.nextStep(formData);
                                                }}
                                                className="relative rounded-lg border border-gray-300 text-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400"
                                                style={{ minHeight: "50px", backgroundColor: type.color }}
                                            >
                                                <div className="flex-shrink-0">
                                                    <span className="mr-3 text-inverse-success font-size-h6">
                                                        <span className={type.icon && type.icon.startsWith("#") ? "icon-icon-calendar" : "icon-" + type.icon}></span>
                                                    </span>
                                                </div>
                                                <div className="flex-1 min-w-0">
                                                    <span className="text-sm font-medium">
                                                        {type.name}
                                                    </span>
                                                </div>
                                            </div>
                                        )
                                    } else {
                                        return null;
                                    };
                                }) : null}
                            </div>
                        </> : null}
                        </>
                    </>
                </Drawer.Body>
            </>
        );
    }
}

export default withTranslation()(ChooseType);
