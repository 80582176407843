import moment from "moment";
import React from "react";
// import Modal from "../../components/modal/Modal";
import * as Drawer from "../../components/drawer";
//import "../../../node_modules/video-react/dist/video-react.css";
import Card from "./Card";
import ListIcon from "../../components/icons/ListIcon";
import { getStaff } from "../../utils/indexedDB/users/handleUser";
import IconRound64x64 from "../icons/IconRound64x64";

class CardAnnounce extends React.Component {
  // Nécessite de recevoir en props les champs suivants :
  // key={annonce.id} l'id de l'annonce
  // annonce={annonce} l'annonce
  // players={this.state.players} la listes de tous les joueurs géré
  // t={t} i18n
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    getStaff(this.props.annonce.authorId).then((author) => {
      this.setState({ author });
    });
  }

  getUsersPhotoFormat(audiences) {
    var users = [];
    if (audiences)
      audiences.forEach((p) => {
        if (
          this.props.players &&
          this.props.players.find((x) => x.id === p.playerId)
        ) {
          users.push(this.props.players.find((x) => x.id === p.playerId));
        }
      });
    return users;
  }

  render() {
    const { t } = this.props;
    return (
      <Drawer.Layout
        component={(show) => (
          <div onClick={show}>
            <Card>
              <div>{this.props.annonce.title}</div>
              <div>{this.props.annonce.content}</div>
              <div>
                {this.state.author
                  ? this.state.author.displayName
                  : t("Author not loaded")}
              </div>
            </Card>
          </div>
        )}
      >
        {(hide) => (
          <>
            <Drawer.Header hide={hide}>{t("Announce")}</Drawer.Header>
            <Drawer.Body>
              {this.state.author ? (
                <IconRound64x64
                  key={1}
                  photo={this.state.author.photo}
                  tooltip={
                    this.state.author.name
                      ? this.state.author.name
                      : this.state.author.displayName
                  }
                />
              ) : (
                ""
              )}
              <div>{this.props.annonce.title}</div>
              <div>.</div>
              <div>{this.props.annonce.content}</div>
              <div>.</div>
              <div>
                {this.state.author
                  ? this.state.author.displayName
                  : this.props.t("Author not loaded")}
              </div>
              <div>
                {this.props.annonce.lastModificationDate
                  ? this.props.annonce.lastModificationDate ===
                    this.props.annonce.date
                    ? moment(this.props.annonce.lastModificationDate).calendar()
                    : moment(
                        this.props.annonce.lastModificationDate
                      ).calendar() +
                      "* " +
                      this.props.t("Edited")
                  : moment(this.props.annonce.date).calendar()}
              </div>
              <ListIcon
                users={this.getUsersPhotoFormat(this.props.annonce.audiences)}
                length={3}
              />
            </Drawer.Body>
          </>
        )}
      </Drawer.Layout>
    );
  }
}

export default CardAnnounce;
