import React from "react";
import {
  loadNationalCompetition, loadInternationalCompetition, loadTournamentById
} from "../../../utils/indexedDB/Tennis-Competition/handleCompetitionTennis";
import { withTranslation } from "react-i18next";
import Input from "../input/Input";
import getDataFiltered from "../../../utils/filteredData";
import Select from "../select/Select";
import moment from "moment"
import GenerateForm from "../../../utils/forms/generateForm";

class ChooseTournament extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tournamentsNational: [],
      tournamentsNationalFiltered: [],
      tournamentsInternational: [],
      tournamentsInternationalFiltered: [],
      value: this.props.value,
      open: false,
      type: null,
      year: null,
      month: null,
      searchingTournament: false
    };

    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
    this.onTournamentSelected = this.onTournamentSelected.bind(this);
    this.loadAllTournamentsPerType = this.loadAllTournamentsPerType.bind(this);
  }

  componentDidMount() {
    var year = moment().year();
    var month = moment().month() + 1;
    if (this.props.formData) {
      if (this.props.formData.date) {
        year = moment(this.props.formData.date).year();
        month = moment(this.props.formData.date).month() + 1;
      }
    }
    this.setState({ year, month });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.formData !== this.props.formData || (this.props.formData && this.props.formData.date && (moment(this.props.formData.date).year() !== this.state.year || moment(this.props.formData.date).month() + 1 !== this.state.month))) {
      var year = moment().year();
      var month = moment().month() + 1;
      if (this.props.formData) {
        if (this.props.formData.date) {
          year = moment(this.props.formData.date).year();
          month = moment(this.props.formData.date).month() + 1;
        }
      }
      this.setState({ year, month });
      if (this.state.type) {
        this.loadAllTournamentsPerType(this.state.type, year, month);
      }
    }

    if (prevProps.value !== this.props.value) {
      var value = this.state.tournamentsNational ? this.state.tournamentsNational.find(t => t.id === this.props.value) : null;
      if (!value) {
        value = this.state.tournamentsInternational ? this.state.tournamentsInternational.find(t => t.id === this.props.value) : null;
        if (!value) {
          loadTournamentById(this.props.value).then(value => this.setState({ value }))
        } else {
          this.setState({ value })
        }
      } else {
        this.setState({ value })
      }

    }
  }

  loadAllTournamentsPerType(type, year, month) {
    this.setState({ searchingTournament: true });
    switch (type) {
      case "national":
        loadNationalCompetition(month, year).then(tournaments => {
          if (month === this.state.month && year === this.state.year) {
            this.setState({ searchingTournament: false, tournamentsNational: tournaments, tournamentsNationalFiltered: tournaments });
          }
        });
        break;
      case "international":
        loadInternationalCompetition(month, year).then(tournaments => {
          if (month === this.state.month && year === this.state.year) {
            this.setState({ searchingTournament: false, tournamentsInternational: tournaments, tournamentsInternationalFiltered: tournaments });
          }
        });
        break;
      default:
        this.setState({ searchingTournament: false });
    }
  }

  show(event) {
    event.preventDefault();
    this.setState({ open: true }, () => {
      document.addEventListener("click", this.hide);
      if (document.getElementById("searchTournament")) {
        document.getElementById("searchTournament").focus();
      }
    });
  }

  hide(event) {
    this.setState({ open: false }, () => {
      document.removeEventListener("click", this.hide);
    });
  }

  onTournamentSelected(tournament) {
    this.props.onChange(tournament);
  }

  render() {
    var { t } = this.props;
    return (
      <div>
        <div>
          {t("Select type of tournament")} :
        </div>
        <div>
          <Select key="selectorTypeTournament"
            id="selectorTypeTournament"
            value={
              this.state.type
            }
            onChange={(type) => {
              this.setState({ type });
              this.loadAllTournamentsPerType(type, this.state.year, this.state.month);
            }}
            options={[{ key: "national", label: t("National") }, { key: "international", label: t("International") }]}
          />
        </div>
        {this.state.type ?
          (this.state.type === "national" ?
            <>
              {this.state.searchingTournament ? <div>
                {t("Searching for tournament", { type: this.state.type, year: this.state.year, month: this.state.month })}
              </div>
                : (this.state.tournamentsNational && this.state.tournamentsNational.length ?
                  <div className="dropdown" style={{ display: "block" }}>
                    {this.state.open ? <>
                      <Input
                        id="searchTournament"
                        className="form-control"
                        autoFocus
                        onChange={(value) => {
                          this.setState({ tournamentsNationalFiltered: undefined });
                          var tournamentsNationalFiltered = this.state.tournamentsNational && this.state.tournamentsNational.length ? getDataFiltered(
                            this.state.tournamentsNational,
                            value
                          ) : [];
                          this.setState({ tournamentsNationalFiltered });
                        }}
                      />
                      <div className="dropdown-menu open" style={{ minWidth: "250px" }}>
                        {
                          this.state.tournamentsNationalFiltered
                            ? this.state.tournamentsNationalFiltered
                              .slice(0, 5)
                              .map((o, key) => (
                                <div key={key} className="dropdown-item"
                                  onClick={() => this.onTournamentSelected(o)}
                                >{o.name}</div>
                              )) : t("No results tournament", { type: this.state.type, year: this.state.year, month: this.state.month })
                        }
                      </div>
                    </>
                      :
                      <div className="dropdown">
                        <button className="btn btn-outline-secondary dropdown-toggle" onClick={this.show}>{this.state.value ? this.state.value.name : t("Choose tournament")}</button>
                      </div>
                    }
                  </div> : <div>
                    {t("No results tournament", { type: this.state.type, year: this.state.year, month: this.state.month })}
                  </div>)}
              <GenerateForm
                callback={(tournamentNational) => {
                  if (navigator.onLine && tournamentNational.id) {
                    this.props.onChange(tournamentNational);
                    var tournamentsNational = this.state.tournamentsNational ? this.state.tournamentsNational : [];
                    tournamentsNational.push(tournamentNational);
                    this.setState({ tournamentsNational, tournamentsNationalFiltered: tournamentsNational })

                  }
                }}
                additionalClass={"createButton"}
                formName={"createTennisTournamentNational"}
                component={(show, label) => <div
                  className="es2-link"
                  onClick={() => show()}
                >
                  {label}
                </div>}
              />
            </>
            :
            <>
              {this.state.searchingTournament ? <div>
                {t("Searching for tournament", { type: this.state.type, year: this.state.year, month: this.state.month })}
              </div>
                : (this.state.tournamentsInternational && this.state.tournamentsInternational.length ?
                  <div className="dropdown" style={{ display: "block" }}>
                    {this.state.open ? <>
                      <Input
                        id="searchTournament"
                        className="form-control"
                        autoFocus
                        onChange={(value) => {
                          this.setState({ tournamentsInternationalFiltered: undefined });
                          var tournamentsInternationalFiltered = this.state.tournamentsInternational && this.state.tournamentsInternational.length ? getDataFiltered(
                            this.state.tournamentsInternational,
                            value
                          ) : [];
                          this.setState({ tournamentsInternationalFiltered });
                        }}
                      />
                      <div className="dropdown-menu open" style={{ minWidth: "250px" }}>
                        {
                          this.state.tournamentsInternationalFiltered
                            ? this.state.tournamentsInternationalFiltered
                              .slice(0, 5)
                              .map((o, key) => (
                                <div key={key} className="dropdown-item"
                                  onClick={() => this.onTournamentSelected(o)}
                                >{o.name}</div>
                              )) : t("No results tournament", { type: this.state.type, year: this.state.year, month: this.state.month })
                        }
                      </div>
                    </> :
                      <div className="dropdown">
                        <button className="btn btn-outline-secondary dropdown-toggle" onClick={this.show}>{this.state.value ? this.state.value.name : t("Choose tournament")}</button>
                      </div>
                    }
                  </div> : <div>
                    {t("No results tournament", { type: this.state.type, year: this.state.year, month: this.state.month })}
                  </div>)}

              <GenerateForm
                callback={(tournamentInternational) => {
                  if (navigator.onLine && tournamentInternational.id) {
                    this.props.onChange(tournamentInternational);
                    var tournamentsInternational = this.state.tournamentsInternational ? this.state.tournamentsInternational : [];
                    tournamentsInternational.push(tournamentInternational);
                    this.setState({ tournamentsInternational, tournamentsInternationalFiltered: tournamentsInternational })

                  }
                }}
                additionalClass={"createButton"}
                formName={"createTennisTournamentInternational"}
                component={(show, label) => <div
                  className="es2-link"
                  onClick={() => show()}
                >
                  {label}
                </div>}
              />
            </>
          )
          : (this.props.value && this.state.value ?
            <div className="dropdown">
              <button className="btn btn-outline-secondary dropdown-toggle" disabled >{this.state.value.name}</button>
            </div> : t("Choose a type of tournament before select a tournament."))}

      </div>);
  }
}

export default withTranslation()(ChooseTournament);
