import Dexie from "dexie";

const db = new Dexie("Tennis-Competition");
db.version(1).stores({
    competition: "++id, kind, playerId, tId", results: "++id, kind", matches: "++id, kind", match: "++id", matchStatistics: "++id, eventId", supervision: "++id, kind", programmation: "++id, kind", programmationOption1: "++id, kind", programmationOption2: "++id, kind", ranking: "++id", rankingDates: "++id", circuits: "++id", grounds: "++id",
    conditions: "++id",
    tournamentsNationalTypes: "++id",
    nationalClassification: "++id",
    opponents: "++id",
    tours: "++id",
    rankingsATPByPlayer: "++id, playerId",
    rankingsWTAByPlayer: "++id, playerId",
    rankingsITFHByPlayer: "++id, playerId",
    rankingsITFFByPlayer: "++id, playerId",
    rankingsITFJHByPlayer: "++id, playerId",
    rankingsITFJFByPlayer: "++id, playerId",
    rankingsTennisEuropeU14HByPlayer: "++id, playerId",
    rankingsTennisEuropeU14FByPlayer: "++id, playerId"
});

db.version(2).stores({
    rankingsATPDblByPlayer: "++id, playerId",
    rankingsWTADblByPlayer: "++id, playerId",
});


export default db;
