import React from "react";
import { withTranslation } from "react-i18next";
import ExportToCSV from "../components/export/ExportToCSV";
import FilterColumns from "../components/filters/FilterColumns";
import FiltersDropDowns from "../components/filters/FiltersDropDowns";
import FiltersSearch from "../components/filters/FiltersSearch";
import SearchInput from "../components/forms/input/SearchInput";
import TableSimple from "../components/table/TableSimple";
import { SelectMultiple } from "../components/forms";
import FilterDate from "../components/filters/FilterDate";
import { Link } from "react-router-dom";
import FilterSeparator from "../components/filters/FilterSeparator";
import FiltersTitle from "../components/filters/FiltersTitle";
import FiltersBar from "../components/filters/FiltersBar";

class TablePlayerTournamentsResults extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actions: [],
      circuits: [],
      selectedCircuits: [],
      modes: [],
      selectedModes: [],
      columns: [],
      searchText: "",
      startDate: null,
      endDate: null,
    };
  }

  componentDidMount() {
    this.prepareData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.prepareData();
    }
  }

  prepareData = () => {
    var results = this.props.results
    var modes = [];
    var selectedModes = [];
    var circuits = [];
    var selectedCircuits = [];

    var unselectedCircuits = localStorage.getItem("TablePlayerTournamentsResults_unselectedCircuits")
    if (unselectedCircuits) unselectedCircuits = unselectedCircuits.split("|")
    
    // groups
    var unselectedModes = localStorage.getItem("TablePlayerTournamentsResults_unselectedModes")
    if (unselectedModes) unselectedModes = unselectedModes.split("|")       
    
    if (results) {
      results = results.map((result) => {
        if (
          circuits &&
          !circuits.find((o) => o.key === result.tournament.circuit.id)
        ) {
          circuits.push({
            key: result.tournament.circuit.id,
            label: result.tournament.circuit.name,
          })
          // only add to selected if not unselected
          if (
            !unselectedCircuits ||
            unselectedCircuits.indexOf(result.tournament.circuit.id.toString()) < 0
          )
          selectedCircuits.push(result.tournament.circuit.id);
        }
        if (modes && !modes.find((o) => o.key === result.mode)) {
          modes.push({
            key: result.mode,
            label: result.mode,
          })
           // only add to selected if not unselected
           if (
            !unselectedModes ||
            unselectedModes.indexOf(result.mode.toString()) < 0
          )
          selectedModes.push(result.mode);
        }
        return result;
      });
    }

    const columns = [
      {
        key: "date",
        visible: true,
        label: this.props.t("Dates"),
        id: "date",
        format: (row) => (
          <span className="font-weight-bold text-muted text-xs 2xl:text-sm">{row.date}</span>
        ),
        width: 200,
      },
      {
        key: "name",
        visible: true,
        label: this.props.t("Tournois"),
        id: "name",
        format: (row) => (
          <Link 
          className="text-indigo-700 font-medium text-xs 2xl:text-sm" 
          to={row.cname !== "National" ?
          "/competitionTennis/internationaldetails/" + row.tournamentId + "/a/b" :
          "/competitionTennis/nationaldetails/" + row.tournamentId + "/a/b"
        }
          >
          <span className="text-primary font-weight-bolder mb-0">
            {row.name}
          </span>
         </Link>
        ),
        width: 300,
      },
      {
        key: "cname",
        visible: true,
        label: this.props.t("Circuit"),
        id: "cname",
        filter: {
          name: "SelectColumnFilter",
          type: "includes",
        },
        width: 200,
        format: (row) =>
          row.tournament && row.tournament.circuit ? (
            <span
              className="px-3 py-0.5 rounded-full text-xs 2xl:text-sm font-medium bg-red-100 text-red-800"
              style={{
                color: row.tournament.circuit.color2,
                backgroundColor: row.tournament.circuit.color1,
              }}
            >
              {row.tournament.circuit.name +
                (row.tournament.classification
                  ? " " + row.tournament.classification
                  : "")}
            </span>
          ) : (
            "-"
          ),
      },
      {
        key: "mode",
        visible: true,
        label: this.props.t("Epreuve"),
        id: "mode",
        filter: {
          name: "SelectColumnFilter",
          type: "includes",
        },
        width: 200,
        format: (row) => <span className=" text-xs 2xl:text-sm">{row.mode}</span>,
      },
      {
        key: "tournament.ground.name",
        visible: true,
        label: this.props.t("Ground"),
        filter: {
          name: "SelectColumnFilter",
          type: "includes",
        },
        format: (row) => (
          <span className="font-weight-bold text-muted text-xs 2xl:text-sm">
            {row.tournament
              ? row.tournament.ground
                ? this.props.t(row.tournament.ground.name)
                : "N/A"
              : "N/A"}
          </span>
        ),
        width: 200,
      },
      {
        key: "tournament.conditions.name",
        visible: true,
        label: this.props.t("Conditions"),
        filter: {
          name: "SelectColumnFilter",
          type: "includes",
        },
        format: (row) => (
          <span className="font-weight-bold text-muted text-xs 2xl:text-sm">
            {row.tournament
              ? row.tournament.conditions
                ? this.props.t(row.tournament.conditions.name)
                : "N/A"
              : "N/A"}
          </span>
        ),
        width: 200,
      },
      {
        key: "tmax",
        visible: true,
        label: this.props.t("Result"),
        id: "tmax",
        filter: {
          name: "SelectColumnFilter",
          type: "includes",
        },
        format: (row) => <span className=" text-xs 2xl:text-sm">{row.isWon ? this.props.t("Winner") : this.props.t(row.tmax)}</span>,
        width: 200,
      },
    ];

    var selectedColumns = localStorage.getItem("TablePlayerTournamentsResults_selectedColumns")
    if (selectedColumns === null || selectedColumns.length === 0)  selectedColumns = ["date", "name", "cname", "mode", "tournament.ground.name", "tournament.conditions.name", "tmax" ]
    else selectedColumns = selectedColumns.split("|")
    columns.forEach((column) => {
      column.visible = selectedColumns.indexOf(column.key) < 0 ? false : true

    });
    this.setState({
      columns,
      results,
      circuits,
      modes,
      selectedColumns,
      selectedCircuits,
      selectedModes,
    });
  };

  // change circuits
  onChangeSelectedCircuits = (res) => {
    this.setState({ selectedCircuits: res }, () => {
      // determiner ce qui n'est pas selectionner
      let unselect = []
      this.state.circuits.forEach((a) => {
        if (this.state.selectedCircuits.indexOf(a.key) < 0) unselect.push(a.key)
      })
      // save unselected elements to localstorage
      localStorage.setItem("TablePlayerTournamentsResults_unselectedCircuits", unselect.join("|"))
    })
  }

  // change modes
  onChangeSelectedModes = (res) => {
      this.setState({ selectedModes: res }, () => {
        // determiner ce qui est selectionne
        let select = []
        this.state.modes.forEach((a) => {
          if (this.state.selectedModes.indexOf(a.key) < 0 ) select.push(a.key)
        })
        // save selected elements to localstorage
        localStorage.setItem("TablePlayerTournamentsResults_unselectedModes", select.join("|"))
      })
    }

  // change columns
  onChangeSelectedColumns = (res) => {
    this.setState({ selectedColumns: res }, () => {
      // determiner ce qui est selectionne
      let select = []
      this.state.columns.forEach((a) => {
        if (this.state.selectedColumns.indexOf(a.key) > -1 ) select.push(a.key)
      })
      // save selected elements to localstorage
      localStorage.setItem("TablePlayerTournamentsResults_selectedColumns", select.join("|"))
    })
  } 
  
        // date filters changed
        onChangeDates = (startDate, endDate) => {
          if (!startDate && !endDate) return;
          var obj = {};
          if (startDate) obj.startDate = startDate;
          if (endDate) obj.endDate = endDate;
          this.setState(obj, () => {
            this.prepareData()
          });
        }

  render() {
    const { t } = this.props;
    var results = this.props.results;
    var columns = this.state.columns;
    var selectedColumns = this.state.selectedColumns;
    var selectedCircuits = this.state.selectedCircuits;
    var selectedModes = this.state.selectedModes;
    var searchText = this.state.searchText;

    if (columns) {
      columns = columns.filter((o) =>
        selectedColumns.some((item) => item === o.key)
      );
    }
    

    if (results) {
        results = results.filter(d => {
        if (this.state.startDate && this.state.startDate > d.endDate) return false  
        if (this.state.endDate && this.state.endDate < d.startDate) return false
        return true
      });
    }
    
    if (results) {
      results = results.filter((o) =>
        selectedCircuits.some((item) => item === o.tournament.circuit.id)
      );
    }
    if (results) {
      results = results.filter((o) =>
        selectedModes.some((item) => item === o.mode)
      );
    }
    if (searchText.length > 2) {
      results = results.filter((o) =>
        o.name.toLowerCase().includes(searchText.toLowerCase())
      );
    }

    return (
      <>
        <FiltersBar
          components={
            <>
              <FiltersTitle title={results ? results.length + " " + t("Results").toLowerCase() : ""} />
              <FilterSeparator />
              <FiltersSearch search={<SearchInput
                value={this.state.searchText}
                onChange={(searchText) => this.setState({ searchText })}
                
              />} />
              <FilterSeparator />
              <FiltersDropDowns selects={[
                <SelectMultiple
                  options={this.state.circuits}
                  keys={this.state.selectedCircuits}
                  className="mx-2"
                  onChange={this.onChangeSelectedCircuits}
                  fixedLabel={t("Circuit")}
                />,
                <SelectMultiple
                  options={this.state.modes}
                  keys={this.state.selectedModes}
                  className="mx-2"
                  onChange={this.onChangeSelectedModes}
                  fixedLabel={t("Mode")}
                />
                ]} 
              />
              <FilterSeparator />
              <FilterColumns
                columns={
                  <SelectMultiple
                    options={this.state.columns}
                    keys={this.state.selectedColumns}
                    className="mx-2"
                    onChange={this.onChangeSelectedColumns}
                    fixedLabel={t("Active columns")}
                  />
                }
              />
              <FilterSeparator />
              <ExportToCSV columns={columns} jsonData={results} fileName={t("Results") + ".csv"} />
              <FilterSeparator />
              <FilterDate onChange={this.onChangeDates} />
            </>
          }
        />
        <TableSimple columns={columns} data={results} />
      </>
    );
  }
}

export default withTranslation()(TablePlayerTournamentsResults);
