import React from "react";
import { withTranslation } from "react-i18next";
import moment from "moment";
import ChartStackedColumns from "../components/charts/ChartStackedColumns";
import ChartDoughnut2D from "../components/charts/ChartDoughnut2D";
import { Select } from "../components/forms";
import WidgetKeyFigure from "../components/widgets/WidgetKeyFigure";
import Carousel from "../components/carousels/Carousel";
import Card from "../components/card/Card";


class ChartCycleByPlayerSessionTimesByTypes extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      view: "Total",
      week: null,
    }
  }

  render() {
    const { t } = this.props;
    const data = this.props.data;
    const startDate = this.props.startDate;
    const endDate = this.props.endDate;
    var types = this.props.types;
    var events = this.props.events
    if (types != null && data != null && data.length > 0) {
      types = types.filter(
        (o) =>
          data.filter((day) => day.results != null && day.results.filter((r) => 
            r.kind === o
          ).length > 0 ).length > 0
      );
    }
    let dataSet = [];
    var dataDoughnut = [];
    var date1 = moment(startDate).format("dddd DD MMM");
    var date2 = moment(endDate).format("dddd DD MMM");
    let dates = [];
    var totalTime = 0;
    let weeks = [];
    let weeksDataSet = [];
    const addWeek = (startOfWeek) => {
      let s = moment(startOfWeek)
      let e = moment(startOfWeek).endOf('week')
      let key = weeks.length;
      weeks.push({
        startDate: s.format(),
        endDate: e.format(),
        key,
        label: s.format("dddd DD MMM") + " - " + e.format("dddd DD MMM")
      })
    }
    var nbSessions = {}
    if (this.props.events) {
      nbSessions.Tennis = events.filter((o) => o.typeId === "Tennis").length
      nbSessions.Physique = events.filter((o) => o.typeId === "Physique").length
      nbSessions.Mental = events.filter((o) => o.typeId === "Mental").length
      nbSessions.Match = events.filter((o) => o.typeId === "Match").length
      nbSessions["Analyse vidéo"] = events.filter((o) => o.typeId === "Analyse vidéo").length
      //nbMedicalSessions = events.filter((o) => o.typeId === "Médical").length;
    }
    if (data != null && startDate != null && endDate != null && types != null) {
      var start = moment(startDate);
      var end = moment(endDate);
      let days = end.diff(start, "days") + 1;
      if (start !== start.startOf('week') && (!this.props.isWeek || this.state.view === "Hebdo")) {
        addWeek(start.startOf('week'));
      }
      for (let i = 0; i < days; i++) {
        const dt1 = moment(moment(startDate).format("YYYY-MM-DD")).add(
          i,
          "days"
        );
        dates.push({
          label: dt1.format("dddd DD"),
        });
        let startOfWeek = moment(moment(startDate).format("YYYY-MM-DD")).add(
          i,
          "days"
        ).startOf('week');
        if (dt1._d.getDate() === startOfWeek._d.getDate() && (!this.props.isWeek || this.state.view === "Hebdo")) {
          addWeek(startOfWeek);
        }
      }
      if (this.state.week === null && weeks.length > 0 && !this.props.isWeek) {
        this.setState({ week: weeks[0] });
      }


      types.forEach((type) => {
        let weeklyValues = [];
        var dailyValues = [];
        let weeklyData = 0;
        let week = 0;
        for (let i = 0; i < days; i++) {
          const dt1 = moment(moment(startDate).format("YYYY-MM-DD")).add(
            i,
            "days"
          );
          if (weeks && weeks.length > 0 && moment(weeks[week].endDate).isBefore(dt1)) {
            weeklyValues.push({
              value: weeklyData.toFixed(0),
            });
            weeklyData = 0;
            week += 1;
          }
          const dailyData = (data.find((o) => o.date === moment(dt1).format("YYYY-MM-DD")+"T00:00:00")).results.filter(
            (o) =>
              o.kind === type
          );
          var total = dailyData.reduce((total, curr) => total + curr.value, 0);
          weeklyData += total;

          dailyValues.push({
            value: total.toFixed(0),
          });
        }
        weeklyValues.push({
          value: weeklyData.toFixed(0),
        });
        var value = 0;
        if (dailyValues)
          dailyValues.forEach(v => {
            value += Number(v.value)
          });
        dataSet.push({
          seriesname: t(type),
          data: dailyValues,
        });
        weeksDataSet.push({
          seriesname: t(type),
          data: weeklyValues
        })
        let min = (((value / 60) % 1) * 60).toFixed(0)
        dataDoughnut.push({
          label: t(type),
          value,
          displayValue: t(type) + " - " + Math.trunc(value / 60) + "H" + (min < 10 ? "0" + min : min)
        })
        totalTime += value;
      });
    }

    var widgets = []

    const widgetSequence = (minutes, title, nbSeance) => {
      widgets.push(
        <div className="px-3 py-3 w-full">
          <WidgetKeyFigure
            svg='svg'
            figure={nbSeance}
            title={t(title)}
            heures={minutes}
          />
        </div>
      )
    }

    dataSet.map(dataSetObject => {
      let totalMinutes = 0
      let nbSequence = nbSessions[dataSetObject.seriesname]
      dataSetObject.data.forEach(dataObject => {
        totalMinutes += parseInt(dataObject.value)
      })
      let hours = moment.duration(totalMinutes * 60 * 1000).asHours()
      return widgetSequence(hours, dataSetObject.seriesname + " training", nbSequence ? nbSequence : "N/A");
    })

    if (this.props.isWeek && this.props.totalSRPE)
      widgets.push(
        <div className="px-3 py-3 w-full">
          <WidgetKeyFigure
            svg='svg'
            sRpe={this.props.totalSRPE ? this.props.totalSRPE : 0}
            title={t("sRpe total")}
            blank={true}
          />
        </div>
      )

    return <>
      {!this.props.isWeek ?
        <>
          <div className="w-full mt-6 mb-20">
            <div
              className="border rounded-md ml-4 mr-4"
              style={{ boxShadow: "0 0 10px 0 rgba(82,63,105,.2)" }}
            >
              <Card
                title={t("Charts")}
                headerActions={[
                  <Select
                    options={[
                      {
                        key: "Total",
                        label: t("Total"),
                      },
                      {
                        key: "Hebdo",
                        label: t("Hebdo"),
                      },
                      {
                        key: "Daily",
                        label: t("Daily"),
                      }
                    ]}
                    value={this.state.view}
                    onChange={(view) => this.setState({ view })}
                  />
                ]}
              >
                
              
                {this.state.view === "Total" ?
                  <div className="mx-2 my-2">
                    <ChartDoughnut2D title={t("Distribution")}
                      dataSet={dataDoughnut}
                      subcaption={t("From x to y", { date1, date2 })}
                      totalTime={totalTime}
                      width="100%"
                    />
                  </div>
                : <></>}
                {this.state.view === "Hebdo" ? // chart une barre par semaine
                  <>
                    <div className="mx-2 my-2">
                      <ChartStackedColumns dataSet={weeksDataSet} categories={weeks}
                        title={t("Session time weekly distribution")}
                      />
                    </div>
                  </>
                  : <></>
                }
                {this.state.view === "Daily" ?
                  <>
                    <div className="mx-2 my-2">
                      <ChartStackedColumns dataSet={dataSet} categories={dates}
                        title={t("Session time daily distribution")}
                      />
                    </div>
                  </>
                  : <></>
                }
                {this.state.view === "Weeks" ?
                  <>
                    <Select
                      options={weeks}
                      value={this.state.week.label}
                      onChange={(week) => this.setState({ week: weeks.find(w => w.key === week) })}
                    />
                    <ChartCycleByPlayerSessionTimesByTypes
                      data={this.props.data}
                      types={this.props.types}
                      startDate={this.state.week.startDate}
                      endDate={this.state.week.endDate}
                      height="400"
                      events={events.filter((o) => moment(o.startDate).isAfter(this.state.week.startDate)
                        && moment(o.endDate).isBefore(this.state.week.endDate))
                      }
                      isWeek={true}
                      t={t}
                    />
                  </>
                  : <></>
                }
              </Card>
            </div>
          </div>
        </>
        : <>
          <Carousel items={widgets} />
          <ChartStackedColumns dataSet={dataSet} categories={dates}
            title={t("Session time daily distribution")}
          />
          
        </>
      }
    </>;
  }
}

export default withTranslation()(ChartCycleByPlayerSessionTimesByTypes);
