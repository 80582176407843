import React from "react";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import {
  getCollectionsByPlayer,
  // getFolder,
  getGalleriesByPlayer,
  loadCollectionsByPlayer,
  // loadContainer,
  loadGalleriesByPlayer,
  // postDocument,
} from "../../utils/indexedDB/medias/handleMedia";
import GeneratedForm from "../../utils/forms/generateForm";
import SubTab from "../../components/tab/SubTab";
import ButtonLight from "../../components/buttons/ButtonLight";
import TablePlayerCollections from "../../tables/TablePlayerCollections";
import TablePlayerGalleries from "../../tables/TablePlayerGalleries";
// import TablePlayerDocuments from "../../tables/TablePlayerDocuments";
import ComingSoon from "../../components/comingSoon/comingSoon";

class PlayerMediasTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.playerId,
      mediasTab: "Videos",
      galleryId: null,
      collectionId: null,
      getCollectionsFromAPI: false,
      getGalleriesFromAPI: false

    };
    this.handleGalleriesPhotos = this.handleGalleriesPhotos.bind(this);
    this.handleCollectionsVideos = this.handleCollectionsVideos.bind(this);
    this.handleFolder = this.handleFolder.bind(this);
    // this.postDocument = postDocument.bind(this);
  }

  componentDidMount() {
    var id = this.props.playerId;
    this.handleCollectionsVideos(id);
    this.handleGalleriesPhotos(id);
    this.handleFolder(id);
  }

  handleCollectionsVideos(id) {
    this.setState({ getCollectionsFromAPI: false });
    getCollectionsByPlayer(id).then((collections) => {
      if (collections && !this.state.getCollectionsFromAPI) {
        this.setState({ collections });
      }
    });
    if (navigator.onLine) {
      loadCollectionsByPlayer(id).then((collections) => {
        if (collections) {
          this.setState({ collections, getCollectionsFromAPI: true });
        }
      });
    }
  }

  handleGalleriesPhotos(id) {
    this.setState({ getGalleriesFromAPI: false });
    getGalleriesByPlayer(id).then((galleries) => {
      if (galleries && !this.state.getGalleriesFromAPI) {
        this.setState({ galleries });
      }
    });
    if (navigator.onLine) {
      loadGalleriesByPlayer(id).then((galleries) => {
        if (galleries) {
          this.setState({ galleries, getGalleriesFromAPI: true });
        }
      });
    }
  }

  handleFolder(id) {
    this.setState({ getDocumentsFolderFromAPI: false });
    // getFolder(id).then(folder => {
    //   if (folder && !this.state.getDocumentsFolderFromAPI) {
    //     this.setState({ folder });
    //   }
    // })
    // if (navigator.onLine) {
    //   loadContainer(id).then(folder => {
    //     this.setState({ folder, getDocumentsFolderFromAPI: true })
    //   })
    // }
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <SubTab
          tab={[
            { key: "Videos", label: t("Videos") },
            { key: "Photos", label: t("Photos") },
            { key: "Documents", label: t("Documents") }
          ]}
          selectedTab={this.state.mediasTab}
          onChange={(key) =>
            this.setState({
              mediasTab: key,
            })
          }
        />
        {this.state.mediasTab === "Videos" ? (
          <TablePlayerCollections
            collections={this.state.collections}
            buttons={[
              <GeneratedForm
                component={(show, text) => (
                  <ButtonLight onClick={show} disabled={!navigator.onLine}>
                    {text}
                  </ButtonLight>
                )}
                callback={() =>
                  this.handleCollectionsVideos(this.state.id)
                }
                formName="createCollection"
                method="POST"
                formData={{
                  playerId: this.state.id,
                  playerIdname: this.props.player
                    ? this.props.player.displayName
                    : null,
                }}
              />,
            ]}
            pId={this.props.playerId}
            updateCollections={() => this.updateCollectionsVideos(this.state.id)}
          />
        ) : (
          ""
        )}
        {this.state.mediasTab === "Photos" ? (
          <TablePlayerGalleries
            galleries={this.state.galleries}
            buttons={[
              <GeneratedForm
                component={(show, text) => (
                  <ButtonLight onClick={show} disabled={!navigator.onLine}>
                    {text}
                  </ButtonLight>
                )}
                callback={() => this.handleGalleriesPhotos(this.state.id)}
                formName="createGallery"
                method="POST"
                formData={{
                  playerId: this.state.id,
                  playerIdname: this.props.player
                    ? this.props.player.displayName
                    : null,
                }}
              />,
            ]}
            pId={this.props.playerId}
            updateGalleries={() => this.handleGalleriesPhotos(this.state.id)}
          />
        ) : (
          ""
        )}
        {this.state.mediasTab === "Documents" ? (
          // <TablePlayerDocuments
          //   // buttons={[
          //   //   <GeneratedForm
          //   //     component={(show, text) => (
          //   //       <ButtonLight onClick={show} disabled={!navigator.onLine}>
          //   //         {text}
          //   //       </ButtonLight>
          //   //     )}
          //   //     callback={() => this.handleFolder(this.state.id)}
          //   //     formName="createDocument"
          //   //     method="POST"
          //   //     postFunction={(formData) => this.postDocument(this.state.id, this.state.root, formData.file, formData.isShared)}
          //   //   />,
          //   // ]}
          //   folder={this.state.folder}
          //   containerId={this.props.playerId}
          // />
          <ComingSoon />
        ) : (
          ""
        )}
      </>
    );
  }
}

export default withTranslation()(withRouter(PlayerMediasTab));
