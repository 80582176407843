import React from "react";
import { withTranslation } from "react-i18next";
import moment from "moment";

import { patch, del } from "../../utils/requests";

import * as Drawer from "../drawer";
import VideoPlayer from "../videoPlayer/videoPlayer";
import Button from "../buttons/Button";
import ActionButton from "../buttons/ActionButton";
class VideoCard extends React.Component {
  render() {
    const { video, isVideoTemp, author, t } = this.props;
    var component = (show) => (
      <div className={this.props.withoutTitle ? "gutter-b h-32 2xl:h-48 w-72" : "gutter-b h-60 2xl:h-72 w-72"}>
        <div
          className="relative h-36 2xl:h-48 w-auto text-center align-middle"
          style={isVideoTemp ? {
            backgroundColor:
              video.state === "Processing" || video.state === "Waiting"
                ? "#C0C0C0"
                : "#ff6666",
            minHeight: "180px"
          } : {
            minHeight: "180px",
          }}
          onClick={!isVideoTemp ? show : null}
        >
          {isVideoTemp ? <>
            <div className="text-white bg-black bg-opacity-50 px-1 py-0.5 h-10 rounded-sm text-lg 2xl:text-2xl font-medium">{video.state === "Processing" || video.state === "Waiting" ? video.percentage + "%" : t("Error")}</div>
          </> :
            <img className="h-full w-full" src={video.thumbnail} alt="" />}
          {video.durationDisplay ? <div className="absolute bottom-0 right-0 m-1.5 text-white bg-black bg-opacity-50 px-1 py-0.5 h-5 rounded-sm text-xs font-medium">{video.durationDisplay}</div> : null}
        </div>
        {this.props.withoutTitle ? "" :
          <div className="mt-1 mb-2 2xl:mt-2 2xl:mb-3 flex items-center h-12 2xl:h-20">
            <div className="w-6 2xl:w-10" title={video.name}>
              <img className="h-6 w-6 2xl:h-10 2xl:w-10 rounded-full" src={author ? author.photo : ""} alt="" />
            </div>
            <div className="flex justify-between ml-3 w-full">
              <div className="mr-3">
                <div className="text-black text-xs 2xl:text-sm font-medium text-hover-primary">
                  {video.name}
                </div>
                <div className="font-normal text-xs 2xl:text-sm text-gray-600">
                  {author ? author.displayName : null}
                </div>
                <div className="font-normal text-xs 2xl:text-sm text-gray-600">
                  {moment(video.date).calendar()}
                </div>
              </div>
              <ActionButton
                deleteForm={isVideoTemp ? null : "deleteVideo"}
                formData={video}
                refreshData={this.props.handleVideos}
                actions={video.state === "Error" ? [<Button className="group flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" onClick={() => {
                  patch("medias", "videos", "reupload/" + video.id).then(() => this.props.handleVideosTemp())
                }}><svg className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                  </svg>
                  {t("Reupload")}</Button>,
                <Button className="group flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" onClick={() => {
                  del("medias", "videos", "videoTemp/" + video.id).then(() => this.props.handleVideosTemp())
                }}><svg className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                  </svg>
                  {t("Delete")}</Button>] : null} />
            </div>
          </div>}
      </div>
    )
    return !isVideoTemp ? (
      <Drawer.Layout
        className="w-screen"
        component={(show) => (
          component(show)
        )}
      >
        {(hide) => (
          <>
            <Drawer.Header hide={hide}>
            </Drawer.Header>
            <Drawer.Body>
              <VideoPlayer style={{ height: "30vh", width: "auto" }} video={video} />
              <div className="">{video.name}</div>
              <div className="">{moment(video.date).calendar()}</div>
            </Drawer.Body>
            <div className="border-b text-gray-900" />
            <Drawer.Body>
              <div className="">{video.description}</div>
            </Drawer.Body>
          </>
        )}
      </Drawer.Layout>
    ) : (component(() => { }));
  }
}

export default withTranslation()(VideoCard);
