import getAccessToken from "../auth/getAccessToken";
import { getSASTokenAzureBlobStorage, uploadFile } from "../azure-blobstorage/functions";
import getUserProfile from "../auth/getUserProfile";

async function patch(APImodule, APIfeature, data, contentType, blobStorage, uploadDataPercentageFunction) {
  if (typeof data === "string") {
    return getAccessToken().then((accessToken) => fetch(
      window.location.origin + "/api/v1.0/" + APImodule + "/" + APIfeature + "/" + data,
      {
        method: "PATCH",
        headers: {
          Accept: "application/json",
          "my-Authorization": `Bearer ${accessToken}`
        }
      }
    ))
  } else {
    if (blobStorage && blobStorage.domain && blobStorage.container) {
      var { domain, container } = blobStorage;
      return getAccessToken().then((accessToken) => getSASTokenAzureBlobStorage(domain).then(azureBlobStorageToken => {
        if (azureBlobStorageToken) {
          var file = data.file;
          var extension = "." + (file.type && file.type.replace(/^.*\//g, '') ? file.type.replace(/^.*\//g, '') : file.name.substring(file.name.lastIndexOf('.') + 1));
          return getUserProfile().then(async (user) => {
            switch (domain) {
              case "exaduosport":
                switch (container) {
                  case "joueurs":
                  case "staff":
                    // rename file with id
                    await data.file.arrayBuffer().then(buffer => {
                      var file = new Blob([buffer], { type: "image/png" });
                      file.lastModified = new Date();
                      file.name = data.id + extension;
                      data.file = file;
                      data.photo = user.club + "/" + data.file.name;
                      data.filePath = data.photo;
                    });
                    break;
                  default:
                    data.picture = data.galleryId + "/" + data.file.name;
                    data.fileName = data.file.name;
                    data.filePath = data.picture;
                    break;
                }
                break;
              default:
                data.picture = data.galleryId + "/" + data.file.name;
                data.fileName = data.file.name;
                data.filePath = data.picture;
                break;
            }

            return uploadFile(azureBlobStorageToken, data, domain, container, uploadDataPercentageFunction).then(res => {
              if (res) {
                data.fileName = data.file.name;
                data.size = data.file.size;
                delete data.file;
                delete data.filePath;
                return fetch(
                  window.location.origin + "/api/v1.0/" + APImodule + "/" + APIfeature,
                  {
                    method: "PATCH",
                    headers: {
                      Accept: "application/json",
                      "Content-Type": contentType ? contentType : "application/json",
                      "my-Authorization": `Bearer ${accessToken}`,
                    },
                    body: JSON.stringify(data),
                  }
                ).catch((error) => {
                  throw error;
                })
                  ;
              } else {
                throw new Error("Error on upload file azure blob storage");
              }
            }).catch(err => {
              throw err;
            })
          });
        }
        else {
          throw new Error("Error on get azure blob storage token");
        }
      }));
    } else {
      return getAccessToken().then((accessToken) =>
        fetch(
          window.location.origin + "/api/v1.0/" + APImodule + "/" + APIfeature,
          {
            method: "PATCH",
            headers: {
              Accept: "application/json",
              "Content-Type": contentType ? contentType : "application/json",
              "my-Authorization": `Bearer ${accessToken}`,
            },
            body: JSON.stringify(data),
          }
        ).catch((error) => {
          throw error;
        })
      );
    }
  }

}

export default patch;
