import React from "react";
import ActionButton from "../components/buttons/ActionButton";
import { withTranslation } from "react-i18next";
import TableSimple from "../components/table/TableSimple";
import SearchInput from "../components/forms/input/SearchInput";
import FiltersSearch from "../components/filters/FiltersSearch";
import FiltersDropDowns from "../components/filters/FiltersDropDowns";
import FilterColumns from "../components/filters/FilterColumns";
import ExportToCSV from "../components/export/ExportToCSV";
import { Link } from "react-router-dom";
import { SelectMultiple } from "../components/forms";
import Button from "../components/buttons/Button";


class TableContacts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sessions: [],
            actions: [],
            durations: [],
            selectedDuration: [],
            groups: [],
            selectedGroupId: [],
            columns: [],
            selectedColumns: [],
            searchText: "",
        };
    }

    componentDidMount() {
        this.prepareData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.prepareData();
        }
    }

    prepareData = () => {
        var sessions = this.props.sessions;
        var durations = []; //types
        var selectedDuration = [];
        var groups = this.props.groups; //players
        var selectedGroupId = [];
        if (sessions) {
            sessions = sessions.map((session) => {
                if (durations && !durations.find((o) => o.key === session.duration)) {
                    durations.push({
                        key: session.duration,
                        label: session.durationHourly,
                    });
                    selectedDuration.push(session.duration);
                }
                if (selectedGroupId && !selectedGroupId.find((o) => o === session.groupId)) {
                    selectedGroupId.push(session.groupId);
                }
                return session;
            });
        }

        let updateForm = "updateTrainingSessionTemplates"
        let deleteForm = "deleteTrainingSessionTemplates"

        if (this.props.type === "physical") {
            updateForm = "updatePhysicalSessionTemplates"
            deleteForm = "deletePhysicalSessionTemplates"
        }

        const hide = () => this.props.hide

        const columns = this.props.button ? [
            {
                key: "name",
                visible: true,
                label: this.props.t("Title"),
                pinned: "left",
                id: "name",
                format: (row) => {
                    return (
                        <span className="flex justify-between">
                            <Button
                                className="btn"
                                onClick={() => {
                                    this.props.handleAdd(row);
                                    hide();
                                }}
                            >
                                <span className="ml-5">
                                    {row.name}
                                </span>
                            </Button>
                        </span>
                    );
                },
            },
            {
                key: "duration",
                visible: true,
                pinned: "left",
                label: this.props.t("Duration"),
                id: "duration",
                format: (row) =>
                    <span className="flex justify-between">
                        <Button
                            className="btn"
                            onClick={() => {
                                this.props.handleAdd(row);
                                hide();
                            }}
                        >
                            <span className="ml-5">
                                {row.durationHourly}
                            </span>
                        </Button>
                    </span>,
            },
            {
                key: "groupId",
                visible: true,
                pinned: "left",
                label: this.props.t("Group"),
                id: "groupId",
                format: (row) => <span className="flex justify-between">
                    <Button
                        className="btn"
                        onClick={() => {
                            this.props.handleAdd(row);
                            hide();
                        }}
                    >
                        <span className="ml-5">
                            {groups.find((o) => o.id === row.groupId).name}
                        </span>
                    </Button>
                </span>,
            },
            {
                key: "description",
                visible: true,
                pinned: "left",
                label: this.props.t("Description"),
                id: "description",
                format: (row) => <span className="flex justify-between">
                    <Button
                        className="btn"
                        onClick={() => {
                            this.props.handleAdd(row);
                            hide();
                        }}
                    >
                        <span className="ml-5">
                            {row.description}
                        </span>
                    </Button>
                </span>,
            },
            {
                key: "exercices",
                visible: true,
                pinned: "left",
                label: this.props.t("Exercices"),
                id: "exercices",
                format: (row) => <span className="flex justify-between">
                    <Button
                        className="btn"
                        onClick={() => {
                            this.props.handleAdd(row);
                            hide();
                        }}
                    >
                        <span className="ml-5">
                            {row.sequences.length}
                        </span>
                    </Button>
                </span>,
            }
        ] : [
            {
                key: "name",
                visible: true,
                label: this.props.t("Title"),
                pinned: "left",
                id: "name",
                format: (row) => {
                    return (
                        <span className="flex justify-between">
                            <Link
                                className="text-indigo-700 font-medium pr-2"
                                to={"/training/sessionTemplate/" + row.id + "/" + this.props.type}
                            >
                                <span className="ml-5">
                                    {row.name}
                                </span>
                            </Link>
                            <ActionButton
                                key={row.id}
                                updateForm={updateForm}
                                deleteForm={deleteForm}
                                formData={row}
                                refreshData={this.props.callback}
                            />
                        </span>
                    );
                },
            },
            {
                key: "duration",
                visible: true,
                pinned: "left",
                label: this.props.t("Duration"),
                id: "duration",
                format: (row) => row.durationHourly,
            },
            {
                key: "groupId",
                visible: true,
                pinned: "left",
                label: this.props.t("Group"),
                id: "groupId",
                format: (row) => <div className="flex justify-between">
                    <div>{groups.find((o) => o.id === row.groupId).name}</div>
                </div>,
            },
            {
                key: "description",
                visible: true,
                pinned: "left",
                label: this.props.t("Description"),
                id: "description",
                format: (row) => row.description,
            },
            {
                key: "exercices",
                visible: true,
                pinned: "left",
                label: this.props.t("Exercices"),
                id: "exercices",
                format: (row) => row.sequences.length,
            }
        ];
        var selectedColumns = [];
        columns.forEach((column) => {
            if (column.visible) {
                selectedColumns.push(column.key);
            }
        });
        this.setState({
            columns,
            sessions,
            durations,
            groups,
            selectedColumns,
            selectedDuration,
            selectedGroupId
        });
    };

    render() {
        const { t } = this.props;
        var sessions = this.state.sessions;
        var columns = this.state.columns;
        var selectedColumns = this.state.selectedColumns;
        var selectedDuration = this.state.selectedDuration;
        var selectedGroupId = this.state.selectedGroupId;
        var searchText = this.state.searchText;
        if (columns) {
            columns = columns.filter((o) =>
                selectedColumns.some((item) => item === o.key)
            );
        }
        if (sessions) {
            sessions = sessions.filter((o) =>
                selectedDuration.some((item) => item === o.duration)
            );
        }
        if (sessions) {
            sessions = sessions.filter((o) =>
                selectedGroupId.some((item) => item === o.groupId)
            );
        }
        if (searchText.length > 2) {
            sessions = sessions.filter((o) =>
                o.name.toLowerCase().includes(searchText.toLowerCase())
            );
        }
        return (
            <>
                <div className="px-8 py-2 flex justify-between border-b-2">
                    <div className="flex flex-row">
                        <h3 className="text-md font-semibold p-2 pr-4 truncate">
                            {sessions
                                ? sessions.length + " " + (sessions.length > 1 ? t("Typical sessions").toLowerCase() : t("Typical session").toLowerCase())
                                : ""}
                        </h3>
                        <span className="ml-5 mr-5 w-1 border-l"></span>
                        <FiltersSearch search={<SearchInput
                            value={this.state.searchText}
                            onChange={(searchText) => this.setState({ searchText })}
                        />} />
                        <span className="ml-5 mr-5 w-1 border-l"></span>
                        <FiltersDropDowns selects={[
                            <SelectMultiple
                                options={this.state.durations}
                                keys={this.state.selectedDuration}
                                className="mx-2"
                                onChange={(res) => this.setState({ selectedTypes: res })}
                                fixedLabel={t("Type")}
                            />,
                            <SelectMultiple
                                options={this.props.groups}
                                keys={this.state.selectedGroupId}
                                className="mx-2"
                                onChange={(res) => this.setState({ selectedPlayers: res })}
                                fixedLabel={t("Type")}
                            />
                        ]}
                        />
                        <span className="ml-5 mr-5 w-1 border-l"></span>
                        <FilterColumns
                            columns={
                                <SelectMultiple
                                    options={this.state.columns}
                                    keys={this.state.selectedColumns}
                                    className="mx-2"
                                    onChange={(res) => this.setState({ selectedColumns: res })}
                                    fixedLabel={t("Active columns")}
                                />
                            }
                        />
                        <span className="ml-5 mr-5 w-1 border-l"></span>
                        <ExportToCSV columns={columns} jsonData={sessions} fileName={t("TableSessionTemplates") + ".csv"} />
                    </div>
                    {this.props.buttons ? (
                        <>
                            <div className="flex flex-row">
                                {this.props.buttons.map((button, key) => <span key={key}>{button}</span>)}
                            </div>
                        </>
                    ) : (
                        ""
                    )}
                </div>
                <TableSimple columns={columns} data={sessions} />
            </>
        );
    }
}

export default withTranslation()(TableContacts);
