import React, { Component } from "react";
import ButtonLight from "../../components/buttons/ButtonLight";
import Card from "../../components/card/Card";
import GeneratedForm from "../../utils/forms/generateForm";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import Carousel from "../../components/carousels/Carousel";
import TableSimple from "../../components/table/TableSimple";
import FiltersBar from "../../components/filters/FiltersBar";
import FiltersTitle from "../../components/filters/FiltersTitle";
import FilterSeparator from "../../components/filters/FilterSeparator";
import FiltersSearch from "../../components/filters/FiltersSearch";
import SearchInput from "../../components/forms/input/SearchInput";
import FiltersDropDowns from "../../components/filters/FiltersDropDowns";
import { SelectMultiple } from "../../components/forms";
import ExportToCSV from "../../components/export/ExportToCSV";
import FilterColumns from "../../components/filters/FilterColumns";
import generateColor from "../../utils/generateColor";
import CardColor from "../../components/card/CardColor";

class StatistiqueIndividuelPhysical extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      selectedGroups: [],
      searchText: "",
      sort_field: "name",
      sort_direction: -1,
      infoByPlayer: [],
      matchInfos: [],
    };
  }

  componentDidMount() {
    this.setState(
      {
        infoByPlayer: this.props.infoByPlayer,
        matchInfos: this.props.matchInfos,
      },
      () => {
        this.prepareData(true);
      }
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      // do this to make props.infoByPlayer modifiable in prepareData
      this.setState({ infoByPlayer: this.props.infoByPlayer }, () => {
        this.prepareData(true);
      });
    }
  }

  generateFormatHeader(key) {
    return (label) => {
      return (
        <span
          className="cursor-pointer"
          onClick={() => {
            this.setState(
              {
                sort_field: key,
                sort_direction: -1 * this.state.sort_direction,
              },
              () => {
                this.prepareData();
              }
            );
          }}
        >
          <span className="flex">
            {label}
            {key ? (
              this.state.sort_field === key &&
              this.state.sort_direction === -1 ? (
                <span className="text-lg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-4"
                    viewBox="0 0 64 64"
                    aria-labelledby="title"
                    aria-describedby="desc"
                    role="img"
                    xlink="http://www.w3.org/1999/xlink"
                  >
                    <path
                      data-name="layer1"
                      d="M21 22a2.99 2.99 0 0 1 2.121.879l8.89 8.636 8.868-8.636a3 3 0 0 1 4.242 4.242L32.011 40 18.879 27.121A3 3 0 0 1 21 22z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </span>
              ) : this.state.sort_field === key ? (
                <span className="text-lg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-4"
                    viewBox="0 0 64 64"
                    aria-labelledby="title"
                    aria-describedby="desc"
                    role="img"
                    xlink="http://www.w3.org/1999/xlink"
                  >
                    <path
                      data-name="layer1"
                      d="M43 40a2.992 2.992 0 0 1-2.121-.879l-8.89-8.635-8.868 8.635a3 3 0 0 1-4.242-4.242L31.989 22l13.132 12.879A3 3 0 0 1 43 40z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </span>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </span>
        </span>
      );
    };
  }

  prepareData = (firstTime) => {
    var infoByPlayer = this.state.infoByPlayer;
    var matchInfos = this.state.matchInfos;

    //
    // names of columns of GPS data
    //

    var columnsNames = [];
    this.props.infoByPlayer &&
      this.props.infoByPlayer.forEach((playerInfos) => {
        playerInfos.gps.forEach((gPlayer) => {
          if (
            !columnsNames.find(
              (columnName) => columnName.id === gPlayer.measure.id
            )
          ) {
            columnsNames.push(gPlayer.measure);
          }
        });
      });

    //
    // find best player each column
    //

    columnsNames &&
      columnsNames.forEach((column) => {
        // find player with the max value for this column
        infoByPlayer.forEach((playerInfo) => {
          // find the real max
          let playerGps = playerInfo.gps.find(
            (columnGps) => columnGps.measure.id === column.id
          );
          // set first player to max
          if (playerGps) {
            if (!column.bestPlayerInfo)
              column.bestPlayerInfo = {
                player: playerInfo.player,
                gpsColumn: playerGps,
                positionColumn: playerInfo.position,
              };
            // check for new max
            if (column.bestPlayerInfo.gpsColumn.value < playerGps.value)
              column.bestPlayerInfo = {
                player: playerInfo.player,
                gpsColumn: playerGps,
                positionColumn: playerInfo.position,
              };
          }
        });
      });

    const columns = [
      {
        key: "name",
        label: "Name",
        visible: true,
        format: (row) => {
          return (
            <Link
              to={{
                pathname: `/competitionSoccer/playerInfoByMatch/${row.matchId}/${row.player.id}`,
              }}
            >
              <span className="hover:bg-blue-200 py-1 px-3 rounded-full text-xs">
                {row.position.number +
                  " - " +
                  row.player.lastName.toUpperCase()}
              </span>
            </Link>
          );
        },
      },
      {
        key: "rpe",
        label: "Rpe",
        visible: true,
        format: (row) => (
          <span className="py-1 px-3 rounded-full text-xs">
            {row && row.rpe && row.rpe.rpe ? row.rpe.rpe : " - "}
          </span>
        ),
        formatHeader: this.generateFormatHeader("rpe"),
      },
    ];

    const currentPlayerIsBestPlayer = (row, column) =>
      column.bestPlayerInfo &&
      column.bestPlayerInfo.player.id === (row && row.player.id);

    columnsNames &&
      columnsNames
        .sort((a, b) => (a.name === b.name ? 0 : a.name < b.name ? -1 : 1))
        .forEach((column) => {
          columns.push({
            key: column.id,
            label: column.name,
            visible: true,
            format: (row) => (
              <span
                className={
                  currentPlayerIsBestPlayer(row, column)
                    ? generateColor(column.name) &&
                      "text-white font-bold py-1 px-3 rounded-full text-xs"
                    : "py-1 px-3 rounded-full text-xs"
                }
                style={{
                  backgroundColor:
                    currentPlayerIsBestPlayer(row, column) &&
                    generateColor(column.name),
                }}
              >
                {row.gps &&
                  row.gps.find((gPlayer) => column.id === gPlayer.measure.id) &&
                  row.gps.find((gPlayer) => column.id === gPlayer.measure.id)
                    .value}{" "}
                {currentPlayerIsBestPlayer(row, column) && " ⭐ "}
              </span>
            ),
            formatHeader: this.generateFormatHeader(column.name),
          });
        });

    // after columns added Add local Storage
    var selectedColumns = localStorage.getItem("TableSoccer_physicalStat");
    if (selectedColumns === null || selectedColumns.length === 0) {
      selectedColumns = [];
      columns.forEach((column) => {
        selectedColumns.push(column.key);
        //  column.visible = true
      });
    } else selectedColumns = selectedColumns.split("|");
    columns.forEach((column) => {
      column.visible = selectedColumns.indexOf(column.key) < 0 ? false : true;
    });

    // after all the columns are added, and the data is elevated appropriately
    // we can sort data
    if (infoByPlayer) {
      var field = this.state.sort_field;
      var direction = this.state.sort_direction;
      infoByPlayer.sort((a, b) => {
        let first =
          field === "name"
            ? a[field]
            : a.gps.find((el) => el.measure.name === field);
        let second =
          field === "name"
            ? b[field]
            : b.gps.find((el) => el.measure.name === field);
        if (!first) return 1;
        if (!second) return -1;
        return first.value < second.value ? direction : -1 * direction;
      });
    }

    // selected columns
    if (firstTime) {
      selectedColumns = [];
      columns.forEach((column) => {
        if (column.visible) {
          selectedColumns.push(column.key);
        }
      });
      selectedColumns = selectedColumns.slice(0, 6);
      this.setState({ selectedColumns });
    }

    this.setState({
      columns,
      infoByPlayer,
      selectedColumns,
      bestPlayerColumns: columnsNames,
      matchInfos,
    });
  };

  // change columns
  onChangeSelectedColumns = (res) => {
    this.setState({ selectedColumns: res }, () => {
      // determiner ce qui est selectionne
      let select = [];
      this.state.columns.forEach((a) => {
        if (this.state.selectedColumns.indexOf(a.key) > -1) select.push(a.key);
      });
      // save selected elements to localstorage
      localStorage.setItem("TableSoccer_physicalStat", select.join("|"));
    });
  };

  render() {
    const { t } = this.props;
    var infoByPlayer = this.state.infoByPlayer;
    var columns = this.state.columns;
    var selectedColumns = this.state.selectedColumns;
    var searchText = this.state.searchText;
    var bestPlayerColumns = this.state.bestPlayerColumns;

    if (columns) {
      columns = columns.filter((o) =>
        selectedColumns.some((item) => item === o.key)
      );
    }

    if (searchText.length > 2) {
      infoByPlayer = infoByPlayer.filter(
        (o) =>
          o.player &&
          o.player.lastName &&
          o.player.lastName.toLowerCase().includes(searchText.toLowerCase())
      );
    }

    //
    // build data for best player by category view
    //

    var itemPlayer = [];
    bestPlayerColumns &&
      bestPlayerColumns.forEach((col) => {
        itemPlayer.push(
          <Link
            to={{
              pathname: `/competitionSoccer/playerInfoByMatch/${this.props.match.params.id}/${col.bestPlayerInfo.player.id}`,
            }}
          >
            <CardColor
              leftPart={col.bestPlayerInfo.player.lastName.slice(0, 1)}
              rightPart={() => {
                return (
                  <>
                    <div className="text-gray-900  hover:text-gray-600 font-semibold truncate">
                      {col.bestPlayerInfo.gpsColumn.measure.name}
                    </div>
                    <p
                      className="text-blue-700 text-xl font-bold my-1"
                      style={{
                        color: generateColor(
                          col.bestPlayerInfo.gpsColumn.measure.name
                        ),
                      }}
                    >
                      {col.bestPlayerInfo.gpsColumn.value +
                        " " +
                        col.bestPlayerInfo.gpsColumn.measure.unite}
                    </p>

                    <p className="text-indigo-800 my-1"></p>
                    {col.bestPlayerInfo.player.photo && (
                      <>
                        <div className="px-0 flex items-center space-x-3 my-2">
                          <img
                            className="h-6 w-6 rounded-full"
                            src={col.bestPlayerInfo.player.photo}
                            alt=""
                          />
                          <span className="font-bold">
                            {col.bestPlayerInfo.player.displayName}
                          </span>
                        </div>
                        <div className="text-xs font-semibold">
                          {col.bestPlayerInfo.positionColumn.name}
                        </div>
                      </>
                    )}
                  </>
                );
              }}
              color={generateColor(col.bestPlayerInfo.gpsColumn.measure.name)}
            />
          </Link>
        );
      });

    return (
      <>
        <div className="">
          {itemPlayer && itemPlayer.length > 0 && (
            <div className="w-full py-2 px-10">
              <Card title={t("Team Leaders")}>
                <Carousel items={itemPlayer} />
              </Card>
            </div>
          )}
        </div>
        <Card
          title={t("Statistiques Physiques")}
          headerActions={
            <GeneratedForm
              key="updateCommentPlayer"
              component={(show, text) => (
                <ButtonLight onClick={show} disabled={!navigator.onLine}>
                  {t("Edit")}
                </ButtonLight>
              )}
              callback={(res) => this.props.refresh()}
              formName="updateCommentPlayer"
              formData={this.state.player}
            />
          }
        >
          <FiltersBar
            components={
              <>
                <FiltersTitle
                  title={
                    infoByPlayer
                      ? infoByPlayer.length + " " + t("Players").toLowerCase()
                      : ""
                  }
                />
                <FilterSeparator />
                <FiltersSearch
                  search={
                    <SearchInput
                      value={this.state.searchText}
                      onChange={(searchText) => this.setState({ searchText })}
                    />
                  }
                />
                <FilterSeparator />
                <FiltersDropDowns
                  selects={[
                    <SelectMultiple
                      options={this.state.groups}
                      keys={this.state.selectedGroups}
                      className="mx-2"
                      fixedLabel={t("Group")}
                    />,
                  ]}
                />
                <FilterSeparator />
                <FilterColumns
                  columns={
                    <SelectMultiple
                      options={this.state.columns}
                      keys={this.state.selectedColumns}
                      className="mx-2"
                      fixedLabel={t("Active columns")}
                      onChange={this.onChangeSelectedColumns}
                    />
                  }
                />
                <FilterSeparator />
                <ExportToCSV
                  columns={columns}
                  jsonData={infoByPlayer}
                  fileName={t("Physical Stat") + ".csv"}
                />
              </>
            }
            buttons={this.props.buttons}
          />
          <TableSimple columns={columns} data={infoByPlayer} />
        </Card>
      </>
    );
  }
}

export default withTranslation()(withRouter(StatistiqueIndividuelPhysical));
