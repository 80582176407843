import React from "react";
// import moment from "moment";
import ModalImage from "react-modal-image";
import ActionButton from "../buttons/ActionButton";
import { Link } from "react-router-dom";
import generateColor from "../../utils/generateColor";


class ExerciceCard extends React.Component {
    render() {
        // var title = this.props.title;
        // var headerActions = this.props.headerActions;
        var { exercice, type, button } = this.props;

        var updateForm = "updateTrainingDrill"
        var deleteForm = "deleteTrainingDrill"
        var theme = exercice[type + "Theme"]
        var category = theme[type + "Category"]

        if (type === "physical") {
            updateForm = "updatePhysicalDrill"
            deleteForm = "deletePhysicalDrill"
        }

        return (
            <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                <div className="flex-shrink-0">
                    {type ?
                        <Link className="text-indigo-700 font-semibold text-sm 2xl:text-base"
                            to={"/training/drill/" + exercice.id + "/" + type}
                        >
                            <ModalImage
                                small={exercice.picture ? exercice.picture : "https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80"}
                                large={exercice.picture ? exercice.picture : "https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80"}
                                alt={exercice.name}
                                className="h-40 2xl:h-64 w-full object-cover"
                            />
                        </Link>
                        :
                        <ModalImage
                            small={exercice.picture ? exercice.picture : "https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80"}
                            large={exercice.picture ? exercice.picture : "https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80"}
                            alt={exercice.name}
                            className="h-40 2xl:h-64 w-full object-cover"
                        />
                    }
                </div>
                <div className="flex-1 bg-white p-4 2xl:p-6 flex flex-col justify-between">
                    <div className="flex-1">
                        <div className="flex justify-between mb-1 2xl:mb-2">
                        <p className="inline-flex items-center px-1.5 py-0.5 border border-transparent text-xs 2xl:text-sm font-medium rounded text-indigo-600 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"  style={{color: "#FFFFFF", backgroundColor: generateColor(category ? category.id : "")}}>
                            <span className="hover:underline">
                                {(category ? category.name : "") + " - " + theme.name}
                            </span>
                        </p>
                        <span className="text-xs 2xl:text-sm font-medium text-indigo-600 mt-1"  style={{color:generateColor(category ? category.id : "")}}>{exercice.duration + " min"}</span>
                        </div>
                        <span className="block mt-2 2xl:mt-3">
                            <div className="flex justify-between">
                                <p className="text-base 2xl:text-lg font-semibold text-gray-900 truncate">
                                    {exercice.name}
                                </p>
                                {button ? "" : <ActionButton
                                    key={exercice.id}
                                    updateForm={updateForm}
                                    deleteForm={deleteForm}
                                    formData={exercice}
                                    refreshData={this.props.refresh}
                                />}
                            </div>
                            <p className="mt-2 2xl:mt-3 text-gray-500 text-xs 2xl:text-sm">
                                {exercice.goals}
                            </p>
                        </span>
                    </div>
                    {exercice.author ?
                        <Link
                            className="text-indigo-700 font-semibold"
                            to={"/group/staff/" + exercice.authorId}
                        >
                            <div className="mt-3 2xl:mt-4 flex items-center">
                                <div className="flex-shrink-0">
                                    <span>
                                        <span className="sr-only">{exercice.author.lastName.toUpperCase()}</span>
                                        <img className="h-6 2xl:h-10 w-6 2xl:w-10 rounded-full" src={exercice.author.photo} alt="" />
                                    </span>
                                </div>
                                <div className="ml-3">
                                    <p className="text-xs 2xl:text-sm font-medium text-gray-900">
                                        <span>
                                            {exercice.author.lastName.toUpperCase() + " " + exercice.author.firstName}
                                        </span>
                                    </p>
                                    <div className="flex space-x-1 text-xs 2xl:text-sm text-gray-500">
                                        {exercice.author.title}
                                    </div>
                                </div>
                            </div>
                        </Link>
                        :
                        <div className="mt-6 flex items-center">
                            <div className="flex-shrink-0">
                                <span>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 64 64"
                                        role="img"
                                        className="h-6"
                                        stroke="currentColor"
                                    >
                                        <path
                                            data-name="layer1"
                                            d="M50.4 51C40.5 49.1 40 46 40 44v-1.2a18.9 18.9 0 0 0 5.7-8.8h.1c3 0 3.8-6.3 3.8-7.3s.1-4.7-3-4.7C53 4 35.5-3.2 22.3 6c-5.4 0-5.9 8-3.9 16-3.1 0-3 3.8-3 4.7s.7 7.3 3.8 7.3c1 3.6 2.3 6.9 4.7 9v1.2c0 2 .5 5-9.5 6.8S2 62 2 62h60a14.6 14.6 0 0 0-11.6-11z"
                                            fill="none"
                                            strokeMiterlimit="10"
                                            strokeWidth="2"
                                            strokeLinejoin="round"
                                            strokeLinecap="round"
                                        />
                                    </svg>
                                </span>
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default ExerciceCard;
