import React from "react"
import { withTranslation } from "react-i18next"
import Input from "../input/Input"
import {
  getPlayer,
  loadPlayer,
} from "../../../utils/indexedDB/users/handleUser"
import {
  getOpponentById,
  loadOpponentById,
} from "../../../utils/indexedDB/Tennis-Competition/handleCompetitionTennis"
import EditableTable from "../../table/EditableTable"
import Flag from "../../icons/Flag"

class EditableTableTennisSetsResults extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      results: [],
      users: [],
    }
    this.formattedResults = this.formattedResults.bind(this)
  }

  getInitialisedResult() {
    return [
      {
        label: "Player 1",
        values: ["", "", "", "", ""],
        line: "1",
      },
      {
        label: "Player 2",
        values: ["", "", "", "", ""],
        line: "2",
      },
      {
        label: "Tie Break",
        values: ["", "", "", "", ""],
        line: "3",
      },
      {
        label: "Duration",
        values: ["", "", "", "", ""],
        line: "4",
      },
    ]
  }

  componentWillMount() {
    var promises = []
    if (this.props.formData) {
      if (this.props.formData.playerId) {
        promises.push(
          getPlayer(this.props.formData.playerId).then((player) => {
            if (player) return player
            return loadPlayer(this.props.formData.playerId)
          })
        )
      }
      if (this.props.formData.partnerId) {
        promises.push(
          getOpponentById(this.props.formData.partnerId).then((opponent) => {
            if (opponent) return opponent
            return loadOpponentById(this.props.formData.partnerId)
          })
        )
      }
      if (this.props.formData.opponentId) {
        promises.push(
          getOpponentById(this.props.formData.opponentId).then((opponent) => {
            if (opponent) return opponent
            return loadOpponentById(this.props.formData.opponentId)
          })
        )
      }
      if (this.props.formData.opponent2Id) {
        promises.push(
          getOpponentById(this.props.formData.opponent2Id).then((opponent) => {
            if (opponent) return opponent
            return loadOpponentById(this.props.formData.opponent2Id)
          })
        )
      }
    }
    if (promises) {
      Promise.all(promises).then((users) => {
        this.setState({ users })
        if (this.props.formData) {
          var formData = this.props.formData
          this.formattedResults(users, formData)
        }
      })
    }

    this.setState({ results: this.getInitialisedResult() })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.formData !== this.props.formData) {
      var promises = []
      var users = this.state.users
      if (
        (!prevProps.formData && this.props.formData) ||
        (prevProps.formData.playerId !== this.props.formData.playerId &&
          !users.find((u) => u.id === this.props.formData.playerId))
      ) {
        if (this.props.formData.playerId) {
          promises.push(
            getPlayer(this.props.formData.playerId).then((player) => {
              if (player) return player
              return loadPlayer(this.props.formData.playerId)
            })
          )
        }
      }
      if (
        (!prevProps.formData && this.props.formData) ||
        (prevProps.formData.partnerId !== this.props.formData.partnerId &&
          !users.find((u) => u.id === this.props.formData.partnerId) &&
          this.props.formData.partnerId)
      ) {
        if (this.props.formData.partnerId) {
          promises.push(
            getOpponentById(this.props.formData.partnerId).then((opponent) => {
              if (opponent) return opponent
              return loadOpponentById(this.props.formData.partnerId)
            })
          )
        }
      }
      if (
        (!prevProps.formData && this.props.formData) ||
        (prevProps.formData.opponentId !== this.props.formData.opponentId &&
          !users.find((u) => u.id === this.props.formData.opponentId) &&
          this.props.formData.opponentId)
      ) {
        if (this.props.formData.opponentId) {
          promises.push(
            getOpponentById(this.props.formData.opponentId).then((opponent) => {
              if (opponent) return opponent
              return loadOpponentById(this.props.formData.opponentId)
            })
          )
        }
      }
      if (
        (!prevProps.formData && this.props.formData) ||
        (prevProps.formData.opponent2Id !== this.props.formData.opponent2Id &&
          !users.find((u) => u.id === this.props.formData.opponent2Id) &&
          this.props.formData.opponent2Id)
      ) {
        if (this.props.formData.opponent2Id) {
          promises.push(
            getOpponentById(this.props.formData.opponent2Id).then(
              (opponent) => {
                if (opponent) return opponent
                return loadOpponentById(this.props.formData.opponent2Id)
              }
            )
          )
        }
      }

      Promise.all(promises).then((newUsers) => {
        if (newUsers && newUsers.length) {
          newUsers.forEach((u) => {
            users.push(u)
          })
        }
        this.formattedResults(users, this.props.formData)
      })
    }
  }

  formattedResults(users, formData) {
    var results = this.state.results
    var player
    if (!users || !users.length) return
    // find player
    player = users.find((u) => u.id === formData.playerId)
    if (player) {
      results[0].label = (
        <>
          <Flag height="12" nationalityId={player.nationalityId} />
          {player.name ? player.name : player.lastName + " " + player.firstName}
        </>
      )
    }
    // find partner
    player = users.find((u) => u.id === formData.partnerId)
    if (player) {
      results[0].label = (
        <>
          {results[0].label} /{" "}
          <Flag height="12" nationalityId={player.nationalityId} />{" "}
          {player.name ? player.name : player.lastName + " " + player.firstName}
        </>
      )
    }
    // find opponent 1
    player = users.find((u) => u.id === formData.opponentId)
    if (player) {
      results[1].label = (
        <>
          <Flag height="12" nationalityId={player.nationalityId} />{" "}
          {player.name ? player.name : player.lastName + " " + player.firstName}
        </>
      )
    }
    // find opponent 2
    player = users.find((u) => u.id === formData.opponent2Id)
    if (player) {
      results[0].label = (
        <>
          {results[1].label} /{" "}
          <Flag height="12" nationalityId={player.nationalityId} />{" "}
          {player.name ? player.name : player.lastName + " " + player.firstName}
        </>
      )
    }
    results[0].values = [
      formData.resultSet1,
      formData.resultSet2,
      formData.resultSet3,
      formData.resultSet4,
      formData.resultSet5,
    ]
    results[1].values = [
      formData.resultSet1Adv,
      formData.resultSet2Adv,
      formData.resultSet3Adv,
      formData.resultSet4Adv,
      formData.resultSet5Adv,
    ]
    results[2].values = [
      formData.resultSet1TieBreak,
      formData.resultSet2TieBreak,
      formData.resultSet3TieBreak,
      formData.resultSet4TieBreak,
      formData.resultSet5TieBreak,
    ]
    results[3].values = [
      formData.durationSet1,
      formData.durationSet2,
      formData.durationSet3,
      formData.durationSet4,
      formData.durationSet5,
    ]
    this.setState({ results })
  }

  render() {
    const columns = [
      {
        key: "player",
        format: (row) => <>{row.label}</>,
      },
      {
        key: "Set1",
        label: "1",
        format: (row) => (
          <Input
            className="form-control"
            value={row.values[0]}
            onChange={(value) => {
              var formData = this.props.formData
              var results = this.state.results
              if (!formData.specificInfos) {
                formData.specificInfos = {}
              }
              if (row.line === "1") {
                formData.resultSet1 = value
                formData.specificInfos.resultSet1 = Number(value)
              }
              if (row.line === "2") {
                formData.resultSet1Adv = value
                formData.specificInfos.resultSet1Adv = Number(value)
              }
              if (row.line === "3") {
                formData.resultSet1TieBreak = value
                formData.specificInfos.resultSet1TieBreak = Number(value)
              }
              if (row.line === "4") {
                formData.durationSet1 = value
                formData.specificInfos.durationSet1 = Number(value)
              }
              results[0].values = [
                formData.resultSet1,
                formData.resultSet2,
                formData.resultSet3,
                formData.resultSet4,
                formData.resultSet5,
              ]
              results[1].values = [
                formData.resultSet1Adv,
                formData.resultSet2Adv,
                formData.resultSet3Adv,
                formData.resultSet4Adv,
                formData.resultSet5Adv,
              ]
              results[2].values = [
                formData.resultSet1TieBreak,
                formData.resultSet2TieBreak,
                formData.resultSet3TieBreak,
                formData.resultSet4TieBreak,
                formData.resultSet5TieBreak,
              ]
              results[3].values = [
                formData.durationSet1,
                formData.durationSet2,
                formData.durationSet3,
                formData.durationSet4,
                formData.durationSet5,
              ]
              this.setState({ results })
              this.props.onChange(formData)
            }}
          />
        ),
      },
      {
        key: "Set2",
        label: "2",
        format: (row) => (
          <Input
            className="form-control"
            value={row.values[1]}
            onChange={(value) => {
              var formData = this.props.formData
              if (!formData.specificInfos) {
                formData.specificInfos = {}
              }
              if (row.line === "1") {
                formData.resultSet2 = value
                formData.specificInfos.resultSet2 = Number(value)
              }
              if (row.line === "2") {
                formData.resultSet2Adv = value
                formData.specificInfos.resultSet2Adv = Number(value)
              }
              if (row.line === "3") {
                formData.resultSet2TieBreak = value
                formData.specificInfos.resultSet2TieBreak = Number(value)
              }
              if (row.line === "4") {
                formData.durationSet2 = value
                formData.specificInfos.durationSet2 = Number(value)
              }
              var results = this.state.results
              results[0].values = [
                formData.resultSet1,
                formData.resultSet2,
                formData.resultSet3,
                formData.resultSet4,
                formData.resultSet5,
              ]
              results[1].values = [
                formData.resultSet1Adv,
                formData.resultSet2Adv,
                formData.resultSet3Adv,
                formData.resultSet4Adv,
                formData.resultSet5Adv,
              ]
              results[2].values = [
                formData.resultSet1TieBreak,
                formData.resultSet2TieBreak,
                formData.resultSet3TieBreak,
                formData.resultSet4TieBreak,
                formData.resultSet5TieBreak,
              ]
              results[3].values = [
                formData.durationSet1,
                formData.durationSet2,
                formData.durationSet3,
                formData.durationSet4,
                formData.durationSet5,
              ]
              this.setState({ results })
              this.props.onChange(formData)
            }}
          />
        ),
      },
      {
        key: "Set3",
        label: "3",
        format: (row) => (
          <Input
            className="form-control"
            value={row.values[2]}
            onChange={(value) => {
              var formData = this.props.formData
              var results = this.state.results
              if (!formData.specificInfos) {
                formData.specificInfos = {}
              }
              if (row.line === "1") {
                formData.resultSet3 = value
                formData.specificInfos.resultSet3 = Number(value)
              }
              if (row.line === "2") {
                formData.resultSet3Adv = value
                formData.specificInfos.resultSet3Adv = Number(value)
              }
              if (row.line === "3") {
                formData.resultSet3TieBreak = value
                formData.specificInfos.resultSet3TieBreak = Number(value)
              }
              if (row.line === "4") {
                formData.durationSet3 = value
                formData.specificInfos.durationSet3 = Number(value)
              }
              results[0].values = [
                formData.resultSet1,
                formData.resultSet2,
                formData.resultSet3,
                formData.resultSet4,
                formData.resultSet5,
              ]
              results[1].values = [
                formData.resultSet1Adv,
                formData.resultSet2Adv,
                formData.resultSet3Adv,
                formData.resultSet4Adv,
                formData.resultSet5Adv,
              ]
              results[2].values = [
                formData.resultSet1TieBreak,
                formData.resultSet2TieBreak,
                formData.resultSet3TieBreak,
                formData.resultSet4TieBreak,
                formData.resultSet5TieBreak,
              ]
              results[3].values = [
                formData.durationSet1,
                formData.durationSet2,
                formData.durationSet3,
                formData.durationSet4,
                formData.durationSet5,
              ]
              this.setState({ results })
              this.props.onChange(formData)
            }}
          />
        ),
      },
      {
        key: "Set4",
        label: "4",
        format: (row) => (
          <Input
            className="form-control"
            value={row.values[3]}
            onChange={(value) => {
              var formData = this.props.formData
              var results = this.state.results
              if (!formData.specificInfos) {
                formData.specificInfos = {}
              }
              if (row.line === "1") {
                formData.resultSet4 = value
                formData.specificInfos.resultSet4 = Number(value)
              }
              if (row.line === "2") {
                formData.resultSet4Adv = value
                formData.specificInfos.resultSet4Adv = Number(value)
              }
              if (row.line === "3") {
                formData.resultSet4TieBreak = value
                formData.specificInfos.resultSet4TieBreak = Number(value)
              }
              if (row.line === "4") {
                formData.durationSet4 = value
                formData.specificInfos.durationSet4 = Number(value)
              }
              results[0].values = [
                formData.resultSet1,
                formData.resultSet2,
                formData.resultSet3,
                formData.resultSet4,
                formData.resultSet5,
              ]
              results[1].values = [
                formData.resultSet1Adv,
                formData.resultSet2Adv,
                formData.resultSet3Adv,
                formData.resultSet4Adv,
                formData.resultSet5Adv,
              ]
              results[2].values = [
                formData.resultSet1TieBreak,
                formData.resultSet2TieBreak,
                formData.resultSet3TieBreak,
                formData.resultSet4TieBreak,
                formData.resultSet5TieBreak,
              ]
              results[3].values = [
                formData.durationSet1,
                formData.durationSet2,
                formData.durationSet3,
                formData.durationSet4,
                formData.durationSet5,
              ]
              this.setState({ results })
              this.props.onChange(formData)
            }}
          />
        ),
      },
      {
        key: "Set5",
        label: "5",
        format: (row) => (
          <Input
            className="form-control"
            value={row.values[4]}
            onChange={(value) => {
              var formData = this.props.formData
              var results = this.state.results
              if (!formData.specificInfos) {
                formData.specificInfos = {}
              }
              if (row.line === "1") {
                formData.resultSet5 = value
                formData.specificInfos.resultSet5 = Number(value)
              }
              if (row.line === "2") {
                formData.resultSet5Adv = value
                formData.specificInfos.resultSet5Adv = Number(value)
              }
              if (row.line === "3") {
                formData.resultSet5TieBreak = value
                formData.specificInfos.resultSet5TieBreak = Number(value)
              }
              if (row.line === "4") {
                formData.durationSet5 = value
                formData.specificInfos.durationSet5 = Number(value)
              }
              results[0].values = [
                formData.resultSet1,
                formData.resultSet2,
                formData.resultSet3,
                formData.resultSet4,
                formData.resultSet5,
              ]
              results[1].values = [
                formData.resultSet1Adv,
                formData.resultSet2Adv,
                formData.resultSet3Adv,
                formData.resultSet4Adv,
                formData.resultSet5Adv,
              ]
              results[2].values = [
                formData.resultSet1TieBreak,
                formData.resultSet2TieBreak,
                formData.resultSet3TieBreak,
                formData.resultSet4TieBreak,
                formData.resultSet5TieBreak,
              ]
              results[3].values = [
                formData.durationSet1,
                formData.durationSet2,
                formData.durationSet3,
                formData.durationSet4,
                formData.durationSet5,
              ]
              this.setState({ results })
              this.props.onChange(formData)
            }}
          />
        ),
      },
    ]
    return (
      <EditableTable
        columns={columns}
        data={this.state.results}
        className="p-0"
      />
    )
  }
}

export default withTranslation()(EditableTableTennisSetsResults)
