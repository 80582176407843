import React from "react";
import { withTranslation } from "react-i18next";

import { del } from "../../../requests/index";
import Button from "../../../../components/buttons/Button";
import Checkbox from "../../../../components/forms/input/CheckBoxLabel";

import db from "../../../indexedDB/events/db";
import * as Drawer from "../../../../components/drawer";
import cogoToast from "cogo-toast";

class StepDeletion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: this.props.formData,
            keepRecurrence: true,
            loading: false
        }

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit() {
        var keepRecurrence = this.state.keepRecurrence && this.state.formData.recurrenceId;
        this.setState({ loading: true });
        del("schedule", keepRecurrence ? "eventsRecurrence" : "events", this.state.formData).then((res) => {
            if (res.ok) {
                cogoToast.success(this.props.t("Deletion Successed"), {position: "bottom-right"});
                if (db) {
                    if (db.events && db.events.get(this.state.formData.id)) {
                        db.events.delete(this.state.formData.id);
                    }
                    if (db.eventsByPlayer && db.eventsByPlayer.get(this.state.formData.id)) {
                        db.eventsByPlayer.delete(this.state.formData.id);
                    }
                }
                this.setState({ loading: false });
                this.props.nextStep(this.state.formData);
            } else {
                throw new Error(res.status + " " + res.url);
            }
        }).catch(err => {
            this.setState({ error: err.toString(), loading: false });
        });
    }


    render() {
        const { t } = this.props;
        return (
            <>
                <Drawer.Header hide={this.props.hide}>
                    {this.state.formData && this.state.formData.name ? t("Delete event name", { name: this.state.formData.name }) : t("Delete event")}
                </Drawer.Header>
                <Drawer.Body>
                    <>
                        {this.state.error ? <div className="alert alert-danger">
                            {this.state.error}
                        </div> : null}
                        <div className="mt-3">{t("Delete event description")}</div>
                        {this.state.formData && this.state.formData.recurrenceId ?
                <>
                  <div className="border-b mt-3 -mx-12"></div>
                  <Checkbox value={this.state.keepRecurrence} onChange={(keepRecurrence) => this.setState({keepRecurrence})} title={t("Keep recurrence")}/>
                </>
                : null}
                    </>
                </Drawer.Body>
                <Drawer.Footer>
                    <Button
                        loading={this.state.loading}
                        onClick={() => {
                            this.handleSubmit();
                        }}
                    >
                        {t("Delete")}
                    </Button>
                </Drawer.Footer>
            </>
        )
    }
}

export default withTranslation()(StepDeletion);