import React from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { SelectMultiple } from "../components/forms";
import TableSimple from "../components/table/TableSimple";
import IconRound32x32 from "../components/icons/IconRound32x32";
import FiltersDropDowns from "../components/filters/FiltersDropDowns";
import FilterColumns from "../components/filters/FilterColumns";
import FiltersSearch from "../components/filters/FiltersSearch";
import SearchInput from "../components/forms/input/SearchInput";
import ExportToCSV from "../components/export/ExportToCSV";
import Tooltip from "../components/typography/Tooltip/Tooltip";
import FilterSeparator from "../components/filters/FilterSeparator";
import FiltersTitle from "../components/filters/FiltersTitle";
import FiltersBar from "../components/filters/FiltersBar";

class TableAnthropometry extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      actions: [],
      groups: [],
      selectedGroups: [],
      columns: [],
      searchText: "",
      sort_field: "name",
      sort_direction: -1,
      players: [],
    }
  }

  componentDidMount() {
    this.setState({
      players: this.props.players,
    }, () => {
      this.prepareData(true)
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      // do this to make props.players modifiable in prepareData
      this.setState({ players: this.props.players }, () => {
        this.prepareData(true)
      })
    }
  }

  generateFormatHeader(key) {
    return (label) => {
      return (
        <span
          className="cursor-pointer"
          onClick={() => {
            this.setState(
              {
                sort_field: key,
                sort_direction: -1 * this.state.sort_direction,
              },
              () => {
                this.prepareData()
              }
            )
          }}
        >
          <span className="flex">
          {label}
          {key ? (
            this.state.sort_field === key && this.state.sort_direction === -1 ? (
              <span className="text-lg">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-4" viewBox="0 0 64 64" aria-labelledby="title" aria-describedby="desc" role="img" xlink="http://www.w3.org/1999/xlink">
                  <path data-name="layer1"
                  d="M21 22a2.99 2.99 0 0 1 2.121.879l8.89 8.636 8.868-8.636a3 3 0 0 1 4.242 4.242L32.011 40 18.879 27.121A3 3 0 0 1 21 22z"
                  fill="currentColor"></path>
                </svg>
              </span>
            ) : (this.state.sort_field === key ?
              <span className="text-lg">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-4"  viewBox="0 0 64 64" aria-labelledby="title" aria-describedby="desc" role="img" xlink="http://www.w3.org/1999/xlink">
                  <path data-name="layer1"
                  d="M43 40a2.992 2.992 0 0 1-2.121-.879l-8.89-8.635-8.868 8.635a3 3 0 0 1-4.242-4.242L31.989 22l13.132 12.879A3 3 0 0 1 43 40z"
                  fill="currentColor"></path>
                </svg>
                
              </span> : "")
            )
           : (
            ""
          )}
          </span>
        </span>
      )
    }
  }

  prepareData = (firstTime) => {
    var players = this.state.players
    var groups = []
    var selectedGroups = []

    //  chercher les elements a déselectionner du localstorage
    var unselectedGroups = localStorage.getItem("TableAnthropometry_unselectedGroups")
    if (unselectedGroups) unselectedGroups = unselectedGroups.split("|")    
    
    
    if (players) {
      players = players.map((player) => {
        if (groups && !groups.find((o) => o.key === player.group1.id)) {
          groups.push({
            key: player.group1.id,
            label: player.group1.name,
          })
          // only add to selected if not unselected
          if (
            !unselectedGroups ||
            unselectedGroups.indexOf(player.group1.id.toString()) < 0
          )
          selectedGroups.push(player.group1.id)
        }
        return player
      })
    }

    const columns = [
      {
        key: "name",
        visible: true,
        pinned: "left",
        label: this.props.t("Player"),
        id: "displayName",
        width: 300,
        format: (row) => (
          <Link
            className="text-indigo-700 font-semibold"
            to={"/group/player/" + row.id}
          >
            <IconRound32x32 photo={row.photo} />
            <span className="ml-5 text-xs 2xl:text-sm">
              {row.lastName.toUpperCase() + " " + row.firstName}
            </span>
          </Link>
        ),
        formatHeader: this.generateFormatHeader("name"),
      },
    ]

    
    if (this.props.measures) {
      this.props.measures.forEach((measure) => {
        if (players) {
          for (var i = 0; i < players.length; i++) {
            if (
              players[i].anthropometricData &&
              players[i].anthropometricData.length
            )
              for (var j = 0; j < players[i].anthropometricData.length; j++) {
                if (players[i].anthropometricData[j].measureId === measure.id) {
                  players[i][measure.id] = players[i].anthropometricData[j].value
                }
              }
          }
        }

        columns.push({
          key: measure.id,
          visible: true,
          id: measure.id,
          label: measure.name,
          format: (row) => {
            if (!row[measure.id]) return "-"
            return row.anthropometricData.find((o) => o.measureId === measure.id).thresholdColor ?
            <Tooltip tooltipContent={<ul className="divide-y divide-gray-200 w-96">
              <li className="relative py-5 px-4 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
                <div className="flex justify-between space-x-3">
                  <div className="min-w-0 flex-1">
                    <div>
                      <span className="absolute inset-0" aria-hidden="true"></span>
                      <p className=" text-xs 2xl:text-sm font-medium text-gray-900 truncate">{this.props.t("Last result")}</p>
                    </div>
                  </div>
                  <div className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500">{row.anthropometricData.find((o) => o.measureId === measure.id).value != null ? row.anthropometricData.find((o) => o.measureId === measure.id).value + measure.unit : "-"}</div>
                </div>
              </li>
              <li className="relative py-5 px-4 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
                <div className="flex justify-between space-x-3">
                  <div className="min-w-0 flex-1">
                    <div>
                      <span className="absolute inset-0" aria-hidden="true"></span>
                      <p className=" text-xs 2xl:text-sm font-medium text-gray-900 truncate">{this.props.t("Previous result")}</p>
                    </div>
                  </div>
                  <div className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500">{row.anthropometricData.find((o) => o.measureId === measure.id).previousValue != null ? row.anthropometricData.find((o) => o.measureId === measure.id).previousValue + measure.unit : "-"}</div>
                </div>
              </li>
              <li className="relative py-5 px-4 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
                <div className="flex justify-between space-x-3">
                  <div className="min-w-0 flex-1">
                    <div>
                      <span className="absolute inset-0" aria-hidden="true"></span>
                      <p className=" text-xs 2xl:text-sm font-medium text-gray-900 truncate">{this.props.t("Delta")}</p>
                    </div>
                  </div>
                  <div className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500">{row.anthropometricData.find((o) => o.measureId === measure.id).delta != null ? row.anthropometricData.find((o) => o.measureId === measure.id).delta + measure.unit : "-"}</div>
                </div>
              </li>
              <li className="relative py-5 px-4 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
                <div className="flex justify-between space-x-3">
                  <div className="min-w-0 flex-1">
                    <div>
                      <span className="absolute inset-0" aria-hidden="true"></span>
                      <p className=" text-xs 2xl:text-sm font-medium text-gray-900 truncate">{this.props.t("Var") + " (%)"}</p>
                    </div>
                  </div>
                  <div className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500">{row.anthropometricData.find((o) => o.measureId === measure.id).var ? row.anthropometricData.find(o => o.measureId === measure.id).var.toFixed(2) + "%" : "-"}</div>
                </div>
              </li>
            </ul>}>
              <span className=" text-xs 2xl:text-sm inline-flex items-center px-2.5 py-0.5 rounded-full text-white" style={{ backgroundColor: row.anthropometricData.find((o) => o.measureId === measure.id).thresholdColor }}>
                {row.anthropometricData.find((o) => o.measureId === measure.id)
                  .value + measure.unit}
              </span>
              </Tooltip>
              : <><Tooltip tooltipContent={<ul className="divide-y divide-gray-200 w-96">
              <li className="relative py-5 px-4 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
                <div className="flex justify-between space-x-3">
                  <div className="min-w-0 flex-1">
                    <div>
                      <span className="absolute inset-0" aria-hidden="true"></span>
                      <p className=" text-xs 2xl:text-sm font-medium text-gray-900 truncate">{this.props.t("Last result")}</p>
                    </div>
                  </div>
                  <div className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500">{row.anthropometricData.find((o) => o.measureId === measure.id).value != null ? row.anthropometricData.find((o) => o.measureId === measure.id).value + measure.unit : "-"}</div>
                </div>
              </li>
              <li className="relative py-5 px-4 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
                <div className="flex justify-between space-x-3">
                  <div className="min-w-0 flex-1">
                    <div>
                      <span className="absolute inset-0" aria-hidden="true"></span>
                      <p className=" text-xs 2xl:text-sm font-medium text-gray-900 truncate">{this.props.t("Previous result")}</p>
                    </div>
                  </div>
                  <div className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500">{row.anthropometricData.find((o) => o.measureId === measure.id).previousValue != null ? row.anthropometricData.find((o) => o.measureId === measure.id).previousValue + measure.unit : "-"}</div>
                </div>
              </li>
              <li className="relative py-5 px-4 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
                <div className="flex justify-between space-x-3">
                  <div className="min-w-0 flex-1">
                    <div>
                      <span className="absolute inset-0" aria-hidden="true"></span>
                      <p className=" text-xs 2xl:text-sm font-medium text-gray-900 truncate">{this.props.t("Delta")}</p>
                    </div>
                  </div>
                  <div className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500">{row.anthropometricData.find((o) => o.measureId === measure.id).delta != null ? row.anthropometricData.find((o) => o.measureId === measure.id).delta + measure.unit : "-"}</div>
                </div>
              </li>
              <li className="relative py-5 px-4 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
                <div className="flex justify-between space-x-3">
                  <div className="min-w-0 flex-1">
                    <div>
                      <span className="absolute inset-0" aria-hidden="true"></span>
                      <p className=" text-xs 2xl:text-sm font-medium text-gray-900 truncate">{this.props.t("Var") + " (%)"}</p>
                    </div>
                  </div>
                  <div className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500">{row.anthropometricData.find((o) => o.measureId === measure.id).var ? row.anthropometricData.find(o => o.measureId === measure.id).var.toFixed(2) + "%" : "-"}</div>
                </div>
              </li>
            </ul>}><span className=" text-xs 2xl:text-sm">{row[measure.id] + measure.unit}</span></Tooltip></>
          },
          /* row &&
            row.anthropometricData &&
            row.anthropometricData.length > 0 &&
            row.anthropometricData.find((o) => o.measureId === measure.id)
              ? <Tooltip tooltipContent={<ul className="divide-y divide-gray-200 w-96">
              <li className="relative py-5 px-4 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
                <div className="flex justify-between space-x-3">
                  <div className="min-w-0 flex-1">
                    <div>
                      <span className="absolute inset-0" aria-hidden="true"></span>
                      <p className="text-sm font-medium text-gray-900 truncate">{this.props.t("Last result")}</p>
                    </div>
                  </div>
                  <div className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500">{row.anthropometricData.find((o) => o.measureId === measure.id).value != null ? row.anthropometricData.find((o) => o.measureId === measure.id).value + measure.unit : "-"}</div>
                </div>
              </li>
              <li className="relative py-5 px-4 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
                <div className="flex justify-between space-x-3">
                  <div className="min-w-0 flex-1">
                    <div>
                      <span className="absolute inset-0" aria-hidden="true"></span>
                      <p className="text-sm font-medium text-gray-900 truncate">{this.props.t("Previous result")}</p>
                    </div>
                  </div>
                  <div className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500">{row.anthropometricData.find((o) => o.measureId === measure.id).previousValue != null ? row.anthropometricData.find((o) => o.measureId === measure.id).previousValue + measure.unit : "-"}</div>
                </div>
              </li>
              <li className="relative py-5 px-4 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
                <div className="flex justify-between space-x-3">
                  <div className="min-w-0 flex-1">
                    <div>
                      <span className="absolute inset-0" aria-hidden="true"></span>
                      <p className="text-sm font-medium text-gray-900 truncate">{this.props.t("Delta")}</p>
                    </div>
                  </div>
                  <div className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500">{row.anthropometricData.find((o) => o.measureId === measure.id).delta != null ? row.anthropometricData.find((o) => o.measureId === measure.id).delta + measure.unit : "-"}</div>
                </div>
              </li>
              <li className="relative py-5 px-4 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600">
                <div className="flex justify-between space-x-3">
                  <div className="min-w-0 flex-1">
                    <div>
                      <span className="absolute inset-0" aria-hidden="true"></span>
                      <p className="text-sm font-medium text-gray-900 truncate">{this.props.t("Var") + " (%)"}</p>
                    </div>
                  </div>
                  <div className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500">{row.anthropometricData.find((o) => o.measureId === measure.id).var ? row.anthropometricData.find(o => o.measureId === measure.id).var.toFixed(2) + "%" : "-"}</div>
                </div>
              </li>
            </ul>}>  {row.anthropometricData.find((o) => o.measureId === measure.id)
                  .value + measure.unit}</Tooltip>
              : "-",
              ? row.anthropometricData.find((o) => o.measureId === measure.id)
                  .value + measure.unit
              : "-", */
          formatHeader: this.generateFormatHeader(measure.id),
        })
      })
    }

    // after columns added Add local Storage
    var selectedColumns = localStorage.getItem("TableAnthropometry_selectedColumns")
    if (selectedColumns === null || selectedColumns.length === 0){
      selectedColumns = []
      columns.forEach((column) => {
        selectedColumns.push(column.key)
        //  column.visible = true
      });
    } else selectedColumns = selectedColumns.split("|")
      columns.forEach((column) => {
       column.visible = selectedColumns.indexOf(column.key) < 0 ? false : true 
        });


    // after all the columns are added, and the data is elevated appropriately
    // we can sort data
    if (players) {
      var field = this.state.sort_field
      var direction = this.state.sort_direction
      players.sort((a, b) => {
        if (!a[field]) return 1
        if (!b[field]) return -1
        return a[field] < b[field] ? direction : -1 * direction
      })
    }



    // selected columns
    if (firstTime) {
      selectedColumns = []
      columns.forEach((column) => {
        if (column.visible) {
          selectedColumns.push(column.key)
        }
      })
      selectedColumns = selectedColumns.slice(0, 6);
      this.setState({selectedColumns});
    }

    this.setState({
      columns,
      players,
      groups,
      selectedGroups,
    })
  }

    // change groups
    onChangeSelectedGroups = (res) => {
      this.setState({ selectedGroups: res }, () => {
        // determiner ce qui n'est pas selectionner
        let unselect = []
        this.state.groups.forEach((a) => {
          if (this.state.selectedGroups.indexOf(a.key) < 0) unselect.push(a.key)
        })
        // save unselected elements to localstorage
        localStorage.setItem("TableAnthropometry_unselectedGroups", unselect.join("|"))
      })
    }

  // change columns
  onChangeSelectedColumns = (res) => {
    this.setState({ selectedColumns: res }, () => {
      // determiner ce qui est selectionne
      let select = []
      this.state.columns.forEach((a) => {
        if (this.state.selectedColumns.indexOf(a.key) > -1 ) select.push(a.key)
      })
      // save selected elements to localstorage
      localStorage.setItem("TableAnthropometry_selectedColumns", select.join("|"))
    })
  }


  render() {
    const { t } = this.props
    var players = this.state.players
    var columns = this.state.columns
    var selectedColumns = this.state.selectedColumns
    var selectedGroups = this.state.selectedGroups
    var searchText = this.state.searchText
    if (columns) {
      columns = columns.filter((o) =>
        selectedColumns.some((item) => item === o.key)
      )
    }
    if (players) {
      players = players.filter((o) =>
        selectedGroups.some((item) => item === o.group1.id)
      )
    }
    if (searchText.length > 2) {
      players = players.filter(
        (o) =>
          o.lastName.toLowerCase().includes(searchText.toLowerCase()) ||
          o.firstName.toLowerCase().includes(searchText.toLowerCase())
      )
    }
    return (
      <>
        <FiltersBar
          components={
            <>
              <FiltersTitle title={players ? players.length + " " + t("Players").toLowerCase() : ""} />
              <FilterSeparator />
              <FiltersSearch
                search={
                  <SearchInput
                    value={this.state.searchText}
                    onChange={(searchText) => this.setState({ searchText })}
                  />
                }
              />
              <FilterSeparator />
              <FiltersDropDowns
                selects={[
                  <SelectMultiple
                    options={this.state.groups}
                    keys={this.state.selectedGroups}
                    className="mx-2"
                    onChange={this.onChangeSelectedGroups}
                    fixedLabel={t("Group")}
                  />,
                ]}
              />
              <FilterSeparator />
              <FilterColumns
                columns={
                  <SelectMultiple
                    options={this.state.columns}
                    keys={this.state.selectedColumns}
                    className="mx-2"
                    // onChange={(res) => this.setState({ selectedColumns: res })}
                    onChange={this.onChangeSelectedColumns}
                    fixedLabel={t("Active columns")}
                  />
                }
              />
              <FilterSeparator />
              <ExportToCSV
                columns={columns}
                jsonData={players}
                fileName={t("Anthropometry") + ".csv"}
              />
            </>
          }
          buttons={this.props.buttons}
        />
        <TableSimple columns={columns} data={players} />
      </>
    )
  }
}

export default withTranslation()(TableAnthropometry)
