import React from "react";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import FusionCharts from "fusioncharts";
import ReactFC from "react-fusioncharts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import Radar from "@fusioncharts/powercharts/src/chart/radar";
import {
  getExternalData,
  getMatchInfos,
  getMatchsInfosByPlayer,
  loadExternalData,
  loadMatchInfos,
  loadMatchsInfosByPlayer,
} from "../../utils/indexedDB/Rugby-Competition/handleCompetitionRugby";

import Page from "../../components/page/Page";
import PlayerStatistics from "./PlayerInfoByMatch/PlayerStatistics";
import PhysicalStatistics from "./PlayerInfoByMatch/PhysicalStatistics";

ReactFC.fcRoot(FusionCharts, Radar, FusionTheme);

class PlayerInfoByMatch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      infoByPlayer: [],
      averageDataNormalizedByMatchByPlayerList: [],
    };
  }

  // get average Data normalized by match & player

  componentDidMount() {
    var id = this.props.match.params.matchId;
    var playerId = this.props.match.params.playerId;
    this.handleLoadExternalData(id, playerId);
    this.handleMatchInfosByPlayer(id, playerId);
    this.handleMatchsInfos(id);
    // this.calculateAverageStatsByColumnByMatch();
    this.setState(
      {
        infoByPlayer: this.props.infoByPlayer,
      },
      () => {
        this.prepareData(true);
      }
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      // do this to make props.infoByPlayer modifiable in prepareData
      this.setState({ infoByPlayer: this.props.infoByPlayer }, () => {
        this.prepareData(true);
      });
    }
  }

  generateFormatHeader(key) {
    return (label) => {
      return (
        <span
          className="cursor-pointer"
          onClick={() => {
            this.setState(
              {
                sort_field: key,
                sort_direction: -1 * this.state.sort_direction,
              },
              () => {
                this.prepareData();
              }
            );
          }}
        >
          <span className="flex">
            {label}
            {key ? (
              this.state.sort_field === key &&
              this.state.sort_direction === -1 ? (
                <span className="text-lg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-4"
                    viewBox="0 0 64 64"
                    aria-labelledby="title"
                    aria-describedby="desc"
                    role="img"
                    xlink="http://www.w3.org/1999/xlink"
                  >
                    <path
                      data-name="layer1"
                      d="M21 22a2.99 2.99 0 0 1 2.121.879l8.89 8.636 8.868-8.636a3 3 0 0 1 4.242 4.242L32.011 40 18.879 27.121A3 3 0 0 1 21 22z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </span>
              ) : this.state.sort_field === key ? (
                <span className="text-lg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-4"
                    viewBox="0 0 64 64"
                    aria-labelledby="title"
                    aria-describedby="desc"
                    role="img"
                    xlink="http://www.w3.org/1999/xlink"
                  >
                    <path
                      data-name="layer1"
                      d="M43 40a2.992 2.992 0 0 1-2.121-.879l-8.89-8.635-8.868 8.635a3 3 0 0 1-4.242-4.242L31.989 22l13.132 12.879A3 3 0 0 1 43 40z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </span>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </span>
        </span>
      );
    };
  }

  prepareData = (firstTime) => {
    var infoByPlayer = this.state.infoByPlayer;
    var matchInfos = this.state.matchInfos;

    //
    // names of columns of GPS data
    //

    var columnsNames = [];
    this.props.infoByPlayer &&
      this.props.infoByPlayer.forEach((playerInfos) => {
        playerInfos.gps.forEach((gPlayer) => {
          if (
            !columnsNames.find(
              (columnName) => columnName.id === gPlayer.measure.id
            )
          ) {
            columnsNames.push(gPlayer.measure);
          }
        });
      });

    //
    // find best player each column
    //

    columnsNames &&
      columnsNames.forEach((column) => {
        // find player with the max value for this column
        infoByPlayer.forEach((playerInfo) => {
          // find the real max
          let playerGps = playerInfo.gps.find(
            (columnGps) => columnGps.measure.id === column.id
          );
          // set first player to max
          if (playerGps) {
            if (!column.bestPlayerInfo)
              column.bestPlayerInfo = {
                player: playerInfo.player,
                gpsColumn: playerGps,
                positionColumn: playerInfo.position,
              };
            // check for new max
            if (column.bestPlayerInfo.gpsColumn.value < playerGps.value)
              column.bestPlayerInfo = {
                player: playerInfo.player,
                gpsColumn: playerGps,
                positionColumn: playerInfo.position,
              };
          }
        });
      });

    const columns = [
      {
        key: "category",
        label: "Category",
        visible: true,
        format: (row) => {
          return (
            <span className="py-1 px-3 rounded-full text-xs">
              {row.categoryName}
            </span>
          );
        },
      },
      {
        key: "averagePlayer",
        label: "Average Player",
        visible: true,
        format: (row) => {
          return (
            <span
              className={`py-1 px-3 rounded-full text-xs 	${
                row.playerValue < row.teamValue
                  ? "bg-red-500 "
                  : "bg-green-400 "
              }`}
            >
              {row.playerValue + " " + row.unite}
            </span>
          );
        },
      },
      {
        key: "averageTeam",
        label: "Average Team",
        visible: true,
        format: (row) => (
          <span
            className={`py-1 px-3 rounded-full text-xs 	 ${
              row.playerValue > row.teamValue ? "bg-red-500 " : "bg-green-400 "
            }`}
          >
            {row.teamValue + " " + row.unite}
          </span>
        ),
      },
    ];

    // after columns added Add local Storage
    var selectedColumns = localStorage.getItem("TableRugby_physicalStat");
    if (selectedColumns === null || selectedColumns.length === 0) {
      selectedColumns = [];
      columns.forEach((column) => {
        selectedColumns.push(column.key);
        //  column.visible = true
      });
    } else selectedColumns = selectedColumns.split("|");
    columns.forEach((column) => {
      column.visible = selectedColumns.indexOf(column.key) < 0 ? false : true;
    });

    // after all the columns are added, and the data is elevated appropriately
    // we can sort data
    if (infoByPlayer) {
      var field = this.state.sort_field;
      var direction = this.state.sort_direction;
      infoByPlayer.sort((a, b) => {
        let first =
          field === "name"
            ? a[field]
            : a.gps.find((el) => el.measure.name === field);
        let second =
          field === "name"
            ? b[field]
            : b.gps.find((el) => el.measure.name === field);
        if (!first) return 1;
        if (!second) return -1;
        return first.value < second.value ? direction : -1 * direction;
      });
    }

    // selected columns
    if (firstTime) {
      selectedColumns = [];
      columns.forEach((column) => {
        if (column.visible) {
          selectedColumns.push(column.key);
        }
      });
      selectedColumns = selectedColumns.slice(0, 6);
      this.setState({ selectedColumns });
    }

    this.setState({
      columns,
      infoByPlayer,
      selectedColumns,
      bestPlayerColumns: columnsNames,
      matchInfos,
    });
  };

  handleMatchsInfos(matchId) {
    this.setState({ getMatchsInfosFromAPi: false });
    getMatchInfos(matchId).then((match) =>
      match && !this.state.getMatchsInfosFromAPi
        ? this.setState({ matchInfos: match[0] })
        : null
    );
    if (navigator.onLine) {
      loadMatchInfos(matchId).then((match) =>
        match
          ? this.setState({ matchInfos: match, getMatchsInfosFromAPi: true })
          : null
      );
    }
  }

  handleMatchInfosByPlayer = (matchId, playerId) => {
    this.setState({ getMatchsInfosByPlayerFromAPi: false });
    getMatchsInfosByPlayer(matchId).then((infoByPlayer) =>
      infoByPlayer &&
      infoByPlayer.length &&
      !this.state.getMatchsInfosByPlayerFromAPi
        ? this.setState({
            infoByPlayer: infoByPlayer.find(
              (inf) => inf.player.id === playerId
            ),
            infosByPlayer: infoByPlayer,
          })
        : null
    );
    if (navigator.onLine) {
      loadMatchsInfosByPlayer(matchId).then((infoByPlayer) =>
        infoByPlayer && infoByPlayer.length
          ? this.setState({
              infoByPlayer: infoByPlayer.find(
                (inf) => inf.player.id === playerId
              ),
              getMatchsInfosByPlayerFromAPi: true,
              infosByPlayer: infoByPlayer,
            })
          : null
      );
    }
  };

  handleLoadExternalData = (matchId, playerId) => {
    this.setState({ getExternalDataFromAPi: false });
    getExternalData(matchId, playerId).then((externalData) => {
      return externalData &&
        externalData.length &&
        !this.state.getExternalDataFromAPi
        ? this.setState({
            averageDataNormalizedByMatchByPlayerList: externalData,
          })
        : null;
    });
    if (navigator.onLine) {
      loadExternalData(matchId, playerId).then((externalData) => {
        return externalData && externalData.length
          ? this.setState({
              averageDataNormalizedByMatchByPlayerList: externalData,
              getExternalDataFromAPi: true,
            })
          : null;
      });
    }
  };

  getCategoryList(data) {
    return data[0].gps.map((category) => ({
      id: category.measure.id,
      name: category.measure.name,
    }));
  }

  render() {
    var infoByPlayer = this.state.infoByPlayer;
    const playerInfo =
      (this.state.infoByPlayer && this.state.infoByPlayer.player) || {};
    const positionInfo =
      (this.state.infoByPlayer && this.state.infoByPlayer.position) || {};
    const matchInfos = this.state.matchInfos || {};
    const matchInfosOpponent =
      (this.state.matchInfos && this.state.matchInfos.opponent) || {};
    const matchInfosClub =
      (this.state.matchInfos && this.state.matchInfos.club) || {};

    var columns = this.state.columns;

    var individualStatistics =
      infoByPlayer && infoByPlayer.individualStatistics;

    const { t } = this.props;

    return (
      <>
        <Page
          pageTitle={
            this.state.infoByPlayer &&
            this.state.infoByPlayer.player ?
            this.state.infoByPlayer.player.displayName : "Joueur sélectionné"
          }
          backPage="/competitionRugby"
          refreshData={() => this.props.history.goBack()}
          tab={[
            {
              title: t("Player"),
              visible: true,
              data: null,
              component: () => {
                return (
                  <PlayerStatistics
                    t={t}
                    playerInfo={playerInfo}
                    positionInfo={positionInfo}
                    matchInfosClub={matchInfosClub}
                    matchInfos={matchInfos}
                    matchInfosOpponent={matchInfosOpponent}
                    infoByPlayer={infoByPlayer}
                  />
                );
              },
            },
            {
              title: t("Physical Statistics"),
              visible: true,
              data: null,
              component: () => {
                return (
                  <PhysicalStatistics
                    t={t}
                    refresh={this.props.refresh}
                    player={this.state.player}
                    individualStatistics={individualStatistics}
                    buttons={this.props.buttons}
                    columns={columns}
                    averageDataNormalizedByMatchByPlayerList={
                      this.state.averageDataNormalizedByMatchByPlayerList
                    }
                  />
                );
              },
            },
          ]}
        />
      </>
    );
  }
}

export default withTranslation()(withRouter(PlayerInfoByMatch));
