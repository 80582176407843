import { getUserManager } from "@axa-fr/react-oidc-context";

async function getUserProfile() {
  return getUserManager()
    .getUser()
    .then((data) => {
      return data.profile;
    });
}

export default getUserProfile;
