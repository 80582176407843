import React from "react";

class TextArea extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value ? this.props.value : ""
        }
        this.onInputChange = this.onInputChange.bind(this);
    }
    onInputChange(event) {
        var value = event.target.value;
        this.props.onChange(value)
        this.setState({ value })
    }
    render() {
        var { onChange, rows, className, ...newProps } = this.props;
        return (
            <textarea onChange={this.onInputChange} value={this.state.value} {...newProps} rows={rows ? rows : "3"}
            className={className ? className : "shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 text-xs 2xl:text-sm border-gray-300 rounded-md"} />
        )
    }
}

export default TextArea;