import React from "react";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";

class Loading extends React.Component {
  render() {
    return (
      <div className="holder">
        <div className="preloader">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(Loading));
