import React from "react";
import ActionButton from "../components/buttons/ActionButton";
import { withTranslation } from "react-i18next";
import TableSimple from "../components/table/TableSimple";
import moment from "moment";
import ChartPhysicalTestsByPlayer from "../charts/ChartPhysicalTestsByPlayer";
import { Select, SelectMultiple } from "../components/forms";
import FiltersSearch from "../components/filters/FiltersSearch";
import SearchInput from "../components/forms/input/SearchInput";
import FilterColumns from "../components/filters/FilterColumns";
import ExportToCSV from "../components/export/ExportToCSV";
import VideoCard from "../components/card/VideoCard";
import GenerateForm from "../utils/forms/generateForm";
import Button from "../components/buttons/Button";
import FilterSeparator from "../components/filters/FilterSeparator";
import FiltersTitle from "../components/filters/FiltersTitle";
import FiltersBar from "../components/filters/FiltersBar";
class TablePlayerPhysicalTestsData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actions: [],
      columns: [],
      searchText: "",
    };
  }

  componentDidMount() {
    this.prepareData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.prepareData();
    }
  }

  prepareData = () => {
    var data = this.props.data;
    var measures = this.props.measures;
    const columns = [
      {
        key: "date",
        visible: true,
        pinned: "left",
        label: this.props.t("Date"),
        id: "date",
        format: (row) => <span className="text-xs 2xl:text-sm">{moment(row.date).calendar()}</span>,
        filter: {
          name: "DateBetweenFilter",
        },
      },
      {
        key: "value",
        visible: true,
        pinned: "left",
        label: this.props.t("Value"),
        id: "value",
        format: (row) =>
          <div className="flex justify-between">
            <span className="text-xs 2xl:text-sm">
              {row.thresholdColor ?
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-white" style={{ backgroundColor: row.thresholdColor }}>
                  {row.value +
                    (measures.find((o) => o.id === row.measureId) &&
                      measures.find((o) => o.id === row.measureId).unit
                      ? measures.find((o) => o.id === row.measureId).unit
                      : "")}
                </span>
                : row.value +
                (measures.find((o) => o.id === row.measureId) &&
                  measures.find((o) => o.id === row.measureId).unit
                  ? measures.find((o) => o.id === row.measureId).unit
                  : "")}
            </span>
            <ActionButton
              key={row.id}
              updateForm="updatePhysique"
              deleteForm="deletePhysique"
              actions={row.video && row.video.id ? [
                <GenerateForm callback={() => { }}
                  formData={row.video}
                  formName={"deleteVideo"}
                  component={(show, text, disabled, tooltip) => <Button
                    onClick={show}
                    disabled={disabled}
                    className={disabled ? "group flex items-center px-4 py-2 text-sm text-gray-700 bg-gray-100" : "group flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"}
                    title={tooltip}
                  >
                    <svg className={disabled ? "mr-3 h-5 w-5 text-gray-400" : "mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                    </svg>
                    {text}
                  </Button>}
                />
              ] : [
                <GenerateForm callback={() => { }}
                  formData={{ physicalTestId: row.id, eventTypeId: this.props.t("Physical test"), playerId: row.playerId }}
                  formName={"createVideo"}
                  component={(show, text, disabled, tooltip) => <Button
                    onClick={show}
                    disabled={disabled}
                    className={disabled ? "group flex items-center px-4 py-2 text-sm text-gray-700 bg-gray-100" : "group flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"}
                    title={tooltip}
                  >
                    <svg className={disabled ? "mr-3 h-5 w-5 text-gray-400" : "mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                    </svg>
                    {text}
                  </Button>}
                />]}
              formData={row}
              refreshData={
                this.props.refresh
                // () =>
                //     loadAnthropometricData(this.state.id).then((anthropometricData) =>
                //         this.setState({ anthropometricData })
                //     )
              }
            />
          </div>
        ,
      },
      {
        key: "comments",
        visible: true,
        pinned: "left",
        label: this.props.t("Comments"),
        id: "comments",
        format: (row) => <span className="text-xs 2xl:text-sm">{row.comments}</span>,
      },
      {
        key: "video",
        visible: true,
        pinned: "left",
        label: this.props.t("Video"),
        id: "video",
        format: (row) => row.video && row.video.filePath && row.video.id ? <VideoCard video={row.video} handleVideos={() => { }} isVideoTemp={false} withoutTitle />
          : <span className=" text-xs 2xl:text-sm">-</span>,
      }
    ];
    var selectedColumns = [];
    columns.forEach((column) => {
      if (column.visible) {
        selectedColumns.push(column.key);
      }
    });
    this.setState({
      columns,
      data,
      measures,
      selectedColumns,
    });
  };

  render() {
    const { t } = this.props;
    var measures = this.props.measures;
    var data = this.state.data;
    var columns = this.state.columns;
    var selectedColumns = this.state.selectedColumns;
    var searchText = this.state.searchText;
    var measureId = this.props.measureId;
    var measuresList = [];
    if (!measureId && measures && measures.length > 0) {
      measureId = measures[0].id;
    }
    if (measures && measures.length > 0) {
      measures.map((measure) =>
        measuresList.push({
          key: measure.id,
          label: measure.name,
        })
      );
    }
    if (columns) {
      columns = columns.filter((o) =>
        selectedColumns.some((item) => item === o.key)
      );
    }
    if (measures && searchText.length > 2) {
      measures = measures.filter((o) =>
        o.name.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    return (
      <>
        <FiltersBar
          components={
            <>
              <FiltersTitle title={measures ? measures.length + " " + t("Results").toLowerCase() : ""} />
              <FilterSeparator />
              <Select
                value={measureId}
                options={measuresList}
                onChange={this.props.onMeasureChange}
              />
              <FilterSeparator />
              <FiltersSearch search={<SearchInput
                value={this.state.searchText}
                onChange={(searchText) => this.setState({ searchText })}
              />} />
              <FilterSeparator />
              <FilterColumns
                columns={
                  <SelectMultiple
                    options={this.state.columns}
                    keys={this.state.selectedColumns}
                    className="mx-2"
                    onChange={(res) => this.setState({ selectedColumns: res })}
                    fixedLabel={t("Active columns")}
                  />
                }
              />
              <FilterSeparator />
              <ExportToCSV columns={columns} jsonData={data} fileName={t("Physical tests") + ".csv"} />
            </>
          }
          buttons={this.props.buttons}
        />
        <ChartPhysicalTestsByPlayer
          // title={
          //   measures.find(
          //     (o) => o.id === measureId
          //   ).name
          // }
          data={data ? data.filter(
            (o) => o.measureId === measureId
          ) : null}
        />
        <TableSimple columns={columns} data={data} />
      </>
    );
  }
}

export default withTranslation()(TablePlayerPhysicalTestsData);
