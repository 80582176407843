import React, { Component } from "react"

// Render a SVG Flag by using nationalityId and className props //

class SVGEdit extends Component {
  render() {
    return (
        <svg className="w-3 h-3 my-1 2xl:w-4 2xl:h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" aria-labelledby="title" aria-describedby="desc" role="img" xlink="http://www.w3.org/1999/xlink">
            <path data-name="layer1" fill="currentColor" d="M7.9 46.1L0 64l17.9-7.9-10-10z"></path>
            <path data-name="layer2" fill="currentColor" d="M46.899 6.274l6.293-6.293 10.854 10.854-6.293 6.293z"></path>
            <path data-name="layer1" fill="currentColor" d="M10.31 42.807l33.73-33.73 10.853 10.855L21.164 53.66z"></path>
        </svg>
    )
  }
}

export default SVGEdit

/*

    

*/
