import React from "react";
import ActionButton from "../components/buttons/ActionButton";
import { withTranslation } from "react-i18next";
import { SelectMultiple } from "../components/forms";
import TableSimple from "../components/table/TableSimple";
import Label from "../components/typography/Label";
import FilterColumns from "../components/filters/FilterColumns";
import FiltersDropDowns from "../components/filters/FiltersDropDowns";
import FiltersSearch from "../components/filters/FiltersSearch";
import SearchInput from "../components/forms/input/SearchInput";
import ExportToCSV from "../components/export/ExportToCSV";
import FilterSeparator from "../components/filters/FilterSeparator";
import FiltersTitle from "../components/filters/FiltersTitle";
import FiltersBar from "../components/filters/FiltersBar";

class TableMonitoringTestsMeasures extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actions: [],
      statuses: [],
      selectedStatuses: [],
      columns: [],
      searchText: "",
    };
  }

  componentDidMount() {
    this.prepareData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.prepareData();
    }
  }

  prepareData = () => {
    var measures = this.props.measures;
    var statuses = [];
    var selectedStatuses = [];
    if (measures) {
      measures = measures.map((measure) => {
        if (statuses && !statuses.find((o) => o.key === measure.active)) {
          statuses.push({
            key: measure.active,
            label: measure.active
              ? this.props.t("Active")
              : this.props.t("Inactive"),
          });
          selectedStatuses.push(measure.active);
        }
        return measure;
      });
    }

    const columns = [
      {
        key: "order",
        visible: true,
        label: this.props.t("Order"),
        id: "order",
        width: 100,
        format: (row) => (
          <span className="font-weight-bold text-muted">{row.order}</span>
        ),
      },
      {
        key: "name",
        visible: true,
        label: this.props.t("Name"),
        id: "name",
        width: 300,
        format: (row) => (
          <span className="flex justify-between">
            <span className="text-primary font-weight-bolder mb-0">
            {row.name}
          </span>
            <ActionButton
            key={row.id}
            updateForm="updateMonitoringTestsMeasure"
            deleteForm="deleteMonitoringTestsMeasure"
            formData={row}
            refreshData={
              this.props.refresh  
            }
          />
          </span>
          
        ),
      },
      {
        key: "unit",
        visible: true,
        label: this.props.t("Unit"),
        id: "unit",
        format: (row) => (
          <span className="font-weight-bold text-muted">{row.unit}</span>
        ),
      },
      {
        key: "status",
        visible: true,
        label: this.props.t("Status"),
        format: (row) =>
          row.active ? (
            <Label type="success" text={this.props.t("Active")} />
          ) : (
            <Label type="danger" text={this.props.t("Inactive")} />
          ),
      },
    ];
    var selectedColumns = [];
    columns.forEach((column) => {
      if (column.visible) {
        selectedColumns.push(column.key);
      }
    });
    this.setState({
      columns,
      measures,
      statuses,
      selectedColumns,
      selectedStatuses,
    });
  };

  render() {
    const { t } = this.props;
    var measures = this.props.measures;
    var columns = this.state.columns;
    var selectedColumns = this.state.selectedColumns;
    var selectedStatuses = this.state.selectedStatuses;
    var searchText = this.state.searchText;
    if (columns) {
      columns = columns.filter((o) =>
        selectedColumns.some((item) => item === o.key)
      );
    }
    if (measures) {
      measures = measures.filter((o) =>
        selectedStatuses.some((item) => item === o.active)
      );
    }
    if (searchText.length > 2) {
      measures = measures.filter((o) =>
        o.name.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    return (
      <>
        <FiltersBar
          components={
            <>
              <FiltersTitle title={measures
                  ? measures.length + " " + t("Measures").toLowerCase()
                  : ""}
              />
              <FilterSeparator />
              <FiltersSearch search={<SearchInput
                value={this.state.searchText}
                onChange={(searchText) => this.setState({ searchText })}
                
              />} />
              <FilterSeparator />
              <FiltersDropDowns selects={[
                <SelectMultiple
                  options={this.state.statuses}
                  keys={this.state.selectedStatuses}
                  className="mx-2"
                  onChange={(res) => this.setState({ selectedStatuses: res })}
                  fixedLabel={t("Status")}
                />
                ]} 
              />
              <FilterSeparator />
              <FilterColumns
                columns={
                  <SelectMultiple
                    options={this.state.columns}
                    keys={this.state.selectedColumns}
                    className="mx-2"
                    onChange={(res) => this.setState({ selectedColumns: res })}
                    fixedLabel={t("Active columns")}
                  />
                }
              />
              <FilterSeparator />
              <ExportToCSV columns={columns} jsonData={measures} fileName={t("Monitoring tests measures") + ".csv"} />
            </>
          }
          buttons={this.props.buttons}
        />
        <TableSimple columns={columns} data={measures} />
      </>
    );
  }
}

export default withTranslation()(TableMonitoringTestsMeasures);
