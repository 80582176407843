import React from "react";
import { withTranslation } from "react-i18next";

class FiltersTitle extends React.Component {
  render() {
    return (
        <h3 className="text-sm 2xl:text-base font-semibold p-2 pr-2 2xl:pr-4 truncate">
            {this.props.title}
        </h3>
    );
  }
}

export default withTranslation()(FiltersTitle);
