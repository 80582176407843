import React from "react";
import ActionButton from "../components/buttons/ActionButton";
import moment from "moment";
import { withTranslation } from "react-i18next";
import { Select, SelectMultiple } from "../components/forms";
import TableSimple from "../components/table/TableSimple";
import IconRound32x32 from "../components/icons/IconRound32x32";
import { Link } from "react-router-dom";
import FiltersDropDowns from "../components/filters/FiltersDropDowns";
import FilterColumns from "../components/filters/FilterColumns";
import FiltersSearch from "../components/filters/FiltersSearch";
import SearchInput from "../components/forms/input/SearchInput";
import ExportToCSV from "../components/export/ExportToCSV";
import generateColor from "../utils/generateColor";
import FiltersTitle from "../components/filters/FiltersTitle";
import FilterSeparator from "../components/filters/FilterSeparator";
import FiltersBar from "../components/filters/FiltersBar";
// import DropdownSelector from "../components/forms/dropdown/DropdownSelector"

class TableTargets extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      actions: [],
      categories: [],
      selectedCategories: [],
      players: [],
      selectedPlayers: [],
      times: [],
      selectedTimes: [],
      columns: [],
      searchText: "",
    }
  }

  componentDidMount() {
    this.prepareData()
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.prepareData()
    }
  }

  prepareData = () => {
    var targets = this.props.targets
    var categories = []
    var selectedCategories = []
    var players = []
    var selectedPlayers = []
    var times = []
    var selectedTimes = []
    if (targets) {
      times = [
        { key: "current", label: "Objectifs en cours" },
        { key: "history", label: "Historique" },
      ]
      selectedTimes = "current"
      targets = targets.map((target) => {
        if (
          target.category &&
          categories &&
          !categories.find((o) => o.key === target.category.id)
        ) {
          categories.push({
            key: target.category.id,
            label: target.category.name,
          })
          selectedCategories.push(target.category.id)
        }
        if (
          target.player &&
          players &&
          !players.find((o) => o.key === target.player.id)
        ) {
          players.push({
            key: target.player.id,
            label:
              target.player.lastName.toUpperCase() +
              " " +
              target.player.firstName,
          })
          selectedPlayers.push(target.player.id)
        }
        return target
      })
    }

    const columns = [
      {
        key: "name",
        visible: true,
        label: this.props.t("Name"),
        id: "name",
        width: 300,
        format: (row) => (
          <span className="flex justify-between">
            <span className="text-primary font-weight-bolder mb-0 text-xs 2xl:text-sm">
              {row.name}
            </span>
            <ActionButton
              key={row.id}
              updateForm="updateObjectif"
              deleteForm="deleteObjectif"
              formData={{
                id: row.id,
                playerId: row.playerId,
                playerIdname: row.player ? row.player.displayName : null,
                name: row.name,
                categoryId: row.categoryId,
                description: row.description,
                startDate: row.startDate,
                startDatename: moment(row.startDate).format("D MMM YYYY"),
                endDate: row.endDate,
              }}
              refreshData={this.props.refresh}
            />
          </span>
        ),
      },
      {
        key: "player.name",
        visible: true,
        label: this.props.t("Player"),
        pinned: "left",
        id: "displayName",
        format: (row) => {
          return (
            <span className="flex justify-between">
              <Link
                className="text-indigo-700 font-medium pr-2 text-xs 2xl:text-sm"
                to={"/group/player/" + row.id}
              >
                <IconRound32x32 photo={row.player.photo} />
                <span className="ml-5">
                  {row.player.lastName.toUpperCase() +
                    " " +
                    row.player.firstName}
                </span>
              </Link>
            </span>
          )
        },
      },
      {
        key: "category.name",
        visible: true,
        format: (row) => 
          <span  className="inline-flex items-center px-3 py-0.5 rounded-full text-xs 2xl:text-sm font-medium bg-yellow-100 text-yellow-800" style={{color: "#FFFFFF", backgroundColor: generateColor(row.category.name)}}>
            {row.category.name}
          </span>,
        id: "category.name",
        label: this.props.t("Category"),
        width: 200,
        filter: {
          name: "SelectColumnFilter",
          type: "includes",
        },
      },
      {
        key: "endDate",
        visible: true,
        format: (row) => <span className=" text-xs 2xl:text-sm">{moment(row.endDate).calendar()}</span>,
        id: "endDate",
        label: this.props.t("End"),
        width: 200,
        filter: {
          name: "DateBetweenFilter",
        },
      },
    ]
    var selectedColumns = []
    columns.forEach((column) => {
      if (column.visible) {
        selectedColumns.push(column.key)
      }
    })
    this.setState({
      columns,
      targets,
      categories,
      players,
      times,
      selectedColumns,
      selectedCategories,
      selectedPlayers,
      selectedTimes,
    })
  }
  render() {
    const { t } = this.props
    var targets = this.props.targets
    var columns = this.state.columns
    var selectedColumns = this.state.selectedColumns
    var selectedCategories = this.state.selectedCategories
    var selectedPlayers = this.state.selectedPlayers
    var selectedTimes = this.state.selectedTimes
    var searchText = this.state.searchText
    if (columns) {
      columns = columns.filter((o) =>
        selectedColumns.some((item) => item === o.key)
      )
    }

    if (selectedTimes === "current") {
      targets = targets.filter((o) => moment(o.endDate).isAfter(moment()))
    } else if (selectedTimes === "history") {
      targets = targets.filter((o) => moment(o.endDate).isBefore(moment()))
    }
    if (targets) {
      targets = targets.sort((a, b) =>
        a === undefined ||
          a.target === undefined ||
          !a.category ||
          a.category.name === b.category.name
          ? 0
          : a.category.name < b.category.name
            ? 1
            : -1
      )
    }
    if (targets) {
      targets = targets.filter((o) =>
        selectedCategories.some((item) => o.category && item === o.category.id)
      )
    }
    if (targets) {
      targets = targets.filter((o) =>
        selectedPlayers.some((item) => o.player && item === o.player.id)
      )
    }
    if (searchText.length > 2) {
      targets = targets.filter((o) =>
        o.name.toLowerCase().includes(searchText.toLowerCase())
      )
    }
    return (
      <>
        <FiltersBar
          components={
            <>
              <FiltersTitle title={targets ? targets.length + " " + t("Targets").toLowerCase() : ""} />
              <FilterSeparator />
              <Select
                options={this.state.times}
                value={this.state.selectedTimes}
                className="mx-2"
                onChange={(res) => this.setState({ selectedTimes: res })}
                fixedLabel={t("Period")}
              />
              <FilterSeparator />
              <FiltersSearch search={<SearchInput
                value={this.state.searchText}
                onChange={(searchText) => this.setState({ searchText })}
              />} />
              <FilterSeparator />
              <FiltersDropDowns selects={[
                <SelectMultiple
                  options={this.state.players}
                  keys={this.state.selectedPlayers}
                  className="mx-2"
                  onChange={(res) => this.setState({ selectedPlayers: res })}
                  fixedLabel={t("Player")}
                />,
                <SelectMultiple
                  options={this.state.categories}
                  keys={this.state.selectedCategories}
                  className="mx-2"
                  onChange={(res) => this.setState({ selectedCategories: res })}
                  fixedLabel={t("Category")}
                />
              ]}
              />
              <FilterSeparator />
              <FilterColumns
                columns={
                  <SelectMultiple
                    options={this.state.columns}
                    keys={this.state.selectedColumns}
                    className="mx-2"
                    onChange={(res) => this.setState({ selectedColumns: res })}
                    fixedLabel={t("Active columns")}
                  />
                }
              />
              <FilterSeparator />
              <ExportToCSV columns={columns} jsonData={targets} fileName={t("Targets") + ".csv"} />
            </>
          }
          buttons={this.props.buttons}
        />
        <TableSimple columns={columns} data={targets} />
      </>
    )
  }
}

export default withTranslation()(TableTargets)
