import React from "react";
import IconRound32x32 from "./IconRound32x32";
import IconRound64x64 from "./IconRound64x64";
import { withTranslation } from "react-i18next";
import Tooltip from "./../typography/Tooltip/Tooltip";

// List Icon
// * users : [{id, name, photo}]
// length (by default 5)

class ListIcon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      length: this.props.length ? this.props.length : 5,
      names: "",
      photos: [],
      count: 0,
    };
  }

  componentDidMount() {
    var count = 0;
    var names = "";
    var photos = [];
    if (this.props.users) {
      if (typeof this.props.users === "object") {
        var users = this.props.users;
        if (users && users.length) {
          users.forEach((u) => {
            if (u && u !== {} && u.photo && photos.length < this.state.length) {
              photos.push(u);
            } else {
              count++;
              if (u === users[users.length - 1]) {
                names += u.name ? u.name : u.displayName;
              } else {
                names += (u.name ? u.name : u.displayName) + ", ";
              }
            }
          });
        }
        this.setState({ photos, count, names });
      } else {
        this.props.users.then((users) => {
          if (users && users.length) {
            users.forEach((u) => {
              if (
                u &&
                u !== {} &&
                u.photo &&
                photos.length < this.state.length
              ) {
                photos.push(u);
              } else {
                count++;
                if (u === users[users.length - 1]) {
                  names += u.name ? u.name : u.displayName;
                } else {
                  names += (u.name ? u.name : u.displayName) + ", ";
                }
              }
            });
            this.setState({ photos, count, names });
          }
        });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.users !== prevProps.users) {
      this.componentDidMount();
    }
  }

  render() {
    const { t } = this.props;
    var names = this.state.names;
    return (
      <div>
        {this.props.user && !this.state.count && !this.state.photos ? (
          t("None")
        ) : this.state.photos && this.state.photos.length > 0 ? (
          <div>
            {this.state.photos.map((p, key) => {
              switch (this.props.size) {
                case "64":
                  return (
                    <Tooltip key={key} tooltipContent={<div>{p.name}</div>}>
                      <IconRound64x64
                        key={key}
                        photo={p.photo}
                        tooltip={p.name ? p.name : p.displayName}
                      />
                    </Tooltip>
                  );
                default:
                  return (
                    <Tooltip key={key} tooltipContent={<div>{p.name}</div>}>
                      <IconRound32x32
                        key={key}
                        photo={p.photo}
                        tooltip={p.name ? p.name : p.displayName}
                      />
                    </Tooltip>
                  );
              }
            })}
            {this.state.count !== 0 ? (
              <span
                data-toggle="tooltip"
                data-placement="auto bottom"
                title={names}
              >
                {" + " + this.state.count}
              </span>
            ) : (
                ""
              )}
          </div>
        ) : this.state.count !== 0 ? (
          <span
            data-toggle="tooltip"
            data-placement="auto bottom"
            title={names}
          >
            {this.state.count}
          </span>
        ) : (
                ""
              )}
      </div>
    );
  }
}

export default withTranslation()(ListIcon);
