import React from "react";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import ChartCycleByPlayerWeeklySessionTimesByTypes from "../../../charts/ChartCycleByPlayerWeeklySessionTimesByTypes";
import SubTab from "../../../components/tab/SubTab";

class PlayerTrainingTabRugby extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.playerId,
      trainingTab: "Global",
    };
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <SubTab
          tab={[{ key: "Global", label: t("Global") }]}
          selectedTab={this.state.trainingTab}
          onChange={(key) =>
            this.setState({
              trainingTab: key,
            })
          }
        />
        <div className="row">
          <div className="col-xl-9 col-xs-12">
            {this.state.trainingTab === "Global" && this.state.weeksResults && (
              <ChartCycleByPlayerWeeklySessionTimesByTypes
                data={this.state.weeksResults}
              />
            )}
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(withRouter(PlayerTrainingTabRugby));
