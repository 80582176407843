import React from "react";
import { withTranslation } from "react-i18next";
import { SelectMultiple } from "../components/forms";
import TennisTournoisCard from "../components/card/TennisCard";
import FiltersSearch from "../components/filters/FiltersSearch";
import SearchInput from "../components/forms/input/SearchInput";
import FiltersDropDowns from "../components/filters/FiltersDropDowns";
import FilterColumns from "../components/filters/FilterColumns";
import ExportToCSV from "../components/export/ExportToCSV";
import FilterDate from "../components/filters/FilterDate";
import FilterSeparator from "../components/filters/FilterSeparator";
import FiltersTitle from "../components/filters/FiltersTitle";
import FiltersBar from "../components/filters/FiltersBar";

class ListTennisMatches extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actions: [],
      circuits: [],
      selectedCircuits: [],
      grounds: [],
      selectedGrounds: [],
      conditions: [],
      selectedConditions: [],
      columns: [],
      searchText: "",
      startDate: null,
      endDate: null,
    };
  }

  componentDidMount() {
    this.prepareData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.prepareData();
    }
  }

  prepareData = () => {
    var tournaments = this.props.tournaments
    var circuits = [];
    var selectedCircuits = [];
    var grounds = [];
    var selectedGrounds = [];
    var conditions = [];
    var selectedConditions = [];
    if (tournaments) {
      tournaments = tournaments.map((tournament) => {
        if (
          circuits &&
          !circuits.find((o) => o.key === tournament.circuit.id)
        ) {
          circuits.push({
            key: tournament.circuit.id,
            label: tournament.circuit.name,
          });
          selectedCircuits.push(tournament.circuit.id);
        }
        if (
          grounds && tournament.ground &&
          !grounds.find((o) => o.key === tournament.ground.id)
        ) {
          grounds.push({
            key: tournament.ground.id,
            label: tournament.ground.name,
          });
          selectedGrounds.push(tournament.ground.id);
        }
        if (
          conditions && tournament.conditions &&
          !conditions.find((o) => o.key === tournament.conditions.id)
        ) {
          conditions.push({
            key: tournament.conditions.id,
            label: tournament.conditions.name,
          });
          selectedConditions.push(tournament.conditions.id);
        }
        return tournament;
      });
    }

    this.setState({
      tournaments,
      circuits,
      selectedCircuits,
      grounds,
      selectedGrounds,
      conditions,
      selectedConditions
    });
  };

      // date filters changed
      onChangeDates = (startDate, endDate) => {
        if (!startDate && !endDate) return;
        var obj = {};
        if (startDate) obj.startDate = startDate;
        if (endDate) obj.endDate = endDate;
        this.setState(obj, () => {
          this.prepareData()
        });
      }
  

  render() {
    const { t } = this.props;
    var tournaments = this.props.tournaments;
    var columns = this.state.columns;
    var selectedColumns = this.state.selectedColumns;
    var selectedCircuits = this.state.selectedCircuits;
    var selectedGrounds = this.state.selectedGrounds;
    var selectedConditions = this.state.selectedConditions;
    var searchText = this.state.searchText;
    if (columns) {
      columns = columns.filter((o) =>
        selectedColumns.some((item) => item === o.key)
      );
    }
    if (tournaments) {
      tournaments = tournaments.filter((o) =>
        selectedGrounds.some((item) => o.ground && item === o.ground.id)
      );
    }
    if (tournaments) {
      tournaments = tournaments.filter((o) =>
        selectedConditions.some((item) => o.conditions && item === o.conditions.id)
      );
    }
    if (tournaments) {
      tournaments = tournaments.filter((o) =>
        selectedCircuits.some((item) => item === o.circuit.id)
      );
    }

    if (tournaments) {
      tournaments = tournaments.filter(d => {
        if (this.state.startDate && this.state.startDate > d.endDate) return false  
        if (this.state.endDate && this.state.endDate < d.startDate) return false
        return true
      });
    }

    if (searchText.length > 2) {
      tournaments = tournaments.filter((o) =>
        o.name.toLowerCase().startsWith(searchText.toLowerCase()) || 
        o.circuit.name.toLowerCase().startsWith(searchText.toLowerCase()) || 
        (o.matches && o.matches.find(r => r.opponent && r.opponent.firstName.toLowerCase().startsWith(searchText.toLowerCase()))) || 
        (o.matches && o.matches.find(r => r.opponent && r.opponent.lastName.toLowerCase().startsWith(searchText.toLowerCase()))) || 
        (o.matches && o.matches.find(r => r.opponent2 && r.opponent2.firstName.toLowerCase().startsWith(searchText.toLowerCase()))) || 
        (o.matches && o.matches.find(r => r.opponent2 && r.opponent2.lastName.toLowerCase().startsWith(searchText.toLowerCase())))
      );
    }
    return (
      <>
        <FiltersBar
          components={
            <>
              <FiltersTitle title={tournaments
                  ? tournaments.length + " " + t("Tournaments").toLowerCase()
                  : ""}
              />
              <FilterSeparator />
              <FiltersSearch search={<SearchInput
                value={this.state.searchText}
                onChange={(searchText) => this.setState({ searchText })}
                
              />} />
              <span className="ml-5 mr-5 w-1 border-l"></span>
              <FiltersDropDowns selects={[
                <SelectMultiple
                  options={this.state.circuits}
                  keys={this.state.selectedCircuits}
                  className="mx-2"
                  onChange={(res) => this.setState({ selectedCircuits: res })}
                  fixedLabel={t("Circuit")}
                />,
                <SelectMultiple
                  options={this.state.grounds}
                  keys={this.state.selectedGrounds}
                  className="mx-2"
                  onChange={(res) => this.setState({ selectedGrounds: res })}
                  fixedLabel={t("Ground")}
                />,
                <SelectMultiple
                  options={this.state.conditions}
                  keys={this.state.selectedConditions}
                  className="mx-2"
                  onChange={(res) => this.setState({ selectedConditions: res })}
                  fixedLabel={t("Conditions")}
                />
                ]} 
              />
              <FilterSeparator />
              <FilterColumns
                columns={
                  <SelectMultiple
                    options={this.state.columns}
                    keys={this.state.selectedColumns}
                    className="mx-2"
                    onChange={(res) => this.setState({ selectedColumns: res })}
                    fixedLabel={t("Active columns")}
                  />
                }
              />
              <FilterSeparator />
              <ExportToCSV columns={columns} jsonData={tournaments} fileName={t("Matches") + ".csv"} />
              <FilterSeparator />
              <FilterDate onChange={this.onChangeDates} />
              <FilterSeparator />
            </>
          }
          buttons={this.props.buttons}
        />
        {tournaments
          ? tournaments.map((tournoi, key) => (
              <TennisTournoisCard
                key={key}
                player={this.props.player}
                tournoi={tournoi}
              />
            ))
          : ""}
      </>
    );
  }
}

export default withTranslation()(ListTennisMatches);
