import React from "react";

class DisplayData1col extends React.Component {
  render() {
    const data = this.props.data; // Value of the item (mandatory)
    return (
      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-1 py-5 px-3">
        {data.map((item, key) => (
          <div className="sm:col-span-1" key={key}>
            <dt className="text-xs 2xl:text-sm font-medium text-gray-500">{item.key}</dt>
            <dd className="mt-1 text-sm 2xl:text-base text-gray-900 font-bold">{item.value}</dd>
          </div>
        ))}
      </dl>
    );
  }
}

export default DisplayData1col;
