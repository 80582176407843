import React from "react";
import { withTranslation } from "react-i18next";
import {
    loadStaffs,
    loadPlayers,
    getPlayers,
    getStaffs,
} from "../../../indexedDB/users/handleUser";
import { loadAttendeesByEvent, loadOrganizersByEvent } from "../../../indexedDB/events/handleEvents";
import DropdownTableSelectorUsers from "../../../../components/table/DropdownTableSelectorUsers";
import { post, del } from "../../../requests/index";
import Button from "../../../../components/buttons/Button";
import getUserProfile from "../../../auth/getUserProfile";
import * as FormsComponent from "../../../../components/forms/index";
import * as Drawer from "../../../../components/drawer";
import cogoToast from "cogo-toast";
// to work needs this.props.method and this.props.formData.id

class StepInvitation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sendEmail: false,
            sendSMS: false,
            sendNotif: false,
            attendees: this.props.formData && this.props.formData.attendees ? this.props.formData.attendees : [],
            organizers: this.props.formData && this.props.formData.organizers ? this.props.formData.organizers : [],
            loadingAtt: true,
            loadingOrg: true,
            loading: true,
        }
    }

    componentDidMount() {
        if (this.props.method && this.props.formData && this.props.formData.id) {
            if (this.props.formData.typeId === "Match tennis" && this.props.method === "POST") {
                var promises = [];
                getUserProfile().then((profile) => {
                    var invit = {
                        staffId: profile.sub,
                        eventId: this.props.formData.id,
                        toBeNotifiedByEmail: false,
                        toBeNotifiedBySMS: false,
                        toBeNotifiedByMobile: false
                    }
                    promises.push(post("schedule", "organizers", invit));
                });
                if (this.props.formData.playerId) {
                    var invitP = {
                        playerId: this.props.formData.playerId,
                        eventId: this.props.formData.id,
                        toBeNotifiedByEmail: false,
                        toBeNotifiedBySMS: false,
                        toBeNotifiedByMobile: false
                    }
                    promises.push(post("schedule", "attendees", invitP));
                }
                if (promises) {
                    Promise.all(promises).then(res => {
                        if (res) {
                            var errors = [];
                            res.forEach(r => {
                                if (!r.ok) {
                                    errors.push(r.status + " - " + r.url);
                                }
                            })
                            if (errors && errors.length) {
                                this.setState({ errors, loading: false });
                            } else {
                                this.setState({ loading: false });
                                this.props.nextStep(this.props.formData);
                            }
                        } else {
                            throw new Error("500");
                        }
                    }).catch(err => {
                        this.setState({ errors: [err.toString()], loading: false });
                    })
                }
            } else {
                getPlayers().then((players) => players ? this.setState({ players }) : null);
                getStaffs().then((staffs) => staffs ? this.setState({ staffs }) : null);
                loadStaffs().then((staffs) => staffs ? this.setState({ staffs }) : null);
                loadPlayers().then((players) => players ? this.setState({ players }) : null);

                if (this.props.method === 'POST') {
                    var organizers = this.props.formData && this.props.formData.organizers ? this.props.formData.organizers : [];
                    getUserProfile().then((profile) => {
                        if (!organizers.find(o => o === profile.sub)) {
                            organizers.push(profile.sub);
                        }
                        if (organizers && organizers.length) {
                            var promisesOrganizers = [];
                            organizers.forEach(o => {
                                var invit = {
                                    staffId: o,
                                    eventId: this.props.formData.id,
                                    toBeNotifiedByEmail: false,
                                    toBeNotifiedBySMS: false,
                                    toBeNotifiedByMobile: false
                                }
                                promisesOrganizers.push(post("schedule", "organizers", invit));
                            });
                            Promise.all(promisesOrganizers).then(() => {
                                loadOrganizersByEvent(this.props.formData.id).then(organizers => organizers ? this.setState({ organizers: organizers.map(o => o.staffId), alreadyOrganizers: organizers, loadingOrg: false, loading: false }) : null);
                            })
                        } else {
                            this.setState({ organizers, alreadyOrganizers: [] });
                        }
                    }).catch(error => {
                        this.setState({ organizers, alreadyOrganizers: [] });
                    });

                    // create invitation for preselected organizers and attendees
                    var attendees = this.props.formData && this.props.formData.attendees ? this.props.formData.attendees : [];
                    if (attendees && attendees.length) {
                        var promisesAttendees = [];
                        attendees.forEach(o => {
                            var invit = {
                                playerId: o,
                                eventId: this.props.formData.id,
                                toBeNotifiedByEmail: false,
                                toBeNotifiedBySMS: false,
                                toBeNotifiedByMobile: false
                            }
                            promisesAttendees.push(post("schedule", "attendees", invit));
                        });
                        Promise.all(promisesAttendees).then(() => {
                            this.props.callback();
                            loadAttendeesByEvent(this.props.formData.id).then(attendees => attendees ? this.setState({ attendees: attendees.map(o => o.playerId), alreadyAttendees: attendees, loadingAtt: false, loading: false }) : null);
                        })
                    } else {
                        this.setState({ attendees, alreadyAttendees: [] });
                    }
                }
                if (this.props.method === 'PATCH') {
                    loadOrganizersByEvent(this.props.formData.id).then(organizers => organizers ? this.setState({ organizers: organizers.map(o => o.staffId), alreadyOrganizers: organizers, loadingOrg: false, loading: this.state.loadingAtt }) : null);
                    loadAttendeesByEvent(this.props.formData.id).then(attendees => attendees ? this.setState({ attendees: attendees.map(o => o.playerId), alreadyAttendees: attendees, loadingAtt: false, loading: this.state.loadingOrg }) : null);
                }
            }

        } else {
            this.props.nextStep(this.props.formData)
        }
    }

    handleSubmit() {
        this.setState({ loading: true });
        // submit
        if (this.props.formData && this.props.formData.id) {
            var event = this.props.formData;
            var promises = [];
            if (this.state.alreadyAttendees) {
                this.state.players.forEach(o => {
                    if (this.state.alreadyAttendees.map(a => a.playerId).includes(o.id) && !this.state.attendees.includes(o.id)) {
                        promises.push(del("schedule", "attendees", this.state.alreadyAttendees.find(a => a.playerId === o.id)));
                    }
                    if (!this.state.alreadyAttendees.map(a => a.playerId).includes(o.id) && this.state.attendees.includes(o.id)) {
                        var invit = {
                            playerId: o.id,
                            eventId: event.id,
                            toBeNotifiedByEmail: this.state.sendEmail,
                            toBeNotifiedBySMS: this.state.sendSMS,
                            toBeNotifiedByMobile: this.state.sendNotif
                        }
                        promises.push(post("schedule", "attendees", invit));
                    }
                });
            }
            if (this.state.alreadyOrganizers) {
                this.state.staffs.forEach(o => {
                    if (this.state.alreadyOrganizers.map(a => a.staffId).includes(o.id) && !this.state.organizers.includes(o.id)) {
                        promises.push(del("schedule", "organizers", this.state.alreadyOrganizers.find(a => a.staffId === o.id)));
                    }
                    if (!this.state.alreadyOrganizers.map(a => a.staffId).includes(o.id) && this.state.organizers.includes(o.id)) {
                        var invit = {
                            staffId: o.id,
                            eventId: event.id,
                            toBeNotifiedByEmail: this.state.sendEmail,
                            toBeNotifiedBySMS: this.state.sendSMS,
                            toBeNotifiedByMobile: this.state.sendNotif
                        }
                        promises.push(post("schedule", "organizers", invit));
                    }
                });
            }
            Promise.all(promises).then(res => {
                if (res) {
                    var errors = [];
                    res.forEach(r => {
                        if (!r.ok) {
                            errors.push(r.status + " - " + r.url);
                        }
                    })
                    if (errors && errors.length) {
                        this.setState({ errors, loading: false });
                    } else {
                        cogoToast.success(this.props.t("Invitation created"), {position: "bottom-right"});
                        this.setState({ loading: false });
                        this.props.nextStep(this.props.formData);
                    }
                } else {
                    throw new Error("500");
                }
            }).catch(err => {
                this.setState({ errors: [err.toString()], loading: false });
            })
        }
    }

    render() {
        const { t } = this.props;
        return (
            <>
                <Drawer.Header hide={this.props.hide}>
                    {t("Invitations")}
                </Drawer.Header>
                <Drawer.Body>
                    <>
                        {this.state.errors ? <div className="alert alert-danger">
                            {t("Error")}
                            {this.state.errors.map((error, key) => {
                                return <div key={key}>{error}</div>;
                            })}
                        </div> : (
                                ""
                            )}
                        <DropdownTableSelectorUsers
                            label={t("Players")}
                            data={this.state.players}
                            selected={
                                this.state.attendees ? this.state.attendees : null
                            }
                            onChange={(list) => {
                                var attendees = this.state.attendees;
                                attendees = list;
                                this.setState({ attendees });
                            }}
                        />
                        <DropdownTableSelectorUsers
                            label={t("Staffs")}
                            data={this.state.staffs}
                            selected={
                                this.state.organizers
                                    ? this.state.organizers
                                    : null
                            }
                            onChange={(list) => {
                                var organizers = this.state.organizers;
                                organizers = list;
                                this.setState({ organizers });
                            }}
                        />
                        <div className="row mt-2 mb-2">
                            <div className="col-md-12 col-lg-6 col-xl-4">
                                <span className="mr-3">
                                    <FormsComponent.Checkbox
                                        value={this.state.sendEmail}
                                        onChange={() =>
                                            this.setState({ sendEmail: !this.state.sendEmail })
                                        }
                                    />
                                </span>
                                {t("Send convocation by Email")}
                            </div>
                            <div className="col-md-12 col-lg-6 col-xl-4">
                                <span className="mr-3">
                                    <FormsComponent.Checkbox
                                        value={this.state.sendSMS}
                                        onChange={() =>
                                            this.setState({ sendSMS: !this.state.sendSMS })
                                        }
                                    />
                                </span>
                                {t("Send convocation by SMS")}
                            </div>
                            <div className="col-md-12 col-lg-6 col-xl-4">
                                <span className="mr-3">
                                    <FormsComponent.Checkbox
                                        value={this.state.sendNotif}
                                        onChange={() =>
                                            this.setState({ sendNotif: !this.state.sendNotif })
                                        }
                                    />
                                </span>
                                {t("Send convocation by Mobile notification")}
                            </div>
                        </div>
                    </>
                </Drawer.Body>
                <Drawer.Footer>
                    <>
                        <Button
                            loading={this.state.loading}
                            onClick={(event) => {
                                this.handleSubmit(event);
                            }}
                        >
                            {t("Next")}
                        </Button>
                    </>
                </Drawer.Footer>
            </>
        )
    }
}

export default withTranslation()(StepInvitation);