import db from "./db";
import get from "../../requests/get";
import post from "../../requests/post";

async function loadCareer(id) {
  return get("education", "careers", id).then((res) => {
    if (res) {
      db.careers.put(res);
    }
    return res;
  });
}

function getCareer(id) {
  return db.careers.get(id);
}

async function loadCurriculumsPursuedByPlayer(playerId) {
  return get("education", "curriculumsPursuedByPlayer", playerId).then(
    (res) => {
      if (res) {
        db.curriculumsPursued.bulkPut(res);
      }
      return res;
    }
  );
}

function loadCurriculumsPursuedByPlayers(ids) {
  return post("education", "curriculumsPursuedByPlayers", {playersIds : ids}).then(res => {return res.json()}).then(
    (res) => {
      if (res) {
        db.curriculumsPursued.bulkPut(res);
      }
      return res;
    }
  );
}

async function getCurriculumsPursuedByPlayer(playerId) {
  return db.curriculumsPursued.where({ playerId: playerId }).toArray();
}

async function getCurriculumsPursuedByCurriculum(curriculumId) {
  return db.curriculumsPursued.where({ curriculumId: curriculumId }).toArray();
}

async function loadCurriculumsPursuedByCurriculum(curriculumId) {
  return get("education", "curriculumsPursuedByCurriculum", curriculumId).then(
    (res) => {
      if (res) {
        db.curriculumsPursued.bulkPut(res);
      }
      return res;
    }
  );
}

async function loadCurriculumsPursuedBySchool(schoolId) {
  return get("education", "curriculumsPursuedBySchool", schoolId).then(
    (res) => {
      if (res) {
        db.curriculumsPursued.bulkPut(res);
      }
      return res;
    }
  );
}

async function loadScholarshipDocumentsByPlayer(playerId) {
  return get("education", "scholarshipDocumentsByPlayer", playerId).then(
    (res) => {
      if (res) {
        db.scholarshipDocuments.bulkPut(res);
      }
      return res;
    }
  );
}

async function getScholarshipReportsByPlayer(playerId) {
  return db.scholarshipReports.where({ playerId: playerId }).toArray();
}

async function loadScholarshipReportsByPlayer(playerId) {
  return get("education", "scholarshipReportsByPlayer", playerId).then(
    (res) => {
      if (res) {
        db.scholarshipReports.bulkPut(res);
      }
      return res;
    }
  );
}

async function getScholarshipDocumentsByPlayer(playerId) {
  return db.scholarshipDocuments.where({ playerId: playerId }).toArray();
}

async function loadCurriculums() {
  return get("education", "curriculums").then((res) => {
    if (res) {
      db.curriculums.bulkPut(res);
    }
    return res;
  });
}

async function getCurriculums() {
  return db.curriculums.toArray();
}

async function loadCurriculumsBySchool(schoolId) {
  return get("education", "curriculumsBySchool", schoolId).then((res) => {
    if (res) {
      db.curriculums.bulkPut(res);
    }
    return res;
  });
}

async function getCurriculumsBySchool(schoolId) {
  return db.curriculums.where({ schoolId: schoolId }).toArray();
}

async function loadCurriculum(id) {
  return get("education", "curriculums", id).then((res) => {
    if (res) {
      db.curriculums.put(res);
    }
    return res;
  });
}

async function getCurriculum(id) {
  return db.curriculums.get(id);
}

async function loadCurriculumsTypes() {
  return get("education", "curriculumsTypes").then((res) => {
    if (res) {
      db.curriculumsTypes.bulkPut(res);
    }
    return res;
  });
}

async function getCurriculumsTypes() {
  return db.curriculumsTypes.toArray();
}

async function loadSchools() {
  return get("education", "schools").then((res) => {
    if (res) {
      db.schools.bulkPut(res);
    }
    return res;
  });
}

async function getSchool(id) {
  return db.schools.get(id);
}

async function loadSchool(id) {
  return get("education", "schools", id).then((res) => {
    if (res) {
      db.schools.put(res);
    }
    return res;
  });
}

async function getSchools() {
  return db.schools.toArray();
}

export {
  loadCareer,
  getCareer,
  loadCurriculumsPursuedByPlayer,
  loadCurriculumsPursuedByPlayers,
  getCurriculumsPursuedByPlayer,
  loadCurriculumsPursuedByCurriculum,
  getCurriculumsPursuedByCurriculum,
  loadCurriculumsPursuedBySchool,
  loadScholarshipDocumentsByPlayer,
  getScholarshipDocumentsByPlayer,
  loadScholarshipReportsByPlayer,
  getScholarshipReportsByPlayer,
  loadSchools,
  getSchools,
  loadSchool,
  getSchool,
  loadCurriculums,
  getCurriculums,
  loadCurriculum,
  getCurriculum,
  loadCurriculumsTypes,
  getCurriculumsTypes,
  loadCurriculumsBySchool,
  getCurriculumsBySchool,
};
