import React from "react";
import ActionButton from "../components/buttons/ActionButton";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { SelectMultiple } from "../components/forms";
import TableSimple from "../components/table/TableSimple";
import moment from "moment";
import IconRound32x32 from "../components/icons/IconRound32x32";
import FiltersDropDowns from "../components/filters/FiltersDropDowns";
import SearchInput from "../components/forms/input/SearchInput";
import FiltersSearch from "../components/filters/FiltersSearch";
import FilterSeparator from "../components/filters/FilterSeparator";
import FiltersTitle from "../components/filters/FiltersTitle";
import FiltersBar from "../components/filters/FiltersBar";

class TableSchoolCurriculumsPursued extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actions: [],
      curriculums: [],
      selectedCurriculums: [],
      columns: [],
      searchText: "",
    };
  }

  componentDidMount() {
    this.prepareData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.prepareData();
    }
  }

  prepareData = () => {
    var curriculumsPursued = this.props.curriculumsPursued;
    var curriculums = [];
    var selectedCurriculums = [];

    if (curriculumsPursued) {
      curriculumsPursued = curriculumsPursued.map((curriculumPursued) => {
        if (
          curriculums &&
          !curriculums.find((o) => o.key === curriculumPursued.curriculum.id)
        ) {
          curriculums.push({
            key: curriculumPursued.curriculum.id,
            label: curriculumPursued.curriculum.name,
          });
          selectedCurriculums.push(curriculumPursued.curriculum.id);
        }
        return curriculumPursued;
      });
    }

    const columns = [
      {
        key: "name",
        visible: true,
        pinned: "left",
        label: this.props.t("Name"),
        id: "name",
        format: (row) => (
          <Link
            className="text-indigo-700 font-medium text-xs 2xl:text-sm"
            to={"/administration/curriculum/" + row.curriculumId}
          >
            {row.curriculum.name}
          </Link>
        ),
      },
      {
        key: "displayName",
        visible: true,
        label: this.props.t("Player"),
        pinned: "left",
        id: "displayName",
        format: (row) => {
          return (
            <span className="flex justify-between">
              <Link
                className="text-indigo-700 font-medium pr-2 text-xs 2xl:text-sm"
                to={"/group/player/" + row.player.id}
              >
                <IconRound32x32 photo={row.player.photo} />
                <span className="ml-5">
                  {row.player.lastName.toUpperCase() + " " + row.player.firstName}
                </span>
              </Link>
            </span>
          );
        },
      },
      {
        key: "startDate",
        visible: true,
        pinned: "left",
        label: this.props.t("Start"),
        id: "startDate",
        format: (row) => <span className=" text-xs 2xl:text-sm">{moment(row.startDate).calendar()}</span>,
      },
      {
        key: "endDate",
        visible: true,
        pinned: "left",
        label: this.props.t("End"),
        id: "endDate",
        format: (row) => <span className=" text-xs 2xl:text-sm">{moment(row.endDate).calendar()}</span>,
      },
      {
        key: "actions",
        visible: true,
        pinned: "left",
        label: "",
        format: (row) => (
          <ActionButton
            key={row.id}
            updateForm="updateCurriculumPursued"
            deleteForm="deleteCurriculumPursued"
            formData={row}
            refreshData={this.props.refresh}
          />
        ),
      },
    ];
    var selectedColumns = [];
    columns.forEach((column) => {
      if (column.visible) {
        selectedColumns.push(column.key);
      }
    });
    this.setState({
      columns,
      curriculumsPursued,
      curriculums,
      selectedColumns,
      selectedCurriculums,
    });
  };

  render() {
    const { t } = this.props;
    var curriculumsPursued = this.props.curriculumsPursued;
    var columns = this.state.columns;
    var selectedColumns = this.state.selectedColumns;
    var selectedCurriculums = this.state.selectedCurriculums;
    var searchText = this.state.searchText;
    if (columns) {
      columns = columns.filter((o) =>
        selectedColumns.some((item) => item === o.key)
      );
    }
    if (curriculumsPursued) {
      curriculumsPursued = curriculumsPursued.filter((o) =>
        selectedCurriculums.some((item) => item === o.curriculum.id)
      );
    }
    if (searchText.length > 2) {
      curriculumsPursued = curriculumsPursued.filter((o) =>
        o.name.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    return (
      <>
        <FiltersBar
          components={
            <>
              <FiltersTitle title={curriculumsPursued
                  ? curriculumsPursued.length +
                    " " +
                    t("Curriculums pursued").toLowerCase()
                  : ""}
              />
              <FilterSeparator />
              <FiltersSearch search={
                  <SearchInput
                    value={this.state.searchText}
                    onChange={(searchText) => this.setState({ searchText })}
                  />
                }
              />
              <FilterSeparator />
              <FiltersDropDowns selects={[
                <SelectMultiple
                  options={this.state.curriculums}
                  keys={this.state.selectedCurriculums}
                  className="mx-2"
                  onChange={(res) => this.setState({ selectedCurriculums: res })}
                  fixedLabel={t("Type")}
                />
                ]} 
              />
            </>
          }
        />
        <TableSimple columns={columns} data={curriculumsPursued} />
      </>
    );
  }
}

export default withTranslation()(TableSchoolCurriculumsPursued);
