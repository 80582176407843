import React from "react";
import handleCondition from "./handleCondition";
import * as FormsComponent from "../../components/forms/index";
import ChooseOpponent from "../../components/forms/specificComponent/chooseOpponent";
import ChooseTournament from "../../components/forms/specificComponent/chooseTournament";
import EditableTableTennisSetsResults from "../../components/forms/specificComponent/editableTableTennisSetsResults";
import moment from "moment";

import { get } from "../requests/index";
// import GenerateForm from "./generateForm";

const APItoIndexedDB = require("../indexedDB/APIToIndexedDB.json");

class Question extends React.Component {
  constructor(props) {
    super(props);
    this.callbackSubForm = callbackSubForm.bind(this);
  }
  render() {
    return (
      <div className="mt-2 2xl:mt-3">
        {this.props.title ? (
          <label className="block text-xs 2xl:text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 mt-3">
            {this.props.title} {this.props.required ? "*" : ""}
          </label>
        ) : (
            ""
          )}
        <div className={this.props.error ? "mt-1 relative rounded-md shadow-sm" : "mt-1"}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

function handleComponentSelection(question, errorQ) {
  const { t } = this.props;
  switch (question.widget) {
    case "TextInput":
      return (
        <FormsComponent.Input
          id={question.label}
          type="text"
          placeholder={t("Response")}
          value={
            this.state.formData && this.state.formData[question.label]
              ? this.state.formData[question.label]
              : ""
          }
          onChange={(value) => {
            var formData = this.state.formData;
            formData[question.label] = value;
            this.setState({ formData });
          }}
          className={errorQ ? "block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 text-xs 2xl:text-sm rounded-md" : "shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-xs 2xl:text-sm border-gray-300 rounded-md"}
        />
      );
    case "NumberPicker":
      return (
        <FormsComponent.NumberPicker
          id={question.label}
          type="text"
          value={
            this.state.formData && this.state.formData[question.label]
              ? this.state.formData[question.label]
              : 0
          }
          onChange={(value) => {
            var formData = this.state.formData;
            formData[question.label] = value;
            this.setState({ formData });
          }}
          className={errorQ ? "block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 text-xs 2xl:text-sm rounded-md" : "shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-xs 2xl:text-sm border-gray-300 rounded-md"}
        />
      );
    case "FileUpload":
      return (
        <FormsComponent.FileUpload
          id={question.label}
          placeholder={t("Response")}
          onChange={(value) => {
            var formData = this.state.formData;
            formData[question.label] = value;
            this.setState({ formData });
          }}
        />
      );
    case "DatePicker":
      return (
        <FormsComponent.DatePicker
          id={question.label}
          placeholder={t("Response")}
          value={
            this.state.formData && this.state.formData[question.label]
              ? this.state.formData[question.label]
              : ""
          }
          onChange={(value) => {
            var formData = this.state.formData;
            formData[question.label] = value;
            this.setState({ formData });
          }}
          className=""
        />
      );
    case "DateTimePicker":
      return (
        <FormsComponent.DateTimePicker
          id={question.label}
          placeholder={t("Response")}
          value={
            this.state.formData && this.state.formData[question.label]
              ? this.state.formData[question.label]
              : ""
          }
          onChange={(value) => {
            var formData = this.state.formData;
            formData[question.label] = value;
            this.setState({ formData });
          }}
          className=""
        />
      );
    case "TimePicker":
      return (
        <FormsComponent.TimePicker
          id={question.label}
          placeholder={t("Response")}
          value={
            this.state.formData && this.state.formData[question.label]
              ? this.state.formData[question.label]
              : ""
          }
          onChange={(value) => {
            var formData = this.state.formData;
            formData[question.label] = value;
            this.setState({ formData });
          }}
          className=""
        />
      );
    // case "Radio":
    //   return (
    //     <>
    //       {/* {question.options.map((option, optionKey) => <div key={optionKey}><FormControlLabel
    //                         checked={this.state.formData && this.state.formData[question.id] ? this.state.formData[question.id] === option.value : false}
    //                         value={option.value}
    //                         control={<Radio />}
    //                         label={option.label}
    //                         onChange={() => {
    //                             var formData = this.state.formData;
    //                             formData[question.id] = option.value;
    //                             this.setState({ formData });
    //                         }} /></div>
    //                     )} */}
    //     </>
    //   );
    // case "Checkbox":
    //   return (
    //     <>
    //       {/* <FormGroup>
    //                         {question.options.map((option, optionKey) => <FormControlLabel key={optionKey}
    //                             control={<Checkbox checked={this.state.formData && this.state.formData[question.id] ? this.state.formData[question.id].includes(option.value) : false}
    //                                 onChange={(event) => {
    //                                     var formData = this.state.formData;
    //                                     if (formData[question.id]) {
    //                                         if (formData[question.id] && formData[question.id].includes(option.value)) {
    //                                             formData[question.id] = formData[question.id].filter(function (o) { return o !== option.value; });
    //                                         } else {
    //                                             formData[question.id].push(option.value);
    //                                         }
    //                                     } else {
    //                                         formData[question.id] = [];
    //                                         formData[question.id].push(option.value);
    //                                     }

    //                                     this.setState({ formData });
    //                                 }} name={option.label} />}
    //                             label={option.label}
    //                         />)}
    //                     </FormGroup> */}
    //     </>
    //   );
    case "Textarea":
      return (
        <FormsComponent.TextArea
          id={question.label}
          placeholder={t("Response")}
          rows="3"
          value={
            this.state.formData && this.state.formData[question.label]
              ? this.state.formData[question.label]
              : ""
          }
          onChange={(value) => {
            var formData = this.state.formData;
            formData[question.label] = value;
            this.setState({ formData });
          }}
          className={errorQ ? "max-w-lg block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md" :
            "max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"}
        />
      );
    case "Switch":
      return (
        <FormsComponent.Switch
          value={
            this.state.formData && this.state.formData[question.label]
              ? this.state.formData[question.label]
              : false
          }
          onChange={(value) => {
            var formData = this.state.formData;
            formData[question.label] = value;
            this.setState({ formData });
          }}
        />
      );
    case "Slider":
      return (
        <FormsComponent.Slider
          id={question.label}
          min={question.min}
          max={question.max}
          value={
            this.state.formData &&
              (this.state.formData[question.label] ||
                this.state.formData[question.label] === 0)
              ? this.state.formData[question.label]
              : Math.trunc((question.min + question.max) / 2)
          }
          onChange={(newValue) => {
            var formData = this.state.formData;
            formData[question.label] = newValue;
            this.setState({ formData });
          }}
          {...question.componentParameter}
        />
      );
    case "Select":
      return (
        <div>
          <FormsComponent.Select
            key={question.label}
            id={question.label}
            disabled={
              question.dependsOnAnotherField &&
              !this.verifyDependance(this.state.formData, question)
            }
            value={
              this.state.formData && this.state.formData[question.label]
                ? typeof this.state.formData[question.label] !== "object"
                  ? this.state.formData[question.label]
                  : this.state.formData[question.label].id
                : null
            }
            onChange={(value) => {
              var formData = this.state.formData;
              formData[question.label] = value;
              this.setState({ formData });
            }}
            options={
              question.listChoices
                ? question.listChoices.map((choice) => {
                  return { key: choice.id, label: t(choice.name) };
                })
                : []
            }
          />
        </div>
      );
    case "SelectMultiple":
      return (
        <FormsComponent.SelectMultiple
          key={question.label}
          id={question.label}
          value={
            this.state.formData && this.state.formData[question.label]
              ? this.state.formData[question.label]
              : null
          }
          keys={
            this.state.formData && this.state.formData[question.label]
              ? this.state.formData[question.label]
              : null
          }
          onChange={(value) => {
            var formData = this.state.formData;
            formData[question.label] = value;
            this.setState({ formData });
          }}
          options={
            question.listChoices
              ? question.listChoices.map((choice) => {
                return { key: choice.id, label: choice.name };
              })
              : []
          }
        />
      );
    case "Selected":
      return (
        <div>
          <div>
            {this.state.formData && this.state.formData[question.label]
              ? typeof this.state.formData[question.label] !== "object"
                ? this.state.formData[question.label + "name"]
                : this.state.formData[question.label + "name"]
              : null}
          </div>
        </div>
      );
    case "ChooseOpponent":
      return (
        <ChooseOpponent
          key={question.label}
          id={question.label}
          value={
            this.state.formData && this.state.formData[question.label]
              ? this.state.formData[question.label]
              : null
          }
          onChange={(value) => {
            var formData = this.state.formData;
            formData[question.label] = value;
            this.setState({ formData });
          }}
        />
      );
    case "ChooseTournament":
      return <ChooseTournament
        formData={this.state.formData}
        key={question.label}
        id={question.label}
        value={
          this.state.formData && this.state.formData[question.label]
            ? this.state.formData[question.label]
            : null
        }
        onChange={(value) => {
          var formData = this.state.formData;
          formData[question.label] = value.id;
          formData["conditionsId"] = value.condition;
          formData["groundId"] = value.grounds;
          this.setState({ formData });
        }}
      />;
    case "TennisSetsResults":
      return (
        <EditableTableTennisSetsResults
          key={question.label}
          id={question.label}
          formData={this.state.formData}
          onChange={(formData) => this.setState({ formData })}
        />
      );
    default:
      return <></>;
  }
}

function getQuestionRender(question, key) {
  let handleComponent = handleComponentSelection.bind(this);
  let getQRender = getQuestionRender.bind(this);

  var errorQ = this.state.errors &&
    this.state.errors.inner &&
    this.state.errors.inner.find((o) => o.path === question.label)
    ? this.state.errors.inner.find((o) => o.path === question.label).message
    : null;
  if (handleCondition(this.state.questions, this.state.formData, question)) {
    return (
      <Question
        key={key}
        {...question}
        error={errorQ}
        title={this.props.t(question.title)}
      >
        {handleComponent(question, errorQ)}
        {question.subQuestions
          ? question.subQuestions.map((sb, sbKey) => {
            return getQRender(sb, sbKey);
          })
          : ""}
      </Question>
    );
  } else {
    return "";
  }
}

function callbackSubForm(formData, question) {

}

function verifyDependance(formData, question) {
  var dependanceValid = true;
  if (question.dependsOnAnotherField) {
    question.dependsOnAnotherField.forEach((d) => {
      if (!formData[d]) {
        dependanceValid = false;
      }
    });
  }
  return dependanceValid;
}

function initResponse(question) {
  switch (question.widget) {
    case "Slider":
      return Math.trunc((question.min + question.max) / 2);
    case "Switch":
      return false;
    case "DatePicker":
      return moment().format("YYYY-MM-DD");
    default:
      return null;
  }
}

async function loadChoices(question, params) {
  var { db, table } = APItoIndexedDB[question.APImodule][question.APIfeature];
  if (db && table) {
    return import("../indexedDB/" + db + "/db").then((db) => {
      if (db.default[table] && db.default[table].toArray()) {
        return db.default[table].toArray().then((res) => {
          if (res && res !== [] && res.length > 0) {
            return res;
          } else {
            return get(question.APImodule, question.APIfeature).then((res) => {
              if (res && res.id) {
                db.default[table].bulkPut(res);
              }
              return res;
            });
          }
        });
      } else {
        return [];
      }
    }).catch(() =>{
      return get(question.APImodule, question.APIfeature).then((res) => {
        if (res && res.id) {
          db.default[table].bulkPut(res);
        }
        return res;
      });
    });
  } else {
    return [];
  }
}

export {
  initResponse,
  handleComponentSelection,
  verifyDependance,
  getQuestionRender,
  loadChoices,
};
