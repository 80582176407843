import React from "react";
import { withTranslation } from "react-i18next";

class FiltersColumns extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  render() {
    return (
        this.state.visible ?
            <div className="flex">
                <button onClick={() => this.setState({visible: !this.state.visible})} className="w-4 2xl:w-5 mr-3" style={{color: "#4B5563"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" aria-labelledby="title" aria-describedby="desc" role="img" xlink="http://www.w3.org/1999/xlink">
                        <path data-name="layer2" fill="none" stroke="#202020" strokeMiterlimit="10" strokeWidth="2" d="M62 18H2m20 0v44m20-44v44" strokeLinejoin="round" strokeLinecap="round"></path>
                        <path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10" strokeWidth="2" d="M2 2h60v60H2z" strokeLinejoin="round" strokeLinecap="round"></path>
                    </svg>
                </button>
                {this.props.columns ? this.props.columns : ""}
                <button onClick={() => this.setState({visible: !this.state.visible})} className="w-4 2xl:w-5 mr-3" style={{color: "#4B5563"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" aria-labelledby="title"
                        aria-describedby="desc" role="img" xlink="http://www.w3.org/1999/xlink">
                        <path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10" strokeWidth="2" d="M41.999 20.002l-22 22m22 0L20 20" strokeLinejoin="round" strokeLinecap="round"></path>
                    </svg>
                </button>
            </div>
            : 
            <div className="flex groupTourStep4">
                <button onClick={() => this.setState({visible: !this.state.visible})}  className="w-4 2xl:w-5 text-gray-600" style={{color: "#4B5563"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" aria-labelledby="title" aria-describedby="desc" role="img" xlink="http://www.w3.org/1999/xlink">
                        <path data-name="layer2" fill="none" stroke="#202020" strokeMiterlimit="10" strokeWidth="2" d="M62 18H2m20 0v44m20-44v44" strokeLinejoin="round" strokeLinecap="round"></path>
                        <path data-name="layer1" fill="none" stroke="#202020" strokeMiterlimit="10" strokeWidth="2" d="M2 2h60v60H2z" strokeLinejoin="round" strokeLinecap="round"></path>
                    </svg>
                </button>
            </div>
    );
  }
}

export default withTranslation()(FiltersColumns);
