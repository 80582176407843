import React from "react";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import Card from "../../../components/card/Card";
import moment from "moment";
import SubTab from "../../../components/tab/SubTab";
// import TablePlayerProgrammation from "../../../tables/TablePlayerProgrammation";
import TournamentsByPlayer from "../../competitionRugby/TournamentsByPlayer";
import MatchsByPlayer from "../../competitionRugby/MatchsByPlayer";

class PlayerCompetitionRugbyTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.playerId,
      player: null,
      competitionTab: "Tournaments",
      year: parseInt(moment().format("YYYY")),
      month: parseInt(moment().format("M")),
      loadRanking: true,
    };
  }
  componentDidMount() {
    // get id from url
    // var id = this.props.playerId;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.player !== this.props.player) {
      this.setState({ player: this.props.player });
    }
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <SubTab
          tab={[
            { key: "Tournaments", label: t("Tournaments") },
            { key: "Matches", label: t("Matches") },
          ]}
          selectedTab={this.state.competitionTab}
          onChange={(key) => {
            if (key !== this.state.competitionTab) {
              this.setState({
                competitionTab: key,
              });
            }
          }}
        />
        {this.state.competitionTab === "Tournaments" && (
          <div>
            <Card>
              <TournamentsByPlayer playerId={this.state.id} />
              {/* <TablePlayerProgrammation /> */}
            </Card>
          </div>
        )}
        {this.state.competitionTab === "Matches" && (
          <div>
            <Card>
              <MatchsByPlayer playerId={this.state.id} />
              {/* <TablePlayerProgrammation /> */}
            </Card>
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation()(withRouter(PlayerCompetitionRugbyTab));
