import React from "react";
import { AzureMP } from "react-azure-mp";

class VideoPlayer extends React.Component {
  render() {
    return (
      <div style={{ maxWidth: "85%", paddingLeft: "15%" }}>
        <AzureMP
          skin="amp-flush"
          src={[
            { src: encodeURI(this.props.video.filePath), type: "application/vnd.ms-sstr+xml" },
          ]}
        />
      </div>
    );
  }
}

export default VideoPlayer;
