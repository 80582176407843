import React, { Component } from "react";
import Page from "../../components/page/Page";
import Loading from "../../components/loaders/Loading";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import TableTournamentGeneric from "../../tables/TableTournamentGeneric";
import TableMatchsResults from "../../tables/TableMatchsResults";

class CompetitionPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      t,
      competitions,
      matchsInfos,
      savedSelectedColumnsTournaments,
      savedSelectedColumnsMatchs,
      sportTypePath,
    } = this.props;
    return (
      <Page
        tab={[
          {
            title: t("Tournaments"),
            visible: true,
            data: competitions,
            component: () => {
              return this.state.loading ? (
                <div style={{ height: "80vh", width: "100%" }}>
                  <Loading />
                </div>
              ) : (
                <>
                  {this.state.loading ? (
                    <div style={{ height: "80vh", width: "100%" }}>
                      <Loading />
                    </div>
                  ) : (
                    <>
                      <TableTournamentGeneric
                        competitions={competitions}
                        savedSelectedColumns={savedSelectedColumnsTournaments}
                      />
                    </>
                  )}
                </>
              );
            },
          },
          {
            title: t("Results"),
            visible: true,
            data: this.state.results,
            component: () => {
              return this.state.loading &&
                !matchsInfos &&
                matchsInfos.length === 0 ? (
                <div style={{ height: "80vh", width: "100%" }}>
                  <Loading />
                </div>
              ) : (
                <TableMatchsResults
                  matchInfos={matchsInfos}
                  savedSelectedColumns={savedSelectedColumnsMatchs}
                  sportTypePath={sportTypePath}
                  seasons={this.props.seasons}
                />
              );
            },
          },
        ]}
      />
    );
  }
}

export default withTranslation()(withRouter(CompetitionPage));
