import React from "react";

class CheckboxLabel extends React.Component {
    constructor(props) {
        super(props);
        this.onCheckboxChange = this.onCheckboxChange.bind(this);
    }
    onCheckboxChange() {
        var value = !this.props.value
        this.props.onChange(value);
    }
    render() {
        var { value, color, title, id } = this.props;
        if (!color) {
            color = "rgb(84, 83, 139)";
        }
        return (
            <div className="ml-2 my-1.5 flex items-center">
                <input onClick={this.onCheckboxChange} checked={value} readOnly type="checkbox" id={id ? id : "checkbox-label"} name={id ? id : "checkbox-label"} className="focus:ring-indigo-500 checked:bg-blue-600 h-4 w-4 text-indigo-600 border-gray-300 rounded-full" style={value ? { backgroundColor: color, color: "rgb(255, 255, 255)" } : { borderColor: "#a19f9d", borderWidth: "1px" }} />
                <div htmlFor={id ? id : "checkbox-label"} className="ml-2 block text-sm font-medium text-gray-600">{title}</div>
            </div>
        )
    }
}

export default CheckboxLabel;