import React from "react";
import * as Form from "../forms/index";

// TAB
// * onChange => (value)
// tab = {key, label}
// length
class Tab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: "",
    };
    this.changeTab = this.changeTab.bind(this);
  }

  componentDidMount() {
    if (
      this.props.tab &&
      this.props.selectedTab &&
      this.props.tab.find((t) => t.label === this.props.selectedTab.title)
    ) {
      var key = this.props.tab.find(
        (t) => t.label === this.props.selectedTab.title
      ).key;
      this.setState({ key });
    } else {
      this.setState({ key: this.props.tab ? this.props.tab[0].key : "" });
    }
  }

  changeTab(key) {
    this.props.onChange(key);
    this.setState({ key });
  }
  render() {
    var buttons = [];
    var selector = [];
    var length = this.props.length ? this.props.length : 8;
    if (this.props.tab && this.props.tab.length) {
      var tabs = this.props.tab;
      for (var i = 0; i < tabs.length; i++) {
        var tab = tabs[i];
        if (buttons.length < length) {
          buttons.push(tab);
        } else {
          tab.key = i;
          selector.push(tab);
        }
      }
    }

    return buttons !== [] ? (
      <>
        <div className="flex h-full text-gray-600 dark:text-gray-400 hidden sm:block groupTourStep1 playerTourStep1">
          <nav className="pl-0 mx-auto -mb-px flex space-x-8 2xl:space-x-8" aria-label="Tabs">
            {buttons.map((ta, i) => {
              return (
                <div
                  key={i}
                  className={
                    i === this.state.key
                      ? `cursor-pointer border-indigo-700 text-indigo-700 whitespace-nowrap py-3 2xl:py-4 px-1 border-b-2 font-medium text-base 2xl:text-lg myTab${ta.key}`
                      : `cursor-pointer border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-3 2xl:py-4 px-1 border-b-2 font-medium text-base 2xl:text-lg myTab${ta.key}`
                  }
                  onClick={() => this.changeTab(ta.key)}
                >
                  {ta.label}
                </div>
              );
            })}
            {selector !== [] && selector.length ? (
              <Form.Select
                key="tab-extension"
                options={selector}
                onChange={(value) => this.changeTab(value)}
              />
            ) : null}
          </nav>
        </div>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          <select
            id="tabs"
            name="tabs"
            value={this.state.key}
            readOnly
            className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
          >
            {buttons.map((ta, i) => {
              return (
                <option key={i} value={ta.key} onClick={() => this.changeTab(ta.key)}>
                  {ta.label}
                </option>
              );
            })}
          </select>
        </div>
      </>
    ) : null;
  }
}

export default Tab;
