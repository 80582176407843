import db from "./db";
import get from "../../requests/get";
import post from "../../requests/post";

async function loadSessionTimesByEvent(eventId) {
  return get("monitoring", "sessionsTimeByEvent", eventId).then((res) => {
    if (res) {
      db.sessionTimes.bulkPut(res);
    }
    return res;
  });
}

function getSessionTimesByEvent(eventId) {
  if (db.sessionTimes.toArray()) {
    return db.sessionTimes.where({ eventId: eventId }).toArray();
  }
}

async function loadRpeDataByEvent(eventId) {
  return get("monitoring", "rpeDataByEvent", eventId).then((res) => {
    if (res) {
      db.rpeData.bulkPut(res);
    }
    return res;
  });
}

function getRpeDataByPlayer(playerId) {
  if (db.rpeData.toArray()) {
    return db.rpeData.where({ playerId: playerId }).toArray();
  }
}

async function loadRpeDataByPlayer(playerId) {
  return get("monitoring", "rpeDataByPlayer", playerId).then((res) => {
    if (res) {
      db.rpeData.bulkPut(res);
    }
    return res;
  });
}

async function loadRpeDataByPlayerAndDates(playerId, startDate, endDate) {
  return get("monitoring", "rpeDataByPlayerAndDates", playerId, startDate, endDate).then((res) => {
    if (res) {
      db.rpeData.bulkPut(res);
    }
    return res;
  });
}

function loadRpeDataByPlayersAndDates(ids, startDate, endDate) {
  return post("monitoring", "rpeDataByPlayersAndDates", {playersIds : ids, startDate: startDate, endDate: endDate}).then(res => { if (res) {return res.json()}}).then(
    (res) => {
      if (res) {
        res = res.map((tar) => {
          tar.kind = tar.playerId;
          return tar;
        });
        db.rpeData.bulkPut(res);
      }
      return res;
    }
  );
}

function getRpeDataByEvent(eventId) {
  if (db.rpeData.toArray()) {
    return db.rpeData.where({ eventId: eventId }).toArray();
  }
}

async function loadGpsMeasures() {
  return get("monitoring", "gpsMeasures").then((res) => {
    if (res) {
      db.gpsMeasures.bulkPut(
        res.sort((a, b) =>
          a.order === b.order ? 0 : a.order > b.order ? 1 : -1
        )
      );
    }
    return res;
  });
}

function getGpsMeasures() {
  return db.gpsMeasures.toArray();
}

async function loadGpsDataByEvent(eventId) {
  return get("monitoring", "gpsDataByEvent", eventId).then((res) => {
    if (res) {
      db.gpsData.bulkPut(res);
    }
    return res;
  });
}

function getGpsDataByEvent(eventId) {
  if (db.gpsData) {
    return db.gpsData.where({ eventId: eventId }).toArray();
  }
}

function getGpsDataByPlayer(playerId) {
  if (db.gpsData.toArray()) {
    return db.gpsData.where({ playerId: playerId }).toArray();
  }
}

async function loadGpsDataByPlayer(playerId) {
  return get("monitoring", "gpsDataByPlayer", playerId).then((res) => {
    if (res) {
      db.gpsData.bulkPut(res);
    }
    return res;
  });
}

async function loadGpsDataByPlayerAndDates(playerId, startDate, endDate) {
  return get("monitoring", "gpsDataByPlayerAndDates", playerId, startDate, endDate).then((res) => {
    if (res) {
      db.gpsData.bulkPut(res);
    }
    return res;
  });
}

function loadGpsDataByPlayersAndDates(ids, startDate, endDate) {
  return post("monitoring", "gpsDataByPlayersAndDates", {playersIds : ids, startDate: startDate, endDate: endDate}).then(res => { if (res) {return res.json()}}).then(
    (res) => {
      if (res) {
        res = res.map((tar) => {
          tar.kind = tar.playerId;
          return tar;
        });
        db.gpsData.bulkPut(res);
      }
      return res;
    }
  );
}

async function loadMonitoringTestsDataCurrent(playerId) {
  return get("monitoring", "monitoringTestsDataCurrentByPlayer", playerId).then(
    (res) => {
      if (res) {
        res = res.map((tar) => {
          tar.kind = playerId;
          return tar;
        });
        db.monitoringTestsDataCurrent.bulkPut(res);
      }
      return res;
    }
  );
}

function loadMonitoringTestsDataCurrentByPlayers(ids) {
  return post("monitoring", "monitoringTestsDataCurrentByPlayers", {playersIds : ids}).then(res => { if (res) {return res.json()}}).then(
    (res) => {
      if (res) {
        res = res.map((tar) => {
          tar.kind = tar.playerId;
          return tar;
        });
        db.monitoringTestsDataCurrent.bulkPut(res);
      }
      return res;
    }
  );
}

function getMonitoringTestsDataCurrent(playerId) {
  if (db.monitoringTestsDataCurrent.toArray()) {
    return db.monitoringTestsDataCurrent
      .where({ kind: playerId })
      .toArray()
      .then((data) => {
        return data;
      });
  }
}

async function loadMonitoringTestsData(playerId) {
  return get("monitoring", "monitoringTestsDataByPlayer", playerId).then(
    (res) => {
      if (res) {
        res = res.map((tar) => {
          tar.kind = playerId;
          return tar;
        });
        db.monitoringTestsData.bulkPut(res);
      }
      return res;
    }
  );
}

function getMonitoringTestsData(playerId) {
  if (db.monitoringTestsData) {
    return db.monitoringTestsData
      .where({ kind: playerId })
      .toArray()
      .then((data) => {
        return data;
      });
  }
}

async function loadMonitoringTestsMeasures() {
  return get("monitoring", "monitoringTestsMeasures").then((res) => {
    if (res) {
      res.map(a => {
        a.activeFilterDislpay = a.active ? "active" : "inactive"
        return a
      })
      db.monitoringTestsMeasures.bulkPut(
        res.sort((a, b) =>
          a.order === b.order ? 0 : a.order > b.order ? 1 : -1
        )
      );
    }
    return res;
  });
}

function getMonitoringTestsMeasures() {
  if (db.monitoringTestsMeasures.toArray()) {
    return db.monitoringTestsMeasures.toArray();
  }
}

async function loadMonitoringTestsMeasuresActive() {
  return get("monitoring", "monitoringTestsMeasuresActive").then((res) => {
    if (res) {
      db.monitoringTestsMeasuresActive.bulkPut(
        res.sort((a, b) =>
          a.lastName === b.order ? 0 : a.order > b.order ? 1 : -1
        )
      );
    }
    return res;
  });
}

function getMonitoringTestsMeasuresActive() {
  if (db.monitoringTestsMeasuresActive.toArray()) {
    return db.monitoringTestsMeasuresActive.toArray();
  }
}

export {
  loadSessionTimesByEvent,
  getSessionTimesByEvent,
  loadRpeDataByEvent,
  getRpeDataByEvent,
  getRpeDataByPlayer,
  loadRpeDataByPlayer,
  loadRpeDataByPlayerAndDates,
  loadRpeDataByPlayersAndDates,
  loadGpsDataByEvent,
  getGpsDataByPlayer,
  loadGpsDataByPlayer,
  loadGpsDataByPlayerAndDates,
  loadGpsDataByPlayersAndDates,
  getGpsDataByEvent,
  loadGpsMeasures,
  getGpsMeasures,
  loadMonitoringTestsDataCurrent,
  loadMonitoringTestsDataCurrentByPlayers,
  loadMonitoringTestsData,
  loadMonitoringTestsMeasures,
  loadMonitoringTestsMeasuresActive,
  getMonitoringTestsDataCurrent,
  getMonitoringTestsData,
  getMonitoringTestsMeasures,
  getMonitoringTestsMeasuresActive,
};
