import React from "react"
import Flag from "../icons/Flag"

class HeroTennisMatch extends React.Component {
  render() {
    return (
      <div className="flex h-80 m-6">
        <div className="w-1/4 flex justify-center pt-20">
          <div className="team team-1 align-bottom">
            <div className="avatar w-3/4">
              <picture>
                {this.props.player != null ? (
                  <img src={this.props.player.photo} alt="" />
                ) : (
                  ""
                )}
              </picture>
            </div>
          </div>
        </div>
        <div className="w-1/2">
          <div className="match-container">
            <div
              className="grid grid-cols-1 text-center"
              style={{ marginBottom: "20px" }}
            >
              <span className="text-gray-500 text-sm pt-3">
                {this.props.match != null && this.props.match.tournament
                  ? this.props.match.tournament.circuit.name +
                    " " +
                    this.props.match.tournament.classification +
                    " - " +
                    this.props.match.tournament.ground.name +
                    " " +
                    this.props.match.tournament.conditions.name
                  : ""}
              </span>
              <span className="font-semibold text-2xl p-2">
                {this.props.match != null && this.props.match.tournament != null
                  ? this.props.match.tournament.name
                  : ""}{" "}
              </span>
              <span className="text-gray-500 text-md p-1">
                {this.props.match != null
                  ? this.props.match.mode +
                    (this.props.match.tour
                      ? " - " + this.props.match.tour.name
                      : "")
                  : ""}
              </span>
            </div>

            <div className="scorecards-container">
              <div
                id="match-centre-scorecard-vue"
                className="vue-portal-target "
              >
                <div className="match-centre-scorecard">
                  {this.props.match != null ? (
                    <div className="match-centre-scorecard__card score-card carousel-index-undefined -fixed-height -has-breaker-row score-card--dark">
                      <div className="score-card__container">
                        <div className="teams-wrapper">
                          <table className="w-full">
                            <tbody>
                              <tr className="h-16">
                                <td className="w-3/4 pl-3">
                                  <div
                                    className={
                                      this.props.match.result != null &&
                                      (this.props.match.result === "V" ||
                                        this.props.match.result === "Vab")
                                        ? "text-indigo-700 font-semibold flex"
                                        : "text-gray-400 flex"
                                    }
                                  >
                                    <div>
                                      <Flag
                                        className="w-6 mr-3 pt-1"
                                        nationalityId={
                                          this.props.player.nationalityId
                                        }
                                      />
                                    </div>
                                    <div>
                                      {this.props.player.firstName +
                                        " " +
                                        this.props.player.lastName}
                                    </div>
                                    {this.props.match.partner != null
                                      ? " / " +
                                        (
                                          <Flag
                                            className="w-6 mr-3 pt-1"
                                            nationalityId={
                                              this.props.partner.nationality
                                            }
                                          />
                                        ) +
                                        this.props.match.partner.firstName +
                                        " " +
                                        this.props.match.partner.lastName
                                      : ""}
                                    <span className="player-seed">&nbsp;</span>
                                  </div>
                                </td>
                                <td>
                                  {this.props.match.result != null &&
                                  (this.props.match.result === "V" ||
                                    this.props.match.result === "Vab") ? (
                                    <i className="ki ki-bold-check mr-3 text-success"></i>
                                  ) : (
                                    ""
                                  )}
                                  {this.props.match.result != null &&
                                  (this.props.match.result === "D" ||
                                    this.props.match.result === "Dab") ? (
                                    <i className="ki ki-bold-close mr-3 text-danger"></i>
                                  ) : (
                                    ""
                                  )}
                                </td>
                                {this.props.match.resultSet1 != null ? (
                                  <td
                                    className={
                                      this.props.match.resultSet1Adv != null &&
                                      this.props.match.resultSet1 >
                                        this.props.match.resultSet1Adv
                                        ? "font-semibold text-gray-900"
                                        : "text-gray-400"
                                    }
                                  >
                                    <span>{this.props.match.resultSet1}</span>
                                  </td>
                                ) : (
                                  ""
                                )}
                                {this.props.match.resultSet2 != null ? (
                                  <td
                                    className={
                                      this.props.match.resultSet2Adv != null &&
                                      this.props.match.resultSet2 >
                                        this.props.match.resultSet2Adv
                                        ? "font-semibold text-gray-900"
                                        : "text-gray-400"
                                    }
                                  >
                                    <span>{this.props.match.resultSet2}</span>
                                  </td>
                                ) : (
                                  ""
                                )}
                                {this.props.match.resultSet2 != null ? (
                                  <td
                                    className={
                                      this.props.match.resultSet3Adv != null &&
                                      this.props.match.resultSet3 >
                                        this.props.match.resultSet3Adv
                                        ? "font-semibold text-gray-900"
                                        : "text-gray-400"
                                    }
                                  >
                                    <span>{this.props.match.resultSet3}</span>
                                  </td>
                                ) : (
                                  ""
                                )}
                                {this.props.match.resultSet2 != null ? (
                                  <td
                                    className={
                                      this.props.match.resultSet4Adv != null &&
                                      this.props.match.resultSet4 >
                                        this.props.match.resultSet4Adv
                                        ? "font-semibold text-gray-900"
                                        : "text-gray-400"
                                    }
                                  >
                                    <span>{this.props.match.resultSet4}</span>
                                  </td>
                                ) : (
                                  ""
                                )}
                                {this.props.match.resultSet2 != null ? (
                                  <td
                                    className={
                                      this.props.match.resultSet5Adv != null &&
                                      this.props.match.resultSet5 >
                                        this.props.match.resultSet5Adv
                                        ? "font-semibold text-gray-900"
                                        : "text-gray-400"
                                    }
                                  >
                                    <span>{this.props.match.resultSet5}</span>
                                  </td>
                                ) : (
                                  ""
                                )}
                              </tr>
                              <tr className="h-16 border-t">
                                <td className="w-3/4 pl-3">
                                  <div
                                    className={
                                      this.props.match.result != null &&
                                      (this.props.match.result === "D" ||
                                        this.props.match.result === "Dab")
                                        ? "text-gray-800 font-semibold flex"
                                        : "text-gray-400 flex"
                                    }
                                  >
                                    {this.props.match.opponent ? (
                                      <>
                                        <Flag
                                          className="w-6 mr-3 pt-1"
                                          nationalityId={
                                            this.props.match.opponent
                                              .nationality
                                          }
                                        />
                                        
                                        {" " +
                                          this.props.match.opponent.firstName +
                                          " " +
                                          this.props.match.opponent.lastName}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    {this.props.match.opponent2 ? (
                                      <>
                                        {" / " +
                                          this.props.match.opponent2.firstName +
                                          " " +
                                          this.props.match.opponent2.lastName}
                                        +
                                        <Flag
                                          className="w-6 mr-3 pt-1"
                                          nationalityId={
                                            this.props.match.opponent2
                                              .nationality
                                          }
                                        />
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    <span className="player-seed">&nbsp;</span>
                                  </div>
                                </td>
                                <td className="status"></td>
                                {this.props.match.resultSet1Adv != null ? (
                                  <td
                                    className={
                                      this.props.match.resultSet1 != null &&
                                      this.props.match.resultSet1Adv >
                                        this.props.match.resultSet1
                                        ? "font-semibold text-gray-900"
                                        : "text-gray-400"
                                    }
                                  >
                                    <span>
                                      {this.props.match.resultSet1Adv}
                                    </span>
                                  </td>
                                ) : (
                                  ""
                                )}
                                {this.props.match.resultSet2Adv != null ? (
                                  <td
                                    className={
                                      this.props.match.resultSet2 != null &&
                                      this.props.match.resultSet2Adv >
                                        this.props.match.resultSet2
                                        ? "font-semibold text-gray-900"
                                        : "text-gray-400"
                                    }
                                  >
                                    <span>
                                      {this.props.match.resultSet2Adv}
                                    </span>
                                  </td>
                                ) : (
                                  ""
                                )}
                                {this.props.match.resultSet3Adv != null ? (
                                  <td
                                    className={
                                      this.props.match.resultSet3 != null &&
                                      this.props.match.resultSet3Adv >
                                        this.props.match.resultSet3
                                        ? "font-semibold text-gray-900"
                                        : "text-gray-400"
                                    }
                                  >
                                    <span>
                                      {this.props.match.resultSet3Adv}
                                    </span>
                                  </td>
                                ) : (
                                  ""
                                )}
                                {this.props.match.resultSet4Adv != null ? (
                                  <td
                                    className={
                                      this.props.match.resultSet4 != null &&
                                      this.props.match.resultSet4Adv >
                                        this.props.match.resultSet4
                                        ? "font-semibold text-gray-900"
                                        : "text-gray-400"
                                    }
                                  >
                                    <span>
                                      {this.props.match.resultSet4Adv}
                                    </span>
                                  </td>
                                ) : (
                                  ""
                                )}
                                {this.props.match.resultSet5Adv != null ? (
                                  <td
                                    className={
                                      this.props.match.resultSet5 != null &&
                                      this.props.match.resultSet5Adv >
                                        this.props.match.resultSet5
                                        ? "font-semibold text-gray-900"
                                        : "text-gray-400"
                                    }
                                  >
                                    <span>
                                      {this.props.match.resultSet5Adv}
                                    </span>
                                  </td>
                                ) : (
                                  ""
                                )}
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="mt-6 text-center">
                    <time className="match-centre-scorecard__time">
                      <span className="match-centre-scorecard__time-value">
                        {this.props.match != null &&
                        this.props.match.duration != null
                          ? this.props.match.duration + " min"
                          : ""}
                      </span>
                    </time>
                  </div>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-1/4 flex justify-center pt-20">
          <div className="team team-1 align-bottom">
            <div className="avatar w-3/4">
              <picture>
                {this.props.match != null &&
                this.props.match.opponent != null ? (
                  <img src={this.props.match.opponent.photo} alt="" />
                ) : (
                  ""
                )}
              </picture>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default HeroTennisMatch
