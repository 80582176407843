import React from "react";
import ActionButton from "../components/buttons/ActionButton";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { SelectMultiple } from "../components/forms";
import TableSimple from "../components/table/TableSimple";
import moment from "moment";
import SearchInput from "../components/forms/input/SearchInput";
import FiltersSearch from "../components/filters/FiltersSearch";
import FiltersDropDowns from "../components/filters/FiltersDropDowns";
import FilterColumns from "../components/filters/FilterColumns";
import ExportToCSV from "../components/export/ExportToCSV";
import FilterSeparator from "../components/filters/FilterSeparator";
import FiltersTitle from "../components/filters/FiltersTitle";
import FiltersBar from "../components/filters/FiltersBar";

class TableWeeks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actions: [],
      categories: [],
      selectedCategories: [],
      columns: [],
      searchText: "",
    };
  }

  componentDidMount() {
    this.prepareData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.prepareData();
    }
  }

  prepareData = () => {
    var weeks = this.props.weeks;
    var categories = [];
    var selectedCategories = [];

    //  chercher les années a déselectionner du localstorage
    var unselectedCategories = localStorage.getItem("TableWeeksByPlayer_unselectedCategories")
    if (unselectedCategories) unselectedCategories = unselectedCategories.split("|")
    
    
    if (weeks) {
      weeks = weeks.map((week) => {
        if (categories && !categories.find((o) => o.key === week.category.id)) {
          categories.push({
            key: week.category.id,
            label: week.category.name,
          })
          // only add to selected if not unselected
        if (
          !unselectedCategories ||
          unselectedCategories.indexOf(week.category.id.toString()) < 0
        ) 
          selectedCategories.push(week.category.id);
        }
        return week;
      });
    }

    const columns = [
      {
        key: "name",
        visible: true,
        label: this.props.t("Name"),
        pinned: "left",
        id: "name",
        format: (row) => {
          return (
            <div className="flex justify-between">
              <Link
                className="text-indigo-700 font-medium text-xs 2xl:text-sm"
                to={"/training/weekByPlayer/" + row.id}
              >
                {row.name}
              </Link>
              <ActionButton
            key={row.id}
            updateForm="updateTennisWeek"
            deleteForm="deleteTennisWeek"
            formData={row}
            refreshData={this.props.refresh}
          />
            </div>
          );
        },
      },
      {
        key: "category",
        visible: true,
        label: this.props.t("Category"),
        pinned: "left",
        id: "category",
        format: (row) => {
          return <><span className=" text-xs 2xl:text-sm">{this.props.t(row.category.name)}</span></>;
        },
      },
      {
        key: "startDate",
        visible: true,
        label: this.props.t("Start date"),
        pinned: "left",
        id: "startDate",
        format: (row) => {
          return <><span className=" text-xs 2xl:text-sm">{moment(row.startDate).calendar()}</span></>;
        },
      },
      {
        key: "endDate",
        visible: true,
        label: this.props.t("End date"),
        pinned: "left",
        id: "endDate",
        format: (row) => {
          return <><span className=" text-xs 2xl:text-sm">{moment(row.endDate).calendar()}</span></>;
        },
      },
    ];

    var selectedColumns = localStorage.getItem("TableWeeksByPlayer_selectedColumns")
    if (selectedColumns === null || selectedColumns.length === 0)  selectedColumns = ["name", "category", "startDate", "endDate"]
    else selectedColumns = selectedColumns.split("|")
    columns.forEach((column) => {
      column.visible = selectedColumns.indexOf(column.key) < 0 ? false : true 
      
    });
    this.setState({
      columns,
      weeks,
      categories,
      selectedColumns,
      selectedCategories,
    });
  };

  // change categories
  onChangeSelectedCategories = (res) => {
    this.setState({ selectedCategories: res }, () => {
      // determiner ce qui n'est pas selectionner
      let unselect = []
      this.state.categories.forEach((a) => {
        if (this.state.selectedCategories.indexOf(a.key) < 0) unselect.push(a.key)
      })
      // save unselected elements to localstorage
      localStorage.setItem("TableWeeksByPlayer_unselectedCategories", unselect.join("|"))
    })
  }

  // change columns
  onChangeSelectedColumns = (res) => {
      this.setState({ selectedColumns: res }, () => {
        // determiner ce qui est selectionne
        let select = []
        this.state.columns.forEach((a) => {
          if (this.state.selectedColumns.indexOf(a.key) > -1 ) select.push(a.key)
        })
        // save selected elements to localstorage
        localStorage.setItem("TableWeeksByPlayer_selectedColumns", select.join("|"))
      })
    }  

  render() {
    const { t } = this.props;
    var weeks = this.props.weeks;
    var columns = this.state.columns;
    var selectedColumns = this.state.selectedColumns;
    var selectedCategories = this.state.selectedCategories;
    var searchText = this.state.searchText;
    if (columns) {
      columns = columns.filter((o) =>
        selectedColumns.some((item) => item === o.key)
      );
    }
    if (weeks) {
      weeks = weeks.filter((o) =>
        selectedCategories.some((item) => item === o.category.id)
      );
    }
    if (searchText.length > 2) {
      weeks = weeks.filter((o) =>
        o.name.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    return (
      <>
        <FiltersBar
          components={
            <>
              <FiltersTitle title={weeks ? weeks.length + " " + t("Weeks").toLowerCase() : ""} />
              <FilterSeparator />
              <FiltersSearch search={<SearchInput
                value={this.state.searchText}
                onChange={(searchText) => this.setState({ searchText })}
              />} />
              <FilterSeparator />
              <FiltersDropDowns selects={[
                <SelectMultiple
                  options={this.state.categories}
                  keys={this.state.selectedCategories}
                  className="mx-2"
                  onChange={this.onChangeSelectedCategories}
                />
                ]} 
              />
              <FilterSeparator />
              <FilterColumns
                columns={
                  <SelectMultiple
                    options={this.state.columns}
                    keys={this.state.selectedColumns}
                    className="mx-2"
                    onChange={this.onChangeSelectedColumns}
                    fixedLabel={t("Active columns")}
                  />
                }
              />
              <FilterSeparator />
              <ExportToCSV columns={columns} jsonData={weeks} fileName={t("Weeks") + ".csv"} />
            </>
          }
          buttons={this.props.buttons}
        />
        <TableSimple columns={columns} data={weeks} />
      </>
    );
  }
}

export default withTranslation()(TableWeeks);
