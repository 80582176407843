import db from "./db";
import get from "../../requests/get";
import post from "../../requests/post";


async function loadPlayTimesByPlayer(id) {
    return get("competition", "playTimesByPlayer", id).then((res) => {
        if (res) {
            db.playTimes.bulkPut(res);
        }
        return res;
    });
}

function getPlayTimesByPlayer(id) {
    if (db.playTimes.toArray()) {
        return db.playTimes
            .where({ playerId: id })
            .toArray()
        
    }
}

async function loadPlayTimesByMatch(id) {
    return get("competition", "playTimesByMatch", id).then((res) => {
        if (res) {
            db.playTimes.bulkPut(res);
        }
        return res;
    });
}

function getPlayTimesByMatch(id) {
    if (db.playTimes.toArray()) {
        return db.playTimes
            .where({ matchId: id })
            .toArray()
        
    }
}

function loadPlayTimesByPlayers(ids, startDate, endDate) {
    return post("competition", "playTimesByPlayers", {playersIds : ids, startDate: startDate, endDate: endDate}).then(res => {if (res) {return res.json()}}).then(
      (res) => {
        if (res) {
          db.playTimes.bulkPut(res);
        }
        return res;
      }
    );
  }

export {
    getPlayTimesByMatch,
    loadPlayTimesByMatch,
    getPlayTimesByPlayer,
    loadPlayTimesByPlayer,
    loadPlayTimesByPlayers
  };