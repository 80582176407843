import React from "react";
import { withTranslation } from "react-i18next";
import ButtonLight from "../buttons/ButtonLight";

class GenerateTable extends React.Component {
  render() {
    return (
      <>
        <div
          className={
            this.props.className ? this.props.className : "-mx-6 -my-6"
          }
        >
          <table className="max-w-full w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                {this.props.columns.map((column, key) => (
                  <th
                    scope="col"
                    className="px-2 py-2 2xl:py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    style={column.maxWidth ? { maxWidth: column.maxWidth } : {}}
                    key={key}
                  >
                    {column.label}
                  </th>
                ))}
              </tr>
            </thead>

            {this.props.data ? (
              <tbody className="bg-white divide-y divide-gray-200">
                {this.props.data.map((row, key) => {
                  return row && row !== null ? (
                    <tr key={key}>
                      {this.props.columns.map((column, columnKey) => {
                        const value = column.format ? row : row[column.id];
                        return (
                          <td
                            key={columnKey}
                            className="px-2 py-2 2xl:py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                            style={
                              column.maxWidth
                                ? { maxWidth: column.maxWidth }
                                : {}
                            }
                          >
                            {column.format ? column.format(value) : value}
                          </td>
                        );
                      })}
                    </tr>
                  ) : null;
                })}
              </tbody>
            ) : null}
          </table>
          {this.props.handleAdd ? (
            <div className="w-full flex justify-center mt-2 2xl:mt-4">
              <ButtonLight
                onClick={() => {
                  this.props.handleAdd();
                }}
              >
                {this.props.t("Add")}
              </ButtonLight>
            </div>
          ) : null}
        </div>
      </>
    );
  }
}

class Table extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      columns: null,
    };
    this.formattedColumns = this.formattedColumns.bind(this);
  }

  componentDidMount() {
    this.formattedColumns(this.props.columns);
    this.setState({ data: this.props.data });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data || this.props.columns !== prevProps.columns) {
      this.formattedColumns(this.props.columns);
      this.setState({ data: this.props.data });
    }
  }

  formattedColumns(columns) {
    if (columns) {
      columns = columns.map((c) => {
        if (!c.Header) {
          if (c.id) {
            c.accessor = c.key;
          }
          c.Header = c.label ? c.label : "";
          c.id = c.id ? c.id : c.key ? c.key : "";
          if (c.format) {
            c.Cell = (row) => c.format(row.row.original);
          } else {
            c.accessor = c.key;
          }
        }
        return c;
      });
      this.setState({ columns });
    }
  }

  render() {
    const { t } = this.props;
    return this.state.data &&
      this.state.data.length > 0 &&
      this.state.columns &&
      this.state.columns.length > 0 ? (
      <GenerateTable
        className={this.props.className}
        columns={this.state.columns}
        handleAdd={this.props.handleAdd}
        data={this.state.data}
        t={t}
        height={this.props.height ? this.props.height : 1000}
      />
    ) : (
      <>LOADING</>
    );
  }
}

export default withTranslation()(Table);
