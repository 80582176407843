import React from "react";
import { withTranslation } from "react-i18next";
import FiltersBar from "../components/filters/FiltersBar";
import FilterSeparator from "../components/filters/FilterSeparator";
import FiltersTitle from "../components/filters/FiltersTitle";
import { Input, SelectMultiple } from "../components/forms";
import TableSimple from "../components/table/TableSimple";

class TablePhysicalSequences extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actions: [],
      themes: [],
      selectedThemes: [],
      columns: [],
      searchText: "",
    };
  }

  componentDidMount() {
    this.prepareData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.prepareData();
    }
  }

  prepareData = () => {
    var sequences = this.props.sequences;
    var themes = [];
    var selectedThemes = [];
    if (sequences) {
      sequences = sequences.map((sequence) => {
        if (
          themes &&
          !themes.find((o) => o.key === sequence.physicalThemeName)
        ) {
          themes.push({
            key: sequence.physicalThemeName,
            label: sequence.physicalThemeName,
          });
          selectedThemes.push(sequence.physicalThemeName);
        }
        return sequence;
      });
    }

    const columns = [
      {
        key: "name",
        visible: true,
        label: this.props.t("Title"),
        id: "name",
        width: 60,
        format: (row) => row.name,
      },
      {
        key: "physicalThemeName",
        visible: true,
        label: this.props.t("Theme"),
        filter: {
          name: "SelectColumnFilter",
          type: "includes",
        },
        width: 50,
        format: (row) => row.physicalThemeName,
      },
      {
        key: "duration",
        visible: true,
        label: this.props.t("Duration"),
        width: 30,
        format: (row) => row.duration + " min",
      },
      {
        key: "instructions",
        visible: true,
        label: this.props.t("Intructions"),
        width: 1000,
        format: (row) => row.instructions,
      },
    ];
    var selectedColumns = [];
    columns.forEach((column) => {
      if (column.visible) {
        selectedColumns.push(column.key);
      }
    });
    this.setState({
      columns,
      sequences,
      themes,
      selectedColumns,
      selectedThemes,
    });
  };

  render() {
    const { t } = this.props;
    var sequences = this.props.sequences;
    var columns = this.state.columns;
    var selectedColumns = this.state.selectedColumns;
    var selectedThemes = this.state.selectedThemes;
    var searchText = this.state.searchText;
    if (columns) {
      columns = columns.filter((o) =>
        selectedColumns.some((item) => item === o.key)
      );
    }
    if (sequences) {
      sequences = sequences.filter((o) =>
        selectedThemes.some((item) => item === o.physicalThemeName)
      );
    }
    if (searchText.length > 2) {
      sequences = sequences.filter((o) =>
        o.name.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    return (
      <>
        <FiltersBar
          components={
            <>
              <FiltersTitle title={sequences
                  ? sequences.length + " " + t("Contents").toLowerCase()
                  : ""}
              />
              <FilterSeparator />
              <SelectMultiple
                options={this.state.themes}
                keys={this.state.selectedThemes}
                className="mx-2"
                onChange={(res) => this.setState({ selectedThemes: res })}
                fixedLabel={t("Themes")}
              />
              <FilterSeparator />
              <Input
                value={this.state.searchText}
                onChange={(searchText) => this.setState({ searchText })}
                className="mx-2 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"
              />
              <FilterSeparator />
              <SelectMultiple
                options={this.state.columns}
                keys={this.state.selectedColumns}
                className="mx-2"
                onChange={(res) => this.setState({ selectedColumns: res })}
                fixedLabel={t("Active columns")}
              />
            </>
          }
        />
        <TableSimple columns={columns} data={sequences} />
        <div className="h-10"></div>
      </>
    );
  }
}

export default withTranslation()(TablePhysicalSequences);
