import React from "react";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import Page from "../../components/page/Page";
import {
  getCompetitionById,
  loadNationalCompetitionById,
} from "../../utils/indexedDB/Tennis-Competition/handleCompetitionTennis";
import moment from "moment";
import ActionButton from "../../components/buttons/ActionButton";
import { getPlayers, getStaffs } from "../../utils/indexedDB/users/handleUser";
import Card from "../../components/card/Card";
import Loading from "../../components/loaders/Loading";
import CardTennisMatch from "../../components/card/CardTennisMatch";
import ButtonLight from "../../components/buttons/ButtonLight";
import GeneratedForm from "../../utils/forms/generateForm";
import DisplayData3cols from "../../components/displayData/DisplayData3cols";
import ButtonLightRed from "../../components/buttons/ButtonLightRed";
import SVGDelete from "../../components/icons/SVGDelete";
import SVGEdit from "../../components/icons/SVGEdit";
import SVGCreate from "../../components/icons/SVGCreate";

class NationalDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      refresh: false,
      disableDelete: true,
    };
  }

  componentDidMount() {
    var id = this.props.match.params.id;

    getCompetitionById(id).then((competition) => {
      this.setState({
        competition,
        disableDelete: competition ? competition.disableDelete : true,
      });
      getPlayers().then((players) =>
        players && players.length ? this.setState({ players }) : null
      );
      getStaffs().then((staffs) =>
        staffs && staffs.length
          ? this.setState({ staffs, loading: false })
          : null
      );
    });
    if (navigator.onLine) {
      loadNationalCompetitionById(this.props.match.params.id).then(
        (competition) => {
          this.setState({
            competition,
            disableDelete: competition ? competition.disableDelete : true,
          })
          getPlayers().then((players) =>
            players && players.length
              ? this.setState({ players, loading: false })
              : null
          );
        }
      );
    }
  }

  update() {
    this.setState({ loading: true });
    loadNationalCompetitionById(this.props.match.params.id).then(
      (competition) => {
        this.setState({
          competition,
          disableDelete: competition ? competition.disableDelete : true,
        });
        getPlayers().then((players) =>
          players && players.length
            ? this.setState({ players, loading: false })
            : null
        );
      }
    );
  }

  getPlayers(engagements) {
    var users = [];
    engagements.forEach((p) => {
      if (
        this.state.players &&
        this.state.players.find((x) => x.id === p.playerId)
      ) {
        engagements.player = this.state.players.find(
          (x) => x.id === p.playerId
        );
        var user = this.state.players.find((x) => x.id === p.playerId);
        user.eId = p.id;
        user.tId = p.tournamentId;
        users.push(user);
      }
    });
    return users;
  }

  getSupervisors(supervisors) {
    var users = [];
    supervisors.forEach((s) => {
      if (
        this.state.staffs &&
        this.state.staffs.find((x) => x.id === s.staffId)
      ) {
        var user = this.state.staffs.find((x) => x.id === s.staffId);
        user.eId = s.id;
        users.push(user);
      }
    });
    return users;
  }

  render() {
    const { t } = this.props;

    return (
      <Page
        pageTitle={
          this.state.competition
            ? this.state.competition.name
            : t("National Details")
        }
        backPage="/competitionTennis/national"
        actions={{
          other: [
            <GeneratedForm
                key="updateTennisTournamentNational"
                component={(show, text) => (
                  <ButtonLight
                    onClick={show}
                    disabled={!navigator.onLine}
                  >
                    <SVGEdit />
                  </ButtonLight>
                )}
                callback={(res) => this.forceUpdate()}
                formName="updateTennisTournamentNational"
                formData={this.state.competition}
              />,
            <GeneratedForm
              key="deleteTennisTournamentNational"
              component={(show, text) => (
                <ButtonLightRed onClick={show} disabled={!navigator.onLine}>
                  <SVGDelete />
                </ButtonLightRed>
              )}
              callback={() =>
                this.props.history.push("/competitionTennis")
              }
              disableDelete={this.state.disableDelete}
              formName="deleteTennisTournamentNational"
              formData={this.state.competition}
            />,
          ],
        }}
        tab={[
          {
            title: t("Tournament information"),
            visible: true,
            data: this.state.competition,
            component: (data) => {
              return this.state.loading || !data ? (
                <div style={{ height: "80vh", width: "100%" }}>
                  <Loading />
                </div>
              ) : (
                
                  <div>
                    <div className="flex space-x-5">
                      <div className="w-full py-6 px-10">
                        <Card
                        >
                          <DisplayData3cols
                            data={[
                              {
                                key: t("Title"),
                                value: data.name
                              },
                              {
                                key: t("Type"),
                                value: data.type ? data.type.name : "-"
                              },
                              {
                                key: t("Classification"),
                                value: data.classification ? data.classification.name : "-"
                              },
                              {
                                key: t("Category"),
                                value: data.category
                              },

                              { key: t("Serie"), value: data.serie },
                              {
                                key: t("Level"),
                                value: data.level
                              },
                              { key: t("Contests"), value: data.contests },



                              {
                                key: t("Mode"),
                                value: data.mode
                              },
                              {
                                key: t("Grounds"),
                                value: data.grounds
                              },
                              { key: t("Location"), value: data.city },
                              { key: t("SDate"), value: moment(data.startDate).calendar() },
                              {
                                key: t("EDate"),
                                value: moment(data.endDate).calendar(),
                              },
                            ]}
                          />
                        </Card>
                      </div>
                    </div>
                    <div className="flex space-x-10 my-3 mx-10">
                      <div
                        className="w-1/2 py-4 px-5 mx-5"
                        style={{ boxShadow: "0 0 10px 0 rgba(82,63,105,.1)" }}
                      >
                        <Card title={t("Engagements")} className=" card-stretch" 
                          headerActions={[
                            <GeneratedForm
                              key="addTennisEngagement"
                              component={(show, text) => (
                                <ButtonLight
                                  onClick={show}
                                  disabled={!navigator.onLine}
                                >
                                  <SVGCreate />
                                </ButtonLight>
                              )}
                              callback={(res) =>
                                this.update()
                              }
                              formName="addTennisEngagement"
                              formData={{
                                tournamentId: this.props.match.params.id,
                              }}
                            />
                          ]}>
                          <ul className="divide-y divide-gray-200">
                            {data.engagements
                              ? this.getPlayers(data.engagements).map((player, Skey) =>
                                player ? (
                                  <li className="py-6 flex" key={Skey}>
                                    <div className="flex justify-between w-full">
                                      <div className="flex">
                                        <img
                                          className="h-7 w-7 2xl:h-10 2xl:w-10 rounded-full"
                                          src={player.photo}
                                          alt=""
                                        />
                                        <div className="ml-3">
                                          <p className="text-xs 2xl:text-sm font-semibold text-gray-900">
                                            {player.lastName
                                              ? player.lastName.toUpperCase() +
                                              " " +
                                              player.firstName
                                              : null}
                                          </p>
                                          <p className="text-xs 2xl:text-sm text-gray-500">{player.group1.name}</p>

                                        </div>
                                      </div>
                                      <ActionButton
                                        key={player.id}
                                        deleteForm="deleteTennisEngagement"
                                        formData={{
                                          id: player.eId,
                                          tournamentId: player.tId,
                                          playerId: player.id,
                                          name: player.lastName.toUpperCase() + " " + player.firstName,
                                        }}
                                        refreshData={() => this.update()}
                                      />
                                    </div>
                                    
                                    
                                  </li>
                                ) : (
                                    ""
                                  )
                              )
                              : ""}
                          </ul>
                        </Card>
                      </div>
                      <div
                        className="w-1/2 py-4 px-5 mx-5"
                        style={{ boxShadow: "0 0 10px 0 rgba(82,63,105,.1)" }}
                      >
                        <Card title={t("Supervisors")} className=" card-stretch" headerActions={[
                            <GeneratedForm
                              key="addTennisSupervision"
                              component={(show, text) => (
                                <ButtonLight
                                  onClick={show}
                                  disabled={!navigator.onLine}
                                >
                                  <SVGCreate />
                                </ButtonLight>
                              )}
                              callback={(res) =>
                                this.update()
                              }
                              formName="addTennisSupervision"
                              formData={{
                                tournamentId: this.props.match.params.id,
                              }}
                            />
                          ]}>
                          <ul className="divide-y divide-gray-200">
                            {data.supervisors
                              ? this.getSupervisors(data.supervisors).map((staff, Skey) =>
                                staff ? (
                                  <li className="py-6 flex" key={Skey}>
                                    <div className="flex justify-between w-full">
                                      <div className="flex">
                                    <img
                                      className="h-7 w-7 2xl:h-10 2xl:w-10 rounded-full"
                                      src={staff.photo}
                                      alt=""
                                    />
                                    <div className="ml-3">
                                      <p className="text-xs 2xl:text-sm font-semibold text-gray-900">
                                        {staff.lastName
                                          ? staff.lastName.toUpperCase() +
                                          " " +
                                          staff.firstName
                                          : null}
                                      </p>
                                      <p className="text-xs 2xl:text-sm text-gray-500">{staff.title}</p>
                                    </div>
                                    </div>
                                    <ActionButton
                                      key={staff.id}
                                      deleteForm="deleteTennisSupervision"
                                      formData={{
                                        id: staff.eId,
                                        tournamentId: this.props.match.params.id,
                                        playerId: staff.id,
                                        name: staff.lastName.toUpperCase() + " " + staff.firstName,
                                      }}
                                      refreshData={() => this.update()}
                                    /></div>
                                  </li>
                                ) : (
                                    ""
                                  )
                              )
                              : ""}
                          </ul>
                        </Card>
                      </div>
                    </div>
                  </div>
              );
            },
          },
          {
            title: t("Results"),
            visible: false,
            data: this.state.competition
              ? this.state.competition.matches
              : null,
            component: (data) => {
              return this.state.loading ? (
                <div style={{ height: "80vh", width: "100%" }}>
                  <Loading />
                </div>
              ) : (
                <>
                  {data
                    ? data.map((match, key) => (
                        <CardTennisMatch
                          match={match}
                          player={this.state.players.find(
                            (x) => x.id === match.playerId
                          )}
                          key={key}
                        />
                      ))
                    : ""}
                </>
              );
            },
          },
        ]}
      />
    );
  }
}

export default withTranslation()(withRouter(NationalDetails));