import getAccessToken from "../auth/getAccessToken";

export default async function del(APImodule, APIfeature, data) {
    if (typeof data === "string") {
        return getAccessToken().then((accessToken) => fetch(
            window.location.origin + "/api/v1.0/" + APImodule + "/" + APIfeature + "/" + data,
            {
                method: "DELETE",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "my-Authorization": `Bearer ${accessToken}`
                }
            }
        ))
    } else if (data.id) {
        return getAccessToken().then((accessToken) => fetch(
            window.location.origin + "/api/v1.0/" + APImodule + "/" + APIfeature + "/" + data.id,
            {
                method: "DELETE",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "my-Authorization": `Bearer ${accessToken}`
                }
            }
        ))
    } else {
        return getAccessToken().then((accessToken) =>
            fetch(
                window.location.origin + "/api/v1.0/" + APImodule + "/" + APIfeature,
                {
                    method: "DELETE",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        "my-Authorization": `Bearer ${accessToken}`,
                    },
                    body: JSON.stringify(data),
                }
            )
        );
    }

}