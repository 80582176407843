import React from "react";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import Page from "../../components/page/Page";
import {
  getAnnounces,
  loadAnnounces,
} from "../../utils/indexedDB/communication/handleCommunication";
import CardAnnounce from "../../components/card/CardAnnounce";
import Loading from "../../components/loaders/Loading";
import {
  loadStaffs,
  loadPlayers,
  getPlayers,
} from "../../utils/indexedDB/users/handleUser";
import getUserProfile from "../../utils/auth/getUserProfile";

class Announces extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      id: null,
    };
  }

  componentDidMount() {
    getUserProfile().then((profile) => this.setState({ id: profile.sub }));
    getPlayers().then((players) => {
      if (players && players.length) {
        this.setState({ players });
        getAnnounces().then((messages) => {
          if (messages && messages.length > 0) {
            this.setState({ messages, loading: false });
          }
        });
      }
    });

    if (navigator.onLine) {
      loadStaffs().then(() =>
        loadPlayers().then((players) => {
          if (players) this.setState({ players });
          loadAnnounces().then((messages) => {
            if (messages) {
              this.setState({ messages, loading: false });
            }
          });
        })
      );
    }
  }

  render() {
    const { t } = this.props;

    return (
      <Page
        pageTitle={t("Messagerie")}
        tab={[
          {
            title: t("Messages reçus"),
            data: this.state.messages
              ? this.state.messages.filter((o) => (o) =>
                  o.authorId !== this.state.id
                )
              : null,
            component: (data) => {
              return this.state.loading ? (
                <div style={{ height: "80vh", width: "100%" }}>
                  <Loading />
                </div>
              ) : (
                <div>
                  {this.state.messages && this.state.messages.length > 0
                    ? this.state.messages.map((message, key) => (
                        <CardAnnounce
                          key={key}
                          annonce={message}
                          players={this.state.players}
                          t={t}
                        />
                      ))
                    : t("No announces")}
                </div>
              );
            },
          },
          {
            title: t("Messages envoyés"),
            data: this.state.messages
              ? this.state.messages.filter((o) => (o) =>
                  o.authorId === this.state.id
                )
              : null,
            component: (data) => {
              return this.state.loading ? (
                <div style={{ height: "80vh", width: "100%" }}>
                  <Loading />
                </div>
              ) : (
                <div>
                  <div></div>
                </div>
              );
            },
          },
        ]}
      />
    );
  }
}

export default withTranslation()(withRouter(Announces));
