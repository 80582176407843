import React from "react";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import { loadPlayers } from "../../utils/indexedDB/users/handleUser";
import {
  loadMatchesByDatesAndPlayers,
  loadTournamentsByPlayersCurrent,
} from "../../utils/indexedDB/Tennis-Competition/handleCompetitionTennis";
import moment from "moment";
import { loadEventsByPlayers } from "../../utils/indexedDB/events/handleEvents";
import Card from "../../components/card/Card";
import { Link } from "react-router-dom";
import Carousel from "../../components/carousels/Carousel";
import svgDashboard from "../../assets/svg/working.svg";
import SVG from "react-inlinesvg";
import ListIcon from "../../components/icons/ListIcon";
import TextTruncated from "../../components/typography/TextTruncated";
import CardTennisMatch from "../../components/card/CardTennisMatch";
import Flag from "../../components/icons/Flag";
import Tour from "reactour";
import getUserProfile from "../../utils/auth/getUserProfile";
import visits from "../../utils/requests/visits";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isTourOpen: false,
    };
    this.closeTour = this.closeTour.bind(this);
    this.reloadTour = this.reloadTour.bind(this);
  }
  componentDidMount() {
    if (navigator.onLine) {
      var host = window.location.host;
      getUserProfile().then((user) => {
        var userId = user.sub;
        visits(userId, "Core", "Dashboard", host).then((res) => {
          if(typeof value === "boolean")
          {
            this.setState({ isTourOpen: res });
          }
        });
      });
      loadPlayers().then((players) => {
        this.setState({ players, loading: false });
        if (players) {
          var playersIds = [];
          players.forEach((player) => playersIds.push(player.id));
          loadTournamentsByPlayersCurrent(playersIds).then((tournaments) => {
            if (tournaments && tournaments !== undefined) {
              this.setState({ tournaments });
            }
          });
          var dateSM1 = moment()
            .add(-1, "weeks")
            .startOf("day")
            .toDate()
            .toISOString();
          var date = moment().toDate().toISOString();
          var dateP1 = moment()
            .add(1, "days")
            .startOf("day")
            .toDate()
            .toISOString();
          loadEventsByPlayers(playersIds, date, dateP1).then((events) => {
            if (events && events !== undefined) {
              this.setState({ events });
            }
          });
          loadMatchesByDatesAndPlayers(playersIds, dateSM1, date).then(
            (events) => {
              events &&
                events.forEach((evt) => {
                  var matches = [];
                  if (evt && evt.matches.length > 0 && evt !== undefined) {
                    if (evt.matches && evt.matches.length > 0) {
                      matches = matches.concat(evt.matches);
                    }
                  }
                  this.setState({ matches });
                });
            }
          );
        }
      });
    }
  }

  reloadTour() {
    this.setState({ isTourOpen: true });
  }

  closeTour() {
    this.setState({ isTourOpen: false });
  }

  render() {
    const { t } = this.props;
    var itemsMatches = [];
    if (this.state.matches) {
      this.state.matches.forEach((match, key) => {
        itemsMatches.push(
          <CardTennisMatch
            match={match}
            player={this.state.players.find((o) => o.id === match.playerId)}
            key={key}
          />
        );
      });
    }
    if (this.state.tournaments) {
      var tournamentsMutualised = [];
      this.state.tournaments.forEach((tournament) => {
        if (tournamentsMutualised.find((o) => o.id === tournament.id)) {
          tournamentsMutualised
            .find((o) => o.id === tournament.id)
            .players.push(
              this.state.players.find((o) => o.id === tournament.playerId)
            );
        } else {
          tournament.players = [];
          tournament.players.push(
            this.state.players.find((o) => o.id === tournament.playerId)
          );
          tournamentsMutualised.push(tournament);
        }
      });
      var itemsTournaments2 = [];
      tournamentsMutualised &&
        tournamentsMutualised.forEach((tournament) => {
          itemsTournaments2.push(
            <Link
              className="tourStep7"
              to={
                tournament.circuit.name === "National"
                  ? "/competitionTennis/nationaldetails/" +
                    tournament.id +
                    "/a/b"
                  : "/competitionTennis/internationaldetails/" +
                    tournament.id +
                    "/a/b"
              }
            >
              <div className="flex items-center space-x-5 p-3 px-4 2xl:p-6 2xl:px-6 m-1 2xl:m-3 rounded-md border justify-between hover:border-indigo-800">
                <div>
                  <div className="truncate">
                    <h1 className="text-base 2xl:text-lg text-indigo-800 font-semibold truncate overflow-hidden">
                      {tournament.name.length > 26 ? (
                        <TextTruncated title={tournament.name}>
                          {tournament.name.slice(0, 23) + "..."}
                        </TextTruncated>
                      ) : (
                        tournament.name
                      )}
                    </h1>
                  </div>
                  <p
                    className="text-xs 2xl:text-sm font-medium truncate"
                    style={{ color: tournament.circuit.color }}
                  >
                    {tournament.circuit.name}
                  </p>
                  <div className="flex flex-inline mt-1">
                    <Flag
                      className="w-3 2xl:w-5"
                      nationalityId={tournament.country}
                    />
                    <div className="ml-2 text-xs 2xl:text-sm">
                      {tournament.city}
                    </div>
                  </div>
                  <div className="flex flex-inline mt-1 2xl:mt-2">
                    <ListIcon users={tournament.players} length={4} />
                  </div>
                </div>
              </div>
            </Link>
          );
        });
    }
    if (this.state.events) {
      var itemsEvents2 = [];
      this.state.events &&
        this.state.events.forEach((event) => {
          itemsEvents2.push(
            <Link
              to={event.link ? event.link + "/" + event.playerId : "/"}
              className="tourStep5"
            >
              <div className="col-span-1 flex rounded-md m-3 hover:border-indigo-800">
                <div
                  className="flex-shrink-0 flex items-center justify-center w-16 bg-pink-600 text-white text-sm font-medium rounded-l-md hover:border-indigo-800"
                  style={{ backgroundColor: event.type.color }}
                >
                  {event.type.name.slice(0, 1)}
                </div>
                <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate hover:border-indigo-800">
                  <div className="flex-1 px-4 py-2 text-sm truncate">
                    <div className="text-gray-900  hover:text-gray-600 font-semibold truncate">
                      {event.name}
                    </div>
                    <p
                      className="text-gray-500 my-1"
                      style={{ color: event.type.color }}
                    >
                      {event.type.name}
                    </p>
                    <p className="text-gray-500 my-1">
                      {moment(event.startDate).format("HH:mm")} -{" "}
                      {moment(event.endDate).format("HH:mm")}
                    </p>
                    <p className="text-indigo-800 my-1"></p>
                    {event.playerId ? (
                      <div className="px-0 flex items-center space-x-3 my-2">
                        <div className="flex-shrink-0">
                          <img
                            className="h-6 w-6 rounded-full"
                            src={
                              this.state.players.find(
                                (o) => o.id === event.playerId
                              ).photo
                            }
                            alt=""
                          />
                        </div>
                        <div className="flex-1 min-w-0">
                          <span
                            className="absolute inset-0"
                            aria-hidden="true"
                          ></span>
                          <p className="text-sm font-medium text-gray-900">
                            {this.state.players
                              .find((o) => o.id === event.playerId)
                              .lastName.toUpperCase() +
                              " " +
                              this.state.players.find(
                                (o) => o.id === event.playerId
                              ).firstName}
                          </p>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </Link>
          );
        });
    }

    let steps = [];
    steps.push(
      {
        selector: ".tourStep1",
        content: t("Presentation of the Exaduo platform"),
      },
      {
        selector: ".tourStep2",
        content: t("Access the Exaduo Dashboard"),
      },
      {
        selector: ".tourStep3",
        content: t("Find the different modules of your platform"),
      },
      {
        selector: ".tourStep12",
        content: t("Access your players' files and the list of staff members"),
      },
      {
        selector: ".tourStep13",
        content: t(
          "Select the players for whom you want to display the schedule simultaneously"
        ),
      },
      {
        selector: ".tourStep14",
        content: t("Access your players' goals and ratings"),
      },
      {
        selector: ".tourStep16",
        content: t("Access your players training and exercises"),
      },
      {
        selector: ".tourStep15",
        content: t("Access the list of tournaments and rankings"),
      },

      {
        selector: ".tourStep17",
        content: t(
          "Access the administrative file of your players, the follow-up of licenses and schooling"
        ),
      }
    );

    if (itemsEvents2 && itemsEvents2.length > 0) {
      steps.push(
        {
          selector: ".tourStep4",
          content: t("Go directly to the global calendar"),
        },
        {
          selector: ".tourStep5",
          content: t("Access the details of a session"),
        }
      );
    }
    if (itemsTournaments2 && itemsTournaments2.length > 0) {
      steps.push(
        {
          selector: ".tourStep6",
          content: t("Access the list of tournaments and rankings"),
        },
        {
          selector: ".tourStep7",
          content: t("Go directly to the tournament details"),
        }
      );
    }
    if (itemsMatches && itemsMatches.length > 0) {
      steps.push(
        {
          selector: ".tourStep8",
          content: t("Go directly to the list of matches"),
        },
        {
          selector: ".tourStep9",
          content: t("Go directly to the match details"),
        }
      );
    }
    if (this.state.players) {
      steps.push(
        {
          selector: ".tourStep10",
          content: t("Go directly to the list of players"),
        },
        {
          selector: ".tourStep11",
          content: t("Go directly to the player's details"),
        }
      );
    }

    return (
      <>
        <Tour
          steps={steps}
          isOpen={this.state.isTourOpen}
          onRequestClose={this.closeTour}
        />
        <div className="w-full px-6 2xl:px-10 py-6 2xl:py-10 flex">
          <div
            className="w-3/5 rounded-xl mr-6 px-6 2xl:px-10 py-4 2xl:py-6 tourStep1"
            style={{ boxShadow: "0 0 10px 0 rgba(82,63,105,.2)" }}
          >
            <h2 className="text-indigo-800 font-bold text-xl 2xl:text-2xl">
              {t("Bienvenue sur Exaduo Sport !")}
            </h2>
            <p className="text-indigo-800 text-sm 2xl:text-base mt-1">
              Vous pourrez y saisir l’ensemble des données sur vos joueur-se-s :
              les données d’entrainement, le suivi physique, les résultats de
              compétition, le dossier administratif, mais aussi les informations
              liées à la scolarité ou à l’hébergement. L’outil vous permettra
              ensuite de gagner du temps dans la visualisation et l’analyse de
              ces informations, tout en optimisant le partage d’informations
              entre les différents acteurs.
            </p>
          </div>
          <div className="w-2/5 bg-indigo-200 rounded-xl mr-6 px-6 2xl:px-10 py-4 2xl:py-6">
            <div className="flex place-items-start">
              <div className="text-indigo-800 2xl:w-3/4">
                <h2 className="font-bold text-xl 2xl:text-2xl">
                  Assistance
                  <button className="ml-1" onClick={this.reloadTour}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-5"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </button>
                </h2>
                <p className="text-sm 2xl:text-base mt-1">
                  Nos équipes sont à votre disposition pour vous aider à travers
                  le chat intégré. Vous pouvez également consulter notre base de
                  connaissance pour trouver les réponses à vos questions
                </p>
              </div>
              <div className="mr-2 overflow-hidden 2xl:w-1/4 -my-16 hidden 2xl:block">
                <SVG src={svgDashboard} title={t("Dashboard")} className=" " />
              </div>
            </div>
          </div>
        </div>
        <div className="">
          {itemsEvents2 && itemsEvents2.length > 0 ? (
            <div className="w-full py-2 px-10">
              <Card
                title={t("Today")}
                headerActions={[
                  <Link
                    to="/calendar"
                    className="text-indigo-700 font-normal tourStep4"
                    key="headerCardCalendar"
                  >
                    <div className="flex justify-center">
                      {t("Global calendar")}
                    </div>
                  </Link>,
                ]}
              >
                <Carousel items={itemsEvents2} />
              </Card>
            </div>
          ) : (
            ""
          )}
          {itemsTournaments2 && itemsTournaments2.length > 0 ? (
            <div className="w-full py-0 2xl:py-2 px-10">
              <Card
                title={t("Current tournaments")}
                headerActions={[
                  <Link
                    key="competitionTennis"
                    to="/competitionTennis"
                    className="text-indigo-700 font-normal tourStep6"
                  >
                    <div className="flex justify-center">
                      {t("Tournaments list")}
                    </div>
                  </Link>,
                ]}
              >
                <Carousel items={itemsTournaments2} />
              </Card>
            </div>
          ) : (
            ""
          )}
          {itemsMatches && itemsMatches.length > 0 ? (
            <div className="w-full pb-1 2xl:py-2 mb-10 px-10 h-48 2xl:h-60">
              <Card
                title={t("Latest matches")}
                headerActions={[
                  <Link
                    to="/competitionTennis"
                    className="text-indigo-700 font-normal tourStep8"
                    key="CardTennisItems"
                  >
                    <div className="flex justify-center">
                      {t("Matches list")}
                    </div>
                  </Link>,
                ]}
              >
                <Carousel items={itemsMatches} />
              </Card>
            </div>
          ) : (
            ""
          )}
          <div className="w-full py-1 2xl:py-2 px-10 2xl:mt-5 mb-20">
            <Card
              title={t("My players")}
              headerActions={[
                <Link
                  key="group"
                  to="/group"
                  className="text-indigo-700 font-normal tourStep10"
                >
                  <div className="flex justify-center">{t("Players list")}</div>
                </Link>,
              ]}
            >
              <ul className="w-full mx-auto grid gap-x-4 gap-y-3 2xl:gap-y-8 py-3 2xl:py-6 grid-cols-5 md:gap-x-6 2xl:max-w-8xl 2xl:gap-x-12 2xl:grid-cols-6 text-center">
                {this.state.players
                  ? this.state.players.map((player, key) => (
                      <li key={key} className="tourStep11">
                        <Link to={"/group/player/" + player.id} key={player.id}>
                          <button className="hover:border-indigo-800 hover:border rounded-xl px-10 py-4 2xl:py-6">
                            {" "}
                            <div className="space-y-4">
                              <img
                                className="mx-auto h-20 w-20 rounded-full 2xl:w-24 2xl:h-24"
                                src={player.photo}
                                alt=""
                              />
                              <div className="space-y-2">
                                <div className="text-xs font-medium 2xl:text-sm truncate">
                                  <h3>
                                    {player.lastName.toUpperCase() +
                                      " " +
                                      player.firstName}
                                  </h3>
                                  <p className="text-indigo-600 truncate text-xs 2xl:text-sm">
                                    {player.group1 ? player.group1.name : ""}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </button>
                        </Link>
                      </li>
                    ))
                  : ""}
              </ul>
            </Card>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(withRouter(Dashboard));
