const configuration = {
    client_id: process.env.REACT_APP_IDP_CLIENTID,
    redirect_uri: process.env.REACT_APP_URL + "/authentication/callback",
    response_type: "code",
    post_logout_redirect_uri: process.env.REACT_APP_URL + "/signOut",
    scope:
      "openid profile email common schedule communication medias group management training competition competitionTennis evaluation monitoring stewardship administration education partners housing health scouting finance",
    authority: process.env.REACT_APP_IDP_AUTHORITY,
    silent_redirect_uri:
      process.env.REACT_APP_URL + "/authentication/silent_callback",
    automaticSilentRenew: false,
    loadUserInfo: false
  };
  
  export default configuration;
  