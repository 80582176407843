import React from "react";
import { withTranslation } from "react-i18next";
import Drawer from "../../../components/drawer/Drawer";
// import Modal from "../../../components/modal/Modal";

import StepConfig from "./config.json";
import * as Steps from "./steps/index.js";

class EventForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            step: this.props.step ? this.props.step : 'chooseType',
            originalStep: this.props.step,
            formData: this.props.formData,
            originalFormData: this.props.formData,
            method: this.props.method
        }
        this.initializeComponent = this.initializeComponent.bind(this);
        // this.nextStep = this.nextStep.bind(this);
        this.chooseComponent = this.chooseComponent.bind(this);
        this.goToStepChooseType = this.goToStepChooseType.bind(this);
    }

    componentDidMount() {
        this.initializeComponent();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.method !== this.props.method) {
            this.setState({ method: this.props.method, step: this.props.method === "DELETE" ? "deletion" : this.props.step ? this.props.step : 'chooseType', originalStep: this.props.method === "DELETE" ? "deletion" : this.props.step ? this.props.step : 'chooseType' });
        }

        if (prevProps.formData !== this.props.formData && (this.state.step === this.state.originalStep || (this.state.originalStep === "chooseType" && this.state.step === "information"))) {
            this.setState({ formData: this.props.formData, originalFormData: this.props.formData })
        }
    }

    initializeComponent() {
        this.setState({
            step: this.props.method === "DELETE" ? "deletion" : this.props.step ? this.props.step : 'chooseType',
            originalStep: this.props.method === "DELETE" ? "deletion" : this.props.step ? this.props.step : 'chooseType',
            formData: this.props.formData,
            originalFormData: this.props.formData
        })
    }

    goToStepChooseType() {
        this.initializeComponent();
    }

    nextStep(formData, hide) {
        var schema = StepConfig["General"];
        if (formData && formData.typeId && StepConfig[formData.typeId]) {
            schema = StepConfig[formData.typeId];
        }
        var currStepIndex = schema.findIndex(x => x === this.state.step);
        if (((this.props.step && this.props.step === schema[currStepIndex + 1]) || !this.props.step) && (currStepIndex || currStepIndex === 0) && currStepIndex < schema.length - 1 && currStepIndex !== -1) {
            if (this.props.callback) {
                this.props.callback();
            }
            this.setState({ step: schema[currStepIndex + 1], formData });
        } else {
            hide();
            if (this.props.callback) {
                this.props.callback();
            }
            this.initializeComponent();
        }

    }

    chooseComponent(step, hide) {
        switch (step) {
            case "chooseType":
                return <Steps.ChooseType method={this.state.method} formData={this.state.formData} hide={hide} nextStep={(formData) => this.nextStep(formData, hide)} />;
            case "information":
                return <Steps.Information method={this.state.method} formData={this.state.formData} originalFormData={this.state.originalFormData} hide={hide} nextStep={(formData) => this.nextStep(formData, hide)} goToStepChooseType={this.goToStepChooseType} />;
            case "invitation":
                return <Steps.Invitation step={this.state.originalStep} method={this.state.method} formData={this.state.formData} hide={hide} nextStep={(formData) => this.nextStep(formData, hide)} callback={this.props.callback} />;
            case "deletion":
                return <Steps.Deletion method={this.state.method} formData={this.state.formData} hide={hide} nextStep={(formData) => this.nextStep(formData, hide)} />;
            case "sequencesTraining":
                return <Steps.SequencesTraining step={this.state.originalStep} formData={this.state.formData} hide={hide} nextStep={(formData) => this.nextStep(formData, hide)} />;
            case "sequencesPhysical":
                return <Steps.SequencesPhysical step={this.state.originalStep} formData={this.state.formData} hide={hide} nextStep={(formData) => this.nextStep(formData, hide)} />;
            case "sequencesTrainingTemplate":
                return <Steps.SequencesTraining template={true} step={this.state.originalStep} formData={this.state.formData} hide={hide} nextStep={(formData) => this.nextStep(formData, hide)} />;
            case "sequencesPhysicalTemplate":
                return <Steps.SequencesPhysical template={true} step={this.state.originalStep} formData={this.state.formData} hide={hide} nextStep={(formData) => this.nextStep(formData, hide)} />;
            case "recurrence":
                return <Steps.Recurrence formData={this.state.formData} hide={hide} nextStep={(formData) => this.nextStep(formData, hide)} />
            default:
                console.log("Step not implemented");
                break;
        }
    }


    render() {
        const { t } = this.props;
        return (
            <Drawer
                className={this.state.step === "sequencesTraining" || this.state.step === "sequencesPhysical" ||
                    this.state.step === "sequencesTrainingTemplate" || this.state.step === "sequencesPhysicalTemplate" ||
                    (this.state.formData && this.state.formData.typeId && ["Physique", "Entrainement"].includes(this.state.formData.typeId)) ? "w-screen" : ""}
                component={(show) => this.props.component(show, t(this.props.title))}
                hide={() => {
                    if (this.props.hide) {
                        this.props.hide();
                    }
                    this.initializeComponent();
                }}
            >
                {(hide) => this.chooseComponent(this.state.step, () => {
                    hide();
                })}
            </Drawer>
        )
    }
}

export default withTranslation()(EventForm);