import React from "react";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import Page from "../../components/page/Page";
import Card from "../../components/card/Card";
import Button from "../../components/buttons/Button";
import GeneratedForm from "../../utils/forms/generateForm";
import {
  loadSchool,
  getCurriculum,
  loadCurriculum,
  loadCurriculumsPursuedByCurriculum,
} from "../../utils/indexedDB/scholarship/handleScholarship";
import TableCurriculumCurriculumsPursued from "../../tables/TableCurriculumCurriculumsPursued";
import { loadPlayers } from "../../utils/indexedDB/users/handleUser";
import DisplayData3cols from "../../components/displayData/DisplayData3cols";
import SVGEdit from "../../components/icons/SVGEdit";
import ButtonLight from "../../components/buttons/ButtonLight";

class Curriculum extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id,
      player: null,
    };
  }

  componentDidMount() {
    var id = this.props.match.params.id;
    getCurriculum(id).then((curriculum) =>
      curriculum ? this.setState({ curriculum }) : null
    );
    if (navigator.onLine) {
      loadCurriculum(id).then((curriculum) => {
        this.setState({ curriculum });
        loadSchool(curriculum.schoolId).then((school) =>
          this.setState({ school })
        );
      });
      loadPlayers().then(players => {
        loadCurriculumsPursuedByCurriculum(id).then(curriculumsPursued =>
        {
          var curriculumsPursuedFiltered = [];
          curriculumsPursued.map(curriculumPursued => {if (players.find(o => o.id === curriculumPursued.playerId)) {curriculumPursued.player = players.find(o => o.id === curriculumPursued.playerId); curriculumsPursuedFiltered.push(curriculumPursued)} return curriculumPursued});  
          this.setState({ curriculumsPursued: curriculumsPursuedFiltered });
        });
      });
    }
  }

  render() {
    const { t } = this.props;


    return (
      <Page
        pageTitle={
          t("Curriculum") +
          (this.state.curriculum ? " - " + this.state.curriculum.name : "")
        }
        backPage="/group/players"
        actions={{
          other: [
            <GeneratedForm
              key="updateCurriculum"
              component={(show, text) => (
                <ButtonLight
                  onClick={show}
                  disabled={!navigator.onLine}
                >
                  <SVGEdit />
                </ButtonLight>
              )}
              callback={(res) => this.forceUpdate()}
              formName="updateCurriculum"
              formData={this.state.curriculum}
            />
          ]
        }}
        tab={[
          {
            title: t("Main information"),
            visible: true,
            data: this.state.curriculum,
            component: (data) => {
              return (
                <>
                <div className="flex space-x-4">
                    <div className="w-full my-6 mx-10">
                      <Card
                      >
                        <DisplayData3cols
                          data={[
                            {
                              key: t("Name"),
                              value: this.state.curriculum
                                ? this.state.curriculum.name
                                : "-",
                            },
                            {
                              key: t("Type"),
                              value: this.state.curriculum && this.state.curriculum.type
                                ? this.state.curriculum.type.name
                                : "-",
                            },
                            {
                              key: t("Description"),
                              value: this.state.curriculum
                                ? this.state.curriculum.description
                                : "-",
                            },
                            {
                              key: t("School"),
                              value: this.state.curriculum && this.state.curriculum.school
                                ? this.state.curriculum.school.name
                                : "-",
                            },
                            {
                              key: t("Address"),
                              value: this.state.curriculum && this.state.curriculum.school
                              ? this.state.curriculum.school.address
                              : "-",
                            },
                            {
                              key: t("City"),
                              value: this.state.curriculum && this.state.curriculum.school
                              ? this.state.curriculum.school.city
                              : "-",
                            },
                            
                          ]}
                        />
                      </Card>
                    </div>
                  </div>
                  
                </>
              );
            },
          },
          {
            title: t("Curriculums pursued"),
            visible: true,
            data: this.state.curriculumsPursued,
            component: (data) => {
              return data ? (
                <>
                  <TableCurriculumCurriculumsPursued curriculumsPursued={data} refresh={() => loadCurriculumsPursuedByCurriculum(
                this.state.id
              ).then((curriculumsPursued) =>
                this.setState({ curriculumsPursued })
              )} buttons={[
                      <GeneratedForm
                        key="createCurriculumPursued"
                        component={(show, text) => (
                          <Button
                            onClick={show}
                            disabled={!navigator.onLine}
                          >
                            <i className="flaticon2-plus"></i> {text}
                          </Button>
                        )}
                        callback={(res) =>
                          loadCurriculumsPursuedByCurriculum(
                            this.state.id
                          ).then((curriculumsPursued) =>
                            this.setState({ curriculumsPursued })
                          )
                        }
                        formName="createCurriculumPursued"
                        formData={{ curriculumId: this.state.id }}
                      />]} />
                </>
              ) : null;
            },
          },
        ]}
      />
    );
  }
}

export default withTranslation()(withRouter(Curriculum));
